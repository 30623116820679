import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { getModule, Action, Module, Mutation, VuexModule, MutationAction } from 'vuex-module-decorators';
import { CollectCTBProtocol, Order, Visit, Mixer } from '@/types';
import dictModule from '@/store/modules/dict';
import {
  OrderStatus,
  VisitStatus,
  NEW_ID_START,
  DATE_TIME_FORMAT,
  ProtocolType,
  VisitToOrderStatusMap,
} from '@/consts';
import visitModule from './visits';
import moment from 'moment';
import * as helpers from '@/helpers';
import protocolsModule from './protocols';
import collectsModule from './collects';
import mixersModule from './mixers';
import samplesModule from './samples';
import examsModule from './collect_exams';
import visitsModule from './visits';
import store from '@/store';
import mixers from './mixers';
import order from './order';
import visits from './visits';
import { RelatedModel } from './decorators';

export interface OrdersState {
  orders: Order[];
}

@Module({
  namespaced: true,
  name: 'orders',
  store,
  dynamic: true,
})
class OrdersModule extends VuexModule implements OrdersState {
  orders: Order[] = [];
  rowsPerPage = 20;
  filters = [];

  public get orderById() {
    return (id: number): Order => {
      return this.orders.find(o => o.id === id) as Order;
    };
  }
  public get orderCopyById() {
    return (id: number): Order => {
      const orderJson = JSON.parse(JSON.stringify(this.orderById(id)));
      return Object.assign(new Order(), orderJson);
    };
  }

  public get allOrders(): Order[] {
    return this.orders;
  }

  @Mutation
  clear() {
    this.orders = [];
    console.log('orders module cleared...');
  }

  @Mutation
  replaceOrder(order: Order): any {
    //console.log('replaceOrder mutation', order);
    const o = this.orders.find(o => o.id === order.id);
    Object.assign(o, order);
  }

  @Mutation
  setOrders(orders: Order[]) {
    this.orders = [];
    orders.forEach(order => {
      this.orders.push(Object.assign(new Order(), order));
    });
  }

  @Mutation
  appendOrder(order: Order) {
    //console.log('Append order', order);
    this.orders.push(order);
  }

  @Mutation
  removeOrder(id: number) {
    this.orders = this.orders.filter(order => order.id !== id);
  }

  get print() {
    return (orderId: number, nested = false) => {
      console.log('------------------------------------------- VUEX ----------------------------------------');
      const order = this.orders.find(o => o.id === orderId);
      console.log('ORDER: id:', order.id, 'number', order.number, 'index', order.index);
      if (nested) {
        visitsModule.print(order.id, nested);
      }
      console.log('------------------------------------------- X ----------------------------------------');
    };
  }

  @Action({ rawError: true })
  async addOrderAction(order: Order): Promise<AxiosResponse> {
    const response = await API.saveOrder(order);
    if (response.data.success) {
      const newOrder = Object.assign(new Order(), response.data.order);

      visitsModule.addVisitByJSON(response.data.order.visit);
      visitsModule.addVisitByJSON(response.data.order.take_ctb_visit);

      newOrder.load(false);
      this.appendOrder(newOrder);
    }
    return response;
  }

  @Action
  async deleteOrderAction(id: number): Promise<boolean> {
    this.removeOrder(id);

    const visits = visitsModule.visits.filter(visit => visit.order_id === id);
    visits.forEach(visit => visitsModule.deleteVisit(visit));

    let isSuccess = false;
    const response = await API.deleteOrder(id);
    if (response.data.success) {
      console.log('Order', id, 'removed...');
      helpers.info('Zamówienie zostało usunięte');
      isSuccess = true;
    }
    return isSuccess;
  }

  @Action
  async removeRedundantData(order: Order) {
    const orderToSaveInDb = JSON.parse(JSON.stringify(order));
    orderToSaveInDb.visit.collect_and_exam_protocol.mixers.forEach(m =>
      m.collects.forEach(c => c.methods.forEach(m => delete m.method_users))
    );
    delete orderToSaveInDb.visit.laborant;
    return orderToSaveInDb;
  }

  @Action({ rawError: true })
  async updateOrderAction(order: Order): Promise<AxiosResponse> {
    const orderToSaveInDb = await this.removeRedundantData(order);
    console.log('Update ORDER...', orderToSaveInDb);
    const response = await API.saveOrder(orderToSaveInDb);
    if (response.data.success) {
      const order = Object.assign(new Order(), response.data.order);
      order.load(false);
      this.replaceOrder(order);
      visitsModule.updateVisitByJSON(response.data.order.visit);
      visitsModule.updateVisitByJSON(response.data.order.take_ctb_visit);
    }
    return response;
  }

  @Action
  @RelatedModel('Order')
  async fetchOrders(): Promise<AxiosResponse> {
    console.log('*** FetchOrders');
    const response = await API.fetchOrders();
    if (response.data.success) {
      this.setOrders(response.data.orders);
      console.log('    fetchOrders VUEX DONE:', this.orders.length);
      return response;
    }
  }

  @Mutation
  loadOrdersFromConfig(orders: object[]) {
    const i_orders = orders as Order[];
    i_orders.forEach(o => {
      if (o.client_id !== undefined) {
        const client = dictModule.clientById(o.client_id);
        if (client !== undefined) {
          o.client_name = client.name;
        }
      }
      if (o.cplace_id !== undefined) {
        const cplace = dictModule.cPlaceById(o.cplace_id);
        if (cplace !== undefined) {
          o.cplace_name = cplace.name;
        }
      }
      o.visit = visitModule.allVisits[3];
      o.take_ctb_visit = visitModule.allVisits[2];
    });
    this.orders = orders as Order[];
    console.log('Store module Orders loaded..', this.orders.length);
  }

  @Mutation
  setRowsPerPage(rowsPerPage: number): any {
    this.rowsPerPage = rowsPerPage;
  }

  @Mutation
  updateOrderStatusBasedOnVisitStatus(visit: Visit) {
    const statusId = visit.status_id;
    const newOrderStatus = VisitToOrderStatusMap[statusId];
    const orderId = visit.order_id;
    const order = this.orders.find(order => order.id === orderId);
    order.status_id = newOrderStatus.id;
  }
}

export default getModule(OrdersModule);
