





































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';

import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import ModalMethod from '../../../components/spec/ModalMethod.vue';
import ModalAction from '../../../components/spec/ModalAction.vue';
import ModalMethodFilter from '../../../components/spec/ModalMethodFilter.vue';

import breadcrumbModule from '../../../store/modules/breadcrumb';
import dashboardItemsModule from '../../../store/modules/dashboard_items';
import dataModule from '../../../store/modules/data';
import dictModule from '../../../store/modules/dict';
import methodsModule from '../../../store/modules/methods';
import userModule from '../../../store/modules/user';

import {
  MethodStatus,
  SortDir,
  LABORANT_ROUTE_PREFIX,
  MethodOperationType,
  DashboardObjectTypes,
  MethodActionStatus,
  DATE_TIME_FORMAT,
} from '../../../consts';
import { AppView, Method, MethodGroup, DashboardItem, User, DashboardAction } from '../../../types';
import { EventBus } from '../../../helpers/eventbus';
import methods from '../../../store/modules/methods';
import toolsModule from '../../../store/modules/tools';

@Component({
  components: {
    TopPanel,
    Header,
    Footer,
    ModalMethod,
    ModalMethodFilter,
    ModalAction,
  },
})
export default class MethodList extends Vue {
  @Ref() readonly modalMethodFilter: ModalMethodFilter;
  @Ref() readonly modalMethod: ModalMethod;
  @Ref() readonly modalAction: ModalAction;

  btnPrevVisible = false;
  btnNextVisible = true;

  pageSize = 200;
  pageNo = 1;
  allPagesCount = 0;
  filterParams: { field: ''; value: any }[] = [];
  sortParams = { field: 'changed_date_time', dir: SortDir.ASC };
  spinner = false;
  methods: Method[] = [];

  // ------------- GETTERS --------------
  get getOperList() {
    return (method: Method) => {
      return method.status.opers;
    };
  }
  async fillMethods() {
    console.log('fillMethods');
    this.spinner = true;
    await methodsModule.fetchMethods();
    this.methods = [];
    let methods = methodsModule.allMethods;

    if (this.filterParams.length > 0) {
      console.log('Filtering', this.filterParams);
      methods = this.filterMethod(methods, this.filterParams);
    }
    console.log('Sorting', this.sortParams);
    methods = this.sortMethod(methods, this.sortParams.field, this.sortParams.dir);
    let index = 1;
    methods.forEach(m => {
      m.load();
      index++;
      m['index'] = index;
    });
    this.allPagesCount = Math.ceil(methods.length / this.pageSize);
    console.log('Paginating pageSize=', this.pageSize, 'pageNo=', this.pageNo);
    this.methods = this.paginate(methods, this.pageSize, this.pageNo) as Method[];
    this.spinner = false;
    this.update();
  }

  getClientName(client_id: number) {
    if (client_id !== undefined) {
      const client = dictModule.clientById(client_id);
      return client !== undefined ? client.name : '';
    } else {
      return '';
    }
  }

  getCPlaceName(cplace_id: number) {
    if (cplace_id !== undefined) {
      const cplace = dictModule.cPlaceById(cplace_id);
      return cplace !== undefined ? cplace.name : '';
    } else {
      return '';
    }
  }

  getBatchColor(methodStatusId: number) {
    return MethodStatus.byId(methodStatusId).badge;
  }
  get getStatusName() {
    return (methodStatusId: number): string => {
      return MethodStatus.byId(methodStatusId).name;
    };
  }

  // ---------------- ROUTES ------------------

  addMethod() {
    this.modalMethod.showModal();
  }
  editMethod(event: any, item: any) {
    const di = new DashboardItem();
    di.enabled = item.method.status_id === MethodStatus.NEW.id;
    di.object = item.method;
    di.object_id = item.method.id;
    console.log('editMethod', di);
    this.modalMethod.showModal(di);
  }
  modalMethodOK(params) {
    console.log('modalMethodOK', params);
    params.object.group = dictModule.methodGroupById(params.object.group_id);
    if (params['new'] === true) {
      methodsModule.addMethod(params.object);
    } else {
      methodsModule.updateMethod(params.object);
    }
    this.fillMethods();
  }
  modalMethodCancel() {
    console.log('ModalPersonCancel');
  }
  get user(): User {
    return userModule.user;
  }
  goLink(method: Method, oper: MethodOperationType) {
    const newItem: DashboardItem = new DashboardItem();
    newItem.object = method;
    newItem.object_id = method.id;
    newItem.object_type_id = DashboardObjectTypes.METHOD;
    newItem.operation_type_id = oper.id;
    newItem.operation_type = oper;
    newItem.status_id = MethodActionStatus.INITIAL.id;
    newItem.status = MethodActionStatus.INITIAL;
    newItem.person_initiated_id = this.user.id;
    newItem.objectTypeName = 'Badanie';

    const lastMove = {
      oldStatusId: -1,
      oldStatus: null,
      newStatusId: MethodActionStatus.INITIAL.id,
      newStatus: MethodActionStatus.INITIAL,
    };
    this.modalAction.showModal({ item: newItem, lastMove: lastMove });
  }
  modalActionOK(params: object) {
    this.$router.push({
      name: 'dashboardactions',
      params: { object_type: DashboardObjectTypes.METHOD.toString() },
    });
  }
  modalActionCancel(params: object) {
    console.log('modalActionCancel', params);
  }

  // ----------------- INTERNAL ACTIONS ----------------
  prevPage() {
    this.pageNo--;
    this.fillMethods();
  }

  nextPage() {
    this.pageNo++;
    this.fillMethods();
  }

  changePageSize() {
    this.update();
    this.fillMethods();
  }

  filterPage() {
    console.log('FilterModal');
    this.modalMethodFilter.showModal();
  }

  sortPage(sortField = 'id') {
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.fillMethods();
  }

  update() {
    console.log('update', this.pageNo, this.allPagesCount);
    this.btnNextVisible = this.pageNo !== this.allPagesCount;
    this.btnPrevVisible = this.pageNo !== 1;
  }

  sortMethod(array: Method[], field: string, dir: SortDir): Method[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      case 'id':
      case 'number':
      case 'name':
      case 'norm':
      case 'acreditation':
      case 'actual':
      case 'status_id':
        console.log('Sorting field number');
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a[field] < b[field] ? 1 : -1;
          } else {
            return a[field] > b[field] ? 1 : -1;
          }
        });
      case 'group.name':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a['group']['name'] < b['group']['name'] ? 1 : -1;
          } else {
            return a['group']['name'] > b['group']['name'] ? 1 : -1;
          }
        });
      case 'changed_date_time':
        return array.sort((a, b) => {
          //console.log("DATES", moment(a[field], DATE_TIME_FORMAT), moment(b[field], DATE_TIME_FORMAT));
          if (dir === SortDir.ASC) {
            return moment(a[field], DATE_TIME_FORMAT).isAfter(moment(b[field], DATE_TIME_FORMAT)) ? -1 : 1;
          } else {
            return moment(a[field], DATE_TIME_FORMAT).isBefore(moment(b[field], DATE_TIME_FORMAT)) ? -1 : 1;
          }
        });
      default:
        return array;
    }
  }

  filterMethod(methods: Method[], filters: { field: string; value: any }[]) {
    //console.log("Filter Array", filters);
    let result = false;
    return methods.filter(method => {
      result = true;
      console.log('########## METHOD ', method.id, ' ###########');
      filters.forEach((filter, index) => {
        if (result) {
          const fieldSplitted = filter.field.split('.');
          console.log('-- FILTER ', index + 1, filter.field, filter.value, 'splitted', fieldSplitted);
          if (fieldSplitted.length === 1) {
            if (typeof filter.value === 'string') {
              console.log('String', method[filter.field], filter.value);
              result = method[filter.field].includes(filter.value);
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              console.log('Number', method[filter.field], filter.value);
              result = method[filter.field] === filter.value;
            }
          } else if (fieldSplitted.length === 2) {
            if (typeof filter.value === 'string') {
              result = method[fieldSplitted[0]][fieldSplitted[1]].includes(filter.value);
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              console.log('Number nested curr:', method[fieldSplitted[0]][fieldSplitted[1]], 'required', filter.value);
              result = method[fieldSplitted[0]][fieldSplitted[1]] === filter.value;
            }
          }
        }
        console.log('Result', result);
      });
      return result;
    });
  }

  paginate(array: Method[], pageSize: number, pageNumber: number): object[] {
    pageNumber--;
    const rowFrom: number = +(pageNumber * pageSize);
    const rowTo: number = +(pageNumber * pageSize) + +pageSize;
    console.log('Page [' + rowFrom, '-', rowTo + ']');
    let index = 1;
    const templateArray: object[] = [];
    array.forEach(o => {
      templateArray.push({
        index: index++,
        method: o,
      });
    });
    return templateArray.slice(rowFrom, rowTo);
  }

  modalMethodFilterOK(filters: any) {
    console.log('modalMethodFilterOK FILTERS:', filters);
    this.filterParams = filters;
    this.fillMethods();
  }
  modalMethodFilterCancel() {
    console.log('modalMethodFilterCancel');
  }

  async mounted() {
    await toolsModule.fetchTools();
  }

  created() {
    console.log('%c*********************** MethodsList creating ***********************', 'color:red');
    this.fillMethods();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Lista badań',
          link: '',
        },
      ],
    });
    console.log('%c*********************** MethodsList created ***********************', 'color:red');
  }

  get start() {
    console.log('%c---------- Start of template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of template -----------', 'color:green;font-weight:600');
    return '';
  }
}
