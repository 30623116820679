import Vue from 'vue';
import filters from './helpers/filters';
import { logProgress } from './helpers/index';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import * as API from '@/store/api';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { EventBus, BusEvents } from '@/helpers/eventbus';
import {
  faCalendarAlt,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faCog,
  faEnvelope,
  faFilter,
  faHome,
  faListAlt,
  faPlus,
  faQuestion,
  faShoppingBasket,
  faSort,
  faUserCircle,
  faUserCog,
  faUserSecret,
  faVial,
  faPlusSquare,
  faMinusSquare,
  faTrashAlt,
  faHandshake,
  faFilePowerpoint,
  faTimes,
  faCheck,
  faSignature,
  faEdit,
  faEye,
  faHistory,
  faRedoAlt,
  faShieldAlt,
  faPlayCircle,
  faSignOutAlt,
  faPowerOff,
  faTable,
  faWindowMaximize,
  faWindowMinimize,
  faTools,
  faExclamationTriangle,
  faExclamationCircle,
  faSave,
  faFilePdf,
  faClock,
  faThumbtack,
  faLock,
  faLockOpen,
  faChevronUp,
  faMagnifyingGlass,
  faCar,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import smoothscroll from 'smoothscroll-polyfill';
import visitsModule from '@/store/modules/visits';
import visitModule from '@/store/modules/visit';
import dictModule from '@/store/modules/dict';
import toolsModule from '@/store/modules/tools';
import offerModule from '@/store/modules/offer';
import methodModule from '@/store/modules/methods';
import orderModule from '@/store/modules/order';
import ordersModule from '@/store/modules/orders';
import userModule from '@/store/modules/user';
import usersModule from '@/store/modules/users';
import appModule from '@/store/modules/app';
import collectsModule from '@/store/modules/collects';
import protocolsModule from '@/store/modules/protocols';
import collectExamsModule from '@/store/modules/collect_exams';
import mixersModule from '@/store/modules/mixers';
import samplesModule from '@/store/modules/samples';
import dataModule from '@/store/modules/data';
import methodsModule from '@/store/modules/methods';
import resultsDocModule from '@/store/modules/results_doc';
import configApp from '@/config/app.json';
import examModule from '@/store/modules/exam';
import examsModule from '@/store/modules/exams';
import dashboardItemsModule from '@/store/modules/dashboard_items';
import { CollectExam } from './types';
import moduleManager from './store/modules/moduleManager';

export function loadStore() {
  console.log('%%%%%%%%%%%%%%%%%% loading store from JSON %%%%%%%%%%%%%%%%%%%%%');

  appModule.loadAppFromConfig(configApp);
  EventBus.$emit(BusEvents.LOGIN_PROGRESS, 98);
}

export function clearStore() {
  console.log('Clearing store...');
  dataModule.clear();
  collectExamsModule.clear();
  collectsModule.clear();
  dashboardItemsModule.clear();
  dictModule.clear();
  examModule.clear();
  examsModule.clear();
  methodsModule.clear();
  mixersModule.clear();
  moduleManager.clearVersions();
  offerModule.clear();
  ordersModule.clear();
  orderModule.clear();
  protocolsModule.clear();
  resultsDocModule.clear();
  samplesModule.clear();
  toolsModule.clear();
  visitModule.clear();
  visitsModule.clear();
  userModule.clear();
  usersModule.clear();
}

// function used to initialize db from json store data
export async function saveToDB() {
  console.log('%%%%%%%%%%%%%%%%%% save to DB from store %%%%%%%%%%%%%%%%%%%%%');
}

export async function loadFromDB() {
  console.log('%%%%%%%%%%%%%%%%%% loading from DB to store %%%%%%%%%%%%%%%%%%%%%');

  await dictModule.fetchAddresses();

  await dictModule.fetchClients();
  logProgress();

  await offerModule.fetchContracts();
  logProgress();

  await visitsModule.fetchVisits();
  logProgress();

  await mixersModule.fetchMixers();
  logProgress();

  await collectsModule.fetchCollects();
  logProgress();

  await protocolsModule.fetchPIBProtocols(false);
  logProgress();

  await dictModule.fetchConstructionPlaces();
  logProgress();

  await methodsModule.fetchMethods();
  logProgress();

  await dictModule.fetchConcreteClasses();
  logProgress();

  await dictModule.fetchConcreteClassGroups();
  logProgress();

  await dictModule.fetchConcreteClassSubgroups();
  logProgress();

  console.log('USER mod ?', userModule.user);
  if (userModule.user.module.toString() === "1") {
    console.log('USER mod 1', userModule.user);
    await dictModule.fetchLocalizations();
    logProgress();

    await dictModule.fetchToolGroups();
    logProgress();

    await ordersModule.fetchOrders();
    logProgress();

    await protocolsModule.fetchCTBProtocols();
    logProgress();

    await collectExamsModule.fetchCollectExams();
    logProgress();

    await dictModule.fetchDepartments();
    logProgress();

    await dictModule.fetchMethodGroups();
    logProgress();

    await methodsModule.fetchMethodStages();
    logProgress();

    await methodsModule.fetchMethodStageParams();
    logProgress();

    await methodsModule.fetchMethodStageTools();
    logProgress();

    await examsModule.fetchExamsStages();
    logProgress();

    await examsModule.fetchExamStageParams();
    logProgress();

    await examsModule.fetchExamSampleStageParams();
    logProgress();

    await dictModule.fetchMaterials();
    logProgress();

    await dataModule.fetchSignatures();
    logProgress();

    await toolsModule.fetchTools();
    logProgress();
  }

  await samplesModule.fetchSamples();
  logProgress();

  await dictModule.fetchDestinies();
  logProgress();

  await dictModule.fetchDestinyGroups();
  logProgress();

  await examsModule.fetchExams();
  logProgress();

  await resultsDocModule.fetchResultsDocs();
  logProgress();

  await offerModule.fetchOfferActions();
  logProgress();

  await methodModule.loadMethods();
  logProgress();
}

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('multiselect', Multiselect);
Vue.component('VueSlider', VueSlider);

library.add(
  faUserSecret,
  faHome,
  faCalendarAlt,
  faShoppingBasket,
  faUserCog,
  faListAlt,
  faEnvelope,
  faCog,
  faUserCircle,
  faQuestion,
  faVial,
  faChevronRight,
  faPlus,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faFilter,
  faSort,
  faPlusSquare,
  faMinusSquare,
  faTrashAlt,
  faHandshake,
  faFilePowerpoint,
  faTimes,
  faCheck,
  faSignature,
  faEdit,
  faEye,
  faHistory,
  faRedoAlt,
  faShieldAlt,
  faPlayCircle,
  faSignOutAlt,
  faPowerOff,
  faTable,
  faWindowMaximize,
  faWindowMinimize,
  faTools,
  faExclamationTriangle,
  faExclamationCircle,
  faSave,
  faFilePdf,
  faClock,
  faThumbtack,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faCar
);

Vue.prototype.$sync = function (key: string, value: any) {
  console.log('Sync key', key, 'value', value);
  this.$emit(`update:${key}`, value);
};

filters.forEach(f => {
  Vue.filter(f.name, f.execute);
});

Vue.prototype.window = window;
Vue.prototype.events = new Vue();

smoothscroll.polyfill();

console.log('Deps loaded');
