import { User } from "@/types";
import userModule from "@/store/modules/user";
import { Component, Vue } from "vue-property-decorator";
import dictModule from "@/store/modules/dict";
import moment from "moment";
import { DATE_FORMAT } from "@/consts";
import users from "@/store/modules/users";

@Component
export default class DashboardMixin extends Vue {
  get user() {
    return userModule.user;
  }
  get start() {
    console.log("%c---------- Start of template -----------", "color:green;font-weight:600");
    return "";
  }
  get end() {
    console.log("%c---------- End of template -----------", "color:green;font-weight:600");
    return "";
  }
}
