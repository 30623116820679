













































































































































































































































































































































































































































































































import { Component, Prop, Vue, PropSync, Ref, Watch } from 'vue-property-decorator';
import VueHtml2pdf from 'vue-html2pdf';
import ExamCardHeader from '../../../components/spec/ExamCardHeader.vue';
import Footer from '../../../components/dummy/Footer.vue';
import userModule from '../../../store/modules/user';
import usersModule from '../../../store/modules/users';
import examsModule from '../../../store/modules/exams';
import examModule from '../../../store/modules/exam';
import {
  User,
  Visit,
  Exam,
  Protocol,
  ExamStage,
  DestinyGroup,
  ExamStageParam,
  Sample,
  Collect,
  Mixer,
  Day,
  Week,
  CollectAndExamProtocol,
  ExamSampleStageParam,
  ConcreteClassSubgroup,
  Order,
} from '../../../types';
import * as consts from '../../../consts';
import moment from 'moment';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import dictModule from '../../../store/modules/dict';
import samplesModule from '../../../store/modules/samples';
import collectsModule from '../../../store/modules/collects';
import mixersModule from '../../../store/modules/mixers';
import protocolsModule from '../../../store/modules/protocols';
import visitsModule from '../../../store/modules/visits';
import ordersModule from '../../../store/modules/orders';
import offersModule from '../../../store/modules/offer';
import resultsDocModule from '@/store/modules/results_doc';
import * as helpers from '../../../helpers';
import methodsModule from '../../../store/modules/methods';
import ModalConfig from '../../../components/spec/ModalResultsDocConfig.vue';
import { create, all } from 'mathjs';
import { compareNumbers } from '@fullcalendar/vue';

const math = create(all);

const enum viewMode {
  FORM,
  PRINT,
}

@Component({
  components: { ExamCardHeader, Footer, VueHtml2pdf, ModalConfig },
})
export default class ExamCardW extends Vue {
  @Ref() readonly protocolpdf: VueHtml2pdf;
  @Ref() readonly config: ModalConfig;

  private currentMonth = 1;
  private currentYear = 2020;
  private currentWeek = 1;
  private currentDay = 1;
  currDate: moment.Moment = moment();
  currStageIndex = 1;
  exam_id = -1;
  exam = null;
  isAuthorised = false;
  examStages: ExamStage[] = [];
  examStageParams: ExamStageParam[] = [];
  examSampleStageParams: ExamSampleStageParam[] = [];
  ExamStatus = consts.ExamStatus;

  viewModePrint = viewMode.PRINT;
  viewModeForm = viewMode.FORM;
  mode: viewMode = viewMode.FORM;

  pdfClickKey = 0;
  closeStageClickKey = 0;
  saveExamClickKey = 0;

  authModalSpin = false;

  get currentMonthTitle() {
    return consts.Months[this.currentMonth];
  }

  get getResult() {
    return (symbol: string, sample: Sample): string => {
      const param = this.examStageParams.find(p => p.symbol === symbol);
      return this.examSampleStageParams.find(sp => sp.param_id === param.id && sp.sample_id === sample.id).value;
    };
  }

  get getParamValue() {
    return (param: ExamStageParam, sample: Sample): string => {
      return this.examSampleStageParams.find(sp => sp.param_id === param.id && sp.sample_id === sample.id).value;
    };
  }
  get paramStage() {
    return (param: ExamStageParam) => {
      return this.examStages.find(es => es.id === param.exam_stage_id);
    };
  }
  get params() {
    return (stage: ExamStage) => {
      return examModule.examStageParams.filter(p => p.exam_stage_id === stage.id && p.symbol !== 'OZNACZENIE');
    };
  }
  get samples() {
    return samplesModule.samples.filter(s => s.exam_id === this.exam.id);
  }
  get getSampleAge() {
    return (sample: Sample) => {
      const date = visitsModule.visitById(this.getProtocolOfSample(sample).visit_id).date;
      const visit_date = moment(date);
      return moment().diff(visit_date, 'days');
    };
  }
  get concreteClass() {
    return (groupSymbol: string) => {
      const group = dictModule.groupClassBySymbol(groupSymbol);
      console.log('concreteClass ', groupSymbol, ' group=', group);
      const classCId = this.exam.protocol.class_ids.find(id => dictModule.concreteClassById(id).group_id === group.id);
      if (classCId) {
        return dictModule.concreteClassById(classCId).name;
      } else {
        return '';
      }
    };
  }
  get methods() {
    return methodsModule.allMethods.filter(m => m.default_destiny_group_id === this.destinyGroup.id);
  }
  get destinyGroup() {
    console.log('dest', this.exam.destiny_id, dictModule.destinies);
    const destiny = dictModule.destinyById(this.exam.destiny_id);
    const group: DestinyGroup = dictModule.destinyGroups.find(d => d.id === destiny.group_id);
    return group;
  }
  get getRecipie() {
    return this.exam.protocol.recipie_no;
  }
  get getMainClass() {
    const class_ids = this.exam.protocol.class_ids;
    if (class_ids.length > 0) {
      return dictModule.concreteClassById(class_ids[0]).name;
    } else {
      return '';
    }
  }

  get collect() {
    return (sample: Sample): Collect => {
      return collectsModule.collectById(sample.collect_id);
    };
  }

  get mixer() {
    return (sample: Sample): Mixer => {
      return mixersModule.mixerById(this.collect(sample).mixer_id);
    };
  }

  get getCPlaceName() {
    return dictModule.cPlaceById(this.visit.cplace_id).name;
  }
  get stage() {
    return this.examStages.find(s => s.id === this.exam.stage_id);
  }
  get getCurrentStage() {
    return this.examStages.find(s => s.index === this.currStageIndex);
  }

  get user() {
    return (id: number): User => {
      return usersModule.userById(id);
    };
  }
  get visit() {
    return visitsModule.visitById(this.exam.protocol.visit_id);
  }
  get getSampleTakeDate() {
    return this.visit.date.slice(0, 10);
  }
  get getTakeDate() {
    return moment(this.visit.date).format('DD.MM.YYYY');
  }
  get getTakeDates() {
    const samples = this.samples;
    const dates = [];
    for (let s = 0; s < samples.length; s++) {
      const date = visitsModule.visitById(this.getProtocolOfSample(samples[s]).visit_id).date;
      const visit_date = moment(date).format('DD.MM.YYYY');
      if (!dates.includes(visit_date)) {
        dates.push(visit_date);
      }
    }
    return dates;
  }
  get client() {
    return dictModule.clientById(this.exam.client_id);
  }
  get contract() {
    if (this.order.contract_id === null) {
      return null;
    } else {
      return offersModule.contractById(this.order.contract_id);
    }
  }
  get order() {
    return ordersModule.orderById(this.visit.order_id);
  }
  get isLastStage() {
    return this.stage.index === this.examStages.length;
  }
  get averageSigns() {
    let soaked = 0;
    for (let s = 0; s < this.samples.length; s++) {
      const sign = this.getResult('OZNAKI', this.samples[s]);
      if (sign === 'próbka przesiąknęła przy ciśnieniu' || sign === 'przesiąknęła przy ciśnieniu') {
        soaked++;
      } else {
        soaked--;
      }
    }
    if (soaked >= 0) {
      return 'przesiąknęła przy ciśnieniu';
    } else {
      return 'nie przesiąknęła';
    }
  }
  get averagePenetration() {
    let sum = 0;
    for (let s = 0; s < this.samples.length; s++) {
      sum += parseInt(this.getResult('GLEBOKOSC', this.samples[s]));
    }
    return sum / this.samples.length;
  }
  get getProtocolOfSample() {
    return (sample: Sample) => {
      const collect_id = sample.collect_id;
      const collect = collectsModule.collectById(collect_id);
      const mixer = mixersModule.mixerById(collect.mixer_id);
      const protocol_id = mixer.protocol_id;
      const protocolPiB = protocolsModule.protocols.find(
        el => el.id === protocol_id && el.type_id === consts.ProtocolType.PIB
      );
      return protocolPiB;
    };
  }
  get canBeAuthorised(): boolean {
    return (
      this.mode === this.viewModePrint &&
      this.isLastStage === true &&
      userModule.hasRightBySymbolInAllRights(consts.UserRightSymbols.EXAM_AUTHORISE) === true
    );
  }
  /*************************************************************************************************/

  async saveExam() {
    this.exam['stages'] = [];
    for (let s = 0; s < this.examStages.length; s++) {
      const stage = this.examStages[s];
      stage['exam_stage_params'] = [];
      for (let sp = 0; sp < this.examStageParams.length; sp++) {
        const stageParam: ExamStageParam = this.examStageParams[sp];
        if (stageParam.exam_stage_id === stage.id) {
          stageParam['sample_params'] = [];
          for (let essp = 0; essp < this.examSampleStageParams.length; essp++) {
            const essparam: ExamSampleStageParam = this.examSampleStageParams[essp];
            if (essparam.param_id === stageParam.id) {
              stageParam['sample_params'].push(essparam);
            }
          }
          stage['exam_stage_params'].push(stageParam);
        }
      }
      this.exam['stages'].push(stage);
    }

    return await examsModule.updateExam(this.exam);
  }

  async closeStageClick() {
    console.log('closing Stage', this.stage.name, this.currStageIndex);
    if (this.currStageIndex === this.examStages.length) {
      this.exam.status = consts.ExamStatus.FINISHED.id;
    } else {
      this.exam.stage_id = this.examStages.find(es => es.index === this.currStageIndex + 1).id;
      console.log('Next stage:', this.exam.stage_id);
    }
    const response = await this.saveExam();
    if (response.data.success) {
      if (this.currStageIndex !== this.examStages.length) {
        helpers.info('Etap zamknięty');
        this.currStageIndex++;
        this.closeStageClickKey++;
      } else {
        helpers.info('Karta zamknięta');
      }
    } else {
      helpers.error('Błąd przy zamykaniu karty !');
    }
    if (this.currStageIndex === 4) {
      this.examStageParams.forEach(esp => {
        if (esp.symbol === 'DATA_ROZPOCZECIA') {
          this.examSampleStageParams.forEach(essp => {
            if (essp.param_id === esp.id) {
              essp.value = this.currDate.format('DD.MM.YYYY');
            }
          });
        }
      });
    } else if (this.currStageIndex === 5) {
      this.examStageParams.forEach(esp => {
        if (esp.symbol === 'DATA_ZAKONCZENIA') {
          this.examSampleStageParams.forEach(essp => {
            if (essp.param_id === esp.id) {
              essp.value = this.currDate.format('DD.MM.YYYY');
            }
          });
        }
      });
    }
  }

  async saveExamClick() {
    console.log('saveExam');

    const response = await this.saveExam();
    let examRef = examsModule.examById(this.exam.id);
    examRef = Object.assign({}, this.exam);

    let examsParams = examsModule.examSampleStageParams.filter(param =>
      examRef.samples.find(el => el.id === param.sample_id)
    );

    examsParams = examsParams.sort((a, b) => a.id - b.id);
    const sortedParams = this.examSampleStageParams.sort((a, b) => a.id - b.id);

    examsParams.forEach((el, index) => {
      el.value = sortedParams[index].value;
    });
    if (response.data.success) {
      helpers.info('Karta zapisana');
    } else {
      helpers.error('Błąd przy zapisie karty !');
    }
  }
  pdf() {
    this.protocolpdf.generatePdf();
    this.pdfClickKey++;
  }

  quitToCalendarClick() {
    this.saveExamClick();
    this.$router.push({ name: 'calendarexamsmonth' });
  }
  /*************************************************************************************************/

  nextStage() {
    if (this.currStageIndex < this.examStages.length) {
      this.currStageIndex++;
    }
  }
  prevStage() {
    if (this.currStageIndex > 1) {
      this.currStageIndex--;
    }
    console.log('ESSP', this.examStageParams);
  }
  async authorise() {
    this.$emit('authorise', { examCard: this, vueHtml2pdf: this.protocolpdf });
  }
  updateByFormula(sampleParam: ExamSampleStageParam, stageParam: ExamStageParam, sample: Sample, value: string) {
    console.log('updateByFormula sampleParam', sampleParam, 'stageParam', stageParam, 'sample', sample, 'value', value);
    const symbol = stageParam.symbol;
    const symbolValues = {};
    const thisSampleParams = this.examSampleStageParams.filter(essp => essp.sample_id === sample.id);
    console.log('This sample params ', thisSampleParams);
    thisSampleParams.forEach(sap => {
      console.log('gathering sample params', sap);
      const stageParam = this.examStageParams.find(sp => sp.id === sap.param_id);
      symbolValues['$' + stageParam.symbol] = sap.value;
      console.log('Added symbol', stageParam.symbol, sap.value);
    });
    console.log('symbolValues', symbolValues);
    const stageParamsToChange = this.examStageParams.filter(sp => {
      console.log('sp.formula=', sp.formula, 'symbol', symbol);
      return sp.formula.includes(symbol);
    });
    console.log('stageParamsToChange', stageParamsToChange);
    stageParamsToChange.forEach(sp => {
      console.log('sp to eval', sp);
      const sampleParam = this.examSampleStageParams.find(
        essp => essp.sample_id === sample.id && essp.param_id === sp.id
      );
      console.log('sampleParam value evaluating', sp.formula, sampleParam, symbolValues, ' BEFORE=', sampleParam.value);
      const val = math.evaluate(sp.formula, symbolValues);
      sampleParam.value = math.round(val, 1);
      console.log('sampleParam value AFTER=', sampleParam.value);
    });
  }

  changeParamValue(e: any, stageParam: ExamStageParam, sample: Sample): any {
    console.log('Change params value', e.target.value, stageParam, sample);
    const p = this.examSampleStageParams.find(p => p.param_id === stageParam.id && p.sample_id === sample.id);
    p.value = e.target.value;
    this.updateByFormula(p, stageParam, sample, p.value);
    this.updateExamSampleStageParams();
  }
  getCurrentDate() {
    return moment({ year: this.currentYear, month: this.currentMonth, day: this.currentDay });
  }
  get currentDayTitle() {
    return moment(this.currentDay + '.' + this.currentMonth + '.' + this.currentYear, consts.DATE_FORMAT).format(
      consts.DATE_FORMAT
    );
  }
  goMonth() {
    console.log('');
    this.$router.push({ name: 'calendarexamsmonth', params: { day: this.currDate.format(consts.DATE_FORMAT) } });
  }
  printView() {
    this.mode = viewMode.PRINT;
  }
  formView() {
    this.mode = viewMode.FORM;
  }
  showAuthModal() {
    this.$bvModal.show('auth-modal');
  }
  hideAuthModal() {
    this.$bvModal.hide('auth-modal');
    this.authModalSpin = false;
  }
  showModalConfig() {
    this.config.showModal(this.exam);
  }
  modalConfigOK(params: any) {
    this.exam.results_doc = params.results_doc;
    params.samples.forEach(s => {
      this.exam.samples.find(sam => sam.id === s.id).uw = s.uw;
    });
  }
  updateExam() {
    examModule.setExam(this.exam);
  }
  updateExamStages() {
    examModule.setExamStages(this.examStages);
  }
  updateExamStageParams() {
    examModule.setExamStageParams(this.examStageParams);
  }
  updateExamSampleStageParams() {
    examModule.setExamSampleStageParams(this.examSampleStageParams);
  }
  setCurrentDate(dateString: string) {
    this.currDate = moment(dateString, 'DD_MM_YYYY');
    this.currentYear = this.currDate.year();
    this.currentWeek = this.currDate.week();
    this.currentDay = this.currDate.date();
    this.currentMonth = this.currDate.month();
    console.log('data:', this.currentDay, '.', this.currentMonth, '.', this.currentYear, ' week:', this.currentWeek);
  }
  created() {
    console.log('CREATED', this.$route.params);
    moment.updateLocale('pl', { week: { dow: 1 } });
    const strDate = this.$route.params['date'];
    console.log('dateString', strDate);
    if (strDate !== undefined) {
      this.setCurrentDate(strDate);
    } else {
      this.setCurrentDate('01.01.2020');
    }

    this.exam_id = parseInt(this.$route.params['exam_id']);
    console.log('EXAM id', this.exam_id);

    // COPY
    this.exam = examsModule.examCopyById(this.exam_id);
    this.updateExam();
    this.examStages = [];
    this.examStageParams = [];
    examsModule.examStages.forEach(es => {
      if (es.exam_id === this.exam.id) {
        this.examStages.push(examsModule.examStageCopyById(es.id));
        console.log('Stage ', es.name);
        examsModule.examStageParams.forEach(esp => {
          if (esp.exam_stage_id === es.id) {
            console.log('OK', esp.id, esp.name, esp.symbol);
            this.examStageParams.push(examsModule.examStageParamCopyById(esp.id));
            examsModule.examSampleStageParams.forEach(essp => {
              if (essp.param_id === esp.id) {
                console.log('ESSP', essp.value, essp.param);
                this.examSampleStageParams.push(Object.assign(new ExamSampleStageParam(), essp));
              }
            });
          }
        });
      }
    });

    this.currStageIndex = this.stage.index;

    console.log('Exam', this.exam);
    this.updateExamStages();
    this.updateExamStageParams();
    this.updateExamSampleStageParams();

    this.exam.load();

    if (this.exam.results_doc === undefined) {
      this.exam.results_doc = helpers.getEmptyResultsDoc(
        dictModule.departments[0],
        consts.ResultsDocType.FROM_KPP,
        this.exam.client_id,
        [this.exam]
      );
      this.exam.results_doc.exam_id = this.exam_id;
    }

    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Kalendarz badań - miesiąc',
          link: 'ce/cem',
        },
        {
          title: 'Karta pierwszego pomiaru - badanie nr ' + this.exam.number,
          link: '',
        },
      ],
    });
    const ssmp: Sample[] = samplesModule.samples.filter(s => s.exam_id === this.exam.id);
  }
}
