








































































































































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

import dataModule from '@/store/modules/data';
import * as consts from '@/consts';
import { Message } from '@/types';

enum IsMessageRead {
  NONE,
  READ,
  NOT_READ,
}

@Component
export default class ModalMessages extends Vue {
  IsMessageRead = IsMessageRead;
  MessageGroup = consts.MessageGroup;
  show = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  groupItemSelected = this.groups[0];
  typeItemSelected = this.types[0];

  messageRead = IsMessageRead.NONE;

  get messages() {
    let messages: Message[] = [];
    switch (this.messageRead) {
      case IsMessageRead.NONE:
        messages = dataModule.allMessages;
        break;
      case IsMessageRead.READ:
        messages = dataModule.allMessages.filter(m => m.is_read === true);
        break;
      case IsMessageRead.NOT_READ:
        messages = dataModule.allMessages.filter(m => m.is_read === false);
        break;
      default:
        break;
    }
    if (this.groupItemSelected.id !== consts.NEW_ID_START) {
      messages = messages.filter(m => m.group_id === this.groupItemSelected.id);
    }
    if (this.typeItemSelected.id !== consts.NEW_ID_START) {
      messages = messages.filter(m => m.message_type === this.typeItemSelected.id);
    }

    return messages;
  }

  get typeIcon() {
    return (type: consts.MessageType) => {
      if (type === consts.MessageType.INFO) {
        return 'envelope';
      } else if (type === consts.MessageType.WARNING) {
        return 'exclamation-triangle';
      } else {
        return 'exclamation-circle';
      }
    };
  }
  get typeClass() {
    return (type: consts.MessageType) => {
      if (type === consts.MessageType.INFO) {
        return 'info';
      } else if (type === consts.MessageType.WARNING) {
        return 'warning';
      } else {
        return 'danger';
      }
    };
  }

  get typeSel() {
    return this.typeItemSelected;
  }
  get types() {
    return [
      {
        id: consts.NEW_ID_START,
        name: 'Wszystkie',
      },
      {
        id: 0,
        name: 'Komunikat',
      },
      {
        id: 1,
        name: 'Ostrzeżenie',
      },
      {
        id: 2,
        name: 'Alarm',
      },
    ];
  }
  typeSelected(event) {
    this.typeItemSelected = event;
  }

  get groupSel() {
    return this.groupItemSelected;
  }

  get groups() {
    return [
      {
        id: consts.NEW_ID_START,
        name: 'wszystkie',
      },
      {
        id: consts.MessageGroup.ACTION.id,
        name: consts.MessageGroup.ACTION.name,
      },
      {
        id: consts.MessageGroup.ORDER.id,
        name: consts.MessageGroup.ORDER.name,
      },
      {
        id: consts.MessageGroup.VISIT_AUTHORISE.id,
        name: consts.MessageGroup.VISIT_AUTHORISE.name,
      },
    ];
  }
  get groupName() {
    return (msg: Message) => {
      return consts.MessageGroup.byId(msg.group_id).name;
    };
  }
  groupSelected(event) {
    this.groupItemSelected = event;
  }

  getFormattedDate(date: string) {
    return moment(date, consts.DB_T_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
  }

  allMessagesClick() {
    this.messageRead = IsMessageRead.NONE;
  }
  readMessagesClick() {
    this.messageRead = IsMessageRead.READ;
  }
  notReadMessagesClick() {
    this.messageRead = IsMessageRead.NOT_READ;
  }
  clear() {
    console.log('clear');
  }

  showModal() {
    this.show = true;
  }

  saveModal() {
    this.$emit(consts.ModalEvents.OK_EVENT);
    this.show = false;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
  changeRead(event: any, message: Message) {
    //dataModule.changeMessageRead(message.id);
    dataModule.readMessage(message.id);
  }
  mounted() {
    this.messageRead = IsMessageRead.NONE;
  }
}
