console.log('--- CONSTS FILE LOADING ---');
import { Enumify } from 'enumify';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DB_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DB_T_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DB_T_S_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DB_T_S_MS_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sss';
export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';

export const NEW_ID_START = 100000;
export const TEST_ID_FIRST = 200000;
export const TEST_ID_SECOND = 200001;
export const TEST_ID_THIRD = 200002;

export const TEMP_SERVER_ID = 500000;
export const CLIENT_PRODUCER = 1;

export const MIN_SAMPLE_AGE_FOR_COMPLIANCE = 28;

export default interface EnumComplex {
  id: number;
  name: string;
  symbol?: string;
}

export const Months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];

export enum ModalEvents {
  CANCEL_EVENT = 'modal_cancel',
  OK_EVENT = 'modal_ok',
  TIME_CHANGED_EVENT = 'time_changed',
}

export enum PageMode {
  PREVIEW = 'preview',
  NEW = 'new',
  EDIT = 'edit',
}

export enum CalendarViewType {
  DAY = 'day',
  MONTH = 'month',
  PERSON_DAY = 'person_day',
  VISIT = 'visit',
}

export enum ClientType {
  PRODUCER = 1,
  RECEIVER = 2, // TO-DO lepsze słowo na Odbiorce
}

export class OrderStatus extends Enumify {
  static NEW = new OrderStatus(0, 'NOWE', 'NEW');
  static ORDERED = new OrderStatus(1, 'ZŁOŻONE', 'ORD');
  static PENDING = new OrderStatus(2, 'W TRAKCIE', 'PEN');
  static FINISHED = new OrderStatus(3, 'WYKONANE', 'DON');
  static CANCELED = new OrderStatus(4, 'ANULOWANE', 'CNL');
  static _ = OrderStatus.closeEnum();

  private static array = [
    OrderStatus.NEW,
    OrderStatus.ORDERED,
    OrderStatus.PENDING,
    OrderStatus.FINISHED,
    OrderStatus.CANCELED,
  ];
  id: number;
  name: string;
  symbol: string;

  constructor(id: number, name: string, symbol: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
  }

  public static byId(id: number): OrderStatus {
    return this.array[id];
  }
}

export class VisitStatus extends Enumify {
  static PLANNED = new VisitStatus(0, 'ZAPLANOWANA', 'PLN', 'light');
  static PENDING = new VisitStatus(1, 'W TRAKCIE', 'PEN', 'info');
  static CORRECT = new VisitStatus(2, 'W KOREKCIE', 'CRT', 'warning');
  static CLOSED = new VisitStatus(3, 'ZAMKNIĘTA', 'CLS', 'secondary');
  static VERIFY = new VisitStatus(4, 'SPRAWDZENIE', 'VER', 'primary');
  static VERIFIED = new VisitStatus(5, 'SPRAWDZONA', 'VRD', 'success');
  static CANCELED = new VisitStatus(6, 'ANULOWANA', 'CNL', 'dark');
  static _ = VisitStatus.closeEnum();

  private static array = [
    VisitStatus.PLANNED,
    VisitStatus.PENDING,
    VisitStatus.CORRECT,
    VisitStatus.CLOSED,
    VisitStatus.VERIFY,
    VisitStatus.VERIFIED,
    VisitStatus.CANCELED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): VisitStatus {
    return this.array[id];
  }
}
export const VisitToOrderStatusMap = {
  [VisitStatus.PLANNED.id]: OrderStatus.ORDERED,
  [VisitStatus.PENDING.id]: OrderStatus.PENDING,
  [VisitStatus.CORRECT.id]: OrderStatus.PENDING,
  [VisitStatus.CLOSED.id]: OrderStatus.FINISHED,
  [VisitStatus.VERIFY.id]: OrderStatus.FINISHED,
  [VisitStatus.VERIFIED.id]: OrderStatus.FINISHED,
  [VisitStatus.CANCELED.id]: OrderStatus.CANCELED,
};
export enum VisitStatusAuthorised {
  NAME = 'ZAUTORYZOWANA',
  BADGE = 'danger',
}

export enum ViewModes {
  PRINT = 'PRINT',
  FORM = 'FORM',
}

export enum VisitType {
  MAIN = 1, // pobranie i badanie
  CTB = 2, // Odebranie uformowanych
  COLLECT = 3, // Odebranie - klient uformowal
  CERT = 4, // certyfikacja
}

export enum VisitViewType {
  MAIN = 'main',
  PROTOCOL = 'protocol',
  MIXER = 'mixer',
  COLLECT = 'collect',
  SAMPLE_LIST = 'sample-list',
}

export enum DictType {
  CLIENTS,
  CONSTR_PLACES,
  LOCALIZATIONS,
  DESTINY,
  DESTINY_GROUP,
  MATERIAL,
  CONCRETE_CLASS_GROUPS,
  CONCRETE_CLASS_SUBGROUP,
  CONCRETE_CLASS,
  METHOD,
}

export enum OfferSettlements {
  LUMP_SUM = 'RYCZ',
  FIX = 'FIX',
  MANY_TESTS = 'MANY',
  SPEC_TESTS = 'SPEC',
  CLIENT_PROTOCOL = 'CLI_PROTO',
}

export enum SortDir {
  ASC,
  DESC,
}

export const INITIAL_SORT_FIELD = 'id';
export const LABORANT_ROUTE_PREFIX = '/laborant';

export enum ExamType {
  CONCRETE,
  GEO,
  CERT,
}

export enum UserModule {
  LABORANT = 1,
  CLIENT = 2,
  ADMIN = 3,
}

export enum UserRightSymbols {
  VISIT_START = 'VISIT_START',
  VISIT_VERIFY = 'VISIT_VERIFY',
  VISIT_PREVIEW = 'VISIT_PREVIEW',
  VISIT_COORD = 'VISIT_COORD',
  VISIT_START_SIGN = 'VISIT_START_SIGN',
  VISIT_AUTHORISE = 'VISIT_AUTHORISE',
  VISIT_REVERT = 'VISIT_REVERT',
  VISIT_EDIT = 'VISIT_EDIT',
  VISIT_OPEN_AGAIN = 'VISIT_OPEN_AGAIN',
  VISIT_MAKE_CORRECT = 'VISIT_MAKE_CORRECT',
  VISIT_CANCEL = 'VISIT_CANCEL',
  VISIT_TO_CORRECT = 'VISIT_TO_CORRECT',
  VISIT_ACCEPT = 'VISIT_ACCEPT',

  EXAM_START = 'EXAM_START',
  EXAM_AUTHORISE = 'EXAM_AUTHORISE',
  EXAM_COORD = 'EXAM_COORD',
  EXAM_SAVE = 'EXAM_SAVE',
  EXAM_RECORD_GENERATE = 'EXAM_RECORD_GENERATE',

  TOOL_DASHBOARD_INIT = 'TOOL_DASHBOARD_INIT',
  TOOL_DASHBOARD_PREVIEW = 'TOOL_DASHBOARD_PREVIEW',
  TOOL_DASHBOARD_PROCESS = 'TOOL_DASHBOARD_PROCESS',
  TOOL_DASHBOARD_IN_PROGRESS = 'TOOL_DASHBOARD_IN_PROGRESS',
  TOOL_DASHBOARD_ACCEPT = 'TOOL_DASHBOARD_ACCEPT',

  METHOD_DASHBOARD_INIT = 'METHOD_DASHBOARD_INIT',
  METHOD_DASHBOARD_PREVIEW = 'METHOD_DASHBOARD_PREVIEW',
  METHOD_DASHBOARD_PROCESS = 'METHOD_DASHBOARD_PROCESS',
  METHOD_DASHBOARD_IN_PROGRESS = 'METHOD_DASHBOARD_IN_PROGRESS',
  METHOD_DASHBOARD_ACCEPT = 'METHOD_DASHBOARD_ACCEPT',

  PERSONEL_DASHBOARD_INIT = 'PERSONEL_DASHBOARD_INIT',
  PERSONEL_DASHBOARD_PREVIEW = 'PERSONEL_DASHBOARD_PREVIEW',
  PERSONEL_DASHBOARD_PROCESS = 'PERSONEL_DASHBOARD_PROCESS',
  PERSONEL_DASHBOARD_IN_PROGRESS = 'PERSONEL_DASHBOARD_IN_PROGRESS',
  PERSONEL_DASHBOARD_ACCEPT = 'PERSONEL_DASHBOARD_ACCEPT',

  CERT_DASHBOARD_PROCESS = 'CERT_DASHBOARD_PROCESS',
  CERT_DASHBOARD_IN_PROGRESS = 'CERT_DASHBOARD_IN_PROGRESS',
  CERT_PREVIEW = 'CERT_PREVIEW',

  OFFER_DASHBOARD_PROCESS = 'OFFER_DASHBOARD_PROCESS',
  OFFER_DASHBOARD_IN_PROGRESS = 'OFFER_DASHBOARD_IN_PROGRESS',
  OFFER_DASHBOARD_PREVIEW = 'OFFER_DASHBOARD_PREVIEW',

  TRAINING_START = 'TRAINING_START',

  EXAM_PROCESS_GRANT = 'EXAM_PROCESS_GRANT',

  ORDER_EDIT = 'ORDER_EDIT',
  ORDER_DELETE = 'ORDER_DELETE',
}

export enum UserGroupSymbols {
  FIELD_WORKER = 'FIELD_WORKER',
  LABORANT = 'LABORANT',
  ORDER_EDIT = 'ORDER_EDIT',
  REGION_COORD = 'REGION_COORD',
  FIELD_COORD = 'FIELD_COORD',
  LAB_COORD = 'LAB_COORD',
  LAB_MANAGER = 'LAB_MANAGER',
  TECH_MANAGER = 'TECH_MANAGER',
  VISIT_VERIF = 'VISIT_VERIF',
  RESULTS_AUTH = 'RESULTS_AUTH',
  EXAM_GEN = 'EXAM_GEN',
  OFFER = 'OFFER',
  ACCOUNTING_SPEC = 'ACCOUNTING_SPEC',
  INSPECTOR = 'INSPECTOR',
  CERTIF_DIR = 'CERTIF_DIR',
  QUALITY_DIR = 'QUALITY_DIR',
  MANAGER = 'MANAGER',
}

export enum ProtocolType {
  PIB = 1, // pobrania i badania
  COLLECT_CTB = 2, // odebranie probek uformowanych
  COLLECT = 3, // odebrania probek
}
export enum ProtocolTypeString {
  PIB = 'pib', // pobrania i badania
  COLLECT_CTB = 'ctb', // odebranie probek uformowanych
  COLLECT = 'collect', // odebrania probek
}

export enum TakeExamPlaces {
  BATCHING_PLANT = 'W - węzeł betoniarski', // Węzeł betoniarski
  CONSTRUCTION_SITE = 'B - budowa', // Budowa
}

export enum ConsistencyNorms {
  S = '12350-2',
  F = '12350-5',
  SF = '12350-8',
}

export enum ProtocolNorms {
  TAKE_SAMPLE = '12350-1',
  MIXTURE_DENSITY = '12350-6',
  MIXTURE_AIR_CONTENT = '1250-7',
}

export enum MakeSampleNorms {
  C = '12350-2',
  ALL = 'PN-88',
}

export enum MethodNorm {
  STRENGTH = '12390-3',
}

export class CollectExamType extends Enumify {
  static S = new CollectExamType(1, 'Klasa konsystencji S', 'S', 'Opad stożka', 'PN-EN ' + ConsistencyNorms.S);
  static F = new CollectExamType(
    2,
    'Klasa konsystencji F (rozpływ)',
    'F',
    'Metoda stolika rozpływowego',
    'PN-EN ' + ConsistencyNorms.F
  );
  static SF = new CollectExamType(
    3,
    'Klasa konsystencji SF, lepkości VS',
    'SF',
    'Rozpływ stożka i czas rozplywu',
    'PN-EN ' + ConsistencyNorms.SF
  );

  static _ = CollectExamType.closeEnum();

  private static array = [CollectExamType.S, CollectExamType.F, CollectExamType.SF];
  id: number;
  name: string;
  symbol: string;
  method_name: string;
  norm: string;

  constructor(id: number, name: string, symbol: string, method_name: string, norm: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.method_name = method_name;
    this.norm = norm;
  }

  public static byId(id: number): CollectExamType {
    return this.array.find(e => e.id === id);
  }
}

export enum GroupClassSymbols {
  COMPRESSIVE_STREGTH = 'C',
  COMPRESSIVE_STREGTH_LIGHT = 'LC',
  CONSISTENCY = 'S',
  CONSISTENCY_CONE = 'SF',
  CONSISTENCY_TABLE = 'F(rozpływ)',
  CHLORIDE = 'Cl',
  EXPOSURE = 'X',
  VISCOSITY = 'VF',
  FROZEN = 'F',
  WATER = 'W',
}

export enum DestinyGroupCodes {
  COMPRESSIVE_STREGTH = 'C',
  FROZEN = 'F',
  WATER = 'W',
  PROBE = 'SW',
  MIXTURE = 'M',
}

export enum ExamComplience {
  Z = 'Zgodny',
  NZ = 'Niezgodny',
}

export enum SlumpTestType {
  OK = 'ŚCIĘTY',
  NOK = 'WŁAŚCIWY',
}

export enum SignatureType {
  VISIT,
  PROTOCOL,
}
export enum ModuleType {
  LAB = 1,
  CLIENT = 2,
  ADMIN = 3, //to pewnie trzeba bedzie zmienic
}

/*************************************** ACTION STATUSES ********************************/

export class ToolActionStatus extends Enumify {
  static NEW = new ToolActionStatus(0, 'NOWE', 'NEW', 'primary');
  static INITIAL = new ToolActionStatus(1, 'ZAINICJOWANA', 'INI', 'info');
  static PENDING = new ToolActionStatus(2, 'W TRAKCIE', 'PEN', 'success');
  static TOACCEPT = new ToolActionStatus(3, 'AKCEPTACJA', 'ACC', 'danger');
  static DONE = new ToolActionStatus(4, 'UKOŃCZONA', 'DON', 'warning');
  static CANCELLED = new ToolActionStatus(5, 'WYCOFANA', 'CNL', 'secondary');
  static _ = ToolActionStatus.closeEnum();

  private static array = [
    ToolActionStatus.INITIAL,
    ToolActionStatus.NEW,
    ToolActionStatus.PENDING,
    ToolActionStatus.DONE,
    ToolActionStatus.CANCELLED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): ToolActionStatus {
    return this.array[id];
  }
}

export class MethodActionStatus extends Enumify {
  static NEW = new MethodActionStatus(0, 'NOWA', 'NEW', 'primary');
  static INITIAL = new MethodActionStatus(1, 'ZAINICJOWANE', 'INI', 'info');
  static PENDING = new MethodActionStatus(2, 'W TRAKCIE', 'PEN', 'success');
  static TOACCEPT = new MethodActionStatus(3, 'AKCEPTACJA', 'ACC', 'danger');
  static DONE = new MethodActionStatus(4, 'UKOŃCZONE', 'DON', 'warning');
  static CANCELLED = new MethodActionStatus(5, 'WYCOFANE', 'CNL', 'secondary');
  static _ = MethodActionStatus.closeEnum();

  private static array = [
    MethodActionStatus.INITIAL,
    MethodActionStatus.NEW,
    MethodActionStatus.PENDING,
    MethodActionStatus.DONE,
    MethodActionStatus.CANCELLED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): MethodActionStatus {
    return this.array[id];
  }
}

export class PersonelActionStatus extends Enumify {
  static NEW = new PersonelActionStatus(0, 'NOWY', 'NEW', 'primary');
  static INITIAL = new PersonelActionStatus(1, 'ZAINICJOWANE', 'INI', 'info');
  static PENDING = new PersonelActionStatus(2, 'W TRAKCIE', 'PEN', 'success');
  static TOACCEPT = new PersonelActionStatus(3, 'AKCEPTACJA', 'ACC', 'danger');
  static DONE = new PersonelActionStatus(4, 'UKOŃCZONE', 'DON', 'warning');
  static CANCELLED = new PersonelActionStatus(5, 'WYCOFANE', 'CNL', 'secondary');
  static _ = PersonelActionStatus.closeEnum();

  private static array = [
    PersonelActionStatus.INITIAL,
    PersonelActionStatus.NEW,
    PersonelActionStatus.PENDING,
    PersonelActionStatus.DONE,
    PersonelActionStatus.CANCELLED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): PersonelActionStatus {
    return this.array[id];
  }
}

export class OfferActionStatus extends Enumify {
  static REQUEST = new OfferActionStatus(1, 'ZAPYTANIA OFERTOWE', 'REQ', 'info');
  static OFFER = new OfferActionStatus(2, 'OFERTA', 'OFR', 'success');
  static TOACCEPT = new OfferActionStatus(3, 'AKCEPTACJA KLIENTA', 'ACC', 'danger');
  static CONTRACT = new OfferActionStatus(4, 'UMOWA', 'DON', 'warning');
  static CANCELLED = new OfferActionStatus(5, 'ANULOWANE', 'CNL', 'secondary');
  static _ = OfferActionStatus.closeEnum();

  private static array = [
    null, //nie ma id
    OfferActionStatus.REQUEST,
    OfferActionStatus.OFFER,
    OfferActionStatus.TOACCEPT,
    OfferActionStatus.CONTRACT,
    OfferActionStatus.CANCELLED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): OfferActionStatus {
    return this.array[id];
  }
}

export class OfferStatus extends Enumify {
  static NOT_SAVED = new OfferStatus(0, 'Nie zapisano', 'NSV', 'secondary');
  static SAVED = new OfferStatus(1, 'Zapisano', 'SAV', 'primary');
  static SENT = new OfferStatus(2, 'Wysłano', 'SNT', 'primary');
  static REJECTED = new OfferStatus(3, 'Odrzucono', 'REJ', 'danger');
  static ACCEPTED = new OfferStatus(4, 'Zaakceptowano', 'ACC', 'success');
  static _ = OfferStatus.closeEnum();
  private static array = [
    OfferStatus.NOT_SAVED,
    OfferStatus.SAVED,
    OfferStatus.SENT,
    OfferStatus.REJECTED,
    OfferStatus.ACCEPTED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): OfferStatus {
    return this.array[id];
  }
  public static len() {
    return this.array.length;
  }
}

export class CertActionStatus extends Enumify {
  static INITIAL = new CertActionStatus(0, 'ANULOWANE', 'REQ', 'info');
  static AFTER = new CertActionStatus(1, 'NOWA INSPEKCJA', 'AFT', 'success');
  static TOACCEPT = new CertActionStatus(2, 'W REALIZACJI', 'ACC', 'danger');
  static CERTYFICATE = new CertActionStatus(3, 'PRZEGLĄD/DECYZJA', 'CRT', 'warning');
  static CANCELLED = new CertActionStatus(4, 'ZAAKCEPTOWANE/CERTYFIKAT', 'CNL', 'secondary');
  static _ = CertActionStatus.closeEnum();

  private static array = [
    CertActionStatus.INITIAL,
    CertActionStatus.AFTER,
    CertActionStatus.TOACCEPT,
    CertActionStatus.CERTYFICATE,
    CertActionStatus.CANCELLED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): CertActionStatus {
    return this.array[id];
  }
}

export class DocumentActionStatus extends Enumify {
  static CANCELLED = new DocumentActionStatus(0, 'ODRZUCONY', 'CNL', 'secondary');
  static INITIATED = new DocumentActionStatus(1, 'NOWY DOKUMENT', 'PROG', 'success');
  static IN_PROGRESS = new DocumentActionStatus(2, 'DOKUMENT W EDYCJI', 'PROG', 'danger');
  static ACCEPTED = new DocumentActionStatus(3, 'AKCEPTACJA', 'ACC', 'warning');
  static FINISHED = new DocumentActionStatus(4, 'ZATWIERDZONY', 'END', 'success');
  static _ = DocumentActionStatus.closeEnum();

  private static array = [
    DocumentActionStatus.CANCELLED,
    DocumentActionStatus.INITIATED,
    DocumentActionStatus.IN_PROGRESS,
    DocumentActionStatus.ACCEPTED,
    DocumentActionStatus.FINISHED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): AuditActionStatus {
    return this.array[id];
  }
}

export class AuditActionStatus extends Enumify {
  static CANCELLED = new AuditActionStatus(0, 'WYCOFANY', 'CNL', 'secondary');
  static INITIATED = new AuditActionStatus(1, 'ZAINICJOWANY', 'PROG', 'success');
  static IN_PROGRESS = new AuditActionStatus(2, 'W TRAKCIE', 'PROG', 'danger');
  static ACCEPTED = new AuditActionStatus(3, 'AKCEPTACJA', 'ACC', 'warning');
  static FINISHED = new AuditActionStatus(4, 'ZAKOŃCZONY', 'END', 'success');
  static _ = AuditActionStatus.closeEnum();

  private static array = [
    AuditActionStatus.CANCELLED,
    AuditActionStatus.INITIATED,
    AuditActionStatus.IN_PROGRESS,
    AuditActionStatus.ACCEPTED,
    AuditActionStatus.FINISHED,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): AuditActionStatus {
    return this.array[id];
  }
}

export class SettlementActionStatus extends Enumify {
  static REQUEST = new SettlementActionStatus(1, 'WYSTAWIONE', 'REQ', 'info');
  static LAB = new SettlementActionStatus(2, 'ZATWIERDZONE PRZEZ LAB', 'LAB', 'success');
  static ACCEPTED = new SettlementActionStatus(3, 'ZAAKCEPTOWANE PRZEZ KLIENTA', 'ACC', 'danger');
  static ACCOUNT_SENT = new SettlementActionStatus(4, 'WYSŁANE DO KSIĘGOWOŚCI', 'SNT', 'warning');
  static INVOICE = new SettlementActionStatus(5, 'Z FAKTURĄ', 'INV', 'secondary');
  static _ = SettlementActionStatus.closeEnum();

  private static array = [
    SettlementActionStatus.REQUEST,
    SettlementActionStatus.LAB,
    SettlementActionStatus.ACCEPTED,
    SettlementActionStatus.ACCOUNT_SENT,
    SettlementActionStatus.INVOICE,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): SettlementActionStatus {
    return this.array[id];
  }
}
/*********************************** OPERATION TYPES **********************************/

export class ToolOperationType extends Enumify {
  static ADD = new ToolOperationType(0, 'WDROŻENIE', 'NEW', 'primary');
  static EDIT = new ToolOperationType(1, 'EDYCJA', 'EDI', 'info');
  static SERVICE = new ToolOperationType(2, 'SERWIS', 'SRV', 'success');
  static REJECT = new ToolOperationType(3, 'WYCOFANIE', 'RJC', 'danger');
  static REVERT = new ToolOperationType(4, 'PRZYWRÓCENIE', 'RVT', 'secondary');
  static _ = ToolOperationType.closeEnum();

  private static array = [
    ToolOperationType.ADD,
    ToolOperationType.EDIT,
    ToolOperationType.SERVICE,
    ToolOperationType.REJECT,
    ToolOperationType.REVERT,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): ToolOperationType {
    return this.array[id];
  }
}

export class MethodOperationType extends Enumify {
  static ADD = new MethodOperationType(0, 'WDROŻENIE', 'NEW', 'primary');
  static EDIT = new MethodOperationType(1, 'EDYCJA', 'EDI', 'info');
  static REJECT = new MethodOperationType(2, 'WYCOFANIE', 'RJC', 'success');
  static REVERT = new MethodOperationType(3, 'PRZYWRÓCENIE', 'RVT', 'secondary');
  static _ = MethodOperationType.closeEnum();

  private static array = [
    MethodOperationType.ADD,
    MethodOperationType.EDIT,
    MethodOperationType.REJECT,
    MethodOperationType.REVERT,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): MethodOperationType {
    return this.array[id];
  }
}

export class PersonelOperationType extends Enumify {
  static ADD = new PersonelOperationType(0, 'ZATRUDNIENIE', 'NEW', 'primary');
  static EDIT = new PersonelOperationType(1, 'ZMIANA DANYCH', 'EDI', 'info');
  static SUSPEND = new PersonelOperationType(2, 'ZAWIESZENIE', 'SUS', 'success');
  static TRAINING = new PersonelOperationType(3, 'SZKOLENIE', 'TRA', 'warning');
  static REJECT = new PersonelOperationType(4, 'ZWOLNIENIE', 'RJC', 'danger');
  static REVERT = new PersonelOperationType(5, 'PRZYWRÓCENIE', 'REV', 'light');
  static VACATION = new PersonelOperationType(3, 'URLOP', 'VAC', 'warning');
  static _ = PersonelOperationType.closeEnum();

  private static array = [
    PersonelOperationType.ADD,
    PersonelOperationType.EDIT,
    PersonelOperationType.SUSPEND,
    PersonelOperationType.TRAINING,
    PersonelOperationType.REJECT,
    PersonelOperationType.REVERT,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): PersonelOperationType {
    return this.array[id];
  }
}

export class OfferOperationType extends Enumify {
  static ADD = new OfferOperationType(0, 'PROCES OFERTOWY', 'NEW', 'primary');

  static _ = OfferOperationType.closeEnum();

  private static array = [OfferOperationType.ADD];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): OfferOperationType {
    return this.array[id];
  }
}
export class CertOperationType extends Enumify {
  static ADD = new CertOperationType(0, 'PROCES CERTYFIKACJI', 'NEW', 'primary');

  static _ = CertOperationType.closeEnum();

  private static array = [CertOperationType.ADD];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): CertOperationType {
    return this.array[id];
  }
}
export class SettlementOperationType extends Enumify {
  static ADD = new SettlementOperationType(0, 'PROCES CERTYFIKACJI', 'NEW', 'primary');

  static _ = SettlementOperationType.closeEnum();

  private static array = [SettlementOperationType.ADD];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): SettlementOperationType {
    return this.array[id];
  }
}
/****************************************** ENTITY STATUS  ******************************/
export class EntityStatus extends Enumify {
  get getId() {
    return -1;
  }
}

export class MethodStatus extends EntityStatus {
  static NEW = new MethodStatus(0, 'NOWA', 'NEW', 'primary', [MethodOperationType.ADD]);
  static WORKING = new MethodStatus(1, 'W UŻYCIU', 'WRK', 'info', [
    MethodOperationType.EDIT,
    MethodOperationType.REJECT,
  ]);
  static CANCELED = new MethodStatus(2, 'WYCOFANA', 'CNL', 'danger', [
    MethodOperationType.EDIT,
    MethodOperationType.REVERT,
  ]);
  static _ = MethodStatus.closeEnum();

  private static array = [MethodStatus.NEW, MethodStatus.WORKING, MethodStatus.CANCELED];
  id: number;
  name: string;
  symbol: string;
  badge: string;
  opers: MethodOperationType[];

  constructor(id: number, name: string, symbol: string, badge: string, opers: MethodOperationType[]) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
    this.opers = opers;
  }
  get getId() {
    return this.id;
  }
  public static byId(id: number): MethodStatus {
    return this.array[id];
  }

  public getDoneEntityStatus(operType: MethodOperationType) {
    console.log('getDoneStatus', this, operType);
    switch (this.id) {
      case MethodStatus.NEW.id:
        if (operType === MethodOperationType.ADD) {
          return MethodStatus.WORKING;
        }
        break;
      case MethodStatus.WORKING.id:
        if (operType === MethodOperationType.EDIT) {
          return MethodStatus.WORKING;
        } else if (operType === MethodOperationType.REJECT) {
          return MethodStatus.CANCELED;
        }
        break;
      case MethodStatus.CANCELED.id:
        if (operType === MethodOperationType.REVERT) {
          return MethodStatus.WORKING;
        }
        break;
      default:
        return this;
    }
  }
}

export class ToolStatus extends EntityStatus {
  static NEW = new ToolStatus(0, 'NOWE', 'NEW', 'primary', [ToolOperationType.ADD]);
  static WORKING = new ToolStatus(1, 'W PRACY', 'WRK', 'info', [
    ToolOperationType.SERVICE,
    ToolOperationType.EDIT,
    ToolOperationType.REJECT,
  ]);
  static SERVICE = new ToolStatus(2, 'SERVICE', 'SRV', 'secondary', [
    ToolOperationType.EDIT,
    ToolOperationType.REJECT,
    ToolOperationType.REVERT,
  ]);
  static CANCELED = new ToolStatus(3, 'WYCOFANE', 'CNL', 'danger', [ToolOperationType.EDIT, ToolOperationType.REVERT]);
  static _ = ToolStatus.closeEnum();

  private static array = [ToolStatus.NEW, ToolStatus.WORKING, ToolStatus.SERVICE, ToolStatus.CANCELED];
  id: number;
  name: string;
  symbol: string;
  badge: string;
  opers: ToolOperationType[];

  constructor(id: number, name: string, symbol: string, badge: string, opers: ToolOperationType[]) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
    this.opers = opers;
  }
  get getId() {
    return this.id;
  }

  public getDoneEntityStatus(operType: ToolOperationType) {
    console.log('getDoneStatus', this, operType);
    switch (this.id) {
      case ToolStatus.NEW.id:
        if (operType === ToolOperationType.ADD) {
          return ToolStatus.WORKING;
        }
        break;
      case ToolStatus.WORKING.id:
        if (operType === ToolOperationType.EDIT || operType === ToolOperationType.SERVICE) {
          return ToolStatus.WORKING;
        } else if (operType === ToolOperationType.REJECT) {
          return ToolStatus.CANCELED;
        }
        break;
      case ToolStatus.SERVICE.id:
        if (operType === ToolOperationType.EDIT) {
          return ToolStatus.SERVICE;
        } else if (operType === ToolOperationType.REVERT) {
          return ToolStatus.WORKING;
        } else if (operType === ToolOperationType.REJECT) {
          return ToolStatus.CANCELED;
        }
        break;
      case ToolStatus.CANCELED.id:
        if (operType === ToolOperationType.REVERT) {
          return ToolStatus.WORKING;
        }
        break;
      default:
        return this;
    }
  }

  public static byId(id: number): ToolStatus {
    return this.array[id];
  }
}

export enum DashboardObjectTypes {
  METHOD = 0,
  TOOL = 1,
  PERSONEL = 2,
  OFFER = 3,
  TRAINING = 4,
  METRO = 5,
  CERT = 6,
  AUDIT = 7,
  DOCUMENT = 8,
  SUPERVISION = 9,
}

export class UserStatus extends EntityStatus {
  static NEW = new UserStatus(0, 'NOWY', 'NEW', 'primary', [PersonelOperationType.ADD]);
  static WORKING = new UserStatus(1, 'W PRACY', 'WRK', 'info', [
    PersonelOperationType.EDIT,
    PersonelOperationType.SUSPEND,
    PersonelOperationType.TRAINING,
    PersonelOperationType.VACATION,
    PersonelOperationType.REJECT,
  ]);
  static SUSPENDED = new UserStatus(2, 'ZAWIESZONY', 'SUS', 'secondary', [
    PersonelOperationType.EDIT,
    PersonelOperationType.REJECT,
    PersonelOperationType.REVERT,
  ]);
  static FIRED = new UserStatus(3, 'ZWOLNIONY', 'CNL', 'danger', [PersonelOperationType.REVERT]);
  static _ = UserStatus.closeEnum();

  private static array = [UserStatus.NEW, UserStatus.WORKING, UserStatus.SUSPENDED, UserStatus.FIRED];
  id: number;
  name: string;
  symbol: string;
  badge: string;
  opers: PersonelOperationType[];

  constructor(id: number, name: string, symbol: string, badge: string, opers: PersonelOperationType[]) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
    this.opers = opers;
  }
  get getId() {
    return this.id;
  }

  public getDoneEntityStatus(operType: PersonelOperationType) {
    console.log('getDoneStatus', this, operType);
    switch (this.id) {
      case UserStatus.NEW.id:
        if (operType === PersonelOperationType.ADD) {
          return UserStatus.WORKING;
        }
        break;
      case UserStatus.WORKING.id:
        if (
          operType === PersonelOperationType.EDIT ||
          operType === PersonelOperationType.TRAINING ||
          operType === PersonelOperationType.VACATION
        ) {
          return UserStatus.WORKING;
        } else if (operType === PersonelOperationType.SUSPEND) {
          return UserStatus.SUSPENDED;
        } else if (operType === PersonelOperationType.REJECT) {
          return UserStatus.FIRED;
        }
        break;
      case UserStatus.SUSPENDED.id:
        if (operType === PersonelOperationType.EDIT) {
          return UserStatus.SUSPENDED;
        } else if (operType === PersonelOperationType.REJECT) {
          return UserStatus.FIRED;
        } else if (operType === PersonelOperationType.REVERT) {
          return UserStatus.WORKING;
        }
        break;
      case UserStatus.FIRED.id:
        if (operType === PersonelOperationType.REVERT) {
          return UserStatus.WORKING;
        }
        break;
      default:
        return this;
    }
  }

  public static byId(id: number): UserStatus {
    return this.array[id];
  }
}

export enum MessageSenderType {
  SYSTEM = 0,
  USER = 1,
}

export enum MessageType {
  INFO = 0,
  WARNING = 1,
  DANGER = 2,
}

export enum MessageObjectType {
  ACTION = 0,
  ORDER = 1,
  VISIT_AUTHORISED = 2,
  SUPERVISION = 3,
  RD_AUTHORISE = 4,
}

export class MessageGroup extends Enumify {
  static ACTION = new MessageGroup(0, 'Akcja', 'ACT', 'primary');
  static ORDER = new MessageGroup(1, 'Zamówienie', 'VAU', 'info');
  static VISIT_AUTHORISE = new MessageGroup(2, 'Autoryzacja wizyty', 'VAU', 'info');
  static SUPERVISION = new MessageGroup(3, 'Nadzór', 'SUP', 'info');
  static RD_AUTHORISE = new MessageGroup(4, 'Autoryzacja sprawozdania', 'RDA', 'info');
  static _ = MessageGroup.closeEnum();

  private static array = [
    MessageGroup.ACTION,
    MessageGroup.ORDER,
    MessageGroup.VISIT_AUTHORISE,
    MessageGroup.SUPERVISION,
    MessageGroup.RD_AUTHORISE,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): MessageGroup {
    return this.array[id];
  }
}

export class FileGroup extends Enumify {
  static NOT_SPECIFIED = new FileGroup(0, 'Brak grupy', 'ACT', 'primary');
  static METHOD = new FileGroup(0, 'Plik metody badawczej', 'MET', 'primary');
  static TOOL = new FileGroup(1, 'Plik urządzenia badawczego', 'TOL', 'info');
  static OFFER_ACTION = new FileGroup(2, 'Plik akcji ofertowej', 'OFA', 'primary');
  static OFFER = new FileGroup(3, 'Plik oferty', 'OFF', 'primary');
  static CONTRACT = new FileGroup(4, 'Plik oferty', 'CON', 'primary');
  static CERT_DOC = new FileGroup(5, 'Plik certyfikacji', 'CRT', 'primary');
  static DOCUMENT = new FileGroup(6, 'Dokument', 'DOC', 'primary');
  static AUDIT = new FileGroup(7, 'Audyt', 'AUD', 'primary');

  static _ = FileGroup.closeEnum();

  private static array = [
    FileGroup.METHOD,
    FileGroup.TOOL,
    FileGroup.OFFER_ACTION,
    FileGroup.OFFER,
    FileGroup.CONTRACT,
  ];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): FileGroup {
    return this.array[id];
  }
}

export class ExamStatus extends Enumify {
  static TO_ASSIGN = new ExamStatus(1, 'DO PRZYPISANIA', 'TSG', 'light');
  static ASSIGNED = new ExamStatus(2, 'PRZYPISANE', 'SGN', 'warning');
  static PENDING = new ExamStatus(3, 'BADANIE W TRAKCIE', 'PEN', 'info');
  static FINISHED = new ExamStatus(4, 'ZAKOŃCZONE', 'FIN', 'success');
  static _ = ExamStatus.closeEnum();

  private static array = [ExamStatus.TO_ASSIGN, ExamStatus.ASSIGNED, ExamStatus.PENDING, ExamStatus.FINISHED];
  id: number;
  name: string;
  symbol: string;
  badge: string;

  constructor(id: number, name: string, symbol: string, badge: string) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.badge = badge;
  }

  public static byId(id: number): ExamStatus {
    return this.array[id];
  }
}
export enum ExamStatusAuthorised {
  NAME = 'ZAUTORYZOWANE',
  BADGE = 'danger',
}

export enum ResultsDocType {
  FROM_KPP,
  GENERATED,
}

export const PRECISIONS = [
  {
    symbol: 'DLUGOSC',
    prec: 1,
  },
  {
    symbol: 'SZEROKOSC',
    prec: 1,
  },
  {
    symbol: 'WYSOKOSC',
    prec: 1,
  },
  {
    symbol: 'ODCHYLKA_PLASK',
    prec: 2,
  },
  {
    symbol: 'ODCHYLKA_PROSTOP',
    prec: 1,
  },
  {
    symbol: 'MASA',
    prec: 3,
  },
  {
    symbol: 'GESTOSC',
    prec: 0,
  },
  {
    symbol: 'SILA',
    prec: 0,
  },
  {
    symbol: 'WYTRZYMALOSC',
    prec: 1,
  },
];

export const sampleToolParams = [
  { symbol: 'k', value: '', prec: 0 },
  { symbol: 'a_error', value: '', prec: 0 },
  { symbol: 'h_error', value: '', prec: 0 },
  { symbol: 'maxF', value: '', prec: 0 },
  { symbol: 'boF', value: '', prec: 0 },
  { symbol: 'wwa', value: '', prec: 2 },
  { symbol: 'wwh', value: '', prec: 3 },
  { symbol: 'wwF', value: '', prec: 2 },
  { symbol: 's', value: '', prec: 3 },
  { symbol: 'Uw', value: '', prec: 1 },
];

export const CTB_WORKER_GET_PERSON = 1;
export const CLIENT_GET_PERSON = 2;

export const rdGetPeople = [
  {
    id: CTB_WORKER_GET_PERSON,
    name:
      'Próbki do badań zostały pobrane przez pracownika CTB wg PN-EN 12350-1:2019-07 i zaformowane wg PN-EN 12390-2:2019-07.',
  },
  { id: CLIENT_GET_PERSON, name: 'Próbki do badań zostały pobrane przez Zleceniodawcę.' },
];

export const rdRules = [
  {
    id: 1,
    name:
      'zasada A (ZA) z rozszerzeniem przedziału akceptowalności o niepewność pomiaru zgodnie z ZPD1 Wydanie 1 z dnia 05.11.2019 r.',
  },
  { id: 2, name: 'zasada prostej akceptacji zgodnie z ILAC-G8:09/2019 (ZB wg ZPD1 Wydanie 1 z dnia 05.11.2019 r.)' },
];

export const AuditEntity = {
  ENTITY: {
    id: 0,
    name: 'Jednostka certyfikująca',
  },
  LAB: {
    id: 1,
    name: 'Laboratorium badawcze',
  },
};
export const AuditInternalState = {
  INTERNAL: {
    id: true,
    name: 'Wewnętrzy',
  },
  EXTERNAL: {
    id: false,
    name: 'Zewnętrzny',
  },
};

console.log('--- CONSTS FILE LODING END ---');
