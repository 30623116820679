































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import { ModalEvents } from '../../consts';

@Component
export default class ModalInfo extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) info;
  @Prop({ type: String, required: false, default: '' }) infoClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'md' }) size;

  show = false;
  private dataObj = null;

  showModal() {
    this.show = true;
  }
  saveModal() {
    this.$emit(ModalEvents.OK_EVENT, this.dataObj);
    this.show = false;
  }
  hideModal() {
    this.show = false;
  }
}
