






































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import userModule from '../../store/modules/user';

@Component
export default class MainMenu extends Vue {
  @Prop({ required: false, default: '' }) routePrefix: string;
  @Prop({ type: Array, required: true })
  menuItems!: object[];

  @Prop({ type: String, required: true })
  menuName!: string;

  toggleMenu(index: number) {
    this.$root.$emit('bv::toggle::collapse', 'accordion-' + index);
  }
  isSubitemVisible(module: string) {
    // TO-DO send module from the backend
    return module === 'lab' || module === 'admin'; // this.profile.module;
  }
  private logoutUser(): void {
    userModule.logout();
    this.$router.push({ name: 'Login' });
  }
}
