



























































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import TopPanel from '../../components/dummy/TopPanel.vue';
import Header from '../../components/dummy/Header.vue';
import Footer from '../../components/dummy/Footer.vue';
import { Guid } from 'guid-typescript';
import FileManager from './FileManager.vue';
import moment from 'moment';
import { BreadcrumbItem, OfferAction } from '../../types';
import DashboardOffers from './DashboardOffers.vue';
import DashboardOfferContract from './DashboardOfferContract.vue';
import { Client } from '../../types';
import offerModule from '../../store/modules/offer';
import ModalClient from '../../components/spec/ModalClient.vue';

import dictModule from '../../store/modules/dict';
import userModule from '../../store/modules/user';
import * as consts from '../../consts';
import breadcrumbModule from '../../store/modules/breadcrumb';
import * as helpers from '../../helpers';

@Component({
  components: {
    TopPanel,
    Header,
    Footer,
    DashboardOffers,
    ModalClient,
    FileManager,
    DashboardOfferContract,
  },
})
export default class DashBoardOfferAction extends Vue {
  @Ref() readonly clientmodal: ModalClient;
  offerNumber = '';
  status: consts.OfferActionStatus;
  offerDescription = '';
  newRequest = consts.PageMode.NEW;
  @Ref() readonly dashboardOffers: DashboardOffers;
  offerAction: OfferAction = null;
  requestVisible = false;
  offerVisible = false;
  newClientModal = false;
  dateText = '';
  date = '';
  btnSave = 0;
  enabled = Array<boolean>(consts.OfferActionStatus.length);
  contractVisible = false;
  private mode? = this.$attrs['mode'];
  private isClient? = this.$attrs['client'];
  revalidate = 0;
  get files() {
    this.revalidate;
    return this.offerAction.files;
  }

  get getTitle() {
    if (this.isClient) {
      return this.mode === 'new' ? 'Nowe zapytanie ofertowe' : 'Zapytanie ofertowe';
    } else {
      return this.mode === 'new' ? 'Nowa akcja ofertowa' : 'Akcja ofertowa';
    }
  }

  updateContractWithClient(client: Client) {
    console.log('updating contract...');
    this.btnSave++;
    this.clientmodal.hideModal();
    this.offerAction.contract.client = client;
    this.offerAction.contract.client_id = client.id;
    this.saveOffer(true);
  }

  showClientModal() {
    this.newClientModal = false;
    this.clientmodal.showModal();
  }

  onMainDateContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.offerAction.date_initiated = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = moment(this.offerAction.date_initiated, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    }
  }

  dateTextLeave(event: any) {
    console.log('orderDateTextLeave', event);
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      console.log('Emptying wrong DATE today', today);
      this.date = today.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = today.format(consts.DATE_FORMAT);
      helpers.warn('Nie wybrano daty, ustawiono domyślną wartość.');
    }
  }

  get offerGuid() {
    return this.offerAction.guid || null;
  }

  get offerActionId() {
    return this.$route.params.offer_id;
  }
  get redirectTo() {
    return this.$route.params.redirectTo;
  }

  cancelOffer() {
    this.$router.go(-1);
    offerModule.setOffer(null);
  }

  showNewClientModal() {
    this.newClientModal = true;
  }

  async saveOffer(skipClientCheck = false, goBack = true) {
    if (!skipClientCheck && this.contractVisible && !this.offerAction.contract.client_id) {
      this.showNewClientModal();
      return;
    }
    await offerModule.saveOfferAction(this.offerAction);
    await offerModule.fetchContracts();
    if (goBack) {
      this.$router.go(-1);
      offerModule.setOffer(null);
    }
    helpers.info('Zapytanie ofertowe zostało zapisane');
    await this.loadView();
  }

  updateOfferDescription() {
    this.offerAction.description = this.offerDescription;
  }

  async createNewOfferAction() {
    console.log('Creating new OfferAction...');
    this.offerAction = new OfferAction();
    this.offerAction.status = consts.OfferActionStatus.REQUEST;
    this.offerAction.number = await offerModule.newOfferActionNumber();
    this.offerAction.description = '';
    this.offerAction.date_initiated = '';
    this.offerAction.guid = Guid.raw();
    this.offerAction.files = [];
    this.offerAction.user_created = userModule.wholeUser;
    this.offerAction.user_created_id = userModule.wholeUser.id;
    this.offerAction.offers = [];
  }

  updateOfferNumber() {
    this.offerAction.number = this.offerNumber;
  }

  async getOfferAction(id: number) {
    this.offerAction = offerModule.offerActionById(id);
    offerModule.setOfferActionReference(this.offerAction);
    await offerModule.fetchOffers();
  }

  setEnabledTabs() {
    for (let i = 0; i < this.status.id; i++) this.enabled[i] = true;
  }
  async loadView() {
    console.log('INITALIZING COMPONENT DashBoardOfferAction...');
    console.log('fetching methods and offers...');
    this.date = this.dateText = '';
    if (!this.isClient) {
      await offerModule.fetchOfferActions();
    }

    if (this.mode === consts.PageMode.NEW) {
      await this.createNewOfferAction();
      if (this.isClient) {
        const client = dictModule.clientById(userModule.userData.client_id);
        this.offerAction.client_description = client.short_name;
        this.offerAction.contact_description = `${client.address.city},  ${client.address.street_and_house_no}`;
      }
    } else {
      await this.getOfferAction(parseInt(this.offerActionId));
      this.date = this.offerAction.date_initiated;
      this.dateText = moment(this.offerAction.date_initiated, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    }

    if (!this.isClient) {
      this.requestVisible = this.offerAction.status === consts.OfferActionStatus.REQUEST;
      this.offerVisible =
        this.offerAction.status === consts.OfferActionStatus.OFFER ||
        this.offerAction.status === consts.OfferActionStatus.TOACCEPT;
      this.contractVisible = this.offerAction.status === consts.OfferActionStatus.CONTRACT;
    } else {
      this.requestVisible = this.redirectTo === consts.OfferActionStatus.REQUEST.symbol;
      this.offerVisible =
        this.redirectTo === consts.OfferActionStatus.OFFER.symbol ||
        this.redirectTo === consts.OfferActionStatus.TOACCEPT.symbol;
      this.contractVisible = this.redirectTo === consts.OfferActionStatus.CONTRACT.symbol;
      if (this.mode === consts.PageMode.NEW) this.requestVisible = true;
    }
    this.status = this.offerAction.status;
    this.offerNumber = this.offerAction.number;
    this.offerDescription = this.offerAction.description;
    this.revalidate++;
    
    this.offerAction.load();
    offerModule.setOfferActionReference(this.offerAction);
    this.setEnabledTabs();
  }

  async mounted() {
    await this.loadView();
    const items: BreadcrumbItem[] = [];
    let routePrefix = '';
    if (this.isClient) {
      items.push({ title: 'Lista akcji ofertowych', link: 'ClientOffers' });
      routePrefix = '/client';
    } else {
      items.push({ title: 'Tablica akcji ofertowych', link: 'dashBoardOffer' });
      routePrefix = '/lab';
    }
    items.push({
      title: this.getTitle,
      link: '',
    });
    breadcrumbModule.setBreadcrumb({
      routePrefix: routePrefix,
      items: items,
    });
  }
}
