




































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import toolsModule from '../../../store/modules/tools';
import usersModule from '../../../store/modules/users';
import { SortDir } from '../../../consts';
import { Car, User } from '../../../types';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import ModalCar from '../../../components/spec/ModalCar.vue';
import userModule from '../../../store/modules/user';

@Component({
  components: {
    TopPanel,
    Header,
    Footer,
    ModalCar,
  },
})
export default class CarList extends Vue {
  @Ref() readonly modalCar: ModalCar;

  btnPrevVisible = false;
  btnNextVisible = true;

  pageSize = 20;
  pageNo = 1;
  allPagesCount = 0;
  sortParams = { field: 'number', dir: SortDir.ASC };
  spinner = false;
  cars: Car[] = [];

  getUserNameLastName(car) {
    if (car.default_user_id === undefined) {
      return '- (rezerwowy)';
    } else {
      if (car.default_user_id !== null) {
        const user = usersModule.userById(car.default_user_id);
        return user.firstname + ' ' + user.lastname;
      } else {
        return '- (rezerwowy)';
      }
    }
  }

  async fillCars() {
    this.spinner = true;
    this.cars = [];
    let cars = toolsModule.cars;
    console.log('Sorting', this.sortParams);
    cars = this.sortCar(cars, this.sortParams.field, this.sortParams.dir);
    let index = 1;
    cars.forEach(c => {
      index++;
      c['index'] = index;
    });
    this.allPagesCount = Math.ceil(cars.length / this.pageSize);
    console.log('Paginating pageSize=', this.pageSize, 'pageNo=', this.pageNo);
    this.cars = this.paginate(cars, this.pageSize, this.pageNo) as Car[];
    console.log('LOADED ', this.cars.length, ' cars');
    this.spinner = false;
    this.update();
  }

  // ---------------- ROUTES ------------------
  addCar() {
    this.modalCar.showModal();
  }
  editCar(event: any, item: any) {
    this.modalCar.showModal(item.car);
  }
  modalCarOK(params) {
    console.log('modalCarOK', params);
    this.fillCars();
  }
  modalCarCancel() {
    console.log('modalCarCancel');
  }
  get user(): User {
    return userModule.user;
  }
  // ----------------- INTERNAL ACTIONS ----------------
  prevPage() {
    this.pageNo--;
    this.fillCars();
  }

  nextPage() {
    this.pageNo++;
    this.fillCars();
  }

  changePageSize() {
    this.update();
    this.fillCars();
  }

  sortPage(sortField = 'id') {
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.fillCars();
  }

  update() {
    console.log('update', this.pageNo, this.allPagesCount);
    this.btnNextVisible = this.pageNo !== this.allPagesCount;
    this.btnPrevVisible = this.pageNo !== 1;
  }

  sortCar(array: Car[], field: string, dir: SortDir): Car[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      case 'number':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return Number(a[field]) < Number(b[field]) ? -1 : 1;
          } else {
            return Number(a[field]) > Number(b[field]) ? -1 : 1;
          }
        });
      case 'register_number':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a[field] < b[field] ? -1 : 1;
          } else {
            return a[field] > b[field] ? -1 : 1;
          }
        });
      case 'default_user_id':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return this.getUserNameLastName(a).localeCompare(this.getUserNameLastName(b));
          } else {
            return this.getUserNameLastName(b).localeCompare(this.getUserNameLastName(a));
          }
        });
      default:
        return array;
    }
  }

  paginate(array: Car[], pageSize: number, pageNumber: number): object[] {
    pageNumber--;
    const rowFrom: number = +(pageNumber * pageSize);
    const rowTo: number = +(pageNumber * pageSize) + +pageSize;
    console.log('Page [' + rowFrom, '-', rowTo + ']');
    let index = 1;
    const templateArray: object[] = [];
    array.forEach(o => {
      //console.log('paginate tool index', index);
      templateArray.push({
        index: index++,
        car: o,
      });
    });
    return templateArray.slice(rowFrom, rowTo);
  }

  async mounted() {
    console.log('mounted');

    this.fillCars();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Lista samochodów',
          link: '',
        },
      ],
    });
  }
}
