





















































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { Client, User } from '../../types';
import usersModule from '../../store/modules/users';
import dictModule from '../../store/modules/dict';
import { filter } from 'vue/types/umd';

const NOT_CHOOSEN = 100;

@Component
export default class ModalClientFilter extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  clientName = '';
  clientShortName = '';
  clientCode = '';
  clientCity = '';
  clientPostalCode = '';
  clientNIP = '';

  enabled = false;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;

  showModal() {
    this.btnSave++;

    this.show = true;
  }
  addFilter(filters: { field: string; value: any }[], field: string, value: any) {
    filters.push({ field: field, value: value });
  }
  saveModal() {
    this.save = true;
    const filters = [];
    if (this.clientName !== '') {
      this.addFilter(filters, 'name', this.clientName);
    }
    if (this.clientShortName !== '') {
      this.addFilter(filters, 'short_name', this.clientShortName);
    }
    if (this.clientCode !== '') {
      this.addFilter(filters, 'code', this.clientCode);
    }
    if (this.clientCity !== '') {
      this.addFilter(filters, 'city', this.clientCity);
    }
    if (this.clientNIP !== '') {
      this.addFilter(filters, 'nip', this.clientNIP);
    }
    if (this.clientPostalCode !== '') {
      this.addFilter(filters, 'postal_code', this.clientPostalCode);
    }
    this.$emit(consts.ModalEvents.OK_EVENT, filters);
    this.show = false;
  }
  clear() {
    this.clientShortName = '';
    this.clientName = '';
    this.clientCode = '';
    this.clientNIP = '';
    this.clientCity = '';
    this.clientPostalCode = '';
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
