
























































































































import { Component, Vue, Prop, PropSync, Watch, Ref } from 'vue-property-decorator';

import visitModule from '../../../store/modules/visit';
import { Mixer, Visit, BreadcrumbItem, Breadcrumb } from '../../../types';
import { EventBus, BusEvents } from '../../../helpers/eventbus';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import visitMixin from '../../../helpers/components/visit';
import * as consts from '../../../consts';
import TimeInput from '@/components/dummy/TimeInput.vue';
import VisitHeader from '@/views/laborant/visits/VisitHeader.vue';
import Footer from '@/components/dummy/Footer.vue';
import VisitTree from '@/views/laborant/visits/VisitTree.vue';
import * as helpers from '../../../helpers';

@Component({
  components: {
    VisitHeader,
    Footer,
    VisitTree,
    TimeInput,
  },
})
export default class VisitMixer extends visitMixin {
  mixer: Mixer = null;
  invalidateTime = 0;

  get visitTitle() {
    let title = 'Gruszka ';
    if (this.mixer) {
      title += this.mixer.index;
      if (this.mixer.vehicle_no !== undefined && this.mixer.vehicle_no !== '') {
        title += ' : ' + this.mixer.vehicle_no.toUpperCase();
      }
    }
    return title;
  }

  modalChooseTimeOK(time: string) {
    this.mixer.load_time = time;
    this.mixer.collects.forEach(c => {
      c.collect_time = time;
      c.exam_time = this.getExamTimeBasedOnCollectTime(time);
    });
    this.updateMixer();
    helpers.warn('Dodatkowo data POBRANIA próbek uległa zmianie');
  }
  choosePhoto() {
    console.log('Choosing photo...');
  }
  onLoadTimeContext(e: any) {
    console.log('onLoadTimeContext', e);
    this.updateMixer();
  }

  /********************  COMMON ********************/

  setBreadcrumb() {
    const breadcrumbItems: BreadcrumbItem[] = [
      {
        title: 'Wizyta ' + this.visit.number,
        link: {
          name: 'visitmain',
          params: {
            visit_id: this.visit.id.toString(),
            pending: true.toString(),
          },
        },
      },
      {
        title: this.protocol.getName() + ' ' + this.protocol.number.toString(),
        link: {
          name: consts.ProtocolTypeString.PIB,
          params: {
            visit_id: this.visit.id.toString(),
            protocol_id: this.visit.collect_and_exam_protocol.id.toString(),
          },
        },
      },
      {
        title: 'Gruszka ' + this.mixer.vehicle_no,
        link: '',
      },
    ];
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: breadcrumbItems,
    });
  }
  loadMixer() {
    console.log('PARAMS:', this.$route.params);
    this.mixer = this.visit.collect_and_exam_protocol.mixers.find(m => m.id === parseInt(this.$route.params.mixer_id));
    this.isTreeVisible = false;
    this.invalidateTime++;
    this.setBreadcrumb();
  }
  created() {
    console.log('%c*********************** Mixer mounting ***********************', 'color:red');
    this.loadMixer();
    window.addEventListener(
      'click',
      function (e: any) {
        this.checkPanelVisible(e);
      }.bind(this)
    );

    EventBus.$on(BusEvents.CHANGE_TREE_VISIBLE, this.changeVisible);
    console.log('%c####################### Mixer mounted ######################## ', 'color:red');
  }
  updateMixer() {
    console.log('updating mixer visit...');
    visitModule.setMixer(this.mixer);
  }
  @Watch('$route.params.mixer_id')
  onCollectChange(newId: string, oldId: string) {
    console.log('ID Mixer change', oldId, '=>', newId);
    this.loadMixer();
  }
}
