





















import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import LaborantMenu from '@/components/spec/LaborantMenu.vue';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import userModule from '@/store/modules/user';
import PINModal from './PINModal.vue';
import SynchroList from '@/views/SynchroList.vue';
import visitsModule from '@/store/modules/visits';
import { onlineHelper } from '@/helpers/offline';
import { EventBus } from '@/helpers/eventbus';
import ModalInfo from '@/components/dummy/ModalInfo.vue';

@Component({
  components: { Footer, Header, TopPanel, LaborantMenu, PINModal, SynchroList, ModalInfo },
})
export default class Laborant extends Vue {
  @Ref() pinModal: PINModal;
  @Ref() offlineModal: ModalInfo;
  @Ref() onlineModal: ModalInfo;

  //todo przerobic wszystko co mamy tutaj zwiazane z logika offline, do dependency
  //i potem ja wstrzykiwac
  localOnlineHelper = onlineHelper;

  showLaborantMenu = true;
  isInSynchroState = false;

  get menuItemsVisible() {
    return onlineHelper.isOnline && this.showLaborantMenu;
  }

  redirectToSynchro() {
    this.showLaborantMenu = false;
    this.isInSynchroState = true;
    const route = this.$router.currentRoute.path;
    if (!route.endsWith('synchro')) {
      this.$router.push({ name: 'Synchro' });
    }
  }

  popToCalendar() {
    this.$router.push({
      name: 'calendarvisitsmonth',
    });
  }

  offlineRedirectNeeded() {
    const isInVisit = this.$router.currentRoute.fullPath.includes('visit');
    const isInCalendar = this.$router.currentRoute.name === 'calendarvisitsmonth';
    if (this.isInSynchroState) {
      return true;
    }
    if (isInVisit || isInCalendar) {
      return false;
    } else return true;
  }

  @Watch('localOnlineHelper', { immediate: true, deep: true })
  processOnline() {
    if (this.localOnlineHelper.isOnline) {
      if (visitsModule.offlineVisits.length !== 0) {
        this.onlineModal.showModal();
      }
    } else {
      this.offlineModal.showModal();
      if (this.offlineRedirectNeeded()) {
        this.isInSynchroState = false;
        this.popToCalendar();
      }
    }
  }

  mounted() {
    EventBus.$on('ENDED_SYNCHRO', (e: boolean) => {
      this.showLaborantMenu = true;
      this.isInSynchroState = false;
    });

    if (!userModule.isPinSet) {
      this.pinModal.showModal();
    }
    this.processOnline();
  }
}
