





































































































































































































































































































































































































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import VueHtml2pdf from 'vue-html2pdf';
import ExamCardHeader from '../../../components/spec/ExamCardHeader.vue';
import Footer from '../../../components/dummy/Footer.vue';
import usersModule from '../../../store/modules/users';
import examsModule from '../../../store/modules/exams';
import examModule from '../../../store/modules/exam';
import {
  User,
  Exam,
  ExamStage,
  DestinyGroup,
  ExamStageParam,
  Sample,
  Collect,
  Mixer,
  ExamSampleStageParam,
  ResultsDoc,
} from '../../../types';
import * as consts from '../../../consts';
import moment from 'moment';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import dictModule from '../../../store/modules/dict';
import samplesModule from '../../../store/modules/samples';
import collectsModule from '../../../store/modules/collects';
import mixersModule from '../../../store/modules/mixers';
import protocolsModule from '../../../store/modules/protocols';
import visitsModule from '../../../store/modules/visits';
import ordersModule from '../../../store/modules/orders';
import offersModule from '../../../store/modules/offer';
import resultsDocModule from '../../../store/modules/results_doc';
import * as helpers from '../../../helpers';
import methodsModule from '../../../store/modules/methods';
import ModalConfig from '../../../components/spec/ModalResultsDocConfig.vue';
import { create, all } from 'mathjs';
import userModule from '../../../store/modules/user';

const math = create(all);

const enum viewMode {
  FORM,
  PRINT,
}

@Component({
  components: { ExamCardHeader, Footer, VueHtml2pdf, ModalConfig },
})
export default class ExamCardF extends Vue {
  @Ref() readonly protocolpdf: VueHtml2pdf;
  @Ref() readonly config: ModalConfig;

  private currentMonth = 1;
  private currentYear = 2020;
  private currentWeek = 1;
  private currentDay = 1;
  currDate: moment.Moment = moment();
  currStageIndex = 1;

  isAuthorised = false;
  user_full_name = '';
  exam_id = -1;
  exam = null;
  examStages: ExamStage[] = [];
  revalidateSamples = 0;
  examStageParams: ExamStageParam[] = [];
  examSampleStageParams: ExamSampleStageParam[] = [];
  viewModePrint = viewMode.PRINT;
  positionSample: Sample[] = [];
  viewModeForm = viewMode.FORM;
  mode: viewMode = viewMode.FORM;

  pdfClickKey = 0;
  closeStageClickKey = 0;
  saveExamClickKey = 0;

  authModalSpin = false;

  get getStatusColor() {
    if (this.exam.status === 1) {
      return 'info';
    } else if (this.exam.status === 2) {
      return 'success';
    } else if (this.exam.status === 3) {
      return 'primary';
    } else if (this.exam.status === 4) {
      return 'danger';
    } else {
      return 'primary';
    }
  }

  get getSampleAge() {
    return (sample: Sample) => {
      const date = visitsModule.visitById(this.getProtocolOfSample(sample).visit_id).date;
      const visit_date = moment(date);
      return moment().diff(visit_date, 'days');
    };
  }

  get getProtocolOfSample() {
    return (sample: Sample) => {
      const collect_id = sample.collect_id;
      const collect = collectsModule.collectById(collect_id);
      const mixer = mixersModule.mixerById(collect.mixer_id);
      const protocol_id = mixer.protocol_id;
      const protocolPiB = protocolsModule.protocols.find(
        el => el.id === protocol_id && el.type_id === consts.ProtocolType.PIB
      );
      return protocolPiB;
    };
  }

  get getTodayStr() {
    return moment().format('DD.MM.YYYY');
  }

  get getExamStartDateStr() {
    return moment(this.exam).format('DD.MM.YYYY');
  }

  get getStatusName() {
    if (this.exam.status === 1) {
      return 'DO PRZYPISANIA';
    } else if (this.exam.status === 2) {
      return 'PRZYPISANE';
    } else if (this.exam.status === 3) {
      return 'BADANIE W TRAKCIE';
    } else if (this.exam.status === 4) {
      return 'ZAKOŃCZONE';
    } else {
      return '-- brak --';
    }
  }

  get currentMonthTitle() {
    return consts.Months[this.currentMonth];
  }

  get getResult() {
    return (symbol: string, sample: Sample): string => {
      if (symbol !== 'WYTRZYMALOSC' && this.normalSamples.findIndex(el => el.id === sample.id) === -1) {
        return '-';
      }
      const param = this.examStageParams.find(p => p.symbol === symbol);
      if (param === undefined) {
        return 'BRAK';
      }

      return this.examSampleStageParams.find(sp => sp.param_id === param.id && sp.sample_id === sample.id).value;
    };
  }

  get getParamValue() {
    return (param: ExamStageParam, sample: Sample): string => {
      const exam = this.exam as Exam;
      if (param.formula === '$DZIS' && param.exam_stage_id === exam.stage_id) {
        console.log(param, 'getting date for', this.getCurrentStage.id);
        const date = moment().format('DD.MM.YYYY');
        const essp = this.examSampleStageParams.find(sp => sp.param_id === param.id && sp.sample_id === sample.id);
        essp.value = date;
        return date;
      }
      const value = this.examSampleStageParams.find(sp => sp.param_id === param.id && sp.sample_id === sample.id).value;
      if (!value) {
        return '';
      }
      return parseFloat(value.replace(',', '.')).toFixed(2).replace('.', ',');
    };
  }
  get paramStage() {
    return (param: ExamStageParam) => {
      return this.examStages.find(es => es.id === param.exam_stage_id);
    };
  }
  get params() {
    return (stage: ExamStage) => {
      return examModule.examStageParams.filter(p => p.exam_stage_id === stage.id);
    };
  }
  get samples() {
    return samplesModule.samples.filter(s => s.exam_id === this.exam.id);
  }

  moveSample(sample: Sample, step: number) {
    console.log(`Move sample : step `, step, sample, this.positionSample);
    const currentPosition = sample.position;
    const swapPosition = sample.position - step;
    if (swapPosition < 0 || swapPosition >= this.positionSample.length) {
      return;
    }
    const swapBuffer = Object.assign(new Sample(), this.positionSample[swapPosition]);
    this.positionSample[swapPosition] = this.positionSample[currentPosition];

    this.positionSample[currentPosition] = swapBuffer;
    this.positionSample[currentPosition].position = currentPosition;
    this.positionSample[swapPosition].position = swapPosition;
    console.log(`FINISHED Moving sample`, sample, this.positionSample);
    this.revalidateSamples++;
  }

  focusOnSample(sample: Sample) {
    console.log('focused sample...', sample);
    sample.isFocused = !sample.isFocused;
    this.revalidateSamples++;
  }

  get witnessSamples() {
    this.revalidateSamples;
    return this.positionSample.slice(Math.ceil(this.positionSample.length / 2), this.positionSample.length);
  }

  get normalSamples() {
    this.revalidateSamples;
    return this.positionSample.slice(0, Math.floor(this.positionSample.length / 2));
  }

  get concreteClass() {
    return (groupSymbol: string) => {
      const group = dictModule.groupClassBySymbol(groupSymbol);
      console.log('concreteClass ', groupSymbol, ' group=', group);
      const classCId = this.exam.protocol.class_ids.find(id => dictModule.concreteClassById(id).group_id === group.id);
      if (classCId) {
        return dictModule.concreteClassById(classCId).name;
      } else {
        return '';
      }
    };
  }
  get methods() {
    return methodsModule.allMethods.filter(m => m.default_destiny_group_id === this.destinyGroup.id);
  }
  get sampleArrays() {
    return [this.normalSamples, this.witnessSamples];
  }

  get destinyGroup() {
    console.log('dest', this.exam.destiny_id, dictModule.destinies);
    const destiny = dictModule.destinyById(this.exam.destiny_id);
    const group: DestinyGroup = dictModule.destinyGroups.find(d => d.id === destiny.group_id);
    return group;
  }
  get getRecipie() {
    return this.exam.protocol.recipie_no;
  }
  get getMainClass() {
    const class_ids = this.exam.protocol.class_ids;
    if (class_ids.length > 0) {
      return dictModule.concreteClassById(class_ids[0]).name;
    } else {
      return '';
    }
  }

  get collect() {
    return (sample: Sample): Collect => {
      return collectsModule.collectById(sample.collect_id);
    };
  }

  get mixer() {
    return (sample: Sample): Mixer => {
      return mixersModule.mixerById(this.collect(sample).mixer_id);
    };
  }

  get getCPlaceName() {
    return dictModule.cPlaceById(this.visit.cplace_id).name;
  }
  get stage() {
    return this.examStages.find(s => s.id === this.exam.stage_id);
  }
  get getCurrentStage() {
    console.log('this.currStageIndex', this.currStageIndex, this.examStages.length);
    if (this.currStageIndex <= this.examStages.length) {
      return this.examStages.find(s => s.index === this.currStageIndex);
    } else {
      return null;
    }
  }

  get isLastStage() {
    return this.stage.index === this.examStages.length;
  }

  get isLastCurrStage() {
    return this.getCurrentStage.index === this.examStages.length;
  }

  get user() {
    return (id: number): User => {
      return usersModule.userById(id);
    };
  }
  get visit() {
    return visitsModule.visitById(this.exam.protocol.visit_id);
  }
  get getSampleTakeDate() {
    return this.visit.date.slice(0, 10);
  }
  get getTakeDate() {
    const map = new Map();
    this.samples.forEach(el => {
      const sample = el as Sample;
      console.log('processing sample', sample);
      if (sample.protocol_ctb_id === null) {
        return;
      }
      const protocol = protocolsModule.protocolById(sample.protocol_ctb_id);
      const visit = visitsModule.visitById(protocol.visit_id);
      if (map[visit.id] === undefined) {
        map[visit.id] = visit;
      }
    });
    const dates = [];
    for (const [key, visit] of Object.entries(map)) {
      console.log('date visit', visit, key);
      dates.push(moment(visit.date).format('DD.MM.YYYY'));
    }
    return dates.join(', ');
  }
  get client() {
    return dictModule.clientById(this.exam.client_id);
  }
  get contract() {
    return offersModule.contractById(this.order.contract_id);
  }
  get order() {
    return ordersModule.orderById(this.visit.order_id);
  }

  shouldSectionBeVisible(param: ExamStageParam, sectionIndex: number) {
    if (param.formula === '$DZIS') {
      return false;
    }
    const allowedWitnessParams = ['Wysokość', 'Długość', 'Szerokość', 'Wytrzymałość na ściskanie'];
    if (sectionIndex === 1 && !this.isLastStage) {
      return allowedWitnessParams.includes(param.name);
    }
    return !(
      (this.getCurrentStage !== null && this.getCurrentStage.id !== this.exam.stage_id) ||
      this.exam.status === 4 ||
      param.exam_stage_id !== this.getCurrentStage.id
    );
  }

  /*************************************************************************************************/

  async saveExam() {
    this.exam['stages'] = [];
    for (let s = 0; s < this.examStages.length; s++) {
      const stage = this.examStages[s];
      stage['exam_stage_params'] = [];
      for (let sp = 0; sp < this.examStageParams.length; sp++) {
        const stageParam: ExamStageParam = this.examStageParams[sp];
        if (stageParam.exam_stage_id === stage.id) {
          stageParam['sample_params'] = [];
          for (let essp = 0; essp < this.examSampleStageParams.length; essp++) {
            const essparam: ExamSampleStageParam = this.examSampleStageParams[essp];
            if (essparam.param_id === stageParam.id) {
              stageParam['sample_params'].push(essparam);
            }
          }
          stage['exam_stage_params'].push(stageParam);
        }
      }
      this.exam['stages'].push(stage);
    }

    return await examsModule.updateExam(this.exam);
  }

  get getExamEndDate() {
    return moment(this.exam.end_date_time).format('DD.MM.YYYY');
  }

  async closeStageClick() {
    console.log('closing Stage', this.stage.name, this.currStageIndex);
    if (this.currStageIndex === this.examStages.length) {
      this.exam.status = 4;
    } else {
      this.exam.stage_id = this.examStages.find(es => es.index === this.currStageIndex + 1).id;
      console.log('Next stage:', this.exam.stage_id);
    }
    const response = await this.saveExam();
    if (response.data.success) {
      if (this.currStageIndex !== this.examStages.length) {
        helpers.info('Etap zamknięty');
        this.currStageIndex++;
        this.closeStageClickKey++;
      } else {
        helpers.info('Karta zamknięta');
      }
    } else {
      helpers.error('Błąd przy zamykaniu karty !');
    }
  }

  freezeDefreezeCount() {
    const destiny = dictModule.destinyById(this.samples[0].destiny_id);
    return destiny.name.split('F')[1];
  }

  averageByParameterAndArray(parameter: string, samples: Sample[]) {
    let sum = 0.0;
    samples.forEach(sample => {
      sum += parseFloat(this.getResult(parameter, sample));
    });
    const result = sum / samples.length;
    return !isNaN(result) ? result.toFixed(2) : '-';
  }

  averageMassLoss() {
    const samples = this.normalSamples;
    let massAfter = 0;
    let massBefore = 0;
    samples.forEach(sample => {
      massAfter += parseFloat(this.getResult('MASA_PRZED', sample));
      massBefore += parseFloat(this.getResult('MASA_PO', sample));
    });
    return (((massBefore - massAfter) / massAfter) * 100).toFixed();
  }

  averageDurabilityLoss() {
    const normal = this.normalSamples;
    const witnesses = this.witnessSamples;
    let durabilityNormal = 0;
    let durabilityWitness = 0;
    normal.forEach(sample => {
      durabilityNormal += parseFloat(this.getResult('WYTRZYMALOSC', sample));
    });
    witnesses.forEach(sample => {
      durabilityWitness += parseFloat(this.getResult('WYTRZYMALOSC', sample));
    });
    return (((durabilityWitness - durabilityNormal) / durabilityWitness) * 100).toFixed(2);
  }

  async saveExamClick() {
    console.log('saveExam');

    const response = await this.saveExam();
    let examRef = examsModule.examById(this.exam.id);
    examRef = Object.assign({}, this.exam);

    let examsParams = examsModule.examSampleStageParams.filter(param =>
      examRef.samples.find(el => el.id === param.sample_id)
    );

    examsParams = examsParams.sort((a, b) => a.id - b.id);
    const sortedParams = this.examSampleStageParams.sort((a, b) => a.id - b.id);

    examsParams.forEach((el, index) => {
      el.value = sortedParams[index].value;
    });

    if (response.data.success) {
      helpers.info('Karta zapisana');
    } else {
      helpers.error('Błąd przy zapisie karty !');
    }
  }
  pdf() {
    this.protocolpdf.generatePdf();
    this.pdfClickKey++;
  }

  /*************************************************************************************************/

  nextStage() {
    this.currStageIndex++;
  }
  prevStage() {
    this.currStageIndex--;
  }
  async authorise() {
    this.$emit('authorise', { examCard: this, vueHtml2pdf: this.protocolpdf });
  }

  authoriseKey = 0;
  updateByFormula(sampleParam: ExamSampleStageParam, stageParam: ExamStageParam, sample: Sample, value: string) {
    console.log('updateByFormula sampleParam', sampleParam, 'stageParam', stageParam, 'sample', sample, 'value', value);
    const symbol = stageParam.symbol;
    const symbolValues = {};
    const thisSampleParams = this.examSampleStageParams.filter(essp => essp.sample_id === sample.id);
    console.log('This sample params ', thisSampleParams);
    thisSampleParams.forEach(sap => {
      console.log('gathering sample params', sap);
      const stageParam = this.examStageParams.find(sp => sp.id === sap.param_id);
      const newVal = sap.value.replace(',', '.');
      console.log('Replaced', newVal);
      symbolValues['$' + stageParam.symbol] = newVal;
      console.log('Added sumbol', stageParam.symbol, sap.value);
    });
    console.log('symbolValues', symbolValues);
    const stageParamsToChange = this.examStageParams.filter(sp => {
      console.log('sp.formula=', sp.formula, 'symbol', symbol);
      return sp.formula.includes(symbol);
    });
    console.log('stageParamsToChange', stageParamsToChange);
    stageParamsToChange.forEach(sp => {
      console.log('sp to eval', sp);
      const sampleParam = this.examSampleStageParams.find(
        essp => essp.sample_id === sample.id && essp.param_id === sp.id
      );
      console.log('sampleParam value evaluating', sp.formula, sampleParam, symbolValues, ' BEFORE=', sampleParam.value);
      const val = math.evaluate(sp.formula, symbolValues);
      sampleParam.value = math.round(val, 1);
      console.log('sampleParam value AFTER=', sampleParam.value);
    });
  }

  changeParamValue(e: KeyboardEvent, stageParam: ExamStageParam, sample: Sample) {
    console.log('Change params value', (e.target as HTMLInputElement).value, stageParam, sample);
    const p = this.examSampleStageParams.find(p => p.param_id === stageParam.id && p.sample_id === sample.id);
    p.value = (e.target as HTMLInputElement).value.replace(',', '.');
    this.updateByFormula(p, stageParam, sample, p.value);
    this.updateExamSampleStageParams();
  }
  getCurrentDate() {
    return moment({ year: this.currentYear, month: this.currentMonth, day: this.currentDay });
  }
  get currentDayTitle() {
    return moment(this.currentDay + '.' + this.currentMonth + '.' + this.currentYear, consts.DATE_FORMAT).format(
      consts.DATE_FORMAT
    );
  }
  goMonth() {
    this.$router.push({ name: 'calendarexamsmonth', params: { day: this.currDate.format(consts.DATE_FORMAT) } });
  }
  printView() {
    this.mode = viewMode.PRINT;
  }
  formView() {
    this.mode = viewMode.FORM;
  }
  updateExam() {
    examModule.setExam(this.exam);
  }
  showAuthModal() {
    this.$bvModal.show('auth-modal');
  }
  hideAuthModal() {
    this.$bvModal.hide('auth-modal');
    this.authModalSpin = false;
  }
  showModalConfig() {
    this.config.showModal(this.exam);
  }
  modalConfigOK(params: { samples: Sample[]; results_doc: ResultsDoc }) {
    this.exam.results_doc = params.results_doc;
    params.samples.forEach(s => {
      this.exam.samples.find(sam => sam.id === s.id).uw = s['uw'];
    });
  }
  updateExamStages() {
    examModule.setExamStages(this.examStages);
  }
  updateExamStageParams() {
    examModule.setExamStageParams(this.examStageParams);
  }
  updateExamSampleStageParams() {
    examModule.setExamSampleStageParams(this.examSampleStageParams);
  }
  setCurrentDate(dateString: string) {
    this.currDate = moment(dateString, 'DD_MM_YYYY');
    this.currentYear = this.currDate.year();
    this.currentWeek = this.currDate.week();
    this.currentDay = this.currDate.date();
    this.currentMonth = this.currDate.month();
    console.log('data:', this.currentDay, '.', this.currentMonth, '.', this.currentYear, ' week:', this.currentWeek);
  }

  get currentStage() {
    return [this.examStages.find(el => el.index === this.currStageIndex)];
  }

  get canBeAuthorised(): boolean {
    return (
      this.mode === this.viewModePrint &&
      this.isLastStage === true &&
      userModule.hasRightBySymbolInAllRights(consts.UserRightSymbols.EXAM_AUTHORISE) === true
    );
  }

  created() {
    console.log('CREATED', this.$route.params);
    moment.updateLocale('pl', { week: { dow: 1 } });
    const strDate = this.$route.params['date'];
    console.log('dateString', strDate);
    if (strDate !== undefined) {
      this.setCurrentDate(strDate);
    } else {
      this.setCurrentDate('01.01.2020');
    }

    this.exam_id = parseInt(this.$route.params['exam_id']);
    console.log('EXAM  id', this.exam_id);

    // COPY
    this.exam = examsModule.examCopyById(this.exam_id);
    console.log(this.exam);
    this.isAuthorised = this.exam.is_authorised;
    if (this.isAuthorised) {
      this.user_full_name = this.exam.person_authorised.first_name + ' ' + this.exam.person_authorised.last_name;
    }

    this.updateExam();
    this.examStages = [];
    this.examStageParams = [];
    examsModule.examStages.forEach(es => {
      if (es.exam_id === this.exam.id) {
        this.examStages.push(examsModule.examStageCopyById(es.id));
        console.log('Stage ', es.name);
        examsModule.examStageParams.forEach(esp => {
          if (esp.exam_stage_id === es.id) {
            console.log('OK', esp.name);
            this.examStageParams.push(examsModule.examStageParamCopyById(esp.id));
            examsModule.getexamSampleStageParams.forEach(essp => {
              if (essp.param_id === esp.id) {
                this.examSampleStageParams.push(Object.assign(new ExamSampleStageParam(), essp));
              }
            });
          }
        });
      }
    });

    this.currStageIndex = this.stage.index;
    console.log('actual stage', this.examStages[this.currStageIndex]);
    console.log(this.examStages, 'ExamStages loaded');

    console.log('Exam', this.exam);
    this.updateExamStages();
    this.updateExamStageParams();
    this.updateExamSampleStageParams();
    this.exam.load();
    this.samples.forEach(sample => {
      sample.load();
    });
    let recalculate = false;
    this.samples.forEach(sample => (recalculate = sample.position === undefined));
    console.log('should positions be recalc?', recalculate);
    if (recalculate) {
      for (let i = 0; i < this.samples.length; i++) {
        this.samples[i].position = i;
        this.positionSample.push(this.samples[i]);
      }
    } else {
      this.positionSample = this.samples.sort((a, b) => a.position - b.position);
    }

    if (this.exam.results_doc === undefined) {
      this.exam.results_doc = helpers.getEmptyResultsDoc(
        dictModule.departments[0],
        consts.ResultsDocType.FROM_KPP,
        this.exam.client_id,
        [this.exam]
      );
      this.exam.results_doc.exam_id = this.exam_id;
    }

    console.log('Loaded samples', this.positionSample);

    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Kalendarz badań - miesiąc',
          link: 'ce/cem',
        },
        {
          title: 'Karta pierwszego pomiaru - badanie nr ' + this.exam.number,
          link: '',
        },
      ],
    });
  }
}
