




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { Offer, Method, File, OfferAction, Period } from '../../types';
import * as consts from '../../consts';
import multiselect from 'vue-multiselect';
import methodModule from '../../store/modules/methods';
import offerModule from '../../store/modules/offer';
@Component({})
export default class OfferLimitedAmountSettlement extends Vue {
  offer: Offer = new Offer();
  methods: Method[] = [];
  offerAction: OfferAction;
  currentMethod: Method = null;
  selectedMethods: Method[] = [];
  singleVisitCost = '';
  @Prop({ required: true })
  isEnabled: boolean;
  visitLimit = '';

  get getModalTitle() {
    if (!this.currentMethod) {
      return '';
    }
    return 'Dodawanie metody:  ' + this.currentMethod.name;
  }

  isClient(): boolean {
    return this.$route.fullPath.includes('client');
  }

  get notSelectedMethods() {
    return this.methods.filter(el => !this.selectedMethods.find(method => method.id === el.id));
  }
  removeMethod(method) {
    console.log('Remove:', method);
    this.selectedMethods = this.selectedMethods.filter(el => el.id !== method.id);
    this.offer.methods = this.selectedMethods;
  }

  methodSelected(e) {
    console.log('Selected method', e);
    this.currentMethod = e;
  }

  addMethodToAnyAmount() {
    this.currentMethod.price = parseInt(this.singleVisitCost);
    this.currentMethod.limit = parseInt(this.visitLimit);
    this.visitLimit = this.singleVisitCost = '';
    this.selectedMethods.push(this.currentMethod);
    this.currentMethod = null;
  }
  created() {
    console.log(methodModule.allMethods);
    console.log(this.methods);
    this.methods = methodModule.allMethods;
    this.offer = offerModule.currentOffer;
    this.selectedMethods = this.offer.methods;
    console.log('loaded any amount settlement');
  }
}
