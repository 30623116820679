






import Vue from 'vue';
import Component from 'vue-class-component';
import MainMenu from '../../components/dummy/MainMenu.vue';

@Component({
  components: { MainMenu },
})
export default class ClientMenu extends Vue {
  wide: boolean;

  constructor() {
    super();
    this.wide = false;
  }

  get height() {
    return 'height: ' + window.innerHeight + 'px';
  }

  get items() {
    return [
      /*
      {
        name: 'client_orders', // orders
        title: 'Zamówienia',
        icon: 'shopping-basket',
        link: 'clientorders',
        items: [],
      },*/
      {
        name: 'requests',
        title: 'Zapytania ofertowe',
        icon: 'user-cog',
        link: 'clientoffers',
        items: [],
        simple: true,
      },
      /*
      {
        name: 'contracts',
        title: 'Umowy',
        icon: 'list-alt',
        items: [],
      },
      {
        name: 'settlements',
        title: 'Rozliczenia',
        icon: 'calendar-alt',
        items: [],
      },
      */
      {
        name: 'clientrdlist',
        title: 'Wyniki badań',
        icon: 'vial',
        link: 'clientrdlist',
        items: [],
        simple: true,
      },
    ];
  }
}
