
























































































































































import VueRouter from 'vue-router';
import { Component, Vue } from 'vue-property-decorator';
import Header from '../../components/dummy/Header.vue';
import Footer from '../../components/dummy/Footer.vue';
import userModule from '../../store/modules/user';
import examsModule from '../../store/modules/exams';
import { Day, Week, Exam } from '../../types';
import * as consts from '../../consts';
import moment from 'moment';
import breadcrumbModule from '../../store/modules/breadcrumb';
import * as helpers from '../../helpers';

const TO_EXAM = 1;
const IN_EXAM = 2;
const AFTER_EXAM = 3;
const ANY_STATUS_EXAM = 4;

const EXAMS_MINE = 1;
const EXAMS_ALL_LABORANTS = 2;

const ASIGNED_EXAMS = 1;
const UNASIGNED_EXAMS = 2;
const ALL_EXAMS = 3;

type MultiselectOption = {
  name: number;
  text: string;
};
type ExamsFilterOptions = {
  statusSelected?: number;
  labCoordOptionSel?: number;
  asignedOrNotSel?: number;
};

@Component({
  components: { Header, Footer },
})
export default class CalendarExamsMonth extends Vue {
  TO_EXAM = TO_EXAM;
  IN_EXAM = IN_EXAM;
  AFTER_EXAM = AFTER_EXAM;
  EXAMS_MINE = EXAMS_MINE;
  EXAMS_ALL_LABORANTS = EXAMS_ALL_LABORANTS;
  ASIGNED_EXAMS = ASIGNED_EXAMS;
  UNASIGNED_EXAMS = UNASIGNED_EXAMS;

  currentMonth = 1;
  currentYear = 2020;
  currentWeek = 1;
  currentDay = 1;
  currentDate = null;
  elementMonthsData: Day[] = [];
  nowDay = null;
  scrolledToEnd = false;
  scrolledToBegin = false;
  firstDate = moment('01.11.2020', consts.DATE_FORMAT).startOf('day');
  lastDate = moment('21.07.2021', consts.DATE_FORMAT).startOf('day');
  weeks = [];

  efo: ExamsFilterOptions = {
    labCoordOptionSel: 1,
    asignedOrNotSel: 1,
    statusSelected: 1,
  };

  get statusSel() {
    return this.statuses[this.efo.statusSelected - 1];
  }
  get labCoordOptionsSel() {
    //console.log('labCoordOptionsSel', this.efo.labCoordOptionSel);
    return this.labCoordOptions[this.efo.labCoordOptionSel - 1];
  }
  get asignedOrNotOptionsSel() {
    return this.asignedOrNotOptions[this.efo.asignedOrNotSel - 1];
  }

  selectStatus(event: MultiselectOption) {
    //console.log('selected', event);
    this.efo.statusSelected = event.name;
    this.sendEfoToVuex();
    this.fillDays();
  }
  selectLabCoordOptions(event: MultiselectOption) {
    this.efo.labCoordOptionSel = event.name;
    this.sendEfoToVuex();
    if (event.name === EXAMS_ALL_LABORANTS && this.efo.asignedOrNotSel === UNASIGNED_EXAMS) {
      this.selectStatus(this.statuses.find(s => s.name === TO_EXAM));
    } else {
      this.selectAsignedOrNotOptions(this.asignedOrNotOptions.find(a => a.name === ASIGNED_EXAMS));
    }
  }
  selectAsignedOrNotOptions(event: MultiselectOption) {
    this.efo.asignedOrNotSel = event.name;
    this.sendEfoToVuex();
    if (event.name === UNASIGNED_EXAMS) {
      this.selectStatus(this.statuses.find(s => s.name === TO_EXAM));
    } else if (event.name === ALL_EXAMS) {
      this.selectStatus(this.statuses.find(s => s.name === ANY_STATUS_EXAM));
    } else {
      this.fillDays();
    }
  }

  get statuses(): MultiselectOption[] {
    return [
      { name: TO_EXAM, text: 'DO BADANIA' },
      { name: IN_EXAM, text: 'W TRAKCIE BADAŃ' },
      { name: AFTER_EXAM, text: 'ZAKOŃCZONE' },
      { name: ANY_STATUS_EXAM, text: 'WSZYSTKIE STATUSY' },
    ];
  }
  get labCoordOptions(): MultiselectOption[] {
    return [
      { name: EXAMS_MINE, text: 'BADANIA MOJE' },
      { name: EXAMS_ALL_LABORANTS, text: 'BADANIA WSZYSTKICH' },
    ];
  }
  get asignedOrNotOptions(): MultiselectOption[] {
    return [
      { name: ASIGNED_EXAMS, text: 'PRZYPISANE BADANIA' },
      { name: UNASIGNED_EXAMS, text: 'NIEPRZYPISANE BADANIA' },
      { name: ALL_EXAMS, text: 'WSZYSTKIE BADANIA' },
    ];
  }

  get disableStatusSelection(): boolean {
    return (
      this.isLabCoord === true &&
      this.efo.labCoordOptionSel === EXAMS_ALL_LABORANTS &&
      this.asignedOrNotOptionsSel.name === UNASIGNED_EXAMS
    );
  }

  //  ExamsFilterOptions
  sendEfoToVuex() {
    examsModule.setExamsFilterOptions({
      labCoordOptionSel: this.efo.labCoordOptionSel,
      asignedOrNotSel: this.efo.asignedOrNotSel,
      statusSelected: this.efo.statusSelected,
    });
  }

  monthName(month: number) {
    return consts.Months[month];
  }

  get now() {
    return moment();
  }

  fillWeeks() {
    const currDate = this.firstDate.clone();
    const lastDate = this.lastDate.clone();

    let week: Week = null;
    const weeks: Week[] = [];
    this.elementMonthsData = [];
    const isLabCoord = this.isLabCoord;
    const userId = userModule.user.id;

    console.log('isCoor:', isLabCoord, ' userId:', userId);

    while (currDate.diff(lastDate) < 0) {
      const weekStart = moment(currDate).startOf('week');
      const weekEnd = moment(currDate).endOf('week');

      week = {
        weekStart: weekStart,
        weekEnd: weekEnd,
        weekOfYear: currDate.week(),
        year: currDate.year(),
        month: currDate.month() + 1,
        days: [],
      };

      const day = currDate.clone().startOf('week');
      for (let dayInWeek = 1; dayInWeek < 8; dayInWeek++) {
        const currDay = {
          date: day,
          dateString: day.format(consts.DATE_FORMAT),
          dayAndMonth: day.format('DD.MM'),
          dayOfMonth: day.date(),
          dayOfYear: day.dayOfYear(),
          dayOfWeek: day.day(),
          year: day.year(),
          weekOfYear: week.weekOfYear,
          month: day.month() + 1,
          exams: [], //this.getExams(day),
        } as Day;
        week.days.push(currDay);
        if (currDay.dayOfMonth === 1) {
          this.elementMonthsData.push(currDay);
        }
        //console.log('Day # ', day.format(), day.date(), this.now.format(), this.now.date());
        if (helpers.isSameDay(day, this.now)) {
          this.nowDay = currDay;
          //console.log('Now', this.nowDay.date.format('DD.MM.YYYY'));
        }
        day.add(1, 'days');
      }
      weeks.push(week);
      currDate.add(7, 'days');
    }
    this.weeks = weeks;
  }

  clearDays() {
    this.weeks.forEach(w => {
      for (let dayInWeek = 0; dayInWeek < 7; dayInWeek++) {
        w.days[dayInWeek].exams = [];
      }
    });
  }

  fillDays() {
    let exams: Exam[] = [];
    let examsAfter: Exam[] = [];
    this.clearDays();
    if (this.efo.statusSelected !== 0) {
      exams = examsModule.allExams.filter(exam => {
        const diff: number = moment().diff(moment(exam.n_date, consts.DB_DATE_TIME_FORMAT));
        return (
          diff >= 0 &&
          ((this.efo.statusSelected === TO_EXAM &&
            (exam.status === consts.ExamStatus.TO_ASSIGN.id || exam.status === consts.ExamStatus.ASSIGNED.id)) ||
            (this.efo.statusSelected === IN_EXAM && exam.status === consts.ExamStatus.PENDING.id) ||
            (this.efo.statusSelected === AFTER_EXAM && exam.status === consts.ExamStatus.FINISHED.id) ||
            this.efo.statusSelected === ANY_STATUS_EXAM) &&
          (this.isLabCoord === true
            ? this.showToLabCoord(exam) === true
            : exam.current_laborant_id === userModule.user.id)
        );
      });
      console.log('choosen Exams:', exams);
      exams.forEach(exam => {
        this.nowDay.exams = exams;
      });
      examsAfter = examsModule.allExams.filter(exam => {
        const diff: number = moment().diff(moment(exam.n_date, consts.DB_DATE_TIME_FORMAT));
        return (
          diff < 0 &&
          ((this.efo.statusSelected === TO_EXAM &&
            (exam.status === consts.ExamStatus.TO_ASSIGN.id || exam.status === consts.ExamStatus.ASSIGNED.id)) ||
            (this.efo.statusSelected === IN_EXAM && exam.status === consts.ExamStatus.PENDING.id) ||
            (this.efo.statusSelected === AFTER_EXAM && exam.status === consts.ExamStatus.FINISHED.id) ||
            this.efo.statusSelected === ANY_STATUS_EXAM) &&
          (this.isLabCoord === true
            ? this.showToLabCoord(exam) === true
            : exam.current_laborant_id === userModule.user.id)
        );
      });
      console.log('choosen Exams AFTER:', exams);
      examsAfter.forEach(exam => {
        const n_date = moment(exam.n_date);
        const week = this.weeks.find(w => {
          return n_date.diff(w.weekStart) >= 0 && n_date.diff(w.weekEnd) <= 0;
        });
        console.log('Found week', week);
        for (let dayInWeek = 0; dayInWeek < 7; dayInWeek++) {
          if (moment(week.days[dayInWeek].dateString, consts.DATE_FORMAT).diff(n_date) === 0) {
            console.log('Found day');
            week.days[dayInWeek].exams.push(exam);
          } else {
            console.log('Day not found', week.days[dayInWeek]);
          }
        }
      });
    }
  }

  getDayToAssignExams(day: Day) {
    const exams = day.exams.filter(e => e.status === 1);
    return exams.length > 0 ? 'Do przypisania: ' + exams.length.toString() : '';
  }
  getDayAssignedExams(day: Day) {
    const exams = day.exams.filter(e => e.status === 2);
    return exams.length > 0 ? 'Przypisane: ' + exams.length.toString() : '';
  }
  getDayProgressExams(day: Day) {
    const exams = day.exams.filter(e => e.status === 3);
    return exams.length > 0 ? 'W trakcie: ' + exams.length.toString() : '';
  }
  getDayClosedExams(day: Day) {
    const exams = day.exams.filter(e => e.status === 4);
    return exams.length > 0 ? 'Zamknięte: ' + exams.length.toString() : '';
  }

  showToLabCoord(exam: Exam): boolean {
    if (this.efo.labCoordOptionSel === EXAMS_MINE && exam.current_laborant_id !== userModule.user.id) {
      return false;
    }
    if (this.efo.asignedOrNotSel === UNASIGNED_EXAMS && exam.current_laborant_id !== null) {
      return false;
    }
    if (this.efo.asignedOrNotSel === ASIGNED_EXAMS && exam.current_laborant_id === null) {
      return false;
    }
    return true;
  }
  get isLabCoord() {
    return userModule.inGroupBySymbol(consts.UserGroupSymbols.LAB_COORD);
  }

  dayClick(week: Week, day: Day) {
    this.currentDay = day.dayOfMonth;
    this.currentMonth = day.month;
    this.currentYear = day.year;

    if (this.isLabCoord) {
      this.$router.push({
        name: 'calendarexamsdaylabcoord',
        params: { day: this.currentDay + '_' + this.currentMonth + '_' + this.currentYear },
      });
    } else {
      this.$router.push({
        name: 'calendarexamsday',
        params: {
          day: this.currentDay + '_' + this.currentMonth + '_' + this.currentYear,
          person_id: userModule.user.id.toString(),
        },
      });
    }
  }

  weekClick(week: Week) {
    alert(week);
  }

  scrollToCurrentDay() {
    const elementToScrollTo = document.getElementById(
      'year_' + this.currentYear.toString() + '_day_' + this.currentDate.dayOfYear().toString()
    );
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  setCurrentDate(dateString: string) {
    this.currentDate = moment(dateString, 'DD.MM.YYYY');
    this.currentYear = this.currentDate.year();
    this.currentWeek = this.currentDate.week();
    this.currentDay = this.currentDate.date();
    this.currentMonth = this.currentDate.month() + 1;
    //console.log('data:', this.currentDay, '.', this.currentMonth, '.', this.currentYear, ' week:', this.currentWeek);
  }

  goToday() {
    this.setCurrentDate(moment().format(consts.DATE_FORMAT));
    this.scrollToCurrentDay();
  }

  getCurrentDate() {
    return moment({ year: this.currentYear, month: this.currentMonth, day: this.currentDay });
  }

  async prevMonth() {
    this.firstDate.subtract(7 * 8, 'd');
    await this.fillWeeks();
    const weeks = document.getElementsByClassName('week');
    const week = weeks[0];
    await this.sleep(250);
    week.scrollIntoView({ block: 'start', behavior: 'smooth' });
    this.getAllBorderWeeks();
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async nextMonth() {
    this.lastDate.add(7 * 8, 'd');
    await this.fillWeeks();
    const weeks = document.getElementsByClassName('week');
    const week = weeks[weeks.length - 3];
    await this.sleep(250);
    week.scrollIntoView({ block: 'start', behavior: 'smooth' });
    this.getAllBorderWeeks();
  }

  get currentMonthTitle() {
    return consts.Months[this.currentMonth];
  }

  get currentDayTitle() {
    return moment(this.currentDay + '.' + this.currentMonth + '.' + this.currentYear, consts.DATE_FORMAT).format(
      consts.DATE_FORMAT
    );
  }

  get isEmptyDay() {
    return (day: Day): boolean => {
      return day.exams.length === 0;
    };
  }

  distanceFromTopBorder(element: Element) {
    const rect = element.getBoundingClientRect();
    const scrollBox = document.getElementById('scroll-box').getBoundingClientRect();
    return Math.abs(rect.bottom - scrollBox.height / 2);
  }

  funcLimiter = 0;
  scrollContent() {
    if (this.funcLimiter !== 5) {
      this.funcLimiter++;
      // this.displayScrollButtons();
      return;
    }
    this.funcLimiter = 0;
    let minDistance = 1e20;
    let closestIndex = 0;
    for (let i = 0; i < this.borderWeeks.length; i++) {
      const elementDistance = this.distanceFromTopBorder(this.borderWeeks[i]);
      if (elementDistance < minDistance) {
        closestIndex = i;
        minDistance = elementDistance;
      }
    }
    this.currentMonth = this.elementMonthsData[closestIndex].month;
    this.currentYear = this.elementMonthsData[closestIndex].year;
  }
  displayScrollButtons() {
    const scrollbox = document.getElementById('scroll-box');
    const pixelsScrolled = scrollbox.scrollTop;
    this.scrolledToBegin = pixelsScrolled == 0;
    if (this.scrolledToBegin) return;
    this.scrolledToEnd = pixelsScrolled + scrollbox.clientHeight === scrollbox.scrollHeight;
  }

  borderWeeks: any = [];

  getAllBorderWeeks() {
    this.borderWeeks = document.getElementsByClassName('border-week');
    //console.log('border weeks', this.borderWeeks);
  }

  mounted() {
    moment.updateLocale('pl', { week: { dow: 1 } });

    this.firstDate = moment().subtract(5, 'month').set('date', 1);
    this.lastDate = moment().add(5, 'month');
    this.lastDate.set('date', this.lastDate.daysInMonth());

    this.fillWeeks();
    this.fillDays();

    if (this.$attrs['day'] !== undefined) {
      this.setCurrentDate(this.$attrs['day']);
    } else {
      this.setCurrentDate('01.01.2020');
    }
    this.scrollToCurrentDay();
    this.getAllBorderWeeks();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Kalendarz badań',
          link: '',
        },
      ],
    });
  }

  created() {
    const efo = examsModule.examsFilterOptions;
    if (efo.statusSelected === undefined) {
      this.efo.labCoordOptionSel = EXAMS_ALL_LABORANTS;
      this.efo.asignedOrNotSel = UNASIGNED_EXAMS;
      this.efo.statusSelected = TO_EXAM;
    } else {
      this.efo.labCoordOptionSel = efo.labCoordOptionSel;
      this.efo.asignedOrNotSel = efo.asignedOrNotSel;
      this.efo.statusSelected = efo.statusSelected;
    }
  }
}
