























































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import toolsModule from '../../../store/modules/tools';
import dictModule from '../../../store/modules/dict';
import {
  ToolStatus,
  SortDir,
  LABORANT_ROUTE_PREFIX,
  ToolOperationType,
  DashboardObjectTypes,
  ToolActionStatus,
  DATE_TIME_FORMAT,
} from '../../../consts';
import { EventBus } from '../../../helpers/eventbus';
import { AppView, Tool, ToolGroup, DashboardAction, DashboardItem, User } from '../../../types';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import ModalTool from '../../../components/spec/ModalTool.vue';
import ModalAction from '../../../components/spec/ModalAction.vue';
import ModalToolFilter from '../../../components/spec/ModalToolFilter.vue';
import dashboardItemsModule from '../../../store/modules/dashboard_items';
import userModule from '../../../store/modules/user';
import dataModule from '../../../store/modules/data';
import moment from 'moment';

@Component({
  components: {
    TopPanel,
    Header,
    Footer,
    ModalAction,
    ModalTool,
    ModalToolFilter,
  },
})
export default class ToolsList extends Vue {
  @Ref() readonly modalToolFilter: ModalToolFilter;
  @Ref() readonly modalTool: ModalTool;
  @Ref() readonly modalAction: ModalAction;

  btnPrevVisible = false;
  btnNextVisible = true;

  pageSize = 20;
  pageNo = 1;
  allPagesCount = 0;
  filterParams: { field: ''; value: '' }[] = [];
  sortParams = { field: 'changed_date_time', dir: SortDir.ASC };
  spinner = false;
  tools: Tool[] = [];

  // ------------- GETTERS --------------
  get getLocalization() {
    return (item: any) => {
      if (item.tool.localization !== undefined) {
        return item.tool.localization.name;
      } else {
        return '';
      }
    };
  }
  get getOperList() {
    return (tool: Tool) => {
      return tool.status.opers;
    };
  }

  async fillTools() {
    this.spinner = true;
    this.tools = [];
    let tools = toolsModule.allTools;

    if (this.filterParams.length > 0) {
      console.log('Filtering', this.filterParams);
      tools = this.filterTool(tools, this.filterParams);
    } else {
      console.log('Filter', this.filterParams);
    }

    console.log('Sorting', this.sortParams);
    tools = this.sortTool(tools, this.sortParams.field, this.sortParams.dir);
    let index = 1;
    tools.forEach(t => {
      t.load();
      index++;
      t['index'] = index;
    });
    this.allPagesCount = Math.ceil(tools.length / this.pageSize);
    console.log('Paginating pageSize=', this.pageSize, 'pageNo=', this.pageNo);
    this.tools = this.paginate(tools, this.pageSize, this.pageNo) as Tool[];
    console.log('LOADED ', this.tools.length, ' tools');
    this.spinner = false;
    this.update();
  }

  getBatchColor(toolStatusId: number) {
    return ToolStatus.byId(toolStatusId).badge;
  }
  get getStatusName() {
    return (toolStatusId: number): string => {
      return ToolStatus.byId(toolStatusId).name;
    };
  }

  // ---------------- ROUTES ------------------
  addTool() {
    this.modalTool.showModal();
  }
  editTool(event: any, item: any) {
    const di = new DashboardItem();
    di.enabled = item.tool.status_id === ToolStatus.NEW.id;
    di.object = item.tool;
    di.object_id = item.tool.id;
    console.log('editTool', di);
    this.modalTool.showModal(di);
  }
  modalToolOK(params) {
    console.log('modalToolOK', params);
    params.object.group = dictModule.toolGroupById(params.object.group_id);
    params.object.localization = dictModule.localizationById(params.object.localization_id);
    if (params['new'] === true) {
      toolsModule.addTool(params.object);
    } else {
      toolsModule.updateTool(params.object);
    }
    this.fillTools();
  }
  modalToolCancel() {
    console.log('modalToolCancel');
  }
  get user(): User {
    return userModule.user;
  }
  goLink(tool: Tool, oper: ToolOperationType) {
    const newItem: DashboardItem = new DashboardItem();
    newItem.object = tool;
    newItem.object_id = tool.id;
    newItem.object_type_id = DashboardObjectTypes.TOOL;
    newItem.operation_type_id = oper.id;
    newItem.operation_type = oper;
    newItem.status_id = ToolActionStatus.INITIAL.id;
    newItem.status = ToolActionStatus.INITIAL;
    newItem.person_initiated_id = this.user.id;
    newItem.objectTypeName = 'Urządzenie';

    const lastMove = {
      oldStatusId: -1,
      oldStatus: null,
      newStatusId: ToolActionStatus.INITIAL.id,
      newStatus: ToolActionStatus.INITIAL,
    };
    this.modalAction.showModal({ item: newItem, lastMove: lastMove });
  }
  modalActionOK(params: object) {
    this.$router.push({
      name: 'dashboardactions',
      params: { object_type: DashboardObjectTypes.TOOL.toString() },
    });
  }
  modalActionCancel(params: object) {
    console.log('modalActionCancel', params);
  }
  // ----------------- INTERNAL ACTIONS ----------------
  prevPage() {
    this.pageNo--;
    this.fillTools();
  }

  nextPage() {
    this.pageNo++;
    this.fillTools();
  }

  changePageSize() {
    this.update();
    this.fillTools();
  }

  filterPage() {
    this.modalToolFilter.showModal();
  }

  sortPage(sortField = 'id') {
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.fillTools();
  }

  update() {
    console.log('update', this.pageNo, this.allPagesCount);
    this.btnNextVisible = this.pageNo !== this.allPagesCount;
    this.btnPrevVisible = this.pageNo !== 1;
  }

  sortTool(array: Tool[], field: string, dir: SortDir): Tool[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      case 'id':
      case 'name':
      case 'fabric_number':
      case 'invent_number':
      case 'status_id':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a[field] < b[field] ? 1 : -1;
          } else {
            return a[field] > b[field] ? 1 : -1;
          }
        });
      case 'group.name':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a['group']['name'] < b['group']['name'] ? 1 : -1;
          } else {
            return a['group']['name'] > b['group']['name'] ? 1 : -1;
          }
        });
      case 'localization.name':
        return array.sort((a, b) => {
          if (a['localization'] === undefined || b['localization'] === undefined) {
            console.log("a['localization'] === undefined", a['localization'], b['localization']);
          }
          if (a['localization'] === undefined) {
            if (b['localization'] === undefined) {
              return -1;
            } else {
              if (dir === SortDir.ASC) {
                return b['localization']['name'] > '' ? 1 : -1;
              } else {
                return b['localization']['name'] < '' ? 1 : -1;
              }
            }
          } else if (b['localization'] === undefined) {
            if (a['localization'] === undefined) {
              return -1;
            } else {
              if (dir === SortDir.ASC) {
                return a['localization']['name'] < '' ? 1 : -1;
              } else {
                return a['localization']['name'] > '' ? 1 : -1;
              }
            }
          } else {
            if (dir === SortDir.ASC) {
              return a['localization']['name'] < b['localization']['name'] ? 1 : -1;
            } else {
              return a['localization']['name'] > b['localization']['name'] ? 1 : -1;
            }
          }
        });
      case 'changed_date_time':
        return array.sort((a, b) => {
          //console.log("DATES", moment(a[field], DATE_TIME_FORMAT), moment(b[field], DATE_TIME_FORMAT));
          if (dir === SortDir.ASC) {
            return moment(a[field], DATE_TIME_FORMAT).isAfter(moment(b[field], DATE_TIME_FORMAT)) ? -1 : 1;
          } else {
            return moment(a[field], DATE_TIME_FORMAT).isBefore(moment(b[field], DATE_TIME_FORMAT)) ? -1 : 1;
          }
        });
      default:
        return array;
    }
  }

  filterTool(array: any[], filters: { field: string; value: any }[]) {
    //console.log("Filter Array", filters);
    let result = false;
    return array.filter(method => {
      result = true;
      console.log('########## METHOD ', method.id, method, ' ###########');
      filters.forEach((filter, index) => {
        if (result) {
          const fieldSplitted = filter.field.split('.');
          // console.log('-- FILTER ', index + 1, filter.field, filter.value, 'splitted', fieldSplitted);
          if (fieldSplitted.length === 1) {
            if (typeof filter.value === 'string') {
              // console.log('String', method[filter.field], filter.value);
              result = method[filter.field].toLowerCase().includes(filter.value.toLowerCase());
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              // console.log('Number', method[filter.field], filter.value);
              result = method[filter.field] === filter.value;
            }
          } else if (fieldSplitted.length === 2) {
            if (typeof filter.value === 'string') {
              result = method[fieldSplitted[0]][fieldSplitted[1]].toLowerCase().includes(filter.value.toLowerCase());
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              if (method[fieldSplitted[0]] === undefined) {
                result = false;
              } else {
                result = method[fieldSplitted[0]][fieldSplitted[1]] === filter.value;
              }
            }
          }
        }
      });
      return result;
    });
  }

  paginate(array: Tool[], pageSize: number, pageNumber: number): object[] {
    pageNumber--;
    const rowFrom: number = +(pageNumber * pageSize);
    const rowTo: number = +(pageNumber * pageSize) + +pageSize;
    console.log('Page [' + rowFrom, '-', rowTo + ']');
    let index = 1;
    const templateArray: object[] = [];
    array.forEach(o => {
      //console.log('paginate tool index', index);
      templateArray.push({
        index: index++,
        tool: o,
      });
    });
    return templateArray.slice(rowFrom, rowTo);
  }

  modalToolFilterOK(filters: any) {
    console.log('modaltoolFilterOK FILTERS:', filters);
    this.filterParams = filters;
    this.pageNo = 1;
    this.fillTools();
  }
  modalToolFilterCancel() {
    console.log('modalToolFilterCancel');
  }

  async mounted() {
    console.log('mounted');

    this.fillTools();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Lista urządzeń',
          link: '',
        },
      ],
    });
  }
}
