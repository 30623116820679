














































































































































































import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import visitModule from '../../../store/modules/visit';
import dictModule from '../../../store/modules/dict';
import ModalQuestion from '../../../components/dummy/ModalQuestion.vue';
import { Collect, Protocol, CollectAndExamProtocol, Mixer, Sample, Visit } from '../../../types';
import { NEW_ID_START, ProtocolType, VisitStatus, ViewModes } from '../../../consts';
import * as helpers from '../../../helpers';
import { EventBus, BusEvents } from '../../../helpers/eventbus';

enum ItemType {
  Visit = 1,
  Protocol = 2,
  Mixer = 3,
  Collect = 4,
  Sample = 5,
}

enum CollectTestType {
  AIR = 1,
  CONSISTENCY = 2,
  NONE = 3,
}
enum CollectTestTypeName {
  AIR = 'Powietrze',
  CONSISTENCY = 'Konsystencja',
  NONE = 'Brak badań',
}

@Component({
  components: {
    ModalQuestion,
  },
})
export default class VisitTree extends Vue {
  @Ref() readonly removeProtocolQuestion: ModalQuestion;
  @Ref() readonly removeMixerQuestion: ModalQuestion;
  @Ref() readonly removeCollectQuestion: ModalQuestion;
  @Prop({ type: Boolean }) disabled;
  selected: { obj: object; index: number; type: ItemType } = {
    obj: null,
    index: 0,
    type: ItemType.Mixer,
  };

  enabled = this.visit.status.id === VisitStatus.PENDING.id;

  collectExams = [
    {
      name: CollectTestTypeName.AIR,
      value: CollectTestType.AIR,
    },
    {
      name: CollectTestTypeName.CONSISTENCY,
      value: CollectTestType.CONSISTENCY,
    },
    {
      name: CollectTestTypeName.NONE,
      value: CollectTestType.NONE,
    },
  ];

  btnAddMixer = true;
  btnAddCollect = false;
  btnAddProtocol = false;
  btnAddVisit = false;

  mixerAddVisibleArray = [];
  collectAddVisibleArray = [];
  collectRemoveVisibleArray = [];

  selectedVisitIndex = '';
  selectedProtocolIndex = '';
  selectedMixerIndex = '';
  selectedCollectIndex = '';
  selectedSampleIndex = '';

  minimize = []; // zwiniete

  selectedDestiny = [];
  selectedCollect: { name: string; value: number } = {
    name: CollectTestTypeName.AIR,
    value: CollectTestType.AIR,
  };

  removeProtocol(protocol: Protocol) {
    console.log('removeProtocol', protocol);
    this.removeProtocolQuestion.showModal(protocol);
  }
  removeProtocolOK(sample: Sample) {
    helpers.info('Protokół usunięty...');
  }

  removeMixer(mixer: Mixer) {
    console.log('removeMixer', mixer);
    this.removeMixerQuestion.showModal(mixer);
  }
  
  removeMixerOK(mixer: Mixer) {
    visitModule.removeMixer(mixer);
    this.resetAddVisible();
    helpers.info('Gruszka usunięta...');
  }

  removeCollect(e: any, collect: Collect) {
    console.log('removeCollect', collect);
    this.removeCollectQuestion.showModal({ collect: collect, event: e });
  }

  removeCollectOK(params) {
    visitModule.removeCollect(params.collect);
    this.resetAddVisible();
    helpers.info('Pobranie usunięte...');
    this.$nextTick(() => {
      EventBus.$emit(BusEvents.CHANGE_TREE_VISIBLE, { isTreeVisible: true, event: params.event });
    });
  }

  get collectAddVisible() {
    return (mixer: Mixer) => {
      return this.collectAddVisibleArray.find(m => m.id === mixer.id)?.visible;
    };
  }
  get mixerAddVisible() {
    return (protocol: Protocol) => {
      return this.mixerAddVisibleArray.find(p => p.id === protocol.id)?.visible;
    };
  }
  get collectRemoveVisible() {
    return (collect: Collect) => {
      return this.collectRemoveVisibleArray.find(c => c.id === collect.id)?.visible;
    };
  }
  setMixerAddVisible(protocol: Protocol, visible: boolean) {
    if (protocol.type_id === ProtocolType.PIB) {
      this.resetAddVisible();
      const mixerVisible = this.mixerAddVisibleArray.forEach(p => {
        if (p.id === protocol.id) {
          p.visible = visible;
        }
      });
    }
  }
  setCollectAddVisible(mixer: Mixer, visible: boolean) {
    this.resetAddVisible();
    console.log('setCollectAddVisible', mixer, visible, this.collectAddVisibleArray);
    this.collectAddVisibleArray.forEach(m => {
      if (m.id === mixer.id) {
        m.visible = visible;
      }
    });
  }
  setCollectRemoveVisible(collect: Collect, visible: boolean) {
    this.resetAddVisible();
    this.collectRemoveVisibleArray.forEach(c => {
      if (c.id === collect.id) {
        c.visible = visible;
      }
    });
  }

  resetAddVisible() {
    this.mixerAddVisibleArray = [];
    this.collectAddVisibleArray = [];
    this.collectRemoveVisibleArray = [];
    if (this.visit.collect_and_exam_protocol) {
      this.mixerAddVisibleArray.push({ id: this.visit.collect_and_exam_protocol.id, visible: false });
      this.visit.collect_and_exam_protocol.mixers.forEach(m => {
        this.collectAddVisibleArray.push({ id: m.id, visible: false });
        m.collects.forEach(c => {
          this.collectRemoveVisibleArray.push({ id: c.id, visible: false });
        });
      });
    }
  }

  get visit(): Visit {
    return visitModule.visit;
  }

  get protocolName() {
    return (prot: Protocol): string => {
      console.log('TREE, protocol name::', prot);
      const protObj = new Protocol();
      Object.assign(protObj, prot);
      return protObj.getName();
    };
  }

  collectSelected(e) {
    this.selectedCollect = e;
    this.$forceUpdate();
  }

  btnCollectClick(e) {
    const exam = this.selectedCollect;
    const indexes = this.selectedCollectIndex.split('-');
    const selCollect = this.visit.collect_and_exam_protocol.mixers[indexes[0]].collects[indexes[1]];
    console.log('btnCollect', exam, indexes, selCollect);
    if (exam.value === CollectTestType.AIR) {
      selCollect.air_contents = true;
    }
    if (exam.value === CollectTestType.CONSISTENCY) {
      selCollect.consistency_exam = true;
    }
    if (exam.value === CollectTestType.NONE) {
      selCollect.air_contents = false;
      selCollect.consistency_exam = false;
    }
  }

  isMinimized(pindex, mindex, cindex) {
    if (mindex < 0 && cindex >= 0) {
      console.error('cindex > 0 and mindex < 0 !!!');
      return false;
    }
    if (pindex < 0 && mindex < 0 && cindex < 0) {
      // visit
      return this.minimize.includes('V');
    }
    if (mindex < 0 && cindex < 0) {
      // protocol
      return this.minimize.includes('P' + pindex);
    }
    if (cindex < 0) {
      return this.minimize.includes('P' + pindex + '-M' + mindex);
    }
    return this.minimize.includes('P' + pindex + '-M' + mindex + '-C' + cindex);
  }

  minimise(pindex, mindex, cindex) {
    if (mindex < 0 && cindex >= 0) {
      console.error('minimise cindex > 0 and mindex < 0 !!!');
      return false;
    }
    if (pindex < 0 && mindex < 0 && cindex < 0) {
      if (this.minimize.includes('V')) {
        this.minimize = this.minimize.filter(o => o !== 'V');
      } else {
        this.minimize.push('V');
      }
    }
    if (mindex < 0 && cindex < 0) {
      if (this.minimize.includes('P' + pindex)) {
        this.minimize = this.minimize.filter(o => o !== 'P' + pindex);
      } else {
        this.minimize.push('P' + pindex);
      }
    }
    if (cindex < 0) {
      if (this.minimize.includes('P' + pindex + '-M' + mindex)) {
        this.minimize = this.minimize.filter(o => o !== 'P' + pindex + '-M' + mindex);
      } else {
        this.minimize.push('P' + pindex + '-M' + mindex);
      }
    }
    if (this.minimize.includes('P' + pindex + '-M' + mindex + '-C' + cindex)) {
      this.minimize = this.minimize.filter(o => o !== 'P' + pindex + '-M' + mindex + '-C' + cindex);
    } else {
      this.minimize.push('P' + pindex + '-M' + mindex + '-C' + cindex);
    }
  }
  getVisitClass() {
    return this.selectedVisitIndex === (0).toString() ? 'item-sel' : '';
  }
  getProtocolClass(pindex: number) {
    return this.selectedProtocolIndex === pindex.toString() ? 'item-sel' : '';
  }
  getMixerClass(pindex: number, mindex: number) {
    const no = pindex + '-' + mindex;
    return this.selectedMixerIndex === no.toString() ? 'item-sel' : '';
  }
  getCollectClass(pindex: number, mindex: number, cindex: number) {
    const no = pindex + '-' + mindex + '-' + cindex;
    return this.selectedCollectIndex === no ? 'item-sel' : '';
  }

  get mixers() {
    return (protocolType: ProtocolType): Mixer[] => {
      if (protocolType === ProtocolType.PIB) {
        return this.visit.collect_and_exam_protocol.mixers;
      } else {
        return [];
      }
    };
  }

  get routeParams() {
    return this.$router.currentRoute.params;
  }

  get protocols() {
    const prots = [];
    console.log('Visit', this.visit);
    if ([undefined, null].includes(this.visit.collect_and_exam_protocol) === false) {
      prots.push(this.visit.collect_and_exam_protocol);
    }
    if ([undefined, null].includes(this.visit.collect_protocol) === false) {
      prots.push(this.visit.collect_protocol);
    }
    if ([undefined, null].includes(this.visit.collect_ctb_protocol) === false) {
      prots.push(this.visit.collect_ctb_protocol);
    }
    return prots;
  }

  addMixer(protocol: Protocol) {
    visitModule.addNewMixer({ protocol: protocol as CollectAndExamProtocol });
    this.resetAddVisible();
    this.$forceUpdate();
  }
  addCollect(mixer: Mixer) {
    let maxId = NEW_ID_START;
    this.visit.collect_and_exam_protocol.mixers.forEach(m => {
      const newId = Math.max(...m.collects.map(s => s.id));
      if (newId > maxId) {
        maxId = newId;
      }
    });
    visitModule.addNewCollect({ mixer: mixer, maxId: maxId + 1 });
    const collect = mixer.collects[mixer.collects.length - 1];
    console.log('Col', collect);
    this.resetAddVisible();
    this.$forceUpdate();
  }

  remove() {
    let no = '';
    if (this.selected.type === ItemType.Mixer) {
      no = this.selectedMixerIndex;
      this.visit.collect_and_exam_protocol.mixers.splice(parseInt(no), 1);
    } else if (this.selected.type === ItemType.Collect) {
      no = this.selectedCollectIndex;
      const splitted = no.split('-');
      this.visit.collect_and_exam_protocol.mixers[splitted[0]].collects.splice(splitted[1], 1);
    }
  }
  selectMixer(protocol: Protocol, mixer: Mixer, pindex: number, mindex: number) {
    const item = {
      path: '/lab/visit/',
      name: 'mixer',
      params: {
        ...this.routeParams,
        ...{
          visit_id: this.visit.id.toString(),
          protocol_id: protocol.id.toString(),
          mixer_id: mixer.id.toString(),
          view_mode: ViewModes.FORM,
        },
      },
    };
    console.log('selectMixer:', item);
    this.$router.push(item);
  }
  selectCollect(protocol: Protocol, mixer: Mixer, collect: Collect, pindex, mindex, cindex) {
    const item = {
      path: '/lab/visit/',
      name: 'collect',
      params: {
        ...this.routeParams,
        ...{
          visit_id: this.visit.id.toString(),
          protocol_id: protocol.id.toString(),
          mixer_id: mixer.id.toString(),
          collect_id: collect.id.toString(),
          view_mode: ViewModes.FORM,
        },
      },
    };
    console.log('Push Collect ITEM:', item);
    this.$router.push(item);
  }

  selectProtocol(protocol: Protocol, pindex, mindex, cindex) {
    console.log('Protocol select', protocol);
    this.$router.push({
      name: (protocol as Protocol).getTypeString(),
      path: '/lab/visit/',
      params: {
        ...this.routeParams,
        ...{
          visit_id: this.visit.id.toString(),
          protocol_id: protocol.id.toString(),
          view_mode: ViewModes.FORM,
        },
      },
    });
  }
  selectVisit() {
    this.$router.push({
      name: 'visitmain',
      params: {
        ...this.routeParams,
        ...{
          visit_id: this.visit.id.toString(),
          pending: true.toString(),
        },
      },
    });
  }

  get classGroups() {
    return dictModule.destinyGroups;
  }
  created() {
    this.resetAddVisible();
  }
}
