import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { getModule, Module, Mutation, Action, MutationAction, VuexModule } from 'vuex-module-decorators';
import { ICollectExam } from '@/store/models';
import { CollectExam, CollectExamS, CollectExamF, CollectExamSF } from '@/types';
import { CollectExamType } from '@/consts';
import * as helpers from '@/helpers';
import store from '@/store';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'collectExam',
  store,
  dynamic: true,
})
class CollectExamsModule extends VuexModule {
  public collectExams: CollectExam[] = [];

  public get collectExamById() {
    return (id: number): CollectExam => {
      return this.collectExams.find(ce => ce.id === id);
    };
  }
  public get collectExamCopyById() {
    return (id: number): object => {
      return JSON.parse(JSON.stringify(this.collectExamById(id)));
    };
  }
  get print() {
    return (collectId: number, nested = false) => {
      const exam = this.collectExams.find(e => e.exam_collect_id === collectId);
      console.log('          COLLECT EXAM id:', exam.id, 'type_id', exam.type_id);
    };
  }

  @Mutation
  clear() {
    this.collectExams = [];
    console.log('collectExams module cleared...');
  }

  @Mutation
  replaceCollectExam(collectExam: CollectExam): any {
    console.log('replaceCollectExam mutation', collectExam);
    let ce = this.collectExams.find(ce => ce.id === collectExam.id);
    ce = Object.assign(ce, collectExam);
  }

  @Mutation
  setCollectExams(collect_exams: CollectExam[]) {
    this.collectExams = [];
    collect_exams.forEach(collect_exam => {
      this.collectExams.push(Object.assign(new CollectExam(CollectExamType.byId(collect_exam.type_id)), collect_exam));
    });
  }

  @Mutation
  appendCollectExam(collect_exam: CollectExam) {
    console.log('Append collect exam', collect_exam);
    this.collectExams.push(collect_exam);
  }

  @Mutation
  addCollectExamByJSON(json: object) {
    let collectExam = null;
    switch (json['type_id']) {
      case CollectExamType.S.id:
        collectExam = Object.assign(new CollectExamS(), json);
        break;
      case CollectExamType.F.id:
        collectExam = Object.assign(new CollectExamF(), json);
        break;
      case CollectExamType.SF.id:
        collectExam = Object.assign(new CollectExamSF(), json);
        break;
      default:
        break;
    }
    this.collectExams.push(collectExam);
    console.log('samples::addCollectExamByJSON', collectExam.id);
  }

  @Mutation
  clearCollectExam(collectId: number) {
    this.collectExams = this.collectExams.filter(e => e.exam_collect_id !== collectId);
  }

  /*
  @Action
  updateCollectExamByJSON(json: object) {
    this.clearCollectExam(json['exam_collect_id']);
    this.addCollectExamByJSON(json);
  }*/

  /* ACTIONS */
  @Action
  async updateCollectExam(collect_exam: CollectExam): Promise<AxiosResponse> {
    const response = await API.saveCollectExam(collect_exam);
    if (response.data.success) {
      collect_exam.id = response.data.collect_exam.id;
      this.replaceCollectExam(collect_exam);
      console.log('Replace to VUEX collect_exams', collect_exam);
      return response;
    }
  }
  @Action
  async addCollectExam(collect_exam: CollectExam): Promise<AxiosResponse> {
    const response = await API.saveCollectExam(collect_exam);
    if (response.data.success) {
      collect_exam.id = response.data.collect_exam.id;
      this.appendCollectExam(collect_exam);
      console.log('Added to VUEX collect_exams', collect_exam);
      return response;
    }
  }
  @Action
  @RelatedModel('CollectExam')
  async fetchCollectExams(): Promise<AxiosResponse> {
    console.log('*** FetchCollectExams');
    const response = await API.fetchCollectExams();
    if (response.data.success) {
      this.setCollectExams(response.data.collect_exams);
      return response;
    }
  }

  @Mutation
  loadCollectExamsFromConfig(configDict: ICollectExam[]): any {
    this.collectExams = [];
    let collectExam = null;
    configDict.forEach(ce => {
      switch (ce.type_id) {
        case CollectExamType.S.id:
          collectExam = new CollectExamS();
          Object.assign(collectExam, ce);
          this.collectExams.push(collectExam as CollectExamS);
          break;
        case CollectExamType.F.id:
          collectExam = new CollectExamF();
          Object.assign(collectExam, ce);
          this.collectExams.push(collectExam as CollectExamF);
          break;
        case CollectExamType.SF.id:
          collectExam = new CollectExamSF();
          Object.assign(collectExam, ce);
          this.collectExams.push(collectExam as CollectExamSF);
          break;
        default:
          break;
      }
    });
  }
}

export default getModule(CollectExamsModule);
