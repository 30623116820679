































import * as helpers from '../helpers';
import * as consts from '../consts';
import { Client, DashboardItem, User, UserGroup } from '../types';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import dictModule from '../store/modules/dict';
import ModalClient from '../components/spec/ModalClient.vue';
import ModalUser from '../components/spec/ModalUser.vue';
import ModalChooseTime from '../components/dummy/ModalChooseTime.vue';
import TimeInput from '../components/dummy/TimeInput.vue';
import usersModule from '../store/modules/users';
import userModule from '../store/modules/user';
import moment from 'moment';
import examsModule from '../store/modules/exams';
import ordersModule from '../store/modules/orders';
import visitsModule from '../store/modules/visits';
import protocolssModule from '../store/modules/protocols';
import mixersModule from '../store/modules/mixers';
import collectsModule from '../store/modules/collects';
import samplesModule from '../store/modules/samples';
import offersModule from '../store/modules/offer';
import methodsModule from '../store/modules/methods';
import toolsModule from '../store/modules/tools';

import { create, all } from 'mathjs';
const math = create(all);

@Component({
  components: {
    ModalClient,
    ModalUser,
    TimeInput,
  },
})
export default class Test extends Vue {
  @Ref() readonly modalClient: ModalClient;
  @Ref() readonly modalPerson: ModalUser;

  producerReceiverType: consts.ClientType = 1;
  prodRecvNameValue = '';
  prodRecvCodeValue = 0;
  prodRecvShortNameValue = '';
  enabled = true;
  load_time = '';
  time = '';

  timeChanged(time: string) {
    alert(time);
  }
  //:title="producerReceiverType === ClientType.PRODUCER ? 'Dodaj Producenta' : 'Dodaj Odbiorcę'"
  addProducerReceiver() {
    this.modalClient.showModal();
  }
  modalClientOK(client) {
    console.log('Modal Client OK', client);
    const newClient = new Client();
    newClient.short_name = this.prodRecvNameValue;
    newClient.name = this.prodRecvNameValue;
    //this.protocol.producer_receiver_id = newClient.id;
  }

  async addUser() {
    await usersModule.fetchUsers();
    await usersModule.loadUserRights();
    await usersModule.loadUserGroups();

    this.modalPerson.showModal();
  }
  async changeUser() {
    await usersModule.fetchUsers();
    await usersModule.loadUserRights();
    await usersModule.loadUserGroups();

    const newUser = usersModule.users.find(u => u.id === 18);

    const item = new DashboardItem();
    item.object_type_id = consts.DashboardObjectTypes.PERSONEL;
    item.object = newUser;
    item.enabled = true;

    this.modalPerson.showModal(item);
  }
  async previewUser() {
    await usersModule.fetchUsers();
    await usersModule.loadUserRights();
    await usersModule.loadUserGroups();

    const newUser = usersModule.users.find(u => u.id === 18);

    const item = new DashboardItem();
    item.object_type_id = consts.DashboardObjectTypes.PERSONEL;
    item.object = newUser;
    item.enabled = false;

    this.modalPerson.showModal(item);
  }
  modalPersonOK(params) {
    //usersModule.addUser(params.object);
  }
  async kkp() {
    await usersModule.fetchUsers();
    await usersModule.loadUserRights();
    await usersModule.loadUserGroups();

    await ordersModule.fetchOrders();
    await offersModule.fetchContracts();
    await visitsModule.fetchVisits();
    await protocolssModule.fetchPIBProtocols();
    await mixersModule.fetchMixers();
    await collectsModule.fetchCollects();
    await samplesModule.fetchSamples();
    await examsModule.fetchExamsStages();
    await examsModule.fetchExamStageParams();
    await examsModule.fetchExams();
    await methodsModule.fetchMethods();
    await methodsModule.fetchMethodStages();
    await methodsModule.fetchMethodStageTools();
    await toolsModule.fetchTools();
    await dictModule.fetchClients();
    await dictModule.fetchConstructionPlaces();
    await dictModule.fetchDestinies();
    await dictModule.fetchDestinyGroups();
    await dictModule.fetchConcreteClasses();
    await dictModule.fetchConcreteClassGroups();
    await dictModule.fetchConcreteClassSubgroups();
    await examsModule.fetchExamSampleStageParams();

    const currDate = moment('14.5.2021', consts.DATE_FORMAT);
    const exam = examsModule.examById(36);
    this.$router.push({
      path: 'lab/ce/cem/examcard/',
      name: 'examcard',
      params: {
        date: currDate.date() + '_' + (currDate.month() + 1).toString() + '_' + currDate.year(),
        exam_id: exam.id.toString(),
      },
    });
  }

  modalChooseTimeOK(time: string) {
    this.load_time = time;
  }

  calculateFormula() {
    const symbolValues = {};

    const param1Symbol = '01_Wt_1_r';
    const param2Symbol = '01_Wt_2_r';
    const formula = '($01_Wt_1_r+$01_Wt_2_r)/2';

    symbolValues['$' + param1Symbol] = 13;
    symbolValues['$' + param2Symbol] = 17;

    const val = math.evaluate(formula, symbolValues);

    alert('Formula:' + formula + ' = ' + val);
  }
}
