






















































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, Tool, User, ToolGroup, Localization } from '../../types';
import usersModule from '../../store/modules/users';
import dictModule from '../../store/modules/dict';
import toolsModule from '../../store/modules/tools';
import moment, { Locale } from 'moment';

const NOT_CHOOSEN = 100;

@Component
export default class ModalToolFilter extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  toolName = '';
  toolFabricNumber = '';
  toolInventNumber = '';
  toolGroup = this.toolGroups[0];
  toolLocalization = this.getLocalizations[0];
  toolStatus = this.getStatuses[0];
  toolCar = this.cars[0];

  enabled = false;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;

  get getStatuses() {
    return [
      { id: NOT_CHOOSEN, name: '-- brak wyboru --' },
      { id: consts.ToolStatus.NEW.id, name: consts.ToolStatus.NEW.name },
      { id: consts.ToolStatus.WORKING.id, name: consts.ToolStatus.WORKING.name },
      { id: consts.ToolStatus.CANCELED.id, name: consts.ToolStatus.CANCELED.name },
    ];
  }
  statusSelected(event) {
    this.toolStatus = event;
  }

  get getLocalizations() {
    return [...[{ id: NOT_CHOOSEN, name: '-- brak wyboru --' }], ...dictModule.allLocalizations];
  }
  localizationSelected(event) {
    this.toolLocalization = event;
  }

  get toolGroups() {
    return [...[{ id: NOT_CHOOSEN, name: '-- brak wyboru --' }], ...dictModule.allToolGroups];
  }
  groupSelected(event) {
    this.toolGroup = event;
  }

  get cars() {
    const cars = [...toolsModule.cars];
    cars.sort((a, b) => {
      return parseInt(a.number) - parseInt(b.number);
    });
    cars.forEach(c => {
      c['number_registerNumber'] = c.number + ' - ' + c.register_number;
    });
    cars.unshift({ id: NOT_CHOOSEN, number: '', number_registerNumber: '-- brak wyboru --' });
    return cars;
  }
  carSelected(event) {
    this.toolCar = event;
  }

  showModal() {
    this.show = true;
  }
  addFilter(filters: { field: string; value: any }[], field: string, value: any) {
    filters.push({ field: field, value: value });
  }
  saveModal() {
    this.save = true;
    const filters = [];
    if (this.toolName !== '') {
      this.addFilter(filters, 'name', this.toolName);
    }
    if (this.toolFabricNumber !== '') {
      this.addFilter(filters, 'fabric_number', this.toolFabricNumber);
    }
    if (this.toolInventNumber !== '') {
      this.addFilter(filters, 'invent_number', this.toolInventNumber);
    }
    if (this.toolStatus.id !== NOT_CHOOSEN) {
      this.addFilter(filters, 'status.id', this.toolStatus.id);
    }
    if (this.toolLocalization.id !== NOT_CHOOSEN) {
      this.addFilter(filters, 'localization.id', this.toolLocalization.id);
    }
    if (this.toolGroup.id !== NOT_CHOOSEN) {
      this.addFilter(filters, 'group.id', this.toolGroup.id);
    }
    if (this.toolCar.id !== NOT_CHOOSEN) {
      this.addFilter(filters, 'car.id', this.toolCar.id);
    }
    this.$emit(consts.ModalEvents.OK_EVENT, filters);
    this.show = false;
  }
  clear() {
    this.toolName = '';
    this.toolFabricNumber = '';
    this.toolInventNumber = '';
    this.toolGroup = this.toolGroups[0];
    this.toolCar = this.cars[0];
    this.toolLocalization = this.getLocalizations[0];
    this.toolStatus = this.getStatuses[0];
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
