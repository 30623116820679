
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { Offer, Method, File, OfferAction, Period } from '../../types';
import * as consts from '../../consts';
import multiselect from 'vue-multiselect';
import methodModule from '../../store/modules/methods';
import offerModule from '../../store/modules/offer';
import * as files from '../../store/modules/file';
import * as API from '../../store/api';
@Component({})
export default class FileManager extends Vue {
  get files() {
    this.revalidate;
    return this.fileArray;
  }
  revalidate = 0;
  @Prop({ required: true })
  type: string;
  @Prop({ required: false })
  guid: string;
  @Prop({ required: true })
  data;
  @Prop({ required: true })
  isEnabled: boolean;
  fileArray: File[] = [];
  addAttachment() {
    document.getElementById('fileBuffer').click();
  }
  removeFile(file) {
    files.removeFileById(this.fileArray, file.id);
  }
  get mediaURL() {
    return process.env.VUE_APP_API_URL + 'media';
  }
  async sendFile(e) {
    const form = new FormData();
    form.append('file', e.target.files[0]);
    form.append('guid', this.type === 'TMP' ? this.guid : '');
    form.append('data', JSON.stringify(this.data));
    form.append('type', this.type);
    form.append('filename', e.target.files[0].name);
    await files.saveFileTo(form, this.fileArray);
    console.log('sendFileTemporary obtained file:', this.fileArray);
  }
  async fetchFiles() {
    if (this.data) {
      this.fileArray = this.data.files;
    }
    if (this.type === 'TMP') {
      return;
    }
    const response = await files.fetchFiles(this.data.id, this.type);
    this.fileArray = response.data.files;
  }
  async mounted() {
    if (this.data) {
      await this.fetchFiles();
    }
  }
  @Watch('data')
  async onDataChanged() {
    await this.fetchFiles();
  }
}
