


























import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class PINInput extends Vue {
  @Prop({ required: true }) pinLength: number;
  @Prop({ required: false, default: 'nums' }) allowInput: 'nums' | 'alphanums' | 'all';

  ARROW_LEFT = 'ArrowLeft';
  ARROW_RIGHT = 'ArrowRight';
  BACKSPACE = 'Backspace';

  specialSigns = [this.ARROW_LEFT, this.ARROW_RIGHT, this.BACKSPACE];

  checkIfValid() {
    if (this.code.length === this.pinLength) {
      return true;
    }
    return false;
  }

  private code: Array<string> = [];
  get fullCode() {
    return this.code.join('');
  }

  clear() {
    this.code.forEach(el => {
      el = '';
    });
  }

  is_numeric(str) {
    return /^\d+$/.test(str);
  }

  is_alpha_numeric(str) {
    return /^[a-z0-9]+$/i.test(str);
  }

  inputTypeTest = {
    nums: this.is_numeric,
    alpha_nums: this.is_alpha_numeric,
    all: () => true,
  };

  checkChar(e, i: number) {
    e.preventDefault();
    if (!this.inputTypeTest[this.allowInput](e.data)) {
      this.code[i] = '';
      return;
    }
    this.code[i] = e.data;
  }

  getNthInput(i: number): HTMLInputElement {
    return this.$refs['c' + i][0] as HTMLInputElement;
  }

  nextInput(e: KeyboardEvent, i: number) {
    if (this.specialSigns.indexOf(e.key) === -1) {
      if (!this.inputTypeTest[this.allowInput](e.key)) {
        return;
      }
    }

    const element = e.target as HTMLInputElement;
    let step = element.value.length === 0 ? -1 : 1;

    if (e.key === 'ArrowLeft' || e.key === 'Backspace') {
      step = -1;
    } else if (e.key === 'ArrowRight') {
      step = 1;
    }

    const curr_index = i;
    if (curr_index + step > 3 || curr_index + step < 0) {
      return;
    }
    const n_element = this.getNthInput(curr_index + step) as HTMLInputElement;

    n_element.focus();
    n_element.select();
  }
}
