


































import { Component } from 'vue-property-decorator';
import VisitHeader from '../../../views/laborant/visits/VisitHeader.vue';
import Footer from '../../../components/dummy/Footer.vue';
import { BreadcrumbItem } from '../../../types';
import VisitTree from '../../../views/laborant/visits/VisitTree.vue';
import { EventBus, BusEvents } from '../../../helpers/eventbus';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import visitMixin from '../../../helpers/components/visit';

@Component({
  components: {
    VisitHeader,
    Footer,
    VisitTree,
  },
})
export default class VisitProtocolCollect extends visitMixin {
  get visitTitle() {
    return 'Protokół odebrania ' + this.protocolCollect.number;
  }
  setBreadcrumb() {
    const breadcrumbItems: BreadcrumbItem[] = [
      {
        title: 'Wizyta ' + this.visit.number,
        link: {
          name: 'visitmain',
          params: {
            visit_id: this.visit.id.toString(),
            pending: true.toString(),
          },
        },
      },
      {
        title: this.protocolCollect.getName() + ' ' + this.protocolCollect.number.toString(),
        link: '',
      },
    ];
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: breadcrumbItems,
    });
  }

  created() {
    console.log('%c*********************** ProtocolCollect mounting ***********************', 'color:red');
    this.isTreeVisible = false;
    this.setBreadcrumb();
    window.addEventListener(
      'click',
      function (e: KeyboardEvent) {
        this.checkPanelVisible(e);
      }.bind(this)
    );

    EventBus.$on(BusEvents.CHANGE_TREE_VISIBLE, this.changeVisible);
    console.log('%c####################### ProtocolCollect mounted ######################## ', 'color:red');
  }
}
