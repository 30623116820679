import store from '@/store';
import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ConstructionPlace, File } from '@/types';
import { IHasFileContainer } from '../models';

export async function fetchFiles(id: number, type: string) {
  console.log('*** fetchFiles: id, type', id);
  const response = await API.fetchFiles(id, type);
  if (response.data.success) {
    console.log('fetchFiles response:', response.data);
    return response;
  }
}
export async function removeFileById(container: File[], id: number) {
  const index = container.findIndex(el => el.id === id);
  container.splice(index, 1);
  API.removeFile(id);
}

export async function saveFileTo(form: FormData, container: File[]): Promise<AxiosResponse> {
  console.log('saveFile form:', form);
  const file = new File();
  file.filename = form.get('filename') as string;
  file.uploading = true;
  container.push(file);
  const response = await API.saveFile(form);
  if (response.data.success) {
    console.log(response.data);
    Object.assign(file, response.data.file);
    file.uploading = false;
    return response;
  }
}

export async function deleteFileById(id: number): Promise<AxiosResponse> {
  console.log('Delete file number', id);
  const response = await API.removeFile(id);
  this.removeFileById(id);
  if (response.data.success) {
    return response;
  }
}
