import store from '@/store';
import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Offer, Request, OfferAction, Contract, File, DashboardItem } from '@/types';
import methodModule from './methods';
import userModule from './user';
import * as files from './file';
import 'moment';
import * as consts from '../../consts';
import dashboardItems from './dashboard_items';
import { FormGroupPlugin } from 'bootstrap-vue';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'offer',
  store,
  dynamic: true,
})
class OfferModule extends VuexModule {
  request: Request[] = [];
  offerActions: OfferAction[] = [];
  offerAction: OfferAction = null;
  offer: Offer = null;
  contracts: Contract[] = [];

  get contractById() {
    return (id: number): Contract | undefined => {
      return this.contracts.find(c => c.id === id) || undefined;
    };
  }

  @Mutation
  clear() {
    this.request = [];
    this.offerActions = [];
    this.offerAction = null;
    this.offer = null;
    this.contracts = [];
    console.log('offer module cleared...');
  }

  @Mutation
  loadContractsFromConfig(configDict: Contract[]): any {
    this.contracts = configDict;
    console.log('Store module OfferModule loadContractsFromConfig..', this.contracts.length);
  }
  @Mutation
  appendContract(contract: Contract) {
    this.contracts.push(contract);
  }

  @Mutation
  appendOfferAction(offerAction: OfferAction) {
    this.offerActions.push(offerAction);
  }
  @Mutation
  addOfferAction(offer: OfferAction) {
    console.log('adding new offer action');
    this.offerActions.push(offer);
  }
  public get offerActionById() {
    return (id: number): OfferAction => {
      console.log('offerActionByID', this.offerActions);
      return this.offerActions.find(el => el.id === id);
    };
  }
  public get currentOffer() {
    return this.offer;
  }
  public get allOfferActions(): OfferAction[] {
    return this.offerActions;
  }
  public get currentOfferAction(): OfferAction {
    return this.offerAction;
  }
  @Action
  async addContract(contract: Contract): Promise<AxiosResponse> {
    console.log('*** addContract');
    const response = await API.saveContract(contract);
    if (response.data.success) {
      const changedContract = response.data.contract;
      contract.id = changedContract.id;
      this.appendContract(contract);
      console.log('Added to VUEX contracts', changedContract, 'contract');
      return response;
    }
  }
  @Mutation
  updateOffer(offer: Offer) {
    let swap = this.offerAction.offers.find(el => el.id === offer.id);
    swap = offer;
  }
  @Mutation
  setOffer(offer: Offer) {
    console.log('setOffer');
    this.offer = offer;
  }
  @Mutation
  setOfferAction(offer: OfferAction) {
    this.offerAction = Object.assign(new OfferAction(), offer);
    console.log('setofferaction', this.offerAction);
  }
  @Mutation
  setOfferActions(offerActions: OfferAction[]) {
    console.log('setOfferActions: ', offerActions);
    this.offerActions = [];
    offerActions.forEach(element => {
      const offerAction = Object.assign(new OfferAction(), element);
      offerAction.load();
      this.offerActions.push(offerAction);
    });
  }

  loadOfferActions() {
    this.offerActions.forEach(elemenet => {
      elemenet.load();
    });
  }

  loadOffers() {
    this.offerAction.offers.forEach(el => {
      el.load();
    });
  }

  @Mutation
  setOffers(offers: Offer[]) {
    this.offerAction.offers = [];
    offers.forEach(el => {
      const offer = Object.assign(new Offer(), el);
      this.offerAction.offers.push(offer);
    });
  }
  @Mutation
  setContracts(contracts: Contract[]) {
    this.contracts = [];
    contracts.forEach(contract => {
      this.contracts.push(Object.assign(new Contract(), contract));
    });
  }
  @Mutation
  setOfferActionReference(offer: OfferAction) {
    console.log('set offer', this.offer);
    this.offerAction = offer;
  }
  @Action
  async newOfferActionNumber(): Promise<string> {
    const response = await API.obtainOfferActionNumber();
    if (response.data.success) {
      return response.data.number;
    } else return '';
  }
  @Action({ rawError: true })
  async saveOfferActionAsItem() {
    console.log('Save as item!');
    const item = new DashboardItem();
    item.object_id = this.offerAction.id;
    item.object_type = consts.DashboardObjectTypes.OFFER;
    item.object_type_id = consts.DashboardObjectTypes.OFFER;
    item.status_id = consts.OfferActionStatus.REQUEST.id;
    item.comment = '';
    item.operation_type_id = consts.OfferOperationType.ADD.id;
    item.person_initiated_id = userModule.wholeUser.id;
    const result = await dashboardItems.addItem(item);
    return result;
  }

  @Action({ rawError: true })
  async saveOfferAction(offerAction: OfferAction): Promise<AxiosResponse> {
    console.log('*** saveOfferAction');
    console.log('offer', offerAction);
    const response = await API.saveOfferAction(offerAction);
    if (response.data.success) {
      console.log('Successfully added new offerAction');
      if (this.offerAction.guid !== undefined) {
        this.setOfferAction(response.data.offer as OfferAction);
        await this.saveOfferActionAsItem();
      }
      return response;
    }
  }
  @Action
  @RelatedModel('Contract')
  async fetchContracts(): Promise<AxiosResponse> {
    console.log('*** FetchContract');
    const response = await API.fetchContracts();
    if (response.data.success) {
      this.setContracts(response.data.contracts);
      return response;
    }
  }
  @Action({ rawError: true })
  async fetchOffersClient(clientId: number): Promise<AxiosResponse> {
    console.log('*** FetchOffersClient');
    const response = await API.fetchOfferActionsClient(clientId);
    if (response.data.success) {
      this.setOfferActions(response.data.offerActions);
      this.fetchEachActionOffers();
      return response;
    }
  }
  @Action({ rawError: true })
  async fetchEachActionOffers() {
    this.offerActions.forEach(async element => {
      const reponse = await API.fetchOffers(element.id);
      const offers: Offer[] = reponse.data.offers;
      element.offers = [];
      offers.forEach(offer => {
        element.offers.push(offer);
      });
      console.log('loaded', element);
    });
  }
  @Action({ rawError: true })
  @RelatedModel('OfferAction')
  async fetchOfferActions(): Promise<AxiosResponse> {
    console.log('*** FetchOffersActions');
    const response = await API.fetchOfferActions();
    if (response.data.success) {
      this.setOfferActions(response.data.offerActions);
      return response;
    }
  }
  @Action
  @RelatedModel('Offer')
  async fetchOffers(): Promise<AxiosResponse> {
    console.log('*** FetchOffers');
    const response = await API.fetchOffers(this.offerAction.id);
    if (response.data.success) {
      this.setOffers(response.data.offers);
      return response;
    }
  }
}

export default getModule(OfferModule);
