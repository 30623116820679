import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { UserRightSymbols, NEW_ID_START, PersonelActionStatus, UserStatus } from '@/consts';
import { User, UserGroup, UserRight } from '@/types';
import { IUser } from '@/store/models';
import userModule from './user';
import store from '@/store';
import moduleManager from './moduleManager';
import { RelatedModel } from './decorators';
@Module({
  namespaced: true,
  name: 'users',
  store,
  dynamic: true,
})
class UsersModule extends VuexModule {
  users: Array<User> = [];
  groups: UserGroup[] = [];
  rights: UserRight[] = [];

  get VISIT_PREVIEW_RIGHT() {
    console.log('VISIT_PREVIEW_RIGHT', this.rights);
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_PREVIEW);
  }
  get VISIT_EDIT_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_EDIT);
  }
  get VISIT_TO_CORRECT_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_TO_CORRECT);
  }
  get VISIT_MAKE_CORRECT_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_MAKE_CORRECT);
  }
  get VISIT_AUTHORISE_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_AUTHORISE);
  }
  get VISIT_START_SIGN_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_START_SIGN);
  }
  get VISIT_REVERT_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_REVERT);
  }
  get VISIT_CANCEL_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_CANCEL);
  }
  get VISIT_COORD_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_COORD);
  }
  get VISIT_VERIFY_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_VERIFY);
  }
  get VISIT_START_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_START);
  }
  get VISIT_OPEN_AGAIN_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_OPEN_AGAIN);
  }
  get VISIT_ACCEPT_RIGHT() {
    return this.rights.find(r => r.symbol === UserRightSymbols.VISIT_ACCEPT);
  }

  public get userById() {
    return (id: number): User => {
      return this.users.find(o => o.id === id);
    };
  }

  public get userCopyById() {
    return (id: number): User => {
      return JSON.parse(JSON.stringify(this.userById(id)));
    };
  }

  public get getWorkingUsers() {
    return (users: User[]) => {
      return users.filter(user => user.status_id === UserStatus.WORKING.id);
    };
  }

  public get allUsers(): User[] {
    return this.users;
  }

  get allGroups() {
    return this.groups;
  }
  get allRights() {
    console.log('rights len ', this.rights.length);
    return this.rights;
  }
  public get getGroupById() {
    return (id: number): UserGroup | undefined => {
      return this.groups.find(g => g.id === id) || undefined;
    };
  }

  public get getGroupBySymbol() {
    return (symbol: string): UserGroup | undefined => {
      return this.groups.find(g => g.symbol === symbol) || undefined;
    };
  }

  public get getRightById() {
    return (id: number): UserRight | undefined => {
      return this.rights.find(r => r.id === id) || undefined;
    };
  }

  public get getRightBySymbol() {
    return (symbol: string): UserRight | undefined => {
      //console.log("getRightBySymbol", symbol, this.rights);
      return (
        this.rights.find(r => {
          //console.log("r.symbol === symbol", r.symbol, symbol);
          return r.symbol === symbol;
        }) || undefined
      );
    };
  }

  public get hasRight() {
    return (user: User, rightSymbol: string): boolean => {
      const right = this.getRightBySymbol(rightSymbol);
      if (right !== undefined) {
        return user.right_ids.includes(right.id);
      } else {
        return false;
      }
    };
  }

  get printRight() {
    return (r: UserRight, title: string) => {
      console.log('[' + title + '] RIGHT id:', r.id, 'name:', r.name, 'symbol:', r.symbol);
    };
  }

  get printRights() {
    return (array: UserRight[], title: string) => {
      array.forEach(r => {
        console.log('[' + title + '] RIGHT id:', r['id'], 'name:', r['name'], 'symbol:', r['symbol']);
      });
      return 1;
    };
  }

  get getAllUserRightsList() {
    return (user: User): UserRight[] => {
      const rights: UserRight[] = [];
      user.right_ids.forEach(r => {
        const right = this.getRightById(r);
        if (right !== undefined) {
          rights.push(right);
        }
      });

      user.group_ids.forEach(g => {
        const group = this.getGroupById(g);
        group.right_ids.forEach(r => {
          if (rights.find(rx => rx.id === r) === undefined) {
            rights.push(this.getRightById(r));
          }
        });
      });
      return rights;
    };
  }

  // np. wszyscy userzy dla ktorych istnieje tablica wizyt
  get getUsersByRightSymbol() {
    return (rightSymbol: string): User[] => {
      const right = this.getRightBySymbol(rightSymbol);
      if (right !== undefined) {
        return this.users.filter(u => u.right_ids.includes(right.id));
      }
    };
  }
  get getUsersByRightSymbolInAllRights() {
    return (rightSymbol: string): User[] => {
      if ([undefined, null, ''].includes(rightSymbol) === false) {
        return this.users.filter(
          u =>
            this.getAllUserRightsList(u)
              .map(r => r.symbol)
              .includes(rightSymbol) === true
        );
      }
    };
  }

  @Mutation
  clear() {
    this.users = [];
    this.groups = [];
    this.rights = [];
    console.log('Users module cleared...');
  }

  @Mutation
  setUsers(users: User[]) {
    this.users = [];
    users.forEach(u => {
      this.users.push(Object.assign(new User(), u));
    });
  }

  @Mutation
  appendUser(user: User) {
    this.users.push(user);
  }

  @Mutation
  replaceUser(user: User): any {
    //console.log('replaceUser mutation', client);
    const u = this.users.find(v => v.id === user.id);
    Object.assign(u, user);
  }

  @Mutation
  loadUsersFromConfig(users: IUser[]) {
    this.users = users.map(u => Object.assign(new User(), u));
    console.log('Store module Users loadUsersFromConfig..', this.users.length);
  }

  @Mutation
  loadUserGroupsFromConfig(groups: UserGroup[]) {
    this.groups = groups.map(g => Object.assign(new UserGroup(), g));
    console.log('Store module Users loadUserGroupsFromConfig..', this.groups.length);
  }
  @Mutation
  loadUserRightsFromConfig(rights: UserRight[]) {
    this.rights = rights.map(r => Object.assign(new UserRight(), r));
    console.log('Store module Users loadUserRightsFromConfig..', this.rights.length);
  }

  @Mutation
  setGroups(groups: UserGroup[]) {
    this.groups = [];
    this.groups = groups.map(g => Object.assign(new UserGroup(), g));
  }

  @Mutation
  setRights(rights: UserRight[]) {
    this.rights = [];
    rights.forEach(r => {
      //console.log('Adding right', r.symbol);
      this.rights.push(Object.assign(new UserRight(), r));
    });
  }

  @Action({ rawError: true })
  async addUser(user: User): Promise<AxiosResponse> {
    const response = await API.saveUser(user);
    if (response.data.success) {
      const newUser = Object.assign(new User(), response.data.user);
      this.appendUser(newUser);
    } else {
      console.log('addUser Error', response.data.error_code);
    }
    return response;
  }

  @Action
  async deactivateUser(id: number): Promise<AxiosResponse> {
    const response = await API.deactivateUser(id);
    if (response.data.success === true) {
      const user = this.userById(id);
      user.is_active = false;
      this.replaceUser(user);
    }
    return response;
  }

  @Action
  async activateUser(id: number): Promise<AxiosResponse> {
    const response = await API.activateUser(id);
    if (response.data.success === true) {
      const user = this.userById(id);
      user.is_active = true;
      this.replaceUser(user);
    }
    return response;
  }

  @Action({ rawError: true })
  async updateUser(user: User): Promise<AxiosResponse> {
    const response = await API.saveUser(user);
    if (response.data.success) {
      const newUser = Object.assign(new User(), response.data.user);
      this.replaceUser(newUser);
    }
    return response;
  }

  @Action({ rawError: true })
  //@RelatedModel('UserData')
  async fetchUsers(): Promise<AxiosResponse> {
    console.log('*** FetchUsers');
    const response = await API.fetchUsers();
    if (response.data.success) {
      this.setUsers(response.data.users);
      console.log('   fetchUsers VUEX DONE:', this.users.length);
      return response;
    }
  }

  @Action
  async loadUserRights(): Promise<AxiosResponse> {
    console.log('loadUserRights');
    const response = await API.getUserRights();
    if (response.data.success) {
      this.setRights(response.data.rights);
      return response;
    }
  }

  @Action
  async loadUserGroups(): Promise<AxiosResponse> {
    console.log('loadUserGroups');
    const response = await API.getUserGroups();
    if (response.data.success) {
      this.setGroups(response.data.groups);
      return response;
    }
  }
}

export default getModule(UsersModule);
