




































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { Offer, Method, File, OfferAction, Period } from '../../types';
import * as consts from '../../consts';
import multiselect from 'vue-multiselect';
import methodModule from '../../store/modules/methods';
import offerModule from '../../store/modules/offer';
@Component({})
export default class OfferLumpSumSettlement extends Vue {
  methods: Method[] = [];
  offerAction: OfferAction;
  currentMethod: Method = null;
  selectedMethods: Method[] = [];
  periods: Period[] = [];

  @Prop({ required: true })
  isEnabled: boolean;
  @Prop({ required: true })
  offer: Offer;
  singleVisitCost = '';
  visitCost = '';
  visitCount = '';
  startDateText = '';
  startDate = '';
  endDateText = '';
  endDate = '';

  isClient(): boolean {
    return this.$route.fullPath.includes('client');
  }

  get notSelectedMethods() {
    return this.methods.filter(el => !this.selectedMethods.includes(el));
  }
  removeMethod(method) {
    console.log('Remove:', method);
    this.selectedMethods = this.selectedMethods.filter(el => el.id !== method.id);
    this.offer.methods = this.selectedMethods;
  }
  methodSelected(e) {
    console.log('Selected method', e);
    //this.offer.methods.push(e);
    this.currentMethod = e;
  }
  onStartDateContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.startDate = dt.format('YYYY-MM-DD');
      this.startDateText = ctx.selectedFormatted;
    }
  }
  onEndDateContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.endDate = dt.format('YYYY-MM-DD');
      this.endDateText = ctx.selectedFormatted;
    }
  }

  addPeriod() {
    const period = new Period();
    period.start_date = this.startDate;
    period.end_date = this.endDate;
    period.visit_count = this.visitCount;
    period.price = parseInt(this.visitCost);
    this.periods.push(period);
    console.log('addedPeriod', this.offer);
  }

  addMethodToLumpSum() {
    this.selectedMethods.push(this.currentMethod);
    this.currentMethod = null;
  }

  @Watch('offer')
  onOfferChanged() {
    this.selectedMethods = this.offer.methods;
    this.periods = this.offer.periods;
  }

  created() {
    this.methods = methodModule.allMethods;
    this.periods = this.offer.periods;
    this.selectedMethods = this.offer.methods;
    console.log('loaded lump sum settlement', this.offer);
  }
}
