










import { Component, Vue, Watch } from "vue-property-decorator";
import userModule from "../../store/modules/user";
import usersModule from "../../store/modules/users";
import visitsModule from "../../store/modules/visit";
import { User} from "../../types";
import * as consts from "../../consts";
import TopPanel from "../../components/dummy/TopPanel.vue";
import moment from "moment";
import visit from "../../store/modules/visit";
import * as LS from "../../helpers/storage";

@Component({
  components: {
    TopPanel,
  },
})
export default class CalendarVisits extends Vue {}
