


















































































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import VisitMixin from '../../../helpers/components/visit';
import dictModule from '../../../store/modules/dict';

import { Sample, Collect } from '../../../types';
import { DATE_FORMAT, CollectExamType } from '../../../consts';
import * as consts from '../../../consts';

@Component
export default class VisitProtocolPIBPrintTable extends VisitMixin {
  @Prop({ type: Array, required: true }) samples: Sample[];

  CollectExamTypeS = CollectExamType.S;
  CollectExamTypeF = CollectExamType.F;
  CollectExamTypeSF = CollectExamType.SF;

  get visitDateTime() {
    if (moment(this.visit.date, consts.DB_DATE_TIME_FORMAT, true).isValid()) {
      return moment(this.visit.date, consts.DB_DATE_TIME_FORMAT).format('DD.MM');
    }
    return moment(this.visit.date, DATE_FORMAT).format('DD.MM');
  }
  isConsistency(id: number) {
    for (let i = 0; i < this.samples.length; i++) {
      if (this.samples[i] !== null) {
        if (this.collect(this.samples[i]).consistency_exam && this.collect(this.samples[i]).exam_type_id === id) {
          return true;
        }
      }
    }
    return false;
  }
  get isConsistencyS() {
    return this.isConsistency(this.CollectExamTypeS.id);
  }
  get isConsistencyF() {
    return this.isConsistency(this.CollectExamTypeF.id);
  }
  get isConsistencySF() {
    return this.isConsistency(this.CollectExamTypeSF.id);
  }
  get isAirContents() {
    for (let i = 0; i < this.samples.length; i++) {
      if (this.samples[i] !== null) {
        if (this.collect(this.samples[i]).air_contents) {
          return true;
        }
      }
    }
    return false;
  }

  getSampleShortNumber(sample: Sample) {
    return sample.number.split('/')[2];
  }
  get cplaceSymbol() {
    return dictModule.cPlaceById(this.visit.cplace_id).symbol;
  }
  collect(sample: Sample) {
    let col: Collect = null;
    this.protocol.mixers.forEach(mixer => {
      mixer.collects.forEach(collect => {
        if (collect !== null) {
          if (collect.id === sample.collect_id) {
            col = collect;
          }
        }
      });
    });
    return col;
  }
  destiny(id: number) {
    return dictModule.destinyById(id);
  }
  mixer(sample: Sample) {
    if (sample !== null) {
      return this.protocol.mixers.find(m => m.id === this.collect(sample).mixer_id);
    } else {
      return [];
    }
  }
  numberToDisplay(value: any) {
    if (value === '') {
      return value;
    }
    value = value.replace('.', ',');
    return value.includes(',') === true ? value : value + ',0';
  }
  collectExam(sample: Sample) {
    const collect = this.collect(sample);
    return collect.exam;
  }
}
