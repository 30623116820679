






































import { Vue, Component } from 'vue-property-decorator';

import dictModule from '@/store/modules/dict';

import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import DestinyModal from '@/components/spec/ModalDestiny.vue';
import breadcrumbModule from '@/store/modules/breadcrumb';
import { Destiny, DestinyGroup } from '@/types';

const ADD_NEW_GROUP = 10;

@Component({
  components: {
    TopPanel,
    Header,
    DestinyModal,
    Footer,
  },
})
export default class SamplesDestiny extends Vue {
  ADD_NEW_GROUP = ADD_NEW_GROUP;

  addDestiny(group: boolean, destiny: Destiny | DestinyGroup | number) {
    (this.$refs.destinyModal as DestinyModal).showModal(group, destiny);
  }

  destiniesOfGroup(id: number): Array<Destiny> {
    return dictModule.destinies.filter(el => el.group_id === id);
  }

  get destinyGroups(): Array<DestinyGroup> {
    return dictModule.destinyGroups;
  }
  get destinies(): Array<Destiny> {
    return dictModule.destinies;
  }

  created(): void {
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Lista przeznaczeń próbek',
          link: '',
        },
      ],
    });
  }
}
