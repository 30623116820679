



































































































import { Component, Watch, Ref } from 'vue-property-decorator';
import Footer from '../../../components/dummy/Footer.vue';
import DashboardOfferHeader from '../../../components/spec/DashboardOfferHeader.vue';
import DashboardMixin from '../../../helpers/components/dashboard';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import * as consts from '../../../consts';
import dashboardItemsModule from '../../../store/modules/dashboard_items';
import { DashboardItem, DashboardEntity, DashboardColumn } from '../../../types';
import draggable from 'vuedraggable';
import ModalAction from '../../../components/spec/ModalAction.vue';
import ModalTool from '../../../components/spec/ModalTool.vue';
import ModalMethod from '../../../components/spec/ModalMethod.vue';
import ModalUser from '../../../components/spec/ModalUser.vue';
import usersModule from '../../../store/modules/users';
import offersModule from '../../../store/modules/offer';

@Component({
  components: {
    DashboardOfferHeader,
    Footer,
    TopPanel,
    draggable,
    ModalAction,
    ModalTool,
    ModalMethod,
    ModalUser,
  },
})
export default class DashboardOffer extends DashboardMixin {
  @Ref() readonly modalAction: ModalAction;
  @Ref() readonly modalTool: ModalTool;
  @Ref() readonly modalMethod: ModalMethod;
  @Ref() readonly modalPerson: ModalUser;

  cols: DashboardColumn[] = [];
  fullscreen = false;
  objectType: consts.DashboardObjectTypes = consts.DashboardObjectTypes.OFFER;
  colWitdth = '0';
  invalidate = 0;
  lastMove = {
    oldIndex: -1,
    oldStatusId: -1,
    oldStatus: null,
    newStatusId: -1,
    newIndex: -1,
    newStatus: null,
  };

  get getNumber() {
    return (item: DashboardItem) => {
      console.log('Entity Obj', item);
      return (item.object as DashboardEntity).getNumber;
    };
  }
  get getColumnHeight() {
    const itemHeight = 200;
    let max = 1200;
    this.cols.forEach(col => {
      if (col.items) {
        if (col.items.length * itemHeight > max) {
          max = col.items.length * itemHeight;
        }
      }
    });
    return 200 + max + 'px';
  }
  getColWidth() {
    return (this.$refs.contentBox as HTMLFormElement).clientWidth / this.cols.length - 10;
  }

  getColByStatusId(statusId: number): DashboardColumn {
    return this.cols.find(col => col.statusId === statusId);
  }

  fillColsItems() {
    this.cols = this.cols.map(col => {
      return this.fillItems(col);
    });
  }

  fillItems(column: DashboardColumn) {
    this.invalidate;
    const items: DashboardItem[] = dashboardItemsModule.allItems.filter(el => {
      return el.object_type_id === this.objectType && el.status_id === column.statusId;
    });
    console.log('------------------ Items', dashboardItemsModule.allItems);
    column.items = [];
    items.forEach((t, index) => {
      console.log('foreach', t, index);
      t.load();
      t.index = index;
      t.columnStatusId = column.statusId;
      t.enabled = column.editable;
      //column.items.push(t);
      this.$set(column.items, index, t);
    });
    console.log('------------------- Items loaded', column);
    return column;
  }

  change(event) {
    console.log('change');
  }
  move(event) {
    console.log('move', event);
    return true;
  }
  add(event) {
    console.log('add', event);
  }
  startDrag(event: any) {
    console.log('startDrag', event);
  }
  endDrag(event: any) {
    console.log('endDrag', event, event.oldIndex, event.from.id, ' => ', event.to.id, event.newIndex);
    if (event.from.id !== event.to.id) {
      const oldCol: DashboardColumn = this.getColByStatusId(parseInt(event.from.id));
      console.log('OLD COL:', oldCol, oldCol.items);
      const newCol: DashboardColumn = this.getColByStatusId(parseInt(event.to.id));
      console.log('NEW COL:', newCol, newCol.items);

      this.lastMove = {
        oldIndex: event.oldIndex,
        oldStatusId: parseInt(event.from.id),
        oldStatus: oldCol.status,
        newStatusId: parseInt(event.to.id),
        newIndex: event.newIndex,
        newStatus: newCol.status,
      };

      const newItem = newCol.items[parseInt(event.newIndex)];

      this.modalAction.showModal({ item: newItem, lastMove: this.lastMove });
      console.log('showModal');
    }
  }
  get dragOptions() {
    return {
      animation: 150,
      disabled: false,
      ghostClass: 'ghost',
      handle: '.tile-handle',
    };
  }

  modalEntity(item: DashboardItem) {
    console.log('item.object_type_id', item.object_type_id);
    if (item.object_type_id === consts.DashboardObjectTypes.METHOD) {
      this.modalMethod.showModal(item);
    } else if (item.object_type_id === consts.DashboardObjectTypes.TOOL) {
      this.modalTool.showModal(item);
    } else if (item.object_type_id === consts.DashboardObjectTypes.PERSONEL) {
      this.modalPerson.showModal(item);
    } else if (item.object_type_id === consts.DashboardObjectTypes.OFFER) {
      this.$router.push({ path: 'offerQuestion/' + item.object.id });
    }
  }

  modalActionOK(params: object) {
    const newCol: DashboardColumn = this.getColByStatusId(this.lastMove.newStatusId);
    newCol.items.forEach((item, index) => (item.index = index));
    const newItem = params['item'];
    const newAction = params['action'];

    console.log('Setting object status', newItem, newAction, newItem.object);
    const doneActionStatus = (newItem.object as DashboardEntity).getDONEActionStatus;
    console.log('doneActionStatus', doneActionStatus['id'], newAction.to_status_id);
    if (newAction.to_status_id === doneActionStatus['id']) {
      newItem.object.update();
    }

    this.fillColsItems();
  }
  modalActionCancel(params: object) {
    console.log('modalAction Cancel', params);
    if (!params['save']) {
      const newCol: DashboardColumn = this.getColByStatusId(this.lastMove.newStatusId);
      const oldCol: DashboardColumn = this.getColByStatusId(this.lastMove.oldStatusId);
      console.log('Revert to old col', this.lastMove.newIndex, newCol, this.lastMove);
      oldCol.items.splice(this.lastMove.oldIndex, 0, newCol.items[this.lastMove.newIndex]);
      oldCol.items.forEach((t, index) => (t.index = index));
      console.log('removing ', this.lastMove, newCol);
      newCol.items = newCol.items.filter(t => t.index !== this.lastMove.newIndex);
      newCol.items.forEach((t, index) => (t.index = index));
    }
  }

  /******************************* TOOL ********************************/
  colsTOOL() {
    this.objectType = consts.DashboardObjectTypes.TOOL;
    this.cols = [
      new DashboardColumn(consts.ToolActionStatus.CANCELLED, 0),
      new DashboardColumn(consts.ToolActionStatus.INITIAL, 1),
      new DashboardColumn(consts.ToolActionStatus.PENDING, 2, true),
      new DashboardColumn(consts.ToolActionStatus.TOACCEPT, 3),
      new DashboardColumn(consts.ToolActionStatus.DONE, 4),
    ];
  }
  modalToolActionOK(params) {
    this.invalidate++;
    console.log('ModalToolActionOK', params);
  }
  modalToolActionCancel() {
    console.log('ModalToolActionCancel');
  }

  /******************************* METHOD ********************************/
  colsMETHOD() {
    this.objectType = consts.DashboardObjectTypes.METHOD;
    this.cols = [
      new DashboardColumn(consts.MethodActionStatus.CANCELLED, 0),
      new DashboardColumn(consts.MethodActionStatus.INITIAL, 1),
      new DashboardColumn(consts.MethodActionStatus.PENDING, 2, true),
      new DashboardColumn(consts.MethodActionStatus.TOACCEPT, 3),
      new DashboardColumn(consts.MethodActionStatus.DONE, 4),
    ];
  }
  modalMethodActionOK(params) {
    usersModule.updateUser(params.object);
    console.log('ModalMethodActionOK', params.object);
    this.invalidate++;
  }
  modalMethodActionCancel() {
    console.log('ModalMethodActionCancel');
  }

  /******************************* PERSONEL ********************************/
  colsPERSONEL() {
    this.objectType = consts.DashboardObjectTypes.PERSONEL;
    this.cols = [
      new DashboardColumn(consts.PersonelActionStatus.CANCELLED, 0),
      new DashboardColumn(consts.PersonelActionStatus.INITIAL, 1),
      new DashboardColumn(consts.PersonelActionStatus.PENDING, 2, true),
      new DashboardColumn(consts.PersonelActionStatus.TOACCEPT, 3),
      new DashboardColumn(consts.PersonelActionStatus.DONE, 4),
    ];
  }
  colsOFFER() {
    this.objectType = consts.DashboardObjectTypes.OFFER;
    this.cols = [
      new DashboardColumn(consts.OfferActionStatus.CANCELLED, 0),
      new DashboardColumn(consts.OfferActionStatus.REQUEST, 1),
      new DashboardColumn(consts.OfferActionStatus.OFFER, 2, true),
      new DashboardColumn(consts.OfferActionStatus.TOACCEPT, 3),
      new DashboardColumn(consts.OfferActionStatus.CONTRACT, 4),
    ];
  }
  modalPersonActionOK(params) {
    usersModule.updateUser(params.object);
    this.fillColsItems();
  }
  modalPersonActionCancel() {
    console.log('ModalPersonActionCancel');
  }

  async created() {
    this.colsOFFER();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Tablica akcji ofertowych',
          link: '',
        },
      ],
    });
  }
  async mounted() {
    await offersModule.fetchOfferActions();
    await dashboardItemsModule.fetchDashboardItems();
    this.colWitdth = this.getColWidth() + 'px';
    this.fillColsItems();
  }
  @Watch('objectType')
  onObjectTypeChange(oldVal: number, newVal: number) {
    this.fillColsItems();
  }
}
