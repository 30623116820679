


















































































import { Component, Watch, Ref } from 'vue-property-decorator';
import Footer from '../../../components/dummy/Footer.vue';
import DashboardCertHeader from '../../../components/spec/DashboardCertHeader.vue';
import DashboardMixin from '../../../helpers/components/dashboard';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import * as consts from '../../../consts';
import dashboardItemsModule from '../../../store/modules/dashboard_items';
import {
  DashboardItem,
  DashboardEntity,
  DashboardColumn,
  GenericDocument,
} from '../../../types';
import draggable from 'vuedraggable';
import ModalAction from '../../../components/spec/ModalAction.vue';
import ModalTool from '../../../components/spec/ModalTool.vue';
import ModalMethod from '../../../components/spec/ModalMethod.vue';
import ModalDocuments from '../../../components/spec/ModalDocuments.vue';
import ModalUser from '../../../components/spec/ModalUser.vue';
import dataModule from '../../../store/modules/data';
import usersModule from '../../../store/modules/users';
import moment from 'moment';

@Component({
  components: {
    DashboardCertHeader,
    Footer,
    TopPanel,
    draggable,
    ModalAction,
    ModalDocuments,
    ModalTool,
    ModalMethod,
    ModalUser,
  },
})
export default class DashboardAudit extends DashboardMixin {
  @Ref() readonly modalAction: ModalAction;
  @Ref() readonly modalDocuments: ModalDocuments;

  cols: DashboardColumn[] = [];
  fullscreen = false;
  objectType: consts.DashboardObjectTypes = consts.DashboardObjectTypes.CERT;
  colWitdth = '0';
  invalidate = 0;
  lastMove = {
    oldIndex: -1,
    oldStatusId: -1,
    oldStatus: null,
    newStatusId: -1,
    newIndex: -1,
    newStatus: null,
  };

  type = consts.DashboardObjectTypes.AUDIT;

  get getNumber() {
    return (item: DashboardItem) => {
      console.log('Entity Obj', item);
      if (!item.object) return ''; //broken record
      return (item.object as DashboardEntity).number;
    };
  }
  get getColumnHeight() {
    const itemHeight = 200;
    let max = 1200;
    this.cols.forEach(col => {
      if (col.items) {
        if (col.items.length * itemHeight > max) {
          max = col.items.length * itemHeight;
        }
      }
    });
    return 200 + max + 'px';
  }
  getColWidth() {
    return (this.$refs.contentBox as HTMLFormElement).clientWidth / this.cols.length - 10;
  }

  getColByStatusId(statusId: number): DashboardColumn {
    return this.cols.find(col => col.statusId === statusId);
  }

  fillColsItems() {
    this.cols = this.cols.map(col => {
      return this.fillItems(col);
    });
  }

  fillItems(column: DashboardColumn) {
    this.invalidate;
    console.log('obj type id', this.objectType);
    const items: DashboardItem[] = dashboardItemsModule.allItems.filter(di => {
      return di.object_type_id === this.objectType && di.status_id === column.statusId;
    });
    console.log('------------------ Items', items);
    column.items = [];
    items.forEach((t, index) => {
      console.log('foreach', t, index);
      t.load();
      t.index = index;
      t.columnStatusId = column.statusId;
      t.enabled = column.editable;
      this.$set(column.items, index, t);
    });
    console.log('------------------- Items loaded', column);
    return column;
  }

  getInternalState(item: DashboardItem) {
    const audit = item.object as GenericDocument;
    for (const key in consts.AuditInternalState) {
      if (consts.AuditInternalState[key].id === audit.internal) {
        return consts.AuditInternalState[key].name;
      }
    }
  }
  getItemDate(item: DashboardItem) {
    const audit = item.object as GenericDocument;
    return moment(audit.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
  }

  change(event) {
    console.log('change');
  }
  move(event) {
    console.log('move', event);
    return true;
  }
  add(event) {
    console.log('add', event);
  }
  startDrag(event: any) {
    console.log('startDrag', event);
  }
  endDrag(event: any) {
    console.log('endDrag', event, event.oldIndex, event.from.id, ' => ', event.to.id, event.newIndex);
    if (event.from.id !== event.to.id) {
      const oldCol: DashboardColumn = this.getColByStatusId(parseInt(event.from.id));
      console.log('OLD COL:', oldCol, oldCol.items);
      const newCol: DashboardColumn = this.getColByStatusId(parseInt(event.to.id));
      console.log('NEW COL:', newCol, newCol.items);

      this.lastMove = {
        oldIndex: event.oldIndex,
        oldStatusId: parseInt(event.from.id),
        oldStatus: oldCol.status,
        newStatusId: parseInt(event.to.id),
        newIndex: event.newIndex,
        newStatus: newCol.status,
      };

      const newItem = newCol.items[parseInt(event.newIndex)];

      this.modalAction.showModal({ item: newItem, lastMove: this.lastMove });
      console.log('showModal');
    }
    this.fillColsItems();
  }
  get dragOptions() {
    return {
      animation: 150,
      disabled: false,
      ghostClass: 'ghost',
      handle: '.tile-handle',
    };
  }

  modalEntity(item: DashboardItem) {
    console.log('item.object_type_id', item.object_type_id);
    if (item.object_type_id === consts.DashboardObjectTypes.AUDIT) {
      this.modalDocuments.showModal(item);
    }
  }

  modalActionOK(params: object) {
    const newCol: DashboardColumn = this.getColByStatusId(this.lastMove.newStatusId);
    console.log('modalActionOK 1', newCol, this.lastMove);

    newCol.items.forEach((item, index) => (item.index = index));

    const newItem = params['item'];
    const newAction = params['action'];

    this.fillColsItems();
  }
  modalActionCancel(params: object) {
    console.log('modalAction Cancel', params);
    if (!params['save']) {
      const newCol: DashboardColumn = this.getColByStatusId(this.lastMove.newStatusId);
      const oldCol: DashboardColumn = this.getColByStatusId(this.lastMove.oldStatusId);
      console.log('Revert to old col', this.lastMove.newIndex, newCol, this.lastMove);
      oldCol.items.splice(this.lastMove.oldIndex, 0, newCol.items[this.lastMove.newIndex]);
      oldCol.items.forEach((t, index) => (t.index = index));
      console.log('removing ', this.lastMove, newCol);
      newCol.items = newCol.items.filter(t => t.index !== this.lastMove.newIndex);
      newCol.items.forEach((t, index) => (t.index = index));
    }
  }
  async modalCertOK() {
    await dataModule.fetchdocuments();
    await dashboardItemsModule.fetchDashboardItems();
    this.fillColsItems();
  }

  modalToolActionOK(params) {
    this.invalidate++;
    console.log('ModalToolActionOK', params);
  }
  modalToolActionCancel() {
    console.log('ModalToolActionCancel');
  }

  modalMethodActionOK(params) {
    usersModule.updateUser(params.object);
    console.log('ModalMethodActionOK', params.object);
    this.invalidate++;
  }
  modalMethodActionCancel() {
    console.log('ModalMethodActionCancel');
  }
  colsAudit() {
    this.objectType = consts.DashboardObjectTypes.AUDIT;
    this.cols = [
      new DashboardColumn(consts.AuditActionStatus.CANCELLED, 0),
      new DashboardColumn(consts.AuditActionStatus.INITIATED, 1),
      new DashboardColumn(consts.AuditActionStatus.IN_PROGRESS, 2, true),
      new DashboardColumn(consts.AuditActionStatus.ACCEPTED, 3),
      new DashboardColumn(consts.AuditActionStatus.FINISHED, 4),
    ];
  }

  created() {
    this.colsAudit();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Tablica auditów',
          link: '',
        },
      ],
    });
  }
  async mounted() {
    await dataModule.fetchdocuments();
    await dashboardItemsModule.fetchDashboardItems();
    this.colWitdth = this.getColWidth() + 'px';
    this.fillColsItems();
  }
  @Watch('objectType')
  onObjectTypeChange(oldVal: number, newVal: number) {
    this.fillColsItems();
  }
}
