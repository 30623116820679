


















































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import dictModule from "../../../store/modules/dict";
import { TakeExamPlaces } from "../../../consts";
import {
  Collect,
  Protocol,
  CollectAndExamProtocol,
  Destiny,
  DestinyGroup,
  Mixer,
  Sample,
  Visit,
  VisitView,
  CollectExamF,
} from "../../../types";

@Component
export default class VisitCollectExamF extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) enabled: boolean;
  @Prop({ type: Collect }) collect: Collect;

  d1Error = "";
  d2Error = "";

  get getAverageResult() {
    const exam = this.collect.exam as CollectExamF;

    if (isNaN(parseInt(exam.d1))) {
      if (exam.d1 !== "") {
        this.d1Error = "Błędny format liczby";
      }
      return "-";
    }
    if (isNaN(parseInt(exam.d2))) {
      if (exam.d2 !== "") {
        this.d2Error = "Błędny format liczby";
      }
      return "-";
    }
    this.d1Error = "";
    this.d2Error = "";
    return (parseInt(exam.d1) + parseInt(exam.d2)) / 2;
  }
}
