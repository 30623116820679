import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IProtocol } from '@/store/models';
import { CollectAndExamProtocol, CollectCTBProtocol, CollectProtocol, Protocol, Sample } from '@/types';
import { ProtocolType } from '@/consts';
import mixersModule from '@/store/modules/mixers';
import samplesModule from '@/store/modules/samples';
import collectsModule from '@/store/modules/collects';
import store from '@/store';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'protocols',
  store,
  dynamic: true,
})
class ProtocolsModule extends VuexModule {
  public protocols: Protocol[] = [];

  public get allProtocols() {
    return this.protocols;
  }
  public get protocolById() {
    return (id: number): Protocol => {
      return this.protocols.find(p => p.id === id);
    };
  }
  public get protocolCopyById() {
    return (id: number): object => {
      const prot = this.protocolById(id);
      return JSON.parse(JSON.stringify(prot));
    };
  }

  public get protocolsByClientCode() {
    return (client_code: string): CollectAndExamProtocol[] => {
      return this.protocols.filter(
        el =>
          el.client_code === client_code &&
          (el as CollectAndExamProtocol).producer_receiver !== undefined &&
          (el as CollectAndExamProtocol).producer_receiver !== ''
      ) as CollectAndExamProtocol[];
    };
  }

  get pibProtocols(): CollectAndExamProtocol[] {
    const protocols = this.protocols.filter(protocol => protocol.type_id === ProtocolType.PIB);
    const pibProtocols: CollectAndExamProtocol[] = [];
    for (const protocol of protocols) {
      pibProtocols.push(Object.assign(new CollectAndExamProtocol(), protocol));
    }
    return pibProtocols;
  }

  get protocolBySample() {
    return (sample: Sample): Protocol => {
      const collect = collectsModule.collects.find(c => c.sample_ids.includes(sample.id));
      const mixer = mixersModule.mixers.find(m => m.collect_ids.includes(collect.id));
      const p = this.pibProtocols.find(p => p.id === mixer.protocol_id);

      return p;
    };
  }

  get protocolBySampleId() {
    return (id: number): Protocol => {
      const collect = collectsModule.collects.find(c => c.sample_ids.includes(id));
      const mixer = mixersModule.mixers.find(m => m.collect_ids.includes(collect.id));
      const p = this.pibProtocols.find(p => p.id === mixer.protocol_id);

      return p;
    };
  }

  get print() {
    return (visitId: number, nested = false) => {
      this.protocols
        .filter(v => v.visit_id === visitId)
        .forEach(prot => {
          console.log('    PROTOCOL: id:', prot.id, 'number', prot.number, 'type', prot.type_id);
          if (nested) {
            if (prot.type_id === ProtocolType.PIB) {
              mixersModule.print(prot.id, nested);
            } else if (prot.type_id === ProtocolType.COLLECT_CTB) {
              samplesModule.printProtCTBSamples(prot.id, nested);
            }
          }
        });
    };
  }

  @Mutation
  clear() {
    this.protocols = [];
    console.log('protocols module cleared...');
  }

  @Mutation
  replaceProtocol(protocol: Protocol): any {
    console.log('replaceProtocol mutation', protocol);
    const p = this.protocols.find(v => v.id === protocol.id);
    Object.assign(p, protocol);
  }

  @Mutation
  setProtocols(data: any) {
    const append = data.append;
    const protocols = data.protocols;

    if (!append) {
      this.protocols = [];
    }

    protocols.forEach(protocol => {
      this.protocols.push(Object.assign(new Protocol(), protocol));
    });
  }

  @Mutation
  appendProtocol(protocol: Protocol) {
    //console.log('Append protocol', protocol);
    this.protocols.push(protocol);
  }

  @Mutation
  addProtocolPIBByJSON(json: object) {
    const prot = Object.assign(new CollectAndExamProtocol(), json);
    this.protocols.push(prot);
    console.log('protocols::addProtocolPIBByJSON', prot.id);

    mixersModule.clearProtocolMixers(prot.id);
    json['mixers'].forEach(mixer_json => {
      mixersModule.addMixerByJSON(mixer_json);
    });
  }

  @Mutation
  addProtocolCTBByJSON(json: object) {
    console.log('protocols::addProtocolCTBByJSON');
    const prot = Object.assign(new CollectCTBProtocol(), json);
    this.protocols.push(prot);
  }

  @Mutation
  replaceProtocolPIBByJSON(json: any) {
    console.log('replaceProtocolPIBByJSON', json);
    const protIndex = this.protocols.findIndex(p => p.id === json['id']);
    const newProt = Object.assign(new CollectAndExamProtocol(), json);
    console.log('replaceProtocolPIBByJSON mutation', newProt.id, newProt);
    this.protocols[protIndex] = newProt;

    mixersModule.clearProtocolMixers(newProt.id);
    json['mixer_ids'] = [];
    if (json.mixers) {
      json['mixers'].forEach(mixer_json => {
        mixersModule.addMixerByJSON(mixer_json);
        json['mixer_ids'].push(mixer_json['id']);
      });
    }
    console.log('replaceProtocolPIBByJSON mutation END', newProt);
  }

  @Mutation
  replaceProtocolCTBByJSON(json: object) {
    const protIndex = this.protocols.findIndex(p => p.id === json['id']);
    const newProt = Object.assign(new CollectCTBProtocol(), json);
    this.protocols[protIndex] = newProt;
    console.log('replaceProtocolCTBByJSON mutation', newProt.id, newProt['description']);
  }

  @Action
  async removeMethodUsers(protocol: CollectAndExamProtocol) {
    const pibToSaveInDb = JSON.parse(JSON.stringify(protocol));
    pibToSaveInDb.mixers.forEach(m => m.collects.forEach(c => c.methods.forEach(m => delete m.method_users)));
    return pibToSaveInDb;
  }

  @Action
  async updatePIBProtocol(protocol: CollectAndExamProtocol): Promise<AxiosResponse> {
    const pibToSaveInDb = await this.removeMethodUsers(protocol);
    const response = await API.saveCollectAndExamProtocol(pibToSaveInDb);
    if (response.data.success) {
      protocol.id = response.data.protocolPIB.id;
      this.replaceProtocol(protocol);
      console.log('Replace PIB to VUEX protocols', protocol);
      return response;
    }
  }

  @Action
  async addPIBProtocol(protocol: CollectAndExamProtocol): Promise<AxiosResponse> {
    const pibToSaveInDb = await this.removeMethodUsers(protocol);
    const response = await API.saveCollectAndExamProtocol(pibToSaveInDb);
    if (response.data.success) {
      protocol.id = response.data.protocolPIB.id;
      this.appendProtocol(protocol);
      console.log('Added to VUEX protocols', protocol);
      return response;
    }
  }

  @Action
  @RelatedModel('CollectAndExamProtocol')
  async fetchPIBProtocols(append = false): Promise<AxiosResponse> {
    console.log('*** FetchPIBProtocols');
    const response = await API.fetchPIBProtocols();
    if (response.data.success) {
      this.setProtocols({ protocols: response.data.protocols, append });
      return response;
    }
  }

  @Action
  async updateCTBProtocol(protocol: CollectCTBProtocol): Promise<AxiosResponse> {
    const response = await API.saveCTBProtocol(protocol);
    if (response.data.success) {
      protocol.id = response.data.protocolCTB.id;
      this.replaceProtocol(protocol);
      console.log('Update CTB to VUEX protocols', protocol);
      return response;
    }
  }
  @Action
  async addCTBProtocol(protocol: CollectCTBProtocol): Promise<AxiosResponse> {
    const response = await API.saveCTBProtocol(protocol);
    if (response.data.success) {
      protocol.id = response.data.protocolCTB.id;
      this.appendProtocol(protocol);
      console.log('Added to VUEX protocols', protocol);
      return response;
    }
  }
  @Action
  @RelatedModel('CollectCTBProtocol')
  async fetchCTBProtocols(append = true): Promise<AxiosResponse> {
    console.log('*** FetchCTBProtocols');
    const response = await API.fetchCTBProtocols();
    if (response.data.success) {
      this.setProtocols({ protocols: response.data.protocols, append });
      return response;
    }
  }

  @Mutation
  loadProtocolsFromConfig(configDict: IProtocol[]): any {
    this.protocols = [];

    configDict.forEach(p => {
      switch (p.type_id) {
        case ProtocolType.PIB:
          this.protocols.push(Object.assign(new CollectAndExamProtocol(), p));
          break;
        case ProtocolType.COLLECT_CTB:
          this.protocols.push(Object.assign(new CollectCTBProtocol(), p));
          break;
        case ProtocolType.COLLECT:
          this.protocols.push(Object.assign(new CollectProtocol(), p));
          break;
        default:
          break;
      }
    });
    console.log('Store module Protocols loaded..', this.protocols.length);
  }

  @Action
  async signPIBProtocol(protocol: CollectAndExamProtocol): Promise<AxiosResponse> {
    const pibToSaveInDb = await this.removeMethodUsers(protocol);
    const response = await API.saveCollectAndExamProtocol(pibToSaveInDb);
    if (response.data.success) {
      this.replaceProtocol(protocol);
      console.log('Signed replaced to VUEX protocols', protocol);
      return response;
    }
  }
}

export default getModule(ProtocolsModule);
