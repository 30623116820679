















































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import ordersModule from '@/store/modules/orders';
import visitsModule from '@/store/modules/visits';
import dictModule from '@/store/modules/dict';
import userModule from '@/store/modules/user';
import { OrderStatus, SortDir, DATE_FORMAT, DB_DATE_TIME_FORMAT, DB_DATE_FORMAT, UserRightSymbols } from '@/consts';
import * as helpers from '@/helpers';
import { Order, Visit } from '@/types';
import ModalOrdersFilter from '@/components/spec/ModalOrdersFilter.vue';
import breadcrumbModule from '@/store/modules/breadcrumb';
import ModalQuestion from '@/components/dummy/ModalQuestion.vue';

type Filters = { field: string; value: string | DateObject }[];
interface DateObject {
  from: {
    value: string;
    datepicker: string;
  };
  to: {
    value: string;
    datepicker: string;
  };
}

@Component({
  components: {
    TopPanel,
    Header,
    Footer,
    ModalOrdersFilter,
    ModalQuestion,
  },
})
export default class Orders extends Vue {
  @Ref() readonly ordersFilter: ModalOrdersFilter;
  @Ref() readonly removeOrderQuestion: ModalQuestion;

  private pageSize = 20;
  private pageNo = 1;
  private allPagesCount = 0;
  private filters: Filters = [];
  private sortParams = { field: 'id', dir: SortDir.ASC };

  btnPrevVisible = false;
  spinner = false;
  btnNextVisible = false;

  // ------------- GETTERS --------------
  get getOrders(): Order[] {
    this.spinner = true;
    let orders = ordersModule.allOrders;
    orders = this.filterMethod(orders);

    orders = this.sortMethod(orders, this.sortParams.field, this.sortParams.dir);
    this.allPagesCount = Math.ceil(orders.length / this.pageSize);
    const objects = this.paginate(orders, this.pageSize, this.pageNo);
    this.update();
    this.spinner = false;
    return objects as Order[];
  }

  get visit() {
    return (order: Order): Visit => {
      return visitsModule.mainVisitByOrderId(order.id);
    };
  }

  getClientName(client_id: number) {
    if (client_id !== undefined) {
      const client = dictModule.clientById(client_id);
      return client !== undefined ? client.code + ' - ' + client.short_name : '';
    } else {
      return '';
    }
  }

  getCPlaceName(cplace_id: number) {
    if (cplace_id !== undefined) {
      const cplace = dictModule.cPlaceById(cplace_id);
      return cplace !== undefined ? cplace.name : '';
    } else {
      return '';
    }
  }

  getBatchColor(orderStatusId: number) {
    switch (orderStatusId) {
      case OrderStatus.PENDING.id:
        return 'info';
      case OrderStatus.ORDERED.id:
        return 'primary';
      case OrderStatus.CANCELED.id:
        return 'secondary';
      case OrderStatus.FINISHED.id:
        return 'danger';
      default:
        return 'light';
    }
  }
  get getStatusName() {
    return (orderStatusId: number): string => {
      return OrderStatus.byId(orderStatusId).name;
    };
  }

  getFormattedDate(date: string) {
    if (moment(date, DATE_FORMAT, true).isValid() === true) return date;
    return moment(date, DB_DATE_FORMAT).format(DATE_FORMAT);
  }

  // ---------------- ROUTES ------------------
  addOrder() {
    this.$router.push({ name: 'labordernew' });
  }

  editRow(order: Order) {
    if (order.status_id === OrderStatus.ORDERED.id || order.status_id === OrderStatus.PENDING.id) {
      this.$router.push({
        name: 'laborderedit',
        params: { order_id: order.id.toString() },
      });
    } else {
      this.$router.push({
        name: 'laborderpreview',
        params: { order_id: order.id.toString() },
      });
    }
  }

  // ----------------- INTERNAL ACTIONS ----------------
  prevPage() {
    this.pageNo--;
  }

  nextPage() {
    this.pageNo++;
  }

  changePageSize() {
    this.update();
  }

  filterPage() {
    this.ordersFilter.showModal();
  }
  ordersFilterOK(filters: Filters) {
    this.filters = filters;
  }

  sortPage() {
    this.spinner = true;
    const sortField = 'id';
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.spinner = false;
  }

  update() {
    console.log('update', this.pageNo, this.allPagesCount);
    this.btnNextVisible = this.pageNo !== this.allPagesCount;
    this.btnPrevVisible = this.pageNo !== 1;
  }

  sortMethod(array: Order[], field: string, dir: SortDir): Order[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      case 'id':
      case 'create_date_time':
      case 'changed_date_time':
      case 'date':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a[field] < b[field] ? 1 : -1;
          } else {
            return a[field] > b[field] ? 1 : -1;
          }
        });
      case 'number':
        return array.sort((a, b) => {
          const numa = a.number.split('/').reverse();
          const numb = b.number.split('/').reverse();
          for (let i = 0; i < numa.length; i++) {
            if (numa[i] !== numb[i]) {
              if (dir === SortDir.ASC) {
                return parseInt(numa[i]) >= parseInt(numb[i]) ? 1 : -1;
              } else {
                return parseInt(numa[i]) >= parseInt(numb[i]) ? -1 : 1;
              }
            }
          }
          return dir === SortDir.ASC ? 1 : -1;
        });
      case 'client.code':
        if (dir === SortDir.ASC) {
          return array.sort((a, b) => (parseInt(a.client.code) > parseInt(b.client.code) ? 1 : -1));
        } else {
          return array.sort((a, b) => (parseInt(a.client.code) < parseInt(b.client.code) ? 1 : -1));
        }
      case 'cplace.name':
        return array.sort((a, b) => {
          const na = Order.create(a);
          const nb = Order.create(b);
          if (dir === SortDir.ASC) {
            return na.cplace.name > nb.cplace.name ? 1 : -1;
          } else {
            return na.cplace.name < nb.cplace.name ? 1 : -1;
          }
        });
      case 'visit.date':
        if (dir === SortDir.ASC) {
          return array.sort((a, b) => (this.visit(a).date > this.visit(b).date ? 1 : -1));
        } else {
          return array.sort((a, b) => (this.visit(a).date < this.visit(b).date ? 1 : -1));
        }
      case 'status.name':
        if (dir === SortDir.ASC) {
          return array.sort((a, b) => (this.getStatusName(a.status_id) > this.getStatusName(b.status_id) ? 1 : -1));
        } else {
          return array.sort((a, b) => (this.getStatusName(a.status_id) < this.getStatusName(b.status_id) ? 1 : -1));
        }
      default:
        return array;
    }
  }
  sortOrders(field: string) {
    this.sortParams.field = field;
    const dir = this.sortParams.dir;
    this.sortParams.dir = dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
  }

  filterMethod(orders: Order[]) {
    for (const filter of this.filters) {
      let value = '';

      if (typeof filter.value === 'string') {
        value = filter.value.toLowerCase();
      }
      switch (filter.field) {
        case 'client.name':
          orders = orders.filter(o => o.client.name.toLowerCase().includes(value));
          break;
        case 'client.short_name':
          orders = orders.filter(o => o.client.short_name.toLowerCase().includes(value));
          break;
        case 'client.code':
          orders = orders.filter(o => o.client.code.toLowerCase().includes(value));
          break;
        case 'number':
          orders = orders.filter(o => o.number.toLowerCase().includes(value));
          break;
        case 'cplace':
          orders = orders.filter(o => this.getCPlaceName(o.cplace_id).toLowerCase().includes(value));
          break;
        case 'date':
        case 'visit.date':
          orders = orders.filter(o => {
            if (typeof filter.value === 'string') {
              throw new Error('filter.value has bad type');
            }
            const fromDate =
              filter.value.from.value === ''
                ? moment().subtract(100, 'years')
                : moment(filter.value.from.value, DATE_FORMAT);
            const toDate =
              filter.value.to.value === '' ? moment().add(100, 'years') : moment(filter.value.to.value, DATE_FORMAT);
            const date =
              filter.field === 'date' ? moment(o.date, DB_DATE_TIME_FORMAT) : moment(o.visit.date, DB_DATE_TIME_FORMAT);

            return date.isBetween(fromDate, toDate, 'days', '[]');
          });
      }
    }

    return orders;
  }

  paginate(array: Order[], pageSize: number, pageNumber: number): object[] {
    pageNumber--;
    const rowFrom: number = +(pageNumber * pageSize);
    const rowTo: number = +(pageNumber * pageSize) + +pageSize;
    console.log('Page [' + rowFrom, '-', rowTo + ']');
    let index = 1;
    const templateArray: object[] = [];
    array.forEach(o => {
      templateArray.push({
        index: index++,
        order: o,
      });
    });
    return templateArray.slice(rowFrom, rowTo);
  }

  mounted() {
    console.log('mounted');

    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Lista zamówień',
          link: '',
        },
      ],
    });
  }

  removeOrder(event: MouseEvent, order: Order) {
    if (userModule.userHasRightBySymbolInAllRights(userModule.user, UserRightSymbols.ORDER_DELETE) === true) {
      order.question = `Czy chcesz usunąć zamówienie numer ${order.number} z całą jego strukturą?`;
      this.removeOrderQuestion.showModal(order);
    } else {
      helpers.error('Użytkownik nie posiada uprawnień do wykonania tej operacji');
    }
  }

  removeOrderOK(order: Order) {
    ordersModule.deleteOrderAction(order.id);
  }
}
