



































































































import { Component, Prop, Vue, PropSync, Watch } from 'vue-property-decorator';
import Header from '../../components/dummy/Header.vue';
import Footer from '../../components/dummy/Footer.vue';
import userModule from '../../store/modules/user';
import usersModule from '../../store/modules/users';
import visitsModule from '../../store/modules/visits';
import { User, Visit, Day, Week } from '../../types';
import * as consts from '../../consts';
import moment from 'moment';
import visit from '../../store/modules/visit';
import breadcrumbModule from '../../store/modules/breadcrumb';
import samplesModule from '@/store/modules/samples';
import { onlineHelper } from '@/helpers/offline';
import user from '../../store/modules/user';

@Component({
  components: { Header, Footer },
})
export default class CalendarVisitsMonth extends Vue {
  currentMonth = 1;
  currentYear = 2021;
  currentWeek = 1;
  currentDay = 1;
  currentDate = null;
  scrolledToEnd = false;
  scrolledToBegin = false;
  elementMonthsData: Day[] = [];
  firstDate = moment('01.11.2020', consts.DATE_FORMAT).startOf('day');
  lastDate = moment('21.07.2021', consts.DATE_FORMAT).startOf('day');
  weeks = [];
  get now() {
    return moment();
  }

  get obtainVisits() {
    let visits = visitsModule.allVisits || [];
    visits = visits.sort((a, b) => {
      const aDate = moment(a.dateDb);
      const bDate = moment(b.dateDb);
      return aDate.diff(bDate);
    });

    if (this.isCoord === false || !onlineHelper.isOnline) {
      visits = visits.filter(el => el.laborant_id === userModule.user.id);
    }
    return visits;
  }

  fillWeeks() {
    let week: Week = null;
    const weeks: Week[] = [];
    this.elementMonthsData = [];

    const visits = this.obtainVisits;

    const currDate = this.firstDate.clone();
    const lastDate = this.lastDate.clone();

    let iter = 0;
    while (iter < visits.length && moment(visits[iter].dateDb, consts.DB_DATE_TIME_FORMAT).diff(currDate, 'days') < 0) {
      iter += 1;
    }

    while (currDate.diff(lastDate) < 0) {
      const weekStart = moment(currDate).startOf('week');
      const weekEnd = moment(currDate).endOf('week');

      week = {
        weekStart: weekStart,
        weekEnd: weekEnd,
        weekOfYear: currDate.week(),
        year: currDate.year(),
        month: currDate.month() + 1,
        days: [],
      };

      const day = currDate.clone().startOf('week');
      for (let dayInWeek = 1; dayInWeek < 8; dayInWeek++) {
        const visitsDay = [];
        const weekDay = new Day(day);
        weekDay.weekOfYear = week.weekOfYear;

        while (
          iter < visits.length &&
          moment(visits[iter].dateDb, consts.DB_DATE_TIME_FORMAT).diff(day, 'days') === 0
        ) {
          visitsDay.push(visits[iter]);
          iter += 1;
        }

        weekDay.visits = visitsDay;

        week.days.push(weekDay);

        if (weekDay.dayOfMonth === 1) {
          this.elementMonthsData.push(weekDay);
        }
        day.add(1, 'days');
      }
      weeks.push(week);
      currDate.add(7, 'days');
    }
    this.weeks = weeks;
  }

  getVisits(currDay: moment.Moment) {
    const isCoord = this.isCoord;
    const online = onlineHelper.isOnline;
    return visitsModule.allVisits.filter(v => {
      let res = false;
      const dt = moment(v.dateDb, consts.DB_DATE_TIME_FORMAT);

      if (isCoord === false || !online) {
        res = dt.diff(currDay) === 0 && v.laborant_id === userModule.user.id;
      } else {
        res = dt.diff(currDay) === 0;
      }
      return res;
    });
  }

  get isCoord() {
    return userModule.hasRightBySymbol(consts.UserRightSymbols.VISIT_COORD);
  }

  get user() {
    return userModule.user;
  }

  dayClick(week: Week, day: Day) {
    this.currentDay = day.dayOfMonth;
    this.currentMonth = day.month;
    this.currentYear = day.year;
    if (this.isCoord && onlineHelper.isOnline) {
      this.$router.push({
        name: 'calendarvisitsdaypeople',
        params: { date: this.currentDay + '_' + this.currentMonth + '_' + this.currentYear },
      });
    } else {
      this.$router.push({
        name: 'calendarvisitsdayperson',
        params: {
          date: this.currentDay + '_' + this.currentMonth + '_' + this.currentYear,
          person_id: userModule.user.id.toString(),
        },
      });
    }
  }

  weekClick(week: Week) {
    alert(week);
  }

  scrollTo() {
    const elementToScrollTo = document.getElementById(
      'year_' + this.currentYear.toString() + '_day_' + this.currentDate.dayOfYear().toString()
    );
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  setCurrentDate(dateString: string) {
    this.currentDate = moment(dateString, 'DD.MM.YYYY');
    this.currentYear = this.currentDate.year();
    this.currentWeek = this.currentDate.week();
    this.currentDay = this.currentDate.date();
    this.currentMonth = this.currentDate.month();

    console.log(
      'data:',
      this.currentDay,
      '.',
      this.currentMonth + 1,
      '.',
      this.currentYear,
      ' week:',
      this.currentWeek
    );
  }

  goToday() {
    this.setCurrentDate(moment().format(consts.DATE_FORMAT));
    this.scrollTo();
  }

  getCurrentDate() {
    return moment({ year: this.currentYear, month: this.currentMonth, day: this.currentDay });
  }

  monthName(month: number) {
    return consts.Months[month];
  }

  get currentDayTitle() {
    return moment(this.currentDay + '.' + this.currentMonth + '.' + this.currentYear, consts.DATE_FORMAT).format(
      consts.DATE_FORMAT
    );
  }

  get getMainVisitsText() {
    return (day: Day): string => {
      const visitsMain = day.visits.filter(
        v => v.collect_and_exam_protocol_id !== null && v.collect_and_exam_protocol_id !== undefined
      );
      // console.log('DAY:', day.dateString, 'MAIN', visitsMain);
      return visitsMain.length > 0 ? 'Wizyty PiB: ' + visitsMain.length : '';
    };
  }
  get getCTBVisitsText() {
    return (day: Day): string => {
      const visitsCTB = day.visits.filter(
        v => v.collect_ctb_protocol_id !== null && v.collect_ctb_protocol_id !== undefined
      );
      //console.log('DAY:', day.dateString, 'CTB', visitsCTB);
      return visitsCTB.length > 0 ? 'Odebrania: ' + visitsCTB.length : '';
    };
  }

  get isEmptyDay() {
    return (day: Day): boolean => {
      return day.visits.length === 0;
    };
  }

  distanceFromTopBorder(element: Element) {
    const rect = element.getBoundingClientRect();
    const scrollBox = document.getElementById('scroll-box').getBoundingClientRect();
    return Math.abs(rect.bottom - scrollBox.height / 2);
  }

  displayScrollButtons() {
    const scrollbox = document.getElementById('scroll-box');
    const pixelsScrolled = scrollbox.scrollTop;
    this.scrolledToBegin = pixelsScrolled == 0;
    if (this.scrolledToBegin) return;
    this.scrolledToEnd = pixelsScrolled + scrollbox.clientHeight === scrollbox.scrollHeight;
  }

  funcLimiter = 0;
  scrollContent(e) {
    if (this.funcLimiter !== 15) {
      this.funcLimiter++;
      // this.displayScrollButtons();
      return;
    }
    this.funcLimiter = 0;
    let minDistance = 1e20;
    let closestIndex = 0;
    for (let i = 0; i < this.borderWeeks.length; i++) {
      const elementDistance = this.distanceFromTopBorder(this.borderWeeks[i]);
      if (elementDistance < minDistance) {
        closestIndex = i;
        minDistance = elementDistance;
      }
    }
    this.currentMonth = this.elementMonthsData[closestIndex].month;
    this.currentYear = this.elementMonthsData[closestIndex].year;
  }
  borderWeeks: any = [];

  async prevMonth() {
    this.firstDate.subtract(7 * 8, 'd');
    await this.fillWeeks();
    const weeks = document.getElementsByClassName('week');
    const week = weeks[0];
    await this.sleep(250);
    week.scrollIntoView({ block: 'start', behavior: 'smooth' });
    this.getAllBorderWeeks();
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async nextMonth() {
    this.lastDate.add(7 * 8, 'd');
    await this.fillWeeks();
    const weeks = document.getElementsByClassName('week');
    const week = weeks[weeks.length - 3];
    await this.sleep(250);
    week.scrollIntoView({ block: 'start', behavior: 'smooth' });
    this.getAllBorderWeeks();
  }

  getAllBorderWeeks() {
    this.borderWeeks = document.getElementsByClassName('border-week');
    console.log('border weeks', this.borderWeeks);
  }

  mounted() {
    moment.updateLocale('pl', { week: { dow: 1 } });
    if (this.$attrs['day'] !== undefined) {
      this.setCurrentDate(this.$attrs['day']);
    } else {
      this.setCurrentDate(moment().format(consts.DATE_FORMAT));
      //this.setCurrentDate('01.01.2020');
    }
    this.firstDate = moment().subtract(5, 'month').set('date', 1);
    this.lastDate = moment().add(5, 'month');
    this.lastDate.set('date', this.lastDate.daysInMonth());
    //this.scrollTo();
    this.getAllBorderWeeks();
    this.fillWeeks();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Kalendarz wizyt',
          link: '',
        },
      ],
    });
  }
}
