import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IExam } from '@/store/models';
import { Exam, ExamStage, ExamStageParam, ExamSampleStageParam, Sample } from '@/types';
import usersModule from '@/store/modules/users';
import * as helpers from '@/helpers';
import store from '@/store';
import { relativeTimeThreshold } from 'moment';
import { RelatedModel } from './decorators';

type ExamsFilterOptions = {
  statusSelected?: number;
  labCoordOptionSel?: number;
  asignedOrNotSel?: number;
};
@Module({
  namespaced: true,
  name: 'exams',
  store,
  dynamic: true,
})
class ExamsModule extends VuexModule {
  public exams: Exam[] = [];
  examStages: ExamStage[] = [];
  examStageParams: ExamStageParam[] = [];
  examSampleStageParams: ExamSampleStageParam[] = [];

  public examsFilterOptions: ExamsFilterOptions = {};

  public get getExamsFilterOptions() {
    return this.examsFilterOptions;
  }

  public get getexamSampleStageParams() {
    return this.examSampleStageParams;
  }

  public get allExams() {
    return this.exams;
  }
  public get examById() {
    return (id: number): Exam => {
      return this.exams.find(m => m.id === id);
    };
  }
  public get examCopyById() {
    return (id: number): Exam => {
      return Object.assign(new Exam(), JSON.parse(JSON.stringify(this.examById(id))));
    };
  }

  public get examStageById() {
    return (id: number): ExamStage => {
      return this.examStages.find(m => m.id === id);
    };
  }

  public get examStagesByExamId() {
    return (exam_id: number): ExamStage[] => {
      return this.examStages.filter(m => m.exam_id === exam_id);
    };
  }

  public get examStageParamById() {
    return (id: number): ExamStageParam => {
      return this.examStageParams.find(p => p.id === id);
    };
  }

  public get examStageCopyById() {
    return (id: number): ExamStage => {
      return Object.assign(new ExamStage(), JSON.parse(JSON.stringify(this.examStageById(id))));
    };
  }
  public get examStageParamCopyById() {
    return (id: number): ExamStageParam => {
      return Object.assign(new ExamStageParam(), JSON.parse(JSON.stringify(this.examStageParamById(id))));
    };
  }
  public get examSampleStageParamById() {
    return (id: number): ExamSampleStageParam => {
      return this.examSampleStageParams.find(essp => essp.id === id);
    };
  }
  public get examSampleStageParamCopyById() {
    return (id: number): ExamSampleStageParam => {
      return Object.assign(new ExamSampleStageParam(), JSON.parse(JSON.stringify(this.examSampleStageParamById(id))));
    };
  }

  @Mutation
  clear() {
    this.exams = [];
    this.examStages = [];
    this.examStageParams = [];
    this.examSampleStageParams = [];
    console.log('exams module cleared...');
  }

  @Mutation
  setExamsFilterOptions(opt: ExamsFilterOptions) {
    this.examsFilterOptions = opt;
  }

  @Mutation
  replaceExam(exam: Exam): any {
    console.log('replaceExam mutation', exam);
    let m = this.exams.find(m => m.id === exam.id);
    m = Object.assign(m, exam);
  }

  @Mutation
  setExams(exams: Exam[]) {
    this.exams = [];
    exams.forEach(exam => {
      // exam.person_authorised = usersModule.userById(exam.person_authorised_id);
      this.exams.push(Object.assign(new Exam(), exam));
    });
  }

  @Mutation
  appendExam(exam: Exam) {
    this.exams.push(exam);
  }

  @Action
  async updateExam(exam: Exam): Promise<AxiosResponse> {
    const response = await API.saveExam(exam);
    if (response.data.success) {
      exam.id = response.data.exam.id;
      exam.end_date_time = response.data.exam.end_date_time;
      if ('results_doc' in exam) {
        exam.results_doc.id = response.data.results_doc.rd_id;
        exam.results_doc.filename = response.data.results_doc.filename;
      }
      this.replaceExam(exam);
      console.log('Replace to VUEX exams', exam);
      return response;
    }
  }
  @Action
  async updateExamIsRdDownloaded(exam: Exam): Promise<AxiosResponse> {
    const response = await API.saveExamIsRdDownloaded(exam);
    if (response.data.success) {
      exam.id = response.data.exam.id;
      exam.is_rd_downloaded = true;
      this.replaceExam(exam);
      console.log('Replace to VUEX exams', exam);
      return response;
    }
    return response;
  }
  @Action
  async addExam(exam: Exam): Promise<AxiosResponse> {
    const response = await API.saveExam(exam);
    if (response.data.success) {
      exam.id = response.data.exam.id;
      this.appendExam(exam);
      console.log('Added to VUEX exams', exam);
      return response;
    }
  }
  @Action
  @RelatedModel('Exam')
  async fetchExams(): Promise<AxiosResponse> {
    console.log('*** FetchExams');
    const response = await API.fetchExams();
    if (response.data.success) {
      this.setExams(response.data.exams);
      return response;
    }
  }

  @Mutation
  setExamStages(examStages: ExamStage[]) {
    examStages.forEach(examStage => {
      let estage = this.examStages.find(es => es.id === examStage.id);
      if (estage !== undefined) {
        estage = Object.assign(estage, examStage);
        //console.log('Updating stage', estage);
      } else {
        this.examStages.push(Object.assign(new ExamStage(), examStage));
        //console.log('Pushing new stage', examStage);
      }
    });
  }

  @Action
  @RelatedModel('ExamStage')
  async fetchExamsStages(): Promise<AxiosResponse> {
    console.log('*** FetchExamsStages');
    const response = await API.fetchExamsStages();
    if (response.data.success) {
      this.setExamStages(response.data.exam_stages);
      return response;
    }
  }

  @Action
  async fetchExamStages(exam: Exam): Promise<AxiosResponse> {
    console.log('*** FetchExamStages');
    const response = await API.fetchExamStages(exam);
    if (response.data.success) {
      this.setExamStages(response.data.exam_stages);
      return response;
    }
  }

  @Mutation
  removeExamSampleStageParamById(id: number) {
    const index = this.examSampleStageParams.findIndex(el => el.id === id);
    this.examSampleStageParams.splice(index, 1);
  }

  @Mutation
  appendExamSampleStageParam(elem: ExamSampleStageParam) {
    console.log('new param', elem);
    this.examSampleStageParams.push(elem);
  }

  @Mutation
  setExamStageParams(examStageParams: ExamStageParam[]) {
    this.examStageParams = [];
    //console.log('setExamStageParams', examStageParams);
    examStageParams.forEach(examStageParam => {
      this.examStageParams.push(Object.assign(new ExamStageParam(), examStageParam));
    });
  }
  @Action
  @RelatedModel('ExamStageParam')
  async fetchExamStageParams(): Promise<AxiosResponse> {
    console.log('*** FetchExamStageParams');
    const response = await API.fetchExamStageParams();
    if (response.data.success) {
      console.log('fetchExamStageParams', response.data);
      this.setExamStageParams(response.data.exam_stage_params);
      return response;
    }
  }

  @Mutation
  setExamSampleStageParams(examSampleStageParams: ExamSampleStageParam[]) {
    this.examSampleStageParams = [];
    console.log('setExamSampleStageParams', examSampleStageParams);
    examSampleStageParams.forEach(examSampleStageParam => {
      this.examSampleStageParams.push(Object.assign(new ExamSampleStageParam(), examSampleStageParam));
    });
  }
  @Action
  @RelatedModel('ExamSampleStageParam')
  async fetchExamSampleStageParams(): Promise<AxiosResponse> {
    console.log('*** FetchExamSampleStageParams');
    const response = await API.fetchExamSampleStageParams();
    if (response.data.success) {
      console.log('fetchExamSampleStageParams', response.data);
      this.setExamSampleStageParams(response.data.exam_sample_stage_params);
      return response;
    }
  }

  @Mutation
  loadExamsFromConfig(exams: IExam[]): any {
    this.exams = exams.map(c => Object.assign(new Exam(), c));
    console.log('Store module Exams loaded..', this.exams.length);
  }
}

export default getModule(ExamsModule);

/*

INSERT INTO api_exam(`number`,`n_date`,`changed_date_time`,`create_date_time`,`client_id`,`current_laborant_id`,`destiny_id`,`stage_id`) 
VALUES('1/2010','0','2019-01-01 11:12','2019-01-01 11:12',67,5,5,3)

*/
