import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ISample } from '@/store/models';
import { Sample, Collect } from '@/types';
import * as helpers from '@/helpers';
import store from '@/store';
import MixersModule from '@/store/modules/mixers';
import CollectsModule from '@/store/modules/collects';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'samples',
  store,
  dynamic: true,
})
class SamplesModule extends VuexModule {
  public samples: Sample[] = [];

  public get allSamples() {
    return this.samples;
  }
  public get sampleById() {
    return (id: number): Sample => {
      return this.samples.find(s => s.id === id);
    };
  }
  public get sampleCopyById() {
    return (id: number): Sample => {
      console.log('sample load ', id);
      return Object.assign(new Sample(), JSON.parse(JSON.stringify(this.sampleById(id))));
    };
  }
  get printCollectSamples() {
    return (collectId: number, nested = false) => {
      this.samples
        .filter(s => s.collect_id === collectId)
        .forEach(sample => {
          console.log(
            '          SAMPLE: id:',
            sample.id,
            'number',
            sample.number,
            'destiny id',
            sample.destiny_id,
            'age',
            sample.age,
            'ctb id',
            sample.protocol_ctb_id
          );
        });
    };
  }
  get printProtCTBSamples() {
    return (protId: number, nested = false) => {
      this.samples
        .filter(s => s.protocol_ctb_id === protId)
        .forEach(sample => {
          console.log(
            '          SAMPLE: id:',
            sample.id,
            'number',
            sample.number,
            'destiny id',
            sample.destiny_id,
            'age',
            sample.age,
            'ctb id',
            sample.protocol_ctb_id
          );
        });
    };
  }
  get samplesByPibId() {
    return (id: number): Sample[] => {
      const mixers = MixersModule.allMixers.filter(mixer => mixer.protocol_id === id);
      const collects: Collect[] = [];
      const samples: Sample[] = [];
      for (const mixer of mixers) {
        const mixerCollects = CollectsModule.collects.filter(collect => collect.mixer_id === mixer.id);
        collects.push(...mixerCollects);
      }
      for (const collect of collects) {
        const collectSamples = this.allSamples.filter(sample => sample.collect_id === collect.id);
        samples.push(...collectSamples);
      }
      return samples;
    };
  }
  /* MUTATIONS */

  @Mutation
  clear() {
    this.samples = [];
    console.log('Samples module cleared...');
  }

  @Mutation
  replaceSample(sample: Sample): any {
    const s = this.samples.find(s => s.id === sample.id);
    Object.assign(s, sample);
  }
  @Mutation
  setSamples(samples: Sample[]) {
    this.samples = [];
    samples.forEach(sample => {
      this.samples.push(Object.assign(new Sample(), sample));
    });
  }
  @Mutation
  appendSample(sample: Sample) {
    this.samples.push(sample);
  }
  @Mutation
  removeSample(sample: Sample) {
    this.samples = this.samples.filter(s => s.id !== sample.id);
  }

  @Mutation
  addSampleByJSON(json: object) {
    const sample = Object.assign(new Sample(), json);
    this.samples.push(sample);
  }
  @Mutation
  clearCollectSamples(collectId: number) {
    this.samples = this.samples.filter(s => s.collect_id !== collectId);
  }

  /* ACTIONS */
  @Action
  updateSampleByJSON(json: object) {
    console.log('updateCollectByJSON', json['id']);
    let sample = this.samples.find(c => c.id === json['id']);
    if (sample) {
      this.replaceSample(json as Sample);
    } else {
      sample = Object.assign(new Sample(), json);
      this.appendSample(sample);
    }
  }

  @Action
  async updateSample(sample: Sample): Promise<AxiosResponse> {
    const response = await API.saveSample(sample);
    if (response.data.success) {
      sample.id = response.data.sample.id;
      this.replaceSample(sample);
      console.log('Replace to VUEX samples', sample);
      return response;
    }
  }
  @Action
  async addSample(sample: Sample): Promise<AxiosResponse> {
    const response = await API.saveSample(sample);
    if (response.data.success) {
      sample.id = response.data.sample.id;
      this.appendSample(sample);
      console.log('Added to VUEX samples', sample);
      return response;
    }
  }
  @Action
  @RelatedModel('Sample')
  async fetchSamples(): Promise<AxiosResponse> {
    console.log('*** FetchSamples');
    const response = await API.fetchSamples();
    if (response.data.success) {
      this.setSamples(response.data.samples);
      return response;
    }
  }

  @Mutation
  loadSamplesFromConfig(samples: ISample[]): any {
    this.samples = samples.map(s => Object.assign(new Sample(), s));
    console.log('Store module Samples loaded..', this.samples.length);
  }

  @Action
  async updateSampleUW(sample_data): Promise<AxiosResponse> {
    const response = await API.updateSampleUW(sample_data);
    if (response.data.success) {
      const sample = this.sampleById(sample_data.sample_id);
      sample.uw = sample_data.sample_uw;
      console.log('Replace to VUEX sample', sample);
      this.replaceSample(sample);
      return response;
    }
  }
}

export default getModule(SamplesModule);
