










































































































































































































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import VueHtml2pdf from 'vue-html2pdf';
import ExamCardHeader from '../../../components/spec/ExamCardHeader.vue';
import Footer from '../../../components/dummy/Footer.vue';
import userModule from '../../../store/modules/user';
import usersModule from '../../../store/modules/users';
import examsModule from '../../../store/modules/exams';
import examModule from '../../../store/modules/exam';
import {
  User,
  Exam,
  ExamStage,
  DestinyGroup,
  ExamStageParam,
  Sample,
  ResultsDoc,
  Collect,
  Mixer,
  ExamSampleStageParam,
  Tool,
  Message,
} from '../../../types';
import * as consts from '../../../consts';
import moment from 'moment';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import dictModule from '../../../store/modules/dict';
import collectsModule from '../../../store/modules/collects';
import mixersModule from '../../../store/modules/mixers';
import protocolsModule from '../../../store/modules/protocols';
import visitsModule from '../../../store/modules/visits';
import ordersModule from '../../../store/modules/orders';
import offersModule from '../../../store/modules/offer';
import toolsModule from '../../../store/modules/tools';
import dataModule from '../../../store/modules/data';
import * as helpers from '../../../helpers';
import methodsModule from '../../../store/modules/methods';
import { create, all } from 'mathjs';
import ModalConfig from '../../../components/spec/ModalResultsDocConfig.vue';
import ModalExamStageTools from '../../../components/spec/ModalExamStageTools.vue';
import ModalSetExamDestiny from '../../../components/spec/ModalSetExamDestiny.vue';
import ModalChoosePerson from '../../../components/dummy/ModalChoosePerson.vue';
import ResultsDocCPrint from '../../../views/laborant/exams/ResultsDocCPrint.vue';
const math = create(all);

const enum viewMode {
  FORM,
  PRINT,
}

const enum ExamSave {
  SUCCESS,
  FAILURE,
}

@Component({
  components: {
    ExamCardHeader,
    Footer,
    VueHtml2pdf,
    ModalConfig,
    ModalExamStageTools,
    ResultsDocCPrint,
    ModalSetExamDestiny,
    ModalChoosePerson,
  },
})
export default class ExamCard extends Vue {
  @Ref() readonly config: ModalConfig;
  @Ref() readonly tools: ModalExamStageTools;
  @Ref() readonly resultsDocPrint: ResultsDocCPrint;
  @Ref() readonly setExamDestiny: ModalSetExamDestiny;
  @Ref() readonly choosePerson: ModalChoosePerson;

  private currentMonth = 1;
  private currentYear = 2020;
  private currentWeek = 1;
  private currentDay = 1;
  currDate: moment.Moment = moment();
  currStageIndex = 1;
  isAuthorised = false;
  userAuthorised = '';
  exam_id = -1;
  exam: Exam = null;
  examStages: ExamStage[] = [];
  examStageParams: ExamStageParam[] = [];
  examSampleStageParams: ExamSampleStageParam[] = [];
  viewModePrint = viewMode.PRINT;
  viewModeForm = viewMode.FORM;
  mode: viewMode = viewMode.FORM;

  authModalSpin = false;
  loading = false;

  get getStatusColor() {
    return consts.ExamStatus.byId(this.exam.status - 1).badge;
  }

  get getStatusName() {
    return consts.ExamStatus.byId(this.exam.status - 1).name;
  }

  get currentMonthTitle() {
    return consts.Months[this.currentMonth];
  }

  get getResult() {
    return (symbol: string, sample: Sample): string => {
      const param = this.examStageParams.find(p => p.symbol === symbol);
      console.log('getResults', symbol, param, sample);
      return this.examSampleStageParams.find(sp => sp.param_id === param.id && sp.sample_id === sample.id).value;
    };
  }

  get round10() {
    return (x: string) => {
      const N = parseFloat(x);
      console.log('ROUND10 N=', N, math.round(N / 10), math.round(N / 10) * 10);
      return math.round(N / 10) * 10;
    };
  }

  get getParamValue() {
    return (param: ExamStageParam, sample: Sample): string => {
      let value = this.examSampleStageParams.find(sp => sp.param_id === param.id && sp.sample_id === sample.id).value;
      const precParam = consts.PRECISIONS.find(p => p.symbol === param.symbol);
      const defaults = [
        { symbols: ['DLUGOSC', 'SZEROKOSC'], value: '150' },
        { symbols: ['ODCHYLKA_PLASK'], value: '0,00' },
        { symbols: ['ODCHYLKA_PROSTOP'], value: '0,0' },
      ];

      let precision = 1;
      if (precParam !== undefined) {
        precision = precParam.prec;
      }

      if (value === '' && this.disableInputs(param) === false) {
        for (const d of defaults) {
          if (d.symbols.includes(param.symbol) === true) {
            value = d.value;
            this.examSampleStageParams.find(p => p.param_id === param.id && p.sample_id === sample.id).value = d.value;
          }
        }
      }
      if (value !== '') {
        return parseFloat(value.replace(',', '.')).toFixed(precision).replace('.', ',');
      } else {
        return '';
      }
    };
  }
  get paramStage() {
    return (param: ExamStageParam) => {
      return this.examStages.find(es => es.id === param.exam_stage_id);
    };
  }
  get params() {
    return (stage: ExamStage) => {
      return examModule.examStageParams.filter(p => p.exam_stage_id === stage.id);
    };
  }
  get samples() {
    return this.exam.samples; //samplesModule.samples.filter(s => s.exam_id === this.exam.id);
  }

  get concreteClass() {
    return (groupSymbol: string) => {
      const group = dictModule.groupClassBySymbol(groupSymbol);
      console.log('concreteClass ', groupSymbol, ' group=', group);
      const classCId = this.exam.protocol.class_ids.find(id => dictModule.concreteClassById(id).group_id === group.id);
      if (classCId) {
        return dictModule.concreteClassById(classCId).name;
      } else {
        return '';
      }
    };
  }
  get methods() {
    return methodsModule.allMethods.filter(m => m.default_destiny_group_id === this.destinyGroup.id);
  }
  get destinyGroup() {
    console.log('dest', this.exam.destiny_id, dictModule.destinies);
    const destiny = dictModule.destinyById(this.exam.destiny_id);
    const group: DestinyGroup = dictModule.destinyGroups.find(d => d.id === destiny.group_id);
    return group;
  }
  get getRecipie() {
    return this.exam.protocol.recipie_no;
  }
  get getMainClass() {
    const class_ids = this.exam.protocol.class_ids;
    if (class_ids.length > 0) {
      return dictModule.concreteClassById(class_ids[0]).name;
    } else {
      return '';
    }
  }

  get collect() {
    return (sample: Sample): Collect => {
      return collectsModule.collectById(sample.collect_id);
    };
  }

  get mixer() {
    return (sample: Sample): Mixer => {
      return mixersModule.mixerById(this.collect(sample).mixer_id);
    };
  }

  get getCPlaceName() {
    return dictModule.cPlaceById(this.visit.cplace_id).name;
  }
  get stage() {
    return this.examStages.find(s => s.id === this.exam.stage_id);
  }
  get getCurrentStage() {
    console.log('this.currStageIndex', this.currStageIndex, this.examStages.length);
    if (this.currStageIndex <= this.examStages.length) {
      return this.examStages.find(s => s.index === this.currStageIndex);
    } else {
      return null;
    }
  }

  get isLastStage() {
    return this.stage.index === this.examStages.length;
  }

  get isLastCurrStage() {
    return this.getCurrentStage.index === this.examStages.length;
  }

  get user() {
    return (id: number): User => {
      return usersModule.userById(id);
    };
  }
  get visit() {
    return visitsModule.visitById(this.exam.protocol.visit_id);
  }
  get getSampleTakeDate() {
    return this.visit.date.slice(0, 10);
  }
  get getTakeDate() {
    if (/\d\d\.\d\d\.\d\d\d\d/.test(this.visit.date) === true) {
      return this.visit.date;
    }
    return moment(this.visit.date, consts.DB_DATE_TIME_FORMAT).format(consts.DB_DATE_FORMAT);
  }
  get client() {
    return dictModule.clientById(this.exam.client_id);
  }
  get protocols() {
    const protocols = [];
    this.exam.samples.forEach(s => {
      const protocol = protocolsModule.protocolBySample(s);
      if (!protocols.includes(protocol)) {
        protocols.push(protocol);
      }
    });
    return protocols;
  }
  get protocolNumberList() {
    return this.protocols.map(p => (p = p.number)).join(', ');
  }
  get getTakeDateList() {
    const takeDates = [];
    this.protocols.forEach(p => {
      const visit = visitsModule.visitById(p.visit_id);
      let date = visit.date;
      if (moment(date, consts.DB_T_S_DATE_TIME_FORMAT, true).isValid()) {
        date = moment(date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
      }
      if (!takeDates.includes(date)) {
        takeDates.push(date);
      }
    });
    return takeDates.join(', ');
  }
  get contract() {
    return offersModule.contractById(this.order.contract_id);
  }
  get order() {
    return ordersModule.orderById(this.visit.order_id);
  }
  get currentExamStageTools(): Tool[] {
    return toolsModule.toolsByExamStage(this.getCurrentStage);
  }
  get toolsCanBeChanged(): boolean {
    return this.currentExamStageTools.length === 0 || this.currStageIndex !== this.stage.index;
  }
  get isProbe(): boolean {
    return dictModule.destinyGroupById(this.exam.destiny.group_id).symbol === consts.DestinyGroupCodes.PROBE;
  }

  getSampleAge(sample: Sample) {
    const collect = collectsModule.collectById(sample.collect_id);
    const mixer = mixersModule.mixerById(collect.mixer_id);
    const protocol = protocolsModule.protocolById(mixer.protocol_id);
    const visit = visitsModule.visitById(protocol.visit_id);

    let dateFormat = '';
    if (/\d\d\.\d\d\.\d\d\d\d/.test(visit.date) === true) {
      dateFormat = consts.DATE_FORMAT;
    } else {
      dateFormat = consts.DB_DATE_TIME_FORMAT;
    }

    const visitDate = moment(visit.date, dateFormat);
    let end_dt = moment(new Date());
    if (this.exam.end_date_time) {
      end_dt = moment(this.exam.end_date_time, consts.DB_DATE_TIME_FORMAT);
      console.log('Exam AGE end_date_time=', end_dt, this.exam.end_date_time, visit.date);
    } else {
      console.log('Exam AGE curr end_date_time=', end_dt, visit.date);
    }
    const sampleAge = moment.duration(end_dt.diff(visitDate)).asDays();

    return Math.floor(sampleAge);
  }
  disableInputs(param: ExamStageParam): boolean {
    return (
      (this.getCurrentStage !== null && this.getCurrentStage.id !== this.exam.stage_id) ||
      this.exam.status === 4 ||
      param.exam_stage_id !== this.getCurrentStage.id
    );
  }

  get isUWFilled() {
    console.log(
      'usUWFilled',
      this.exam.samples.find(s => s.uw === ''),
      this.exam.samples
    );
    this.exam.samples.forEach(s => {
      console.log('Sample uw', s.uw);
    });
    return this.exam.samples.find(s => s.uw === '') === undefined;
  }

  /*************************************************************************************************/

  async saveExam() {
    this.exam['stages'] = [];
    for (let s = 0; s < this.examStages.length; s++) {
      const stage = this.examStages[s];
      stage['exam_stage_params'] = [];
      for (let sp = 0; sp < this.examStageParams.length; sp++) {
        const stageParam: ExamStageParam = this.examStageParams[sp];
        if (stageParam.exam_stage_id === stage.id) {
          stageParam['sample_params'] = [];
          for (let essp = 0; essp < this.examSampleStageParams.length; essp++) {
            const essparam: ExamSampleStageParam = this.examSampleStageParams[essp];
            if (essparam.param_id === stageParam.id) {
              stageParam['sample_params'].push(essparam);
            }
          }
          stage['exam_stage_params'].push(stageParam);
        }
      }
      this.exam['stages'].push(stage);
    }
    return await examsModule.updateExam(this.exam);
  }

  async closeStageClick(askForPerson = false) {
    console.log('closing Stage', this.stage.name, this.currStageIndex);

    if (this.currStageIndex === this.examStages.length) {
      if (
        this.exam.results_doc.uncert_tool_id === null
        // &&
        // this.exam.destiny.symbol === consts.DestinyGroupCodes.COMPRESSIVE_STREGTH
      ) {
        helpers.error('Nie wybrano urządzenia do wyliczania niepewności');
        return;
      }
      for (const stage of this.examStages) {
        for (const param of this.params(stage)) {
          for (const sample of this.samples) {
            if (this.getParamValue(param, sample) === '') {
              helpers.error('Nie podano wszystkich parametrów dla wszystkich próbek');
              return;
            }
          }
        }
      }
      if (askForPerson === true) {
        this.choosePerson.showModal({
          question: 'Wybierz osobę do autoryzacji sprawozdania',
          rightToFilter: consts.UserRightSymbols.EXAM_AUTHORISE,
        });
        return;
      }
    } else {
      if (this.currStageIndex === 1 && this.exam.current_laborant_id === null) {
        helpers.error('Nie wybrano laboranta');
        return;
      }
      this.exam.stage_id = this.examStages.find(es => es.index === this.currStageIndex + 1).id;
      console.log('Next stage:', this.exam.stage_id);
      helpers.info('Etap zamknięty');
      this.currStageIndex++;
    }
  }

  async saveExamClick(): Promise<ExamSave> {
    console.log('saveExam');

    const response = await this.saveExam();
    let examRef = examsModule.examById(this.exam.id);
    examRef = Object.assign({}, this.exam);

    let examsParams = examsModule.examSampleStageParams.filter(param =>
      examRef.samples.find(el => el.id === param.sample_id)
    );

    examsParams = examsParams.sort((a, b) => a.id - b.id);
    const sortedParams = this.examSampleStageParams.sort((a, b) => a.id - b.id);

    examsParams.forEach((el, index) => {
      el.value = sortedParams[index].value;
    });

    if (response.data.success) {
      helpers.info('Karta zapisana');
      return ExamSave.SUCCESS;
    } else {
      helpers.error('Błąd przy zapisie karty! Spróbuj ponownie');
      return ExamSave.FAILURE;
    }
  }

  pdf() {
    console.log('RD', this.resultsDocPrint);
    this.resultsDocPrint.makePdf();
  }

  showModalConfig() {
    if (this.stage.tools.length > 0 || !this.isNotFinished(this.exam.status)) {
      console.log('showModalConfig');
      this.config.showModal(this.exam);
    } else {
      helpers.error('Przed konfiguracją wybierz urządzenie');
    }
  }
  modalConfigOK(params: { samples: Sample[]; results_doc: ResultsDoc }) {
    console.log('Config OK', params);
    this.exam.results_doc = params.results_doc;
    params.samples.forEach(s => {
      this.exam.samples.find(sam => sam.id === s.id)['uw'] = s['uw'];
    });
    this.formView();
    this.$forceUpdate();
  }

  showModalTools() {
    for (const stage of this.examStages) {
      for (const param of this.params(stage)) {
        for (const sample of this.samples) {
          if (this.getParamValue(param, sample) === '') {
            helpers.error('Przed wybraniem urządzenia uzupełnij wszystkie parametry dla wszystkich próbek');
            return;
          }
        }
      }
    }
    this.tools.showModal(this.getCurrentStage);
  }
  modalToolsOK(tools: Tool[]) {
    console.log('Tools OK', tools);
    this.stage.tools = tools;
  }

  showSetExamModal() {
    this.setExamDestiny.showModal();
  }
  modalSetExamDestinyOK() {
    console.log('modalSetExamDestinyOK');
  }

  async choosePersonOK(params: { name: string; id: number }) {
    console.log('params', params, params.id, params.name);

    this.exam.status = 4;
    this.exam['person_authorised'] = usersModule.userById(params.id);
    let response = await this.saveExam();
    if (response.data.success) {
      helpers.info('Karta zamknięta');
    } else {
      helpers.error('Błąd przy zamykaniu karty !');
    }

    const message = new Message();
    message.receiver_id = params.id;
    message.title = 'Sprawozdanie do autoryzacji';
    message.content_before_link = 'Sprawozdanie ';
    message.link = this.$router.currentRoute.fullPath;
    message.link_text = this.exam.number;
    message.content_after_link = ' gotowe do autoryzacji';
    message.type = consts.MessageSenderType.SYSTEM;
    message.group_id = consts.MessageGroup.RD_AUTHORISE.id;
    message.message_type = consts.MessageType.INFO;
    message.sender_id = null;
    message.object_id = 1;
    message.object_type_id = consts.MessageObjectType.ACTION;
    response = await dataModule.sendMessage(message);
    if (response.data.success === 'false') {
      helpers.error('Wysyłanie wiadomości do wybranej osoby nie powiodło się');
    }
  }

  /*************************************************************************************************/

  nextStage() {
    this.currStageIndex++;
  }
  prevStage() {
    this.currStageIndex--;
  }
  async authorise() {
    this.$emit('authorise', { examCard: this, vueHtml2pdf: this.resultsDocPrint.rdPrint });
  }

  get canBeAuthorised(): boolean {
    console.log('EXAM_AUTH:', userModule.hasRightBySymbolInAllRights(consts.UserRightSymbols.EXAM_AUTHORISE));
    return (
      this.mode === this.viewModePrint &&
      userModule.hasRightBySymbolInAllRights(consts.UserRightSymbols.EXAM_AUTHORISE) === true &&
      this.exam.results_doc !== undefined &&
      this.exam.status === consts.ExamStatus.FINISHED.id
    );
  }

  authoriseKey = 0;
  updateByFormula(sampleParam: ExamSampleStageParam, stageParam: ExamStageParam, sample: Sample, value: string) {
    console.log('updateByFormula sampleParam', sampleParam, 'stageParam', stageParam, 'sample', sample, 'value', value);
    const symbol = stageParam.symbol;
    const symbolValues = {};
    const thisSampleParams = this.examSampleStageParams.filter(essp => essp.sample_id === sample.id);
    console.log('This sample params ', thisSampleParams);
    thisSampleParams.forEach(sap => {
      console.log('gathering sample params', sap);
      const stageParam = this.examStageParams.find(sp => sp.id === sap.param_id);
      const newVal = sap.value.toString().replace(',', '.');
      console.log('Replaced', newVal);
      symbolValues['$' + stageParam.symbol] = newVal;
      console.log('Added symbol', stageParam.symbol, sap.value);
    });
    console.log('symbolValues', symbolValues);
    const stageParamsToChange = this.examStageParams.filter(sp => {
      console.log('sp.formula=', sp.formula, 'symbol', symbol);
      return sp.formula.includes(symbol);
    });
    console.log('stageParamsToChange', stageParamsToChange);
    stageParamsToChange.forEach(sp => {
      console.log('sp to eval', sp);
      const sampleParam = this.examSampleStageParams.find(
        essp => essp.sample_id === sample.id && essp.param_id === sp.id
      );
      console.log('sampleParam value evaluating', sp.formula, sampleParam, symbolValues, ' BEFORE=', sampleParam.value);
      const val = math.evaluate(sp.formula, symbolValues);
      console.log('VAL pure', val);
      sampleParam.value = math.round(val, 2);
      sampleParam.value = sampleParam.value.toString().replace('.', ',');
      console.log('sampleParam value AFTER=', sampleParam.value);
    });
  }

  changeParamValue(e: KeyboardEvent, stageParam: ExamStageParam, sample: Sample) {
    console.log('Change params value', e, (e.target as HTMLInputElement).value, stageParam, sample);
    if (math.hasNumericValue((e.target as HTMLInputElement).value.toString().replace(',', '.')) === false) {
      helpers.error('Błędny format danych ! Przywrócona poprzednia wartość');
      (e.target as HTMLInputElement).value = '';
      return;
    }
    const p = this.examSampleStageParams.find(p => p.param_id === stageParam.id && p.sample_id === sample.id);
    p.value = (e.target as HTMLInputElement).value.toString().replace(',', '.');
    this.updateByFormula(p, stageParam, sample, p.value);
    p.value = p.value.toString().replace('.', ',');
    this.updateExamSampleStageParams();
  }
  getCurrentDate() {
    return moment({ year: this.currentYear, month: this.currentMonth, day: this.currentDay });
  }
  get currentDayTitle() {
    return moment(this.currentDay + '.' + this.currentMonth + '.' + this.currentYear, consts.DATE_FORMAT).format(
      consts.DATE_FORMAT
    );
  }
  goMonth() {
    console.log('');
    this.$router.push({ name: 'calendarexamsmonth', params: { day: this.currDate.format(consts.DATE_FORMAT) } });
  }
  printView() {
    this.mode = viewMode.PRINT;
  }
  formView() {
    this.mode = viewMode.FORM;
  }
  updateExam() {
    examModule.setExam(this.exam);
  }
  showAuthModal() {
    this.$bvModal.show('auth-modal');
  }
  hideAuthModal() {
    this.$bvModal.hide('auth-modal');
    this.authModalSpin = false;
  }
  updateExamStages() {
    examModule.setExamStages(this.examStages);
  }
  updateExamStageParams() {
    examModule.setExamStageParams(this.examStageParams);
  }
  updateExamSampleStageParams() {
    examModule.setExamSampleStageParams(this.examSampleStageParams);
  }
  setCurrentDate(dateString: string) {
    this.currDate = moment(dateString, 'DD_MM_YYYY');
    this.currentYear = this.currDate.year();
    this.currentWeek = this.currDate.week();
    this.currentDay = this.currDate.date();
    this.currentMonth = this.currDate.month();
    console.log('data:', this.currentDay, '.', this.currentMonth, '.', this.currentYear, ' week:', this.currentWeek);
  }
  isNotFinished(status: number) {
    return status !== consts.ExamStatus.FINISHED.id;
  }

  get isViewOnly() {
    return this.exam.status === consts.ExamStatus.FINISHED.id;
  }

  get isLabCoord() {
    return userModule.inGroupBySymbol(consts.UserGroupSymbols.LAB_COORD);
  }
  async cancelExamClick() {
    if (this.isLabCoord) {
      this.$router.push({
        name: 'calendarexamsdaylabcoord',
        params: { day: this.$route.params.date },
      });
    } else {
      this.$router.push({
        name: 'calendarexamsday',
        params: {
          day: this.$route.params.date,
          person_id: userModule.user.id.toString(),
        },
      });
    }
  }
  async saveAndExit() {
    if ((await this.saveExamClick()) === ExamSave.FAILURE) {
      return;
    }
    if (this.isLabCoord) {
      this.$router.push({
        name: 'calendarexamsdaylabcoord',
        params: { day: this.$route.params.date },
      });
    } else {
      this.$router.push({
        name: 'calendarexamsday',
        params: {
          day: this.$route.params.date,
          person_id: userModule.user.id.toString(),
        },
      });
    }
  }

  CTB_WORKER_GET_PERSON = 1;
  CLIENT_GET_PERSON = 2;
  GET_PERSON_K = 1;

  async created() {
    this.loading = true;
    console.log('CREATED', this.$route.params);
    moment.updateLocale('pl', { week: { dow: 1 } });
    const strDate = this.$route.params['date'];
    console.log('dateString', strDate);
    if (strDate !== undefined) {
      this.setCurrentDate(strDate);
    } else {
      this.setCurrentDate('01.01.2020');
    }

    this.exam_id = parseInt(this.$route.params['exam_id']);
    console.log('EXAM id', this.exam_id);

    // COPY
    this.exam = examsModule.examCopyById(this.exam_id);
    console.log(this.exam);
    this.exam.load();
    this.exam.samples.forEach(sample => {
      /*
      if (this.exam.results_doc?.get_person_id === this.CTB_WORKER_GET_PERSON) {
        sample['uw'] = this.GET_PERSON_K;
      } else {
        sample['uw'] = '';
      }*/
    });
    this.isAuthorised = this.exam.is_authorised;
    if (this.isAuthorised) {
      this.userAuthorised = this.exam.person_authorised.firstname + ' ' + this.exam.person_authorised.lastname;
    }

    this.updateExam();

    this.examStages = [];
    examsModule.examStages.forEach(es => {
      if (es.exam_id === this.exam.id) {
        this.examStages.push(examsModule.examStageCopyById(es.id));
        console.log('Stage ', es.name);
        this.examStageParams = [];
        examsModule.examStageParams.forEach(esp => {
          if (esp.exam_stage_id === es.id) {
            this.examStageParams.push(examsModule.examStageParamCopyById(esp.id));
            examsModule.examSampleStageParams.forEach(essp => {
              if (essp.param_id === esp.id) {
                this.examSampleStageParams.push(examsModule.examSampleStageParamCopyById(essp.id));
              }
            });
          }
        });
      }
    });

    this.currStageIndex = this.stage.index;
    console.log('Exam', this.exam);
    this.updateExamStages();
    this.updateExamStageParams();
    this.updateExamSampleStageParams();

    if (this.exam.results_doc === undefined) {
      this.exam.results_doc = helpers.getEmptyResultsDoc(
        dictModule.departments[0],
        consts.ResultsDocType.FROM_KPP,
        this.exam.client_id,
        [this.exam]
      );
      this.exam.results_doc.exam_id = this.exam_id;
    }

    await toolsModule.fetchTools();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Kalendarz badań - miesiąc',
          link: 'ce/cem',
        },
        {
          title: 'Karta pierwszego pomiaru - badanie nr ' + this.exam.number,
          link: '',
        },
      ],
    });

    this.loading = false;
  }
}
