























import TopPanel from '../../components/dummy/TopPanel.vue';
import Calendar from '../../components/dummy/Calendar.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Calendar,
    TopPanel,
  },
})
export default class CalendarMetroControl extends Vue {
  private view: object = { type: 'month', month: '12' };
}
