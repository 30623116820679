




















import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import ModalChooseTime from '@/components/dummy/ModalChooseTime.vue';

@Component({
  components: {
    ModalChooseTime,
  },
})
export default class TimeInput extends Vue {
  @Ref() readonly modalChooseTime: ModalChooseTime;
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: Boolean, default: false }) enabled;
  @Prop({ type: Boolean, required: false, default: true }) blackColor;
  events: [consts.ModalEvents.TIME_CHANGED_EVENT];
  private xtime = '';

  hours = Array(24)
    .fill(0)
    .map((e, i) => i);
  minutes = Array(60)
    .fill(0)
    .map((e, i) => i);

  get value(): string {
    return this.xtime;
  }
  set value(value: string) {
    console.log('Setting time ', value);
    const splitted = value ? value.split(':') : [];
    if (splitted.length >= 2) {
      let hour = parseInt(splitted[0]);
      if (!this.hours.includes(hour)) {
        hour = 0;
      }
      let minute = parseInt(splitted[1]);
      if (!this.minutes.includes(minute)) {
        minute = 0;
      }
      this.xtime = hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0');
    } else {
      this.xtime = '00:00';
    }
  }
  change(e: any) {
    this.value = e.target.value;
    this.$emit(consts.ModalEvents.TIME_CHANGED_EVENT, this.value);
  }

  mounted() {
    this.value = this.$attrs.value;
  }

  timeChooseModal() {
    this.modalChooseTime.showModal(this.xtime);
  }
  modalChooseTimeOK(time: string) {
    this.xtime = time;
    this.$emit(consts.ModalEvents.TIME_CHANGED_EVENT, this.value);
  }
}
