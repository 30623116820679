






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CalEvent, User, Breadcrumb, Visit } from '../../types';
import * as consts from '../../consts';
import * as helpers from '../../helpers';
import visitsModule from '@/store/modules/visits';
import ordersModule from '@/store/modules/orders';
import draggable from 'vuedraggable';
import { max } from 'node_modules/moment/ts3.1-typings/moment';

@Component({
  components: {
    draggable,
  },
})
export default class CalendarColumn extends Vue {
  @Prop({ type: Number }) colNumber: number;
  @Prop({ type: User, required: false }) user: User;
  @Prop({ type: Array, required: false }) events: CalEvent[];
  @Prop({ required: false, default: false }) isTime: boolean;
  @Prop({ required: true }) colWidth: number;
  //@Prop({ required: false }) firstVisitFocused: boolean;
  schedule = null;
  revalidate = 0;
  eventSched = [];

  get getUser() {
    console.log('CC getUser', this.user);
    return this.user;
  }

  eventsColliding(elem1: CalEvent, elem2: CalEvent) {
    return !(elem1.start > elem2.end || elem1.end < elem2.start);
  }

  emptyEventText = '---';

  processEventsPostitions() {
    let toCalc = this.events.slice();
    toCalc = toCalc.sort((a, b) => a.start - b.start);
    const calculated: Array<CalEvent> = [];
    const first = toCalc.shift();
    first.column = 0;
    calculated.push(first);
    let columnCount = -1;

    while (toCalc.length !== 0) {
      const actual = toCalc.shift();
      actual.colSpan = 0;
      let freePlace = false;
      calculated.forEach(el => {
        if (!this.eventsColliding(actual, el)) {
          if (freePlace) return;
          freePlace = true;
          actual.column = el.column;
        }
      });
      if (!freePlace) {
        actual.column = calculated[calculated.length - 1].column + 1;
        columnCount = Math.max(actual.column, columnCount);
      }
      calculated.push(actual);
    }

    let maxCol = -1;
    calculated.forEach(el => {
      maxCol = Math.max(el.column, maxCol);
    });

    for (let position = 0; position < calculated.length; position++) {
      const actual = calculated[position];
      let left = position - 1;
      let right = position + 1;
      let collided = false;
      let colSpan = 0;
      let iterProgress = true;
      while (iterProgress && !collided) {
        if (actual.column === maxCol) {
          colSpan = maxCol;
          break;
        }
        iterProgress = false;
        if (right < calculated.length && actual.column < calculated[right].column) {
          iterProgress = true;
          if (this.eventsColliding(calculated[position], calculated[right])) {
            collided = true;
            colSpan = maxCol + 1 - Math.abs(actual.column - calculated[right].column);
          } else {
            right++;
          }
        }
        if (left >= 0 && actual.column < calculated[left].column) {
          iterProgress = true;
          if (this.eventsColliding(calculated[position], calculated[left])) {
            collided = true;
            colSpan = maxCol + 1 - Math.abs(actual.column - calculated[left].column);
          } else {
            left--;
          }
        }
      }
      actual.colSpan = colSpan;
      actual.text = this.emptyEventText;
      if (colSpan !== 0) {
        console.log(calculated[position]);
        const obj = visitsModule.visitById(actual.object_id);
        const order = ordersModule.orderById(obj.order_id);
        actual.text = order.client_type === consts.ClientType.PRODUCER ? 'Producent' : 'Odbiorca';
      }
    }

    console.log('CalcEvents', calculated);
    this.eventSched = calculated;
  }

  fillSchedule() {
    const outHours = [];
    if (this.events.length !== 0 && !this.isTime) {
      this.processEventsPostitions();
    }

    if (this.isTime) {
      this.eventSched = this.events.slice();
    } else {
      for (let tenStart = 0; tenStart <= 6 * 24; tenStart += 1) {
        if (this.events !== undefined) {
          if (this.isTime === false) {
            outHours.push({
              start: tenStart,
              end: tenStart + 1,
              text: '',
              colspan: 0,
              column: 0,
            });
          }
        }
      }
    }

    this.schedule = outHours;
    this.revalidate++;
  }

  get getSchedule() {
    this.revalidate;
    //console.log('Revalidate get');
    return this.schedule;
  }

  get getEvents() {
    this.revalidate;
    return this.eventSched;
  }

  borderForEvent(event: CalEvent) {
    if (event.text !== '' && this.isTime === false) {
      if (event.object_id === undefined) {
        return '1px solid gray';
      }
      const visit = visitsModule.visitById(event.object_id);
      const css = visit.type_id === consts.VisitType.MAIN ? '2px solid red' : '2px solid cyan';
      return css;
    }
    return '';
  }

  get getColorForEvent() {
    return (event: CalEvent): string => {
      if (event.text !== '' && this.isTime === false) {
        const visit = visitsModule.visitById(event.object_id);
        const color = visit.type_id === consts.VisitType.MAIN ? 'red' : 'cyan';
        return color;
      }
      return '';
    };
  }

  deleteEmptyStringsFromObject(obj: any) {
    for (const key in obj) {
      if (obj[key] === '') {
        delete obj[key];
      }
    }
  }

  get getEventStyle() {
    return (event: CalEvent): object => {
      const oneEventLen = 10;
      const len = event.end - event.start;

      const height = oneEventLen * len;
      const width = this.colWidth / (event.colSpan + 1) - 5;

      // let addition = 0;
      // if (event.text !== '' && this.isTime === false) {
      //   //len++;
      //   addition = len;
      // }

      let css: any;

      if (event.object_id !== undefined) {
        css = {
          position: 'absolute',
          border: this.borderForEvent(event),
          height: height + 'px',
          top: event.start * 10 + 'px',
          zIndex: '2',
          width: width + 'px',
          left: event.column * (this.colWidth / (event.colSpan + 1)) + 'px',
          fontSize: 12 + 'px',
          backgroundColor: 'white',
        };
      } else {
        css = {
          height: height + 'px',
          'grid-area': event.start + 1 + '/' + this.colNumber + '/' + (+event.end + 1) + '/' + this.colNumber + '',
          'border-top': event.start % 6 === 0 ? '1px solid lightgray !important' : '',
          border: this.borderForEvent(event),
        };
      }
      this.deleteEmptyStringsFromObject(css);
      return css;
    };
  }
  get getColumnStyle() {
    if (this.isTime === true) {
      return { position: 'sticky', left: '0', backgroundColor: 'white', zIndex: '3', width: '80px' };
    } else {
      return { position: 'relative' };
    }
  }
  get getTimeFormatted() {
    return (no: number): string => {
      if (no > 0) {
        const hour = Math.floor(no / 6);
        const minutes = (no % 6) * 10;
        return (hour + '').padStart(2, '0') + ':' + (minutes + '').padStart(2, '0');
      } else {
        return '0:00';
      }
    };
  }

  get isSelected() {
    return (event: CalEvent) => {
      return event['isSelected'];
    };
  }

  clearSelected() {
    this.events.forEach(e => {
      e['isSelected'] = false;
    });
  }

  clickEvent(e, event: CalEvent, user: User) {
    console.log('Click', e, event, user);
    if (event.object_id !== undefined) {
      this.clearSelected();
      event['isSelected'] = true;
      this.fillSchedule();
      this.$parent.$emit('select-event', { event: event, user: user });
    }
    console.log('TYPE:');
  }
  created() {
    this.fillSchedule();
  }

  focusOnFirst() {
    const firstElement = document.getElementsByClassName('toFocus')[0];
    if (firstElement) {
      firstElement.scrollIntoView({ block: 'center', inline: 'center' });
    }
  }
  mounted() {
    this.focusOnFirst();
  }
}
