
























































































































































































import { Component, Vue } from 'vue-property-decorator';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';

import Multiselect from 'vue-multiselect';
import OrderConcrete from '../../../views/laborant/orders/OrderConcrete.vue';
import OrderGEO from '../../../views/laborant/orders/OrderGEO.vue';

import OrderVisit from '../../../views/laborant/orders/OrderVisit.vue';
import orderModule from '../../../store/modules/order';
import visitsModule from '../../../store/modules/visits';
import { OrderStatus, PageMode, DB_DATE_TIME_FORMAT } from '../../../consts';
import * as helpers from '../../../helpers';

import ordersModule from '../../../store/modules/orders';
import dictModule from '../../../store/modules/dict';
import offerModule from '../../../store/modules/offer';

import {
  BreadcrumbItem,
  Client,
  CollectAndExamProtocol,
  CollectCTBProtocol,
  ConstructionPlace,
  Mixer,
  Order,
  AppView,
} from '../../../types';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import moment from 'moment';
import * as consts from '../../../consts';

@Component({
  components: {
    OrderGEO,
    OrderConcrete,
    OrderVisit,
    TopPanel,
    Header,
    Footer,
    Multiselect,
  },
})
export default class OrderView extends Vue {
  view: AppView = {};
  mode? = this.$attrs['mode'];
  description = '';
  btnSaveOrderKey = 0;
  clientType = 1;
  revalidate = 0;
  examTypeConcrete = consts.ExamType.CONCRETE;
  examTypeGEO = consts.ExamType.GEO;
  examType = consts.ExamType.CONCRETE;
  orderDate = '';
  orderDateText = '';
  contractDate = '';
  contractDateText = '';
  cplaceSymbolRequired = false;

  get order() {
    return orderModule.order;
  }

  getSimilarCPlaces(order: Order) {
    const places = visitsModule.visits.filter(
      v =>
        moment(v.date).format(consts.DATE_FORMAT) === moment(order.visit.date).format(consts.DATE_FORMAT) &&
        v.laborant_id === order.visit.laborant_id &&
        v.client_id === order.visit.client_id
    );
    console.log('orderView::sameDayPrevVisits', places);
    this.cplaceSymbolRequired = places.length > 0;
    return places;
  }

  async isDestiny(order) {
    for (const mixer of order.visit.collect_and_exam_protocol.mixers) {
      for (const collect of mixer.collects) {
        for (const s of collect.samples) {
          if (s.destiny_id == undefined || s.destiny_id == 1) {
            return false;
          }
        }
      }
    }
    return true;
  }

  async saveOrder() {
    const order: Order = orderModule.order;
    console.log('Order COMPONENT:saveOrder', order);

    if (!('client_id' in order) || order['client_id'] === null) {
      helpers.error('Wybierz klienta');
      this.btnSaveOrderKey++;
      return;
    }
    if (!('cplace_id' in order) || order['cplace_id'] === 0) {
      helpers.error('Wybierz budowę');
      this.btnSaveOrderKey++;
      return;
    }

    const isDestiny = await this.isDestiny(order);
    if (isDestiny == false) {
      helpers.error('Wybierz przeznaczenie dla każdej próbki');
      this.btnSaveOrderKey++;
      return;
    }

    if (!order.visit.date) {
      helpers.error('Wypełnij datę wizyty głównej');
      this.btnSaveOrderKey++;
      return;
    }

    if (!('start_time' in order.visit) || order.visit['start_time'] === '') {
      helpers.error('Wypełnij godzinę rozpoczęcia wizyty głównej');
      this.btnSaveOrderKey++;
      return;
    }
    if (!('start_time' in order.take_ctb_visit) || order.take_ctb_visit['start_time'] === '') {
      helpers.error('Wypełnij godzinę rozpoczęcia wizyty CTB');
      this.btnSaveOrderKey++;
      return;
    }

    if (!orderModule.order.visit.laborant_id) {
      helpers.error('Wybierz pracownika terenowego pobierającego i wykonującego badania - zakładka -Wizyta-');
      this.btnSaveOrderKey++;
      return;
    }
    if (order.visit.date.includes('.')) {
      order.visit.date = moment(order.visit.date, consts.DATE_FORMAT).format(DB_DATE_TIME_FORMAT);
    }
    if (order.take_ctb_visit.date.includes('.')) {
      order.take_ctb_visit.date = moment(order.take_ctb_visit.date, consts.DATE_FORMAT).format(DB_DATE_TIME_FORMAT);
    }

    if (this.cplaceSymbolRequired === true && order.visit.client_cplace_symbol === '') {
      helpers.error('Wypełnij symbol budowy lub zmień datę wizyty/pracownika/budowę');
      this.btnSaveOrderKey++;
      return;
    }
    helpers.info('Zapisywanie zamówienia w toku...');

    // sprawdzamy czy mamy wizytę o tej samej dacie, laborancie terenowym i kliencie
    // jeeli tak to musimy do numeru próbki dodać symbol, jeeli nie ma symbolu musimy o niego poprosić laboranta

    //this.btnSaveOrderKey++;
    //return;

    let response = null;
    if (this.mode === PageMode.NEW) {
      console.log('--------------------------------------------------- AddNewOrder', order);
      response = await ordersModule.addOrderAction(order);
    } else {
      console.log('--------------------------------------------------- UpdateOrder', order);
      response = await ordersModule.updateOrderAction(order);
    }

    console.log('Response.data', response.data);
    if (response.data.success === true) {
      helpers.info('Zamówienie zapisane');
      const ctbVisit = response.data.order.take_ctb_visit;
      const pibVisit = response.data.order.visit;
      visitsModule.updateVisitByJSON(ctbVisit);
      visitsModule.updateVisitByJSON(pibVisit);
      this.$router.push({ path: '/lab/laborders' });
    } else {
      helpers.error('Błąd w trakcie zapisywnia zamówienia. restartuj aplikację i spróbuj jescze raz');
      this.btnSaveOrderKey++;
    }
  }

  orderDateTextLeave(event: any) {
    console.log('orderDateTextLeave', event);
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      console.log('Emptying wrong DATE today', today);
      this.orderDate = today.format(consts.DB_DATE_TIME_FORMAT);
      this.orderDateText = today.format(consts.DATE_FORMAT);
      // helpers.warn('Błędny format daty, przywrócona domyślna wartość.');
    }
  }

  fillOrderDate() {
    console.log('fillOrderData', this.order.date);
    if (!this.order.date) {
      const today = moment();
      console.log('Empty DATE today', today);
      this.orderDate = today.format(consts.DB_DATE_TIME_FORMAT);
      this.orderDateText = today.format(consts.DATE_FORMAT);
    } else {
      this.orderDate = orderModule.order.date;
      this.orderDateText = moment(orderModule.order.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    }
  }

  onMainDateContext(ctx) {
    console.log('onMainDateContext', ctx);
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) orderModule.order.date = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.fillOrderDate();
    } else {
      this.orderDateText = ctx.selectedFormatted;
    }
  }

  contractDateTextLeave(event: any) {
    console.log('contractDateTextLeave', event);
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      console.log('Emptying wrong contract DATE today', today);
      this.contractDate = today.format(consts.DB_DATE_TIME_FORMAT);
      this.contractDateText = today.format(consts.DATE_FORMAT);
      helpers.warn('Błędny format daty, przywrócona domyślna wartość.');
    }
  }

  fillContractDate() {
    if (this.order.contract_date !== null) {
      this.contractDate = orderModule.order.contract_date;
      this.contractDateText = moment(orderModule.order.contract_date, consts.DB_DATE_TIME_FORMAT).format(
        consts.DATE_FORMAT
      );
    }
  }

  onContractDateContext(ctx) {
    console.log('onContractDateContextX', ctx);
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) orderModule.order.contract_date = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.fillContractDate();
    } else {
      this.contractDateText = ctx.selectedFormatted;
    }
  }
  cancel() {
    this.$router.push({ path: '/lab/laborders' });
  }

  get enabled() {
    return this.mode === PageMode.NEW || this.mode === PageMode.EDIT;
  }

  get id() {
    return this.$route.params.order_id;
  }

  get title() {
    switch (this.mode as PageMode) {
      case PageMode.PREVIEW:
      case PageMode.EDIT: {
        const o = orderModule.order;
        if (o !== null) {
          return 'Zamówienie nr ' + o.number;
        } else {
          return 'Zamówienie';
        }
      }
      case PageMode.NEW:
        return 'Nowe zamówienie';
      default:
        return '';
    }
  }

  get clientSel() {
    this.revalidate;
    return this.client();
  }

  get cplaceSel() {
    this.revalidate;
    return this.cplace();
  }

  fillDescription() {
    this.revalidate;
    const o = orderModule.order;
    console.log('Order descr', o);
    this.description = o?.description;
  }

  get orderStatusName(): string {
    if (orderModule.order !== null) {
      return OrderStatus.byId(orderModule.order.status_id).name;
    } else {
      return '';
    }
  }

  client() {
    const o = orderModule.order;
    return dictModule.clientById(o.client_id);
  }

  cplace() {
    const o = orderModule.order;
    if (o !== null) {
      const cplace_id = o.cplace_id;
      if (cplace_id !== null) {
        return dictModule.cPlaceById(cplace_id) || new ConstructionPlace();
      } else {
        return new ConstructionPlace();
      }
    } else {
      return new ConstructionPlace();
    }
  }
  contract() {
    const o = orderModule.order;
    return offerModule.contractById(o.contract_id);
  }

  get getClients() {
    const clients = dictModule.clients;
    clients.sort((a, b) => {
      return parseInt(a.code) - parseInt(b.code);
    });
    clients.forEach(c => {
      c['code_name'] = c.code + ' - ' + c.name;
    });
    return clients;
  }

  get getClientCPlaces() {
    this.revalidate;
    const client: Client = this.client();
    if (client !== undefined) {
      const clientId = client.id;
      return dictModule.cPlaces.filter(c => {
        return c.client_id === clientId;
      });
    } else {
      return [];
    }
  }

  clientSelected(e: any) {
    const o = orderModule.order;
    o.client_id = (e as Client).id;
    o.client_name = (e as Client).name;
    o.cplace_id = 0;
    o.cplace_name = '';
    o.contract_id = 0;
    o.contract_number = '';
    o.visit.client_id = o.client_id;
    o.take_ctb_visit.client_id = o.client_id;
    orderModule.setOrder(o);
    this.revalidate++;
    this.contractRevalidate++;
  }

  cplaceSelected(e: any) {
    const o = orderModule.order;
    o.cplace_id = (e as ConstructionPlace).id;
    o.cplace_name = (e as ConstructionPlace).name;
    o.visit.cplace_id = o.cplace_id;
    o.take_ctb_visit.cplace_id = o.cplace_id;
    o.visit.client_cplace_symbol = (e as ConstructionPlace).symbol;
    orderModule.setOrder(o);
    this.revalidate++;
  }

  /**************************************** CONTRACT *************************************/

  contractNumber = '';
  contractRevalidate = 0;

  getClientContractNumber() {
    const clientId = this.client()?.id;
    console.log('ClientID=', clientId);
    return offerModule.contracts
      .filter(c => {
        return c.client_id === clientId;
      })
      .map(c => c.contract_number);
  }

  get getClientContracts() {
    this.contractRevalidate;
    const arr = [this.contractNumber !== '' ? this.contractNumber : [], ...this.getClientContractNumber()];
    console.log('getClientContracts', this.contractNumber, arr);
    return arr;
  }

  searchContractChange(e: any) {
    this.contractNumber = e;
    console.log('searchContractChange', e, this.contractNumber);
    this.contractRevalidate++;
  }

  get contractSel() {
    this.revalidate;
    console.log('contractSel');
    if (this.contract() !== undefined) {
      console.log('A', this.contract());
      return this.contract().contract_number;
    } else {
      console.log('B', this.order.contract_number);
      return this.order.contract_number;
    }
  }

  contractSelected(e: any) {
    console.log('contractSelected=', e, this.client()?.id);
    const clientId = this.client()?.id;
    const c = offerModule.contracts.find(c => {
      return c.client_id === clientId && c.contract_number === e;
    });

    if (c !== undefined) {
      this.order.contract_id = c.id;
      this.order.contract_number = c.contract_number;
      console.log('X');
    } else {
      this.order.contract_id = undefined;
      this.order.contract_number = e;
      console.log('Y');
    }
    orderModule.setOrder(this.order);
    console.log('Contract selected', this.order.contract_number);
    this.revalidate++;
  }

  descrChange(e: any) {
    const o = orderModule.order;
    this.description = e;
    o.description = e as string;
    orderModule.setOrder(o);
  }
  updateClientType(e: any) {
    const o = orderModule.order;
    o.client_type = o.visit.collect_and_exam_protocol.producer_receiver_type = this.clientType = e;
    console.log('clienTypeChanged', o.client_type);
  }
  /*
  errorHandler(err: any) {
    if (err.code == 1) {
      console.log("Error: Access is denied!");
    } else if (err.code == 2) {
      console.log("Error: Position is unavailable!");
    }
  }

  showLocation(position: Position) {
    const pos = {
      lat: position.coords.latitude.toFixed(5),
      lng: position.coords.longitude.toFixed(5)
    };
    console.log("point :" + pos.lat + "," + pos.lng);
  }*/

  /*
  get getGeoLocation() {
    if (navigator.geolocation) {
      console.log("navigator.geo exists");

      const options = { enableHighAccuracy: !0, timeout: 3e4, maximumAge: 0 };
      navigator.geolocation.getCurrentPosition(
        this.showLocation,
        this.errorHandler,
        options
      );
    } else {
      console.log("GEO not supported by this browser!");
    }
    return {};
  }

*/
  mounted() {
    console.log('Mounted');
  }
  created() {
    console.log('Mounted OrderView MODE:', this.mode);
    const breadcrumbItems: BreadcrumbItem[] = [
      {
        title: 'Lista zamówień',
        link: 'laborders',
      },
    ];

    if (this.mode === PageMode.NEW) {
      orderModule.createEmptyOrder();
      this.order.visit.collect_and_exam_protocol = new CollectAndExamProtocol();
      const newMixer = new Mixer();
      newMixer.index = 1;
      this.order.visit.collect_and_exam_protocol.mixers.push(newMixer);
      this.order.take_ctb_visit.collect_ctb_protocol = new CollectCTBProtocol();
      orderModule.setVisitPIB(this.order.visit);
      orderModule.setVisitCTB(this.order.take_ctb_visit);
      this.updateClientType(consts.ClientType.PRODUCER);
      breadcrumbItems.push({
        title: 'Nowe zamówienie',
        link: '',
      });
    } else {
      const orderId = parseInt(this.id);
      const editOrder = ordersModule.orderCopyById(orderId);
      editOrder.load(false);
      this.clientType = editOrder.client_type;
      orderModule.setOrder(editOrder);
      breadcrumbItems.push({
        title: 'Zamówienie nr ' + editOrder.number,
        link: '',
      });
    }
    console.log('ORDER after mounted', this.order);
    this.fillOrderDate();
    this.fillContractDate();
    this.fillDescription();

    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: breadcrumbItems,
    });
  }
}
