














import { Component, Vue } from 'vue-property-decorator';
import ProfileMenu from './ProfileMenu.vue';
import BreadcrumbPanel from '../../components/dummy/BreadcrumbPanel.vue';
import appModule from '../../store/modules/app';
@Component({
  components: { BreadcrumbPanel, ProfileMenu },
})
export default class ClientTopPanel extends Vue {
  get clientConfig() {
    const config = appModule.config;
    config.home_route_link = 'clientrdlist';
    return config;
  }
  mounted() {
    //this.$store.state.breadcrumb.breadcrumbObj.routePrefix = 'client';
  }
}
