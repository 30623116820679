















































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import moment from 'moment';
import hasher from 'ts-md5';
import * as consts from '@/consts';
@Component
export default class DashboardOfferHeader extends Vue {
  private mainTitle = '<brak nazwy>';
  private badges = [];

  @Prop({ required: true, type: String }) readonly title: string;
  @PropSync('fullscreenProp', { type: Boolean }) fullscreen: boolean;
  @PropSync('entityType', { type: Number }) entityTypeSelected: number;
  buttonClick = 0;

  addOfferQuestion() {
    this.$router.push({ name: 'addOfferQuestion', params: { redirectTo: consts.OfferActionStatus.REQUEST.symbol } });
  }

  get entitySel() {
    return { name: this.entityTypeSelected, text: this.entityTypes[this.entityTypeSelected].text };
  }
  entitySelected(event) {
    console.log('selected', event);
    this.entityTypeSelected = event.name;
  }

  get entityTypes() {
    return [
      { name: 0, text: 'Badania' },
      { name: 1, text: 'Urządzenia' },
      { name: 2, text: 'Personel' },
    ];
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    console.log('to', this.fullscreen);
  }
}
