































































































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import {
  ModalEvents,
  sampleToolParams,
  ResultsDocType,
  DestinyGroupCodes,
  ExamStatus,
  rdGetPeople,
  rdRules,
  CTB_WORKER_GET_PERSON,
} from '../../consts';
import { getEmptyResultsDoc } from '../../helpers';
import * as types from '../../types';
import examModule from '../../store/modules/exam';
import dictModule from '../../store/modules/dict';
import toolsModule from '../../store/modules/tools';
import samplesModule from '../../store/modules/samples';
import { create, all } from 'mathjs';
const math = create(all);

@Component
export default class ModalResultsDocConfig extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'xl' }) size;
  @Prop({ type: Array, required: false, default: [] }) examStageParams;
  @Prop({ type: Array, required: false, default: [] }) examSampleStageParams;

  private show = false;
  private events: [ModalEvents.OK_EVENT, ModalEvents.CANCEL_EVENT];

  exam = null;
  headerDescription = '';
  resultsDescription = '';
  uncertainty = '';

  calculateToolParams() {
    this.exam.samples.forEach(sample => {
      sample.sampleToolParams = JSON.parse(JSON.stringify(sampleToolParams));
      console.log('------------------- Sample ', sample.number, sample);
      sample.sampleToolParams.forEach(stp => {
        console.log('-------- stp:', stp.symbol);
        const thisSampleParams = this.examSampleStageParams.filter(essp => essp.sample_id === sample.id);
        if (!this.selectedTool) {
          return;
        }
        const thisToolsParams = this.selectedTool.params;
        if (thisToolsParams === undefined) {
          return;
        }
        const toolParam = thisToolsParams.find(p => p.symbol === stp.symbol);

        console.log('calculateToolParam', thisSampleParams, thisToolsParams);
        const symbolValues = {};
        thisSampleParams.forEach(sap => {
          //console.log('gathering sample params', sap.symbol, sap.value);
          const stageParam = this.examStageParams.find(sp => sp.id === sap.param_id);
          const newVal = sap.value.toString().replace(',', '.');
          symbolValues['$' + stageParam.symbol] = newVal;
          //console.log('Added symbol', stageParam.symbol, sap.value);
        });

        sample.sampleToolParams.forEach(p => {
          //console.log('gathering sample uncert params', p.symbol, p.value);
          const newVal = p.value.toString().replace(',', '.');
          symbolValues['$' + p.symbol] = newVal;
          //console.log('Added uncert symbol', p.symbol, p.value);
        });

        if (toolParam) {
          console.log('toolParam value evaluating', toolParam.formula, symbolValues);
          let val = math.evaluate(toolParam.formula, symbolValues);
          if (toolParam.symbol !== 'Uw' && isNaN(val)) {
            return;
          }
          console.log('Val=', val);
          if (toolParam.symbol === 'boF') {
            val = math.ceil(val);
            console.log('BoF after rounding', val);
          }
          const p = sample.sampleToolParams.find(sp => sp.symbol === toolParam.symbol);
          stp.value = val.toString().replace('.', ','); //math.round(val, 1).toString().replace('.', ',');

          console.log('param value AFTER=', stp.symbol, stp.value);
        }
      });
    });
  }

  get getParamValue() {
    return (paramSymbol: string, sample: types.Sample): string => {
      //console.log('getParamValue', paramSymbol, sample);
      const param = this.examStageParams.find(p => p.symbol === paramSymbol);
      let value = '';
      if (param !== undefined) {
        value = this.examSampleStageParams.find(sp => sp.param_id === param.id && sp.sample_id === sample.id).value;
        if (paramSymbol === 'SILA') {
          value = this.pad(value, 2);
        } else {
          value = this.pad(value, 1);
        }
        //console.log('-   getParamValue existing', paramSymbol, value);
      } else {
        //console.log('-   getParamValue new', paramSymbol, value);
        if (sample['sampleToolParams'] && paramSymbol !== 'SILA') {
          const p = sample['sampleToolParams'].find(p => p.symbol === paramSymbol);
          //console.log('p=', p, p.value.replace(',', '.'));
          value = math.round(p.value.replace(',', '.'), p.prec).toString().replace('.', ',');
          value = this.pad(value, p.prec);
          //console.log('-      getParamValue new <> SILA', paramSymbol, p, value);
        } else {
          //console.log('-      getParamValue new SILA', paramSymbol);
          value = this.pad(value, 2);
        }
      }
      return value;
    };
  }

  departments = dictModule.departments;

  selectedDepartment = this.departments[0];
  get selDepartment() {
    return this.selectedDepartment;
  }
  selectDepartment(e: any) {
    this.selectedDepartment = e;
  }

  GET_PERSON_K = 1;

  getPeople = rdGetPeople;
  selectedGetPerson = this.getPeople[0];
  get selGetPerson() {
    return this.selectedGetPerson;
  }
  selectGetPerson(e: any) {
    this.selectedGetPerson = e;
  }

  rules = rdRules;

  selectedRule = this.rules[0];
  get selRule() {
    return this.selectedRule;
  }
  selectRule(e: any) {
    this.selectedRule = e;
  }

  get tools() {
    let toolArray = [];
    //console.log('gathering tools...');
    if (this.exam) {
      const stages = examModule.examStages.filter(s => s.exam_id === this.exam.id);
      //console.log('Stages', stages);
      stages.forEach(stage => {
        //console.log('stage', stage.name, stage.tools);
        toolArray = [...toolArray, ...stage.tools];
      });
    }

    return toolArray;
  }

  selectedTool: types.Tool = null;

  get selTool() {
    return this.selectedTool;
  }

  selectTool(e: any) {
    console.log('Selected', e);
    this.selectedTool = e;
    if (this.selectedTool !== undefined) {
      this.selectedTool.params = toolsModule.toolParamsByToolId(this.selectedTool.id);
    }
    this.calculateToolParams();
  }

  @Emit()
  modalClose() {
    return 1;
  }

  showModal(exam: types.Exam) {
    console.log('showModal, exam', exam);
    this.exam = exam;
    if (this.exam.results_doc === undefined) {
      this.exam.results_doc = getEmptyResultsDoc(
        dictModule.departments[0],
        ResultsDocType.FROM_KPP,
        this.exam.client_id,
        [this.exam]
      );
    }
    this.selectedDepartment =
      this.departments.find(d => d.id === this.exam.results_doc.department_id) || this.departments[0];
    this.selectedGetPerson = this.getPeople.find(d => d.id === this.exam.results_doc.get_person_id);
    this.selectedRule = this.rules.find(d => d.id === this.exam.results_doc.rule_id);
    this.headerDescription = this.exam.results_doc.header_description;
    if (this.headerDescription === undefined) {
      this.headerDescription = '';
    }
    this.resultsDescription = this.exam.results_doc.results_description;
    if (this.resultsDescription === undefined) {
      this.resultsDescription = '';
    }
    this.selectedTool = this.tools.find(t => t.id === this.exam.results_doc.uncert_tool_id);
    if (this.selectedTool !== undefined) {
      this.selectedTool.params = toolsModule.toolParamsByToolId(this.selectedTool.id);
    }
    console.log('showModal, calculate..', this.selectedTool);
    this.calculateToolParams();
    this.show = true;
  }
  pad(val: string, prec: number) {
    const splitted = val.split(',');
    if (splitted.length > 1) {
      return splitted[0] + ',' + splitted[1].padEnd(prec, '0');
    } else {
      return splitted[0] + ',' + '0'.padEnd(prec, '0');
    }
  }
  async saveModal() {
    this.exam.samples.forEach(sample => {
      if (this.selectedGetPerson.id === CTB_WORKER_GET_PERSON) {
        sample.uw = this.GET_PERSON_K;
      } else {
        sample.uw = '';
      }
      const x = math.number(sample.uw.toString().replace(',', '.')) as number;
      const y = math.number(this.getParamValue('Uw', sample).toString().replace(',', '.')) as number;
      console.log('x,y', x, y);
      sample.uw = x + y;
      sample.uw = sample.uw.toString().replace('.', ',');
    });

    console.log('SAMPLES', this.exam.samples);
    for (let i = 0; i < this.exam.samples.length; i++) {
      console.log('this.exam.samples.uw', i, this.exam.samples[i], this.exam.samples[i].uw);
      await samplesModule.updateSampleUW({ sample_id: this.exam.samples[i].id, sample_uw: this.exam.samples[i].uw });
    }

    const headerDescription = this.headerDescription === '' ? '-' : this.headerDescription;
    const resultsDescription = this.resultsDescription === '' ? '-' : this.resultsDescription;
    const params = {
      samples: this.exam.samples,
      results_doc: {
        id: this.exam.results_doc.id,
        number: '',
        department_id: this.selectedDepartment.id,
        get_person_text: this.selectedGetPerson.name,
        get_person_id: this.selectedGetPerson.id,
        rule_text: this.selectedRule.name,
        rule_id: this.selectedRule.id,
        exam_id: this.exam.id,
        header_description: headerDescription,
        results_description: resultsDescription,
        uncert_tool_id: this.selectedTool?.id,
        client_id: this.exam.client_id,
        rd_type: ResultsDocType.FROM_KPP,
        uncertainty: this.uncertainty,
      },
    };

    this.$emit(ModalEvents.OK_EVENT, params);
    this.show = false;
  }
  hideModal() {
    this.$emit(ModalEvents.CANCEL_EVENT);
    this.show = false;
  }

  get getToolParam() {
    return (paramSymbol: string) => {
      //console.log('getToolParam', paramSymbol, this.selectedTool);
      if (this.selectedTool && this.selectedTool.params) {
        //console.log('Tool params', this.selectedTool.params);
        return this.selectedTool.params.find(p => p.symbol === paramSymbol);
      } else {
        return '';
      }
    };
  }

  get checkForUncertainty(): boolean {
    return dictModule.destinyGroupById(this.exam.destiny.group_id).symbol === DestinyGroupCodes.COMPRESSIVE_STREGTH;
  }

  get isDestinyWater(): boolean {
    const destiny = dictModule.destinyById(this.exam.destiny_id);
    const destinyGroupSymbol = dictModule.destinyGroups.find(d => d.id === destiny.group_id).symbol;
    return destinyGroupSymbol === DestinyGroupCodes.WATER;
  }

  isFinished(status: number) {
    console.log('status', status);
    return status === ExamStatus.FINISHED.id;
  }
}
