import { Visit, CollectAndExamProtocol, Client, User, CollectCTBProtocol, CollectProtocol, Protocol } from '@/types';
import visitModule from '@/store/modules/visit';
import visitsModule from '@/store/modules/visits';
import samplesModule from '@/store/modules/samples';
import userModule from '@/store/modules/user';
import { Component, Vue } from 'vue-property-decorator';
import { onlineHelper, forceUpdateForage } from '../offline';
import moment from 'moment';
import dictModule from '@/store/modules/dict';
import { DATE_FORMAT, DB_DATE_TIME_FORMAT, TIME_FORMAT, NEW_ID_START } from '@/consts';
import users from '@/store/modules/users';
import * as types from '@/types';
import * as helpers from '@/helpers';
import visits from '@/store/modules/visits';
import { PageMode } from '@/consts';

export function createSampleNumber(visit: Visit, person: User, lastNumber: number) {
  console.log('Visit date:', visit.date);
  const code = dictModule.clientById(visit.client_id).code;
  const initials = person.initials;
  const dt = moment(visit.date, DB_DATE_TIME_FORMAT);
  const dateString = dt.format('DDMM');
  const number = (lastNumber + 1).toString();
  // TO-DO jak wizyta jest kolejna w ciagu dnia to dodajemy do kodu A,B,C np. 1022A/0101/01AP
  // inaczej moze powtorzyc sie dla tej samej budowy numer
  return code + '/' + dateString + '/' + number + initials;
}

@Component
export default class VisitMixin extends Vue {
  isTreeVisible = false;
  btnSaveKey = 0;
  btnSaveOnlyKey = 0;

  get user() {
    return userModule.user;
  }
  get person() {
    return users.userById(this.visit.laborant_id);
  }
  get visit(): Visit {
    return visitModule.visit;
  }
  get oryginalVisit() {
    return visitsModule.visitById(this.visit.id);
  }
  get protocol(): CollectAndExamProtocol {
    return this.visit.collect_and_exam_protocol;
  }
  get protocolCtb(): CollectCTBProtocol {
    return this.visit.collect_ctb_protocol;
  }
  get protocolCollect(): CollectProtocol {
    return this.visit.collect_protocol;
  }
  get client(): Client {
    return this.visit.client;
  }
  get cplace() {
    return this.visit.cplace;
  }
  returnToCalendar() {
    this.$router.push({
      name: 'calendarvisitsdayperson',
      params: {
        date: this.oryginalVisit.getURLDate(),
        person_id: this.oryginalVisit.laborant_id.toString(),
        visit_id: this.visit.id.toString(),
      },
    });
  }

  getFinalNumber(user: types.User, index: number) {
    return (
      this.visit.client.code + '/' + moment().date() + (moment().month() + 1) + '/' + index.toString() + user.initials
    );
  }
  redirectedFromSynchro() {
    return this.$router.currentRoute.params.isDirectedBySynchro === 'y';
  }

  returnToSynchro() {
    this.$router.push({ name: 'Synchro' });
  }

  editAllowedByRoute() {
    if (!this.$router.currentRoute.params.edit) return true;
    if (this.$router.currentRoute.params.edit === 'y') {
      return true;
    }
    return false;
  }

  getMode(v: Visit) {
    return v.isEditable() && this.editAllowedByRoute() ? PageMode.EDIT : PageMode.PREVIEW;
  }

  // dla zwyklego numeru nie bedacego BOSTA 80
  // 80/0402/01PP  = kod budowy/DDMM/[index][Inicjaly pobierajacego]
  getSampleNumber(collectPlaceName: string, addSampleCount = 0, prevIndex = null) {
    let no = '';
    let noPrefix = '';
    const initials = this.person.initials;

    // budujemy date
    const dt: moment.Moment | string = this.visit.date.includes('-')
      ? moment(this.visit.date, DB_DATE_TIME_FORMAT)
      : moment(this.visit.date, DATE_FORMAT);

    // 1 i 2 czlon numeru
    noPrefix = this.visit.client.code + this.visit.cplace.symbol + '/' + dt.format('DDMM') + '/';

    const samplesToContinueNumeration = samplesModule.allSamples.filter(
      s => s.number.startsWith(noPrefix) && s.number.endsWith(initials)
    );
    const lastSample = samplesModule.sampleById(Math.max(...samplesToContinueNumeration.map(s => s.id)));
    let lastIndex = null;
    let index = null;

    if (lastSample !== undefined) {
      if (this.person.initials.length === 2) {
        lastIndex = lastSample.number.slice(-4, -2);
      } else {
        lastIndex = lastSample.number.slice(-5, -3);
      }
    } else {
      lastIndex = 0;
      index = 1;
    }
    lastIndex = Number(lastIndex);
    if (addSampleCount > 0) {
      index = lastIndex + 1 + addSampleCount;
    } else {
      index = lastIndex + 1;
    }

    if (!this.visit.client_data) {
      no = noPrefix + index.toString().padStart(2, '0') + this.visit.laborant.initials;
    } else {
      no = noPrefix + collectPlaceName + index + this.visit.laborant.initials;
    }
    return [no, index];
  }

  recalculateNewSamplesNumbers(addSampleCount: number) {
    console.log('Recalculate numbers... addSampleCount', addSampleCount);
    for (let mindex = 0; mindex < this.protocol.mixers.length; mindex++) {
      const mixer = this.protocol.mixers[mindex];
      for (let cindex = 0; cindex < mixer.collects.length; cindex++) {
        const collect = mixer.collects[cindex];
        let prevIndex = null;
        for (let sindex = addSampleCount; sindex > 0; sindex--) {
          const sample = collect.samples[collect.samples.length - sindex];
          if (sample !== undefined) {
            const [sampleNumber, lastIndex] = this.getSampleNumber(
              collect.collect_place,
              addSampleCount - sindex,
              prevIndex
            );
            sample.number = sampleNumber;
            prevIndex = lastIndex;
            console.log('Sample number recalculated', sample.number);
          }
        }
      }
    }
    this.updateProtocolPIB(this.protocol);
  }

  cancel() {
    this.$router.push({
      name: 'calendarvisitsdayperson',
      params: {
        date: this.oryginalVisit.getURLDate(),
        person_id: this.oryginalVisit.laborant_id.toString(),
        visit_id: this.visit.id.toString(),
      },
    });
  }

  getExamTimeBasedOnCollectTime(collectTime: string) {
    const momentCollectTime = moment(collectTime, TIME_FORMAT);
    const examTime = momentCollectTime.add(3, 'minutes').format(TIME_FORMAT);
    return examTime;
  }

  public async save(exit = true) {
    helpers.info('Zapisywanie wizyty w toku...');
    console.log(
      '------------------------------------------ SAVING visit --------------------------------------------------',
      this.visit,
      this.protocol
    );

    const laborant = users.userById(this.visit.laborant_id);
    if (this.protocol !== undefined) {
      for (let mindex = 0; mindex < this.protocol.mixers.length; mindex++) {
        const mixer = this.protocol.mixers[mindex];
        for (let cindex = 0; cindex < mixer.collects.length; cindex++) {
          const collect = mixer.collects[cindex];
          if (!exit) {
            console.log('Do not validate inputs... no exit');
            break;
          }
          if (
            !('collect_time' in collect) ||
            collect['collect_time'] === '' ||
            collect['collect_time'] === 'wybierz czas'
          ) {
            helpers.error('Wypełnij godzinę pobrania mieszanki na pobraniu');
            this.btnSaveKey++;
            return;
          }
          if (!('exam_time' in collect) || collect['exam_time'] === '' || collect['exam_time'] === 'wybierz czas') {
            helpers.error('Wypełnij godzinę badania mieszanki na pobraniu');
            this.btnSaveKey++;
            return;
          }
          if (collect['collec_place'] === '') {
            helpers.error('Wypełnij miejsce pobrania/badania');
            this.btnSaveKey++;
            return;
          }

          for (const sample of collect.samples) {
            if (sample.age === null) {
              helpers.error('Wypełnij wiek próbek');
              this.btnSaveKey++;
              return;
            }
          }
        }
      }
    }

    if (exit && (laborant === null || laborant === undefined)) {
      helpers.error('Wybierz osobę przeprowadzającą wizytę w oknie wizyty');
      this.btnSaveKey++;
      return;
    }

    this.visit.date = moment(this.visit.date, DATE_FORMAT).format(DB_DATE_TIME_FORMAT);
    console.log('Validation OK, saving ...', this.visit);

    const errorMessage =
      'Błąd podczas zapisu wizyty. Spróbuj zmienić dane lub skontaktuj sie z administratorem systemu';
    try {
      if (onlineHelper.isOnline && !this.redirectedFromSynchro()) {
        const response = await visitsModule.updateVisitAction(this.visit);
        const visitId2 = response.data.visit.id;
        if (exit) {
          this.returnToCalendar();
        } else {
          this.btnSaveOnlyKey++;
          visitModule.setVisit(visitsModule.visitById(visitId2));
        }
      } else {
        const visitId2 = this.visit.id;
        const fromSynchro = this.redirectedFromSynchro();

        await visits.updateVisitByJSON(this.visit);
        await visitsModule.addOfflineVisit(this.visit);

        if (exit) {
          fromSynchro ? this.returnToSynchro() : this.returnToCalendar();
        } else {
          this.btnSaveOnlyKey++;
          visitModule.setVisit(visitsModule.visitById(visitId2));
        }

        await forceUpdateForage();
      }
    } catch (err) {
      helpers.error(errorMessage);
      console.error(err);
    }
    helpers.info(onlineHelper.isOnline ? 'Wizyta zapisana' : 'Wizyta zapisana (OFFLINE)');
  }

  get enabled() {
    return this.visit.isEditable() && this.editAllowedByRoute() && !this.isTreeVisible;
    //return true;
  }
  checkPanelVisible(e: any) {
    const treePanel = document.getElementById('tree-panel');
    const treePanelTrigger = document.getElementById('tree-panel-trigger');
    if (treePanel !== undefined && treePanel !== null) {
      if (treePanelTrigger.contains(e.target) === false) {
        if (treePanel.contains(e.target) === false) {
          this.isTreeVisible = false;
        }
      }
    }
  }
  changeVisible(params: any) {
    console.log('changing treeVisible to', params.isTreeVisible);
    this.isTreeVisible = params.isTreeVisible;
    params.event.stopPropagation();
  }
  get start() {
    console.log('%c---------- Start of template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of template -----------', 'color:green;font-weight:600');
    return '';
  }
  updateVisit() {
    console.log('updating visit...');
    visitModule.setVisit(this.visit);
  }
  updateProtocolPIB(protocol: CollectAndExamProtocol) {
    console.log('updating protocol...');
    this.protocol.recipie_no = this.protocol.recipie_no.toUpperCase();
    visitModule.setProtocolPIB(protocol);
  }
}
