import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import usersModule from '@/store/modules/users';
import mixersModule from '@/store/modules/mixers';
import collectsModule from '@/store/modules/collects';
import collectExamsModule from '@/store/modules/collect_exams';
import samplesModule from '@/store/modules/samples';
import { IVisit } from '@/store/models';
import { Visit, ActionButton, User, Mixer } from '@/types';
import { VisitStatus, DATE_FORMAT, DB_DATE_TIME_FORMAT, VisitType, DB_DATE_FORMAT } from '@/consts';
import moment, { Moment } from 'moment';
import { onlineHelper } from '@/helpers/offline';
import store from '@/store';
import userModule from '@/store/modules/user';
import protocolsModule from '@/store/modules/protocols';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'visits',
  store,
  dynamic: true,
})
class VisitsModule extends VuexModule {
  public visits: Visit[] = [];

  public offlineVisits: Array<Visit> = [];

  @Mutation
  clear() {
    this.visits = [];
    this.offlineVisits = [];
    console.log('Visits module cleared...');
  }

  @Mutation
  public addOfflineVisit(v: Visit) {
    const found = this.offlineVisits.findIndex(el => el.id === v.id);
    if (found !== -1) {
      this.offlineVisits.splice(found, 1);
    }
    this.offlineVisits.push(v);
  }

  @Mutation
  public clearOfflineVisits() {
    this.offlineVisits = [];
  }

  public get allVisits() {
    return this.visits;
  }
  public get visitById() {
    return (id: number): Visit => {
      //console.log('visitById id', id, this.visits);
      return this.visits.find(v => v.id === id);
    };
  }

  public get mainVisitByOrderId() {
    return (orderId: number): Visit => {
      return this.visits.find(v => v.order_id === orderId && v.type_id === VisitType.MAIN);
    };
  }

  get visitsByUserAndDate() {
    return (user: User, date: Moment) => {
      return this.visits.filter(v => {
        const dt = v.date.includes('-') ? moment(v.date, DB_DATE_TIME_FORMAT) : moment(v.date, DATE_FORMAT);
        return dt.diff(date) === 0 && v.laborant_id === user.id;
      });
    };
  }

  get visitsByDate() {
    return (date: Moment) => {
      return this.visits.filter(v => {
        const dt = v.date.includes('-') ? moment(v.date, DB_DATE_TIME_FORMAT) : moment(v.date, DATE_FORMAT);
        return dt.diff(date) === 0;
      });
    };
  }

  get coordVisitsByDate() {
    return (user: User, date: Moment) => {
      return this.visits.filter(v => {
        const dt = v.date.includes('-') ? moment(v.date, DB_DATE_TIME_FORMAT) : moment(v.date, DATE_FORMAT);
        return moment(v.date, DB_DATE_TIME_FORMAT).diff(date) === 0 && v.laborant_id === user.id;
      });
    };
  }

  get visitCopyById() {
    return (id: number): Visit => {
      //console.log('ID', id);
      const str = JSON.stringify(this.visitById(id));
      const visitJson = JSON.parse(str);
      return Object.assign(new Visit(), visitJson);
    };
  }

  get print() {
    return (orderId: number, nested = false) => {
      console.log('  ----------------- VISITS ---------------');
      this.visits
        .filter(v => v.order_id === orderId)
        .forEach(visit => {
          console.log(
            '  VISIT: id:',
            visit.id,
            'type',
            visit.type_id,
            'number',
            visit.number,
            'order id',
            visit.order_id,
            'duration',
            visit.duration
          );
          if (nested) {
            protocolsModule.print(visit.id, nested);
          }
        });
    };
  }

  get printById() {
    return (visitId: number, nested = false) => {
      console.log('  ----------------- VISITS ---------------');
      const visit = this.visits.find(v => v.id === visitId);
      console.log(
        '  VISIT: id:',
        visit.id,
        'type',
        visit.type_id,
        'number',
        visit.number,
        'order id',
        visit.order_id,
        'duration',
        visit.duration
      );
      if (nested) {
        protocolsModule.print(visit.id, nested);
      }
    };
  }

  @Mutation
  addVisitByJSON(json: object) {
    //console.log('visitsModule::addVisitByJSON');
    const visit = Object.assign(new Visit(), json);
    visit.dateDb = visit.date;
    console.log('visit dateDb', visit.dateDb);
    if (json['collect_and_exam_protocol']) {
      //console.log('visitsModule::addVisitByJSON::MAIN');
      protocolsModule.addProtocolPIBByJSON(json['collect_and_exam_protocol']);
    }

    if (json['collect_ctb_protocol']) {
      //console.log('visitsModule::addVisitByJSON::CTB');
      protocolsModule.addProtocolCTBByJSON(json['collect_ctb_protocol']);
    }
    this.visits.push(visit);
  }

  @Mutation
  updateVisitByJSON(json: object) {
    const v = this.visits.find(v => v.id === json['id']);
    Object.assign(v, json);
    v.dateDb = moment(v.date, DATE_FORMAT).format(DB_DATE_TIME_FORMAT);
    console.log('visit date db on update');
    v.dateDb = v.date;
    console.log('visit date db on update');
    if ('collect_and_exam_protocol' in json && json['collect_and_exam_protocol']) {
      protocolsModule.replaceProtocolPIBByJSON(json['collect_and_exam_protocol']);
    }
    if ('collect_ctb_protocol' in json && json['collect_ctb_protocol']) {
      protocolsModule.replaceProtocolCTBByJSON(json['collect_ctb_protocol']);
    }
  }

  @Mutation
  updateVisit(visit: Visit): any {
    //console.log('updateVisitmutation', visit);
    const v = this.visits.find(v => v.id === visit.id);
    v.dateDb = v.date;
    Object.assign(v, visit);
  }

  @Mutation
  setVisits(visits: Visit[]) {
    this.visits = [];
    visits.forEach(visit => {
      const vis = Object.assign(new Visit(), visit);
      vis.dateDb = vis.date;
      this.visits.push(vis);
    });
  }

  @Mutation
  appendVisit(visit: Visit) {
    //console.log('AppendVisit', visit);
    this.visits.push(visit);
  }

  @Mutation
  loadVisitsFromConfig(visits: IVisit[]): any {
    this.visits = visits.map(v => Object.assign(new Visit(), v));
    console.log('Store module VisitsModule visits loaded...', this.visits.length);
  }

  @Mutation
  deleteVisit(visit: Visit) {
    this.visits = this.visits.filter(v => v !== visit);
  }

  @Action({ rawError: true })
  async addVisitAction(visit: Visit): Promise<AxiosResponse> {
    const response = await API.saveVisit(visit);
    if (response.data.success) {
      this.addVisitByJSON(response.data.visit);
      const newVisit = this.visitById(response.data.visit.id);
      newVisit.load(false);
      //console.log('   Added to VUEX visits DONE:', this.visits.length);
      return response;
    }
  }

  @Action
  async removeRedundantData(visit: Visit) {
    const visitToSaveInDb = JSON.parse(JSON.stringify(visit));
    visitToSaveInDb.collect_and_exam_protocol.mixers.forEach(m =>
      m.collects.forEach(c => c.methods.forEach(m => delete m.method_users))
    );
    delete visitToSaveInDb.laborant;
    return visitToSaveInDb;
  }

  @Action({ rawError: true })
  async updateVisitAction(visit: Visit): Promise<AxiosResponse> {
    const visitToSaveInDb = await this.removeRedundantData(visit);
    console.log('updateVisitAction::Updating visit', visitToSaveInDb);
    const response = await API.saveVisit(visitToSaveInDb);
    if (response.data.success) {
      this.updateVisitByJSON(response.data.visit);
      const newVisit = this.visitById(response.data.visit.id);
      newVisit.load(false);
    }
    return response;
  }

  @Action({ rawError: true })
  async updateVisitIsProtDownloaded(visit: Visit): Promise<AxiosResponse> {
    console.log('updateVisitIsProtDownloaded::Updating visit', visit);
    const response = await API.saveVisitIsProtDownloaded(visit);
    if (response.data.success) {
      visit.id = response.data.visit.id;
      visit.is_prot_downloaded = true;
      this.updateVisit(visit);
      console.log('Replace to VUEX visits', visit);
      return response;
    }
    return response;
  }

  @Action
  @RelatedModel('Visit')
  async fetchVisits(): Promise<AxiosResponse> {
    console.log('*** FetchVisits');
    const response = await API.fetchVisits();
    if (response.data.success) {
      this.setVisits(response.data.visits);
      console.log('   fetchVisits VUEX DONE:', this.visits.length);
      return response;
    }
  }

  @Action
  async deleteVisitAction(id: number): Promise<boolean> {
    let result = false;
    const response = await API.deleteVisit(id);
    if (response.data.success) {
      console.log('Visit', id, 'removed...');
      result = true;
    }
    return result;
  }

  appendMainVisit(visit: Visit, mixers: Mixer[]) {
    this.appendVisit(visit);

    const prot_pib = visit.collect_and_exam_protocol;
    protocolsModule.appendProtocol(prot_pib);

    mixers.forEach(mixer => {
      mixersModule.appendMixer(mixer);
      mixer.collects.forEach(collect => {
        collectsModule.appendCollect(collect);
        collectExamsModule.appendCollectExam(collect.exam);
        collect.samples.forEach(sample => {
          samplesModule.appendSample(sample);
        });
      });
    });
  }

  appendCTBVisit(take_ctb_visit: Visit) {
    this.appendVisit(take_ctb_visit);
    const protocol_ctb = take_ctb_visit.collect_ctb_protocol;
    protocolsModule.appendProtocol(protocol_ctb);
  }
}

export default getModule(VisitsModule);
