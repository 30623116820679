import { render, staticRenderFns } from "./OfferFixAmountSettlement.vue?vue&type=template&id=c7f3f332&"
import script from "./OfferFixAmountSettlement.vue?vue&type=script&lang=ts&"
export * from "./OfferFixAmountSettlement.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports