import * as API from '@/store/api';
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
@Module({
  namespaced: true,
  name: 'moduleManager',
  store,
  dynamic: true,
})
class ModuleManager extends VuexModule {
  moduleVersions: Map<string, number> = new Map();
  modulesToUpdate: Array<string> = [];

  public get checkIfUpdateNeeded() {
    return (moduleName: string) => {
      return this.modulesToUpdate.indexOf(moduleName) !== -1;
    };
  }

  @Mutation
  setVersions(versions: Map<string, string>) {
    this.modulesToUpdate = [];
    for (const [key, value] of Object.entries(versions)) {
      const storedVersion = this.moduleVersions[key];
      if (storedVersion !== value) {
        console.log(key, '] VUEX=', storedVersion, 'DB=', value);
        this.moduleVersions[key] = value;
        this.modulesToUpdate.push(key);
      } else {
        console.log(key, '] VUEX=DB=', value);
      }
    }
  }

  @Action({ rawError: true })
  async fetchVersions() {
    const response = await API.fetchVersions();
    const versions = response.data.versions;

    this.setVersions(versions);
  }

  @Mutation
  clearVersions() {
    this.moduleVersions = new Map<string, number>();
    /*this.moduleVersions.forEach(moduleV => {
      moduleV = 0;
    });*/
    console.log('Module Manager versions cleared..');
  }
}
export default getModule(ModuleManager);
