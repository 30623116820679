









































































































































































































































































































































































































































































import { Component, Ref, Watch } from 'vue-property-decorator';
import VisitHeader from '../../../views/laborant/visits/VisitHeader.vue';
import Footer from '../../../components/dummy/Footer.vue';
import visitModule from '../../../store/modules/visit';
import dictModule from '../../../store/modules/dict';
import { Collect, Destiny, DestinyGroup, Mixer, Sample, BreadcrumbItem, Method, Visit } from '../../../types';
import * as consts from '../../../consts';
import { TakeExamPlaces, CollectExamType } from '../../../consts';
import methodsModule from '../../../store/modules/methods';
import VisitCollectExamS from '../../../views/laborant/visits/VisitCollectExamS.vue';
import VisitCollectExamF from '../../../views/laborant/visits/VisitCollectExamF.vue';
import VisitCollectExamSF from '../../../views/laborant/visits/VisitCollectExamSF.vue';
import ModalQuestion from '../../../components/dummy/ModalQuestion.vue';
import * as helpers from '../../../helpers';
import VisitTree from '../../../views/laborant/visits/VisitTree.vue';
import { EventBus, BusEvents } from '../../../helpers/eventbus';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import visitMixin from '../../../helpers/components/visit';
import TimeInput from '../../../components/dummy/TimeInput.vue';

@Component({
	components: {
		VisitCollectExamS,
		VisitCollectExamF,
		VisitCollectExamSF,
		ModalQuestion,
		VisitHeader,
		Footer,
		VisitTree,
		TimeInput,
	},
})
export default class VisitCollect extends visitMixin {
	CollectExamType = CollectExamType;
	@Ref() readonly removeQuestion: ModalQuestion;
	@Ref() readonly removeMethodQuestion: ModalQuestion;

	destinyGroup = null;
	collect: Collect = null;
	mixer: Mixer = null;

	collectTime = '';
	examTime = '';
	collectPlace = {};
	methodsVisible = false;
	selDestinyGroupsAdd = null;
	selDestinyAdd = null;
	selAgeAdd = 1;
	destiniesAdd = [];
	revalidateAdd = 0;
	sampleAddCount = 1;

	selectedProtMethod = null;

	protMethodInvalidate = 0;
	timeInvalidate = 0;

	get getMethodClass() {
		return (method: Method) => {
			const m = this.collectMethods.find(m => m === method);
			console.log('CLASS', m);
			return m['method_class'];
		};
	}
	get selProtMethod() {
		console.log('selProtMethods');
		return this.selectedProtMethod;
	}
	get protMethods() {
		this.protMethodInvalidate;
		const groups = [];
		this.collect.samples.forEach(s => {
			const g = dictModule.getDestinyGroupByDestinyId(s.destiny_id);
			if (!groups.includes(g.symbol)) {
				groups.push(g.symbol);
			}
		});

		this.updateProtConsistencyExam();
		const methods = methodsModule.methods.filter(m => {
			let result = false;
			for (let i = 0; i < groups.length; i++) {
				const g = groups[i];

				if (m.protocol_destiny_groups.includes(g) && !this.collect.methods.find(el => el.id === m.id)) {
					result = true;
				}
			}
			return result;
		});
		methods.forEach(m => {
			m['name_norm'] = m.name + ' : ' + m.norm;
			m['method_class'] = '';
		});
		return methods;
	}

	get isCollectTimeChosen() {
		if (!this.collect) {
			return false;
		}
		return this.collect.collect_time !== this.mixer.load_time;
	}

	get isExamTimeChosen() {
		if (!this.collect) {
			return false;
		}
		const defaultTimeMixer = this.mixer.load_time.slice(0, 4) + '3';
		const defaultTimeCollect = this.collect.collect_time.slice(0, 4) + '3';
		return this.collect.exam_time !== defaultTimeMixer && this.collect.exam_time !== defaultTimeCollect;
	}

	chooseProtMethod(e: any) {
		console.log('chooseProtMethod', e);
		this.selectedProtMethod = e;
	}

	blinking = false;
	interval = 0;
	//intervalId = 0;

	blink(method: any) {
		console.log('Blinking', this.blinking, this.interval);
		this.interval++;
		if (this.interval > 5) {
			//clearInterval(this.intervalId);
			this.collect.methods.find(m => m.number === method.number)['method_class'] = '';
			this.methodInvalidate++;
			this.blinking = false;
			this.interval = 0;
		} else {
			console.log(
				'blink Method',
				method,
				this.collect.methods.find(m => m.number === method.number)
			);
			this.collect.methods.find(m => m.number === method.number)['method_class'] = this.blinking ? 'blinking' : '';
			this.methodInvalidate++;
			this.blinking = !this.blinking;
			setTimeout(() => {
				console.log('timeout 500');
			}, 200);
		}
	}

	addProtMethod() {
		this.collect.methods.push(this.selectedProtMethod);
		this.methodInvalidate++;
		const elementToScrollTo = document.getElementById(this.selectedProtMethod.number);
		console.log('Scrolling to', elementToScrollTo);
		if (elementToScrollTo) {
			elementToScrollTo.scrollIntoView({ block: 'start', behavior: 'smooth' });
		}
		//this.intervalId = setInterval(this.blink, 500, this.selectedProtMethod);
		this.protMethodInvalidate++;
	}

	removeMethod(method: Method) {
		this.removeMethodQuestion.showModal(method);
	}
	removeMethodOK(method: Method) {
		const index = this.collect.methods.findIndex(m => m.name === method.name && m.norm === method.norm);
		console.log('Removing index', index);
		this.collect.methods.splice(index, 1);
		this.protMethodInvalidate++;
	}

	get visitTitle() {
		const mIndex = this.visit.collect_and_exam_protocol.mixers.findIndex(mixer => mixer.id === this.mixer.id);
		const cIndex = this.mixer.collects.findIndex(collect => collect.id === this.collect.id);
		return 'Pobranie ' + (mIndex + 1) + '-' + (cIndex + 1);
	}

	methodInvalidate = 0;

	get collectMethods() {
		this.methodInvalidate;
		return this.collect.methods;
	}

	modalChooseCollectTimeOK(time: string) {
		console.log('Changing collect time', time);
		this.collect.collect_time = time;
		this.collectTime = time;
		const examTime = this.getExamTimeBasedOnCollectTime(time);
		this.collect.exam_time = examTime;
		this.examTime = examTime;
		this.timeInvalidate++;
		this.updateCollect();
		helpers.warn('Dodatkowo data BADANIA próbek uległa zmianie');
	}
	collectTimeContext(e: any) {
		this.collect.collect_time = e.formatted;
		this.updateCollect();
	}
	modalChooseExamTimeOK(time: string) {
		this.collect.exam_time = time;
		this.updateCollect();
	}
	examTimeContext(e: any) {
		this.collect.exam_time = e.formatted;
		this.updateCollect();
	}
	get getCollectPlace() {
		return this.collectPlace;
	}
	placeSelected(e: any) {
		this.collectPlace = e;
		this.collect.collect_place = e.name;
		this.updateCollect();
	}

	get mixtureTemp() {
		console.log('get mixtureTemp', this.collect.mixture_temp);
		return this.collect.mixture_temp;
	}
	set mixtureTemp(value: string) {
		this.collect.mixture_temp = value;
		this.updateCollect();
	}

	get airTemp() {
		console.log('get airTemp', this.collect.air_temp);
		return this.collect?.air_temp;
	}
	set airTemp(value: string) {
		this.collect.air_temp = value;
		this.updateCollect();
	}

	get airContents() {
		console.log('get airContents', this.collect.air_contents);
		return this.collect.air_contents;
	}
	set airContents(value: boolean) {
		this.collect.air_contents = value;
		this.updateCollect();
	}
	get consistencyExam() {
		console.log('get consistencyExam', this.collect.consistency_exam);
		return this.collect.consistency_exam;
	}
	set consistencyExam(value: boolean) {
		this.collect.consistency_exam = value;
		this.updateCollect();
	}

	get airContentsResult() {
		console.log('get airContentsResult', this.collect.air_contents_result);
		return this.collect.air_contents_result;
	}
	set airContentsResult(value: string) {
		this.collect.air_contents_result = value.toString();
		this.updateCollect();
	}

	get getExamType() {
		console.log('get examType', this.collect.exam_type_id);
		const ctype = CollectExamType.byId(this.collect.exam_type_id);
		return { name: ctype.name, id: ctype.id };
	}

	consistencyNorm = '';

	updateProtConsistencyExam() {
		console.log('updateProtConsistencyExam', this.collect.exam_type_id);
		switch (this.collect.exam_type_id) {
			case consts.CollectExamType.S.id:
				this.consistencyNorm = consts.ConsistencyNorms.S;
				break;
			case consts.CollectExamType.F.id:
				this.consistencyNorm = consts.ConsistencyNorms.F;
				break;
			case consts.CollectExamType.SF.id:
				this.consistencyNorm = consts.ConsistencyNorms.SF;
				break;
			default:
				break;
		}
		// clear
		this.collect.methods = this.collect.methods.filter(m => {
			return !(
				m.norm.includes(consts.ConsistencyNorms.S) ||
				m.norm.includes(consts.ConsistencyNorms.F) ||
				m.norm.includes(consts.ConsistencyNorms.SF)
			);
		});
		// assign current
		if (this.consistencyExam) {
			const consistencyMethods = methodsModule.methods.filter(m => {
				// console.log(
				//   'Method check consistency',
				//   m.norm,
				//   this.consistencyNorm,
				//   this.consistencyExam,
				//   m.norm.includes(this.consistencyNorm) && this.consistencyExam
				// );
				if (
					m.norm.includes(consts.ConsistencyNorms.S) ||
					m.norm.includes(consts.ConsistencyNorms.F) ||
					m.norm.includes(consts.ConsistencyNorms.SF)
				) {
					return m.norm.includes(this.consistencyNorm);
				}
				return false;
			});
			console.log('Consistency methods', consistencyMethods);
			if (consistencyMethods.length > 0) {
				this.collect.methods = [...this.collect.methods, consistencyMethods[0]];
			}
		}
	}

	updateProtAirContent() {
		const airContentMethods = methodsModule.methods.filter(m =>
			m.norm.includes(consts.ProtocolNorms.MIXTURE_AIR_CONTENT)
		);
		const consistencyMethod = this.collect.methods.find(m => m.norm.includes(consts.ProtocolNorms.MIXTURE_AIR_CONTENT));
		console.log('airContentMethods', airContentMethods, consistencyMethod);
		if (consistencyMethod === undefined && airContentMethods.length > 0) {
			this.collect.methods = [...this.collect.methods, airContentMethods[0]];
		}
	}

	examTypeSelected(e: any) {
		console.log('Exam type selected', e);
		this.collect.exam_type_id = (e as CollectExamType).id;

		this.updateProtConsistencyExam();

		visitModule.addNewCollectExam({ collect: this.collect });

		this.updateCollect();
		console.log('Collect exam_type_id', this.collect.exam_type_id, this.collect.exam);
	}

	get collectSamples() {
		console.log('get collectSamples', this.collect.samples);
		return this.collect.samples;
	}

	get selDestinyGroup() {
		return (sample: Sample): DestinyGroup => {
			const group = dictModule.getDestinyGroupByDestinyId(sample.destiny_id);
			return group;
		};
	}
	get destinyGroups() {
		console.log('get destinyGroups', dictModule.destinyGroups);
		return dictModule.destinyGroups;
	}

	updateProtNormBySamples() {
		console.log('# updateProtNormBySamples');
		this.collect.samples.forEach(sample => {
			console.log('Processing sample ', sample.id);
			const destSymbol = dictModule.getDestinyGroupByDestinyId(sample.destiny_id).symbol;
			let makeSampleMethods = [];
			if (destSymbol === consts.DestinyGroupCodes.COMPRESSIVE_STREGTH) {
				console.log('make sample norm C');
				makeSampleMethods = methodsModule.methods.filter(m => m.norm.includes(consts.MakeSampleNorms.C));
			} else {
				console.log('make sample norm ALL');
				makeSampleMethods = methodsModule.methods.filter(m => m.norm.includes(consts.MakeSampleNorms.ALL));
			}
			makeSampleMethods.sort((a, b) => b.actual - a.actual); // actual na gorze tablicy
			console.log('makeSampleMethods', makeSampleMethods, this.collect.methods.length);
			if (makeSampleMethods.length > 0) {
				this.collect.methods = this.collect.methods.filter(m => {
					console.log(
						'##Method',
						m.id,
						m.name,
						m.norm,
						makeSampleMethods[0].norm,
						m.norm.includes(consts.MakeSampleNorms.C) || m.norm.includes(consts.MakeSampleNorms.ALL)
					);
					return m.norm.includes(consts.MakeSampleNorms.C) || m.norm.includes(consts.MakeSampleNorms.ALL)
						? m.norm === makeSampleMethods[0].norm // jezeli metoda nieaktualna a nie mamy aktualnej to zawsze bierzemy pierwsza, choc ten przypadek raczej sie nie pojawi
						: true;
				});
			}
		});
	}

	destinyGroupSelected(e: any, sample: Sample) {
		console.log('destinyGroupSelected e=', e, ' sample=', sample);
		const selSample = this.collect.samples.find(s => s.id === sample.id);
		const dests = dictModule.destiniesByGroupId(e.id);
		selSample.destiny_id = dests[0].id;

		// updaetuje dla probek norme dotyczaca wykonania probek ktora dla C i pozostalych (ALL) jest inna
		this.updateProtNormBySamples();

		this.updateCollect();
	}

	get destinies() {
		return (sample: Sample): Destiny[] => {
			const group = dictModule.getDestinyGroupByDestinyId(sample.destiny_id);
			//console.log("destinies sample=", sample);
			return dictModule.destiniesByGroupId(group.id);
		};
	}
	get selDestiny() {
		return (sample: Sample): Destiny => {
			const selSample = this.collect.samples.find(s => s.id === sample.id);
			return dictModule.destinyById(selSample.destiny_id);
		};
	}

	destinySelected(e: any, sample: Sample) {
		const selSample = this.collect.samples.find(s => s.id === sample.id);
		selSample.destiny_id = (e as Destiny).id;
		this.updateCollect();
	}

	/******************************* AGE *************************/

	get sampleAge() {
		return (sample: Sample) => {
			console.log('getSampleAge id=', sample.id, 'age=', sample.age);
			return sample.age;
		};
	}
	changeSampleAge(event: any, sample: Sample) {
		console.log('Sample age', event);
		sample.age = parseInt(event.target.value);
		this.updateCollect();
	}

	/****************************** ADD SAMPLE  *****************************/

	get destinyGroupsAdd() {
		return dictModule.destinyGroups;
	}
	destinyGroupAddSelected(e: any) {
		this.revalidateAdd;
		console.log('destinyGroupAddSelected', e);
		this.selDestinyGroupsAdd = e;
		this.destiniesAdd = dictModule.destiniesByGroupId(e.id);
		this.selDestinyAdd = this.destiniesAdd[0];
		this.destinyAddSelected(this.selDestinyAdd);
		console.log('this.selDestinyAdd', this.selDestinyAdd);
		this.revalidateAdd++;
	}
	get destiniesAddItems() {
		return this.destiniesAdd;
	}

	get selDestinyAddItem() {
		this.revalidateAdd;
		console.log('this.selDestinyAddItem', this.revalidateAdd);
		return this.selDestinyAdd;
	}

	destinyAddSelected(e: any) {
		this.revalidateAdd;
		console.log('destinyAddSelected', e);
		this.selDestinyAdd = e;
		const group = dictModule.getDestinyGroupByDestinyId(e.id);
		console.log('Group', group);
		if (group.symbol === consts.DestinyGroupCodes.COMPRESSIVE_STREGTH) {
			if (this.selDestinyAdd.id) {
				this.selAgeAdd = this.selDestinyAdd.name;
			}
		}
		if (group.symbol === consts.DestinyGroupCodes.WATER) {
			this.selAgeAdd = this.protocol.defaultWAge;
		}
		if (group.symbol === consts.DestinyGroupCodes.FROZEN) {
			this.selAgeAdd = this.protocol.defaultFAge;
		}
		console.log('this.selAgeAdd=', this.selAgeAdd);
		this.revalidateAdd++;
		//this.$forceUpdate();
	}

	get sampleAgeAdd() {
		this.revalidateAdd;
		console.log('sampleAgeAdd', this.revalidateAdd);
		return this.selAgeAdd;
	}
	changeAddSampleAge(e: any) {
		console.log('changeAddSampleAge', e.target.value);
		this.selAgeAdd = e.target.value;
	}

	addSampleKey = 0;
	addSampleCount = 0;
	indexOfLastOldSamples = null;

	async addSample() {
		if (this.sampleAddCount > 0) {
			console.log('AddSample', this.visit, this.person, this.collect, ' sampleAddCount=', this.sampleAddCount);
			const sCount: number = this.collect.samples.length + parseInt(this.sampleAddCount.toString());
			console.log('sCount', sCount);

			let prevNumber = null; // jest to sam index (integer) numeru probki
			for (let i = this.collect.samples.length; i < sCount; i++) {
				console.log('Adding ', i + 1, ' sample...', this.collectPlace['name']);
				const [number, lastNumber] = this.getSampleNumber(this.collectPlace['name'], this.addSampleCount, prevNumber);
				await visitModule.addNewSample({
					person: this.person,
					collect: this.collect,
					destiny: this.selDestinyAdd,
					age: this.sampleAgeAdd,
					sampleNumber: number,
				});
				prevNumber = lastNumber;
				console.log('Sample ', i + 1, ' added');
			}

			this.updateCollect();
			this.updateProtNormBySamples();
			helpers.info('Próbki dodane');
			this.addSampleKey++;
			this.addSampleCount++;
		} else {
			helpers.error('Liczba dodawanych próbek musi być większa od 0!');
			this.addSampleKey++;
		}
	}
	async removeSample(sample: Sample) {
		console.log('remove', sample);
		await this.removeOK(sample);
	}

	async removeOK(sample: Sample) {
		console.log('Removing sample', sample);
		if (sample.id >= consts.NEW_ID_START) {
			this.addSampleCount--;
		}
		await this.recalculateNewSamplesNumbers(this.addSampleCount);
		this.collect.samples = this.collect.samples.filter(s => s.id !== sample.id);
		this.collect.sample_ids = this.collect.sample_ids.filter(s => s !== sample.id);
		await this.updateCollect();
		helpers.info('Próbka usunięta...');
	}

	scrollTo(index: number) {
		const elementToScrollTo = document.getElementById('sample-row-' + index.toString());
		console.log('scrolling to', elementToScrollTo);
		if (elementToScrollTo) {
			elementToScrollTo.scrollIntoView({ block: 'start', behavior: 'smooth' });
		}
	}

	processAirMethod() {
		console.log('processing air method');
		const methods = this.collect.methods;
		const airContainNorm = 'PN-EN 12350-7';
		const found = methods.findIndex(el => el.norm.startsWith(airContainNorm));
		if (!this.airContents) {
			if (found !== -1) {
				return;
			}
			const result = methodsModule.allMethods.filter(method => method.norm.startsWith(airContainNorm));
			if (result.length !== 0) {
				methods.push(result[0]);
			}
		} else {
			if (found === -1) {
				return;
			}
			methods.splice(found, 1);
		}
	}

	/********************  COMMON ********************/
	setBreadcrumb() {
		const mIndex = this.visit.collect_and_exam_protocol.mixers.findIndex(mixer => mixer.id === this.mixer.id);
		const cIndex = this.mixer.collects.findIndex(collect => collect.id === this.collect.id);

		const breadcrumbItems: BreadcrumbItem[] = [
			{
				title: 'Wizyta ' + this.visit.number,
				link: {
					name: 'visitmain',
					params: {
						visit_id: this.visit.id.toString(),
						pending: true.toString(),
					},
				},
			},
			{
				title: this.protocol.getName() + ' ' + this.protocol.number.toString(),
				link: {
					name: consts.ProtocolTypeString.PIB,
					params: {
						visit_id: this.visit.id.toString(),
						protocol_id: this.visit.collect_and_exam_protocol.id.toString(),
					},
				},
			},
			{
				title: 'Gruszka ' + (mIndex + 1) + ': ' + this.mixer?.vehicle_no,
				link: {
					name: 'mixer',
					params: {
						visit_id: this.visit.id.toString(),
						protocol_id: this.visit.collect_and_exam_protocol.id.toString(),
						mixer_id: this.mixer.id.toString(),
					},
				},
			},
			{
				title: 'Pobranie ' + (cIndex + 1),
				link: '',
			},
		];
		breadcrumbModule.setBreadcrumb({
			routePrefix: '/lab',
			items: breadcrumbItems,
		});
	}

	created() {
		console.log('%c*********************** Collect created start ***********************', 'color:red');
		this.loadCollect();
		window.addEventListener(
			'click',
			function (e: any) {
				this.checkPanelVisible(e);
			}.bind(this)
		);
		EventBus.$on(BusEvents.CHANGE_TREE_VISIBLE, this.changeVisible);
		console.log(
			'%c###################  VISIT COLLECT created END id ' + this.collect.id + ' loaded.. ' + '###################',
			'color:red',
			this.collect,
			this.mixer
		);
	}

	changeMethodsVisibility() {
		this.methodsVisible = !this.methodsVisible;
	}

	mounted() {
		console.log('%c*********************** Collect mounting ***********************', 'color:orange');

		this.selDestinyGroupsAdd = this.destinyGroupsAdd[0];
		this.revalidateAdd++;
		console.log('this.destinyGroupAddSelected', this.destinyGroupAddSelected);
		console.log('%c*********************** Collect mounting END ***********************', 'color:orange');
	}

	loadCollect() {
		const mixer_id = parseInt(this.$route.params.mixer_id);
		const collect_id = parseInt(this.$route.params.collect_id);

		this.mixer = this.visit.collect_and_exam_protocol?.mixers.find(m => m.id === mixer_id);
		this.collect = this.mixer.collects.find(c => c.id === collect_id);

		console.log('Collect loaded:', this.collect, this.collect.id);

		this.collectTime = this.collect.collect_time;
		this.examTime = this.collect.exam_time;
		console.log('Collect TIME', this.collectTime);
		this.timeInvalidate++;
		if (this.collect.collect_place === TakeExamPlaces.BATCHING_PLANT[0]) {
			this.collectPlace = {
				name: TakeExamPlaces.BATCHING_PLANT[0],
				text: TakeExamPlaces.BATCHING_PLANT,
			};
		} else if (this.collect.collect_place === TakeExamPlaces.CONSTRUCTION_SITE[0]) {
			this.collectPlace = {
				name: TakeExamPlaces.CONSTRUCTION_SITE[0],
				text: TakeExamPlaces.CONSTRUCTION_SITE,
			};
		}

		console.log(this.collect, 'chuj');

		this.airTemp = this.collect.air_temp;
		this.mixtureTemp = this.collect.mixture_temp;
		this.airContents = this.collect.air_contents;
		this.consistencyExam = this.collect.consistency_exam;
		this.airContentsResult = this.collect.air_contents_result;
		this.isTreeVisible = false;
		this.setBreadcrumb();
	}

	updateCollect() {
		console.log('updating collect visit...', this.mixer.id, this.collect.id, this.collect);
		visitModule.setMixer(this.mixer);
	}

	@Watch('$route.params.collect_id')
	onCollectChange(newId: string, oldId: string) {
		console.log('ID Collect change', oldId, '=>', newId);
		this.loadCollect();
	}

	roundMixtureTemperature() {
		const mixtureTemp = parseFloat(this.mixtureTemp);
		if (isNaN(mixtureTemp) === false) this.mixtureTemp = helpers.roundNumber(mixtureTemp, 2).toString();
	}

	roundAirTemperature() {
		const airTemp = parseFloat(this.airTemp);
		if (isNaN(airTemp) === false) this.airTemp = helpers.roundNumber(airTemp, 2).toString();
	}

	roundAirContentsResult() {
		this.airContentsResult = helpers.roundNumber(parseFloat(this.airContentsResult), 1).toString();
	}
}
