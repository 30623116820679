






















































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as consts from '../../consts';
import methodsModule from '../../store/modules/methods';
import dictModule from '../../store/modules/dict';
import usersModule from '../../store/modules/users';
import * as helpers from '../../helpers';
import moment from 'moment';

const UNSELECTED = -1;
const NOT_CHOOSEN = 100;
type multiselectOption = { id: number; name: string };

@Component
export default class ModalCalendarExamsDayFilter extends Vue {
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: 'xl' }) size;
  protocolNumber = '';
  show = false;
  save = false;
  params = {};
  enabled = true;
  btnSave = 0;
  filters: { field: string; value: any }[] = [];
  filtersReceived = [];
  selectedClient = {};
  destiny: multiselectOption = { name: '-- brak wyboru --', id: UNSELECTED };
  destinyGroup: multiselectOption = { name: '-- brak wyboru --', id: UNSELECTED };
  laborant: multiselectOption = { name: '-- brak wyboru --', id: UNSELECTED };
  stage: multiselectOption = { name: '-- brak wyboru --', id: UNSELECTED };
  sampleNumber = '';
  examNumber = '';
  takeDateFrom = '';
  takeDateFromText = '';
  takeDateTo = '';
  takeDateToText = '';

  showModal(filters: { field: string; value: any }[]) {
    this.filtersReceived = filters;
    this.filters = this.filtersReceived;
    this.show = true;
    methodsModule.fetchMethods();
  }

  addFilter(field: string, value: any) {
    if (value === NOT_CHOOSEN) {
      this.filters = this.filters.filter(el => el.field !== field);
      return;
    }
    for (const filter of this.filters) {
      if (filter.field === field) {
        filter.value = value;
        return;
      }
    }
    this.filters.push({ field: field, value: value });
  }

  saveModal() {
    this.save = true;
    if (this.selectedClient && 'id' in this.selectedClient) {
      this.addFilter('client_id', this.selectedClient['id']);
    }
    if (this.protocolNumber !== '') {
      this.addFilter('prot_number', this.protocolNumber);
    } else {
      this.filters = this.filters.filter(f => f.field !== 'prot_number');
    }
    if (this.destiny.id !== UNSELECTED) {
      this.addFilter('destiny_id', this.destiny.id);
    } else {
      this.filters = this.filters.filter(f => f.field !== 'destiny_id');
    }
    if (this.destinyGroup.id !== UNSELECTED) {
      this.addFilter('destiny_group_id', this.destinyGroup.id);
    } else {
      this.filters = this.filters.filter(f => f.field !== 'destiny_group_id');
    }
    if (this.laborant.id !== UNSELECTED) {
      this.addFilter('laborant_id', this.laborant.id);
    } else {
      this.filters = this.filters.filter(f => f.field !== 'laborant_id');
    }
    if (this.stage.id !== UNSELECTED) {
      this.addFilter('stage_id', this.stage.id);
    } else {
      this.filters = this.filters.filter(f => f.field !== 'stage_id');
    }
    if (this.takeDateFrom !== '') {
      this.addFilter('take_date_from', this.takeDateFrom);
    }
    if (this.takeDateTo !== '') {
      this.addFilter('take_date_to', this.takeDateTo);
    }
    if (this.sampleNumber !== '') {
      this.addFilter('sample_number', this.sampleNumber);
    } else {
      this.filters = this.filters.filter(f => f.field !== 'sample_number');
    }
    if (this.examNumber !== '') {
      this.addFilter('exam_number', this.examNumber);
    } else {
      this.filters = this.filters.filter(f => f.field !== 'exam_number');
    }

    console.log('----FILTERS----', this.filters);
    this.$emit(consts.ModalEvents.OK_EVENT, this.filters);
    this.show = false;
  }

  clear() {
    this.selectedClient = {};
    this.protocolNumber = '';
    this.sampleNumber = '';
    this.examNumber = '';
    this.destiny = { name: '-- brak wyboru --', id: UNSELECTED };
    this.destinyGroup = { name: '-- brak wyboru --', id: UNSELECTED };
    this.laborant = { name: '-- brak wyboru --', id: UNSELECTED };
    this.stage = { name: '-- brak wyboru --', id: UNSELECTED };
    this.takeDateFrom = '';
    this.takeDateFromText = '';
    this.takeDateTo = '';
    this.takeDateToText = '';
    this.filters = [];
    console.log('Clear');
  }

  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }

  get clients() {
    const clients = dictModule.clients;
    clients.sort((a, b) => {
      return parseInt(a.code) - parseInt(b.code);
    });
    clients.forEach(c => {
      c['code_name'] = c.code + ' - ' + c.name;
    });
    return clients;
  }
  get destinyOptions(): multiselectOption[] {
    const destinyOptions: multiselectOption[] = [{ id: UNSELECTED, name: '-- brak wyboru --' }];
    dictModule.destinies.map(destiny => destinyOptions.push({ id: destiny.id, name: destiny.name }));
    return destinyOptions;
  }
  get destinyGroupOptions(): multiselectOption[] {
    const destinyGroupOptions: multiselectOption[] = [{ id: UNSELECTED, name: '-- brak wyboru --' }];
    dictModule.destinyGroups.map(dg => destinyGroupOptions.push({ id: dg.id, name: dg.name }));
    return destinyGroupOptions;
  }
  get laborantOptions(): multiselectOption[] {
    const laborantOptions: multiselectOption[] = [{ id: UNSELECTED, name: '-- brak wyboru --' }];
    const usersWithExamStartRight = usersModule.getUsersByRightSymbolInAllRights(consts.UserRightSymbols.EXAM_START);
    const users = usersModule.getWorkingUsers(usersWithExamStartRight);
    users.map(u => laborantOptions.push({ id: u.id, name: u.firstname + ' ' + u.lastname }));
    return laborantOptions;
  }
  get stageOptions(): multiselectOption[] {
    const stageOptions: multiselectOption[] = [{ id: UNSELECTED, name: '-- brak wyboru --' }];
    stageOptions.push(
      {
        id: 1,
        name: '1',
      },
      {
        id: 2,
        name: '2',
      }
    );
    return stageOptions;
  }

  selectClient(e: KeyboardEvent) {
    this.selectedClient = e;
  }
  removeClient() {
    this.selectedClient = {};
    this.filters = this.filters.filter(f => f.field !== 'client_id');
  }
  destinySelected(e: any) {
    console.log('destinySelected', e);
    this.destiny = e;
  }
  destinyGroupSelected(e: any) {
    console.log('destinyGroupSelected', e);
    this.destinyGroup = e;
  }
  laborantSelected(e: any) {
    console.log('laborantSelected', e);
    this.laborant = e;
  }
  stageSelected(e: any) {
    console.log('stageSelected', e);
    this.stage = e;
  }

  takeDateFromTextLeave(event: any) {
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.takeDateFrom = today.format(consts.DB_DATE_TIME_FORMAT);
      this.takeDateFromText = today.format(consts.DATE_FORMAT);
      helpers.warn('Błędny format daty, przywrócona domyślna wartość.');
    }
  }
  fillTakeDateFrom() {
    if (this.takeDateFrom !== '') {
      this.takeDateFromText = moment(this.takeDateFrom, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    }
  }
  onTakeDateFromContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.takeDateFrom = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.fillTakeDateFrom();
    } else {
      this.takeDateFromText = ctx.selectedFormatted;
    }
  }
  takeDateToTextLeave(event: any) {
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.takeDateTo = today.format(consts.DB_DATE_TIME_FORMAT);
      this.takeDateToText = today.format(consts.DATE_FORMAT);
      helpers.warn('Błędny format daty, przywrócona domyślna wartość.');
    }
  }
  fillTakeDateTo() {
    if (this.takeDateTo !== '') {
      this.takeDateToText = moment(this.takeDateTo, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    }
  }
  onTakeDateToContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.takeDateTo = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.fillTakeDateTo();
    } else {
      this.takeDateToText = ctx.selectedFormatted;
    }
  }
}
