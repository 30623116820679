import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import store from '@/store';
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Method, MethodStage, MethodStageParam, MethodStageTool, MethodUser } from '@/types';
import { NEW_ID_START } from '@/consts';
import { IMethod, IMethodStage, IMethodStageParam } from '../models';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'methods',
  store,
  dynamic: true,
})
class MethodsModule extends VuexModule {
  methods: Method[] = [];
  methodStages: MethodStage[] = [];
  methodStageParams: MethodStageParam[] = [];
  methodStageTools: MethodStageTool[] = [];
  methodUsers: MethodUser[] = [];

  get allMethods() {
    return this.methods;
  }
  get allMethodStages() {
    return this.methodStages;
  }
  get allMethodStageParams() {
    return this.methodStageParams;
  }
  get allMethodStageTools() {
    return this.methodStageTools;
  }
  get methodStagesByMethodId() {
    return (method_id: number): MethodStage[] => {
      return this.methodStages.filter(ms => ms.method_id === method_id);
    };
  }

  public get methodById() {
    return (id: number): Method => {
      return this.methods.find(m => m.id === id);
    };
  }
  public get methodStageById() {
    return (id: number): MethodStage => {
      return this.methodStages.find(m => m.id === id);
    };
  }
  public get methodStageByMethodIdAndIndex() {
    return (method_id: number, index: number): MethodStage => {
      return this.methodStages.find(m => m.method_id === method_id && m.index === index);
    };
  }
  public get methodStageParamById() {
    return (id: number): MethodStageParam => {
      return this.methodStageParams.find(m => m.id === id);
    };
  }
  public get methodCopyById() {
    return (id: number): Method => {
      return Object.assign(new Method(), JSON.parse(JSON.stringify(this.methodById(id))));
    };
  }
  public get methodStageCopyById() {
    return (id: number): MethodStage => {
      return Object.assign(new MethodStage(), JSON.parse(JSON.stringify(this.methodStageById(id))));
    };
  }
  public get methodStageParamCopyById() {
    return (id: number): MethodStageParam => {
      return Object.assign(new MethodStageParam(), JSON.parse(JSON.stringify(this.methodStageParamById(id))));
    };
  }
  public get methodStageToolById() {
    return (id: number): MethodStageTool => {
      return this.methodStageTools.find(m => m.id === id);
    };
  }
  public get methodStageToolsByMethodStageId() {
    return (method_stage_id: number): MethodStageTool[] => {
      return this.methodStageTools.filter(m => m.method_stage_id === method_stage_id);
    };
  }
  public get methodUserById() {
    return (id: number): MethodUser => {
      return this.methodUsers.find(m => m.id === id);
    };
  }

  @Mutation
  clear() {
    this.methods = [];
    this.methodStages = [];
    this.methodStageParams = [];
    this.methodStageTools = [];
    this.methodUsers = [];
    console.log('methods module cleared...');
  }

  @Mutation
  appendMethod(method: Method) {
    this.methods.push(method);
  }

  @Mutation
  replaceMethod(method: Method): any {
    const u = this.methods.find(v => v.id === method.id);
    Object.assign(u, method);
  }

  @Action({ rawError: true })
  async addMethod(method: Method): Promise<AxiosResponse> {
    const response = await API.saveMethod(method);
    if (response.data.success) {
      const newMethod = Object.assign(new Method(), response.data.method);
      this.appendMethod(method);
    } else {
      console.log('addMethod Error', response.data.error_code);
    }
    return response;
  }

  @Action({ rawError: true })
  async updateMethod(method: Method): Promise<AxiosResponse> {
    const response = await API.saveMethod(method);
    if (response.data.success) {
      const newMethod = Object.assign(new Method(), response.data.method);
      this.replaceMethod(method);
    } else {
      console.log('updateMethod Error', response.data.error_code);
    }
    return response;
  }

  @Mutation
  addMethodStage(methodStage: MethodStage): any {
    const maxId = Math.max(...this.methodStages.map(c => c.id));
    if (methodStage.id === 0) {
      if (maxId < NEW_ID_START) {
        methodStage.id = NEW_ID_START;
      } else {
        methodStage.id = maxId + 1;
      }
    }
    this.methodStages.push(methodStage);
  }
  @Mutation
  addMethodStageParam(methodStageParam: MethodStageParam): any {
    const maxId = Math.max(...this.methodStageParams.map(c => c.id));
    if (methodStageParam.id === 0) {
      if (maxId < NEW_ID_START) {
        methodStageParam.id = NEW_ID_START;
      } else {
        methodStageParam.id = maxId + 1;
      }
    }
    this.methodStageParams.push(methodStageParam);
  }

  @Mutation
  updateMethodStage(methodStage: Method): any {
    const m = this.methodStages.find(obj => obj.id === methodStage.id);
    console.log('updateMethodStage mutation', m, methodStage);
    Object.assign(m, methodStage);
  }
  @Mutation
  updateMethodStageParam(methodStageParam: MethodStageParam): any {
    const m = this.methodStageParams.find(obj => obj.id === methodStageParam.id);
    console.log('updateMethodStageParam mutation', m, methodStageParam);
    Object.assign(m, methodStageParam);
  }
  @Mutation
  loadMethodsFromConfig(methods: IMethod[]): any {
    this.methods = methods.map(c => Object.assign(new Method(), c));
    console.log('Store module Methods loaded..', this.methods.length);
  }
  @Mutation
  loadMethodStagesFromConfig(methodStages: IMethodStage[]): any {
    this.methodStages = methodStages.map(c => Object.assign(new MethodStage(), c));
    console.log('Store module Methods Stages loaded..', this.methodStages.length);
  }
  @Mutation
  loadMethodStageParamsFromConfig(methodStageParams: IMethodStageParam[]): any {
    this.methodStageParams = methodStageParams.map(c => Object.assign(new MethodStageParam(), c));
    console.log('Store module Methods Stage Params loaded..', this.methodStageParams.length);
  }

  @Mutation
  setMethodUsers(methodUsers: MethodUser[]) {
    this.methodUsers = [];
    methodUsers.forEach(methodUser => {
      this.methodUsers.push(Object.assign(new MethodUser(), methodUser));
    });
  }
  @Mutation
  setMethods(methods: Method[]) {
    this.methods = [];
    methods.forEach(method => {
      this.methods.push(Object.assign(new Method(), method));
    });
  }

  @Mutation
  loadMethods() {
    this.methods = this.methods.map(method => Object.assign(new Method(), method));
    this.methodUsers = this.methodUsers.map(methodUser => Object.assign(new MethodUser(), methodUser));
    this.methods.forEach(m => {
      m.load();
    });
    this.methodUsers.forEach(mu => {
      mu.load();
    });
  }

  @Action
  @RelatedModel('Method')
  async fetchMethods(): Promise<AxiosResponse> {
    console.log('*** FetchMethods');
    const response = await API.fetchMethods();
    if (response.data.success) {
      this.setMethods(response.data.methods);
      this.setMethodUsers(response.data.method_users);
      return response;
    }
  }
  @Mutation
  setMethodStages(methodStages: MethodStage[]) {
    this.methodStages = [];
    methodStages.forEach(methodStage => {
      this.methodStages.push(Object.assign(new MethodStage(), methodStage));
    });
  }
  @Action
  @RelatedModel('MethodStage')
  async fetchMethodStages(): Promise<AxiosResponse> {
    console.log('*** FetchMethodStages');
    const response = await API.fetchMethodStages();
    if (response.data.success) {
      this.setMethodStages(response.data.method_stages);
      return response;
    }
  }
  @Mutation
  setMethodStageParams(methodStageParams: MethodStageParam[]) {
    this.methodStageParams = [];
    methodStageParams.forEach(methodStageParam => {
      this.methodStageParams.push(Object.assign(new MethodStageParam(), methodStageParam));
    });
  }
  @Action
  @RelatedModel('MethodStageParam')
  async fetchMethodStageParams(): Promise<AxiosResponse> {
    console.log('*** FetchMethodStageParams');
    const response = await API.fetchMethodStageParams();
    if (response.data.success) {
      this.setMethodStageParams(response.data.method_stage_params);
      return response;
    }
  }
  @Mutation
  setMethodStageTools(methodStageTools: MethodStageTool[]) {
    this.methodStageTools = [];
    methodStageTools.forEach(methodStageTool => {
      this.methodStageTools.push(Object.assign(new MethodStageTool(), methodStageTool));
    });
  }
  @Action
  @RelatedModel('MethodStageTool')
  async fetchMethodStageTools(): Promise<AxiosResponse> {
    console.log('*** FetchMethodStageTools');
    const response = await API.fetchMethodStageTools();
    if (response.data.success) {
      this.setMethodStageTools(response.data.method_stage_tools);
      return response;
    }
  }
}

export default getModule(MethodsModule);
