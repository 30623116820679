import axios, { AxiosResponse } from 'axios';
import * as types from '@/types';
import { onlineHelper } from '@/helpers/offline';
import { error } from '@/helpers';

export const ctbApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL + 'api/',
});

// ctbApi.interceptors.request.use(
//   config => {
//     if (!onlineHelper.isOnline) {
//       console.log('caching request....');
//       error('Nie można wykonać zapisu w trybie offline.');
//       return {};
//     }
//     return config;
//   },
//   err => {
//     console.error(err);
//     return err;
//   }
// );

/************************************** AUTH ****************************************/
export function setJWT(jwt: string) {
  console.log('JWT set');
  ctbApi.defaults.headers.common['Authorization'] = `${jwt}`;
}

export function clearJWT() {
  delete ctbApi.defaults.headers.common['Authorization'];
}

export async function fetchVersions(): Promise<AxiosResponse> {
  const response = await ctbApi.get('/all_versions');
  return response;
}

export async function loginUser(user: types.UserSubmit) {
  const response = await ctbApi.post('/login', { user });
  console.log('api response', response);
  return response;
}

export async function reset(email: string): Promise<AxiosResponse> {
  console.log('BaseURL:', ctbApi.defaults.baseURL);
  const response = await ctbApi.post('/reset', { email });
  console.log('Response', response);
  return response;
}
export async function resetDev(email: string): Promise<AxiosResponse> {
  return await ctbApi.post('/reset_dev', { email });
}
/************************************** USER ****************************************/
export async function saveUser(user: types.User): Promise<AxiosResponse> {
  return await ctbApi.post('/save_user', { user });
}
export async function deactivateUser(id: number): Promise<AxiosResponse> {
  return await ctbApi.post('/deactivate_user', { id });
}
export async function activateUser(id: number): Promise<AxiosResponse> {
  return await ctbApi.post('/activate_user', { id });
}
export async function addUserRight(right: types.UserRight): Promise<AxiosResponse> {
  return await ctbApi.post('/add_user_right', { right });
}
export async function fetchUsers(): Promise<AxiosResponse> {
  const response = await ctbApi.get('/get_users');
  return response;
}
export async function getUserData(userId: number): Promise<AxiosResponse> {
  return await ctbApi.post('/get_user_data', { user_id: userId });
}
export async function fetchSettings(params: { user: number; groups: Array<number> }): Promise<AxiosResponse> {
  return await ctbApi.post('/get_settings', params);
}
export async function addUserGroup(group: types.UserGroup): Promise<AxiosResponse> {
  return await ctbApi.post('/add_user_group', { group });
}
export async function getUserGroups(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_user_groups');
}
export async function getUserRights(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_user_rights');
}
/************************************** MESSAGES ****************************************/
export async function fetchMessages(user): Promise<AxiosResponse> {
  return await ctbApi.post('/get_messages', { user });
}

export async function readMessage(message_id): Promise<AxiosResponse> {
  return await ctbApi.post('/read_message', { message_id });
}

export async function addMessage(message: types.Message): Promise<AxiosResponse> {
  return await ctbApi.post('/add_message', { message });
}
/************************************** ORDER ****************************************/
export async function saveOrder(order: object): Promise<AxiosResponse> {
  console.log('API.saveOrder...');
  return await ctbApi.post('/save_order', {
    order,
  });
}
export async function deleteOrder(order_id: number): Promise<AxiosResponse> {
  console.log('API.deleteOrder...');
  return await ctbApi.post('/delete_order', {
    order_id,
  });
}
export async function fetchOrders(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_orders');
}
/************************************** CLIENT ****************************************/
export async function saveClient(client: types.Client): Promise<AxiosResponse> {
  console.log('API.saveClient', client);
  return await ctbApi.post('/save_client', {
    client,
  });
}
export async function deleteClient(client: types.Client): Promise<AxiosResponse> {
  console.log('API.deleteClient', client);
  return await ctbApi.post('/delete_client', {
    client,
  });
}
export async function deleteClientByCode(code: string): Promise<AxiosResponse> {
  console.log('API.deleteClientByCode', code);
  return await ctbApi.post('/delete_client_by_code', {
    code,
  });
}
export async function incrementClientRDCounter(clientId: number): Promise<AxiosResponse> {
  return await ctbApi.post('/increment_client_rd_counter', { client_id: clientId });
}

export async function fetchAddresses(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_addresses');
}

export async function saveAddress(address: types.Address): Promise<AxiosResponse> {
  return await ctbApi.post('/save_address', {
    address,
  });
}
export async function deleteAddress(address: types.Address): Promise<AxiosResponse> {
  console.log('API.deleteAddress', address);
  return await ctbApi.post('/delete_address', {
    address,
  });
}

export async function fetchClients(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_clients');
}

/************************************ CONSTRUCTION_PLACE **************************************/
export async function saveConstructionPlace(cplace: types.ConstructionPlace): Promise<AxiosResponse> {
  return await ctbApi.post('/save_construction_place', {
    cplace,
  });
}
export async function fetchConstructionPlaces(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_construction_places');
}

/************************************** LOCALIZATIONS ****************************************/
export async function fetchLocalizations(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_localizations');
}

/************************************** CONTRACTS ****************************************/
export async function saveContract(contract: types.Contract): Promise<AxiosResponse> {
  console.log('API.saveContract', contract);
  return await ctbApi.post('/save_contract', {
    contract,
  });
}
export async function fetchContracts(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_contracts');
}

/************************************** VISITS ****************************************/
export async function saveVisit(visit: types.Visit): Promise<AxiosResponse> {
  //  console.log('API.saveVisit', visit);
  return await ctbApi.post('/save_visit', {
    visit,
  });
}
export async function saveVisitIsProtDownloaded(visit: types.Visit): Promise<AxiosResponse> {
  //  console.log('API.saveVisitIsProtDownloaded', visit);
  return await ctbApi.post('/save_visit_is_prot_downloaded', {
    visit,
  });
}
export async function saveVisitByJSON(visit: types.Visit): Promise<AxiosResponse> {
  console.log('API.saveVisitByJSON', visit);
  return await ctbApi.post('/save_visit_by_json', {
    visit,
  });
}
export async function closeVisit(visit: types.Visit): Promise<AxiosResponse> {
  console.log('API.closeVisit', visit);
  return await ctbApi.post('/close_visit', {
    visit,
  });
}
export async function startVisit(visit: types.Visit): Promise<AxiosResponse> {
  console.log('API.startVisit', visit);
  return await ctbApi.post('/start_visit', {
    visit,
  });
}
export async function changeStatusVisit(visit: types.Visit): Promise<AxiosResponse> {
  console.log('API.changeStatusVisit', visit);
  return await ctbApi.post('/change_status_visit', {
    visit,
  });
}
export async function fetchVisits(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_visits');
}
export async function deleteVisit(visit_id: number): Promise<AxiosResponse> {
  console.log('API.deleteVisit...');
  return await ctbApi.post('/delete_visit', {
    visit_id,
  });
}
/************************************** PROTOCOLS ****************************************/
export async function saveCollectAndExamProtocol(protocol: types.CollectAndExamProtocol): Promise<AxiosResponse> {
  console.log('API.saveCollectAndExamProtocol', protocol);
  return await ctbApi.post('/save_collect_and_exam_protocol', {
    protocol,
  });
}
export async function fetchPIBProtocols(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_pib_protocols');
}
export async function saveCTBProtocol(protocol: types.CollectCTBProtocol): Promise<AxiosResponse> {
  console.log('API.saveCTBProtocol');
  return await ctbApi.post('/save_ctb_protocol', {
    protocol,
  });
}
export async function fetchCTBProtocols(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_ctb_protocols');
}
/************************************** MIXERS ****************************************/
export async function saveMixer(mixer: types.Mixer): Promise<AxiosResponse> {
  console.log('API.saveMixer', mixer);
  return await ctbApi.post('/save_mixer', {
    mixer,
  });
}
export async function fetchMixers(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_mixers');
}
/************************************** COLLECTS ****************************************/
export async function saveCollect(collect: types.Collect): Promise<AxiosResponse> {
  console.log('API.saveCollect', collect);
  return await ctbApi.post('/save_collect', {
    collect,
  });
}
export async function fetchCollects(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_collects');
}
/************************************** SAMPLES ****************************************/
export async function saveSample(sample: types.Sample): Promise<AxiosResponse> {
  console.log('API.saveSample', sample);
  return await ctbApi.post('/save_sample', {
    sample,
  });
}
export async function fetchSamples(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_samples');
}
export async function updateSampleUW(sample_data): Promise<AxiosResponse> {
  return await ctbApi.post('/update_sample_uw', {
    sample_id: sample_data.sample_id,
    sample_uw: sample_data.sample_uw,
  });
}

/************************************** COLLECT_EXAM ****************************************/
export async function saveCollectExam(collect_exam: types.CollectExam): Promise<AxiosResponse> {
  console.log('API.saveCollectExam', collect_exam);
  return await ctbApi.post('/save_collect_exam', {
    collect_exam,
  });
}
export async function fetchCollectExams(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_collect_exams');
}
/************************************** SAMPLES ****************************************/
export async function saveExam(exam: types.Exam): Promise<AxiosResponse> {
  console.log('API.saveExam', exam);
  return await ctbApi.post('/save_exam', {
    exam,
  });
}
export async function saveExamIsRdDownloaded(exam: types.Exam): Promise<AxiosResponse> {
  console.log('API.saveExamIsRdDownloaded', exam);
  return await ctbApi.post('/save_exam_is_rd_downloaded', {
    exam,
  });
}
export async function fetchExams(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_exams');
}
export async function fetchExamsStages(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_exams_stages');
}
export async function fetchExamStages(exam: types.Exam): Promise<AxiosResponse> {
  return await ctbApi.get('/get_exam_stages', {
    params: {
      exam_id: exam.id,
    },
  });
}

export async function authorise_exam(exam: types.Exam, person_authorised: types.User): Promise<AxiosResponse> {
  return await ctbApi.post('/authorise_exam', {
    exam,
    person_authorised,
  });
}

export async function fetchExamStageParams(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_exam_stage_params');
}

export async function fetchMethodGroups(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_method_groups');
}

export async function fetchMethods(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_methods');
}
export async function fetchMethodStages(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_method_stages');
}
export async function fetchMethodStageParams(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_method_stage_params');
}
export async function fetchMethodStageTools(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_method_stage_tools');
}

export async function addCollectExam(collect_exam: types.CollectExam): Promise<AxiosResponse> {
  return await ctbApi.post('/save_collect_exam', { collect_exam });
}

export async function fetchConcreteClasses(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_concrete_classes');
}
export async function fetchConcreteClassGroups(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_concrete_class_groups');
}
export async function fetchConcreteClassSubgroups(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_concrete_class_subgroups');
}

export async function fetchExamSampleStageParams(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_exam_sample_stage_params');
}
export async function fetchMaterials(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_materials');
}
export async function fetchToolGroups(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_tool_groups');
}
/************************************ DASHBOARDS ********************************/
export async function saveDashboardItem(item: types.DashboardItem): Promise<AxiosResponse> {
  return await ctbApi.post('/save_dashboard_item', { item });
}

export async function saveDashboardAction(action: types.DashboardAction): Promise<AxiosResponse> {
  return await ctbApi.post('/save_dashboard_action', { action });
}

export async function saveDashboardItemWithAction(payload: {
  item: types.DashboardItem;
  action: types.DashboardAction;
}): Promise<AxiosResponse> {
  return await ctbApi.post('/save_dashboard_item_with_action', { payload });
}

export async function fetchDashboardItems(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_dashboard_items');
}
export async function fetchDashboardActions(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_dashboard_actions');
}

export async function saveMethod(method: types.Method): Promise<AxiosResponse> {
  return await ctbApi.post('/save_method', { method });
}
export async function saveTool(tool: types.Tool): Promise<AxiosResponse> {
  return await ctbApi.post('/save_tool', { tool });
}
export async function fetchTools(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_tools');
}
export async function saveCar(car: types.Car): Promise<AxiosResponse> {
  return await ctbApi.post('/save_car', { car });
}

/************************************** ADMIN ****************************************/

export async function createExams(visit_id: number): Promise<AxiosResponse> {
  console.log('API.createExams', visit_id);
  return await ctbApi.post('/create_exams', {
    visit_id,
  });
}
export async function insertClasses(classes: types.ConcreteClass[]): Promise<AxiosResponse> {
  console.log('API.insertClasses', classes);
  return await ctbApi.post('/insert_classes', {
    classes,
  });
}

export async function clearDB(): Promise<AxiosResponse> {
  return await ctbApi.post('/clear_db');
}

export async function log(message: string): Promise<AxiosResponse> {
  return await ctbApi.post('/log', { message });
}
/************************************** OFFER_ACTION ****************************************/
export async function fetchOfferActionsClient(clientId: number): Promise<AxiosResponse> {
  return await ctbApi.post('/client_offerActions', { clientId });
}
export async function fetchOfferActions(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_offerActions');
}
export async function fetchOffers(offerActionId: number): Promise<AxiosResponse> {
  return await ctbApi.post('/get_offers', { offerActionId });
}
export async function saveOfferAction(offerAction: types.OfferAction): Promise<AxiosResponse> {
  return await ctbApi.post('/save_offerAction', { offerAction });
}
export async function saveOffer(offer: types.Offer): Promise<AxiosResponse> {
  return await ctbApi.post('/save_offer', { offer });
}
export async function obtainOfferActionNumber(): Promise<AxiosResponse> {
  return await ctbApi.get('/obtain_offerAction_number');
}
export async function obtainContractNumber(): Promise<AxiosResponse> {
  return await ctbApi.get('/obtain_contract_number');
}

/************************************** SENDING_FILE ****************************************/
export async function saveFile(fileData: FormData): Promise<AxiosResponse> {
  return await ctbApi.post('/save_file', fileData);
}
export async function fetchFiles(id: number, type: string): Promise<AxiosResponse> {
  return await ctbApi.post('/get_files', { id, type });
}
export async function removeFile(id: number): Promise<AxiosResponse> {
  return await ctbApi.post('/remove_file', { id });
}
/************************************** SIGNATURE ****************************************/
export async function saveSignature(signature: types.Signature): Promise<AxiosResponse> {
  return await ctbApi.post('/save_signature', { signature });
}
export async function fetchSignatures(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_signatures');
}

export async function sendSignatureCode(signature: types.Signature): Promise<AxiosResponse> {
  return await ctbApi.post('/confirm_signature', { signature });
}

/************************************** CERT ****************************************/

export async function saveCert(certDocument: types.CertificationDocument): Promise<AxiosResponse> {
  return await ctbApi.post('/save_cert_doc', { certDocument });
}

export async function obtainCertDocNumber(): Promise<AxiosResponse> {
  return await ctbApi.get('/cert_doc_number');
}

export async function fetchCertDocs(): Promise<AxiosResponse> {
  return await ctbApi.get('/cert_docs');
}

/************************************** FILES ****************************************/

export async function getFile(data: types.File): Promise<AxiosResponse> {
  return await ctbApi.post(
    '/get_file',
    { data },
    {
      responseType: 'blob',
    }
  );
}

/************************************** DESTINY/GROUP ****************************************/

export async function fetchDestinies(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_destinies');
}
export async function addDestiny(destiny: types.Destiny): Promise<AxiosResponse> {
  return await ctbApi.post('/add_destiny', destiny);
}
export async function editDestiny(destiny: types.Destiny, oldName: string): Promise<AxiosResponse> {
  return await ctbApi.post('/edit_destiny', { destiny: destiny, old_name: oldName });
}
export async function removeDestiny(oldName: string): Promise<AxiosResponse> {
  return await ctbApi.post('/remove_destiny', { name: oldName });
}
export async function fetchDestinyGroups(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_destiny_groups');
}
export async function addDestinyGroup(destinyGroup: types.DestinyGroup): Promise<AxiosResponse> {
  return await ctbApi.post('/add_destiny_group', destinyGroup);
}
export async function editDestinyGroup(destinyGroup: types.DestinyGroup, oldName: string): Promise<AxiosResponse> {
  return await ctbApi.post('/edit_destiny_group', { destiny_group: destinyGroup, old_name: oldName });
}
export async function removeDestinyGroup(id: number): Promise<AxiosResponse> {
  return await ctbApi.post('/remove_destiny_group', { id });
}

/************************************** RESULTS_DOC ****************************************/

export async function fetchResultsDocs(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_results_docs');
}
export async function saveResultsDocPdf(pdfData: FormData): Promise<AxiosResponse> {
  return await ctbApi.post('/save_results_doc_pdf', pdfData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
export async function saveResultsDoc(resultsDoc: types.ResultsDoc): Promise<AxiosResponse> {
  return await ctbApi.post('/save_results_doc', resultsDoc);
}

export async function fetchDepartments(): Promise<AxiosResponse> {
  return await ctbApi.get('/get_departments');
}
/*
export async function fetchProfile(username: string): Promise<Profile> {
  const response = await conduitApi.get(`/profiles/${username}`);
  return (response.data as ProfileResponse).profile;
}*/

/* EXAM */
export async function mergeExams(exams_merge: types.Exam[], exam: types.Exam): Promise<AxiosResponse> {
  return await ctbApi.post('/merge_exams', { exams_merge, exam });
}

export async function getModuleVersion(module: string): Promise<AxiosResponse> {
  return await ctbApi.get(`/get_version?module=${module}`);
}
