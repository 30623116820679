


















































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, DashboardAction, User, Message } from '../../types';
import usersModule from '../../store/modules/users';
import userModule from '../../store/modules/user';
import dashboardItemsModule from '../../store/modules/dashboard_items';
import dataModule from '../../store/modules/data';
import moment from 'moment';

@Component
export default class ModalAction extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  item: DashboardItem = null;
  lastMove = {};
  revalidate = 0;
  workerSelected: User = null;
  peopleSelected: User[] = [];

  certType = consts.DashboardObjectTypes.CERT;
  auditType = consts.DashboardObjectTypes.AUDIT;
  docType = consts.DashboardObjectTypes.DOCUMENT;
  actionType = consts.DashboardObjectTypes.OFFER;

  comment = '';
  btnSave = 0;

  get shouldBadgeVisible() {
    this.item.load();
    console.log(this.item);
    const item: any = this.item;
    return ![this.certType, this.auditType, this.docType, this.actionType].includes(item.object.type);
  }

  @Emit()
  modalClose() {
    return 1;
  }

  get workerSel() {
    return this.workerSelected;
  }
  workerItemSelected(event) {
    this.workerSelected = event;
  }

  get getOperType() {
    return this.item.operation_type;
  }

  get getOldStatus() {
    return this.lastMove['oldStatus'] || '';
  }
  get getNewStatus() {
    return this.lastMove['newStatus'];
  }

  showModal(params: object) {
    console.log('ModalAction::showModal', params);
    this.comment = '';
    this.show = true;
    this.params = params;
    this.item = params['item'];
    this.lastMove = params['lastMove'];
    this.params['people'] = [];
  }
  async saveModal() {
    this.save = true;
    this.params['save'] = true;
    console.log('ModalAction::saveModal', this.params);

    const newItem = this.params['item'] as DashboardItem;
    console.log('newitem', newItem);
    this.params['comment'] = this.comment;

    const newAction = new DashboardAction();
    newAction.index = 0;
    newAction.from_status_id = this.params['lastMove']['oldStatusId'];
    newAction.to_status_id = this.params['lastMove']['newStatusId'];
    newAction.dashboard_item_id = newItem.id;
    newAction.comment = this.params['comment'];
    newAction.action_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
    newAction.action_person = userModule.user;
    newAction.action_person_id = userModule.user.id;
    if (this.workerSelected !== null) {
      newAction.new_status_person = this.workerSelected;
      newAction.new_status_person_id = this.workerSelected.id;
    } else {
      newAction.new_status_person_id = userModule.user.id;
      newAction.new_status_person = userModule.user;
    }

    newItem.status_id = newAction.to_status_id;

    let response = null;
    if (newAction.from_status_id === -1) {
      response = await dashboardItemsModule.addItemWithAction({ item: newItem, action: newAction });
    } else {
      response = await dashboardItemsModule.updateItemWithAction({ item: newItem, action: newAction });
    }
    if (!response.data.success) {
      console.log('Error while saving action');
    } else {
      this.params['item'] = dashboardItemsModule.dashboardItemById(response.data.item.id);
      this.params['action'] = dataModule.actionById(response.data.action.id);
    }
    if (
      this.getNewStatus.id === consts.ToolActionStatus.DONE.id &&
      this.item.operation_type_id === consts.PersonelOperationType.ADD.id
    ) {
      // Change API call here to some function that generates password and sends it or if it's possible send email from frontend
      userModule.reset((newItem.object as any).email);
    }
    this.$emit(consts.ModalEvents.OK_EVENT, this.params);
    this.show = false;
    this.sendMessage();
  }

  sendMessage() {
    const message = new Message();
    let tableType = '';
    let object_id = null;
    if (this.item.object_type_id === consts.DashboardObjectTypes.METHOD) {
      tableType = 'Metody Badawcze';
      object_id = consts.DashboardObjectTypes.METHOD;
    } else if (this.item.object_type_id === consts.DashboardObjectTypes.TOOL) {
      tableType = 'Urzadzenia';
      object_id = consts.DashboardObjectTypes.TOOL;
    } else if (this.item.object_type_id === consts.DashboardObjectTypes.PERSONEL) {
      tableType = 'Personel';
      object_id = consts.DashboardObjectTypes.PERSONEL;
    } else if (this.item.object_type_id === consts.DashboardObjectTypes.CERT) {
      tableType = 'Certyfikacja';
      object_id = consts.DashboardObjectTypes.CERT;
    }
    message.receiver_id = this.workerSelected.id;
    message.title = this.item.objectTypeName + ':' + this.item.operation_type['name'];
    message.content = 'Komentarz: ' + this.comment;
    message.type = consts.MessageSenderType.SYSTEM;
    message.group_id = consts.MessageGroup.ACTION.id;
    message.message_type = consts.MessageType.INFO;
    message.sender_id = null;
    message.object_id = object_id;
    message.object_type_id = consts.MessageObjectType.ACTION;
    dataModule.sendMessage(message);
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }

  addPerson() {
    if (!this.params['people'].find(p => p.id === this.workerSelected.id)) {
      this.peopleSelected.push(usersModule.userById(this.workerSelected.id));
      this.params['people'] = this.peopleSelected;
    }
  }
  removePerson(person: User) {
    this.peopleSelected = this.peopleSelected.filter(p => p.id !== person.id);
    this.params['people'] = this.peopleSelected;
  }
  get workers() {
    this.revalidate;

    let right = consts.UserRightSymbols.TOOL_DASHBOARD_PROCESS;
    if (this.item) {
      console.log('Workers', this.item, this.item.object_type_id);

      switch (this.item.object_type_id) {
        case consts.DashboardObjectTypes.METHOD: {
          right = consts.UserRightSymbols.METHOD_DASHBOARD_PROCESS;
          break;
        }
        case consts.DashboardObjectTypes.TOOL: {
          right = consts.UserRightSymbols.TOOL_DASHBOARD_PROCESS;
          break;
        }
        case consts.DashboardObjectTypes.PERSONEL: {
          right = consts.UserRightSymbols.PERSONEL_DASHBOARD_PROCESS;
          break;
        }
        case consts.DashboardObjectTypes.OFFER: {
          right = consts.UserRightSymbols.OFFER_DASHBOARD_PROCESS;
          break;
        }
        case consts.DashboardObjectTypes.CERT: {
          right = consts.UserRightSymbols.CERT_DASHBOARD_PROCESS;
          break;
        }
        default: {
          console.log('Loading DEFAULT right TOOL_DASHBOARD_PROCESS');
          break;
        }
      }
    }

    console.log('usersModule:users', usersModule.allUsers, right);
    const optionsUsers = usersModule.getUsersByRightSymbolInAllRights(right).map(u => {
      const retUser = { name: u.firstname + ' ' + u.lastname, id: u.id };
      return retUser;
    });
    console.log('Workers:', optionsUsers);
    return optionsUsers;
  }
  descrChange(event) {
    this.comment = event;
  }
}
