import { render, staticRenderFns } from "./ModalDocuments.vue?vue&type=template&id=122a3fde&scoped=true&"
import script from "./ModalDocuments.vue?vue&type=script&lang=ts&"
export * from "./ModalDocuments.vue?vue&type=script&lang=ts&"
import style0 from "./ModalDocuments.vue?vue&type=style&index=0&id=122a3fde&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "122a3fde",
  null
  
)

export default component.exports