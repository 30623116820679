import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import store from '@/store';
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators';
import { Tool, ToolParam, Car, ExamStage } from '@/types';
import { NEW_ID_START, MethodNorm } from '@/consts';
import { ITool } from '../models';

import methodsModule from '@/store/modules/methods';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'tools',
  store,
  dynamic: true,
})
class ToolsModule extends VuexModule {
  public tools: Tool[] = [];
  public toolParams: ToolParam[] = [];
  public cars: Car[] = [];

  get allTools() {
    return this.tools;
  }

  public get toolById() {
    return (id: number): Tool => {
      return this.tools.find(m => m.id === id);
    };
  }
  public get toolCopyById() {
    return (id: number): Tool => {
      return Object.assign(new Tool(), JSON.parse(JSON.stringify(this.toolById(id))));
    };
  }
  public get carById() {
    return (id: number): Car => {
      return this.cars.find(m => m.id === id);
    };
  }
  public get carByNumber() {
    return (number: string): Car => {
      return this.cars.find(m => m.number === number);
    };
  }

  public get toolParamsByToolId() {
    return (toolId: number): ToolParam[] => {
      return this.toolParams.filter(tp => tp.tool_id === toolId);
    };
  }

  @Mutation
  clear() {
    this.tools = [];
    this.toolParams = [];
    this.cars = [];
    console.log('Tools module cleared...');
  }

  @Mutation
  load() {
    this.tools.forEach(t => {
      t.load();
    });
  }

  @Mutation
  appendTool(tool: Tool) {
    this.tools.push(tool);
  }

  @Mutation
  appendCar(car: Car) {
    this.cars.push(car);
  }

  @Mutation
  replaceTool(tool: Tool): any {
    const u = this.tools.find(v => v.id === tool.id);
    Object.assign(u, tool);
  }

  @Mutation
  replaceCar(car: Car): any {
    const u = this.cars.find(v => v.id === car.id);
    Object.assign(u, car);
  }

  @Action({ rawError: true })
  async addTool(tool: Tool): Promise<AxiosResponse> {
    const response = await API.saveTool(tool);
    if (response.data.success) {
      const newTool = Object.assign(new Tool(), response.data.tool);
      this.appendTool(newTool);
    } else {
      console.log('addTool Error', response.data.error_code);
    }
    return response;
  }

  @Action({ rawError: true })
  async updateTool(tool: Tool): Promise<AxiosResponse> {
    const response = await API.saveTool(tool);
    if (response.data.success) {
      const newTool = Object.assign(new Tool(), response.data.tool);
      this.replaceTool(newTool);
    } else {
      console.log('updateTool Error', response.data.error_code);
    }
    return response;
  }
  @Action({ rawError: true })
  async addCar(car: Car): Promise<AxiosResponse> {
    const response = await API.saveCar(car);
    if (response.data.success) {
      const newCar = Object.assign(new Car(), response.data.car);
      this.appendCar(newCar);
    } else {
      console.log('addCar Error', response.data.error_code);
    }
    return response;
  }

  @Action({ rawError: true })
  async updateCar(car: Car): Promise<AxiosResponse> {
    const response = await API.saveCar(car);
    if (response.data.success) {
      const newCar = Object.assign(new Car(), response.data.car);
      this.replaceCar(newCar);
    } else {
      console.log('updateCar Error', response.data.error_code);
    }
    return response;
  }
  @Mutation
  loadToolsFromConfig(tools: ITool[]): any {
    this.tools = tools.map(c => Object.assign(new Tool(), c));
    console.log('Store module Tools loaded..', this.tools.length);
  }
  @Mutation
  setTools(tools: Tool[]) {
    this.tools = [];
    tools.forEach(u => {
      this.tools.push(Object.assign(new Tool(), u));
    });
  }
  @Mutation
  setCars(cars: Car[]) {
    this.cars = [];
    cars.forEach(u => {
      this.cars.push(Object.assign(new Car(), u));
    });
  }

  @Action
  @RelatedModel('Tool')
  async fetchTools(): Promise<AxiosResponse> {
    console.log('*** FetchTools');
    const response = await API.fetchTools();
    if (response.data.success) {
      this.setTools(response.data.tools);
      this.setCars(response.data.cars);
      this.setToolParams(response.data.params);
      this.load();
      return response;
    }
  }

  @Mutation
  setToolParams(toolParams: ToolParam[]) {
    this.toolParams = [];
    toolParams.forEach(u => {
      this.toolParams.push(Object.assign(new ToolParam(), u));
    });
  }
  @Mutation
  appendToolParam(toolParam: ToolParam) {
    this.toolParams.push(toolParam);
  }

  @Mutation
  replaceToolParam(tool: ToolParam): any {
    const u = this.toolParams.find(v => v.id === tool.id);
    Object.assign(u, tool);
  }

  get toolsByExamStage() {
    return (examStage: ExamStage): Tool[] => {
      let methods = methodsModule.methods.filter(m => m.norm.includes(MethodNorm.STRENGTH));
      methods = methods.sort((a, b) => (a.actual < b.actual ? 1 : -1));

      const method = methods[0];
      const methodStage = methodsModule.methodStageByMethodIdAndIndex(method.id, examStage.index);
      const methodTools = methodsModule.methodStageToolsByMethodStageId(methodStage.id);
      const methodToolIds = methodTools.map(t => t.tool_id);

      return this.tools.filter(t => methodToolIds.includes(t.id) === true);
    };
  }
}

export default getModule(ToolsModule);
