













import { Vue, Component, Ref } from 'vue-property-decorator';
import userModule from '../store/modules/user';
import { sha256 } from 'js-sha256';
import PINInput from '../components/dummy/PINInput.vue';
import { forceUpdateForage } from '../helpers/offline';
import { info, error } from '../helpers';

@Component({
  components: { PINInput },
})
export default class PINModal extends Vue {
  @Ref() pinInput: PINInput;

  modalVisible = false;
  pin = '';
  errorMessage = '';

  showModal() {
    this.modalVisible = true;
  }

  clearErrors() {
    this.errorMessage = '';
  }

  async saveModal() {
    const reg = new RegExp('^[0-9]+$');

    this.pin = this.pinInput.fullCode;
    const match = reg.exec(this.pin);

    if (!match || this.pin.length < 4) {
      this.errorMessage = 'Niepoprawny pin.';
    } else {
      try {
        userModule.setPinHash(sha256(this.pin));
        await forceUpdateForage();
        info('Pomyślnie zapisano PIN dla modułu OFFLINE.');
        this.modalVisible = false;
      } catch (err) {
        console.error(err);
        error('Wystąpił nieoczekiwany błąd');
      }
    }
  }
}
