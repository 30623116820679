

























































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import dictModule from "../../../store/modules/dict";
import { TakeExamPlaces, ExamComplience, SlumpTestType } from "../../../consts";
import visitModule from "../../../store/modules/visit";
import {
  Collect,
  Protocol,
  CollectAndExamProtocol,
  Destiny,
  DestinyGroup,
  Mixer,
  Sample,
  Visit,
  VisitView,
  CollectExamS,
} from "../../../types";

@Component
export default class VisitCollectExamS extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) enabled: boolean;
  @Prop({ type: Collect }) collect: Collect;

  get getComplienceOptions() {
    return [ExamComplience.Z, ExamComplience.NZ];
  }
  complienceSelected(e) {
    (this.collect.exam as CollectExamS).complience = e;
    this.update();
  }
  get getSlumpOptions() {
    return [SlumpTestType.OK, SlumpTestType.NOK];
  }
  slumpSelected(e) {
    (this.collect.exam as CollectExamS).slump_test_type = e;
    this.update();
  }
  get visit(): Visit {
    return visitModule.visit;
  }
  update() {
    console.log("updating visit...");
    visitModule.setVisit(this.visit);
  }
}
