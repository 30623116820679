














































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import usersModule from '../../../store/modules/users';
import dictModule from '../../../store/modules/dict';
import {
  UserStatus,
  SortDir,
  LABORANT_ROUTE_PREFIX,
  PersonelActionStatus,
  PersonelOperationType,
  DashboardObjectTypes,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  ModuleType,
} from '../../../consts';
import { EventBus } from '../../../helpers/eventbus';
import { AppView, DashboardAction, DashboardItem, User, UserGroup } from '../../../types';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import ModalUser from '../../../components/spec/ModalUser.vue';
import ModalAction from '../../../components/spec/ModalAction.vue';
import userModule from '../../../store/modules/user';
import dashboardItemsModule from '../../../store/modules/dashboard_items';
import dataModule from '../../../store/modules/data';

import moment from 'moment';

@Component({
  components: {
    TopPanel,
    Header,
    Footer,
    ModalUser,
    ModalAction,
  },
})
export default class PersonelList extends Vue {
  @Ref() readonly modalPerson: ModalUser;
  @Ref() readonly modalAction: ModalAction;

  private btnPrevVisible = false;
  private btnNextVisible = true;

  private pageSize = 20;
  private pageNo = 1;
  private allPagesCount = 0;
  private filterParams = { field: '', value: '' };
  private sortParams = { field: 'changed_date_time', dir: SortDir.ASC };
  private spinner = false;

  userItems = [];

  // ------------- GETTERS --------------

  get getOperList() {
    return (user: User) => {
      return user.status.opers;
    };
  }

  fillUsers() {
    this.spinner = true;
    let users = usersModule.allUsers;
    users = users.filter(u => u.module != ModuleType.CLIENT);
    if (this.filterParams.field !== '' && this.filterParams.value !== '') {
      users = this.filterUser(users, this.filterParams.field, this.filterParams.value);
    }

    users = this.sortUser(users, this.sortParams.field, this.sortParams.dir);
    let index = 1;
    users.forEach(t => {
      t.load();
      index++;
      t['index'] = index;
    });
    this.allPagesCount = Math.ceil(users.length / this.pageSize);
    this.userItems = this.paginate(users, this.pageSize, this.pageNo);
    this.spinner = false;
    this.update();
  }

  getClientName(client_id: number) {
    if (client_id !== undefined) {
      const client = dictModule.clientById(client_id);
      return client !== undefined ? client.name : '';
    } else {
      return '';
    }
  }

  getCPlaceName(cplace_id: number) {
    if (cplace_id !== undefined) {
      const cplace = dictModule.cPlaceById(cplace_id);
      return cplace !== undefined ? cplace.name : '';
    } else {
      return '';
    }
  }

  getBatchColor(userStatusId: number) {
    return UserStatus.byId(userStatusId).badge;
  }
  get getStatusName() {
    return (userStatusId: number): string => {
      return UserStatus.byId(userStatusId).name;
    };
  }

  // ---------------- ROUTES ------------------
  addUser() {
    this.modalPerson.showModal();
  }

  modalUser(userItem: any) {
    const item = new DashboardItem();
    item.object_type_id = DashboardObjectTypes.PERSONEL;
    item.object = userItem.user;
    item.enabled = userItem.user.status.id === UserStatus.NEW.id;

    this.modalPerson.showModal(item);
  }
  modalPersonOK(params) {
    this.fillUsers();
  }

  get user(): User {
    return userModule.user;
  }

  goLink(user: User, oper: PersonelOperationType) {
    const newItem: DashboardItem = new DashboardItem();
    newItem.object = user;
    newItem.object_id = user.id;
    newItem.object_type_id = DashboardObjectTypes.PERSONEL;
    newItem.operation_type_id = oper.id;
    newItem.operation_type = oper;
    newItem.status_id = PersonelActionStatus.INITIAL.id;
    newItem.status = PersonelActionStatus.INITIAL;
    newItem.person_initiated_id = this.user.id;
    newItem.objectTypeName = 'Pracownik';

    const lastMove = {
      oldStatusId: -1,
      oldStatus: null,
      newStatusId: PersonelActionStatus.INITIAL.id,
      newStatus: PersonelActionStatus.INITIAL,
    };
    this.modalAction.showModal({ item: newItem, lastMove: lastMove });
  }
  modalActionOK(params: object) {
    this.$router.push({
      name: 'dashboardactions',
      params: { object_type: DashboardObjectTypes.PERSONEL.toString() },
    });
  }
  modalActionCancel(params: object) {
    console.log('modalActionCancel', params);
  }

  // ----------------- INTERNAL ACTIONS ----------------
  prevPage() {
    this.pageNo--;
    this.fillUsers();
  }

  nextPage() {
    this.pageNo++;
    this.fillUsers();
  }

  changePageSize() {
    this.update();
    this.fillUsers();
  }

  filterPage() {
    this.filterParams.field = 'group';
    this.filterParams.value = '1';
  }

  sortPage() {
    this.spinner = true;
    const sortField = 'id';
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.spinner = false;
    this.fillUsers();
  }

  update() {
    console.log('update', this.pageNo, this.allPagesCount);
    this.btnNextVisible = this.pageNo !== this.allPagesCount;
    this.btnPrevVisible = this.pageNo !== 1;
  }

  sortUser(array: User[], field: string, dir: SortDir): User[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      case 'changed_date_time':
        return array.sort((a, b) => {
          //console.log("DATES", moment(a[field], DATE_TIME_FORMAT), moment(b[field], DATE_TIME_FORMAT));
          if (dir === SortDir.ASC) {
            return moment(a[field], DATE_TIME_FORMAT).isAfter(moment(b[field], DATE_TIME_FORMAT)) ? -1 : 1;
          } else {
            return moment(a[field], DATE_TIME_FORMAT).isBefore(moment(b[field], DATE_TIME_FORMAT)) ? -1 : 1;
          }
        });
      case 'initials':
        //console.log("Sorting ini");
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a[field] < b[field] ? 1 : -1;
          } else {
            return a[field] > b[field] ? 1 : -1;
          }
        });
      default:
        return array;
    }
  }

  filterUser(array: User[], field: string, value: string) {
    console.log('Filter Array', field, value);
    switch (field) {
      case 'firstname':
        return array.filter(u => u.firstname === value);
      default:
        return array;
    }
  }

  paginate(array: User[], pageSize: number, pageNumber: number): object[] {
    pageNumber--;
    const rowFrom: number = +(pageNumber * pageSize);
    const rowTo: number = +(pageNumber * pageSize) + +pageSize;
    console.log('Page [' + rowFrom, '-', rowTo + ']');
    let index = 1;
    const templateArray: object[] = [];
    array.forEach(o => {
      templateArray.push({
        index: index++,
        user: o,
      });
    });
    return templateArray.slice(rowFrom, rowTo);
  }

  mounted() {
    console.log('mounted');
    this.fillUsers();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Lista personelu',
          link: '',
        },
      ],
    });
  }
}
