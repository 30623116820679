







import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import ProfileMenu from './ProfileMenu.vue';
import { AppView } from '../../types';
import BreadcrumbPanel from '../../components/dummy/BreadcrumbPanel.vue';
@Component({
  components: { BreadcrumbPanel, ProfileMenu },
})
export default class TopPanel extends Vue {}
