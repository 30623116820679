







































import { Component, Vue } from 'vue-property-decorator';
import UserModule from '../store/modules/user';
import validator from 'validator';
import * as helpers from '../helpers';

@Component
export default class Login extends Vue {
  private email = '';
  resetError = '';
  currentVersion = '';

  private validate() {
    if (this.email === '') {
      return 'Proszę wypełnić email';
    }
    if (validator.isEmail(this.email) === false) {
      return 'Proszę podać prawidłowy adres email';
    }
    return '';
  }

  clearErrors() {
    this.resetError = '';
  }

  async reset() {
    this.resetError = this.validate();
    if (this.resetError !== '') {
      return;
    }
    const response = await UserModule.reset(this.email);
    if (response.data.code === 200) {
      helpers.info('Hasło zresetowane, sprawdź skrzynkę email');
    } else if(response.data.code === 400) {
      helpers.error('Niepoprawny email !');
    } else if (response.data.code === 500) {
      helpers.error('Wystąpił błąd podczas resetowania hasła');
    }
  }

  created() {
    this.currentVersion = process.env.VUE_APP_VERSION;
  }
}
