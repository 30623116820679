




import { Component, Vue, PropSync } from "vue-property-decorator";
import orderModule from "../../../store/modules/order";
import visitModule from "../../../store/modules/visit";
import dictModule from "../../../store/modules/dict";
import {
  Collect,
  Protocol,
  CollectAndExamProtocol,
  Destiny,
  DestinyGroup,
  Mixer,
  Sample,
  Visit,
  VisitView,
} from "../../../types";
import visit from "../../../store/modules/visit";
import { min } from "moment";

@Component
export default class VisitSampleList extends Vue {
  @PropSync("viewProp", { type: Object }) view: VisitView;
}
