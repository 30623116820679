


























































































































































































import * as API from '../../store/api';
import { Component, Vue, Ref } from 'vue-property-decorator';
import Header from '../../components/dummy/Header.vue';
import Footer from '../../components/dummy/Footer.vue';
import userModule from '../../store/modules/user';
import usersModule from '../../store/modules/users';
import protocolsModule from '../../store/modules/protocols';
import visitModule from '../../store/modules/visit';
import visitsModule from '../../store/modules/visits';
import dictModule from '../../store/modules/dict';
import examsModule from '../../store/modules/exams';
import { onlineHelper } from '@/helpers/offline';
import dataModule from '../../store/modules/data';
import ordersModule from '../../store/modules/orders';
import {
  User,
  Visit,
  CalEvent,
  ActionButton,
  Signature,
  BreadcrumbItem,
  Client,
  ConstructionPlace,
  Sample,
  Exam,
  Message,
  Protocol,
} from '../../types';
import { VisitStatus, UserRightSymbols } from '../../consts';
import * as helpers from '../../helpers';
import moment from 'moment';
import samplesModule from '../../store/modules/samples';
import CalendarColumn from '../../views/laborant/CalendarColumn.vue';
import ModalQuestion from '../../components/dummy/ModalQuestion.vue';
import * as consts from '../../consts';
import SignVisitModal from '../../components/spec/SignVisit.vue';
import ModalChoosePerson from '../../components/dummy/ModalChoosePerson.vue';
import breadcrumbModule from '../../store/modules/breadcrumb';

@Component({
  components: { Header, Footer, CalendarColumn, SignVisitModal, ModalQuestion, ModalChoosePerson },
})
export default class CalendarVisitsDayPerson extends Vue {
  @Ref() readonly questionModal: ModalQuestion;
  @Ref() readonly signModalRef: SignVisitModal;
  @Ref() readonly choosePersonModal: ModalChoosePerson;

  firstVisitFocused = true;
  date = moment();
  personId = 0;
  person: User = null;

  selectedVisitId: number = null;
  PIB_TYPE = 1;
  CTB_TYPE = 2;
  private currentMonth = 7;
  private currentYear = 2020;
  private currentWeek = 15;
  private currentDay = 1;

  private revalidate = 0;

  get isAuthorised() {
    return (
      this.visit.collect_and_exam_protocol !== undefined &&
      this.visit.collect_and_exam_protocol.authorise_person !== undefined &&
      this.visit.status == VisitStatus.VERIFIED
    );
  }

  get isExamAssignedToLaborant() {
    const exam = examsModule.exams.find(e => e.protocol_id === this.visit.collect_and_exam_protocol_id);
    return exam !== undefined ? exam.current_laborant_id !== null : false;
  }

  get clientWidth() {
    console.log('clientWidth', window.innerWidth);
    const w = window.innerWidth;
    if (w > 1440) {
      return 750;
    } else {
      return 520;
    }
  }

  get visit(): Visit | null {
    this.revalidate;
    if (this.selectedVisitId) {
      return visitsModule.visitById(this.selectedVisitId) as Visit;
    } else {
      return null;
    }
  }

  get getTimeFormatted() {
    return (no: number): string => {
      if (no > 0) {
        const hour = Math.floor(no / 6);
        const minutes = (no % 6) * 10;
        return (hour + '').padStart(2, '0') + ':' + (minutes + '').padStart(2, '0');
      } else {
        return '0:00';
      }
    };
  }

  get visitStartTime() {
    return this.getTimeFormatted(this.getGridRowFromTime(this.visit.start_time));
  }
  get visitEndTime() {
    const endTime = moment(this.visit.start_time, 'HH,mm').clone().add(this.visit.duration, 'minutes');
    return this.getTimeFormatted(this.getGridRowFromTime(endTime.format('HH:mm')));
  }

  get getPersonEvents(): CalEvent[] {
    const personVisits = visitsModule.visitsByUserAndDate(this.person, this.date);
    const events: CalEvent[] = [];

    personVisits.forEach(visit => {
      const endTime = moment(visit.start_time, 'HH,mm').clone().add(visit.duration, 'minutes');
      visit.load(false);
      const event: CalEvent = {
        start: this.getGridRowFromTime(visit.start_time),
        end: this.getGridRowFromTime(endTime.format('HH:mm')),
        text: 'Zlec',
        object_id: visit.id,
      };
      console.log('Adding event', event);
      events.push(event);
    });
    return events;
  }

  get getClientByVisitId() {
    return (event: KeyboardEvent): Client => {
      //console.log('getClientByVisitId visit_id=', event);
      const v = visitsModule.visitById(event['object_id']);
      return dictModule.clientById(v.client_id);
    };
  }
  get getCPlaceByVisitId() {
    return (event: KeyboardEvent): ConstructionPlace => {
      //console.log('getCPlaceByVisitId visit_id=', event);
      const v = visitsModule.visitById(event['object_id']);
      return dictModule.cPlaceById(v.cplace_id);
    };
  }

  get user(): User {
    return userModule.user;
  }

  actionButtons = [];

  async questionOK(params: { status_id: number; show_preview?: boolean }) {
    console.log('Question OK');
    this.visit.status_id = params.status_id;
    ordersModule.updateOrderStatusBasedOnVisitStatus(this.visit);
    this.visit.status = VisitStatus.byId(params.status_id);
    this.update();
    this.selectedVisitId = this.visit.id;

    const visitToSaveInDb = await visitsModule.removeRedundantData(this.visit);
    const response = await API.changeStatusVisit(visitToSaveInDb);
    if (response.data.success === false) {
      helpers.error('Błąd w trakcie zamykania wizyty !!');
      return;
    }

    switch (params.status_id) {
      case VisitStatus.CLOSED.id: {
        const visitToSaveInDb = await visitsModule.removeRedundantData(this.visit);
        const response = await API.closeVisit(visitToSaveInDb);
        if (response.data.success === true) {
          console.log('response.data.took_place_date_time', response.data.took_place_date_time);

          this.visit.took_place_date_time = response.data.took_place_date_time;
          visitsModule.updateVisit(this.visit);
          const exams = response.data.exams as Exam[];
          console.log('response.data.exams', response.data.exams);

          for (const exam of exams) {
            exam.sample_ids = [];
            exam.samples = [];
          }
          for (const sample of response.data.samples as Sample[]) {
            console.log('sample', sample);

            const storeSample = samplesModule.samples.find(s => s.id === sample.id);
            storeSample.exam_id = sample.exam_id;
            const exam = exams.find(e => e.id === sample.exam_id);
            exam.sample_ids.push(sample.id);
            exam.samples.push(Object.assign(new Sample(), sample));
            samplesModule.replaceSample(storeSample);
          }
          for (const exam of exams) {
            examsModule.appendExam(Object.assign(new Exam(), exam));
          }
          await examsModule.fetchExamStageParams();
          await examsModule.fetchExamSampleStageParams();
          helpers.info('Wizyta zamknięta!');
        } else {
          helpers.error('Błąd w trakcie zamykania wizyty!');
        }
        break;
      }
      case VisitStatus.PENDING.id:
        console.log('Visit opened again', this.visit);
        this.editVisit(null, this.visit);
        break;
      case VisitStatus.VERIFY.id: {
        const protocol = this.visit.collect_and_exam_protocol as Protocol;
        if (params.show_preview === false) break;

        const visitId = this.visit.id.toString();
        const editVisit = visitsModule.visitCopyById(parseInt(visitId));
        (editVisit as Visit).load();
        visitModule.setVisit(editVisit);

        this.$router.push({
          name: (protocol as Protocol).getTypeString(),
          path: '/lab/visit/',
          params: {
            visit_id: this.visit.id.toString(),
            protocol_id: protocol.id.toString(),
            view_mode: consts.ViewModes.PRINT,
          },
        });
        break;
      }
      case VisitStatus.CORRECT.id:
        console.log('Visit to correct', this.visit);
        break;
      case VisitStatus.CANCELED.id: {
        this.visit.collect_and_exam_protocol.authorise_person = undefined;
        this.visit.collect_and_exam_protocol.authorise_person_id = undefined;
        this.visit.collect_and_exam_protocol.authorise_person = undefined;
        visitModule.setVisit(this.visit);
        await protocolsModule.updatePIBProtocol(this.visit.collect_and_exam_protocol);
        break;
      }
      case VisitStatus.VERIFIED.id: {
        const protocol = this.visit.collect_and_exam_protocol as Protocol;
        if (params.show_preview === false) break;

        const visitId = this.visit.id.toString();
        const editVisit = visitsModule.visitCopyById(parseInt(visitId));
        (editVisit as Visit).load();
        visitModule.setVisit(editVisit);

        this.$router.push({
          name: (protocol as Protocol).getTypeString(),
          path: '/lab/visit/',
          params: {
            visit_id: this.visit.id.toString(),
            protocol_id: protocol.id.toString(),
            view_mode: consts.ViewModes.PRINT,
          },
        });
        break;
      }
      default:
        break;
    }
  }
  async choosePersonOK(params: { name: string; id: number }) {
    this.questionOK({ status_id: VisitStatus.VERIFIED.id, show_preview: false });
    const message = new Message();
    message.receiver_id = params.id;
    message.title = 'Wizyta do autoryzacji';
    message.content_before_link = 'Wizyta ';
    message.link = this.$router.currentRoute.fullPath;
    message.link_text = this.visit.number;
    message.content_after_link = ' gotowa do autoryzacji';
    message.type = consts.MessageSenderType.SYSTEM;
    message.group_id = consts.MessageGroup.VISIT_AUTHORISE.id;
    message.message_type = consts.MessageType.INFO;
    message.sender_id = null;
    message.object_id = 1;
    message.object_type_id = consts.MessageObjectType.ACTION;
    const response = await dataModule.sendMessage(message);
    if (response.data.success === 'false') {
      helpers.error('Wysyłanie wiadomości do wybranej osoby nie powiodło się');
    }
  }

  async startVisit(e: KeyboardEvent, visit: Visit) {
    let success = false;
    let message = 'Wystąpił błąd';
    const visitObj = Object.assign(new Visit(), { id: visit.id });
    if (onlineHelper.isOnline) {
      const response = await API.startVisit(visitObj);
      message = response.data.message;
      success = response.data.success;
    } else {
      success = true;
      message = 'Pomyślnie rozpoczęto wizytę (OFFLINE).';
    }

    if (success) {
      console.log('Visit been started', e, visit);
      visit.status_id = VisitStatus.PENDING.id;
      ordersModule.updateOrderStatusBasedOnVisitStatus(visit);
      this.$router.push({
        name: 'visitmain',
        params: { visit_id: visit.id.toString() },
      });
      helpers.info(message, 4000);
    } else {
      helpers.error(message);
    }
  }

  previewVisit(e: KeyboardEvent, visit: Visit) {
    console.log('Preview visit', e, visit);
    //LS.saveLastView(this.view);
    this.$router.push({
      name: 'visitmain',
      params: { visit_id: visit.id.toString() },
    });
  }
  signVisit(e: KeyboardEvent, visit: Visit) {
    const signature: Signature = { id: 0, type: consts.SignatureType.VISIT, object: visit, user: userModule.wholeUser };
    visitModule.setVisit(visitsModule.visitById(this.selectedVisitId));
    console.log('Sign visit', e, visit, signature);
    this.signModalRef.showModal(signature);
  }
  async signModalOK(signature: Signature) {
    const sigVisit = signature.object as Visit;
    sigVisit.signature_id = signature.id;
    console.log('Signature', signature.object, signature);
    sigVisit.collect_and_exam_protocol.sign_laborant_id = this.user.id;
    const response = await protocolsModule.signPIBProtocol(sigVisit.collect_and_exam_protocol);
    if (response.data.success) {
      console.log('Protocol signed', sigVisit);
      helpers.info('Wizyta została podpisana.');
    } else {
      helpers.error('Błąd przy podpisywaniu wizyty!');
    }
  }

  cancelVisit(e: KeyboardEvent, visit: Visit) {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz anulować wizytę?',
      status_id: VisitStatus.CANCELED.id,
    });
    console.log('btn cancelVisit', e, visit);
  }
  toCorrectVisit(e: KeyboardEvent, visit: Visit) {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz wysłać wizytę do korekty?',
      status_id: VisitStatus.CORRECT.id,
    });
    console.log('btn toCorrectVisit', e, visit);
  }
  makeCorrectVisit(e: KeyboardEvent, visit: Visit) {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz zamknąć wizytę po korekcie?',
      status_id: VisitStatus.CLOSED.id,
    });
    console.log('btn makeCorrectVisit', e, visit);
  }
  revertVisit(e: KeyboardEvent, visit: Visit) {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz przywrócić wizytę?',
      status_id: VisitStatus.CLOSED.id,
    });
    console.log('btn revertVisit', e, visit);
  }
  openAgainVisit(e: KeyboardEvent, visit: Visit) {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz otworzyć ponownie wizytę?',
      status_id: VisitStatus.PENDING.id,
    });
    console.log('btn openAgainVisit', e, visit);
  }
  closeVisit(e: KeyboardEvent, visit: Visit) {
    if (!onlineHelper.isOnline) {
      helpers.error('Nie można zamykać wizyty w trybie offline!');
      return;
    }
    this.questionModal.showModal({ question: 'Czy na pewno chcesz zamknąć wizytę?', status_id: VisitStatus.CLOSED.id });
    console.log('Close visit', e, visit);
  }
  authoriseVisit(e: KeyboardEvent, visit: Visit) {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz rozpocząć autoryzację wizyty?',
      authorised: true,
      status_id: VisitStatus.VERIFIED.id,
    });
    console.log('Authorise visit', e, visit);
  }
  verifyVisit(e: KeyboardEvent, visit: Visit) {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz sprawdzić wizytę?',
      status_id: VisitStatus.VERIFY.id,
    });
    console.log('verifyVisit visit', e, visit);
  }
  acceptVisit(e: KeyboardEvent, visit: Visit) {
    this.choosePersonModal.showModal({
      rightToFilter: consts.UserRightSymbols.VISIT_AUTHORISE,
    });
  }

  private editVisit(e: KeyboardEvent, visit: Visit) {
    console.log('VISIT edit', visit, e);
    this.$router.push({
      name: 'visitmain',
      params: { visit_id: visit.id.toString() },
    });
  }

  get getTimeColumnData() {
    const array = [];
    for (let i = 0; i < 24; i++) {
      array.push({
        start: i * 6,
        end: (i + 1) * 6,
        text: i.toString().padStart(2, '0') + ':00',
      });
    }
    return array;
  }

  get getPeople() {
    return usersModule.getUsersByRightSymbol(UserRightSymbols.VISIT_START);
  }

  get getStartTime() {
    this.revalidate;
    return this.visit.start_time;
  }
  get cplaceSymbol() {
    return dictModule.cPlaceById(this.visit.cplace_id).symbol;
  }

  goMonth() {
    this.$router.push({ name: 'calendarvisitsmonth', params: { day: this.date.format(consts.DATE_FORMAT) } });
  }

  get getInitials() {
    return (user: User): string => {
      return user.initials;
    };
  }

  get getFullPersonName(): string {
    if (this.person) {
      return this.person.firstname + ' ' + this.person.lastname;
    } else {
      return '';
    }
  }

  get getUser() {
    return (user: User): User => {
      const u: User = new User();
      Object.assign(u, user);
      //console.log("USER:", u, typeof u); //weird - typeof = object, u = User
      return u;
    };
  }

  get currentDayTitle() {
    return this.date.format(consts.DATE_FORMAT);
  }

  getGridRowFromTime(time: string) {
    const timeObj = moment(time, 'HH:mm');
    return timeObj.hour() * 6 + Math.floor(timeObj.minutes() / 10);
  }

  get isCoord() {
    return userModule.hasRightBySymbol(UserRightSymbols.VISIT_COORD);
  }

  update() {
    this.revalidate++;
  }

  isVisitProperlyEnded(visit: Visit) {
    if (this.visit.type_id === consts.VisitType.MAIN) {
      return true;
    }
    const mainVisit = visitsModule.visitById(visit.related_visit_id);
    console.log('minDaysCtb', userModule.getSettings);
    const settings = userModule.getSettings;
    const minDaysCtb = settings.minDaysCtb || 0;
    console.log('VISIT DATE', mainVisit);
    const visitDate = moment(mainVisit.took_place_date_time).format(consts.DB_DATE_TIME_FORMAT);
    const diff = moment().subtract(visitDate);
    if (diff.days() >= minDaysCtb && mainVisit.status_id >= consts.VisitStatus.CLOSED.id) {
      return true;
    }
    return false;
  }

  previewButton: ActionButton;
  editButton: ActionButton;
  signButton: ActionButton;
  cancelButton: ActionButton;
  verifyButton: ActionButton;
  toCorrectButton: ActionButton;
  makeCorrectButton: ActionButton;
  revertButton: ActionButton;
  startButton: ActionButton;
  openAgainButton: ActionButton;
  closeButton: ActionButton;
  authoriseButton: ActionButton;
  acceptButton: ActionButton;

  buttons: Array<ActionButton> = [];

  loadButtons() {
    this.previewButton = new ActionButton(
      'Podgląd',
      'eye',
      [usersModule.VISIT_PREVIEW_RIGHT],
      [VisitStatus.PLANNED, VisitStatus.CLOSED, VisitStatus.VERIFIED],
      true
    );

    this.editButton = new ActionButton(
      'Edycja',
      'edit',
      [usersModule.VISIT_EDIT_RIGHT],
      [VisitStatus.PENDING, VisitStatus.CORRECT, VisitStatus.VERIFY]
    );
    this.signButton = new ActionButton(
      'Podpisz',
      'signature',
      [usersModule.VISIT_START_SIGN_RIGHT],
      [VisitStatus.CLOSED, VisitStatus.VERIFY, VisitStatus.VERIFIED, VisitStatus.CANCELED, VisitStatus.CORRECT]
    );
    this.cancelButton = new ActionButton(
      'Anuluj wizytę',
      'times',
      [usersModule.VISIT_CANCEL_RIGHT],
      [VisitStatus.CLOSED, VisitStatus.VERIFY, VisitStatus.VERIFIED]
    );
    this.verifyButton = new ActionButton(
      'Sprawdź wizytę',
      'check',
      [usersModule.VISIT_VERIFY_RIGHT],
      [VisitStatus.CLOSED]
    );
    this.toCorrectButton = new ActionButton(
      'Do korekty',
      'history',
      [usersModule.VISIT_TO_CORRECT_RIGHT],
      [VisitStatus.VERIFY, VisitStatus.VERIFIED]
    );
    this.makeCorrectButton = new ActionButton(
      'Zakończ korektę',
      'times',
      [usersModule.VISIT_MAKE_CORRECT_RIGHT],
      [VisitStatus.CORRECT]
    );
    this.revertButton = new ActionButton(
      'Przywróć',
      'redo-alt',
      [usersModule.VISIT_REVERT_RIGHT],
      [VisitStatus.CANCELED]
    );
    this.startButton = new ActionButton(
      'Rozpocznij',
      'play-circle',
      [usersModule.VISIT_START_RIGHT],
      [VisitStatus.PLANNED]
    );
    this.openAgainButton = new ActionButton(
      'Otwórz ponownie',
      'redo-alt',
      [usersModule.VISIT_OPEN_AGAIN_RIGHT],
      [VisitStatus.CLOSED], // VisitStatus.CLOSED],
      false,
      true,
      this.isExamAssignedToLaborant
    );
    this.closeButton = new ActionButton(
      'Zakończ wizytę',
      'times',
      [usersModule.VISIT_START_RIGHT],
      [VisitStatus.PENDING]
    ); // to nie blad
    this.authoriseButton = new ActionButton(
      'Rozpocznij autoryzację',
      'shield-alt',
      [usersModule.VISIT_AUTHORISE_RIGHT],
      [VisitStatus.VERIFIED], //, VisitStatus.CLOSED]
      false,
      true,
      this.isAuthorised
    );
    this.acceptButton = new ActionButton(
      'Akceptuj',
      'check',
      [usersModule.VISIT_ACCEPT_RIGHT],
      [VisitStatus.VERIFY] //, VisitStatus.CLOSED]
    );

    this.buttons = [
      this.previewButton,
      this.editButton,
      this.signButton,
      this.cancelButton,
      this.verifyButton,
      this.toCorrectButton,
      this.makeCorrectButton,
      this.revertButton,
      this.startButton,
      this.openAgainButton,
      this.closeButton,
      this.authoriseButton,
      this.acceptButton,
    ];
  }

  getButtons() {
    const buttons = this.buttons;
    return buttons.filter(btn => {
      if (btn.title == this.startButton.title) {
        const visitMainProperlyEnded = this.isVisitProperlyEnded(this.visit);
        return (
          btn.hasRightToBeVisible(this.user) && btn.hasStatusToBeVisible(this.visit.status) && visitMainProperlyEnded
        );
      }
      const hasRight = btn.hasRightToBeVisible(this.user);
      const hasStatus = btn.hasStatusToBeVisible(this.visit.status);
      const result = btn.hasRightToBeVisible(this.user) && btn.hasStatusToBeVisible(this.visit.status);
      // console.log('btn visible?', 'user has rights' + hasRight, 'visit has proper status' + hasStatus, result);
      return result;
    });
  }
  selectVisit(params: { event: CalEvent; user: User }) {
    console.log('EVENT clicked', params.event);
    this.selectedVisitId = params.event.object_id;
    this.visit.load(false);
    this.visit.status = VisitStatus.byId(this.visit.status_id);
    this.update();
  }
  created() {
    this.date = moment(this.$route.params.date, 'DD_MM_YYYY');
    this.personId = parseInt(this.$route.params.person_id);
    this.person = usersModule.userById(this.personId);
    if (this.$route.params.visit_id !== undefined) {
      this.selectedVisitId = parseInt(this.$route.params.visit_id);
    } else {
      const events = this.getPersonEvents;
      if (events.length > 0) {
        this.selectedVisitId = events[0].object_id;
      }
    }

    this.$on('select-event', this.selectVisit);

    if (this.visit) {
      console.log('DayPerson created load', this.visit);
      this.visit.load();
    }

    this.loadButtons();

    this.previewButton.setOnClickMethod(this.previewVisit);
    this.startButton.setOnClickMethod(this.startVisit);
    this.toCorrectButton.setOnClickMethod(this.toCorrectVisit);
    this.makeCorrectButton.setOnClickMethod(this.makeCorrectVisit);
    this.signButton.setOnClickMethod(this.signVisit);
    this.authoriseButton.setOnClickMethod(this.authoriseVisit);
    this.editButton.setOnClickMethod(this.editVisit);
    this.revertButton.setOnClickMethod(this.revertVisit);
    this.cancelButton.setOnClickMethod(this.cancelVisit);
    this.verifyButton.setOnClickMethod(this.verifyVisit);
    this.openAgainButton.setOnClickMethod(this.openAgainVisit);
    this.acceptButton.setOnClickMethod(this.acceptVisit);
    this.closeButton.setOnClickMethod(this.closeVisit);

    const breadcrumbItems: BreadcrumbItem[] = [
      {
        title: 'Kalendarz wizyt',
        link: 'cv/cvm',
      },
    ];

    if (this.isCoord) {
      breadcrumbItems.push({
        title: this.date.format(consts.DATE_FORMAT),
        link: {
          name: 'calendarvisitsdaypeople',
          params: { date: this.date.date() + '_' + (this.date.month() + 1) + '_' + this.date.year() },
        },
      });
      breadcrumbItems.push({
        title: this.person.fullName,
        link: '',
      });
    } else {
      breadcrumbItems.push({
        title: this.date.format(consts.DATE_FORMAT) + ' - ' + this.person.fullName,
        link: '',
      });
    }

    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: breadcrumbItems,
    });
    console.log('Created end');
  }
  get start() {
    console.log('%c---------- Start of template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of template -----------', 'color:green;font-weight:600');
    return '';
  }
}
