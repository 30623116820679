












































































import { Component, Vue, Prop } from 'vue-property-decorator';

import dictModule from '@/store/modules/dict';
import examsModule from '@/store/modules/exams';
import samplesModule from '@/store/modules/samples';

import { Exam, Destiny } from '@/types';
import { DestinyGroupCodes } from '@/consts';

@Component
export default class ModalResultsDoc extends Vue {
  @Prop({ required: true, type: Exam }) exam: Exam;

  show = false;

  selDestinyGroup = dictModule.destinyGroupBySymbol(DestinyGroupCodes.COMPRESSIVE_STREGTH);
  selDestiny: Destiny = null;
  selAge = 1;
  destiniesAdd = dictModule.destiniesByGroupId(this.selDestinyGroup.id);

  get destiniesAddItems() {
    return this.destiniesAdd;
  }

  get selDestinyAddItem() {
    return this.selDestiny;
  }

  destinyAddSelected(e: any) {
    this.selDestiny = e;
  }

  get sampleAgeAdd() {
    return this.selAge;
  }
  changeAddSampleAge(e: any) {
    this.selAge = e.target.value;
  }
  showModal() {
    this.show = true;
  }
  async saveModal() {
    this.exam.destiny_id = this.selDestiny.id;
    this.exam.destiny = this.selDestiny;
    this.exam.age = this.selAge;
    examsModule.updateExam(this.exam);
    for (const sample of this.exam.samples) {
      sample.destiny_id = this.selDestiny.id;
      sample.destiny = this.selDestiny;
      sample.age = this.selAge;
      samplesModule.updateSample(sample);
    }

    this.show = false;
  }
  hideModal() {
    this.show = false;
  }
}
