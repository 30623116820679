


















































































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import VisitHeader from '../../../views/laborant/visits/VisitHeader.vue';
import Footer from '../../../components/dummy/Footer.vue';
import orderModule from '../../../store/modules/order';
import visitModule from '../../../store/modules/visit';
import dictModule from '../../../store/modules/dict';
import {
  Collect,
  Protocol,
  CollectAndExamProtocol,
  Destiny,
  DestinyGroup,
  Mixer,
  Sample,
  Visit,
  VisitView,
  CollectCTBProtocol,
  Client,
  BreadcrumbItem,
  Breadcrumb,
} from '../../../types';
import visit from '../../../store/modules/visit';
import visitsModule from '../../../store/modules/visits';
import protocolsModule from '../../../store/modules/protocols';
import * as consts from '@/consts';
import { min } from 'moment';
import VisitTree from '../../../views/laborant/visits/VisitTree.vue';
import { EventBus, BusEvents } from '../../../helpers/eventbus';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import visitMixin from '../../../helpers/components/visit';
import samples from '@/store/modules/samples';

@Component({
  components: {
    VisitHeader,
    Footer,
    VisitTree,
  },
})
export default class VisitProtocolCTB extends visitMixin {
  get visitTitle() {
    return 'Odebranie CTB: ' + this.protocolCtb.number;
  }

  get getDestiny() {
    return (sample: Sample): Destiny => {
      return dictModule.destinyById(sample.destiny_id);
    };
  }

  setBreadcrumb() {
    const breadcrumbItems: BreadcrumbItem[] = [
      {
        title: 'Wizyta ' + this.visit.number,
        link: {
          name: 'visitmain',
          params: {
            visit_id: this.visit.id.toString(),
            pending: true.toString(),
          },
        },
      },
      {
        title: this.protocolCtb.getName() + ' ' + this.protocolCtb.number.toString(),
        link: '',
      },
    ];
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: breadcrumbItems,
    });
  }
  created() {
    console.log('%c*********************** ProtocolCTB mounting ***********************', 'color:red');
    this.isTreeVisible = false;
    console.log(this.protocolCtb, 'LOADED PROTOCOL');
    this.setBreadcrumb();
    window.addEventListener(
      'click',
      function (e: any) {
        this.checkPanelVisible(e);
      }.bind(this)
    );

    EventBus.$on(BusEvents.CHANGE_TREE_VISIBLE, this.changeVisible);
    console.log('%c####################### ProtocolCTB mounted ######################## ', 'color:red');
  }
}
