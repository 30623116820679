





































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { ModalEvents } from '../../../src/consts';
import { Signature } from '../../types';
import * as helpers from '../../helpers';
import dataModule from '../../store/modules/data';
import userModule from '../../store/modules/user';
import * as consts from '../../consts';
import moment from 'moment';

@Component
export default class SignVisit extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  private nameSurname = '';
  private phoneNumber = '';
  private code1 = '';
  private code2 = '';
  private code3 = '';
  private code4 = '';
  private show = false;
  private sendKey = 0;
  private confirmKey = 0;
  private events: [ModalEvents.OK_EVENT, ModalEvents.CANCEL_EVENT];
  private signature: Signature;
  private canSend = false;

  private badCode = false;
  @Emit()
  modalClose() {
    return 1;
  }

  checkIfCanSend() {
    if (helpers.checkPhoneNumber(this.phoneNumber) && this.nameSurname !== '' && this.fullCode.length === 4)
      this.canSend = true;
    else this.canSend = false;
    console.log('can send?', this.canSend);
  }

  get fullCode() {
    return this.code1 + this.code2 + this.code3 + this.code4;
  }

  getNthInput(i: number): HTMLInputElement {
    return this.$refs['c' + i] as HTMLInputElement;
  }

  nextInput(e: KeyboardEvent) {
    const element = e.target as HTMLInputElement;
    let step = element.value.length === 0 ? -1 : 1;

    if (e.key === 'ArrowLeft' || e.key === 'Backspace') {
      step = -1;
    } else if (e.key === 'ArrowRight') {
      step = 1;
    }

    console.log('Key:', e.key);
    const curr_index = parseInt(element.id[element.id.length - 1]) - 1;
    if (curr_index + step > 3 || curr_index + step < 0) {
      return;
    }
    const n_element = this.getNthInput(curr_index + step) as HTMLInputElement;
    n_element.focus();
    n_element.select();
  }
  sendAndSave() {
    this.signature.client_name = this.nameSurname;
    this.signature.user = userModule.wholeUser;
    this.signature.type = consts.SignatureType.VISIT;
    this.signature.phone_number = this.phoneNumber;
    dataModule.saveSignature(this.signature);
    this.sendKey++;
  }

  showModal(signature: Signature) {
    this.signature = signature;
    this.show = true;
  }
  async saveModal() {
    this.badCode = false;
    this.signature.client_name = this.nameSurname;
    this.signature.phone_number = this.phoneNumber;
    this.signature.code = this.fullCode;
    this.signature.datetime = moment().format('DD.MM.YYYY HH:mm:ss');
    console.log('saveModal signature', this.signature);
    const response = await dataModule.sendCode(this.signature);
    this.badCode = !response.data.success;
    if (this.badCode) {
      this.confirmKey++;
      return;
    }
    this.$emit(ModalEvents.OK_EVENT, this.signature);
    this.confirmKey++;
    this.show = false;
  }
  hideModal() {
    this.$emit(ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
