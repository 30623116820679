



































































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { File, OfferAction, Contract } from '../../types';
import FileManager from './FileManager.vue';
import * as consts from '../../consts';
import dictModule from '@/store/modules/dict';
import { Guid } from 'guid-typescript';
import * as API from '@/store/api';
import offerModule from '../../store/modules/offer';
import VueHtml2pdf from 'vue-html2pdf';
@Component({
  components: {
    FileManager,
    VueHtml2pdf,
  },
})
export default class DashboardOfferContract extends Vue {
  offerAction: OfferAction;
  printView = false;
  contract: Contract = null;
  @Prop({ required: true })
  enabled: boolean;
  @Ref() readonly pdfcontract: VueHtml2pdf;
  files: File[] = [];
  TYPE_CONTRACT = 'CON';
  TYPE_TEMP = 'TMP';
  isContractNew = false;
  pdfClickKey = 0;

  clientFromUserCreated = false;

  get contractGuid() {
    return this.contract.guid || null;
  }
  revalidate = 0;
  get contr() {
    this.revalidate;
    return this.contract;
  }

  isClient(): boolean {
    return this.$route.fullPath.includes('client');
  }

  get getClients() {
    return dictModule.clients;
  }

  clientSelected(e) {
    console.log(e, 'client selected');
    this.contract.client = e;
    this.contract.client_id = e.id;
    this.revalidate++;
  }

  pdf() {
    console.log('Creating pdf file...');
    this.pdfcontract.generatePdf();
    this.pdfClickKey++;
  }

  get getCurrentFileType() {
    return this.isContractNew ? this.TYPE_TEMP : this.TYPE_CONTRACT;
  }

  get getClient() {
    this.revalidate;
    if (this.contract.client === undefined) {
      return null;
    }
    return this.contract.client;
  }

  async mounted() {
    this.TYPE_CONTRACT = consts.FileGroup.CONTRACT.symbol;
    this.offerAction = offerModule.currentOfferAction;
    this.contract = this.offerAction.contract;
    if (this.contract === undefined) {
      console.log('Creating new contract...');
      this.isContractNew = true;
      this.offerAction.contract = new Contract();
      this.contract = this.offerAction.contract;
      this.contract.description = '';
      this.contract.client_order_number = '';
      this.contract.base_offer = '';
      this.contract.files = [];
      this.contract.contract_number = '';
      this.contract.guid = Guid.raw();
      this.contract.client = null;
      const response = await API.obtainContractNumber();
      if (response.data.success) {
        const number = response.data.number;
        this.contract.contract_number = number;
      }
      console.log('Created new contract.', this.contract);
    }
    if (this.isClient()) {
      this.enabled = false;
    }
    console.log('loaded contract', this.contract);
    this.offerAction.user_created.load();

    if (this.offerAction.user_created && this.offerAction.user_created.client) {
      this.contract.client_id = this.offerAction.user_created.client_id;
      this.clientFromUserCreated = true;
    }

    this.contract.load();
    this.revalidate++;
  }

  changePrintView() {
    this.printView = !this.printView;
  }
}
