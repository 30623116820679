import { render, staticRenderFns } from "./CalendarVisitsDayPerson.vue?vue&type=template&id=b87d3338&scoped=true&"
import script from "./CalendarVisitsDayPerson.vue?vue&type=script&lang=ts&"
export * from "./CalendarVisitsDayPerson.vue?vue&type=script&lang=ts&"
import style0 from "./CalendarVisitsDayPerson.vue?vue&type=style&index=0&id=b87d3338&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b87d3338",
  null
  
)

export default component.exports