

































































































import Vue from 'vue';
import Component from 'vue-class-component';
import ClientMenu from '@/components/spec/ClientMenu.vue';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import breadcrumbModule from '@/store/modules/breadcrumb';

@Component({
  components: { ClientMenu, TopPanel, Header, Footer },
})
export default class ClientOrders extends Vue {
  pageSize = 20;
  pageNumber = 0;

  async created() {
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/client',
      items: [
        {
          title: 'Lista zamówień',
          link: '',
        },
      ],
    });
  }
}
