import { render, staticRenderFns } from "./ModalDestiny.vue?vue&type=template&id=71a37101&scoped=true&"
import script from "./ModalDestiny.vue?vue&type=script&lang=ts&"
export * from "./ModalDestiny.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a37101",
  null
  
)

export default component.exports