


















































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import {
  DashboardItem,
  Method,
  User,
  MethodGroup,
  MethodStage,
  MethodStageParam,
  Tool,
  Destiny,
  DestinyGroup,
} from '../../types';
import usersModule from '../../store/modules/users';
import dictModule from '../../store/modules/dict';
import methodsModule from '../../store/modules/methods';
import toolsModule from '../../store/modules/tools';
import moment from 'moment';
import * as helpers from '../../helpers';

enum SectionView {
  PARAMS,
  TOOLS,
}

const METHOD_NAME_ALREADY_EXISTED = 2;

@Component
export default class ModalMethod extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  leftArrowSign = '<<';
  rightArrowSign = '>>';

  enabled = false;
  show = false;
  isNew = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  comment = '';
  btnSave = 0;
  orginalMethod: Method = null;
  item: DashboardItem = null;

  SectionViewPARAMS = SectionView.PARAMS;
  SectionViewTOOLS = SectionView.TOOLS;

  groupItemSelected: MethodGroup = null;
  method: Method = null;

  stages: MethodStage[] = [];
  sectionTitle = '';
  invalidate = 0;
  toolInvalidate = 0;
  protInvalidate = 0;
  rdInvalidate = 0;

  addGroupToProtMethodEnabled = false;
  addGroupToRDMethodEnabled = false;
  invalidateRight = 0;

  /* DESTINIES */
  get destinies() {
    return dictModule.destinies;
  }
  get selDestiny() {
    return dictModule.destinyById(this.method.default_destiny_id);
  }

  destinySelected(e: any) {
    console.log('Destiny selected', e);
    this.method.default_destiny_id = (e as Destiny).id;
    this.method.default_destiny = e as Destiny;
  }

  get methodUsers() {
    return this.method.method_users;
  }

  get getStages() {
    this.invalidate;
    return this.stages;
  }

  get getStageTools() {
    return (stage: MethodStage): Tool[] => {
      this.toolInvalidate;
      return stage.tools;
    };
  }
  choosenProtDestinyGroups = [];
  availableProtDestinyGroups = [];

  fillChoosenProtDestinyGroups() {
    const groups = [];

    if (this.method.protocol_destiny_groups && this.method.protocol_destiny_groups.length > 0) {
      const groupSymbols = this.method.protocol_destiny_groups.split(',');
      console.log('groupSymbols=', groupSymbols);
      groupSymbols.forEach(symbol => {
        const group = dictModule.destinyGroupBySymbol(symbol);
        group['selected'] = false;
        groups.push(group);
      });
    }
    this.choosenProtDestinyGroups = groups;
  }
  fillAvailableProtDesinyGroups() {
    const groups = dictModule.destinyGroups.filter(g => !this.choosenProtDestinyGroups.includes(g));
    groups.forEach(g => {
      g['selected'] = false;
    });
    this.availableProtDestinyGroups = groups;
  }

  addGroupToProtDestinyGroups() {
    if (this.addGroupToProtMethodEnabled) {
      const group = this.availableProtDestinyGroups.find(g => g['selected'] === true);
      if (group) {
        this.availableProtDestinyGroups = this.availableProtDestinyGroups.filter(g => g['selected'] !== true);
        this.choosenProtDestinyGroups.push(group);
        this.addGroupToProtMethodEnabled = false;
      }
    }
  }
  removeGroupFromProtDestinyGroups() {
    if (!this.addGroupToProtMethodEnabled) {
      const group = this.choosenProtDestinyGroups.find(g => g['selected'] === true);
      if (group) {
        this.choosenProtDestinyGroups = this.choosenProtDestinyGroups.filter(g => g['selected'] !== true);
        this.availableProtDestinyGroups.push(group);
        this.addGroupToProtMethodEnabled = true;
      }
    }
  }

  get isGroupProtSelected() {
    this.protInvalidate;
    let gr = this.choosenProtDestinyGroups.find(g => g['selected'] === true);
    if (gr === undefined) {
      gr = this.availableProtDestinyGroups.find(g => g['selected'] === true);
    }
    return gr !== undefined;
  }

  clearProtGroupSelection() {
    this.availableProtDestinyGroups.forEach(g => {
      g['selected'] = false;
    });
    this.choosenProtDestinyGroups.forEach(g => {
      g['selected'] = false;
    });
  }

  selectChoosenProtGroup(group: DestinyGroup) {
    console.log('Sel, ', group.symbol);
    this.clearProtGroupSelection();
    group['selected'] = true;
    this.addGroupToProtMethodEnabled = false;
    this.protInvalidate++;
  }
  selectProtGroup(group: DestinyGroup) {
    this.clearProtGroupSelection();
    group['selected'] = true;
    this.addGroupToProtMethodEnabled = true;
    this.protInvalidate++;
  }

  /* RD - ResultsDoc Destiny Groups */
  choosenRDDestinyGroups = [];
  availableRDDestinyGroups = [];

  fillChoosenRDDestinyGroups() {
    const groups = [];

    if (this.method.results_doc_destiny_groups && this.method.results_doc_destiny_groups.length > 0) {
      const groupSymbols = this.method.results_doc_destiny_groups.split(',');
      console.log('groupSymbols=', groupSymbols);
      groupSymbols.forEach(symbol => {
        const group = dictModule.destinyGroupBySymbol(symbol);
        group['selected_rd'] = false;
        groups.push(group);
      });
    }
    this.choosenRDDestinyGroups = groups;
  }
  fillAvailableRDDesinyGroups() {
    const groups = dictModule.destinyGroups.filter(g => !this.choosenRDDestinyGroups.includes(g));
    groups.forEach(g => {
      g['selected_rd'] = false;
    });
    this.availableRDDestinyGroups = groups;
  }

  addGroupToRDDestinyGroups() {
    if (this.addGroupToRDMethodEnabled) {
      const group = this.availableRDDestinyGroups.find(g => g['selected_rd'] === true);
      if (group) {
        this.availableRDDestinyGroups = this.availableRDDestinyGroups.filter(g => g['selected_rd'] !== true);
        this.choosenRDDestinyGroups.push(group);
        this.addGroupToRDMethodEnabled = false;
      }
    }
  }
  removeGroupFromRDDestinyGroups() {
    if (!this.addGroupToRDMethodEnabled) {
      const group = this.choosenRDDestinyGroups.find(g => g['selected_rd'] === true);
      if (group) {
        this.choosenRDDestinyGroups = this.choosenRDDestinyGroups.filter(g => g['selected_rd'] !== true);
        this.availableRDDestinyGroups.push(group);
        this.addGroupToRDMethodEnabled = true;
      }
    }
  }

  get isGroupRDSelected() {
    this.rdInvalidate;
    let gr = this.choosenRDDestinyGroups.find(g => g['selected_rd'] === true);
    if (gr === undefined) {
      gr = this.availableRDDestinyGroups.find(g => g['selected_rd'] === true);
    }
    return gr !== undefined;
  }

  clearRDGroupSelection() {
    this.availableRDDestinyGroups.forEach(g => {
      g['selected_rd'] = false;
    });
    this.choosenRDDestinyGroups.forEach(g => {
      g['selected_rd'] = false;
    });
  }

  selectChoosenRDGroup(group: DestinyGroup) {
    console.log('Sel, ', group.symbol);
    this.clearRDGroupSelection();
    group['selected_rd'] = true;
    this.addGroupToRDMethodEnabled = false;
    this.rdInvalidate++;
  }
  selectRDGroup(group: DestinyGroup) {
    this.clearRDGroupSelection();
    group['selected_rd'] = true;
    this.addGroupToRDMethodEnabled = true;
    this.rdInvalidate++;
  }

  newStageTab() {
    const ms = new MethodStage();
    ms.method_id = this.method.id;
    ms['index'] = this.stages.length + 1;
    console.log('STAGES', ms, this.stages);
    ms['section'] = SectionView.PARAMS;
    ms['params'] = [];
    ms['tools'] = [];
    this.stages.push(ms);
    this.invalidate++;
  }

  removeStage(index: number) {
    this.stages.splice(index, 1);
  }

  paramsClick(stage: MethodStage) {
    stage['section'] = SectionView.PARAMS;
    this.invalidate++;
  }
  toolsClick(stage: MethodStage) {
    console.log('section Tools');
    stage['section'] = SectionView.TOOLS;
    this.invalidate++;
  }
  changeParamClick(e: any, stage: MethodStage, param: MethodStageParam) {
    param['edit'] = !param['edit'];
  }
  removeParamClick(e: any, stage: MethodStage, param: MethodStageParam) {
    stage['params'] = stage['params'].filter(p => p.id !== param.id);
  }

  changeToolClick(e: any, stage: MethodStage, tool: Tool) {
    tool['edit'] = !tool['edit'];
    this.toolInvalidate++;
  }
  removeToolClick(e: any, stage: MethodStage, tool: Tool) {
    stage['tools'] = stage['tools'].filter(t => t.id !== tool.id);
    this.toolInvalidate++;
  }
  addParam(e: any, stage: MethodStage) {
    const param = Object.assign(new MethodStageParam(), {
      id: 0,
      name: '-- nazwa --',
      unit: '-jedn-',
      formula: '-- formuła --',
      method_stage_id: stage.id,
      symbol: '-- symbol --',
      on_rd: true,
      edit: true,
    });
    helpers.setTempId(stage['params'], param);
    stage['params'].push(param);
  }

  get getToolsNotUsed() {
    return (stage: MethodStage) => {
      return toolsModule.allTools.filter(t => !stage.tools.includes(t)) || [];
    };
  }

  addTool(e: any, stage: MethodStage) {
    const toolsNotUsed = this.getToolsNotUsed(stage);
    console.log('toolsNotUsed', toolsNotUsed, toolsNotUsed[0].name);
    const tool = toolsNotUsed[0];
    tool['edit'] = true;
    stage['tools'].push(tool);
    this.toolInvalidate++;
    console.log('Tool added', tool, stage['tools']);
  }

  get allTools() {
    return (stage: MethodStage, tool: Tool) => {
      this.toolInvalidate;
      return this.getToolsNotUsed(stage).filter(t => t.id !== tool.id);
    };
  }

  get selTool() {
    return (stage: MethodStage, tool: Tool) => {
      this.toolInvalidate;
      console.log('seltool', tool);
      if (tool !== undefined) {
        return stage['tools'].find(t => t.id === tool.id);
      } else {
        return {};
      }
    };
  }
  toolSelected(e: any, stage: MethodStage, tool: Tool) {
    console.log('selected tool', e, tool);
    stage['tools'].splice(
      stage['tools'].findIndex(t => t.id === tool.id),
      1,
      e as Tool
    );
  }

  fillStages() {
    const stages = methodsModule.allMethodStages.filter(s => s.method_id === this.method.id);
    stages.forEach(s => {
      s['section'] = SectionView.PARAMS;
      s['params'] = this.getStageParams(s);
      s['tools'] = this.fillStageTools(s);
    });
    console.log('Stages:', stages);
    this.stages = stages;
  }

  get getStageParams() {
    return (stage: MethodStage) => {
      const params = methodsModule.allMethodStageParams.filter(p => p.method_stage_id === stage.id);
      params.forEach(p => {
        p['edit'] = false;
      });
      return params;
    };
  }

  get fillStageTools() {
    return (stage: MethodStage): Tool[] => {
      console.log(
        'Tools',
        methodsModule.allMethodStageTools.filter(s => s.method_stage_id === stage.id)
      );
      const tools = methodsModule.allMethodStageTools
        .filter(s => s.method_stage_id === stage.id)
        .map(s => {
          console.log('MS ', s, toolsModule.toolById(s.tool_id), toolsModule.tools);
          return toolsModule.toolById(s.tool_id);
        });
      tools.forEach(t => {
        t['edit'] = false;
      });
      return tools;
    };
  }

  get status() {
    return consts.MethodStatus.byId(this.method.status_id);
  }

  get groupSel() {
    return this.groupItemSelected;
  }
  get methodGroups() {
    return dictModule.allMethodGroups;
  }
  groupSelected(event) {
    this.groupItemSelected = event;
    this.method.group_id = this.groupItemSelected.id;
  }

  showModal(params: DashboardItem = null) {
    console.log('MethodModal params showModal', params);
    this.show = true;

    this.isNew = params === null;
    if (this.isNew) {
      console.log('Add method');
      this.item = new DashboardItem();
      this.item.object_type_id = consts.DashboardObjectTypes.METHOD;
      this.params = this.item;
      this.enabled = true;
      this.params['enabled'] = true;
      this.method = new Method();
      this.method.group_id = this.methodGroups[0].id;
      this.groupItemSelected = this.methodGroups[0];
      this.stages = [];
      this.choosenProtDestinyGroups = [];
      this.choosenRDDestinyGroups = [];
    } else {
      console.log('Edit method');
      this.params = params;
      this.item = params;
      this.method = JSON.parse(JSON.stringify(params.object));
      this.groupItemSelected = this.method['group'];
      this.orginalMethod = params.object as Method;
      this.fillStages();
      this.fillChoosenProtDestinyGroups();
      this.fillChoosenRDDestinyGroups();
    }
    this.fillAvailableProtDesinyGroups();
    this.fillAvailableRDDesinyGroups();
    console.log('showModal', this.item, this.isNew);
  }
  async saveModal() {
    this.save = true;
    this.params['save'] = true;
    this.params['new'] = this.isNew;
    if (this.isNew) {
      this.method.add_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
    }
    this.method['stages'] = this.stages;
    this.method['protocol_destiny_groups'] = this.choosenProtDestinyGroups.map(g => g.symbol).toString();
    this.method['results_doc_destiny_groups'] = this.choosenRDDestinyGroups.map(g => g.symbol).toString();
    console.log('Saving method', this.method);
    let response = null;
    if (this.isNew) {
      response = await methodsModule.addMethod(this.method);
    } else {
      response = await methodsModule.updateMethod(this.method);
    }

    if (response.data.success === false && response.data.error_code === METHOD_NAME_ALREADY_EXISTED) {
      console.log('Method name already existed');
      helpers.error('Podana nazwa metody oraz norma są już wykorzystane, użyj innej pary');
      this.btnSave++;
      return;
    }
    this.method.id = response.data.method['id'];
    this.method = Object.assign(new Method(), this.method)
    this.method.load();
    this.params['object']  = this.method;
    console.log("ModalMethod end", this.params['object'])
    this.$emit(consts.ModalEvents.OK_EVENT, this.params);
    this.show = false;
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
}
