






import Vue from 'vue';
import Component from 'vue-class-component';
import MainMenu from '@/components/dummy/MainMenu.vue';

@Component({
  components: { MainMenu },
})
export default class ClientMenu extends Vue {
  get height() {
    return 'height: ' + window.innerHeight + 'px';
  }

  get items() {
    return [
      {
        name: 'calendars',
        title: 'Użytkownicy',
        icon: 'user-cog',
        items: [
          {
            name: 'userslist',
            title: 'Lista użytkowników',
            module: 'admin',
          },
        ],
      },
    ];
  }
}
