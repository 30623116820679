console.log('--- TYPES FILE LOADING ---');
import * as consts from '@/consts';
import { OrderStatus, SortDir, VisitStatus } from '@/consts';
import {
  IClient,
  ICollect,
  ICollectAndExamProtocol,
  ICollectProtocol,
  ICollectSampleList,
  IConcreteClassGroup,
  IConcreteClassSubgroup,
  IConcreteClass,
  IConstructionPlace,
  IContract,
  ILocalization,
  IDestiny,
  IDestinyGroup,
  IExam,
  IMaterial,
  IMixer,
  IOffer,
  IOfferAction,
  IOrder,
  IProfile,
  IRequest,
  ISample,
  IUser,
  IUserResponse,
  IUserRight,
  IUserSubmit,
  ITool,
  IMethod,
  IDashboardAction,
  IDashboardItem,
  IMessage,
  IFile,
  IExamStage,
  IExamStageParam,
  IMethodStage,
  IMethodStageParam,
  IExamSampleStageParam,
  IPeriod,
  ILimitedAmountSettlement,
  IHasFileContainer,
  IUserData,
  IResultsDoc,
  IDepartment,
} from '@/store/models';
import visitsModule from '@/store/modules/visits';
import methodModule from '@/store/modules/methods';
import appModule from '@/store/modules/app';
import dictModule from '@/store/modules/dict';
import userModule from '@/store/modules/user';
import usersModule from '@/store/modules/users';
import collectsModule from '@/store/modules/collects';
import protocolsModule from '@/store/modules/protocols';
import collectExamsModule from '@/store/modules/collect_exams';
import mixersModule from '@/store/modules/mixers';
import samplesModule from '@/store/modules/samples';
import dataModule from '@/store/modules/data';
import offerModule from '@/store/modules/offer';
import moment from 'moment';
import methodsModule from '@/store/modules/methods';
import toolsModule from '@/store/modules/tools';
import examsModule from '@/store/modules/exams';
import dashboardItemsModule from '@/store/modules/dashboard_items';
import { Enumify } from 'enumify';
import { VuexModule } from 'vuex-module-decorators';

class LabeledEntity {
  __type?: string;
  constructor() {
    this.__type = this.constructor.name;
  }
}

export class DashboardEntity extends LabeledEntity {
  id: number;
  number: string;
  name?: string;

  get getNumber() {
    return this.number;
  }
  set getNumber(value: string) {
    this.number = value;
  }
  get getDONEActionStatus(): Enumify {
    return null;
  }
  get getStatus() {
    return null;
  }
  set statusId(id: number) {
    console.log('');
  }
  update() {
    console.log('');
  }
}

export class Sample extends LabeledEntity implements ISample {
  id: number;
  number?: string;
  client_number?: string; // numer klienta
  condition?: string; // stan proki P lub N
  destiny_id?: number;
  destiny?: Destiny; // TO-DO load
  need_ctb_description?: boolean; // czy potrzebny komentarz CTB
  visit_ctb_description?: string; // opis odebrania
  age?: number; // wiek probki - czyli ile czeka przed badaniem
  collect_id?: number;
  exam_id?: number;
  Exam?: Exam;
  protocol_ctb_id?: number;
  position: number;
  isFocused = false;
  uw?: string; // niepewnosc

  constructor() {
    super();
    this.id = 0;
    this.number = '';
    this.destiny_id = 1;
    this.client_number = '';
    this.condition = '';
    this.age = 28;
    this.collect_id = -1;
    this.uw = '0,5';
  }

  load(copy = true) {
    console.log('Sample', this, copy);
    return undefined;
  }
}

/*
  Badania mieszanki
*/
export class CollectExam {
  id: number;
  type_id: number;
  method_name?: string;
  norm?: string;
  exam_collect_id?: number;

  constructor(examType: consts.CollectExamType = consts.CollectExamType.F) {
    this.id = 0;
    this.type_id = examType.id;
    this.method_name = examType.method_name;
    this.norm = examType.norm;
    this.exam_collect_id = -1;
  }

  load(foo: any) {
    console.log(foo);
  }
}

export class CollectExamS extends CollectExam {
  complience?: string;
  slump_test_type: string;
  result?: number;

  constructor() {
    super(consts.CollectExamType.S);
    this.complience = consts.ExamComplience.NZ;
    this.slump_test_type = consts.SlumpTestType.NOK;
    this.result = 0;
  }
}

export class CollectExamF extends CollectExam {
  d1?: string; // max rozmiar rozpływu mieszanki równoległy do jednej krawędzi stolka
  // zaokrąglany do 10 mm
  d2?: string;
  distribution_value?: string; // wartosc rozpływu = d1+d2/2
  comments?: string;

  constructor() {
    super(consts.CollectExamType.F);
    this.d1 = '';
    this.d2 = '';
    this.distribution_value = '';
    this.comments = '';
  }
}

export class CollectExamSF extends CollectExam {
  distribution_duration_given?: boolean; // czy wpisywać czas rozpływu
  distribution_duration?: string; // czas rozpływu z dokladnoscia do 0,5 sek, mozna pominac
  d1?: string; // najwieksza srednica rozplywu (zaokraglona do 10 mm)
  d2?: string; // srednica rozplywu mierzona pod katem 90 st do d1 (zaokr do 10),
  // uwaga roznica miedzy d1 i d2 nie moze byc wieksza nic 50 mm, jezeli tak to powtorz badanie
  distribution_value?: string; // wartosc rozpływu = d1+d2/2
  viscosity_class?: string;
  comments?: string;

  constructor() {
    super(consts.CollectExamType.SF);
    this.d1 = '';
    this.d2 = '';
    this.distribution_value = '';
    this.distribution_duration_given = true;
    this.distribution_value = '';
    this.viscosity_class = '';
    this.comments = '';
  }
  load(copy = true) {
    console.log('CollectExamSF', this, copy);
    super.load(copy);
  }
}

//PODCIAGANIE, SAMPLE, METHOD
export class Collect extends LabeledEntity implements ICollect {
  // Pobranie
  id: number;
  mixer_id: number;
  index: number;
  collect_time?: string; // godzina pobrania
  exam_time?: string; // godzina badania mieszanki
  collect_place?: string; // miejsce pobrania W - wezel lub ??
  mixture_temp?: string; // temperatura mieszanki
  air_temp?: string; // tempeeratura powietrza
  consistency_exam?: boolean; // badanie konsystencji
  air_contents?: boolean; // zawartosc powietra
  exam_type_id?: number; // rodzaj badania konsystencji
  air_contents_result?: string; // wynik awartosci powietrza
  exam?: CollectExam;
  exam_id?: number;
  sample_ids: number[];
  samples?: Sample[];
  method_ids?: number[];
  methods?: Method[];

  constructor() {
    super();
    this.id = 0;
    this.samples = [];
    this.consistency_exam = true;
    this.air_contents = true;
    this.sample_ids = [];
    this.air_contents_result = '';
    this.exam = new CollectExamS();
    this.exam_type_id = consts.CollectExamType.S.id;
    this.methods = [];
    this.method_ids = [];
  }

  /*eslint no-use-before-define: ["error", { "classes": false }]*/
  /*eslint-env es6*/
  public load(copy = true) {
    //console.log('Collect load', this);
    this.samples = [];
    this.sample_ids.forEach(id => {
      let sample = null;
      if (copy) {
        sample = samplesModule.sampleCopyById(id);
      } else {
        sample = samplesModule.sampleById(id);
      }
      sample = Object.assign(new Sample(), sample);
      sample.load(copy);
      this.samples.push(sample);
    });

    this.methods = [];
    this.method_ids.forEach(id => {
      let method = null;
      if (copy) {
        method = methodsModule.methodCopyById(id);
      } else {
        method = methodsModule.methodById(id);
      }

      /*eslint no-use-before-define: ["error", { "classes": false }]*/
      //method = Object.assign(new Method(), method);
      method.load(copy);
      this.methods.push(method);
    });

    //console.log('Collect load - Loading exam', this.exam_type_id, copy, this.exam_id);
    if (collectExamsModule.collectExamById(this.exam_id)) {
      switch (this.exam_type_id) {
        case consts.CollectExamType.S.id:
          if (copy) {
            this.exam = Object.assign(new CollectExamS(), collectExamsModule.collectExamCopyById(this.exam_id));
          } else {
            this.exam = Object.assign(new CollectExamS(), collectExamsModule.collectExamById(this.exam_id));
          }
          break;
        case consts.CollectExamType.F.id:
          if (copy) {
            this.exam = Object.assign(new CollectExamF(), collectExamsModule.collectExamCopyById(this.exam_id));
          } else {
            this.exam = Object.assign(new CollectExamF(), collectExamsModule.collectExamById(this.exam_id));
          }
          break;
        case consts.CollectExamType.SF.id:
          if (copy) {
            this.exam = Object.assign(new CollectExamSF(), collectExamsModule.collectExamCopyById(this.exam_id));
          } else {
            this.exam = Object.assign(new CollectExamSF(), collectExamsModule.collectExamById(this.exam_id));
          }
          break;
        default:
          break;
      }
      //console.log('Exam loaded', this.exam);
      if (this.exam) {
        console.log(this.exam, 'error exam!');
        this.exam.load(copy);
      }
    }
  }
}

export class Mixer extends LabeledEntity implements IMixer {
  // Gruszka
  id: number;
  number?: string;
  protocol_id?: number;
  index?: number;
  vehicle_no?: string; // oznaczenie pojazdu
  delivery_doc_no?: string; // numer dow dostawy
  volume?: string; // objetosc ladunku
  load_time?: string; // godzina zaladunku
  collect_ids?: number[];
  collects?: Collect[];

  constructor() {
    super();
    this.id = 0;
    this.vehicle_no = '';
    this.collect_ids = [];
    this.collects = [];
  }
  load(copy = true) {
    //console.log('Mixer load', this);
    this.collects = [];
    this.collect_ids.forEach(id => {
      let collect = null;
      if (copy) {
        collect = collectsModule.collectCopyById(id);
      } else {
        collect = collectsModule.collectById(id);
      }
      collect = Object.assign(new Collect(), collect);
      collect.load(copy);
      this.collects.push(collect);
    });
  }
}

export class Protocol extends LabeledEntity {
  id: number;
  mixer_id?: number;
  number: string;
  type_id: number;
  cplace_id?: number; // budowa
  client_code: string;
  cplace?: ConstructionPlace;
  create_date?: string; // data utworzenia protokołu
  create_time?: string; // godzina utworzenia protokolu
  sign_laborant_id?: number;
  sign_laborant?: User; // Osoba podpisująca protokół
  sign_client_id?: number;
  sign_client?: User; // Osoba podpisujaca od strony klienta, musi miec konto (?)
  visit_id?: number;

  constructor() {
    super();
    this.id = 0;
    this.number = '';
  }

  getName?(): string {
    switch (this.type_id) {
      case consts.ProtocolType.PIB:
        return 'Protokół PiB';
      case consts.ProtocolType.COLLECT_CTB:
        return 'Odebranie CTB';
      case consts.ProtocolType.COLLECT:
        return 'Protokół odebrania';
      default:
        return '';
    }
  }

  getTypeString?(): string {
    switch (this.type_id) {
      case consts.ProtocolType.PIB:
        return consts.ProtocolTypeString.PIB;
      case consts.ProtocolType.COLLECT_CTB:
        return consts.ProtocolTypeString.COLLECT_CTB;
      case consts.ProtocolType.COLLECT:
        return consts.ProtocolTypeString.COLLECT;
      default:
        return '';
    }
  }

  load(copy = true) {
    console.log('Protocol load empty', copy);
    /*
    if (copy) {
      this.sign_laborant = usersModule.userCopyById(this.sign_laborant_id);
    } else {
      this.sign_laborant = usersModule.userById(this.sign_laborant_id);
    }
    if (copy) {
      this.sign_client = usersModule.userCopyById(this.sign_client_id);
    } else {
      this.sign_client = usersModule.userById(this.sign_client_id);
    }
    */
  }
}

/*
    PROTOKÓŁ POBRANIA I BADANIA
*/
export class CollectAndExamProtocol extends Protocol implements ICollectAndExamProtocol {
  producer_receiver: string; // id producenta lub odbiorcy w zaleznosci od wyboru, zleceniodawca w zamowieniu ale tutaj ustawiamy jeszcze raz
  producer_receiver_type?: number; // type producent lub odbiorca
  material_id?: number; // 1 - beton itd
  element?: string; // element np. strop, sciana
  recipie_no?: string; // numer receptury
  cement?: string; // cement
  granulation?: string; // uziarnienie
  wc_factor?: string; // wspolczynnik woda / cement
  additionals1?: string; // dodatki
  admixtures1?: string; //domieszk
  additionals2?: string; // dodatki
  admixtures2?: string; //domieszk
  mixer_ids?: number[];
  mixers?: Mixer[]; // gruszki
  class_ids?: number[]; // id klas betonu
  classes?: ConcreteClass[];
  self_compacting?: boolean; // czy beton samoz ageszczalny
  comments?: string; // komentarze do protokolu
  client_represents_name?: string; // reprezentant klienta
  exam_maker_id?: number;
  exam_maker?: string; // próbkę pobrał i badania wykonał
  is_training?: boolean; // czy jest uczestnik i nadzorujacy
  participant?: string; // uczestnik badania
  supervisor?: string; // nadzorujacy badanie
  defaultSAge?: number; // default wiek wytrz na sciskanie
  defaultWAge?: number; // wiek wodoszczelnosci
  defaultFAge?: number; // wiek mrozoodpornosci
  authorise_person_id?: number;
  authorise_person?: User;
  authorise_date_time?: string;

  constructor() {
    super();
    this.mixers = [];
    this.class_ids = [];
    this.mixer_ids = [];
    this.additionals1 = '';
    this.admixtures1 = '';
    this.additionals2 = '';
    this.admixtures2 = '';
    this.type_id = consts.ProtocolType.PIB;
    this.defaultSAge = 28;
    this.defaultWAge = 28;
    this.defaultFAge = 28;
  }
  getName?(): string {
    return super.getName();
  }

  load(copy = true) {
    //console.log('Protocol load', this);

    super.load(copy);
    let mixer: Mixer = null;
    this.mixers = [];
    this.mixer_ids.forEach(id => {
      if (copy) {
        mixer = mixersModule.mixerCopyById(id);
      } else {
        mixer = mixersModule.mixerById(id);
      }
      mixer = Object.assign(new Mixer(), mixer);
      mixer.load(copy);
      this.mixers.push(mixer);
    });

    this.classes = [];
    this.class_ids.forEach(classId => {
      const classObj = dictModule.concreteClassById(classId);
      classObj.load();
      this.classes.push(classObj);
    });

    if (this.authorise_person_id !== null) {
      this.authorise_person = usersModule.userById(this.authorise_person_id);
    }
  }
  gatherSamplesIds(): number[] {
    const sample_ids: number[] = [];
    this.mixers.forEach(m => {
      m.collects.forEach(c => {
        c.samples.forEach(s => {
          sample_ids.push(s.id);
        });
      });
    });
    return sample_ids;
  }
  gatherSamples(): Sample[] {
    const samples: Sample[] = [];
    this.mixers.forEach(m => {
      m.collects.forEach(c => {
        c.samples.forEach(s => {
          samples.push(s);
        });
      });
    });
    return samples;
  }
}

/*
    PROTOKÓŁ ODBIORU
*/
export class CollectProtocol extends Protocol implements ICollectProtocol {
  descriptive_cplace: string; // miejsce odbioru probek
  localization_id: number; // dostawa probek do
  collect_date: string; // data odbioru probek
  sample_type: string; // rodzaj probki, jezeli Inny to
  sample_other_type: string; // inny rodzaj probek
  client_box: number; // probka opakowana przez klienta
  comments: string; // opis probek (np. uszkodzenie)
  descriptive_client_data: string; // dane kontaktowe zleceniodawct

  constructor() {
    super();
    this.type_id = consts.ProtocolType.COLLECT;
  }
  getName?() {
    return super.getName();
  }
  load(copy = true) {
    console.log('CollectProtocol load', copy);
  }
}
/*
    PROTOKÓŁ ODEBRANIA PROBEK CTB - UFORMOWANYCH PRZEZ CTB,nie jest to formalnie protokół, 
    ale dla nas ma ta sama funkcjonalnosc
*/
export class CollectCTBProtocol extends Protocol {
  sample_ids?: number[];
  samples?: Sample[];
  description?: string;

  constructor() {
    super();
    this.sample_ids = [];
    this.samples = [];
    this.description = '';
    this.type_id = consts.ProtocolType.COLLECT_CTB;
  }
  getName?() {
    return super.getName();
  }
  load(copy = true) {
    //console.log('CollectCTBProt loading', copy);
    super.load(copy);
    let sample = null;
    this.samples = [];
    this.sample_ids.forEach(id => {
      if (copy) {
        sample = samplesModule.sampleCopyById(id);
      } else {
        sample = samplesModule.sampleById(id);
      }
      sample = Object.assign(new Sample(), sample);
      sample.load(copy);
      this.samples.push(sample);
    });
    // TO-DO class, users
  }
}
export interface VisitInterface {
  id: number;
  number?: string;
  user_ids?: number[]; // wizytujacy
  cplace_id?: number;
  cplace_name?: string;
  cplace?: ConstructionPlace;
  date?: string;
  type_id?: number;
  start_time?: string;
  duration?: number; // czas trwania wizyty w minutach
  order_id?: number;
  order?: Order;
  client_id?: number;
  client_name?: string;
  client?: Client;
  status_id: number;
  status?: consts.VisitStatus;
  descriptive_address?: string;
  // TO-DO zapis przy zamówieniu - osoby która tworzy wizyte
  lab_contact_person_id?: number; // pracownik z laboratorium nadzorujący wizytę
  laborant_id?: number; // pracownik wykonujący wizytę
  accepted_id?: number; // id usera akceptującego wizyte (weryfikujacego)
  signed_id?: number; // id usera podpisujacego wizyte ze strony klienta, zakladamy ze taki jest w bazie albo go wprowadzamy
  took_place_date_time?: string;
  collect_protocol?: CollectProtocol;
  collect_and_exam_protocol?: CollectAndExamProtocol;
  collect_ctb_protocol?: CollectCTBProtocol;
  related_visit_id?: number;
  created_date_time?: string;
  changed_date_time?: string;
}

export class Visit extends LabeledEntity implements VisitInterface {
  id: number;
  number?: string;
  user_ids?: number[]; // wizytujacy
  cplace_id?: number;
  cplace_name?: string;
  cplace?: ConstructionPlace;
  date?: string;
  dateDb: string;
  type_id?: number;
  start_time?: string;
  duration?: number; // czas trwania wizyty w minutach
  order_id?: number;
  order?: Order;
  client_id?: number;
  client_name?: string;
  client?: Client;
  status_id: number;
  status?: consts.VisitStatus;
  descriptive_address?: string;
  // TO-DO zapis przy zamówieniu - osoby która tworzy wizyte
  lab_contact_person_id?: number; // pracownik z laboratorium nadzorujący wizytę
  laborant_id?: number; // pracownik wykonujący wizytę
  laborant?: User;
  accepted_id?: number; // id usera akceptującego wizyte (weryfikujacego)
  signed_id?: number; // id usera podpisujacego wizyte ze strony klienta, zakladamy ze taki jest w bazie albo go wprowadzamy
  signature_id?: number; // id podpisu
  signature?: Signature; // podpis
  took_place_date_time?: string;
  collect_protocol_id?: number;
  collect_and_exam_protocol_id?: number;
  collect_ctb_protocol_id?: number;
  collect_protocol?: CollectProtocol;
  collect_and_exam_protocol?: CollectAndExamProtocol;
  collect_ctb_protocol?: CollectCTBProtocol;
  is_prot_downloaded?: boolean;
  related_visit_id?: number;
  car_number?: string;
  created_date_time?: string;
  changed_date_time?: string;
  client_data?: boolean;
  client_cplace_symbol?: string; // symbol wezla klienta
  client_visit_index?: number; // numer pobrania od klienta

  setVisitStatus(visitStatus: VisitStatus, orderStatus?: OrderStatus) {
    this.status = visitStatus;
    this.status_id = visitStatus.id;

    if (this.order && orderStatus) {
      this.order.status_id = orderStatus.id;
    }
  }

  constructor() {
    super();
    this.id = 0;
    this.number = '';
    this.date = null;
    this.duration = 0;
    this.status_id = consts.VisitStatus.PLANNED.id;
    this.duration = appModule.defaultVisitDuration;
    this.client_cplace_symbol = '';
    this.client_visit_index = 0;
    //this.collect_and_exam_protocol = new CollectAndExamProtocol();
  }

  public load(copy = true) {
    console.log(
      'visit LOAD copy=',
      copy,
      this,
      'PiB=',
      this.collect_and_exam_protocol_id,
      'CTB=',
      this.collect_ctb_protocol_id,
      'CP=',
      this.collect_protocol_id
    );

    if (this.collect_and_exam_protocol_id) {
      if (copy) {
        this.collect_and_exam_protocol = Object.assign(
          new CollectAndExamProtocol(),
          protocolsModule.protocolById(this.collect_and_exam_protocol_id)
        );
        console.log('Loaded new protocolPIB', this.collect_and_exam_protocol_id, this.collect_and_exam_protocol, copy);
      } else {
        this.collect_and_exam_protocol = Object.assign(
          new CollectAndExamProtocol(),
          protocolsModule.protocolById(this.collect_and_exam_protocol_id)
        );
      }
      if (this.collect_and_exam_protocol) {
        this.collect_and_exam_protocol.load(copy);
      }
    }

    if (this.collect_ctb_protocol_id) {
      if (copy) {
        this.collect_ctb_protocol = Object.assign(
          new CollectCTBProtocol(),
          protocolsModule.allProtocols.find(
            el => el.visit_id === this.id && el.type_id === consts.ProtocolType.COLLECT_CTB
          )
        );
      } else {
        this.collect_ctb_protocol = Object.assign(
          new CollectCTBProtocol(),
          protocolsModule.allProtocols.find(
            el => el.visit_id === this.id && el.type_id === consts.ProtocolType.COLLECT_CTB
          )
        );
      }
      if (this.collect_ctb_protocol) {
        (this.collect_ctb_protocol as CollectCTBProtocol).load(copy);
      }
    }

    if (this.collect_protocol_id) {
      if (copy) {
        this.collect_protocol = Object.assign(
          new CollectProtocol(),
          protocolsModule.protocolById(this.collect_protocol_id)
        );
      } else {
        this.collect_protocol = protocolsModule.protocolById(this.collect_protocol_id) as CollectProtocol;
      }
    }

    if (this.date.includes('-')) {
      console.log('Loading visit, date format DB, changing...', this.date);
      const dt = moment(this.date, consts.DB_DATE_TIME_FORMAT);
      this.date = dt.format(consts.DATE_FORMAT);
      console.log('date format changed', this.date);
    }

    this.client = dictModule.clientById(this.client_id);
    this.cplace = dictModule.cPlaceById(this.cplace_id);
    this.status = consts.VisitStatus.byId(this.status_id);
    this.signature = dataModule.signatureById(this.signature_id);
    this.laborant = usersModule.userById(this.laborant_id);
    //this.order = ordersModule.orderById(this.order_id);
  }

  getDate(): moment.Moment {
    return moment(this.date, consts.DATE_FORMAT);
  }

  getURLDate(): string {
    const dt = this.date.includes('-')
      ? moment(this.date, consts.DB_DATE_TIME_FORMAT)
      : moment(this.date, consts.DATE_FORMAT);
    return dt.date() + '_' + (dt.month() + 1) + '_' + dt.year();
  }

  isEditable() {
    return (
      this.status === VisitStatus.PENDING || this.status === VisitStatus.VERIFY || this.status === VisitStatus.CORRECT
    );
  }
}

export class Order extends LabeledEntity implements IOrder {
  id: number;
  index?: number;
  number?: string;
  create_date_time?: string;
  changed_date_time?: string;
  date?: string;
  client_id?: number;
  client_name?: string;
  client_type?: number; // PRODUCENT/ODBIORCA
  client_address_id?: number;
  client_address?: Address;
  cplace_id?: number;
  cplace_name?: string;
  status_id: number;
  contract_id?: number;
  contract_number?: string;
  contract_date?: string;
  description?: string;

  visit?: Visit; // wizyta glowna
  take_ctb_visit?: Visit; // wizyta do obioru uformowanych w trakcie badan probek
  take_visit?: Visit; // wizyta do odbioru probek przygotowanych przez klienta
  visits_ids?: number[]; // for future use
  visits?: Visit[]; // wizyty

  [propName: string]: any;

  constructor() {
    super();
    this.id = 0;
    this.status_id = 0;
    this.visit = new Visit();
    this.visit.type_id = consts.VisitType.MAIN;
    this.take_ctb_visit = new Visit();
    this.take_ctb_visit.type_id = consts.VisitType.CTB;
    this.client_type = consts.ClientType.PRODUCER;
  }

  public static create(obj: object) {
    const order = new Order();
    Object.assign(order, obj);
    return order;
  }

  get client(): Client {
    return dictModule.clients.find(c => c.id === this.client_id) || ({} as Client);
  }

  get cplace(): ConstructionPlace {
    return dictModule.cPlaces.find(c => c.id === this.cplace_id) || ({} as ConstructionPlace);
  }

  get status(): OrderStatus {
    return OrderStatus.byId(this.status_id);
  }

  load(copy = true) {
    this.address = dictModule.addressById(this.client_address_id);
    console.log('Order visits loading', copy, visitsModule.allVisits, this.order_id);
    const visitMain = visitsModule.allVisits.find(v => v.order_id === this.id && v.type_id === consts.VisitType.MAIN);
    const visitCTB = visitsModule.allVisits.find(v => v.order_id === this.id && v.type_id === consts.VisitType.CTB);
    const visitCollect = visitsModule.allVisits.find(
      v => v.order_id === this.id && v.type_id === consts.VisitType.COLLECT
    );
    if (visitMain) {
      console.log('Loading main visit', visitMain);
      this.visit = visitsModule.visitCopyById(visitMain.id);
      this.visit.load(copy);
    }
    if (visitCTB) {
      //console.log('loading ctb visit', visitCTB);
      this.take_ctb_visit = visitsModule.visitCopyById(visitCTB.id);
      this.take_ctb_visit.load(copy);
    }
    if (visitCollect) {
      this.take_visit = visitsModule.visitCopyById(visitCollect.id);
      this.take_visit.load(copy);
    }
    console.log('ORDER loaded', this);
  }
}

export class Address extends LabeledEntity {
  id: number;
  city?: string;
  street_and_house_no?: string;
  postal_code?: string;
  correspond?: string;
  create_date_time?: string;
  changed_date_time?: string;

  constructor() {
    super();
    this.id = 0;
    this.city = '';
    this.street_and_house_no = '';
    this.postal_code = '';
    this.correspond = '';
  }
}

export class Client extends LabeledEntity implements IClient {
  id: number;
  name: string;
  short_name?: string;
  code?: string;
  address_id?: number;
  address?: Address;
  nip?: string;
  active?: boolean;
  correspond_address?: string;
  rd_counter: number;

  contact_person_name?: string;
  contact_email?: string;
  contact_phone?: string;

  cplaces?: ConstructionPlace[];

  create_date_time?: string;
  changed_date_time?: string;

  constructor() {
    super();
    this.id = 0;
    this.name = '';
    this.code = '';
    this.cplaces = [];
    this.short_name = '';
    this.address_id = -1;
    this.address = new Address();
    this.active = true;
    this.contact_email = '';
    this.contact_person_name = '';
    this.contact_phone = '';
  }

  load() {
    this.address = dictModule.addressById(this.address_id);
  }

  import?(obj: object) {
    Object.assign(this, obj);
  }
}

export class ConstructionPlace extends LabeledEntity implements IConstructionPlace {
  id: number;
  name: string;
  short_name: string;
  symbol?: string;
  active?: boolean;
  client_id: number;
  users?: User[] = [];
  users_ids?: number[];

  constructor() {
    super();
    this.id = 0;
    this.name = '';
    this.short_name = '';
    this.symbol = '';
    this.active = true;
    this.users = [];
    this.users_ids = [];
  }
}

export class Contract extends LabeledEntity implements IContract, IHasFileContainer {
  id: number;
  files: File[];
  contract_number: string;
  description: string;
  client_accepted: boolean;
  client_order_number: string;
  client?: Client;
  client_id: number;
  base_offer: string;
  guid?: string;
  offer_id?: number;

  load() {
    if (this.client_id !== null) {
      this.client = dictModule.clientById(this.client_id);
    }
  }
}

export class Exam extends LabeledEntity implements IExam {
  id: number;
  protocol_id?: number;
  protocol?: CollectAndExamProtocol;
  number?: string;
  name: string;
  client_id: number;
  client?: Client;
  description: string;
  sample_ids: number[];
  end_date_time?: string;
  samples?: Sample[];
  destiny_id?: number;
  destiny?: Destiny;
  n_date?: string;
  stage_id?: number;
  stage?: ExamStage;
  current_laborant_id?: number;
  current_laborant?: User;
  is_authorised?: boolean;
  person_authorised?: User;
  person_authorised_id: number;
  started_date: string;
  start_date_time?: string;
  age?: number;
  status?: number;

  results_doc?: ResultsDoc;
  is_rd_downloaded?: boolean;

  constructor() {
    super();
    this.id = 0;
    this.number = '';
    this.sample_ids = [];
    this.n_date = '';
    this.age = 28;
    this.status = 1; // do badania
  }

  load() {
    this.client = dictModule.clientById(this.client_id);
    this.destiny = dictModule.destinyById(this.destiny_id);
    this.stage = examsModule.examStageById(this.stage_id);
    this.samples = [];
    this.sample_ids.forEach(id => {
      this.samples.push(Object.assign(new Sample(), samplesModule.sampleById(id)));
    });
    this.current_laborant = usersModule.userById(this.current_laborant_id);
    this.protocol = protocolsModule.protocolById(this.protocol_id) as CollectAndExamProtocol;
    if (this.person_authorised_id !== null) {
      this.person_authorised = usersModule.userById(this.person_authorised_id);
    }
    console.log('Exam ', this.id, this.number, 'loaded');
  }
}

export class ExamStage extends LabeledEntity implements IExamStage {
  id: number;
  index: number; // numer etapu
  name: string;
  description?: string;
  exam_id?: number;
  exam?: Exam;
  tool_ids?: number[];
  tools?: Tool[];
  n_from_days_far?: number; // ilosc dni od rozpoczecia
}

export class ExamStageParam extends LabeledEntity implements IExamStageParam {
  id: number;
  name: string;
  unit?: string;
  formula?: string;
  description?: string;
  exam_stage_id?: number;
  exam_stage?: ExamStage;
  symbol?: string;
  on_rd?: boolean; // czy jest na sprawozdaniu
}
export class ExamSampleStageParam extends LabeledEntity implements IExamSampleStageParam {
  id: number;
  param_id?: number;
  param?: ExamStageParam;
  sample_id?: number;
  sample?: Sample;
  value?: string;
}

export class MethodStage implements IMethodStage {
  id: number;
  name: string;
  description: string;
  method_id?: number;
  method?: Method;
  tool_ids?: number[];
  tools?: Tool[];
  index?: number;
  n_from_days_far?: number; // ilosc dni od rozpoczecia

  params?: MethodStageParam[];
  stage_tools?: MethodStageTool[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.method_id = -1;
    this.method = null;
    this.tool_ids = [];
    this.tools = [];
    this.index = 1;
    this.n_from_days_far = 0;
  }
  load() {
    //console.log('Method Stage load', this.id);
    this.params = methodsModule.methodStageParams.filter(p => p.method_stage_id === this.id);
    this.stage_tools = methodsModule.methodStageTools.filter(t => t.method_stage_id === this.id);
    this.tools = [];
    this.stage_tools.forEach(st => {
      this.tools.push(toolsModule.toolById(st.tool_id));
    });
  }
}
export class MethodStageParam implements IMethodStageParam {
  id: number;
  name: string;
  unit?: string;
  formula?: string;
  description?: string;
  method_stage_id?: number;
  method_stage?: MethodStage;
  symbol?: string;
  on_rd?: boolean; // czy jest na sprawozdaniu
}

export class MethodStageTool {
  id: number;
  method_stage_id?: number;
  method_stage?: MethodStage;
  tool_id?: number;
  tool?: Tool;
}

export class DestinyGroup implements IDestinyGroup {
  id: number;
  name: string;
  description?: string;
  symbol?: string;
}

export class Destiny implements IDestiny {
  id: number;
  name: string;
  group_id: number;
  value?: string;
  symbol?: string;
  description?: string;
}

export class Localization implements ILocalization {
  id: number;
  name: string;
  code?: string;
}

/*
    WYKAZ PR�BEK
*/
export class CollectSampleList implements ICollectSampleList {
  id: number;
  sample_ids: number[]; // probeki na wykazie
  format: string; // format numeru probek
}

export class OfferAction extends DashboardEntity implements IOfferAction, IHasFileContainer {
  id: number;
  number: string;
  client_id?: number;
  guid?: string;
  files: File[];
  offers: Offer[];
  status: consts.OfferActionStatus;
  create_date_time: string;
  status_id: number;
  type = consts.DashboardObjectTypes.OFFER;
  client_description: string;
  contact_description: string;
  request_range: string;
  date_initiated: string;

  user_created_id: number;
  user_created: User;
  contract: Contract;
  contract_id: number;
  description: string;
  get getStatus() {
    return this.status;
  }
  get getDONEActionStatus() {
    return consts.OfferActionStatus.CONTRACT;
  }
  load() {
    this.status = consts.OfferActionStatus.byId(this.status_id);
    if (this.user_created_id) {
      this.user_created = usersModule.userById(this.user_created_id);
    }
    if (this.contract_id) {
      this.contract = Object.assign(new Contract(), offerModule.contractById(this.contract_id));
    }
    console.log('Loaded OfferAction', this);
  }
}

export class Request implements IRequest {
  id: number;
  number: string;
  iter: number; // ktora oferta z kolei
  action_id: number; // przywiazanie do akcji ofertowej
  status: consts.OfferStatus;
  description: string;
}

export class Period implements IPeriod {
  start_date: string;
  end_date: string;
  visit_count: string;
  price: number;
}

export class LimitedAmountSettlement implements ILimitedAmountSettlement {
  method_id: number;
  cost: number;
  limit: number;
}

export class Offer extends DashboardEntity implements IOffer, IHasFileContainer {
  id: number;
  offerActionId: number;
  number: string;

  local_number: number;

  guid?: string;
  files: File[];
  active = false;
  methods: Method[];
  iter: number;
  settlement_type: consts.OfferSettlements;
  action_id: number;
  status_id?: number;
  status: consts.OfferStatus;
  description: string;
  //RZECZY ZWIAZANE Z ROZLICZENIEM OFERTY
  periods?: Period[] = []; //okresy ryczaltwe
  price?: number; //calkowity koszt oferty
  load() {
    this.methods = this.methods.map(method => {
      const newMethod = methodModule.methodCopyById(method.id);
      newMethod.price = method.price || null;
      newMethod.limit = method.limit || null;
      return newMethod;
    });
    console.log(this);
    this.status = consts.OfferStatus.byId(this.status_id);
    console.log('Loaded offer', this.status);
  }
}

export class UserRight extends LabeledEntity implements IUserRight {
  id: number;
  name: string;
  view_group?: string;
  symbol: string;
  description?: string;
}

export class UserSubmit extends LabeledEntity implements IUserSubmit {
  email: string;
  password: string;
}

export class UserGroup extends LabeledEntity {
  id: number;
  name: string;
  type: number;
  index: number;
  symbol: string;
  group_ids: number[];
  right_ids: number[];
  rights?: UserRight[];
  right_symbols?: string[];
  description: string;

  load() {
    this.rights = [];
    this.right_ids.forEach(right_id => {
      const r = usersModule.getRightById(right_id);
      this.rights.push(r);
    });
  }
}

export class User extends DashboardEntity implements IUser {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  client_id?: number;
  client_code?: string;
  client?: Client;
  position?: string;
  token?: string;
  is_logged_in: boolean;
  created_date_time?: string;
  changed_date_time?: string;
  module?: number;
  initials: string;
  role_id?: number;
  group_ids?: number[];
  groups?: UserGroup[];
  right_ids?: number[];
  rights?: UserRight[];
  status_id?: number;
  status?: consts.UserStatus;
  calendar_index: number;
  is_active: boolean;

  public get fullName() {
    return this.firstname + ' ' + this.lastname;
  }
  get getNumber() {
    return this.fullName;
  }
  get getStatus() {
    return this.status;
  }
  set statusId(id: number) {
    this.status_id = id;
    this.status = consts.UserStatus.byId(id);
  }

  get getDONEActionStatus(): Enumify {
    return consts.PersonelActionStatus.DONE;
  }

  async update() {
    await usersModule.updateUser(this);
  }

  constructor() {
    super();
    this.id = 0;
    this.status_id = consts.UserStatus.NEW.id;
    this.status = consts.UserStatus.NEW;
    this.email = '';
    this.firstname = '';
    this.lastname = '';
    this.token = '';
    this.is_logged_in = false;
    this.group_ids = [];
    this.groups = [];
    this.right_ids = [];
    this.rights = [];
    this.role_id = -1;
    this.module = 1;
    this.calendar_index = 0;
  }

  load(copy = true) {
    console.log('User load', this, copy);
    this.rights = [];
    this.right_ids.forEach(rightId => {
      this.rights.push(usersModule.getRightById(rightId));
    });
    this.groups = [];
    this.group_ids.forEach(groupId => {
      const gr = usersModule.getGroupById(groupId);
      gr.load();
      this.groups.push(gr);
    });
    this.client = dictModule.clientById(this.client_id);
    this.status = consts.UserStatus.byId(this.status_id);
  }

  hasRight(rightToCheck: UserRight) {
    return userModule.userHasRightBySymbolInAllRights(this, rightToCheck.symbol);
  }

  isClient(): boolean {
    if (this.module !== undefined) {
      return this.module === consts.UserModule.CLIENT;
    } else {
      return false;
    }
  }
  isLaborant(): boolean {
    if (this.module !== undefined) {
      return +this.module === consts.UserModule.LABORANT;
    } else {
      return false;
    }
  }
  isAdmin(): boolean {
    if (this.module !== undefined) {
      return this.module === consts.UserModule.ADMIN;
    } else {
      return false;
    }
  }
}

export class UserResponse implements IUserResponse {
  user: User;
}

export class Profile implements IProfile {
  user?: User;
}

export interface Day extends LabeledEntity {
  date: object;
  dateString: string;
  dayAndMonth: string;
  dayOfMonth: number;
  dayOfYear: number;
  dayOfWeek: number;
  year: number;
  month: number;
  visits?: Visit[];
  exams?: Exam[];
  weekOfYear: number;
}

export class Day implements Day {
  constructor(date: moment.Moment) {
    this.date = date;
    this.dateString = date.format(consts.DATE_FORMAT);
    this.dayAndMonth = date.format('DD.MM');
    this.dayOfMonth = date.date();
    this.dayOfYear = date.dayOfYear();
    this.dayOfWeek = date.day();
    this.year = date.year();
    this.month = date.month() + 1;
    this.visits = [];
  }
}

export interface Week extends LabeledEntity {
  weekStart: object;
  weekEnd: object;
  weekOfYear: number;
  year: number;
  month: number;
  days: Day[];
}

export class CalEvent extends LabeledEntity {
  start: number;
  end: number;
  column?: number = -1;
  colSpan?: number = -1;
  text: string;
  object_id?: number;
  object?: object;
}

export class BreadcrumbItem extends LabeledEntity {
  title: string;
  name?: string;
  link?: string | object;
  view?: AppView;
  class?: string;
}

export class Breadcrumb extends LabeledEntity {
  routePrefix: string;
  items: BreadcrumbItem[];
}

export interface AppView extends LabeledEntity {
  broadcrumb?: Breadcrumb;
}

export interface CalendarViewInterface extends AppView {
  type: consts.CalendarViewType;
  day?: Day;
  week?: number;
  month?: number;
  year?: number;
  event?: CalEvent;
  user?: User;
}

export class CalendarView extends LabeledEntity implements CalendarViewInterface {
  type: consts.CalendarViewType;
  day?: Day;
  week?: number;
  month?: number;
  year?: number;
  event?: CalEvent;
  user?: User;
  broadcrumb?: Breadcrumb;

  static load(json: CalendarView) {
    // wszystkie obiekty nadpisujemy wartosciami ze store
    const newView = Object.assign(new CalendarView(), json);
    newView.user = usersModule.userById(json.user.id) as User;
    newView.event = Object.assign(new CalEvent(), json.event);
    if (json.type === consts.CalendarViewType.VISIT) {
      newView.event.object = visitsModule.visitById((json.event.object as Visit).id);
    }
    newView.day.visits = json.day.visits.map(v => Object.assign(new Visit(), v));
    newView.broadcrumb = json.broadcrumb;
    console.log('CalendarView::load', newView);
    return newView;
  }
}

export interface VisitViewInterface extends AppView {
  type: consts.VisitViewType;
  subtype?: number;
  visit?: Visit;
  user?: User;
  protocol?: Protocol;
  mixer?: Mixer;
  collect?: Collect;
  comment?: string;
}

export class VisitView implements VisitViewInterface {
  type: consts.VisitViewType;
  subtype?: number;
  visit?: Visit;
  user?: User;
  protocol?: Protocol;
  mixer?: Mixer;
  collect?: Collect;
}

/**
 * IFilterabe - this interface is needed when we want to use [] accessing member by name
 */
export interface Filterable {
  [propName: string]: any;
}

export interface Filter {
  field: string;
  value: string;
}

export interface Sort {
  field: string;
  dir: SortDir;
}

export interface ListParams {
  pageNo: number;
  pageSize: number;
  curPageSize: number;
  rowsAllCount: number;
  pagesAllCount: number;
  filter: Filter;
  sort: Sort;
}

export interface RouterSettings {
  last_view?: CalendarView;
}

export interface Settings {
  router_settings?: RouterSettings;
}

export class Material extends LabeledEntity implements IMaterial {
  id: number;
  name: string;
  description?: string;
  visible?: boolean; // cy pokazywac material na listach
}

export class ConcreteClassSubgroup implements IConcreteClassSubgroup {
  id: number;
  name: string;
  group_id: number;
  symbol: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.symbol = '';
    this.group_id = 0;
  }
}

export class ConcreteClassGroup extends LabeledEntity implements IConcreteClassGroup {
  id: number;
  name: string;
  symbol: string;
  subgroup_ids?: number[];
  subgroups?: IConcreteClassSubgroup[];
  description?: string;

  constructor() {
    super();
    this.id = 0;
    this.name = '';
    this.symbol = '';
    this.subgroup_ids = [];
    this.subgroups = [];
    this.description = '';
  }
  load() {
    console.log('Group load', this, this.id, this.name);
    /*
    this.subgroups = [];
    this.subgroup_ids.forEach((subgroup_id) => {
      const subgroup = dictModule.concreteClassSubgroupById(subgroup_id);
      //console.log("Subgroup", subgroup_id, subgroup);
      //this.subgroups.push(subgroup);
    });
    console.log("group after load", this);
  */
  }
}

export class ConcreteClass extends LabeledEntity implements IConcreteClass {
  id: number;
  group_id: number;
  group?: ConcreteClassGroup;
  subgroup_id?: number;
  subgroup?: ConcreteClassSubgroup;
  name: string;
  text: string;
  value?: string;
  value2?: string;
  description?: string;

  load() {
    //console.log('Class load', this, this.id, this.name);
    this.group = dictModule.concreteClassGroupById(this.group_id);
    this.group.load();
    //this.subgroup = dictModule.concreteClassSubgroupById(this.subgroup_id);
  }
}

export class ActionButton extends LabeledEntity {
  title: string;
  onclick: Function;
  icon: string;
  visible: boolean;
  disabled: boolean;
  index: number;
  rights: UserRight[];
  visitStatuses: VisitStatus[];
  outlined: boolean; // czy button jest narysowany z outline (puste tło)

  constructor(
    title?: string,
    icon?: string,
    rights: UserRight[] = [],
    visitStatuses: VisitStatus[] = [],
    outlined = false,
    visible = true,
    disabled = false
  ) {
    super();
    this.title = title;
    this.index = 0;
    //this.onclick = null;
    this.icon = icon;
    this.visible = visible;
    this.disabled = disabled;
    this.rights = rights;
    this.visitStatuses = visitStatuses;
    this.outlined = outlined;
  }

  setOnClickMethod(method: Function) {
    this.onclick = method;
  }
  hasRightToBeVisible(userToCheck: User) {
    //console.log('hasRightToBeVisible', userToCheck, this);
    let hasRight = true;
    this.rights.forEach(btnRight => {
      if (userToCheck.hasRight(btnRight) === false) {
        hasRight = false;
      }
    });
    //console.log("hasRightToBeVisible: ", this, " Checked user:", userToCheck, "result=", hasRight);
    return hasRight;
  }
  hasStatusToBeVisible(visitStatusToCheck: VisitStatus) {
    let hasStatus = false;
    this.visitStatuses.forEach(btnStatus => {
      if (visitStatusToCheck.id === btnStatus.id) {
        hasStatus = true;
      }
    });
    return hasStatus;
  }
}

export class Signature extends LabeledEntity {
  id: number;
  client_name?: string;
  phone_number?: string;
  type: consts.SignatureType;
  confirmed?: boolean;
  datetime?: string;
  user?: User;
  code?: string;
  object?: object;
  object_id?: number;
}

export class MethodUser extends LabeledEntity {
  id?: number;
  user_id?: number;
  user?: User;
  method_id?: number;
  method?: Method;
  from_date?: string;
  date_type?: string;

  load() {
    this.user = usersModule.userById(this.user_id);
    //this.method = methodsModule.methodById(this.method_id)
  }
}

export class MethodGroup {
  id: number;
  name: string;
  symbol?: string;
}

export class Method extends DashboardEntity implements IMethod {
  name?: string;
  description?: string;
  norm?: string;
  add_date_time?: string;
  status_id: number;
  status?: consts.MethodStatus;
  group_id?: number;
  group?: MethodGroup;
  unit?: string;
  price?: number;
  limit?: number;
  acreditation?: boolean;
  actual?: boolean;
  default_destiny_group_id?: number;
  protocol_destiny_groups?: string;
  results_doc_destiny_groups?: string;
  default_destiny_id?: number;
  default_destiny?: Destiny;

  created_date_time?: string;
  changed_date_time?: string;

  method_user_ids?: number[];
  method_users?: MethodUser[];

  stages?: MethodStage[];
  params?: MethodStageParam[];
  tools?: MethodStageTool[];

  constructor() {
    super();
    this.id = 0;
    this.status_id = consts.MethodStatus.NEW.id;
    this.status = consts.MethodStatus.NEW;
    this.name = '';
    this.group_id = -1;
    this.norm = '';
    this.actual = false;
    this.acreditation = false;
    this.method_users = [];
    this.default_destiny_id = -1;
  }

  get getNumber() {
    return this.number;
  }
  set setNumber(value: string) {
    this.number = value;
  }
  get getStatus() {
    return this.status;
  }
  set statusId(id: number) {
    this.status_id = id;
    this.status = consts.MethodStatus.byId(id);
  }

  get getDONEActionStatus(): Enumify {
    return consts.MethodActionStatus.DONE;
  }
  async update() {
    this.load();
    await methodsModule.updateMethod(this);
  }
  load() {
    this.group = dictModule.methodGroupById(this.group_id);
    this.status = consts.MethodStatus.byId(this.status_id);
    this.default_destiny = dictModule.destinyById(this.default_destiny_id);
    this.method_users = [];
    this.method_user_ids.forEach(uId => {
      this.method_users.push(methodsModule.methodUserById(uId));
    });
    this.stages = methodsModule.methodStagesByMethodId(this.id);
    this.stages.forEach(s => {
      const stage = Object.assign(new MethodStage(), s);
      stage.load();
    });
  }
}

export class CollectMethod extends LabeledEntity {
  id: number;
  method_id?: number;
  collect_id?: number;
}

export class Car extends LabeledEntity {
  id: number;
  number: string;
  register_number?: string;
  number_registerNumber?: string;
  default_user_id?: number;
  default_user?: User;
}
export class ToolGroup extends LabeledEntity {
  id: number;
  name: string;
  index?: number;
}
export class Tool extends DashboardEntity implements ITool {
  name?: string;
  group_id?: number;
  group?: ToolGroup;
  description?: string;
  localization_id?: number;
  localization?: Localization;
  add_date_time?: string;
  status_id: number;
  status?: consts.ToolStatus;
  fabric_number?: string;
  invent_number?: string;
  car_id?: number;
  car?: Car;
  params?: ToolParam[];

  created_date_time?: string;
  changed_date_time?: string;

  constructor() {
    super();
    this.id = 0;
    this.status_id = consts.ToolStatus.NEW.id;
    this.status = consts.ToolStatus.NEW;
    this.name = '';
    this.localization_id = -1;
    this.group_id = -1;
    this.car_id = -1;
    this.fabric_number = '';
    this.invent_number = '';
    this.params = [];
  }

  get getNumber() {
    return this.invent_number;
  }
  set setNumber(value: string) {
    this.invent_number = value;
  }
  get getDONEActionStatus(): Enumify {
    return consts.ToolActionStatus.DONE;
  }
  get getStatus() {
    return this.status;
  }
  set statusId(id: number) {
    this.status_id = id;
    this.status = consts.ToolStatus.byId(id);
  }
  async update() {
    await toolsModule.updateTool(this);
  }

  load() {
    //console.log('Tool load', this);
    this.group = dictModule.toolGroupById(this.group_id);
    this.status = consts.ToolStatus.byId(this.status_id);
    this.localization = dictModule.localizationById(this.localization_id);
    this.car = toolsModule.carById(this.car_id);
    this.params = toolsModule.toolParamsByToolId(this.id);
  }
}

export class ToolParam {
  id: number;
  name: string;
  symbol?: string;
  formula?: string;
  description?: string;
  tool_id?: number;
}

export class DashboardAction extends LabeledEntity implements IDashboardAction {
  id: number;
  index: number;
  from_status_id: number;
  to_status_id: number;
  comment?: string;
  dashboard_item_id: number;
  dashboard_item: DashboardItem;
  action_date_time: string;
  new_status_person_id?: number;
  new_status_person?: User;
  action_person_id?: number;
  action_person?: User;

  constructor() {
    super();
    this.id = 0;
    this.index = 0;
    this.from_status_id = -1;
    this.to_status_id = -1;
    this.comment = '';
    this.dashboard_item_id = -1;
    this.dashboard_item = null;
    this.new_status_person_id = -1;
    this.new_status_person = null;
    this.action_person_id = -1;
    this.action_person = null;
  }
  load(dashboardItem: DashboardItem) {
    console.log('DashboardAction load', dashboardItem);
    this.new_status_person = usersModule.userById(this.new_status_person_id);
    this.dashboard_item = dashboardItemsModule.dashboardItemById(this.dashboard_item_id);
  }
}

export class DashboardColumn {
  title: string;
  statusId: number;
  status: Enumify;
  index: number;
  fullIndex: string;
  editable: boolean;
  items: DashboardItem[];

  constructor(status: Enumify = new Enumify(), index: number, editable = false) {
    this.title = status['name'];
    this.statusId = status['id'];
    this.status = status;
    this.index = index;
    this.editable = editable;
  }
}

export class DashboardPerson extends LabeledEntity {
  id: number;
  person_id: number;
  person?: User;
  dashboard_item_id?: number;
  dashboard_item?: DashboardItem;
  required?: boolean;
  status_id?: number;
  status?: Enumify;
  done: boolean;

  constructor() {
    super();
    this.id = 0;
    this.person_id = 0;
    this.person = null;
    this.dashboard_item_id = 0;
    this.dashboard_item = null;
    this.required = false;
    this.status_id = 0;
    this.status = null;
    this.done = false;
  }
}

export class DashboardItem extends LabeledEntity implements IDashboardItem {
  id: number;
  object_id: number; // id obiektu czyli. tool, method, user itd
  object?: DashboardEntity; // obiekt ktorego typ poznamy pozniej, tool, method, use itd
  object_type_id: number; // DashboardActionTypes
  object_type?: consts.DashboardObjectTypes;
  operation_type_id: number; //  EDYCJA, SERWIS
  operation_type?: Enumify;
  status_id: number; // ZAINICJOWANE, W TRAKCIE, UKONCZONE... ToolActionStatus, MethodActionStatus...
  status?: Enumify;
  start_date_time: string;
  end_date_time?: string;
  person_initiated_id?: number; // osoba ktora zainicjowala akcje
  person_initiated?: User;
  enabled: boolean;
  comment?: string; // zbiorczy koment wszystkich akcji

  index?: number;
  columnStatusId?: number;
  objectTypeName?: string;

  constructor() {
    super();
    this.id = 0;
    this.start_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
    this.object_type_id = -1;
    this.operation_type_id = -1;
    this.status_id = -1;
    this.end_date_time = '';
    this.person_initiated_id = -1;
    this.enabled = true;
  }

  load() {
    console.log('Loading DashboardItem', this.id, this.object_type_id);
    if (this.object_type_id < 0 || this.operation_type_id < 0 || this.status_id < 0) {
      return;
    }
    switch (this.object_type_id) {
      case consts.DashboardObjectTypes.METHOD: {
        this.objectTypeName = 'Metoda badawcza';
        this.object = methodsModule.methodById(this.object_id);
        console.log('Loading METHOD', this.object_id, this.object);
        (this.object as Method).load();
        this.operation_type = consts.MethodOperationType.byId(this.operation_type_id);
        this.status = consts.MethodActionStatus.byId(this.status_id);
        break;
      }
      case consts.DashboardObjectTypes.TOOL: {
        //console.log("Loading TOOL", this.object_id);
        this.objectTypeName = 'Urządzenie badawcze';
        this.object = toolsModule.toolById(this.object_id);
        (this.object as Tool).load();
        this.operation_type = consts.ToolOperationType.byId(this.operation_type_id);
        this.status = consts.ToolActionStatus.byId(this.status_id);
        break;
      }
      case consts.DashboardObjectTypes.PERSONEL: {
        //console.log("Loading PERSONEL", this.object_id);
        this.objectTypeName = 'Pracownik';
        this.object = usersModule.userById(this.object_id);
        (this.object as User).load(false);
        this.operation_type = consts.PersonelOperationType.byId(this.operation_type_id);
        this.status = consts.PersonelActionStatus.byId(this.status_id);
        break;
      }
      case consts.DashboardObjectTypes.OFFER: {
        this.objectTypeName = 'Oferta';
        this.object = offerModule.offerActionById(this.object_id);
        this.operation_type = consts.OfferOperationType.byId(this.operation_type_id);
        (this.object as OfferAction).load();
        this.status = consts.OfferActionStatus.byId(this.status_id);
        break;
      }
      case consts.DashboardObjectTypes.SUPERVISION: {
        this.objectTypeName = 'Nadzór';
        this.object = dataModule.docByTypeAndId(this.object_id, consts.DashboardObjectTypes.SUPERVISION);
        this.operation_type = consts.CertOperationType.byId(this.operation_type_id);
        this.status = consts.CertActionStatus.byId(this.status_id);
        break;
      }
      case consts.DashboardObjectTypes.CERT: {
        this.objectTypeName = 'Certyfikacja';
        this.object = dataModule.docByTypeAndId(this.object_id, consts.DashboardObjectTypes.CERT);
        this.operation_type = consts.CertOperationType.byId(this.operation_type_id);
        this.status = consts.CertActionStatus.byId(this.status_id);
        break;
      }
      case consts.DashboardObjectTypes.AUDIT: {
        this.objectTypeName = 'Audit';
        this.object = dataModule.docByTypeAndId(this.object_id, consts.DashboardObjectTypes.AUDIT);
        this.operation_type = consts.CertOperationType.byId(this.operation_type_id);
        this.status = consts.AuditActionStatus.byId(this.status_id);
        break;
      }
      case consts.DashboardObjectTypes.DOCUMENT: {
        this.objectTypeName = 'Dokument';
        this.object = dataModule.docByTypeAndId(this.object_id, consts.DashboardObjectTypes.DOCUMENT);
        this.operation_type = consts.CertOperationType.byId(this.operation_type_id);
        this.status = consts.DocumentActionStatus.byId(this.status_id);
        break;
      }
      default: {
        console.log('Loading DEFAULT', this.object_id);
        break;
      }
    }
    this.person_initiated = usersModule.userById(this.person_initiated_id);
  }
}

export class Message extends LabeledEntity implements IMessage {
  id: number;
  title: string;
  content: string;
  link?: string;
  link_text?: string;
  content_after_link?: string;
  content_before_link?: string;
  created_date_time: string;
  group_id: number; // 0 - BADANIA, 1 - METROLOGIA
  message_type: number; // 0 - message, 1 - warning, 2 - error
  sender_id?: number; // jezeli to jest wiadomosc systemowa - Null
  receiver_id: number;
  hash?: string;
  object_id?: number;
  object_type_id?: number;
  is_read?: boolean;
  type: number; // 0 - systemowa 1 - od uzytkownika

  create_date_time: string;
  changed_date_time: string;
}

export class File extends LabeledEntity implements IFile {
  id: number;
  filename: string;
  fullpath?: string;
  uploading?: boolean;
  created_date_time?: string;
  description?: string;
  user_id?: number; // id usera ktory wgral plik
  group_id?: number; // opis urzadzenia, swiadectwo zdrowia itd
}

export class CertificationDocument extends DashboardEntity implements IHasFileContainer {
  id: number;
  description: string;
  name: string;
  number: string;
  files: IFile[];
  guid?: string;
}

export class ResultsDoc extends LabeledEntity implements IResultsDoc {
  id?: number;
  number: string;
  department_id: number;
  get_person_text?: string;
  get_person_id: number;
  rule_text?: string;
  rule_id: number;
  header_description?: string;
  results_description?: string;
  uncert_tool_id?: number;
  uncert_tool?: Tool;
  exam_id: number;
  exam?: Exam;
  client_id: number;
  client?: Client;
  rd_type: consts.ResultsDocType;
  filename: string;
  uncertainty?: string;
  destiny?: Destiny;
  destiny_id?: number;
  created: string;
  modified: string;

  load() {
    if (this.exam === undefined) {
      this.exam = examsModule.allExams.find(el => el.id === this.exam_id);
    }
    if (this.client === undefined) {
      this.client = dictModule.clients.find(el => el.id === this.client_id);
    }
    if (this.uncert_tool === undefined) {
      this.uncert_tool = toolsModule.toolById(this.uncert_tool_id);
    }
    this.destiny = dictModule.destinyById(this.destiny_id);
  }
}

export class UserData extends LabeledEntity implements IUserData {
  user?: User;
  user_id: number;
  client?: Client;
  client_id?: number;
  client_code?: string;
  initials?: string;
  phone?: string;
  position?: string;
  module?: string;
  status_id: number;
  calendar_index: number;
  changed_date_time: string;
  create_date_time: string;

  load() {
    if (this.user === undefined) {
      this.user = usersModule.userById(this.user_id);
    }
    if (this.client === undefined) {
      this.client = dictModule.clientById(this.client_id);
    }
  }
}

export class DocumentTitles extends LabeledEntity {
  typeId: number;
  title: string;
  secondTitle: string;
  statusTitle: string;
  descriptionTitle: string;
  numberSeparator: string;
  status: any;
  dateLabel: string;
}

export class GenericDocument extends DashboardEntity implements IHasFileContainer {
  id: number;
  description: string;
  name: string;
  number: string;
  files: IFile[];
  guid?: string;
  date?: string;
  type?: number;
  internal?: boolean;
  entity?: number; // id of consts.AuditEntity;
  additionals1: string;
  additionals2: string;
  additionals3: string;

  changed_date_time: string;
  create_date_time: string;

  titles: DocumentTitles;

  load() {
    const documentTitles: DocumentTitles[] = [
      {
        __type: 'DocumentTitles',
        typeId: 7,
        title: 'Audit numer:',
        secondTitle: 'Nazwa firmy',
        descriptionTitle: 'Opis auditu',
        statusTitle: 'Status auditu',
        dateLabel: 'Data stworzenia auditu',
        status: consts.AuditActionStatus,
        numberSeparator: 'AUDIT',
      },
      {
        __type: 'DocumentTitles',
        typeId: 6,
        title: 'Certyfikacja',
        secondTitle: 'Nazwa firmy',
        descriptionTitle: 'Opis procesu inspekcji',
        statusTitle: 'Status certyfikacji',
        numberSeparator: 'CERT',
        dateLabel: 'Data ponownej inspekcji',
        status: consts.CertActionStatus,
      },
      {
        __type: 'DocumentTitles',
        typeId: 9,
        title: 'Nadzór',
        secondTitle: 'Nazwa firmy',
        descriptionTitle: 'Opis procesu inspekcji',
        statusTitle: 'Status certyfikacji',
        numberSeparator: 'NADZ',
        dateLabel: 'Data ponownej inspekcji',
        status: consts.CertActionStatus,
      },
      {
        __type: 'DocumentTitles',
        typeId: 8,
        title: 'Dokument numer:',
        secondTitle: 'Skrócony opis dokumentu',
        descriptionTitle: 'Opis dokumentu',
        numberSeparator: 'DOC',
        statusTitle: 'Status dokumentu',
        dateLabel: 'Data stworzenia dokumentu',
        status: consts.DocumentActionStatus,
      },
    ];

    const documentTitlesMap = new Map();
    documentTitles.forEach(el => {
      documentTitlesMap[el.typeId] = el;
    });

    this.titles = documentTitlesMap[this.type];
  }
}

export class Department extends LabeledEntity implements IDepartment {
  id: number;
  name: string;
  changed?: string;
  create?: string;
}

export interface ModalChoosePersonData {
  question?: string;
  rightToFilter: consts.UserRightSymbols;
}
