








import { Component, Vue } from 'vue-property-decorator';
import ExamCard from '../../../views/laborant/exams/ExamCard.vue';
import ExamCardF from '../../../views/laborant/exams/ExamCardF.vue';
import ExamCardW from '../../../views/laborant/exams/ExamCardW.vue';
import VueHtml2pdf from 'vue-html2pdf';
import { Exam, ResultsDoc } from '../../../types';
import { saveResultsDocPdf } from '../../../store/api';
import examsModule from '../../../store/modules/exams';
import examModule from '../../../store/modules/exam';
import userModule from '../../../store/modules/user';
import resultsDocModule from '../../../store/modules/results_doc';
import dictModule from '../../../store/modules/dict';
import * as helpers from '../../../helpers';
import { AxiosResponse } from 'axios';
import * as consts from '../../../consts';
import ordersModule from '../../../store/modules/orders';
import visitsModule from '../../../store/modules/visits';
import moment from 'moment';

@Component({
  components: { ExamCard, ExamCardF, ExamCardW },
})
export default class ExamCardView extends Vue {
  exam: Exam = null;
  F_DEST = 3;
  C_DEST = 1;
  W_DEST = 2;
  SW_DEST = 5;
  NOT_LOADED_EXAM = -1;

  name: string;
  examCard: ExamCard;

  get visit() {
    return visitsModule.visitById(this.exam.protocol.visit_id);
  }
  get client() {
    return dictModule.clientById(this.exam.client_id);
  }
  get cplaceSymbol() {
    return dictModule.cPlaceById(this.visit.cplace_id).symbol;
  }
  get clientAddress() {
    return (
      this.order.address.street_and_house_no + ' ' + this.order.address.postal_code + ' ' + this.order.address.city
    );
  }
  get order() {
    const o = ordersModule.orderById(this.visit.order_id);
    o.load();
    return o;
  }
  isNotFinished(status: number) {
    return status !== consts.ExamStatus.FINISHED.id;
  }

  mounted() {
    const exam_id = parseInt(this.$route.params['exam_id']);
    console.log('exam_id', exam_id);
    this.exam = examsModule.examById(exam_id);
    this.exam.load();
  }

  get currentDestiny() {
    if (this.exam !== null) {
      return this.exam.destiny.group_id;
    } else {
      return this.NOT_LOADED_EXAM;
    }
  }

  async authorise(e: { examCard: ExamCard; vueHtml2pdf: VueHtml2pdf }) {
    console.log('ExamCardView::authorise', e);

    e.examCard.authModalSpin = true;
    this.name = e.vueHtml2pdf.filename;
    this.examCard = e.examCard;
    this.examCard.isAuthorised = true;
    this.examCard.exam.is_authorised = true;
    this.examCard.exam.person_authorised = userModule.wholeUser;
    this.examCard.saveExam();
    examsModule.replaceExam(this.examCard.exam);

    this.examCard.userAuthorised = this.examCard.exam.person_authorised.fullName;
    this.$forceUpdate();

    e.vueHtml2pdf.enableDownload = false;
    e.vueHtml2pdf.$once('beforeDownload', this.savePdfToServer); // authorise
    e.vueHtml2pdf.generatePdf();
    e.vueHtml2pdf.enableDownload = true;
    helpers.info('Zautoryzowano!');
  }

  getRDFilename(rd_counter) {
    const examEndDateTime = !this.isNotFinished(this.exam.status)
      ? moment(this.exam.end_date_time, consts.DB_DATE_TIME_FORMAT)
      : moment();
    return (
      `Swiadectwo_badania_OsEN` +
      (this.order.client_type === consts.CLIENT_PRODUCER ? '' : 'C') +
      '_' +
      this.client.code.trim() +
      this.cplaceSymbol.trim() +
      examEndDateTime.format('_DDMM_YYYY') +
      '_' +
      rd_counter +
      '.pdf'
    );
  }
  getRDNumber(rd_counter) {
    const examEndDateTime = !this.isNotFinished(this.exam.status)
      ? moment(this.exam.end_date_time, consts.DB_DATE_TIME_FORMAT)
      : moment();
    return (
      'OsEN' +
      (this.order.client_type === consts.CLIENT_PRODUCER ? '' : 'C') +
      '/' +
      this.client.code.trim() +
      this.cplaceSymbol.trim() +
      examEndDateTime.format('/DDMM/YYYY') +
      '/' +
      rd_counter
    );
  }

  async savePdfToServer({ html2pdf, options, pdfContent }) {
    console.log('savePdfToServer');
    const resultsDoc = this.examCard.exam.results_doc as ResultsDoc;
    console.log('resultsDoc', resultsDoc);
    const checkResponse = (res: AxiosResponse): boolean => {
      if (res.data.success === true) {
        this.examCard.hideAuthModal();
      }
      return res.data.success;
    };
    let response: AxiosResponse;

    response = await dictModule.getClientRDCounter(this.examCard.exam.client_id);
    if (checkResponse(response) === false) {
      console.log('getClientRDCounter error', response);
      return;
    }
    const rd_counter = response.data.rd_counter;

    if ([undefined, ''].includes(resultsDoc.number) === true) {
      resultsDoc.number = this.getRDNumber(response.data.rd_counter);
      console.log('2 resultsDoc.number=', resultsDoc.number);
    }

    response = await examModule.authoriseExam(this.examCard.exam);
    if (checkResponse(response) === false) {
      console.log('authoriseExam error', response);
      return;
    }
    resultsDoc.destiny_id = this.examCard.exam.destiny.id;
    resultsDoc.client_id = this.examCard.exam.client.id;
    resultsDoc.rd_type = consts.ResultsDocType.FROM_KPP;
    console.log('authoriseExam done');

    if ([undefined, ''].includes(resultsDoc.filename) === true) {
      resultsDoc.filename = this.getRDFilename(rd_counter);
    }
    response = await resultsDocModule.saveResultsDoc(resultsDoc);
    if (checkResponse(response) === false) {
      console.log('saveResultsDoc error', response);
      return;
    }
    console.log('saveResultsDoc response', response);
    resultsDoc.id = response.data.rd_id;
    console.log('saveResultsDoc done', JSON.parse(JSON.stringify(resultsDoc)));

    const pdfBlob = await html2pdf().set(options).from(pdfContent).outputPdf('blob');
    const formData = new FormData();

    formData.append('filename', resultsDoc.filename);
    formData.append('pdf', pdfBlob);

    console.log('before saveResultsDocPdf', formData);
    response = await saveResultsDocPdf(formData);
    if (checkResponse(response) === false) {
      console.log('saveResultsDocPdf error', response);
      return;
    }

    this.examCard.hideAuthModal();
    console.log('Authorise DONE');
    helpers.info('Autoryzacja wykonana.');
  }
}
