













































































































































































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, User, Client, ConstructionPlace } from '../../types';
import dictModule from '../../store/modules/dict';
import usersModule from '../../store/modules/users';
import moment from 'moment';
import * as helpers from '../../helpers';
import validator from 'validator';

@Component
export default class ModalClient extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'xl' }) size;
  helpers = helpers;

  show = false;
  new = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  comment = '';
  btnSave = 0;
  client: Client = null;
  orginalClient: Client = null;
  cplaces: ConstructionPlace[] = [];

  users: User[] = [];
  choosenUsers = [];
  userValue = null;

  nameWithLastname({ firstname, lastname }) {
    return `${firstname} ${lastname}`;
  }

  get getCPlaces() {
    this.revalidate;
    return this.cplaces;
  }

  fillCPlaces(client: Client) {
    this.cplaces = dictModule.cPlacesByClientId(client.id);
    this.cplaces.forEach((cp, index) => {
      cp['edit'] = false;
      cp['index'] = index;
      cp['users'] = [];
      cp['users_ids'].forEach(u_id => {
        cp['users'].push(usersModule.userById(u_id));
      });
    });
  }

  fillUsers(client: Client) {
    this.users = usersModule.users.filter(user => user.client_id === client.id);
    this.users.forEach((u, index) => {
      u['index'] = index;
    });
  }

  userSelected(e: any) {
    this.userValue = this.users.find(u => u.id === e.id);
  }
  addUser(index) {
    if (this.cplaces[index].users.includes(this.userValue)) {
      helpers.error('Wybrana osoba znajduje się już na liście!');
    } else {
      this.cplaces[index].users.push(this.userValue);
    }
  }

  deselectUser(user, index) {
    this.cplaces[index].users = this.cplaces[index].users.filter(u => u.id != user.id);
  }

  truncate(value: any, max: number) {
    if (value.toString().length > max) {
      return parseInt(value.toString().slice(0, max));
    }
    return value;
  }

  showModal(client: Client = null) {
    console.log('Show modal ', client);
    this.show = true;
    this.new = client === null;
    if (this.new) {
      this.client = new Client();

      let maxCode = 0;
      dictModule.clients.forEach(c => {
        const num: number = parseInt(c.code.replace(/[^0-9]/g, ''));
        if (num > maxCode) {
          maxCode = num;
        }
      });
      this.client.short_name = '';
      this.client.name = '';
      this.client.code = (maxCode + 1).toString();
      this.client.address.city = '';
      this.client.address.street_and_house_no = '';
      this.client.address.postal_code = '';
      this.client.address.correspond = '';
      this.client.nip = '';
      this.client.active = true;
      this.client.contact_person_name = '';
      this.client.contact_email = '';
      this.client.contact_phone = '';
      this.cplaces = [];
    } else {
      this.client = Object.assign(new Client(), client);
      this.client.load();
      this.orginalClient = client;
      this.fillCPlaces(this.client);
      this.fillUsers(this.client);
    }
    console.log('showModal', this.client, this.new);
  }
  async saveModal() {
    if (this.client.short_name.length === 0) {
      helpers.error('Proszę wypełnić skróconą nazwę klienta');
      this.btnSave++;
      return;
    }
    if (this.client.name.length === 0) {
      helpers.error('Proszę wypełnić nazwę klienta klienta');
      this.btnSave++;
      return;
    }
    if (this.client.code.length === 0) {
      helpers.error('Proszę wypełnić kod klienta');
      this.btnSave++;
      return;
    }

    if (this.client.nip.length !== 0 && !helpers.isValidNip(this.client.nip)) {
      helpers.error('Niepooprawny NIP !');
      this.btnSave++;
      return;
    }

    if (this.client.contact_email.length !== 0 && !validator.isEmail(this.client.contact_email)) {
      helpers.error('Niepoprawny adres email !');
      this.btnSave++;
      return;
    }

    if (this.client.address.postal_code.length !== 0 && !helpers.isValidPostalCode(this.client.address.postal_code)) {
      helpers.error('Niepoprawny kod pocztowy !');
      this.btnSave++;
      return;
    }

    this.save = true;
    this.client['save'] = true;
    this.client['new'] = this.new;
    if (this.new) {
      this.client.create_date_time = moment().format(consts.DATE_TIME_FORMAT);
    }
    this.client.changed_date_time = moment().format(consts.DATE_TIME_FORMAT);
    this.client['cplaces'] = this.cplaces;

    console.log('saveModal', this.client);
    let response = null;
    if (this.client['new'] === true) {
      response = await dictModule.addClient(this.client);
    } else {
      response = await dictModule.updateClient(this.client);
    }
    if (response.data.success === false && response.data.error_code === 1) {
      console.log('Code already existed');
      helpers.error('Podany KOD klienta został już wykorzystany, użyj innego');
      this.btnSave++;
      return;
    }
    if (response.data.success) {
      const message = this.new ? 'Klient dodany' : 'Dane klienta zapisane';
      helpers.info(message);
    }
    this.client.id = response.data.client['id'];
    this.$emit(consts.ModalEvents.OK_EVENT, this.client);
    this.show = false;
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
  addCPlace() {
    console.log('Add cplace');
    const cplace = new ConstructionPlace();
    cplace.name = ' ';
    cplace.short_name = ' ';
    cplace.symbol = ' ';
    cplace.active = true;
    cplace['index'] = this.cplaces.length;
    this.cplaces.push(cplace);
  }
  removeCPlace(e: any, index: number) {
    console.log('remove', index);
    this.cplaces.splice(index, 1);
  }
  changeCPlace(e: any, index: number) {
    console.log('change cplace', this.cplaces[index]);
    this.cplaces[index]['edit'] = !this.cplaces[index]['edit'];
    console.log('change cplace', this.cplaces[index]['edit']);
    this.revalidate++;
  }
}
