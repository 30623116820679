

































import { Component, Vue, Prop, Ref, Emit } from "vue-property-decorator";
import { ModalEvents } from "../../consts";

@Component
export default class ModalQuestion extends Vue {
  @Prop({ type: String, required: false, default: "" }) title;
  @Prop({ type: String, required: false, default: "" }) question;
  @Prop({ type: String, required: false, default: "" }) questionClass;
  @Prop({ type: String, required: false, default: "" }) titleClass;
  @Prop({ type: String, required: false, default: "md" }) size;

  private show = false;
  private events: [ModalEvents.OK_EVENT, ModalEvents.CANCEL_EVENT];
  private dataObj = null;

  questionValue = "";

  @Emit()
  modalClose() {
    return 1;
  }

  setQuestionValue() {
    console.log("Question=", this.question);
    if (this.question === undefined || this.question === null || this.question === "") {
      this.questionValue = this.dataObj.question;
    } else {
      this.questionValue = this.question;
    }
  }

  showModal(dataObj: any) {
    this.dataObj = dataObj;
    console.log("DataObj=", dataObj);
    this.setQuestionValue();
    this.show = true;
  }
  saveModal() {
    console.log("Emit dataObj", this.dataObj);
    this.$emit(ModalEvents.OK_EVENT, this.dataObj);
    this.show = false;
  }
  hideModal() {
    this.$emit(ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
  created() {
    this.questionValue = "";
  }
}
