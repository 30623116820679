





























































































































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import * as consts from '../../../consts';
import moment from 'moment';

import VisitProtocolPIBPrint from '../../../views/laborant/visits/VisitProtocolPIBPrint.vue';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import ModalResultsDocFilter from '../../../components/spec/ModalResultsDocFilter.vue';
import ModalResultsDoc from '../../../components/spec/ModalResultsDoc.vue';

import breadcrumbModule from '../../../store/modules/breadcrumb';
import userModule from '../../../store/modules/user';
import collectsModule from '../../../store/modules/collects';
import mixersModule from '../../../store/modules/mixers';
import samplesModule from '../../../store/modules/samples';
import visitsModule from '../../../store/modules/visits';
import visitModule from '../../../store/modules/visit';
import protocolsModule from '../../../store/modules/protocols';
import dictModule from '../../../store/modules/dict';
import examsModule from '../../../store/modules/exams';
import resultsDocModule from '../../../store/modules/results_doc';
import ordersModule from '../../../store/modules/orders';
import { Visit, ConstructionPlace } from '../../../types';
import { EventBus, BusEvents } from '../../../helpers/eventbus';
import ResultsDocCPrint from '../../../views/laborant/exams/ResultsDocCPrint.vue';

import { SortDir, DATE_FORMAT, DB_T_DATE_TIME_FORMAT, DB_DATE_FORMAT, UserModule } from '../../../consts';
import { Exam, User, CollectAndExamProtocol, Sample, Client } from '../../../types';
import { types } from 'util';

@Component({
  components: {
    TopPanel,
    Header,
    Footer,
    ModalResultsDocFilter,
    ModalResultsDoc,
    VisitProtocolPIBPrint,
    ResultsDocCPrint,
  },
})
export default class LaborantResultsDocsList extends Vue {
  @Ref() readonly modalResultsDocFilter: ModalResultsDocFilter;
  @Ref() readonly modalResultsDoc: ModalResultsDoc;

  btnPrevVisible = false;
  btnNextVisible = true;
  isClient = false;
  enabled = true;

  pageSize = 20;
  pageNo = 1;
  allPagesCount = 0;
  filterParams: { field: string; value: any }[] = [];
  sortParams = { field: 'number', dir: SortDir.ASC };
  spinner = false;
  exams: Exam[] = [];
  protocols: CollectAndExamProtocol[] = [];
  prot_selected = false;

  log(idx) {
    console.log('iter', idx);
  }

  expanded: Array<boolean> = [];
  expandProt(index: number) {
    this.expanded[index] = !this.expanded[index];
    console.log('Expand ', index, this.expanded[index]);
    this.$forceUpdate();
  }

  currentCPlace = {}; // wybrana budowa
  cPlaces = []; // budowy klienta
  selectCPlace(e: KeyboardEvent) {
    console.log('selectCPlace', e);
    // wybrana budowa
    this.currentCPlace = e;
    this.pageNo = 1;
    this.fillProtocols();
  }

  get getFiltersFieldValue() {
    return field => {
      let retVal = null;
      this.filterParams.forEach(filter => {
        if (filter.field === field) {
          retVal = filter.value;
        }
      });
      return retVal;
    };
  }

  get clientName() {
    let name = '<brak wyboru>';
    const client_id = this.getFiltersFieldValue('client_id');
    if (client_id) {
      const client: Client = dictModule.clientById(client_id);
      if (client) {
        name = client.name;
      }
    }
    return name;
  }
  get clientNameByProt() {
    return prot => {
      let name = '<brak wyboru>';
      const visit = visitsModule.visitById(prot.visit_id);
      const client_id = visit.client_id;
      if (client_id) {
        const client: Client = dictModule.clientById(client_id);
        if (client) {
          name = client.name;
        }
      }
      return name;
    };
  }
  get cplaceName() {
    let name = '<brak wyboru>';
    const cplace_id = this.getFiltersFieldValue('cplace_id');
    if (cplace_id) {
      const cplace: ConstructionPlace = dictModule.cPlaceById(cplace_id);
      if (cplace) {
        name = cplace.name;
      }
    }
    return name;
  }
  get cplaceNameByProt() {
    return prot => {
      let name = '';
      const visit = visitsModule.visitById(prot.visit_id);
      const cplace_id = visit.cplace_id;
      if (cplace_id) {
        const cplace: ConstructionPlace = dictModule.cPlaceById(cplace_id);
        if (cplace) {
          name = cplace.name;
        }
      }
      return name;
    };
  }

  initializeVisit(prot) {
    this.startTime('initializeVisit');
    const editVisit = visitsModule.visitCopyById(parseInt(prot.visit_id));
    console.log('PDF Visit', editVisit);
    console.log('%cVisit from vuex visits', 'color:red');
    (editVisit as Visit).load();
    console.log('Visit loaded', editVisit);
    visitModule.setVisit(editVisit);
    this.endTime('initializeVisit');
  }

  pdf(prot) {
    this.prot_selected = true;
    this.initializeVisit(prot);
    this.$forceUpdate();
    console.log('PDF');
    this.$nextTick(() => {
      EventBus.$emit(BusEvents.PRINT_PDF);
    });
  }

  isProtDownloaded(prot) {
    const visit = visitsModule.visitById(prot.visit_id);
    return visit.is_prot_downloaded;
  }

  isRdDownloaded(rd) {
    if (rd.exam_id !== null) {
      const exam = examsModule.examById(rd.exam_id);
      return exam.is_rd_downloaded;
    } else {
      return true;
    }
  }

  get resultsDocs() {
    return prot => {
      const protSamples = this.protSamples(prot);
      const examsId = [];
      protSamples.forEach(sample => {
        //console.log('Sample', sample.number, sample.exam_id);
        if (sample.exam_id && !examsId.includes(sample.exam_id)) {
          //console.log('sample exam_id:', sample.exam_id);
          examsId.push(sample.exam_id);
        }
      });
      //const examsId = examsModule.exams.filter(e => e.protocol_id === prot.id).map(x => x.id);
      //console.log('Exams_id', examsId);

      let rds = resultsDocModule.resultsDocs.filter(rd => {
        //console.log('rd.exam_id', rd.exam_id);
        return examsId.includes(rd.exam_id);
      });
      let result = true;
      const filteredRds = rds.filter(rd => {
        result = true;
        this.filterParams.forEach(filter => {
          if (result) {
            if (filter.field === 'results_doc_number') {
              if (filter.value !== '') {
                result = rd.number.includes(filter.value);
                return result;
              } else {
                return result;
              }
            }
            if (filter.field === 'rdStatus') {
              if (filter.value !== '') {
                result = this.examStatus(rd).name === filter.value;
                return result;
              } else {
                return result;
              }
            }
            if (filter.field === 'resultsDoc_date_from') {
              if (filter.value !== '') {
                result = moment(rd.created, DB_DATE_FORMAT).isSameOrAfter(moment(filter.value, DB_DATE_FORMAT));
                return result;
              } else {
                return result;
              }
            }
            if (filter.field === 'resultsDoc_date_to') {
              if (filter.value !== '') {
                result = moment(rd.created, DB_DATE_FORMAT).isSameOrBefore(moment(filter.value, DB_DATE_FORMAT));
                return result;
              } else {
                return result;
              }
            }
            if (filter.field === 'method') {
              if (filter.value !== '') {
                result = this.getDestinyNorm(rd.destiny).includes(filter.value);
                return result;
              } else {
                return result;
              }
            } else {
              return result;
            }
          }
        });
        return result;
      });
      rds = filteredRds;

      rds.forEach(rd => {
        rd.load();
        rd.number = rd.number.replace('_', '/');
      });
      //console.log('rds', rds.length, rds);
      return rds;
    };
  }

  get examStatus() {
    return rd => {
      const exam = examsModule.examById(rd.exam_id);
      let status = new consts.ExamStatus(null, '-', '', 'dark');
      if (this.isAuthorised(exam.id)) {
        status.name = consts.ExamStatusAuthorised.NAME;
        status.badge = consts.ExamStatusAuthorised.BADGE;
      } else {
        status = exam.status !== undefined ? consts.ExamStatus.byId(exam.status - 1) : status;
        //console.log(rd, { status });
      }
      return status;
    };
  }

  getRdNumber(rd, prot) {
    //console.log('GetRDNumber', prot.id);
    const exam = examsModule.exams.find(e => e.id === rd.exam_id);
    const endDate = moment(exam.end_date_time, consts.DB_DATE_TIME_FORMAT).format('/DDMM/YYYY');
    const visit = visitsModule.visitById(prot.visit_id);
    const order = ordersModule.orderById(visit.order_id);
    const selCPlace: ConstructionPlace = dictModule.cPlaceById(visit.cplace_id);
    const client: Client = dictModule.clientById(visit.client_id);
    return (
      'OsEN' +
      (order.client_type === consts.CLIENT_PRODUCER ? '' : 'C') +
      '/' +
      client.code.trim() +
      selCPlace.symbol.trim() +
      endDate
    );
  }

  getRdLink(rd) {
    //console.log('getRDLink');
    const exam = examsModule.exams.find(e => e.id === rd.exam_id);
    const endDate = moment(exam.end_date_time, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    const currDate = moment(endDate, 'DD_MM_YYYY');
    this.$router.push({
      path: 'lab/ce/cem/examcard/',
      name: 'examcard',
      params: {
        date: currDate.date() + '_' + (currDate.month() + 1).toString() + '_' + currDate.year(),
        exam_id: exam.id.toString(),
      },
    });
    return this.$router.currentRoute.fullPath;
  }

  getProtLink(prot) {
    const visit = visitsModule.visitById(prot.visit_id);
    this.$router.push({
      name: 'visitmain',
      params: { visit_id: visit.id.toString() },
    });
    return this.$router.currentRoute.fullPath;
  }

  get userID() {
    return userModule.user.id;
  }
  get clientID() {
    return userModule.userData.client_id;
  }
  get clientCode() {
    return userModule.userData.client_code;
  }
  get visit() {
    return prot => {
      return visitsModule.visitById(prot.visit_id);
    };
  }
  get visitStatus() {
    return prot => {
      const protVisit = visitsModule.visitById(prot.visit_id);
      let visitStatus = new consts.VisitStatus(null, '', '', '');
      if (protVisit.collect_and_exam_protocol_id !== undefined) {
        if (protVisit.status_id === consts.VisitStatus.VERIFIED.id && prot.authorise_person_id !== null) {
          visitStatus.name = consts.VisitStatusAuthorised.NAME;
          visitStatus.badge = consts.VisitStatusAuthorised.BADGE;
        } else {
          visitStatus = consts.VisitStatus.byId(protVisit.status_id);
        }
      } else {
        visitStatus = consts.VisitStatus.byId(protVisit.status_id);
      }
      return visitStatus;
    };
  }
  get getDestinyName() {
    return rd => {
      const exam = examsModule.exams.find(e => e.id === rd.exam_id);
      //console.log('getDestinyName Exam', exam);
      const destiny = dictModule.destinies.find(d => d.id === exam.destiny_id);
      //console.log(' Destiny', destiny);
      return destiny.description;
    };
  }
  get getDestiny() {
    return rd => {
      const exam = examsModule.exams.find(e => e.id === rd.exam_id);
      const destiny = dictModule.destinies.find(d => d.id === exam.destiny_id);
      return destiny;
    };
  }

  get protSamples() {
    return prot => {
      const samples = [];
      const mixers = mixersModule.mixers.filter(m => prot.mixer_ids.includes(m.id));
      //console.log('mixers', mixers);
      mixers.forEach(mixer => {
        const collects = collectsModule.collects.filter(c => mixer.collect_ids.includes(c.id));
        //console.log('Collects', collects);
        collects.forEach(collect => {
          samples.push(...samplesModule.samples.filter(s => collect.sample_ids.includes(s.id)));
        });
      });
      //console.log('Samples', samples);
      return samples;
    };
  }

  get examSamples() {
    return exam => {
      return samplesModule.samples.filter(s => exam.sample_ids.includes(s.id));
    };
  }

  get protDestiniesIds() {
    return prot => {
      return this.protSamples(prot).map(s => s.destiny_id);
    };
  }

  get getDestinyNorm() {
    return dst => {
      return dictModule.destinyGroupById(dst.group_id).description;
    };
  }

  get ifProtocols() {
    if (this.protocols) {
      if (this.protocols.length > 0) {
        return true;
      }
    }
    return false;
  }

  modalProtocolFilterOK(filters) {
    console.log('modalProtocolFilterOK', filters);
    this.filterParams = filters;
    this.pageNo = 1;
    this.fillProtocols();
  }
  modalProtocolFilterCancel() {
    console.log('modalProtocolFilterCancel');
  }

  EMPTY_INVESTMENTS = { name: '- brak inwestycji -', short_name: '- brak inwestycji -', id: 0 };
  CPLACE_NAME_TRUNCATE_LENGTH = 150;
  fillConstructionPlaces() {
    dictModule.fetchConstructionPlaces();

    dictModule.cPlaces.forEach(cplace => {
      this.cPlaces.push(JSON.parse(JSON.stringify(cplace)));
    });

    this.cPlaces.forEach(cp => {
      const client = dictModule.clientById(cp.client_id);
      cp.short_name =
        client.name.substring(0, this.CPLACE_NAME_TRUNCATE_LENGTH) +
        ' => ' +
        cp.name.substring(0, this.CPLACE_NAME_TRUNCATE_LENGTH);
      if (cp.name.length > this.CPLACE_NAME_TRUNCATE_LENGTH) {
        cp.short_name += '...';
      }
    });
    if (this.cPlaces.length === 0) {
      this.cPlaces.push(this.EMPTY_INVESTMENTS);
      this.currentCPlace = this.EMPTY_INVESTMENTS;
      this.enabled = false;
    } else {
      this.currentCPlace = this.cPlaces[0];
      this.fillProtocols();
    }
  }

  startT = null;
  startTime(title) {
    console.log('Timer START:', title);
    this.startT = performance.now();
  }
  endTime(title) {
    const endT = performance.now();
    console.log('Timer END:', title, endT - this.startT, ' ms');
  }

  async fillProtocols() {
    this.startTime('fillProtocols');
    console.log('fillProtocol..');
    this.spinner = true;
    const selCPlace: ConstructionPlace = this.currentCPlace as ConstructionPlace;

    this.protocols = [];
    this.$forceUpdate();
    await protocolsModule.fetchPIBProtocols();
    let protocols = [];

    protocols = protocolsModule.pibProtocols;
    //console.log('protocolsByCientCode', client, protocols.length, protocols, this.spinner);
    if (this.filterParams.length > 0) {
      console.log('FILL Filters', this.filterParams);
      protocols = this.filterProtocols(protocols, this.filterParams);
      console.log('After filtering', protocols);
    }
    protocols = this.sortProtocols(protocols, this.sortParams.field, this.sortParams.dir);

    this.allPagesCount = Math.ceil(protocols.length / this.pageSize);
    this.protocols = this.paginate(protocols, this.pageSize, this.pageNo);
    this.update();
    this.expanded = [];
    for (let index = 0; index < this.protocols.length; index++) {
      this.expanded[index] = false;
    }
    if (this.protocols.length) {
      this.initializeVisit(this.protocols[0]);
    }
    this.protocols.forEach(prot => {
      prot['resultsDocs'] = this.resultsDocs(prot);
    });
    this.spinner = false;
    this.endTime('fillProtocols');
  }

  filterProtocols(protocols: CollectAndExamProtocol[], filters: { field: string; value: any }[]) {
    let result = true;
    const prots = protocols.filter(protocol => {
      result = true;
      filters.forEach((filter, index) => {
        if (result) {
          if (filter.field === 'client_id') {
            const visit = visitsModule.visitById(protocol.visit_id);
            result = visit.client_id === filter.value;
            return result;
          }
          if (filter.field === 'cplace_id') {
            const visit = visitsModule.visitById(protocol.visit_id);
            result = visit.cplace_id === filter.value;
            return result;
          }
          if (filter.field === 'number') {
            result = protocol.number.includes(filter.value);
            return result;
          }
          if (filter.field === 'results_doc_number') {
            if (filter.value !== '') {
              result = this.resultsDocs(protocol).length > 0;
              return result;
            } else {
              return result;
            }
          }

          if (filter.field === 'destiny') {
            if (filter.value !== '') {
              return this.protDestiniesIds(protocol).includes(filter.value);
            } else {
              console.log('   Filter destiny empty');
              return result;
            }
          }
          if (filter.field === 'recipie_number') {
            if (filter.value !== '') {
              return protocol.recipie_no.includes(filter.value);
            } else {
              return result;
            }
          }
          if (filter.field === 'protStatus') {
            if (filter.value !== '') {
              result = this.visitStatus(protocol).name === filter.value;
              // console.log('   protStatus', filter.value, '=', result);
              return result;
            } else {
              // console.log('   Filter protStatus empty');
              return result;
            }
          }
          if (filter.field === 'rdStatus') {
            if (filter.value !== '') {
              result = this.resultsDocs(protocol).length > 0;
              // console.log('   rdStatus', filter.value, '=', result);
              return result;
            } else {
              // console.log('   Filter rdStatus empty');
              return result;
            }
          }
          if (filter.field === 'take_date_from') {
            if (filter.value !== '') {
              result = moment(this.visit(protocol).date, DB_DATE_FORMAT).isSameOrAfter(
                moment(filter.value, DB_DATE_FORMAT)
              );
              return result;
            } else {
              return result;
            }
          }
          if (filter.field === 'take_date_to') {
            if (filter.value !== '') {
              result = moment(this.visit(protocol).date, DB_DATE_FORMAT).isSameOrBefore(
                moment(filter.value, DB_DATE_FORMAT)
              );
              return result;
            } else {
              return result;
            }
          }
          if (filter.field === 'resultsDoc_date_from') {
            if (filter.value !== '') {
              result = this.resultsDocs(protocol).length > 0;
              return result;
            } else {
              return result;
            }
          }
          if (filter.field === 'resultsDoc_date_to') {
            if (filter.value !== '') {
              result = this.resultsDocs(protocol).length > 0;
              return result;
            } else {
              return result;
            }
          }
          if (filter.field === 'method') {
            if (filter.value !== '') {
              result = this.resultsDocs(protocol).length > 0;
              return result;
            } else {
              return result;
            }
          }
          const fieldSplitted = filter.field.split('.');
          if (fieldSplitted.length === 1) {
            if (typeof filter.value === 'string') {
              result = protocol[filter.field] === filter.value;
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              result = protocol[filter.field] === filter.value;
            }
          } else if (fieldSplitted.length === 2) {
            if (typeof filter.value === 'string') {
              result = protocol[fieldSplitted[0]][fieldSplitted[1]].includes(filter.value);
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              result = protocol[fieldSplitted[0]][fieldSplitted[1]] === filter.value;
            }
          }
        }
      });
      return result;
    });
    return prots;
  }

  get protocol() {
    return (sample: Sample): CollectAndExamProtocol => {
      const collect = collectsModule.collectById(sample.collect_id);
      const mixer = mixersModule.mixerById(collect.mixer_id);
      const prot = protocolsModule.protocolById(mixer.protocol_id);

      return prot as CollectAndExamProtocol;
    };
  }
  get getRecipie() {
    return (exam: Exam): string => {
      if (exam.samples.length > 0) {
        return this.protocol(exam.samples[0]).recipie_no;
      } else {
        return '-';
      }
    };
  }
  getResultDocUrl(filename: string) {
    if (filename === undefined) {
      return '#';
    }
    return `${process.env.VUE_APP_API_URL}media/results-docs/${filename}`;
  }

  isAuthorised(exam_id: number) {
    const exam = examsModule.examById(exam_id);
    return exam.is_authorised;
  }

  get user(): User {
    return userModule.user;
  }
  prevPage() {
    this.pageNo--;
    this.fillProtocols();
  }
  async nextPage() {
    this.pageNo++;
    await this.fillProtocols();
  }
  changePageSize() {
    this.update();
    this.fillProtocols();
  }
  filterPage() {
    this.modalResultsDocFilter.showModal(this.filterParams.slice(0, 2));
    this.fillProtocols();
  }
  sortPage(sortField = 'id') {
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.fillProtocols();
  }
  update() {
    this.btnNextVisible = this.pageNo !== this.allPagesCount;
    this.btnPrevVisible = this.pageNo !== 1;
  }

  sortProtocols(array: CollectAndExamProtocol[], field: string, dir: SortDir): CollectAndExamProtocol[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      case 'number':
      case 'recipie_no':
        if (dir === SortDir.ASC) {
          return array.sort((a, b) => (a[field] < b[field] ? 1 : -1));
        } else {
          return array.sort((a, b) => (a[field] > b[field] ? 1 : -1));
        }
      case 'visit_date':
        return array.sort((a, b) => {
          console.log(
            moment(this.visit(a).date, DB_T_DATE_TIME_FORMAT),
            moment(this.visit(b).date, DB_T_DATE_TIME_FORMAT)
          );
          if (dir === SortDir.ASC) {
            return moment(this.visit(a).date, DB_T_DATE_TIME_FORMAT).isAfter(
              moment(this.visit(b).date, DB_T_DATE_TIME_FORMAT)
            )
              ? -1
              : 1;
          } else {
            return moment(this.visit(a).date, DB_T_DATE_TIME_FORMAT).isBefore(
              moment(this.visit(b).date, DB_T_DATE_TIME_FORMAT)
            )
              ? -1
              : 1;
          }
        });
      case 'created':
        return array.sort((a, b) => {
          console.log(moment(a[field], DB_T_DATE_TIME_FORMAT), moment(b[field], DB_T_DATE_TIME_FORMAT));
          if (dir === SortDir.ASC) {
            return moment(a[field], DB_T_DATE_TIME_FORMAT).isAfter(moment(b[field], DB_T_DATE_TIME_FORMAT)) ? -1 : 1;
          } else {
            return moment(a[field], DB_T_DATE_TIME_FORMAT).isBefore(moment(b[field], DB_T_DATE_TIME_FORMAT)) ? -1 : 1;
          }
        });
      case 'destiny.name':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a['destiny']['name'] < b['destiny']['name'] ? 1 : -1;
          } else {
            return a['destiny']['name'] > b['destiny']['name'] ? 1 : -1;
          }
        });
      case 'short_name':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a['client']['short_name'] < b['client']['short_name'] ? 1 : -1;
          } else {
            return a['client']['short_name'] > b['client']['short_name'] ? 1 : -1;
          }
        });
      case 'status':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return this.visitStatus(a).name < this.visitStatus(b).name ? 1 : -1;
          } else {
            return this.visitStatus(a).name > this.visitStatus(b).name ? 1 : -1;
          }
        });
      default:
        return array;
    }
  }

  paginate(array: CollectAndExamProtocol[], pageSize: number, pageNumber: number): CollectAndExamProtocol[] {
    console.log('Paginate', pageSize, pageNumber);
    pageNumber--;
    const rowFrom = pageNumber * pageSize;
    const rowTo = pageNumber * pageSize + pageSize;
    let index = 1;
    array.forEach(e => {
      e['index'] = index++;
    });
    return array.slice(rowFrom, rowTo);
  }

  getFormattedDate(date: string): string {
    if (
      moment(date, consts.DB_T_S_DATE_TIME_FORMAT, true).isValid() ||
      moment(date, consts.DB_T_S_MS_DATE_TIME_FORMAT, true).isValid()
    ) {
      return moment(date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    } else {
      return date;
    }
  }

  created() {
    console.log('%c*********************** Protocol list creating ***********************', 'color:red');

    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Wyniki badań',
          link: '',
        },
      ],
    });
    const module = parseInt(userModule.getUserData.module);

    if (this.userID !== null && module === UserModule.CLIENT) {
      this.isClient = true;
      this.filterParams.push({ field: 'client_code', value: this.clientCode });
    }

    //await this.fillConstructionPlaces();
    //this.selectCPlace = this.cPlaces[0]; // cplace bedzie mialo zawsze 1 rekord, nawet jak puste
    this.currentCPlace = undefined;
    this.fillProtocols();
    console.log('CPlaces:', this.cPlaces);

    console.log('%c*********************** Protocol list created ***********************', 'color:red');
  }
  get start() {
    console.log('%c---------- Start of template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of template -----------', 'color:green;font-weight:600');
    return '';
  }
}
