














































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as consts from '../../consts';
import methodsModule from '../../store/modules/methods';
import dictModule from '../../store/modules/dict';
import * as helpers from '../../helpers';
import moment from 'moment';
import { Client, ConstructionPlace } from '../../types';

const UNSELECTED = -1;
const NOT_CHOOSEN = 100;
type multiselectOption = { id: number; name: string };

@Component
export default class ModalResultsDocFilter extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'xl' }) size;
  @Prop({ type: Boolean, required: false, default: false }) isLaborantView;
  protocolNumber = '';
  show = false;
  save = false;
  params = {};
  enabled = true;

  btnSave = 0;
  resultDocType = this.getResultDocTypes[0];
  filters: { field: string; value: any }[] = [];
  filtersReceived = [];
  protMethod = { name: '-- brak wyboru --', id: UNSELECTED };
  destiny = { name: '-- brak wyboru --', id: UNSELECTED };
  protStatus = { name: '-- brak wyboru --', id: UNSELECTED };
  rdStatus = { name: '-- brak wyboru --', id: UNSELECTED };
  resultsDocNumber = '';
  recipieNumber = '';
  takeDateFrom = '';
  takeDateFromText = '';
  takeDateTo = '';
  takeDateToText = '';
  resultsDocDateFrom = '';
  resultsDocDateFromText = '';
  resultsDocDateTo = '';
  resultsDocDateToText = '';

  removeClient() {
    this.selectedClient = {};
    this.removeCPlace();
    this.cplaceMultiselectEnabled = false;
  }
  removeCPlace() {
    this.selectedCPlace = {};
  }

  selectedClient = {};
  get clients() {
    const clients = dictModule.clients;
    clients.sort((a, b) => {
      return parseInt(a.code) - parseInt(b.code);
    });
    clients.forEach(c => {
      c['code_name'] = c.code + ' - ' + c.name;
    });
    return clients;
  }
  selectClient(e: KeyboardEvent) {
    this.selectedClient = e;
    this.removeCPlace();
    this.cplaces = dictModule.cPlacesByClientId(this.selectedClient['id']);
    this.cplaceMultiselectEnabled = true;
  }

  cplaceMultiselectEnabled = false;
  cplaces = [];
  selectedCPlace = {};
  selectCPlace(e: KeyboardEvent) {
    this.selectedCPlace = e;
  }

  showModal(filters: { field: string; value: any }[]) {
    this.filtersReceived = filters;
    this.filters = this.filtersReceived;
    this.show = true;
    methodsModule.fetchMethods();
  }
  addFilter(field: string, value: any) {
    if (value === NOT_CHOOSEN) {
      this.filters = this.filters.filter(el => el.field !== field);
      return;
    }
    for (const filter of this.filters) {
      if (filter.field === field) {
        filter.value = value;
        return;
      }
    }
    this.filters.push({ field: field, value: value });
  }
  isEmptyObject(obj) {
    return JSON.stringify(obj) === '{}';
  }
  saveModal() {
    this.save = true;
    if (this.selectedClient && 'id' in this.selectedClient) {
      this.addFilter('client_id', this.selectedClient['id']);
    }
    if (this.selectedCPlace && 'id' in this.selectedCPlace) {
      this.addFilter('cplace_id', this.selectedCPlace['id']);
    }
    if (this.protocolNumber !== '') {
      this.addFilter('number', this.protocolNumber);
    }
    if (this.destiny.id !== UNSELECTED) {
      this.addFilter('destiny', this.destiny.id);
    }
    if (this.protStatus.id !== UNSELECTED) {
      this.addFilter('protStatus', this.protStatus.name);
    }
    if (this.rdStatus.id !== UNSELECTED) {
      this.addFilter('rdStatus', this.rdStatus.name);
    }
    if (this.protMethod.id !== UNSELECTED) {
      this.addFilter('method', this.protMethod.name);
    }
    if (this.takeDateFrom !== '') {
      this.addFilter('take_date_from', this.takeDateFrom);
    }
    if (this.takeDateTo !== '') {
      this.addFilter('take_date_to', this.takeDateTo);
    }
    if (this.resultsDocDateFrom !== '') {
      this.addFilter('resultsDoc_date_from', this.resultsDocDateFrom);
    }
    if (this.resultsDocDateTo !== '') {
      this.addFilter('resultsDoc_date_to', this.resultsDocDateTo);
    }
    if (this.resultsDocNumber !== '') {
      this.addFilter('results_doc_number', this.resultsDocNumber);
    }
    if (this.recipieNumber !== '') {
      this.addFilter('recipie_number', this.recipieNumber);
    }

    console.log('----FILTERS----', this.filters);
    this.$emit(consts.ModalEvents.OK_EVENT, this.filters);
    this.show = false;
  }
  clear() {
    this.selectedClient = {};
    this.selectedCPlace = {};
    this.cplaceMultiselectEnabled = false;
    this.resultDocType = this.getResultDocTypes[0];
    this.protocolNumber = '';
    this.resultsDocNumber = '';
    this.recipieNumber = '';
    this.destiny = { name: '-- brak wyboru --', id: UNSELECTED };
    this.protStatus = { name: '-- brak wyboru --', id: UNSELECTED };
    this.rdStatus = { name: '-- brak wyboru --', id: UNSELECTED };
    this.takeDateFrom = '';
    this.takeDateFromText = '';
    this.takeDateTo = '';
    this.takeDateToText = '';
    this.resultsDocDateFrom = '';
    this.resultsDocDateFromText = '';
    this.resultsDocDateTo = '';
    this.resultsDocDateToText = '';
    this.protMethod = { name: '-- brak wyboru --', id: UNSELECTED };
    this.filters = [];
    console.log('Clear');
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
  typeSelected(event: { id: number; name: string }) {
    this.resultDocType = event;
    this.addFilter('rd_type', event.id);
  }
  get destiniesOptions(): multiselectOption[] {
    const destiniesOptions: multiselectOption[] = [{ id: UNSELECTED, name: '-- brak wyboru --' }];
    dictModule.destinies.map(destiny => destiniesOptions.push({ id: destiny.id, name: destiny.name }));
    return destiniesOptions;
  }
  get protStatusesOptions(): multiselectOption[] {
    const protStatusesOptions: multiselectOption[] = [
      { id: UNSELECTED, name: '-- brak wyboru --' },
      consts.VisitStatus.byId(consts.VisitStatus.PLANNED.id),
      consts.VisitStatus.byId(consts.VisitStatus.PENDING.id),
      consts.VisitStatus.byId(consts.VisitStatus.CORRECT.id),
      consts.VisitStatus.byId(consts.VisitStatus.CLOSED.id),
      consts.VisitStatus.byId(consts.VisitStatus.VERIFY.id),
      consts.VisitStatus.byId(consts.VisitStatus.VERIFIED.id),
      consts.VisitStatus.byId(consts.VisitStatus.CANCELED.id),
      { id: consts.VisitStatus.CANCELED.id + 1, name: consts.VisitStatusAuthorised.NAME },
    ];
    return protStatusesOptions;
  }
  get rdStatusesOptions(): multiselectOption[] {
    const rdStatusesOptions: multiselectOption[] = [{ id: UNSELECTED, name: '-- brak wyboru --' }];
    rdStatusesOptions.push(
      consts.ExamStatus.byId(consts.ExamStatus.PENDING.id - 1),
      consts.ExamStatus.byId(consts.ExamStatus.FINISHED.id - 1),
      {
        id: consts.ExamStatus.FINISHED.id + 1,
        name: consts.ExamStatusAuthorised.NAME,
      }
    );
    return rdStatusesOptions;
  }
  get protMethods(): multiselectOption[] {
    const protMethods: multiselectOption[] = [{ id: UNSELECTED, name: '-- brak wyboru --' }];
    dictModule.destinyGroups.map(destiny => protMethods.push({ id: destiny.id, name: destiny.description }));
    return protMethods;
  }

  protMethodSelected(e: any) {
    console.log('protMethodSelected', e);
    this.protMethod = e;
  }
  destinySelected(e: any) {
    console.log('destinySelected', e);
    this.destiny = e;
  }
  protStatusSelected(e: any) {
    console.log('protStatusSelected', e);
    this.protStatus = e;
  }
  rdStatusSelected(e: any) {
    console.log('rdStatusSelected', e);
    this.rdStatus = e;
  }
  get getResultDocTypes() {
    return [
      { id: NOT_CHOOSEN, name: '-- brak wyboru --' },
      { id: consts.ResultsDocType.FROM_KPP, name: 'Z karty pierwszego pomiaru' },
      { id: consts.ResultsDocType.GENERATED, name: 'Z generatora (wyboru protokołów)' },
    ];
  }

  takeDateFromTextLeave(event: any) {
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.takeDateFrom = today.format(consts.DB_DATE_TIME_FORMAT);
      this.takeDateFromText = today.format(consts.DATE_FORMAT);
      helpers.warn('Błędny format daty, przywrócona domyślna wartość.');
    }
  }
  fillTakeDateFrom() {
    if (this.takeDateFrom !== '') {
      this.takeDateFromText = moment(this.takeDateFrom, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    }
  }
  onTakeDateFromContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.takeDateFrom = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.fillTakeDateFrom();
    } else {
      this.takeDateFromText = ctx.selectedFormatted;
    }
  }
  takeDateToTextLeave(event: any) {
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.takeDateTo = today.format(consts.DB_DATE_TIME_FORMAT);
      this.takeDateToText = today.format(consts.DATE_FORMAT);
      helpers.warn('Błędny format daty, przywrócona domyślna wartość.');
    }
  }
  fillTakeDateTo() {
    if (this.takeDateTo !== '') {
      this.takeDateToText = moment(this.takeDateTo, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    }
  }
  onTakeDateToContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.takeDateTo = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.fillTakeDateTo();
    } else {
      this.takeDateToText = ctx.selectedFormatted;
    }
  }

  resultsDocDateFromTextLeave(event: any) {
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.resultsDocDateFrom = today.format(consts.DB_DATE_TIME_FORMAT);
      this.resultsDocDateFromText = today.format(consts.DATE_FORMAT);
      helpers.warn('Błędny format daty, przywrócona domyślna wartość.');
    }
  }
  fillresultsDocDateFrom() {
    if (this.resultsDocDateFrom !== '') {
      this.resultsDocDateFromText = moment(this.resultsDocDateFrom, consts.DB_DATE_TIME_FORMAT).format(
        consts.DATE_FORMAT
      );
    }
  }
  onresultsDocDateFromContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.resultsDocDateFrom = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.fillresultsDocDateFrom();
    } else {
      this.resultsDocDateFromText = ctx.selectedFormatted;
    }
  }
  resultsDocDateToTextLeave(event: any) {
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.resultsDocDateTo = today.format(consts.DB_DATE_TIME_FORMAT);
      this.resultsDocDateToText = today.format(consts.DATE_FORMAT);
      helpers.warn('Błędny format daty, przywrócona domyślna wartość.');
    }
  }
  fillresultsDocDateTo() {
    if (this.resultsDocDateTo !== '') {
      this.resultsDocDateToText = moment(this.resultsDocDateTo, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    }
  }
  onresultsDocDateToContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.resultsDocDateTo = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.fillresultsDocDateTo();
    } else {
      this.resultsDocDateToText = ctx.selectedFormatted;
    }
  }
}
