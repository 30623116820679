
















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  private rightElements = [];
  private leftElements = [];
  private middle = [];

  get innerWidth() {
    return window.innerWidth;
  }

  get innerHeight() {
    return window.innerHeight;
  }
}
