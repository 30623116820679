import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  Visit,
  Sample,
  Collect,
  Mixer,
  CollectAndExamProtocol,
  CollectCTBProtocol,
  CollectProtocol,
  User,
  Destiny,
  CollectExamS,
  CollectExamF,
  CollectExamSF,
} from '@/types';

import * as consts from '@/consts';

import visitsModule from './visits';

import * as helpersVisit from '@/helpers/components/visit';

import protocols from './protocols';

import collects from './collects';
import { getRandomInt } from '@/helpers';
const NEW_ID_START = 100000;

@Module({
  namespaced: true,
  name: 'visit_item',
  store,
  dynamic: true,
})
class VisitModule extends VuexModule {
  public visit: Visit = null;
  rowsPerPage = 20;

  @Mutation
  clear() {
    this.visit = null;
    console.log('Visit module cleared...');
  }

  @Mutation
  createEmptyVisit() {
    this.visit = new Visit();
  }
  @Mutation
  setRowsPerPage(rowsPerPage: number): any {
    this.rowsPerPage = rowsPerPage;
  }

  @Mutation
  createEmptyvisit() {
    this.visit = new Visit();
  }

  @Mutation
  setVisit(visit: Visit) {
    this.visit = visit;
  }
  @Mutation
  setProtocolPIB(protocolPIB: CollectAndExamProtocol) {
    Object.assign(this.visit.collect_and_exam_protocol, protocolPIB);
  }
  @Mutation
  setProtocolCTB(protocol: CollectCTBProtocol) {
    this.visit.collect_ctb_protocol = protocol;
  }
  @Mutation
  setProtocolCollect(protocol: CollectProtocol) {
    this.visit.collect_protocol = protocol;
  }
  @Mutation
  setMixer(mixer: Mixer) {
    const mixerIndex = this.visit.collect_and_exam_protocol.mixers.findIndex(m => m.id === mixer.id);
    const newMixer = Object.assign(new Mixer(), mixer);
    this.visit.collect_and_exam_protocol.mixers[mixerIndex] = newMixer;
  }
  @Mutation
  setCollect(payload: { mixer: Mixer; collect: Collect }) {
    if (payload.mixer && payload.collect) {
      this.visit.collect_and_exam_protocol.mixers.forEach(m => {
        if (m.id === payload.mixer.id) {
          payload.mixer.collects.forEach(c => {
            if (c.id === payload.collect.id) {
              console.log(
                "Updating vuex visit's collect",
                c.id,
                payload.collect.exam_type_id,
                payload.collect,
                payload.mixer
              );
              c = Object.assign(c, payload.collect);
            }
          });
        }
      });
    }
  }

  @Mutation
  addNewSample(payload: { person: User; collect: Collect; destiny: Destiny; age: number; sampleNumber: string }) {
    const sample = new Sample();
    const maxId = Math.max(...payload.collect.samples.map(s => s.id));
    if (sample.id === 0) {
      if (maxId < NEW_ID_START) {
        sample.id = NEW_ID_START;
      } else {
        sample.id = maxId + 1;
      }
    }
    sample.number = payload.sampleNumber;
    sample.destiny_id = payload.destiny.id;
    sample.age = payload.age;
    sample.collect_id = payload.collect.id;
    const mixer = this.visit.collect_and_exam_protocol.mixers.find(m => m.id === payload.collect.mixer_id);
    mixer.collects.find(c => c.id === payload.collect.id).samples.push(sample);
    mixer.collects.find(c => c.id === payload.collect.id).sample_ids.push(sample.id);
    console.log('Sample added:', sample);
  }

  @Mutation
  addNewMixer(payload: { protocol: CollectAndExamProtocol }): Mixer {
    console.log('AddNewMixer', payload.protocol);

    const mixer = new Mixer();
    const maxId = Math.max(...payload.protocol.mixers.map(s => s.id));
    if (mixer.id === 0) {
      if (maxId < NEW_ID_START) {
        mixer.id = NEW_ID_START;
      } else {
        mixer.id = maxId + 1;
      }
    }

    const { mixers } = payload.protocol;
    let lastIndex = Math.max(...mixers.map(mixer => mixer.index));
    if (mixers.length === 0) lastIndex = 0;
    mixer.index = lastIndex + 1;
    payload.protocol.mixers.push(mixer);
    payload.protocol.mixer_ids.push(mixer.id);
    return mixer;
  }
  @Mutation
  addNewCollect(payload: { mixer: Mixer; maxId: number }): Collect {
    const collect = new Collect();

    if (payload.maxId < NEW_ID_START) {
      collect.id = NEW_ID_START;
    } else {
      collect.id = payload.maxId + 1;
    }

    collect.exam.exam_collect_id = collect.id;
    collect.index = payload.mixer.collects.length + 1;
    collect.mixer_id = payload.mixer.id;
    payload.mixer.collects.push(collect);
    payload.mixer.collect_ids.push(collect.id);
    return collect;
  }

  @Mutation
  addNewCollectExam(payload: { collect: Collect }): any {
    switch (payload.collect.exam_type_id) {
      case consts.CollectExamType.S.id:
        payload.collect.exam = new CollectExamS();
        break;
      case consts.CollectExamType.F.id:
        payload.collect.exam = new CollectExamF();
        break;
      case consts.CollectExamType.SF.id:
        payload.collect.exam = new CollectExamSF();
        break;
      default:
        break;
    }
    payload.collect.exam.id = NEW_ID_START;
    payload.collect.exam_id = NEW_ID_START;
    payload.collect.exam.exam_collect_id = payload.collect.id;
  }

  @Mutation
  removeMixer(mixer: Mixer) {
    this.visit.collect_and_exam_protocol.mixer_ids = this.visit.collect_and_exam_protocol.mixer_ids.filter(
      id => id !== mixer.id
    );
    this.visit.collect_and_exam_protocol.mixers = this.visit.collect_and_exam_protocol.mixers.filter(
      m => m.id !== mixer.id
    );
  }
  @Mutation
  removeCollect(collect: Collect) {
    const mixer = this.visit.collect_and_exam_protocol.mixers.find(m => m.id === collect.mixer_id);
    mixer.collects = mixer.collects.filter(c => c.id !== collect.id);
    mixer.collect_ids = mixer.collect_ids.filter(id => id !== collect.id);
  }
}

export default getModule(VisitModule);
