import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import store from '@/store';
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators';
import { DashboardItem, DashboardAction } from '@/types';
import { IDashboardItem } from '../models';
import dataModule from '@/store/modules/data';

@Module({
  namespaced: true,
  name: 'dashboard_items',
  store,
  dynamic: true,
})
class DashboardItemsModule extends VuexModule {
  dashboardItems: DashboardItem[] = [];

  get allItems(): DashboardItem[] {
    return this.dashboardItems;
  }

  public get dashboardItemById() {
    return (id: number): DashboardItem => {
      return this.dashboardItems.find(m => m.id === id);
    };
  }
  public get dashboardItemCopyById() {
    return (id: number): DashboardItem => {
      return Object.assign(new DashboardItem(), JSON.parse(JSON.stringify(this.dashboardItemById(id))));
    };
  }

  @Mutation
  clear() {
    this.dashboardItems = [];
    console.log('dashboardItems module cleared...');
  }

  @Mutation
  appendItem(item: DashboardItem) {
    this.dashboardItems.push(item);
  }

  @Mutation
  replaceItem(item: DashboardItem) {
    const u = this.dashboardItems.find(v => v.id === item.id);
    Object.assign(u, item);
  }

  @Action({ rawError: true })
  async addItemWithAction(payload: { item: DashboardItem; action: DashboardAction }) {
    const response = await API.saveDashboardItemWithAction(payload);
    if (response.data.success) {
      const newItem = Object.assign(new DashboardItem(), response.data.item);
      this.appendItem(newItem);
      const newAction = Object.assign(new DashboardAction(), response.data.action);
      dataModule.appendAction(newAction);
    } else {
      console.log('addItem Error', response.data.error_code);
    }
    return response;
  }

  @Action({ rawError: true })
  async updateItemWithAction(payload: { item: DashboardItem; action: DashboardAction }) {
    const response = await API.saveDashboardItemWithAction(payload);
    if (response.data.success) {
      const newItem = Object.assign(new DashboardItem(), response.data.item);
      this.replaceItem(newItem);
      const newAction = Object.assign(new DashboardAction(), response.data.action);
      dataModule.appendAction(newAction);
    } else {
      console.log('addItem Error', response.data.error_code);
    }
    return response;
  }

  @Action({ rawError: true })
  async addItem(item: DashboardItem) {
    const response = await API.saveDashboardItem(item);
    if (response.data.success) {
      const newItem = Object.assign(new DashboardItem(), response.data.item);
      this.appendItem(newItem);
    } else {
      console.log('addItem Error', response.data.error_code);
    }
    return response;
  }

  @Action({ rawError: true })
  async updateItem(item: DashboardItem): Promise<AxiosResponse> {
    const response = await API.saveDashboardItem(item);
    if (response.data.success) {
      this.replaceItem(item);
    }
    return response;
  }
  @Mutation
  setDashboardItems(dashboardItems: DashboardItem[]) {
    this.dashboardItems = [];
    dashboardItems.forEach(u => {
      this.dashboardItems.push(Object.assign(new DashboardItem(), u));
    });
  }
  @Action
  async fetchDashboardItems(): Promise<AxiosResponse> {
    console.log('*** fetchDashboardItems');
    const response = await API.fetchDashboardItems();
    if (response.data.success) {
      this.setDashboardItems(response.data.dashboard_items);
      console.log('   fetchDashboardItems VUEX DONE:', this.dashboardItems);
      return response;
    }
  }

  @Mutation
  loadDashboardItemsFromConfig(dashboardItems: IDashboardItem[]): any {
    this.dashboardItems = dashboardItems.map(c => Object.assign(new DashboardItem(), c));
    this.dashboardItems.forEach(a => {
      a.load();
    });
    console.log('Store module loadDashboardItemsFromConfig loaded..', this.dashboardItems.length);
  }
}

export default getModule(DashboardItemsModule);
