




















































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import ModalQuestion from '../../../components/dummy/ModalQuestion.vue';
import moment from 'moment';

import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import ModalClient from '../../../components/spec/ModalClient.vue';
import ModalClientFilter from '../../../components/spec/ModalClientFilter.vue';

import breadcrumbModule from '../../../store/modules/breadcrumb';
import dashboardItemsModule from '../../../store/modules/dashboard_items';
import dataModule from '../../../store/modules/data';
import userModule from '../../../store/modules/user';

import { SortDir, LABORANT_ROUTE_PREFIX, DATE_TIME_FORMAT } from '../../../consts';
import { AppView, Client, DashboardItem, User, DashboardAction, Address } from '../../../types';
import { EventBus } from '../../../helpers/eventbus';
import dictModule from '../../../store/modules/dict';
import * as helpers from '../../../helpers';

const CLIENT_ALREADY_IN_USE = 1;

@Component({
  components: {
    TopPanel,
    Header,
    Footer,
    ModalClient,
    ModalClientFilter,
    ModalQuestion,
  },
})
export default class ClientList extends Vue {
  @Ref() readonly modalClientFilter: ModalClientFilter;
  @Ref() readonly modalClient: ModalClient;
  @Ref() readonly removeClientQuestion: ModalQuestion;

  btnPrevVisible = false;
  btnNextVisible = true;

  pageSize = 20;
  pageNo = 1;
  allPagesCount = 0;
  filterParams: { field: ''; value: any }[] = [];
  sortParams = { field: 'code', dir: SortDir.DESC };
  spinner = false;
  clients: Client[] = [];

  // ------------- GETTERS --------------
  async fillClients() {
    this.spinner = true;

    await dictModule.fetchClients();

    this.clients = [];
    let clients = dictModule.clients;
    if (this.filterParams.length > 0) {
      console.log('Filtering', this.filterParams);
      clients = this.filterClient(clients, this.filterParams);
    }
    console.log('Sorting', this.sortParams);
    clients = this.sortClient(clients, this.sortParams.field, this.sortParams.dir);
    let index = 1;
    clients.forEach(c => {
      c['index'] = index;
      index++;
    });
    this.allPagesCount = Math.ceil(clients.length / this.pageSize);
    console.log('Paginating pageSize=', this.pageSize, 'pageNo=', this.pageNo);
    this.clients = this.paginate(clients, this.pageSize, this.pageNo) as Client[];
    console.log('After fill clients', this.clients);
    this.spinner = false;
    this.update();
  }

  getClientName(client_id: number) {
    if (client_id !== undefined) {
      const client = dictModule.clientById(client_id);
      return client !== undefined ? client.name : '';
    } else {
      return '';
    }
  }

  getCPlaceName(cplace_id: number) {
    if (cplace_id !== undefined) {
      const cplace = dictModule.cPlaceById(cplace_id);
      return cplace !== undefined ? cplace.name : '';
    } else {
      return '';
    }
  }

  progressValue = 0;
  progressMax = 100;
  uploading = false;

  importFileChange(e) {
    const formData = new FormData();
    formData.append('rd_file', e.target.files[0]);
    formData.set('filename', e.target.files[0].name);

    this.uploading = true;
    this.progressValue = 0;
    console.log(e.target.files[0]);
    const reader = new FileReader();

    reader.onload = async function (theFile) {
      console.log('theFile', theFile);
      //console.log('Result', reader.result);
      const allLines = reader.result.toString().split(/\r\n|\n/);
      console.log('allLines', allLines[1]);
      let counter = 0;
      this.progressMax = allLines.length;
      this.uploading = true;
      for (let i = 0; i < allLines.length; i++) {
        this.progressValue++;
        const line = allLines[i];
        const clientArray = line.split(';');
        const client = new Client();
        if (clientArray.length !== 12) {
          counter++;
          console.log('CODE:', clientArray[0], 'LEN', clientArray.length, 'Index', i);
        }
        /*
          25;HARTBEX;HARTBEX Przedsiębiorstwo Budowlane Sp. z o.o.;813-03-33-649;Trzebownisko 22;Trzebownisko;36-001 ;;;;;
          25A;Biuro Techniczne Kraków;Biuro Techniczne Kraków;;ul. Szwedzka 52;Kraków;30-015 ;;;;;
        */
        if (isNaN(parseInt(clientArray[0])) || isNaN(clientArray[0] as any)) {
          console.log('Code NAN', clientArray[0]);
        } else {
          //console.log('Code not NaN', clientArray[0]);
          client.code = clientArray[0];
          client.short_name = clientArray[1] || '';
          client.name = clientArray[2] || '';
          client.nip = clientArray[3] || '';
          client.address.street_and_house_no = clientArray[4] || '';
          client.address.city = clientArray[5] || '';
          client.address.postal_code = clientArray[6] || '';
          client.address.correspond = clientArray[7] || '';
          let response = null;
          const storeClient = dictModule.clientByCode(client.code);
          console.log('Stored', storeClient);
          if (storeClient === undefined) {
            response = await dictModule.addClient(client);
            if (response.data.success) {
              console.log('Client imported - add', response.data.client.id);
            }
          } else {
            client.id = storeClient.id;
            response = await dictModule.updateClient(client);
            if (response.data.success) {
              console.log('Client imported - update', response.data.client.id);
            }
          }
          if (response.data.success === false) {
            console.log('import error', client.short_name);
          }
        }
      }
      this.uploading = false;
      this.fillClients();
      console.log('ERROR Counter=', counter);
      this.uploading = false;
    }.bind(this);

    reader.readAsText(e.target.files[0], 'UTF-8');
  }

  addClient() {
    this.modalClient.showModal();
  }
  removeClient(event: any, client: Client) {
    client['question'] = 'Czy chcesz usunąć klienta ' + client.code + ' ?';
    this.removeClientQuestion.showModal(client);
  }

  async removeClientOK(client: Client) {
    const response = await dictModule.removeClient(client);
    if (!response.data.success) {
      if (response.data.error_code === CLIENT_ALREADY_IN_USE) {
        helpers.warn('Klient jest w użyciu, został zdeaktywowany', 5000);
      }
    } else {
      helpers.info('Klient usunięty');
    }
    this.fillClients();
  }
  editClient(event: any, client: Client) {
    console.log('editClient', client);
    this.modalClient.showModal(client);
  }
  modalClientOK(client) {
    if (client['new'] === true) {
      this.sortParams = { field: 'changed_date_time', dir: SortDir.ASC };
    }
    this.fillClients();
  }
  modalClientCancel() {
    console.log('ModalPersonCancel');
  }
  get user(): User {
    return userModule.user;
  }

  // ----------------- INTERNAL ACTIONS ----------------
  prevPage() {
    this.pageNo--;
    this.fillClients();
  }

  nextPage() {
    this.pageNo++;
    this.fillClients();
  }

  changePageSize() {
    this.update();
    this.fillClients();
  }

  filterPage() {
    console.log('FilterModal');
    this.modalClientFilter.showModal();
  }

  sortPage(sortField = 'id') {
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.fillClients();
  }

  update() {
    console.log('update', this.pageNo, this.allPagesCount);
    this.btnNextVisible = this.pageNo !== this.allPagesCount;
    this.btnPrevVisible = this.pageNo !== 1;
  }

  sortClient(array: Client[], field: string, dir: SortDir): Client[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      case 'id':
      case 'code':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return parseInt(a[field].toString()) < parseInt(b[field].toString()) ? 1 : -1;
          } else {
            return parseInt(a[field].toString()) > parseInt(b[field].toString()) ? 1 : -1;
          }
        });
      case 'name':
      case 'short_name':
      case 'city':
      case 'nip':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a[field] < b[field] ? 1 : -1;
          } else {
            return a[field] > b[field] ? 1 : -1;
          }
        });
      case 'address.city':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a['address']['city'] < b['address']['city'] ? 1 : -1;
          } else {
            return a['address']['city'] > b['address']['city'] ? 1 : -1;
          }
        });
      case 'changed_date_time':
        return array.sort((a, b) => {
          //console.log("DATES", moment(a[field], DATE_TIME_FORMAT), moment(b[field], DATE_TIME_FORMAT));
          if (dir === SortDir.ASC) {
            return moment(a[field], DATE_TIME_FORMAT).isAfter(moment(b[field], DATE_TIME_FORMAT)) ? -1 : 1;
          } else {
            return moment(a[field], DATE_TIME_FORMAT).isBefore(moment(b[field], DATE_TIME_FORMAT)) ? -1 : 1;
          }
        });
      default:
        return array;
    }
  }

  filterClient(clients: Client[], filters: { field: string; value: any }[]) {
    //console.log("Filter Array", filters);
    let result = false;
    return clients.filter(client => {
      result = true;
      // console.log('########## METHOD ', client.id, ' ###########');
      filters.forEach((filter, index) => {
        if (result) {
          const fieldSplitted = filter.field.split('.');
          // console.log('-- FILTER ', index + 1, filter.field, filter.value, 'splitted', fieldSplitted);
          if (fieldSplitted.length === 1) {
            if (typeof filter.value === 'string') {
              // console.log('String', client[filter.field], filter.value);
              result = client[filter.field].toLowerCase().includes(filter.value);
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              // console.log('Number', client[filter.field], filter.value);
              result = client[filter.field] === filter.value;
            }
          } else if (fieldSplitted.length === 2) {
            if (typeof filter.value === 'string') {
              result = client[fieldSplitted[0]][fieldSplitted[1]].includes(filter.value);
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              // console.log('Number nested curr:', client[fieldSplitted[0]][fieldSplitted[1]], 'required', filter.value);
              result = client[fieldSplitted[0]][fieldSplitted[1]] === filter.value;
            }
          }
        }
        // console.log('Result', result);
      });
      return result;
    });
  }

  paginate(array: Client[], pageSize: number, pageNumber: number): object[] {
    pageNumber--;
    const rowFrom: number = +(pageNumber * pageSize);
    const rowTo: number = +(pageNumber * pageSize) + +pageSize;
    console.log('Page [' + rowFrom, '-', rowTo + ']');
    let index = 1;
    const templateArray: object[] = [];
    array.forEach(o => {
      templateArray.push({
        index: index++,
        client: o,
      });
    });
    return templateArray.slice(rowFrom, rowTo);
  }

  modalClientFilterOK(filters: any) {
    console.log('modalClientFilterOK FILTERS:', filters);
    this.filterParams = filters;
    this.fillClients();
  }
  modalClientFilterCancel() {
    console.log('modalClientFilterCancel');
  }

  created() {
    console.log('%c*********************** ClientsList creating ***********************', 'color:red');
    this.fillClients();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Lista klientów',
          link: '',
        },
      ],
    });
    console.log('%c*********************** ClientsList created ***********************', 'color:red');
  }
  get start() {
    console.log('%c---------- Start of template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of template -----------', 'color:green;font-weight:600');
    return '';
  }
}
