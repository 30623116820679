var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tabs',{scopedSlots:_vm._u([{key:"tabs-end",fn:function(){return [_vm._l((_vm.getOffers),function(element,index){return _c('b-nav-item',{key:index,attrs:{"active":element.active},on:{"click":function($event){return _vm.changeOfferView(element)}}},[_vm._v(_vm._s(element.number)+" "),(_vm.isEnabled && element.status.symbol === 'NSV')?_c('span',{staticClass:"close ml-1",on:{"click":function($event){return _vm.removeOffer(element)}}},[_vm._v("×")]):_vm._e()])}),(!_vm.isClient())?_c('b-nav-item',{attrs:{"role":"presentation","disabled":!_vm.isEnabled},on:{"click":function($event){$event.preventDefault();return _vm.addNewOffer.apply(null, arguments)}}},[_vm._v("Dodaj nową ofertę")]):_vm._e()]},proxy:true}])}),(_vm.offer !== null)?_c('div',{staticClass:"main ml-3"},[_c('b-form',{staticClass:"w-100 text-left mt-4"},[_c('h2',[_vm._v("Dane podstawowe oferty")]),(_vm.offer.status.id === _vm.enums.SNT || (!_vm.clientFromUserCreated && _vm.offer.status.id === 1))?_c('span',[_c('b-button',{staticClass:"mr-3",attrs:{"size":"sm","variant":"success"},on:{"click":_vm.acceptOffer}},[_vm._v(_vm._s(_vm.isClient() ? 'Akceptuj' : 'Zaakceptuj w imieniu klienta'))]),(_vm.isClient())?_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.rejectOffer}},[_vm._v("Odrzuć")]):_vm._e()],1):_vm._e(),_c('hr'),_c('b-row',[_c('b-col',[_c('h6',[_c('span',{staticClass:"vam"},[_vm._v("Status oferty:")]),_c('b-badge',{staticClass:"vam ml-2",attrs:{"variant":_vm.offerStatus.badge}},[_vm._v(_vm._s(_vm.offerStatus.name))])],1)]),_c('b-col',[(
              (_vm.offer.status.id === _vm.enums.SAV || _vm.offer.status.id === _vm.enums.REJ) &&
              !this.isClient() &&
              _vm.clientFromUserCreated
            )?_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.sendOfferToClient}},[_vm._v("Przekaż do klienta")]):_vm._e()],1)],1),_c('label',{staticClass:"mt-2"},[_vm._v("Treść oferty (wpisz lub wklej z maila)")]),_c('b-textarea',{attrs:{"rows":"8","disabled":!_vm.isEnabled},on:{"change":_vm.updateOfferDescription},model:{value:(_vm.offerDescription),callback:function ($$v) {_vm.offerDescription=$$v},expression:"offerDescription"}}),_c('b-form-group',{staticClass:"w-75 mb-3 mt-3",attrs:{"id":"input-group-3"}},[_c('FileManager',{attrs:{"type":_vm.offer.guid !== undefined ? 'TMP' : 'OFF',"data":_vm.offer,"guid":_vm.getGuid,"isEnabled":_vm.isEnabled}})],1),_c('div',{staticStyle:{"display":"none"}},[_c('h2',[_vm._v("Rozliczenia")]),_c('hr'),(!_vm.isClient())?_c('div',[_c('label',[_vm._v("Tryb rozliczenia:")]),_c('multiselect',{staticClass:"ml-2 w-50 mb-3",attrs:{"options":_vm.checkoutOptions,"value":_vm.selectedCheckoutOption,"track-by":"name","label":"name","name":"name","disabled":!_vm.isEnabled,"searchable":false,"placeholder":"- wybierz rozliczenie -","selectLabel":"naciśnij Enter aby wybrać","selectedLabel":"Wybrany"},on:{"select":_vm.checkoutOptionSelected}})],1):_c('div',{staticClass:"mb-3 ml-2 w-50"},[_c('h5',[_vm._v("Tryb rozliczenia: "+_vm._s(_vm.selectedCheckoutOption.name))])]),_c('div',[(_vm.selectedCheckoutOptionTag === _vm.checkoutOptions[0].tag)?_c('OfferLumpSumSettlement',{attrs:{"isEnabled":_vm.isEnabled,"offer":_vm.offer}}):_vm._e(),(_vm.selectedCheckoutOptionTag === _vm.checkoutOptions[1].tag)?_c('OfferAnyAmountSettlement',{attrs:{"isEnabled":_vm.isEnabled,"offer":_vm.offer}}):_vm._e(),(_vm.selectedCheckoutOptionTag === _vm.checkoutOptions[2].tag)?_c('OfferLimitedAmountSettlement',{attrs:{"isEnabled":_vm.isEnabled,"offer":_vm.offer}}):_vm._e(),(
              _vm.selectedCheckoutOptionTag === _vm.checkoutOptions[3].tag ||
              _vm.selectedCheckoutOptionTag === _vm.checkoutOptions[4].tag
            )?_c('OfferFixAmountSettlement',{attrs:{"offer":_vm.offer,"isEnabled":_vm.isEnabled,"settlementType":_vm.selectedCheckoutOptionTag}}):_vm._e()],1)])],1),_c('b-modal',{attrs:{"id":"changeCheckoutModal","header-bg-variant":"danger","title":"Ostrzeżenie"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":_vm.hideModal}},[_vm._v("Nie")]),_c('b-button',{key:_vm.btnSave,staticClass:"ml-2 float-right",attrs:{"variant":"outline-info"},on:{"~click":function($event){return _vm.clearAndChangeCheckout.apply(null, arguments)}}},[_vm._v("Tak ")])],1)])]},proxy:true}],null,false,3919591457)},[_vm._v(" Czy na pewno chcesz zmienić wybrany typ rozliczenia? ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }