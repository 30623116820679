









































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import orderModule from '../../../store/modules/order';
import usersModule from '../../../store/modules/users';
import visitsModule from '../../../store/modules/visits';
import appModule from '../../../store/modules/app';
import * as consts from '../../../consts';
import moment from 'moment';
import TimeInput from '../../../components/dummy/TimeInput.vue';
import * as types from '../../../types';

@Component({
  components: {
    TimeInput,
  },
})
export default class OrderVisit extends Vue {
  @Prop({ type: String, required: true }) readonly mode;
  mainVisitDate = '';
  mainVisitDateText = '';
  mainVisitStartTime = '';
  mainVisitDuration = 1;

  takeVisitDate = '';
  takeVisitDateText = '';
  takeVisitStartTime = '';
  takeVisitDuration = 1;

  durationFactor = 60;
  defaultDuration = 60; // 1h

  cplaceSymbolRequired = false;

  revalidate = 0;
  isAuto = true;

  locale: 'pl-PL';
  locales: [{ value: 'pl-PL'; text: 'Polski PL (pl-PL)' }];

  userSel = {};

  timeInvalidate = 0;
  takeTimeInvalidate = 0;

  setCPlaceSymbol(e) {
    console.log('setCPlaceSymbol', e);
    orderModule.order.visit.client_cplace_symbol = e;
  }

  getSimilarCPlaces(order: types.Order) {
    const places = visitsModule.visits.filter(
      v =>
        moment(v.date).format(consts.DATE_FORMAT) === moment(order.visit.date).format(consts.DATE_FORMAT) &&
        v.laborant_id === order.visit.laborant_id &&
        v.client_id === order.visit.client_id
    );
    console.log('sameDayPrevVisits', places);
    this.cplaceSymbolRequired = places.length > 0;
    return places;
  }

  fillUser() {
    const labId = orderModule.order.visit.laborant_id;
    const user = usersModule.users.find(u => u.id === labId);
    if (user) {
      console.log('User=', user);
      this.userSel = { name: user.firstname + ' ' + user.lastname, id: user.id };
    }
  }

  get mainDurationSel() {
    this.revalidate;
    console.log(orderModule);
    if (orderModule !== undefined) {
      if (orderModule.order !== undefined) {
        if (orderModule.order.visit !== null) {
          return Math.floor(orderModule.order.visit.duration / this.durationFactor);
        }
      }
    }
    return 1;
  }

  get takeDurationSel() {
    this.revalidate;
    console.log(orderModule);
    if (orderModule !== undefined) {
      if (orderModule.order !== undefined) {
        if (orderModule.order.visit !== null) {
          return orderModule.order.take_ctb_visit.duration / this.durationFactor;
        }
      }
    }
    return 1;
  }

  get workers() {
    this.revalidate;
    console.log('usersModule:users', usersModule.allUsers);
    const usersWithVisitStartRight = usersModule.getUsersByRightSymbolInAllRights(consts.UserRightSymbols.VISIT_START);
    const workingUsers = usersModule.getWorkingUsers(usersWithVisitStartRight);
    const optionsUsers = workingUsers.map(u => {
      const retUser = { name: u.firstname + ' ' + u.lastname, id: u.id };
      return retUser;
    });
    return optionsUsers;
  }

  changeAuto(e: any) {
    if (e === true) {
      const order = orderModule.order;
      const dtMain = moment(order.visit.date);
      if (dtMain.isValid()) {
        const newMoment = moment(dtMain);
        const newDT = newMoment.add(appModule.getDaysToCTBVisit, 'days');
        orderModule.order.take_ctb_visit.date = newDT.format(consts.DB_DATE_TIME_FORMAT);
        this.takeVisitDate = newDT.format(consts.DB_DATE_TIME_FORMAT);
        this.takeVisitDateText = newDT.format(consts.DATE_FORMAT);
      }
      order.take_ctb_visit.start_time = order.visit.start_time;
      this.takeVisitStartTime = order.take_ctb_visit.start_time;
      this.takeTimeInvalidate++;
      order.take_ctb_visit.duration = appModule.defaultVisitDuration;
      orderModule.setVisitCTB(order.take_ctb_visit);
    }
  }

  workerSelected(e) {
    console.log('workerSelected e', e);
    orderModule.order.visit.laborant_id = e.id;
    orderModule.order.take_ctb_visit.laborant_id = e.id;

    orderModule.setVisitPIB(orderModule.order.visit);
    orderModule.setVisitCTB(orderModule.order.take_ctb_visit);
    this.fillUser();
  }

  fillMainDate() {
    if (orderModule.order.visit.date !== null) {
      this.mainVisitDate = orderModule.order.visit.date;

      this.mainVisitDateText = moment(
        orderModule.order.visit.date,
        orderModule.order.visit.date.includes('-') ? consts.DB_DATE_TIME_FORMAT : consts.DATE_FORMAT
      ).format(consts.DATE_FORMAT);
    }
  }
  onMainDateContext(ctx) {
    console.log('DATE onMainDateContext ctx', ctx);
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) {
        orderModule.order.visit.date = dt.format(consts.DB_DATE_TIME_FORMAT);
        orderModule.setVisitPIB(orderModule.order.visit);
        this.changeAuto(this.isAuto);
      }
    } else {
      this.mainVisitDateText = ctx.selectedFormatted;
    }
    this.fillMainDate();
  }
  modalChooseMainTimeOK(time: string) {
    console.log('Main time', time);
    orderModule.order.visit.start_time = time;
    orderModule.setVisitPIB(orderModule.order.visit);
    this.changeAuto(this.isAuto);
  }

  onTakeDateContext(ctx) {
    console.log('DATE onTakeDateContext ctx', ctx);
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) {
        orderModule.order.take_ctb_visit.date = dt.format(consts.DB_DATE_TIME_FORMAT);
        orderModule.setVisitCTB(orderModule.order.take_ctb_visit);
        this.takeVisitDateText = dt.format(consts.DATE_FORMAT);
      }
    } else {
      this.takeVisitDateText = ctx.selectedFormatted;
    }
  }
  modalChooseTakeTimeOK(time: string) {
    console.log('take Time ', time);
    orderModule.order.take_ctb_visit.start_time = time;
    orderModule.setVisitCTB(orderModule.order.take_ctb_visit);
    this.changeAuto(this.isAuto);
  }

  mainDurationSelected(e) {
    console.log('DurationSelected e', e);
    const minutes = e * this.durationFactor;
    orderModule.order.visit.duration = minutes;
    orderModule.setVisitPIB(orderModule.order.visit);
    this.changeAuto(this.isAuto);
  }

  takeDurationSelected(e) {
    console.log('TakeDurationSelected e', e);
    const minutes = e * this.durationFactor;
    orderModule.order.take_ctb_visit.duration = minutes;
    orderModule.setVisitCTB(orderModule.order.take_ctb_visit);
    this.changeAuto(this.isAuto);
  }

  get getVisitDurations() {
    return Array.from({ length: 24 }, (v, i) => i + 1);
  }

  created() {
    console.log('OrderVisit mounted MODE:', this.mode);
    const order = orderModule.order;
    if (order !== null) {
      console.log('Created OrderVisit order is not null, date', order.visit.date);
      if (this.mode !== consts.PageMode.NEW) {
        const dt = order.visit.date;
        const dtTake = order.take_ctb_visit.date;
        console.log('MAIN Date:', dt);
        this.mainVisitDate = dt;
        this.mainVisitDateText = this.mainVisitDate;
        this.mainVisitStartTime = order.visit.start_time;

        console.log('Take Date:', dtTake);
        this.takeVisitDate = dtTake;
        this.takeVisitDateText = dtTake;
        this.takeVisitStartTime = order.take_ctb_visit.start_time;

        console.log('date time 1', this.mainVisitDate, this.mainVisitStartTime);
      }
      this.fillUser();
      console.log(
        'order.visit.start_time',
        order.visit.start_time,
        'order.take_ctb_visit.start_time',
        order.take_ctb_visit.start_time
      );
      if (order.visit.start_time) {
        this.mainVisitStartTime = order.visit.start_time;
      }
      if (order.take_ctb_visit.start_time) {
        this.takeVisitStartTime = order.take_ctb_visit.start_time;
      }
    }
  }
  get start() {
    console.log('%c---------- Start of template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of template -----------', 'color:green;font-weight:600');
    return '';
  }
}
