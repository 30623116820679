
























































































































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

import { DB_DATE_FORMAT, ModalEvents, DATE_FORMAT } from '@/consts';

interface DateObject {
  from: {
    value: string;
    datepicker: string;
  };
  to: {
    value: string;
    datepicker: string;
  };
}

@Component
export default class ModalOrdersFilter extends Vue {
  clientName = '';
  clientShortName = '';
  clientCode = '';
  orderNumber = '';
  clientPostalCode = '';
  cPlace = '';
  orderDate = this.newDateObject;
  visitDate = this.newDateObject;

  enabled = false;
  show = false;
  btnSave = 0;
  filters: { field: string; value: any }[] = [];

  dateFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  get newDateObject(): DateObject {
    return { from: { value: '', datepicker: '' }, to: { value: '', datepicker: '' } };
  }

  dateInput(date: string, dateObject: { value: string }) {
    if (date === '') {
      dateObject.value = '';
    } else {
      dateObject.value = moment(date, DB_DATE_FORMAT).format(DATE_FORMAT);
    }
  }

  showModal() {
    this.btnSave++;
    this.show = true;
  }
  addFilter(field: string, value: string | DateObject) {
    if (value !== '' && JSON.stringify(value) !== JSON.stringify(this.newDateObject)) {
      this.filters.push({ field: field, value: value });
    } else {
      this.filters = this.filters.filter(f => f.field !== field);
    }
  }
  saveModal() {
    this.filters = [];

    this.addFilter('client.name', this.clientName);
    this.addFilter('client.short_name', this.clientShortName);
    this.addFilter('client.code', this.clientCode);
    this.addFilter('number', this.orderNumber);
    this.addFilter('cplace', this.cPlace);
    this.addFilter('date', this.orderDate);
    this.addFilter('visit.date', this.visitDate);

    this.$emit(ModalEvents.OK_EVENT, this.filters);
    this.show = false;
  }
  clear() {
    this.clientShortName = '';
    this.clientName = '';
    this.clientCode = '';
    this.cPlace = '';
    this.orderNumber = '';
    this.orderDate = this.newDateObject;
    this.visitDate = this.newDateObject;
  }
  hideModal() {
    this.$emit(ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
