import store from '@/store';
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import * as api from '@/store/api';
import { Order, Mixer, Visit } from '@/types';
import { OrderStatus, VisitStatus } from '@/consts';
import ordersModule from './orders';
import moment from 'moment';
moment.locale('pl', { week: { dow: 1 } });

@Module({
  namespaced: true,
  name: 'order_item',
  store,
  dynamic: true,
})
class OrderModule extends VuexModule {
  public order: Order = null;
  rowsPerPage = 20;

  @Mutation
  clear() {
    this.order = null;
    console.log('order module cleared...');
  }

  @Mutation
  setRowsPerPage(rowsPerPage: number): any {
    this.rowsPerPage = rowsPerPage;
  }

  @Mutation
  createEmptyOrder() {
    console.log('OrderModule::create empty order');
    this.order = new Order();
  }

  @Mutation
  setOrder(order: Order) {
    this.order = order;
  }
  @Mutation
  setVisitPIB(visit: Visit) {
    Object.assign(this.order.visit, visit);
  }
  @Mutation
  setVisitCTB(visit: Visit) {
    Object.assign(this.order.take_ctb_visit, visit);
  }

  @Mutation
  setMixers(mixers: Mixer[]) {
    this.order.visit.collect_and_exam_protocol.mixers = [...mixers];
  }

  @Action
  async saveOrder(order: Order | undefined) {
    if (order !== undefined) {
      console.log('saveOrder', order);
      const response = await api.saveOrder(order);
      console.log('store.order.Response:', response);
      //ordersModule.addOrder(order);
      if (response.data.result === true) {
        console.log('order stored');
        return order;
      }
    }
    return undefined;
  }
}

export default getModule(OrderModule);
