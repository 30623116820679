

























































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';

import TopPanel from '../../../components/dummy/TopPanel.vue';
import Header from '../../../components/dummy/Header.vue';
import Footer from '../../../components/dummy/Footer.vue';
import ModalExternalUser from '../../../components/spec/ModalExternalUser.vue';
import ModalQuestion from '../../../components/dummy/ModalQuestion.vue';

import breadcrumbModule from '../../../store/modules/breadcrumb';
import usersModule from '../../../store/modules/users';
import dictModule from '../../../store/modules/dict';

import { SortDir } from '../../../consts';
import { User } from '../../../types';
import * as helpers from '../../../helpers';

@Component({
  components: {
    TopPanel,
    Header,
    Footer,
    ModalExternalUser,
    ModalQuestion,
  },
})
export default class UserList extends Vue {
  @Ref() readonly modalExternalUser: ModalExternalUser;

  btnPrevVisible = false;
  btnNextVisible = true;

  pageSize = 20;
  pageNo = 1;
  allPagesCount = 0;
  filterParams: { field: ''; value: any }[] = [];
  sortParams = { field: 'lastname', dir: SortDir.ASC };
  spinner = false;
  users: User[] = [];
  idToDeactivate = 0;
  idToActivate = 0;

  // ------------- GETTERS --------------
  async fillUsers() {
    this.spinner = true;

    await usersModule.fetchUsers();
    this.users = [];
    let users = usersModule.users;
    if (this.filterParams.length > 0) {
      users = this.filterUser(users, this.filterParams);
    }
    users = this.sortUser(users, this.sortParams.field, this.sortParams.dir);
    this.allPagesCount = Math.ceil(users.length / this.pageSize);
    this.users = this.paginate(users, this.pageSize, this.pageNo) as User[];
    this.spinner = false;
    this.update();
  }

  progressValue = 0;
  progressMax = 100;
  uploading = false;

  getUserClientShortName(user) {
    if (user.client_id === undefined) {
      return '-';
    } else {
      if (user.client_id !== null && user.client_id !== 0) {
        const client = dictModule.clientById(user.client_id);
        return client.short_name;
      } else {
        return '-';
      }
    }
  }
  addExternalUser() {
    this.modalExternalUser.showModal('add');
  }
  editUser(user) {
    this.modalExternalUser.showModal('edit', user);
  }
  tryDeactivatingExternalUser(id: number) {
    this.idToDeactivate = id;
    const question = 'Czy na pewno chcesz zdeaktywować użytkownika?';
    (this.$refs.deactivateModalQuestion as ModalQuestion).showModal({ question });
  }
  async deactivateExternalUser() {
    const response = await usersModule.deactivateUser(this.idToDeactivate);
    if (response.data.success === false) {
      helpers.error('Wystąpił błąd w trakcie komunikacji z serwerem');
      return;
    }
    helpers.info('Użytkownik zdeaktywowany');
  }
  tryActivatingExternalUser(id: number) {
    this.idToActivate = id;
    const question = 'Czy na pewno chcesz aktywować użytkownika?';
    (this.$refs.activateModalQuestion as ModalQuestion).showModal({ question });
  }
  async activateExternalUser() {
    const response = await usersModule.activateUser(this.idToActivate);
    if (response.data.success === false) {
      helpers.error('Wystąpił błąd w trakcie komunikacji z serwerem');
      return;
    }
    helpers.info('Użytkownik aktywowany');
  }

  // ----------------- INTERNAL ACTIONS ----------------
  prevPage() {
    this.pageNo--;
    this.fillUsers();
  }

  nextPage() {
    this.pageNo++;
    this.fillUsers();
  }

  changePageSize() {
    this.update();
    this.fillUsers();
  }

  filterPage() {
    console.log('FilterModal');
    this.modalExternalUser.showModal('filter');
  }

  sortPage(sortField = 'id') {
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.fillUsers();
  }

  update() {
    this.btnNextVisible = this.pageNo !== this.allPagesCount;
    this.btnPrevVisible = this.pageNo !== 1;
  }

  sortUser(array: User[], field: string, dir: SortDir): User[] {
    switch (field) {
      case 'firstname':
      case 'lastname':
      case 'position':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a[field].localeCompare(b[field]);
          } else {
            return b[field].localeCompare(a[field]);
          }
        });
      case 'client_id':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return this.getUserClientShortName(a).localeCompare(this.getUserClientShortName(b));
          } else {
            return this.getUserClientShortName(b).localeCompare(this.getUserClientShortName(a));
          }
        });
      case 'email':
      case 'module':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a[field] < b[field] ? -1 : 1;
          } else {
            return a[field] > b[field] ? -1 : 1;
          }
        });
      default:
        return array;
    }
  }

  filterUser(users: User[], filters: { field: string; value: any }[]): User[] {
    let result = false;
    return users.filter(client => {
      result = true;
      for (const filter of filters) {
        if (typeof filter.value === 'string') {
          result = client[filter.field].toLowerCase().includes(filter.value.toLowerCase());
        } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
          result = client[filter.field] === filter.value;
        }

        if (result === false) {
          break;
        }
      }
      return result;
    });
  }

  paginate(array: User[], pageSize: number, pageNumber: number): object[] {
    pageNumber--;
    const rowFrom: number = pageNumber * pageSize;
    const rowTo: number = pageNumber * pageSize + pageSize;
    return array.slice(rowFrom, rowTo);
  }

  modalExternalUserOK(filters: any) {
    console.log('modalClientFilterOK FILTERS:', filters);
    this.filterParams = filters;
    this.pageNo = 1;
    this.fillUsers();
  }
  modalExternalUserCancel() {
    console.log('modalClientFilterCancel');
  }

  created() {
    console.log('%c*********************** UsersList creating ***********************', 'color:red');
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Lista użytkowników',
          link: '',
        },
      ],
    });
    this.fillUsers();
    console.log('%c*********************** UsersList created ***********************', 'color:red');
  }
}
