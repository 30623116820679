



















































































































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import moment from 'moment';

import * as consts from '../../consts';
import * as helpers from '../../helpers';
import VisitProtocolPIBPrint from '../../views/laborant/visits/VisitProtocolPIBPrint.vue';
import ClientTopPanel from '../../components/dummy/ClientTopPanel.vue';
import ClientHeader from '../../components/dummy/ClientHeader.vue';
import Footer from '../../components/dummy/Footer.vue';
import ModalResultsDocFilter from '../../components/spec/ModalResultsDocFilter.vue';
import ModalResultsDoc from '../../components/spec/ModalResultsDoc.vue';

import userModule from '../../store/modules/user';
import collectsModule from '../../store/modules/collects';
import mixersModule from '../../store/modules/mixers';
import samplesModule from '../../store/modules/samples';
import visitsModule from '../../store/modules/visits';
import visitModule from '../../store/modules/visit';
import protocolsModule from '../../store/modules/protocols';
import dictModule from '../../store/modules/dict';
import examsModule from '../../store/modules/exams';
import resultsDocModule from '../../store/modules/results_doc';
import { Visit } from '../../types';
import { EventBus, BusEvents } from '../../helpers/eventbus';
import ResultsDocCPrint from '../../views/laborant/exams/ResultsDocCPrint.vue';

import { SortDir, DATE_FORMAT, DB_T_DATE_TIME_FORMAT, DB_DATE_FORMAT, UserModule } from '../../consts';
import { Exam, User, CollectAndExamProtocol, Sample } from '../../types';

const NO_SUCH_EXAM = 1;
const NO_SUCH_VISIT = 1;

@Component({
  components: {
    ClientTopPanel,
    ClientHeader,
    Footer,
    ModalResultsDocFilter,
    ModalResultsDoc,
    VisitProtocolPIBPrint,
    ResultsDocCPrint,
  },
})
export default class ClientResultsDocsList extends Vue {
  @Ref() readonly modalResultsDocFilter: ModalResultsDocFilter;
  @Ref() readonly modalResultsDoc: ModalResultsDoc;

  btnPrevVisible = false;
  btnNextVisible = true;
  isClient = false;
  enabled = true;

  pageSize = 20;
  pageNo = 1;
  allPagesCount = 0;
  filterParams: { field: string; value: any }[] = [];
  sortParams = { field: 'number', dir: SortDir.ASC };
  spinner = false;
  exams: Exam[] = [];
  protocols: CollectAndExamProtocol[] = [];
  prot_selected = false;

  expanded: Array<boolean> = [];
  expandProt(index: number) {
    this.expanded[index] = !this.expanded[index];
    console.log('Expand ', index, this.expanded[index]);
    this.$forceUpdate();
  }

  currentCPlace = {}; // wybrana budowa
  cPlaces = []; // budowy klienta
  selectCPlace(e: KeyboardEvent) {
    // wybrana budowa
    this.currentCPlace = e;
    this.pageNo = 1;
    this.fillProtocols();
  }

  initializeVisit(prot) {
    const editVisit = visitsModule.visitCopyById(parseInt(prot.visit_id));
    console.log('PDF Visit', editVisit);
    console.log('%cVisit from vuex visits', 'color:red');
    (editVisit as Visit).load();
    console.log('Visit loaded', editVisit);
    visitModule.setVisit(editVisit);
  }

  isProtDownloaded(prot) {
    const visit = visitsModule.visitById(prot.visit_id);
    return visit.is_prot_downloaded;
  }

  async pdf(prot) {
    this.prot_selected = true;
    this.initializeVisit(prot);
    this.$forceUpdate();
    console.log('PDF');
    this.$nextTick(() => {
      EventBus.$emit(BusEvents.PRINT_PDF);
    });
    const visit = visitsModule.visitById(prot.visit_id);
    if (visit.is_prot_downloaded === false) {
      const response = await visitsModule.updateVisitIsProtDownloaded(visit);
      if (response.data.success === false && response.data.error_code === NO_SUCH_VISIT) {
        console.log('No visit with such id in database');
        helpers.error(
          'Nie powiodło się oznaczenie protokołu jako pobranego - skontaktuj się z administratorem systemu'
        );
        return;
      }
    }
  }

  get resultsDocs() {
    return prot => {
      //console.log('resultsDocs', prot.id);
      const protSamples = this.protSamples(prot);
      const examsId = [];
      protSamples.forEach(sample => {
        //console.log('Sample', sample.number, sample.exam_id);
        if (sample.exam_id && !examsId.includes(sample.exam_id)) {
          //console.log('sample exam_id:', sample.exam_id);
          examsId.push(sample.exam_id);
        }
      });
      //const examsId = examsModule.exams.filter(e => e.protocol_id === prot.id).map(x => x.id);
      //console.log('Exams_id', examsId);
      let rds = resultsDocModule.resultsDocs.filter(rd => {
        //console.log('rd.exam_id', rd.exam_id);
        return examsId.includes(rd.exam_id);
      });
      if (this.filterParams.length > 2) {
        let result = true;
        const filteredRds = rds.filter(rd => {
          result = true;
          this.filterParams.forEach(filter => {
            if (result) {
              if (filter.field === 'results_doc_number') {
                if (filter.value !== '') {
                  result = rd.number.includes(filter.value);
                  return result;
                } else {
                  return result;
                }
              }
              if (filter.field === 'resultsDoc_date_from') {
                if (filter.value !== '') {
                  result = moment(rd.created, DB_DATE_FORMAT).isSameOrAfter(moment(filter.value, DB_DATE_FORMAT));
                  return result;
                } else {
                  return result;
                }
              }
              if (filter.field === 'resultsDoc_date_to') {
                if (filter.value !== '') {
                  result = moment(rd.created, DB_DATE_FORMAT).isSameOrBefore(moment(filter.value, DB_DATE_FORMAT));
                  return result;
                } else {
                  return result;
                }
              }
              if (filter.field === 'method') {
                if (filter.value !== '') {
                  result = this.getDestinyNorm(rd.destiny).includes(filter.value);
                  return result;
                } else {
                  return result;
                }
              } else {
                return result;
              }
            }
          });
          return result;
        });
        rds = filteredRds;
      }

      rds.forEach(rd => {
        rd.load();
        rd.number = rd.number.replace('_', '/');
      });
      //console.log('rds', rds.length, rds);
      return rds;
    };
  }

  get userID() {
    return userModule.user.id;
  }
  get clientID() {
    return userModule.userData.client_id;
  }
  get clientCode() {
    return userModule.userData.client_code;
  }
  get visit() {
    return prot => {
      return visitsModule.visitById(prot.visit_id);
    };
  }
  get getDestinyName() {
    return rd => {
      const exam = examsModule.exams.find(e => e.id === rd.exam_id);
      //console.log('getDestinyName Exam', exam);
      const destiny = dictModule.destinies.find(d => d.id === exam.destiny_id);
      //console.log(' Destiny', destiny);
      return destiny.description;
    };
  }
  get getDestiny() {
    return rd => {
      const exam = examsModule.exams.find(e => e.id === rd.exam_id);
      const destiny = dictModule.destinies.find(d => d.id === exam.destiny_id);
      return destiny;
    };
  }

  get protSamples() {
    return prot => {
      const samples = [];
      const mixers = mixersModule.mixers.filter(m => prot.mixer_ids.includes(m.id));
      //console.log('mixers', mixers);
      mixers.forEach(mixer => {
        const collects = collectsModule.collects.filter(c => mixer.collect_ids.includes(c.id));
        //console.log('Collects', collects);
        collects.forEach(collect => {
          samples.push(...samplesModule.samples.filter(s => collect.sample_ids.includes(s.id)));
        });
      });
      //console.log('Samples', samples);
      return samples;
    };
  }

  get examSamples() {
    return exam => {
      return samplesModule.samples.filter(s => exam.sample_ids.includes(s.id));
    };
  }

  get protDestiniesIds() {
    return prot => {
      return this.protSamples(prot).map(s => s.destiny_id);
    };
  }

  get getDestinyNorm() {
    return dst => {
      return dictModule.destinyGroupById(dst.group_id).description;
    };
  }

  get ifProtocols() {
    if (this.protocols) {
      if (this.protocols.length > 0) {
        return true;
      }
    }
    return false;
  }

  modalProtocolFilterOK(filters) {
    console.log('modalProtocolFilterOK', filters);
    this.filterParams = filters;
    this.fillProtocols();
  }
  modalProtocolFilterCancel() {
    console.log('modalProtocolFilterCancel');
  }

  EMPTY_INVESTMENTS = { name: '- brak inwestycji -', short_name: '- brak inwestycji -', id: 0 };
  CPLACE_NAME_TRUNCATE_LENGTH = 65;
  fillConstructionPlaces() {
    dictModule.fetchConstructionPlaces();
    this.cPlaces = dictModule.cPlacesByClientId(this.clientID);
    console.log('cPlaces from vuex', this.cPlaces);
    this.cPlaces = this.cPlaces.filter(cp => cp.users_ids.includes(this.userID));
    this.cPlaces.forEach(cp => {
      cp.short_name = cp.name.substring(0, this.CPLACE_NAME_TRUNCATE_LENGTH);
      if (cp.name.length > this.CPLACE_NAME_TRUNCATE_LENGTH) {
        cp.short_name += '...';
      }
    });
    if (this.cPlaces.length === 0) {
      this.cPlaces.push(this.EMPTY_INVESTMENTS);
      this.currentCPlace = this.EMPTY_INVESTMENTS;
      this.enabled = false;
    } else {
      this.currentCPlace = this.cPlaces[0];
      this.fillProtocols();
    }
  }

  fillProtocols() {
    console.log('fillProtocol..');
    this.spinner = true;
    const selCPlace = this.currentCPlace;

    this.spinner = true;
    this.protocols = [];
    protocolsModule.fetchPIBProtocols();
    let protocols = protocolsModule.protocolsByClientCode(this.clientCode);
    protocols = protocols.filter(prot => this.getVisitStatus(prot));
    console.log('protocolsByCientCode', this.clientCode, protocols.length, protocols);
    const cplace_filter_index = this.filterParams.findIndex(fp => fp.field === 'cplace_id');
    if (cplace_filter_index === -1) {
      this.filterParams.push({ field: 'cplace_id', value: selCPlace['id'] });
    } else {
      this.filterParams[cplace_filter_index].value = selCPlace['id'];
    }
    if (this.filterParams.length > 0) {
      console.log('FILL Filters', this.filterParams);
      protocols = this.filterProtocols(protocols, this.filterParams);
      console.log('After filtering', protocols);
    }
    protocols = this.sortProtocols(protocols, this.sortParams.field, this.sortParams.dir);

    this.allPagesCount = Math.ceil(protocols.length / this.pageSize);
    this.protocols = this.paginate(protocols, this.pageSize, this.pageNo);
    this.update();
    this.expanded = [];
    for (let index = 0; index < this.protocols.length; index++) {
      this.expanded[index] = false;
    }
    if (this.protocols.length) {
      this.initializeVisit(this.protocols[0]);
    }
    this.spinner = false;
  }

  filterProtocols(protocols: CollectAndExamProtocol[], filters: { field: string; value: any }[]) {
    let result = true;
    filters.forEach(f => {
      console.log('Filter: ', f.field, '=', f.value);
    });
    const prots = protocols.filter(protocol => {
      console.log('-------- Protocol', protocol.id, protocol.number, protocol.client_code);
      result = true;
      filters.forEach((filter, index) => {
        console.log(' ### Filter', filter.field, filter.value, index, result);
        if (result) {
          if (filter.field === 'cplace_id') {
            const visit = visitsModule.visitById(protocol.visit_id);
            result = visit.cplace_id === filter.value;
            console.log('   cplace_id', filter.value, '=', result);
            return result;
          }
          if (filter.field === 'number') {
            result = protocol.number.includes(filter.value);
            console.log('   protocol number', filter.value, '=', result);
            return result;
          }
          if (filter.field === 'results_doc_number') {
            if (filter.value !== '') {
              result = this.resultsDocs(protocol).length > 0;
              console.log('   results_doc_number', filter.value, '=', result);
              return result;
            } else {
              console.log('   Filter results_doc_number empty');
              return result;
            }
          }

          if (filter.field === 'destiny') {
            if (filter.value !== '') {
              result = this.protDestiniesIds(protocol).includes(filter.value);
              console.log('   destiny', filter.value, '=', result);
              return result;
            } else {
              console.log('   Filter destiny empty');
              return result;
            }
          }
          if (filter.field === 'recipie_number') {
            if (filter.value !== '') {
              result = protocol.recipie_no.includes(filter.value);
              console.log('   recipie_number', filter.value, '=', result);
              return result;
            } else {
              console.log('   Filter recipie_number empty');
              return result;
            }
          }
          if (filter.field === 'take_date_from') {
            if (filter.value !== '') {
              result = moment(this.visit(protocol).date, DB_DATE_FORMAT).isSameOrAfter(
                moment(filter.value, DB_DATE_FORMAT)
              );
              console.log('   take_date_from', filter.value, '=', result);
              return result;
            } else {
              console.log('   Filter take_date_from empty');
              return result;
            }
          }
          if (filter.field === 'take_date_to') {
            if (filter.value !== '') {
              result = moment(this.visit(protocol).date, DB_DATE_FORMAT).isSameOrBefore(
                moment(filter.value, DB_DATE_FORMAT)
              );
              console.log('   take_date_to', filter.value, '=', result);
              return result;
            } else {
              console.log('   Filter take_date_to empty');
              return result;
            }
          }
          if (filter.field === 'resultsDoc_date_from') {
            if (filter.value !== '') {
              result = this.resultsDocs(protocol).length > 0;
              console.log('   resultsDoc_date_from', filter.value, '=', result);
              return result;
            } else {
              console.log('  Filter resultsDoc_date_from empty');
              return result;
            }
          }
          if (filter.field === 'resultsDoc_date_to') {
            if (filter.value !== '') {
              result = this.resultsDocs(protocol).length > 0;
              console.log('   resultsDoc_date_to', filter.value, '=', result);
              return result;
            } else {
              console.log('   Filter resultsDoc_date_to empty');
              return result;
            }
          }
          if (filter.field === 'method') {
            if (filter.value !== '') {
              result = this.resultsDocs(protocol).length > 0;
              console.log('   method', filter.value, '=', result);
              return result;
            } else {
              console.log('   Filter method empty');
              return result;
            }
          }
          const fieldSplitted = filter.field.split('.');
          if (fieldSplitted.length === 1) {
            if (typeof filter.value === 'string') {
              result = protocol[filter.field] === filter.value;
              console.log(' 1', filter.field, protocol[filter.field], ' ?', filter.value, '=>', result);
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              result = protocol[filter.field] === filter.value;
              console.log(' 2', '=>', result);
            }
          } else if (fieldSplitted.length === 2) {
            if (typeof filter.value === 'string') {
              result = protocol[fieldSplitted[0]][fieldSplitted[1]].includes(filter.value);
              console.log(' 3', filter.field, protocol[filter.field], ' ?', filter.value, '=>', result);
            } else if (typeof filter.value === 'number' || typeof filter.value === 'boolean') {
              result = protocol[fieldSplitted[0]][fieldSplitted[1]] === filter.value;
              console.log(' 4', filter.field, protocol[filter.field], ' ?', filter.value, '=>', result);
            }
          }
        }
      });
      return result;
    });
    console.log('Prots', prots);
    return prots;
  }

  get protocol() {
    return (sample: Sample): CollectAndExamProtocol => {
      const collect = collectsModule.collectById(sample.collect_id);
      const mixer = mixersModule.mixerById(collect.mixer_id);
      const prot = protocolsModule.protocolById(mixer.protocol_id);

      return prot as CollectAndExamProtocol;
    };
  }
  get getRecipie() {
    return (exam: Exam): string => {
      if (exam.samples.length > 0) {
        return this.protocol(exam.samples[0]).recipie_no;
      } else {
        return '-';
      }
    };
  }
  getResultDocUrl(filename: string) {
    if (filename === undefined) {
      return '#';
    }
    return `${process.env.VUE_APP_API_URL}media/results-docs/${filename}`;
  }

  isRdDownloaded(rd) {
    if (rd.exam_id !== null) {
      const exam = examsModule.examById(rd.exam_id);
      return exam.is_rd_downloaded;
    } else {
      return true;
    }
  }

  async markRdAsDownloaded(rd) {
    if (this.isRdDownloaded(rd) === false) {
      const exam = examsModule.examById(rd.exam_id);
      const response = await examsModule.updateExamIsRdDownloaded(exam);
      if (response.data.success === false && response.data.error_code === NO_SUCH_EXAM) {
        console.log('No exam with such id in database');
        helpers.error(
          'Nie powiodło się oznaczenie sprawozdania jako pobranego - skontaktuj się z administratorem systemu'
        );
        return;
      }
    }
  }

  getVisitStatus(prot) {
    const protVisit = visitsModule.visitById(prot.visit_id);
    let visitStatus = new consts.VisitStatus(null, '', '', '');
    if (protVisit.collect_and_exam_protocol_id !== undefined) {
      if (protVisit.status_id === consts.VisitStatus.VERIFIED.id && prot.authorise_person_id !== null) {
        visitStatus.name = consts.VisitStatusAuthorised.NAME;
        visitStatus.badge = consts.VisitStatusAuthorised.BADGE;
      } else {
        visitStatus = consts.VisitStatus.byId(protVisit.status_id);
      }
    } else {
      visitStatus = consts.VisitStatus.byId(protVisit.status_id);
    }
    return visitStatus.name === consts.VisitStatusAuthorised.NAME;
  }

  isAuthorised(exam_id: number) {
    const exam = examsModule.examById(exam_id);
    return exam.is_authorised;
  }

  get user(): User {
    return userModule.user;
  }
  prevPage() {
    this.pageNo--;
    this.fillProtocols();
  }
  nextPage() {
    this.pageNo++;
    this.fillProtocols();
  }
  changePageSize() {
    this.update();
    this.fillProtocols();
  }
  filterPage() {
    this.modalResultsDocFilter.showModal(this.filterParams.slice(0, 2));
    this.fillProtocols();
  }
  sortPage(sortField = 'id') {
    if (this.sortParams.field !== sortField) {
      this.sortParams.dir = SortDir.ASC; // pierwszy raz zawsze ASC
    } else {
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    }
    this.sortParams.field = sortField;
    this.fillProtocols();
  }
  update() {
    this.btnNextVisible = this.pageNo !== this.allPagesCount;
    this.btnPrevVisible = this.pageNo !== 1;
  }

  sortProtocols(array: CollectAndExamProtocol[], field: string, dir: SortDir): CollectAndExamProtocol[] {
    console.log('Sorting ', field, dir);
    switch (field) {
      case 'number':
      case 'recipie_no':
        if (dir === SortDir.ASC) {
          return array.sort((a, b) => (a[field] < b[field] ? 1 : -1));
        } else {
          return array.sort((a, b) => (a[field] > b[field] ? 1 : -1));
        }
      case 'visit_date':
        return array.sort((a, b) => {
          console.log(
            moment(this.visit(a).date, DB_T_DATE_TIME_FORMAT),
            moment(this.visit(b).date, DB_T_DATE_TIME_FORMAT)
          );
          if (dir === SortDir.ASC) {
            return moment(this.visit(a).date, DB_T_DATE_TIME_FORMAT).isAfter(
              moment(this.visit(b).date, DB_T_DATE_TIME_FORMAT)
            )
              ? -1
              : 1;
          } else {
            return moment(this.visit(a).date, DB_T_DATE_TIME_FORMAT).isBefore(
              moment(this.visit(b).date, DB_T_DATE_TIME_FORMAT)
            )
              ? -1
              : 1;
          }
        });
      case 'created':
        return array.sort((a, b) => {
          console.log(moment(a[field], DB_T_DATE_TIME_FORMAT), moment(b[field], DB_T_DATE_TIME_FORMAT));
          if (dir === SortDir.ASC) {
            return moment(a[field], DB_T_DATE_TIME_FORMAT).isAfter(moment(b[field], DB_T_DATE_TIME_FORMAT)) ? -1 : 1;
          } else {
            return moment(a[field], DB_T_DATE_TIME_FORMAT).isBefore(moment(b[field], DB_T_DATE_TIME_FORMAT)) ? -1 : 1;
          }
        });
      case 'destiny.name':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a['destiny']['name'] < b['destiny']['name'] ? 1 : -1;
          } else {
            return a['destiny']['name'] > b['destiny']['name'] ? 1 : -1;
          }
        });
      case 'short_name':
        return array.sort((a, b) => {
          if (dir === SortDir.ASC) {
            return a['client']['short_name'] < b['client']['short_name'] ? 1 : -1;
          } else {
            return a['client']['short_name'] > b['client']['short_name'] ? 1 : -1;
          }
        });
      default:
        return array;
    }
  }

  paginate(array: CollectAndExamProtocol[], pageSize: number, pageNumber: number): CollectAndExamProtocol[] {
    console.log('Paginate', pageSize, pageNumber);
    pageNumber--;
    const rowFrom = pageNumber * pageSize;
    const rowTo = pageNumber * pageSize + pageSize;
    let index = 1;
    array.forEach(e => {
      e['index'] = index++;
    });
    return array.slice(rowFrom, rowTo);
  }

  getFormattedDate(date: string): string {
    if (
      moment(date, consts.DB_T_S_DATE_TIME_FORMAT, true).isValid() ||
      moment(date, consts.DB_T_S_MS_DATE_TIME_FORMAT, true).isValid()
    ) {
      return moment(date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    } else {
      return date;
    }
  }

  async created() {
    console.log('%c*********************** Protocol list creating ***********************', 'color:red');

    const module = parseInt(userModule.getUserData.module);

    if (this.userID !== null && module === UserModule.CLIENT) {
      this.isClient = true;
      this.filterParams.push({ field: 'client_code', value: this.clientCode });
    }

    await this.fillConstructionPlaces();
    console.log('CPlaces:', this.cPlaces);

    console.log('%c*********************** Protocol list created ***********************', 'color:red');
  }
  get start() {
    console.log('%c---------- Start of template -----------', 'color:green;font-weight:600');
    return '';
  }
  get end() {
    console.log('%c---------- End of template -----------', 'color:green;font-weight:600');
    return '';
  }
}
