









































































































































































































import { Component } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import TopPanel from '../../../components/dummy/TopPanel.vue';
import VisitHeader from '../../../views/laborant/visits/VisitHeader.vue';
import Footer from '../../../components/dummy/Footer.vue';
import VisitTree from '../../../views/laborant/visits/VisitTree.vue';

import visitModule from '../../../store/modules/visit';
import { PageMode, UserRightSymbols, ProtocolType } from '../../../consts';
import * as helpers from '../../../helpers';

import visitsModule from '../../../store/modules/visits';
import ordersModule from '../../../store/modules/orders';
import usersModule from '../../../store/modules/users';
import userModule from '../../../store/modules/user';
import toolsModule from '../../../store/modules/tools';
import { Mixer, Visit, BreadcrumbItem } from '../../../types';

import VisitProtocolPIB from '../../../views/laborant/visits/VisitProtocolPIB.vue';
import VisitCTB from '../../../views/laborant/visits/VisitProtocolCTB.vue';
import VisitProtocolCollect from '../../../views/laborant/visits/VisitProtocolCollect.vue';
import VisitMixer from '../../../views/laborant/visits/VisitMixer.vue';
import VisitCollect from '../../../views/laborant/visits/VisitCollect.vue';
import VisitSampleList from '../../../views/laborant/visits/VisitSampleList.vue';

import moment from 'moment';
import * as consts from '../../../consts';
import { EventBus, BusEvents } from '../../../helpers/eventbus';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import visitMixin from '../../../helpers/components/visit';

import TimeInput from '../../../components/dummy/TimeInput.vue';

const CLIENT_NAMING_BOSTA_A = '80';
const CLIENT_NAMING_BOSTA_B = '454';

@Component({
  components: {
    TopPanel,
    Footer,
    VisitHeader,
    Multiselect,
    VisitTree,
    VisitProtocolPIB,
    VisitCTB,
    VisitProtocolCollect,
    VisitMixer,
    VisitCollect,
    VisitSampleList,
    TimeInput,
  },
})
export default class VisitViewMain extends visitMixin {
  ProtocolType = ProtocolType;
  PageMode = PageMode;

  mainVisitDate = '';
  mainVisitDateText = '';
  mainVisitStartTime = '';
  locale: 'pl-PL';

  mode = '';
  revalidate = 0;
  numberRegisters = [];
  selectedCarNumber = '';

  isClientNaming = false;
  mainTimeInvalidate = 0;
  headerKey = 0;

  redirectedFromSynchro() {
    return this.$router.currentRoute.params.isDirectedBySynchro === 'y';
  }

  returnToSynchro() {
    this.$router.push({ name: 'Synchro' });
  }

  get clientData() {
    return this.visit.client_data;
  }
  set clientData(value: boolean) {
    this.visit.client_data = value;
    this.updateVisit();
  }

  get clientCPlaceSymbol() {
    return this.visit.client_cplace_symbol;
  }
  set clientCPlaceSymbol(value: string) {
    this.visit.client_cplace_symbol = value;
    this.updateVisit();
  }

  get clientVisitIndex() {
    return this.visit.client_visit_index;
  }
  set clientVisitIndex(value: number) {
    this.visit.client_visit_index = value;
    this.updateVisit();
  }

  get carSel() {
    return this.selectedCarNumber;
  }

  fillCars() {
    const cars = toolsModule.cars;
    cars.sort((a, b) => {
      return parseInt(a.number) > parseInt(b.number) ? 1 : -1;
    });
    cars.forEach(c => {
      this.numberRegisters.push(c.number + ' - ' + c.register_number);
    });
  }
  carSelected(e) {
    this.selectedCarNumber = e;
    this.visit.car_number = e.split('-')[0].trim();
    this.updateVisit();
  }

  get isCoord() {
    return userModule.hasRightBySymbol(consts.UserRightSymbols.VISIT_COORD);
  }
  get visitOrderNumber() {
    return ordersModule.orderById(this.visit.order_id).number;
  }

  get visitTitle() {
    return 'Wizyta nr ' + this.visit.number;
  }

  get laborantSel() {
    this.revalidate;
    const labId = this.visit.laborant_id;
    const user = usersModule.users.find(u => u.id === labId);
    if (user !== undefined) {
      return { name: user.firstname + ' ' + user.lastname, id: user.id };
    }
    return {};
  }

  get getTitle() {
    switch (this.mode as PageMode) {
      case PageMode.PREVIEW:
      case PageMode.EDIT: {
        const v = this.visit;
        if (v !== null) {
          return 'Wizyta nr ' + (v ? v.number : '');
        } else {
          return 'Wizyta';
        }
      }
      case PageMode.NEW:
        return 'Nowa wizyta';
      default:
        return '';
    }
  }

  get workers() {
    this.revalidate;
    const optionsUsers = usersModule.getUsersByRightSymbol(UserRightSymbols.VISIT_START).map(u => {
      const retUser = { name: u.firstname + ' ' + u.lastname, id: u.id };
      return retUser;
    });
    return optionsUsers;
  }
  workerSelected(e) {
    this.visit.laborant_id = e.id;
    const car = toolsModule.cars.find(c => c.default_user_id === e.id);
    if (car !== undefined) {
      this.selectedCarNumber = car.number + ' - ' + car.register_number;
      this.visit.car_number = car.number;
    } else {
      this.selectedCarNumber = '';
      this.visit.car_number = '';
    }
    this.revalidate++;
    this.updateVisit();
  }

  descrChange(e: any) {
    this.visit.descriptive_address = e as string;
    this.updateVisit();
  }

  visitDateTextLeave(event: any) {
    console.log('orderDateTextLeave', event);
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      console.log('Emptying wrong DATE today', today);
      this.visit.date = today.format(consts.DB_DATE_TIME_FORMAT);
      this.mainVisitDateText = today.format(consts.DATE_FORMAT);
      helpers.warn('Błędny format daty, przywrócona domyślna wartość.');
      this.updateVisit();
    }
  }

  onMainDateContext(ctx) {
    console.log('DATE onMainDateContext ctx', ctx);
    const date = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
    if (date.isValid()) {
      console.log('Date changed ', ctx.selectedFormatted);
      visitModule.visit.date = ctx.selectedFormatted;
      this.mainVisitDateText = ctx.selectedFormatted;
      this.$forceUpdate();
    } else {
      console.log('Date not valid', ctx.selectedFormatted);
    }
    this.updateVisit();
  }
  modalChooseTakeTimeOK(time: string) {
    console.log('Time', time);
    this.visit.start_time = time;
    visitModule.visit.start_time = time;
    this.updateVisit();
  }

  /********************  COMMON ********************/
  setBreadcrumb() {
    const breadcrumbItems: BreadcrumbItem[] = [
      {
        title: 'Wizyta ' + this.visit.number,
        link: '',
      },
    ];
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: breadcrumbItems,
    });
  }

  async created() {
    console.log('%c*********************** VisitMainView mounting ***********************', 'color:red');
    console.log('PARAMS:', this.$route.params);
    window.addEventListener(
      'click',
      function (e: any) {
        this.checkPanelVisible(e);
      }.bind(this)
    );
    //await toolsModule.fetchTools();
    this.fillCars();
    console.log('Mounted VisitViewMain MODE:', this.$route.params);

    if (this.$route.params.visit_id === undefined) {
      this.mode = PageMode.NEW;
      visitModule.createEmptyVisit();
      visitModule.visit.collect_and_exam_protocol.mixers.push(new Mixer());
      console.log('%cVisit new', 'color:red');
    } else {
      const visitId = this.$route.params.visit_id;
      let editVisit: Visit = null;
      if (this.$route.params.pending) {
        editVisit = visitModule.visit;
        console.log('%cVisit from vuex visit_item', 'color:red', editVisit);
        const car = toolsModule.carByNumber(editVisit.car_number);
        if (car && car.number.length > 0) {
          this.selectedCarNumber = car.number + ' - ' + car.register_number;
        } else {
          const car = toolsModule.cars.find(c => c.default_user_id === editVisit.laborant_id);
          this.selectedCarNumber = car ? car.number + ' - ' + car.register_number : this.numberRegisters[0];
        }
      } else {
        editVisit = visitsModule.visitCopyById(parseInt(visitId));
        console.log('%cVisit from vuex visits', 'color:red');
        (editVisit as Visit).load();
        console.log('Visit loaded', editVisit);
        if (!editVisit.car_number || editVisit.car_number.length === 0) {
          const car = toolsModule.cars.find(c => c.default_user_id === editVisit.laborant_id);
          this.selectedCarNumber = car ? car.number + ' - ' + car.register_number : this.numberRegisters[0];
        } else {
          const car = toolsModule.carByNumber(editVisit.car_number);
          this.selectedCarNumber = car.number + ' - ' + car.register_number;
        }
      }
      visitModule.setVisit(editVisit);
      this.mode = this.getMode(editVisit);

      const dt = this.visit.date; // moment(this.visit.date, consts.DB_DATE_TIME_FORMAT).clone().format(consts.DATE_FORMAT);
      console.log('dt=', dt);
      this.mainVisitDate = dt;
      console.log(this.mainVisitDate);
      this.mainVisitDateText = dt;
      console.log(this.mainVisitDateText);
      this.mainVisitStartTime = this.visit.start_time;
      this.mainTimeInvalidate++;

      if (this.visit.client.code === CLIENT_NAMING_BOSTA_A || this.visit.client.code === CLIENT_NAMING_BOSTA_B) {
        this.isClientNaming = true;
        this.clientData = this.visit.client_data;
        this.clientCPlaceSymbol = this.visit.client_cplace_symbol;
        this.clientVisitIndex = this.visit.client_visit_index;
      }
      this.$forceUpdate();
    }
    EventBus.$on(BusEvents.CHANGE_TREE_VISIBLE, this.changeVisible);
    this.setBreadcrumb();
    console.log(
      '%c###################  VISIT VIEW MAIN id ' + this.visit.id + ' loaded.. ' + '###################',
      'color:red',
      visitModule.visit,
      this.mode
    );
    this.headerKey++;
  }
}
