
















































import { Component, Prop, PropSync, Vue, Ref } from 'vue-property-decorator';
import * as consts from '@/consts';
import userModule from '@/store/modules/user';
import ModalCert from '@/components/spec/ModalCert.vue';
import ModalDocuments from '@/components/spec/ModalDocuments.vue';
@Component({
  components: {
    ModalCert,
    ModalDocuments,
  },
})
export default class DashboardCertHeader extends Vue {
  @Ref() modalCert: ModalCert;
  @Prop({ required: true, type: String }) readonly title: string;
  @Prop({ required: false, type: Number }) readonly type: number;
  @Prop({ required: false, type: String }) readonly buttonTitle: string;
  @Prop({ required: false, type: Boolean, default: true }) readonly isCert: boolean;
  @PropSync('fullscreenProp', { type: Boolean }) fullscreen: boolean;
  @PropSync('entityType', { type: Number }) entityTypeSelected: number;
  buttonClick = 0;

  get entitySel() {
    return { name: this.entityTypeSelected, text: this.entityTypes[this.entityTypeSelected].text };
  }
  get canAddDocument() {
    return this.isCert === true
      ? userModule.hasRightBySymbolInAllRights(consts.UserRightSymbols.CERT_DASHBOARD_IN_PROGRESS) === true
      : true;
  }

  passEventToParent() {
    this.$emit(consts.ModalEvents.OK_EVENT);
  }

  entitySelected(event) {
    console.log('selected', event);
    this.entityTypeSelected = event.name;
  }

  addCert() {
    this.modalCert.showModal();
  }

  get entityTypes() {
    return [
      { name: 0, text: 'Badania' },
      { name: 1, text: 'Urządzenia' },
      { name: 2, text: 'Personel' },
    ];
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    console.log('to', this.fullscreen);
  }
}
