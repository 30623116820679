



























































































































































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, Localization, Tool, ToolParam, ToolGroup, User, Car } from '../../types';
import usersModule from '../../store/modules/users';
import dictModule from '../../store/modules/dict';
import toolsModule from '../../store/modules/tools';
import moment from 'moment';
import * as helpers from '../../helpers';

const TOOL_INVENT_ALREADY_EXISTED = 2;

@Component
export default class ModalTool extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'xl' }) size;

  enabled = false;

  show = false;
  isNew = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  comment = '';
  btnSave = 0;
  tool: Tool = null;
  orginalTool: User = null;
  item: DashboardItem = null;
  localizationItemSelected: Localization = this.localizations[0];
  groupItemSelected: ToolGroup = this.toolGroups[0];
  carItemSelected: Car = this.cars[0];

  newParamName = '';
  newParamSymbol = '';
  newParamDescription = '';
  newParamFormula = '';
  paramAddVisible = false;

  get status() {
    return consts.ToolStatus.byId(this.tool.status_id);
  }
  get getFullName() {
    return this.tool.name;
  }

  get locSel() {
    return this.localizationItemSelected;
  }
  get localizations() {
    this.revalidate;
    return dictModule.allLocalizations;
  }
  localizationSelected(event) {
    this.localizationItemSelected = event;
    this.tool.localization_id = this.localizationItemSelected.id;
  }

  get groupSel() {
    return this.groupItemSelected;
  }
  get toolGroups() {
    return dictModule.allToolGroups;
  }
  groupSelected(event) {
    this.groupItemSelected = event;
    this.tool.group_id = this.groupItemSelected.id;
  }

  get carSel() {
    return this.carItemSelected;
  }
  get cars() {
    const cars = [...toolsModule.cars];
    cars.sort((a, b) => {
      return parseInt(a.number) - parseInt(b.number);
    });
    cars.forEach(c => {
      c['number_registerNumber'] = c.number + ' - ' + c.register_number;
    });
    cars.unshift({ id: 0, number: '', number_registerNumber: '-- brak --' });
    return cars;
  }
  carSelected(event) {
    this.carItemSelected = event;
    this.tool.car_id = this.carItemSelected.id;
    this.tool.car = this.carItemSelected;
  }

  removeParam(param) {
    console.log('param', param);
    this.tool.params = this.tool.params.filter(p => p.id !== param.id);
  }

  clearAddParam() {
    this.newParamName = '';
    this.newParamSymbol = '';
    this.newParamDescription = '';
    this.newParamFormula = '';
  }

  addParam(event: any) {
    const param = new ToolParam();
    param.id = this.tool.params.length;
    param.name = this.newParamName;
    param.symbol = this.newParamSymbol;
    param.description = this.newParamDescription;
    param.formula = this.newParamFormula;
    param.tool_id = this.tool.id;

    this.tool.params.push(param);
    this.paramAddVisible = false;
    helpers.info('Parametr dodany...');
    this.clearAddParam();
  }

  showModal(params: DashboardItem = null) {
    console.log('ToolModal params showModal', params);
    this.show = true;

    this.isNew = params === null;
    if (this.isNew) {
      this.item = new DashboardItem();
      this.item.object_type_id = consts.DashboardObjectTypes.TOOL;
      this.params = this.item;
      this.params['enabled'] = true;
      this.tool = new Tool();
      this.enabled = true;
      this.tool.localization_id = this.localizations[0].id;
      this.localizationItemSelected = this.localizations[0];
      this.tool.group_id = this.toolGroups[0].id;
      this.groupItemSelected = this.toolGroups[0];
    } else {
      this.params = params;
      this.item = params;
      this.tool = JSON.parse(JSON.stringify(params.object));
      this.localizationItemSelected = this.tool['localization'];
      this.groupItemSelected = this.tool['group'];
      this.carItemSelected =
        this.tool['car'] !== undefined ? this.tool['car'] : ({ id: 0, number_registerNumber: '-- brak --' } as any);
      this.orginalTool = params.object as User;
    }
    console.log('showModal', this.item, this.isNew);
  }
  async saveModal() {
    this.save = true;
    this.params['save'] = true;
    this.params['new'] = this.isNew;
    if (this.isNew) {
      //this.tool.created_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
      this.tool.add_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
    }
    //this.tool.changed_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
    this.tool.description = this.comment;
    console.log('Saving tool', this.tool, this.tool.car_id);
    this.tool.car_id = this.tool.car_id === 0 ? null : this.tool.car_id;

    console.log('Saving tool', this.tool, this.isNew);
    let response = null;
    if (this.isNew) {
      response = await toolsModule.addTool(this.tool);
    } else {
      response = await toolsModule.updateTool(this.tool);
    }

    if (response.data.success === false && response.data.error_code === TOOL_INVENT_ALREADY_EXISTED) {
      console.log('Tool invent no already existed');
      helpers.error('Podany numer ewidencyjny jest już wykorzystany, użyj innego');
      this.btnSave++;
      return;
    }
    this.tool.id = response.data.tool['id'];
    this.params['object'] = this.tool;

    this.$emit(consts.ModalEvents.OK_EVENT, this.params);
    this.show = false;
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
  descrChange(event) {
    this.comment = event;
  }
}
