












































































































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import moment from 'moment';
import { Offer, Method, File, OfferAction, Period } from '../../types';
import * as consts from '../../consts';
import multiselect from 'vue-multiselect';
import methodsModule from '../../store/modules/methods';
import offerModule from '../../store/modules/offer';
import dashBoardItems from '@/store/modules/dashboard_items';
import FileManager from './FileManager.vue';
import OfferAnyAmountSettlement from './OfferAnyAmountSettlement.vue';
import * as helpers from '../../helpers';
import OfferLumpSumSettlement from './OfferLumpSumSettlement.vue';
import OfferLimitedAmountSettlement from './OfferLimitedAmountSettlement.vue';
import OfferFixAmountSettlement from './OfferFixAmountSettlement.vue';
import { Guid } from 'guid-typescript';
import * as API from '../../store/api';
import { parse } from '@fortawesome/fontawesome-svg-core';
import dashboard_items from '@/store/modules/dashboard_items';
@Component({
  components: {
    FileManager,
    OfferAnyAmountSettlement,
    OfferLumpSumSettlement,
    OfferLimitedAmountSettlement,
    OfferFixAmountSettlement,
  },
})
export default class DashboardOffers extends Vue {
  offers: Offer[] = [];
  offer: Offer = null;
  offerAction: OfferAction;
  files = [];

  offerStatus: consts.OfferStatus;
  offerDescription = '';
  offerNumber = '';
  offerGuid = '';
  checkOutSelected;
  lastDeletedNumber = '';
  isActive = false;

  currentMethod: Method = null;

  checkoutOptions = [
    { name: 'Ryczałt', tag: consts.OfferSettlements.LUMP_SUM },
    { name: 'Ceny jednostkowe - dowolna ilość badań', tag: consts.OfferSettlements.MANY_TESTS },
    { name: 'Ceny jednostkowe - ograniczona ilość badań', tag: consts.OfferSettlements.SPEC_TESTS },
    { name: 'Kwota fix', tag: consts.OfferSettlements.FIX },
    { name: 'W oparciu o protokół klienta', tag: consts.OfferSettlements.CLIENT_PROTOCOL },
  ];
  selectedCheckoutOption = this.checkoutOptions[0];
  peroid = new Period();
  revalidate = 0;
  selectedCheckoutOptionTag = consts.OfferSettlements.LUMP_SUM;

  isClient(): boolean {
    return this.$route.fullPath.includes('client');
  }
  get isEnabled() {
    return this.isActive && !this.isClient();
  }

  get getGuid() {
    return this.offer.guid || null;
  }
  get getOffers() {
    this.revalidate;
    if (this.isClient()) {
      return this.offers.filter(el => el.status_id >= consts.OfferStatus.SENT.id);
    } else return offerModule.offerAction.offers;
  }
  offerRevalidate = 0;
  get getOffer() {
    this.offerRevalidate;
    return this.offer;
  }

  checkoutOptionSelected(e: any) {
    if (this.offer.methods.length !== 0) {
      this.checkOutSelected = e;
      setTimeout(this.showModal, 150);
      return;
    }
    this.offer.methods = [];
    this.selectedCheckoutOption = e;
    this.selectedCheckoutOptionTag = e.tag;
    this.offer.settlement_type = e.tag;
  }

  clearAndChangeCheckout() {
    this.offer.methods = [];
    this.checkoutOptionSelected(this.checkOutSelected);
    this.hideModal();
  }

  showModal() {
    this.$bvModal.show('changeCheckoutModal');
  }

  hideModal() {
    this.$bvModal.hide('changeCheckoutModal');
  }

  enums = {};
  getEnums() {
    for (let i = 0; i < consts.OfferStatus.len(); i++) {
      this.enums[consts.OfferStatus.byId(i).symbol] = i;
    }
  }

  SAVE = 'save';

  async sendOfferToClient() {
    this.offer.status_id = consts.OfferStatus.SENT.id;
    this.offer.status = consts.OfferStatus.SENT;
    this.saveDashBoardItemWithStatus(3, 'Wysłano ofertę.');
    this.offerAction.status_id = consts.OfferActionStatus.TOACCEPT.id;
    this.offerAction.status = consts.OfferActionStatus.TOACCEPT;
    this.$emit(this.SAVE);
  }

  async rejectOffer() {
    this.offer.status_id = consts.OfferStatus.ACCEPTED.id;
    this.offer.status = consts.OfferStatus.ACCEPTED;
    this.offerStatus = this.offer.status;
    this.offerAction.status_id = consts.OfferActionStatus.OFFER.id;
    this.offerAction.status = consts.OfferActionStatus.OFFER;
    this.saveDashBoardItemWithStatus(2, 'Odrzucono ofertę.');
    this.$emit(this.SAVE);
  }

  async saveDashBoardItemWithStatus(status: number, message: string) {
    const dashboardItem = dashBoardItems.allItems.find(
      el => el.object_id === this.offerAction.id && el.object_type_id === consts.DashboardObjectTypes.OFFER
    );
    dashboardItem.status_id = status;
    const result = await API.saveDashboardItem(dashboardItem);
    if (result.data.success) {
      helpers.info(message);
    }
  }

  async acceptOffer() {
    this.offer.status_id = consts.OfferStatus.ACCEPTED.id;
    this.offer.status = consts.OfferStatus.ACCEPTED;
    this.offerStatus = this.offer.status;
    this.offerAction.status_id = consts.OfferActionStatus.CONTRACT.id;
    this.offerAction.status = consts.OfferActionStatus.CONTRACT;
    //this.saveDashBoardItemWithStatus(4, 'Zaakceptowano ofertę!');
    this.$emit(this.SAVE);
  }

  generateNumber(offer: Offer): string {
    let num = moment().format('DD[/]MM[/]YYYY');
    const offers = this.offers
      .sort((a, b) => {
        return a.local_number - b.local_number;
      })
      .filter(el => el.number.startsWith(num));
    let free_number = -1;
    for (let i = 0; i < offers.length; i++) {
      if (offers.findIndex(el => el.local_number === i) === -1) {
        free_number = i;
        break;
      }
    }
    if (free_number === -1) {
      free_number = offers.length;
    }
    offer.local_number = free_number;
    num += free_number.toString() === '0' ? '' : '/' + free_number;
    return num;
  }

  addNewOffer() {
    if (this.offer) {
      this.offer.active = false;
    }
    console.log('Creating new Offer...');
    this.offer = new Offer();
    this.offer.status = consts.OfferStatus.NOT_SAVED;
    this.offer.description = '';
    this.offer.settlement_type = consts.OfferSettlements.LUMP_SUM;
    this.offer.offerActionId = offerModule.offerAction.id;
    this.offer.status_id = 0;
    this.offer.guid = Guid.raw();
    this.offer.files = [];
    this.offer.methods = [];
    this.offer.id = this.offers.length !== 0 ? Math.min(...this.offers.map(el => el.id)) - 1 : -1;
    this.offer.number = this.generateNumber(this.offer);
    this.offerAction.offers.push(this.offer);
    this.selectedCheckoutOption = this.checkoutOptions[0];
    console.log('created new offer', this.offer);
    this.changeOfferView(this.offer);
  }

  changeOfferView(offer: Offer) {
    if (offer.number === this.lastDeletedNumber) return;
    if (this.offer !== null) this.offer.active = false;
    this.offerGuid = offer.guid;
    this.offerDescription = offer.description;
    this.offerStatus = offer.status;
    this.offerNumber = offer.number;
    this.files = offer.files;
    this.offer = offer;
    this.offer.active = true;
    offerModule.setOffer(this.offer);
    this.selectedCheckoutOption = this.checkoutOptions.find(el => el.tag === this.offer.settlement_type);
    if (this.selectedCheckoutOptionTag === consts.OfferSettlements.LUMP_SUM) {
      if (this.offer.periods === undefined) {
        this.offer.periods = [];
      }
    }
    if (this.offerAction.user_created && this.offerAction.user_created.client) {
      this.clientFromUserCreated = true;
    }
    this.selectedCheckoutOptionTag = this.offer.settlement_type;

    this.isActive =
      ((this.offerAction.status === consts.OfferActionStatus.OFFER ||
        this.offerAction.status === consts.OfferActionStatus.TOACCEPT) &&
        this.offer.status.id < consts.OfferStatus.SENT.id) ||
      this.offer.status_id === consts.OfferStatus.REJECTED.id;

    this.revalidate++;
    this.offerRevalidate++;
  }

  updateOfferDescription() {
    this.offer.description = this.offerDescription;
  }

  removeOffer(offer: Offer) {
    console.log('Deleting offer...', offer);
    const index = this.offers.findIndex(el => el.id === offer.id);
    this.offers.splice(index, 1);
    if (index === this.offers.length) {
      this.lastDeletedNumber = this.offer.number;
      this.changeOfferView(this.offers[0]);
    }
  }

  loadView() {
    this.getEnums();
    this.offerAction = offerModule.currentOfferAction;
    this.offers = this.offerAction.offers;
    this.offers.forEach(el => {
      el.load();
    });
    if (offerModule.currentOffer) {
      offerModule.setOffer(this.offerAction.offers.find(el => el.id === offerModule.offer.id));
      this.changeOfferView(offerModule.currentOffer);
    } else {
      if (this.offerAction.offers.length == 0 && !this.isClient()) this.addNewOffer();
      this.changeOfferView(this.getOffers[0]);
    }
    this.isActive =
      ((this.offerAction.status === consts.OfferActionStatus.OFFER ||
        this.offerAction.status === consts.OfferActionStatus.TOACCEPT) &&
        this.offer.status.id < consts.OfferStatus.SENT.id) ||
      this.offer.status_id === consts.OfferStatus.REJECTED.id;
    if (this.isClient()) {
      this.isActive = false;
    }
  }

  clientFromUserCreated = false;
  async created() {
    this.loadView();
  }
}
