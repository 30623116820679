
































































import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import Header from '../../components/dummy/Header.vue';
import Footer from '../../components/dummy/Footer.vue';
import userModule from '../../store/modules/user';
import usersModule from '../../store/modules/users';
import visitModule from '../../store/modules/visit';
import visitsModule from '../../store/modules/visits';
import { User, Visit, CalEvent, Breadcrumb } from '../../types';
import * as consts from '../../consts';
import moment from 'moment';
import visit from '../../store/modules/visit';
import CalendarColumn from '../../views/laborant/CalendarColumn.vue';
import breadcrumbModule from '../../store/modules/breadcrumb';
import dictModule from '../../store/modules/dict';

@Component({
  components: { Header, Footer, CalendarColumn },
})
export default class CalendarVisitsDayPeople extends Vue {
  date = null;
  firstVisitFocused = false;
  contentBoxWidth = 0;
  get getTimeColumnData() {
    const array = [];
    for (let i = 0; i < 24; i++) {
      array.push({
        start: i * 6,
        end: (i + 1) * 6,
        text: i.toString().padStart(2, '0') + ':00',
      });
    }
    return array;
  }

  get getUser() {
    return (user: User): User => {
      return usersModule.userById(user.id);
    };
  }

  get visit() {
    return (id: number): Visit => {
      const visit = visitsModule.visitById(id);
      return visit;
    };
  }

  get getPeople(): User[] {
    let users = usersModule.getUsersByRightSymbolInAllRights(consts.UserRightSymbols.VISIT_START);
    users = usersModule.getWorkingUsers(users);
    this.contentBoxWidth = 215 * users.length - 80;

    users = users.sort((a, b) => a.calendar_index - b.calendar_index);

    return users;
  }

  goMonth() {
    this.$router.push({ name: 'calendarvisitsmonth', params: { day: this.date.format(consts.DATE_FORMAT) } });
  }

  get getInitials() {
    return (user: User): string => {
      console.log('getInitials');
      return user.initials;
    };
  }

  get getFullName() {
    return (user: User): string => {
      console.log('getFullName');
      return user.firstname + ' ' + user.lastname;
    };
  }

  get currentDayTitle() {
    return this.date?.format(consts.DATE_FORMAT);
  }

  getGridRowFromTime(time: string) {
    const timeObj = moment(time, 'HH:mm');
    return timeObj.hour() * 6 + Math.floor(timeObj.minutes() / 10);
  }

  getPersonEvents(user: User): CalEvent[] {
    const personVisits = !this.isCoord
      ? visitsModule.visitsByDate(this.date)
      : visitsModule.visitsByUserAndDate(user, this.date);
    console.log(
      '------------------------------- CalendarVisitsDayPeople::getPersonEvents date:',
      this.date.format(consts.DATE_FORMAT),
      ' user',
      user.id,
      user.fullName,
      user,
      personVisits,
      ' ----------------------------------'
    );
    const events: CalEvent[] = [];
    personVisits.forEach(visit => {
      console.log('Person ', user.email + ' Visit', visit);
      const endTime = moment(visit.start_time, 'HH,mm').clone().add(visit.duration, 'minutes');
      console.log('THIS', this);
      visit.client = dictModule.clientById(visit.client_id);
      visit.cplace = dictModule.cPlaceById(visit.cplace_id);
      visit.status = consts.VisitStatus.byId(visit.status_id);
      const event: CalEvent = {
        start: this.getGridRowFromTime(visit.start_time),
        end: this.getGridRowFromTime(endTime.format('HH:mm')),
        text: 'Zlec',
        object_id: visit.id,
      };
      console.log('Adding event', event);
      events.push(event);
    });
    return events;
  }

  get isCoord() {
    return userModule.hasRightBySymbol(consts.UserRightSymbols.VISIT_COORD);
  }
  selectVisit(params: any) {
    console.log('EVENT clicked', params);
    this.$router.push({
      name: 'calendarvisitsdayperson',
      params: {
        date: this.date.date() + '_' + (this.date.month() + 1).toString() + '_' + this.date.year(),
        person_id: params.user.id.toString(),
        visit_id: params.event.object_id.toString(),
      },
    });
  }
  async mounted() {
    await this.$nextTick();
    //debugger;
    this.$on('select-event', this.selectVisit);

    this.date = moment(this.$route.params.date, 'DD_MM_YYYY');

    console.log('CalendarVisitDayPeople::Mounted date=', this.currentDayTitle);
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Kalendarz wizyt',
          link: 'cv/cvm',
        },
        {
          title: this.date.format(consts.DATE_FORMAT),
          link: '',
        },
      ],
    });
  }
}
