import moduleManager from './moduleManager';

export const RelatedModel = (modelName: string) => {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const childFunc = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const shouldBeUpdated = moduleManager.checkIfUpdateNeeded(modelName);
      if (shouldBeUpdated) {
        console.log('   [', modelName, '] - UPDATE');
        return childFunc.apply(this, args);
      } else {
        console.log('   [', modelName, '] - NOT UPDATE');
        return null;
      }
    };
  };
};
