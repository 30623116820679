























import { Component, Vue, Ref } from 'vue-property-decorator';
import ModalMessages from '../../components/spec/ModalMessages.vue';
import userModule from '../../store/modules/user';
import dataModule from '../../store/modules/data';
import { onlineHelper } from '../../helpers/offline';

@Component({
  components: {
    ModalMessages,
  },
})
export default class ProfileMenu extends Vue {
  @Ref() readonly modalMessages: ModalMessages;

  get isOnline() {
    return onlineHelper.isOnline;
  }

  get user() {
    console.log('GetUSER', userModule.user);
    return userModule.user;
  }

  get unreadMessages() {
    return dataModule.unreadMessagesCount;
  }

  get fullname() {
    return this.user.fullName;
  }
  showMessages() {
    this.modalMessages.showModal();
  }

  private logoutUser(): any {
    userModule.logout();
    this.$router.push({ name: 'Login' });
  }
}
