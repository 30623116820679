import izi from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import * as consts from '@/consts';
import moment, { Moment } from 'moment';
import { Department, Exam, Sample, Protocol } from '@/types';
import protocolsModule from '@/store/modules/protocols';
import userModule from '@/store/modules/user';
import { EventBus, BusEvents } from '@/helpers/eventbus';

export function info(message: string, timeout = 2000) {
  izi.show({
    message: message,
    title: 'Komunikat',
    position: 'center',
    timeout: timeout,
    color: 'green',
  });
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export function checkPhoneNumber(number: string) {
  const regex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/;
  return regex.test(number);
}

export function warn(message: string, timeout = 2000) {
  izi.show({
    message: message,
    title: 'Uwaga',
    position: 'center',
    timeout: timeout,
    color: 'yellow',
  });
}

export function roundNumber(number: number, decimalNumbers: number) {
  const roundedNumber = Math.round(number * 10 ** decimalNumbers) / 10 ** decimalNumbers;
  return roundedNumber;
}

export function error(message: string) {
  izi.show({
    message: message,
    title: 'Błąd',
    position: 'center',
    timeout: 2000,
    color: 'red',
  });
}

export function isValidNip(nip: string) {
  //    if (typeof nip !== "string")
  //        return false;

  nip = nip.replace(/[\\-]/gi, '');

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
}

export function isValidPostalCode(postalCode: string) {
  const regex = /\d{2}-\d{3}/g;
  return postalCode.match(regex);
  //return new RegExp(regex).test(postalCode);
}

export function setTempId(array: object[], obj: object) {
  console.log('setTempId', array, obj);
  const maxId = Math.max(...array.map(o => o['id']));
  if (obj['id'] === 0 || obj['id'] === -1) {
    if (maxId < consts.NEW_ID_START) {
      obj['id'] = consts.NEW_ID_START;
    } else {
      obj['id'] = maxId + 1;
    }
  }
}

export function getServerId(array: object[], obj: object) {
  console.log('getServerID', obj);
  if (array.length > 0) {
    const maxId = Math.max(...array.filter(el => el['id'] < consts.NEW_ID_START).map(o => o['id']));
    console.log('NewID=', maxId + 1);
    return maxId + 1;
  } else {
    return 1;
  }
}

export function createNumber(id: number) {
  return id.toString() + '/' + moment().year().toString();
}

export function truncate(value: any, max: number) {
  if (value.toString().length > max) {
    return parseInt(value.toString().slice(0, max));
  }
  return value;
}

export function isSameDay(a: Moment, b: Moment) {
  return a.year() === b.year() && a.month() === b.month() && a.date() === b.date();
}

export function getEmptyResultsDoc(
  department: Department,
  rdType: consts.ResultsDocType,
  clientId: number,
  exams: Exam[]
) {
  let resultsDescription = '';
  const SEP = ', ';
  const samples: Sample[] = [];
  for (const exam of exams) {
    samples.push(...exam.samples);
  }

  const protocols: Protocol[] = [];
  for (const sample of samples) {
    const protocol = protocolsModule.protocolBySample(sample);
    if (protocols.includes(protocol) === false) {
      protocols.push(protocol);
    }
  }

  for (const protocol of protocols) {
    resultsDescription += protocol.number + SEP;
  }
  resultsDescription = resultsDescription.slice(0, -SEP.length);

  return JSON.parse(
    JSON.stringify({
      department_id: department.id,
      get_person_id: consts.rdGetPeople[0].id,
      get_person_text: consts.rdGetPeople[0].name,
      rule_id: consts.rdRules[0].id,
      rule_text: consts.rdRules[0].name,
      header_description: '-',
      results_description: resultsDescription,
      uncert_tool_id: null,
      uncertainty: null,
      rd_type: rdType,
      client_id: clientId,
      destiny_id: exams[0].destiny_id,
    })
  );
}

export function copyObject(fromObj, isShallow = false, withMethods = true): Record<string, any> {
  let destObj = {};
  if (isShallow === true) {
    for (const key in fromObj) {
      destObj[key] = fromObj[key];
    }
  } else if (withMethods === false) {
    destObj = JSON.parse(JSON.stringify(fromObj));
  } else {
    for (const key in fromObj) {
      if (fromObj[key] != null && typeof fromObj[key] === 'object') {
        destObj[key] = copyObject(fromObj[key], isShallow, withMethods);
      } else {
        destObj[key] = fromObj[key];
      }
    }
  }
  return destObj;
}

const DEFAULT_START_PROGRESS = 20;
export let progress = DEFAULT_START_PROGRESS;

export function logProgress() {
  progress += 1;
  EventBus.$emit(BusEvents.LOGIN_PROGRESS, progress);
}

export function resetProgress() {
  progress = DEFAULT_START_PROGRESS;
}
