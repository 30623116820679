






import { Component, Vue } from "vue-property-decorator";
//import { OrderStatus, PageMode } from "@/consts";
//import * as helpers from "@/helpers";
//import orderModule from "@/store/modules/order";
//import dictModule from "@/store/modules/dict";
//import dictOffer from "@/store/modules/offer";

//import * as models from "@/store/models";
//import Multiselect from "vue-multiselect";

@Component
export default class OrderGEO extends Vue {}
