import { getModule, Module, Mutation, Action, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import * as API from '@/store/api';

import { ResultsDoc } from '@/types';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'results_doc',
  store,
  dynamic: true,
})
class ResultsDocModule extends VuexModule {
  public resultsDocs: ResultsDoc[] = [];

  get allResultsDocs() {
    return this.resultsDocs;
  }

  @Mutation
  clear() {
    this.resultsDocs = [];
    console.log('resultsDoc module cleared...');
  }

  @Mutation
  setResultsDocs(resultsDocs: ResultsDoc[]) {
    this.resultsDocs = [];
    resultsDocs.forEach(rd => {
      this.resultsDocs.push(Object.assign(new ResultsDoc(), rd));
    });
  }

  @Action
  @RelatedModel('ResultsDoc')
  async fetchResultsDocs() {
    const response = await API.fetchResultsDocs();
    const resultsDocs: ResultsDoc[] = [];
    for (const resultDoc of response.data.results_docs) {
      const rd: ResultsDoc = Object.assign(new ResultsDoc(), resultDoc);
      // rd.loadExam();
      resultsDocs.push(rd);
    }
    this.setResultsDocs(resultsDocs);
    return response;
  }

  @Mutation
  addResultsDoc(resultsDoc: ResultsDoc) {
    this.resultsDocs = this.resultsDocs.filter(rd => rd.id !== resultsDoc.id);
    this.resultsDocs.push(resultsDoc);
  }

  @Action({ rawError: true })
  async saveResultsDoc(resultsDoc: ResultsDoc) {
    if (resultsDoc.id !== undefined) {
      resultsDoc = Object.assign(
        this.allResultsDocs.find(rd => rd.id === resultsDoc.id),
        resultsDoc
      );
    }
    const response = await API.saveResultsDoc(resultsDoc);
    if (response.data.success === true) {
      resultsDoc.id = response.data.rd_id;
      //resultsDoc.filename = response.data.filename;
      this.addResultsDoc(resultsDoc);
    }
    return response;
  }
}

export default getModule(ResultsDocModule);
