












































import { Component, Vue } from 'vue-property-decorator';
import { DestinyGroup, Destiny } from '@/types';
import dictModule from '@/store/modules/dict';
import * as helpers from '@/helpers';
import { AxiosResponse } from 'axios';

@Component
export default class ModalDestiny extends Vue {
  show = false;
  editing = false;

  group = true;
  groupId = 0;
  name = '';
  oldName = '';
  oldSymbol = '';
  symbol = '';
  value = '';
  description = '';
  id = -1;

  showModal(group: boolean, destiny: Destiny | DestinyGroup | number) {
    this.group = group;
    this.oldName = '';
    this.editing = false;
    if (typeof destiny === 'object') {
      this.id = destiny.id;
      this.name = destiny.name;
      this.oldName = destiny.name;
      this.editing = true;
      this.symbol = destiny.symbol;
      this.oldSymbol = destiny.symbol;
      this.description = destiny.description;
      if (destiny instanceof Destiny) this.value = destiny.value;
    } else {
      this.groupId = destiny;
      this.name = '';
      this.value = '';
      this.symbol = '';
      this.description = '';
    }
    this.show = true;
  }

  hideModal(): void {
    this.show = false;
  }

  async saveModal() {
    if (this.name === '') {
      helpers.error(
        'Aby utworzyć now' + (this.group ? 'ą grupę przeznaczeń' : 'e przeznaczenie') + ' trzeba podać imię'
      );
    } else if (this.group === true && this.symbol === '') {
      helpers.error('Aby utworzyć nową grupę przeznaczeń trzeba podać symbol');
    } else if (this.isUnique() === true) {
      let response: AxiosResponse;

      if (this.group === true) {
        const destinyGroup = new DestinyGroup();
        destinyGroup.name = this.name;
        destinyGroup.description = this.description;
        destinyGroup.symbol = this.symbol;

        if (this.editing === true) {
          response = await dictModule.editDestinyGroup({ destinyGroup, oldName: this.oldName });
        } else {
          response = await dictModule.addDestinyGroup(destinyGroup);
        }
      } else {
        const destiny = new Destiny();
        destiny.name = this.name;
        destiny.value = this.value;
        destiny.symbol = this.symbol;
        destiny.description = this.description;
        destiny.group_id = this.groupId;

        if (this.editing === true) {
          response = await dictModule.editDestiny({ destiny, oldName: this.oldName });
        } else {
          response = await dictModule.addDestiny(destiny);
        }
      }

      if (response.data.success === true) {
        this.show = false;
      } else {
        helpers.error('Wystąpił błąd w komunikacji z serwerem, spróbuj ponownie później');
      }
    }
  }

  async removeDestiny() {
    let response: AxiosResponse;
    if (this.group === true) {
      response = await dictModule.removeDestinyGroup(this.oldName);
    } else {
      response = await dictModule.removeDestiny(this.oldName);
    }

    if (response.data.success === true) {
      this.show = false;
    } else {
      helpers.error('Wystąpił błąd w komunikacji z serwerem, spróbuj ponownie później');
    }
  }

  isUnique(): boolean {
    if (this.group === true) {
      const uniqueName = dictModule.destinyGroups.every(el => el.name !== this.name);
      const uniqueSymbol = dictModule.destinyGroups.every(el => el.symbol !== this.symbol);
      
      if ((this.oldName === this.name || uniqueName) && (this.oldSymbol === this.symbol || uniqueSymbol)) {
        return true;
      } else {
        helpers.error('Grupa przeznaczeń o takiej nazwie lub symbolu już istnieje');
        return false;
      }
    } else {
      const uniqueName = dictModule.destinies.every(el => el.name !== this.name);

      if (this.oldName === this.name || uniqueName) {
        return true;
      } else {
        helpers.error('Przeznaczenie o takiej nazwie już istnieje');
        return false;
      }
    }
  }

  get groupOrNot(): string {
    return this.group ? 'grupy przeznaczeń' : 'przeznaczenia';
  }

  get maxNameLength(): number {
    return this.group ? 150 : 50;
  }
}
