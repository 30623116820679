import Vue from 'vue';
import store from '@/store';
import { getModule, Module, Mutation, Action, VuexModule } from 'vuex-module-decorators';
import {
  IDestiny,
  IMethod,
  IDestinyGroup,
  ILocalization,
  IMaterial,
  IConcreteClassGroup,
  IConcreteClass,
  IConcreteClassSubgroup,
} from '@/store/models';
import {
  Client,
  ConstructionPlace,
  ConcreteClass,
  ConcreteClassGroup,
  ConcreteClassSubgroup,
  Destiny,
  DestinyGroup,
  Localization,
  Material,
  MethodGroup,
  ToolGroup,
  Address,
  Department,
} from '@/types';
import * as consts from '@/consts';
import * as API from '@/store/api';
import { AxiosResponse, Method } from 'axios';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'dict',
  store,
  dynamic: true,
})
class DictModule extends VuexModule {
  addresses: Address[] = [];
  clients: Client[] = [];
  cPlaces: ConstructionPlace[] = [];
  localizations: ILocalization[] = [];
  destinies: IDestiny[] = [];
  methods: IMethod[] = [];
  destinyGroups: IDestinyGroup[] = [];
  materials: IMaterial[] = [];
  concreteClassGroups: ConcreteClassGroup[] = [];
  concreteClassSubgroups: ConcreteClassSubgroup[] = [];
  concreteClasses: ConcreteClass[] = [];
  methodGroups: MethodGroup[] = [];
  toolGroups: ToolGroup[] = [];
  departments: Department[] = [];

  dictTypesArray = [
    this.addresses,
    this.clients,
    this.cPlaces,
    this.localizations,
    this.destinies,
    this.destinyGroups,
    this.concreteClassGroups,
    this.concreteClassSubgroups,
    this.concreteClasses,
  ];
  dictArray = [
    { type: consts.DictType.CLIENTS, dict: this.clients },

    { type: consts.DictType.CONSTR_PLACES, dict: this.cPlaces },
    { type: consts.DictType.LOCALIZATIONS, dict: this.localizations },
    { type: consts.DictType.DESTINY, dict: this.destinies },
    { type: consts.DictType.DESTINY_GROUP, dict: this.destinyGroups },
    { type: consts.DictType.MATERIAL, dict: this.materials },
    {
      type: consts.DictType.CONCRETE_CLASS_GROUPS,
      dict: this.concreteClassGroups,
    },
    { type: consts.DictType.CONCRETE_CLASS, dict: this.concreteClasses },
  ];

  /*
    public getDictElement<T>(type: consts.DictType, id: number) {
        const dictType = this.dictTypesArray.filter(el => typeof el === T[]);
        return dictType.dict.filter(o => o.id === id);
    }

    public getDictById<T>(type: consts.DictType, id: number) {
        const dictType = this.dictTypesArray.filter(el => typeof el === T[]);
        return dictType.find(c => c.id === client_id);
    }*/

  get destinyById() {
    return (id: number): Destiny => {
      return this.destinies.find(d => d.id === id);
    };
  }
  get methodById() {
    return (id: number): IMethod => {
      return this.methods.find(m => m.id === id);
    };
  }
  get destiniesByGroupId() {
    return (group_id: number): Destiny[] => {
      return this.destinies.filter(d => d.group_id === group_id);
    };
  }
  get destinyGroupById() {
    return (id: number): DestinyGroup => {
      return this.destinyGroups.find(g => g.id === id);
    };
  }
  get destinyGroupBySymbol() {
    return (symbol: string): DestinyGroup => {
      return this.destinyGroups.find(g => g.symbol === symbol);
    };
  }
  get groupClassBySymbol() {
    return (symbol: string) => {
      return this.concreteClassGroups.find(g => g.symbol === symbol);
    };
  }

  get getDestinyGroupByDestinyId() {
    return (destiny_id: number): DestinyGroup => {
      const destiny = this.destinyById(destiny_id);
      return this.destinyGroupById(destiny.group_id);
    };
  }

  get localizationById() {
    return (id: number) => {
      return this.localizations.find(l => l.id === id);
    };
  }
  get allLocalizations() {
    return this.localizations;
  }
  get materialById() {
    return (id: number) => {
      return this.materials.find(m => m.id === id);
    };
  }
  get getVisibleMaterials(): IMaterial[] {
    return this.materials.filter(m => m.visible === true);
  }
  get concreteClassById() {
    return (id: number): ConcreteClass => {
      return this.concreteClasses.find(c => c.id === id);
    };
  }
  get concreteClassGroupById() {
    return (id: number): ConcreteClassGroup => {
      return this.concreteClassGroups.find(g => g.id === id);
    };
  }
  get concreteClassSubgroupById() {
    return (id: number): ConcreteClassSubgroup => {
      return this.concreteClassSubgroups.find(g => g.id === id);
    };
  }
  get getGroupClassesById() {
    return (group_id: number): ConcreteClass[] => {
      return this.concreteClasses.filter(c => c.group_id === group_id);
    };
  }

  get getSubgroupClassesById() {
    return (group_id: number, subgroup_id: number): ConcreteClass[] => {
      return this.concreteClasses.filter(c => c.group_id === group_id && c.subgroup_id === subgroup_id);
    };
  }

  get getSubgroupClassesByGroupId() {
    return (group_id: number): ConcreteClass[] => {
      return this.concreteClasses.filter(c => c.group_id === group_id);
    };
  }
  get subgroupsByGroupId() {
    return (group_id: number) => {
      return this.concreteClassSubgroups.filter(sg => {
        //console.log("subgroupsByGroupId", group_id, sg);
        return sg.group_id === group_id;
      });
    };
  }
  get departmentById() {
    return (id: number) => {
      return this.departments.find(m => m.id === id);
    };
  }
  /************************* methodGroups **********************/
  get allMethodGroups() {
    return this.methodGroups;
  }

  get methodGroupById() {
    return (id: number): MethodGroup => {
      return this.methodGroups.find(m => m.id === id);
    };
  }
  /************************* ToolGroups **********************/
  get toolGroupById() {
    return (id: number): ToolGroup => {
      return this.toolGroups.find(tg => tg.id === id);
    };
  }
  get allToolGroups() {
    return this.toolGroups;
  }

  @Mutation
  clear() {
    this.addresses = [];
    this.clients = [];
    this.cPlaces = [];
    this.localizations = [];
    this.destinies = [];
    this.methods = [];
    this.destinyGroups = [];
    this.materials = [];
    this.concreteClassGroups = [];
    this.concreteClassSubgroups = [];
    this.concreteClasses = [];
    this.methodGroups = [];
    this.toolGroups = [];
    this.departments = [];
    console.log('dicts module cleared...');
  }

  @Mutation
  loadClientsFromConfig(clients: Client[]) {
    this.clients = clients.map(c => Object.assign(new Client(), c));
    console.log('Store module DictModule loadClientsFromConfig...', this.clients.length);
  }

  @Mutation
  loadCPlacesFromConfig(cPlaces: ConstructionPlace[]) {
    this.cPlaces = cPlaces.map(c => Object.assign(new ConstructionPlace(), c));
    console.log('Store module DictModule loadCPlacesFromConfig...', this.cPlaces.length);
  }

  @Mutation
  loadLocalizationsFromConfig(localizations: ILocalization[]) {
    this.localizations = localizations.map(c => Object.assign(new Localization(), c));
    console.log('Store module DictModule loadLocalizationsFromConfig...', this.localizations.length);
  }

  @Mutation
  loadDestinyGroupFromConfig(destinyGroups: IDestinyGroup[]) {
    this.destinyGroups = destinyGroups.map(c => Object.assign(new DestinyGroup(), c));
    console.log('Store module DictModule loadDestinyGroupFromConfig...', this.destinyGroups.length);
  }

  @Mutation
  loadDestiniesFromConfig(destinies: IDestiny[]): any {
    this.destinies = destinies.map(c => Object.assign(new Destiny(), c));
    console.log('Store module DictModule loadDestiniesFromConfig...', this.destinies.length);
  }
  @Mutation
  loadMaterialsFromConfig(materials: IMaterial[]) {
    this.materials = materials.map(c => Object.assign(new Material(), c));
    console.log('Store module DictModule loadMaterialsFromConfig...', this.materials.length);
  }

  @Mutation
  loadConcreteClassSubgroupsFromConfig(concreteClassSubgroups: IConcreteClassSubgroup[]) {
    this.concreteClassSubgroups = concreteClassSubgroups.map(c => Object.assign(new ConcreteClassSubgroup(), c));
    console.log('Store module DictModule loadConcreteClassSubgroupsFromConfig...', this.concreteClassSubgroups.length);
  }
  @Mutation
  loadConcreteClassGroupsFromConfig(concreteClassGroups: IConcreteClassGroup[]) {
    this.concreteClassGroups = concreteClassGroups.map(c => {
      const group = Object.assign(new ConcreteClassGroup(), c);
      //group.load();
      return group;
    });
    console.log('Store module DictModule loadConcreteClassGroupsFromConfig...', this.concreteClassGroups.length);
  }
  @Mutation
  loadConcreteClassesFromConfig(concreteClasses: IConcreteClass[]) {
    this.concreteClasses = concreteClasses.map(c => Object.assign(new ConcreteClass(), c));
    console.log('Store module DictModule loadConcreteClassesFromConfig...', this.concreteClasses.length);
  }
  @Mutation
  loadMethodGroupsFromConfig(methodGroups: MethodGroup[]) {
    this.methodGroups = methodGroups.map(m => Object.assign(new MethodGroup(), m));
    console.log('Store module DictModule loadCMethodGroupsFromConfig...', this.methodGroups.length);
  }
  @Mutation
  loadToolGroupsFromConfig(toolGroups: ToolGroup[]) {
    this.toolGroups = toolGroups.map(m => Object.assign(new ToolGroup(), m));
    console.log('Store module DictModule loadToolGroupsFromConfig...', this.toolGroups.length);
  }
  get addressById() {
    return (id: number): Address => {
      return this.addresses.find(c => c.id === id);
    };
  }
  /*************************************************************************************************/
  /*********************************************** CLIENT ******************************************/
  /*************************************************************************************************/
  get clientById() {
    return (id: number): Client => {
      return this.clients.find(c => c.id === id);
    };
  }
  get clientByName() {
    return (name: string): Client => {
      return this.clients.find(c => c.name === name);
    };
  }
  get clientByCode() {
    return (code: string): Client => {
      return this.clients.find(c => c.code === code);
    };
  }

  @Mutation
  appendClient(client: Client) {
    this.clients.push(client);
  }
  @Mutation
  setClients(clients: Client[]) {
    this.clients = [];
    clients.forEach(client => {
      this.clients.push(Object.assign(new Client(), client));
    });
  }
  @Mutation
  removeClientById(id: number) {
    console.log('Removing ', id);
    this.clients = this.clients.filter(c => c.id !== id);
  }
  @Mutation
  replaceClient(client: Client): any {
    console.log('replaceClient mutation', client);
    const c = this.clients.find(v => v.id === client.id);
    Object.assign(c, client);
  }
  @Mutation
  clearClientCPlaces(clientId: number) {
    this.cPlaces = this.cPlaces.filter(cp => cp.client_id !== clientId);
  }

  @Action({ rawError: true })
  async addClient(client: Client): Promise<AxiosResponse> {
    const response = await API.saveClient(client);
    if (response.data.success) {
      const newClient = Object.assign(new Client(), response.data.client);
      newClient.cplaces = [];
      response.data.client.cplaces.forEach(cp => {
        const newCPlace = Object.assign(new ConstructionPlace(), cp);
        this.appendConstructionPlace(newCPlace);
        newClient.cplaces.push(newCPlace);
      });
      this.appendClient(newClient);
    } else {
      console.log('addClient Error', response.data.error_code);
    }
    return response;
  }
  @Action({ rawError: true })
  async updateClient(client: Client): Promise<AxiosResponse> {
    const response = await API.saveClient(client);
    if (response.data.success) {
      this.replaceClient(response.data.client);
      const cl = this.clients.find(c => c.id === response.data.client.id);
      cl.cplaces = [];

      this.clearClientCPlaces(response.data.client.id);
      response.data.client.cplaces.forEach(cp => {
        const newCPlace = Object.assign(new ConstructionPlace(), cp);
        this.appendConstructionPlace(newCPlace);
        cl.cplaces.push(newCPlace);
      });

      let address = this.addressById(response.data.client.address_id);
      if (address === undefined) {
        address = Object.assign(new Address(), response.data.client.address);
        this.appendAddress(address);
      } else {
        this.replaceAddress(address);
      }
    }
    return response;
  }
  @Action({ rawError: true })
  async removeClient(client: Client): Promise<AxiosResponse> {
    const response = await API.deleteClient(client);
    if (response.data.success) {
      this.removeClientById(client.id);
    }
    return response;
  }

  @Mutation
  loadClients() {
    this.clients.forEach(c => {
      c.load();
    });
  }

  @Action
  @RelatedModel('Client')
  async fetchClients(): Promise<AxiosResponse> {
    console.log('*** FetchClients');
    const response = await API.fetchClients();
    if (response.data.success) {
      this.setClients(response.data.clients);
      return response;
    }
  }

  @Action
  async getClientRDCounter(clientId: number) {
    const response = await API.incrementClientRDCounter(clientId);

    if (response.data.success === false) {
      return response;
    }

    const client = this.clientById(clientId);
    const clients = this.clients.filter(c => c.id !== clientId);

    client.rd_counter++;
    clients.push(client);
    this.setClients(clients);

    return response;
  }

  @Mutation
  appendAddress(address: Address) {
    this.addresses.push(address);
  }
  @Mutation
  replaceAddress(address: Address): any {
    console.log('replaceAddress mutation', address);
    const c = this.addresses.find(v => v.id === address.id);
    Object.assign(c, address);
  }
  @Mutation
  setAddresses(addresses: Address[]) {
    this.addresses = [];
    addresses.forEach(address => {
      this.addresses.push(Object.assign(new Address(), address));
    });
  }
  @Action
  @RelatedModel('Address')
  async fetchAddresses(): Promise<AxiosResponse> {
    console.log('*** FetchAddreses');
    const response = await API.fetchAddresses();
    if (response.data.success) {
      this.setAddresses(response.data.addresses);
      return response;
    }
  }
  /*************************************************************************************************/
  /*********************************************** CPLACE ******************************************/
  /*************************************************************************************************/
  get cPlaceById() {
    return (id: number) => {
      return this.cPlaces.find(cp => cp.id === id);
    };
  }
  get cPlacesByClientId() {
    return (clientId: number) => {
      console.log('cPlacesByClientId', clientId);
      return this.cPlaces.filter(cp => cp.client_id === clientId);
    };
  }
  @Mutation
  appendConstructionPlace(cplace: ConstructionPlace) {
    this.cPlaces.push(cplace);
  }
  @Mutation
  setConstructionPlaces(cplaces: ConstructionPlace[]) {
    this.cPlaces = [];
    cplaces.forEach(cplace => {
      this.cPlaces.push(Object.assign(new ConstructionPlace(), cplace));
    });
  }
  @Mutation
  removeConstructionPlaceById(id: number) {
    console.log('Removing ', id);
    this.cPlaces.filter(c => c.id !== id);
  }

  @Mutation
  addConstructionPlaceByJSON(json: object) {
    console.log('dict::addConstructionPlaceByJSON');
    const cplace = Object.assign(new ConstructionPlace(), json);
    this.cPlaces.push(cplace);
  }

  @Action
  async addConstructionPlace(cplace: ConstructionPlace): Promise<AxiosResponse> {
    console.log('*** addConstructionPlace');
    const response = await API.saveConstructionPlace(cplace);
    if (response.data.success) {
      const changedConstructionPlace = response.data.cplace;
      cplace.id = changedConstructionPlace.id;
      this.appendConstructionPlace(cplace);
      console.log('Added to VUEX cplaces', changedConstructionPlace, 'cplace', cplace.name);
      return response;
    }
  }
  @Action
  @RelatedModel('ConstructionPlace')
  async fetchConstructionPlaces(): Promise<AxiosResponse> {
    console.log('*** FetchConstructionPlaces');
    const response = await API.fetchConstructionPlaces();
    if (response.data.success) {
      this.setConstructionPlaces(response.data.cplaces);
      return response;
    }
  }
  /*************************************************************************************************/
  /*********************************************** DICT ******************************************/
  /*************************************************************************************************/
  @Mutation
  setLocalizations(localizations: Localization[]) {
    this.localizations = [];
    localizations.forEach(localization => {
      this.localizations.push(Object.assign(new Localization(), localization));
    });
  }
  @Action
  @RelatedModel('Localization')
  async fetchLocalizations(): Promise<AxiosResponse> {
    console.log('*** FetchLocs');
    const response = await API.fetchLocalizations();
    if (response.data.success) {
      this.setLocalizations(response.data.localizations);
      return response;
    }
  }
  @Mutation
  setToolGroups(groups: ToolGroup[]) {
    this.toolGroups = [];
    groups.forEach(group => {
      this.toolGroups.push(Object.assign(new ToolGroup(), group));
    });
  }
  @Action
  @RelatedModel('ToolGroup')
  async fetchToolGroups(): Promise<AxiosResponse> {
    console.log('*** FetchToolGroups');
    const response = await API.fetchToolGroups();
    if (response.data.success) {
      this.setToolGroups(response.data.tool_groups);
      return response;
    }
  }
  @Mutation
  setDestinies(destinies: Destiny[]) {
    this.destinies = [];
    destinies.forEach(destiny => {
      this.destinies.push(Object.assign(new Destiny(), destiny));
    });
  }

  @Action
  @RelatedModel('Destiny')
  async fetchDestinies(): Promise<AxiosResponse> {
    console.log('*** FetchDestinies');
    const response = await API.fetchDestinies();
    if (response.data.success) {
      this.setDestinies(response.data.destinies);
      return response;
    }
  }

  @Mutation
  appendDestiny(destiny: Destiny): void {
    this.destinies.push(destiny);
  }

  @Action
  async addDestiny(destiny: Destiny): Promise<AxiosResponse> {
    const response = await API.addDestiny(destiny);

    if (response.data.success) {
      response.data.destiny.group_id = response.data.destiny.group;
      delete response.data.destiny.group;
      response.data.destiny = Object.assign(new Destiny(), response.data.destiny);
      this.appendDestiny(response.data.destiny);
    }

    return response;
  }

  @Mutation
  changeDestiny(args: { destiny: Destiny; oldName: string }): void {
    const d = this.destinies.filter(el => el.name === args.oldName)[0];
    Object.assign(d, args.destiny);
  }

  @Action
  async editDestiny(args: { destiny: Destiny; oldName: string }): Promise<AxiosResponse> {
    const response = await API.editDestiny(args.destiny, args.oldName);

    if (response.data.success) {
      args.destiny = response.data.destiny;
      this.changeDestiny(args);
    }

    return response;
  }

  @Mutation
  deleteDestiny(name: string): void {
    const index = this.destinies.findIndex(el => el.name === name);
    Vue.delete(this.destinies, index);
  }

  @Action
  async removeDestiny(oldName: string): Promise<AxiosResponse> {
    const response = await API.removeDestiny(oldName);

    if (response.data.success) this.deleteDestiny(oldName);

    return response;
  }

  @Mutation
  setDestinyGroups(destinyGroups: DestinyGroup[]) {
    this.destinyGroups = [];
    destinyGroups.forEach(destinyGroup => {
      this.destinyGroups.push(Object.assign(new DestinyGroup(), destinyGroup));
    });
  }

  @Action
  @RelatedModel('DestinyGroup')
  async fetchDestinyGroups(): Promise<AxiosResponse> {
    console.log('*** FetchDestinyGroups');
    const response = await API.fetchDestinyGroups();
    if (response.data.success) {
      this.setDestinyGroups(response.data.destiny_groups);
      return response;
    }
  }

  @Mutation
  appendDestinyGroup(destinyGroup: DestinyGroup): void {
    this.destinyGroups.push(destinyGroup);
  }

  @Action
  async addDestinyGroup(destinyGroup: DestinyGroup): Promise<AxiosResponse> {
    const response = await API.addDestinyGroup(destinyGroup);

    if (response.data.success) this.appendDestinyGroup(response.data.destiny_group);

    return response;
  }

  @Mutation
  changeDestinyGroup(args: { destinyGroup: DestinyGroup; oldName: string }): void {
    const dg = this.destinyGroups.filter(el => el.name === args.oldName)[0];
    Object.assign(dg, args.destinyGroup);
  }

  @Action
  async editDestinyGroup(args: { destinyGroup: DestinyGroup; oldName: string }): Promise<AxiosResponse> {
    const response = await API.editDestinyGroup(args.destinyGroup, args.oldName);

    if (response.data.success) {
      args.destinyGroup = response.data.destiny_group;
      this.changeDestinyGroup(args);
    }

    return response;
  }

  @Mutation
  deleteDestinyGroup(name: string): void {
    const index = this.destinyGroups.findIndex(el => el.name === name);
    Vue.delete(this.destinyGroups, index);
  }

  @Action
  async removeDestinyGroup(oldName: string): Promise<AxiosResponse> {
    const groupId = this.destinyGroups.filter(el => el.name === oldName)[0].id;
    const response = await API.removeDestinyGroup(groupId);

    if (response.data.success) this.deleteDestinyGroup(oldName);

    return response;
  }

  @Mutation
  setMethodGroups(methodgroups: MethodGroup[]) {
    this.methodGroups = [];
    methodgroups.forEach(methodgroup => {
      this.methodGroups.push(Object.assign(new MethodGroup(), methodgroup));
    });
  }
  @Action
  @RelatedModel('MethodGroup')
  async fetchMethodGroups(): Promise<AxiosResponse> {
    console.log('*** FetchMethodGroups');
    const response = await API.fetchMethodGroups();
    if (response.data.success) {
      this.setMethodGroups(response.data.method_groups);
      return response;
    }
  }

  @Mutation
  setConcreteClassGroups(groups: ConcreteClassGroup[]) {
    this.concreteClassGroups = [];
    groups.forEach(group => {
      this.concreteClassGroups.push(Object.assign(new ConcreteClassGroup(), group));
    });
  }
  @Action
  @RelatedModel('ConcreteClassGroup')
  async fetchConcreteClassGroups(): Promise<AxiosResponse> {
    console.log('*** fetchConcreteClassGroups');
    const response = await API.fetchConcreteClassGroups();
    if (response.data.success) {
      this.setConcreteClassGroups(response.data.concrete_class_groups);
      return response;
    }
  }
  @Mutation
  setConcreteClasses(classes: ConcreteClass[]) {
    this.concreteClasses = [];
    classes.forEach(c => {
      this.concreteClasses.push(Object.assign(new ConcreteClass(), c));
    });
  }
  @Action
  @RelatedModel('ConcreteClass')
  async fetchConcreteClasses(): Promise<AxiosResponse> {
    console.log('*** fetchConcreteClasses');
    const response = await API.fetchConcreteClasses();
    if (response.data.success) {
      this.setConcreteClasses(response.data.concrete_classes);
      return response;
    }
  }
  @Mutation
  setConcreteClassSubgroups(subgroups: ConcreteClass[]) {
    this.concreteClassSubgroups = [];
    subgroups.forEach(s => {
      this.concreteClassSubgroups.push(Object.assign(new ConcreteClassSubgroup(), s));
    });
  }
  @Action
  @RelatedModel('ConcreteClassSubgroup')
  async fetchConcreteClassSubgroups(): Promise<AxiosResponse> {
    console.log('*** fetchConcreteClassSubgroups');
    const response = await API.fetchConcreteClassSubgroups();
    if (response.data.success) {
      this.setConcreteClassSubgroups(response.data.concrete_class_subgroups);
      return response;
    }
  }

  @Mutation
  setMaterials(materials: Material[]) {
    this.materials = [];
    materials.forEach(m => {
      this.materials.push(Object.assign(new Material(), m));
    });
  }
  @Action
  @RelatedModel('Material')
  async fetchMaterials(): Promise<AxiosResponse> {
    console.log('*** fetchMaterials');
    const response = await API.fetchMaterials();
    if (response.data.success) {
      this.setMaterials(response.data.materials);
      return response;
    }
  }

  get departmentsCopy(): Department[] {
    return JSON.parse(JSON.stringify(this.departments));
  }

  @Mutation
  setDepartments(departments: Department[]) {
    this.departments = [];
    for (const department of departments) {
      this.departments.push(Object.assign(new Department(), department));
    }
  }
  @Action
  @RelatedModel('Department')
  async fetchDepartments(): Promise<AxiosResponse> {
    const response = await API.fetchDepartments();
    if (response.data.success === true) {
      this.setDepartments(response.data.departments);
      return response;
    }
  }
}

export default getModule(DictModule);
