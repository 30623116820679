












import { Component, Vue, Watch } from 'vue-property-decorator';
import TopPanel from '@/components/dummy/TopPanel.vue';

@Component({
  components: {
    TopPanel,
  },
})
export default class Visit extends Vue {}
