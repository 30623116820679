











import { Component, Prop, Vue } from 'vue-property-decorator';
import MainMenu from '../../components/dummy/MainMenu.vue';
import userModule from '../../store/modules/user';
import usersModule from '../../store/modules/users';
import { UserRightSymbols } from '../../consts';
import { copyObject } from '../../helpers';

const LAB_PREFIX = 'lab';
const CERT_INDEX = 2;

@Component({
  components: { MainMenu },
})
export default class LaborantMenu extends Vue {
  wide: boolean;
  @Prop({ default: true }) menuItemsVisible: boolean;

  constructor() {
    super();
    this.wide = false;
  }

  get height() {
    return 'height: ' + window.innerHeight + 'px';
  }

  get items() {
    const items = [
      {
        name: 'calendars',
        title: 'Kalendarz',
        icon: 'calendar-alt',
        items: [
          {
            name: 'cv/cvm',
            title: 'Wizyt',
            module: 'lab',
          },
          {
            name: 'ce/cem',
            title: 'Badań',
            module: 'lab',
          } /*
          {
            name: 'calendarmetrocontrol',
            title: 'Kontroli metrologicznej',
            module: 'lab',
          },
          {
            name: 'calendarcerts',
            title: 'Kalendarz inspekcji',
            module: 'cert',
          },*/,
        ],
      },
      {
        name: LAB_PREFIX,
        title: 'Laboratorium',
        icon: 'vial',
        items: [
          {
            name: 'laborders',
            title: 'Zamówienia',
            module: 'lab',
          },
          {
            name: 'resultsdoclist',
            title: 'Wyniki badań',
            module: 'lab',
          },
          {
            name: 'dashboardaudit',
            title: 'Tablica auditów',
            module: 'lab',
          },
          {
            name: 'dashboarddocument',
            title: 'Tablica dokumentów',
            module: 'lab',
          },
          {
            name: 'dashboardoffer',
            title: 'Akcje ofertowe',
            module: 'lab',
          },
          /*
          {
            name: 'DashboardSettlement',
            title: 'Rozliczenia',
            module: 'lab',
          },
          */
        ],
      },
      {
        name: 'administer',
        title: 'Administracja',
        icon: 'user-cog',
        items: [
          {
            name: 'methodlist',
            title: 'Metody badawcze',
            module: 'lab',
          },
          {
            name: 'personellist',
            title: 'Personel',
            module: 'lab',
          },
          {
            name: 'toollist',
            title: 'Urządzenia',
            module: 'lab',
          },
          {
            name: 'carlist',
            title: 'Samochody',
            module: 'lab',
          },
          {
            name: 'dashboardactions',
            title: 'Tablica akcji',
            module: 'lab',
          },
        ],
      },
      {
        name: 'dictionaries',
        title: 'Słowniki',
        icon: 'list-alt',
        items: [
          {
            name: 'clientlist',
            title: 'Klienci',
            module: 'lab',
          },
          /*
          {
            name: 'places',
            title: 'Lokalizacje',
            module: 'lab',
          },*/
          {
            name: 'examDestiny',
            title: 'Przeznaczenia próbek',
            module: 'lab',
          },
          /*
          {
            name: 'units',
            title: 'Jednostki',
            module: 'lab',
          },
          {
            name: 'classes',
            title: 'Klasy',
            module: 'lab',
          },
          {
            name: 'exams-packages',
            title: 'Badania/Pakiety',
            module: 'lab',
          },
          {
            name: 'trainings',
            title: 'Szkolenia',
            module: 'lab',
          },
          {
            name: 'admin',
            title: 'Administrator',
            module: 'lab',
          },*/
        ],
      },
    ];
    if (this.canSeeCertDashboard === true) {
      items
        .find(i => i.name === LAB_PREFIX)
        .items.splice(CERT_INDEX, 0, {
          name: 'dashboardcert',
          title: 'Certyfikacja',
          module: 'lab',
        });
    }
    return items;
  }

  get getUser() {
    return userModule.user;
  }

  get canSeeCertDashboard() {
    const rightsToSeeCert = [
      UserRightSymbols.CERT_DASHBOARD_PROCESS,
      UserRightSymbols.CERT_DASHBOARD_IN_PROGRESS,
      UserRightSymbols.CERT_PREVIEW,
    ];
    console.log('userModule.user', copyObject(userModule.user));
    const userRightsSymbols = usersModule.getAllUserRightsList(userModule.user).map(r => r.symbol);

    for (const rightSymbol of rightsToSeeCert) {
      if (userRightsSymbols.includes(rightSymbol) === true) {
        return true;
      }
    }

    return false;
  }
}
