















































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import * as types from '../../types';
import methodsModule from '../../store/modules/methods';
import toolsModule from '../../store/modules/tools';

@Component
export default class ModalExamStageTools extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;
  @Prop({ type: Boolean, required: false, default: false }) viewOnly;

  private show = false;
  private events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];

  stage = null;
  stageTools = [];
  selectedTool = null;

  choosenTools = [];

  @Emit()
  modalClose() {
    return 1;
  }

  get selTool() {
    return this.selectedTool;
  }

  selectTool(e: any) {
    console.log('Selected', e);
    this.selectedTool = e;
  }

  addTool() {
    if (this.choosenTools.find(t => t.id === this.selectedTool.id) === undefined) {
      this.choosenTools.push(this.selectedTool);
      console.log('Added ', this.selectedTool);
    }
    console.log('ChoosenTools', this.choosenTools);
  }

  removeTool(tool: types.Tool) {
    this.choosenTools = this.choosenTools.filter(t => t.id !== tool.id);
  }

  async showModal(examStage: types.ExamStage) {
    this.stage = examStage;

    await toolsModule.fetchTools();
    this.stageTools = toolsModule.toolsByExamStage(examStage);

    this.selectedTool = this.stageTools.length > 0 ? this.stageTools[0] : null;

    this.choosenTools = examStage.tools;

    this.show = true;
  }
  saveModal() {
    this.$emit(consts.ModalEvents.OK_EVENT, this.choosenTools);
    this.show = false;
  }
  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
