import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ICollect } from '@/store/models';
import { Collect, CollectMethod } from '@/types';
import * as helpers from '@/helpers';
import samplesModule from '@/store/modules/samples';
import methodsModule from '@/store/modules/methods';
import collectExamsModule from '@/store/modules/collect_exams';
import store from '@/store';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'collects',
  store,
  dynamic: true,
})
class CollectsModule extends VuexModule {
  public collects: Collect[] = [];
  public collectMethods: CollectMethod[] = [];

  public get collectById() {
    return (id: number): Collect => {
      return this.collects.find(c => c.id === id);
    };
  }
  public get collectCopyById() {
    return (id: number): Collect => {
      return Object.assign(new Collect(), JSON.parse(JSON.stringify(this.collectById(id))));
    };
  }
  public get collectMethodsByCollectId() {
    return (id: number): CollectMethod[] => {
      return this.collectMethods.filter(cm => cm.collect_id === id);
    };
  }
  get print() {
    return (mixerId: number, nested = false) => {
      this.collects
        .filter(c => c.mixer_id === mixerId)
        .forEach(collect => {
          console.log('        COLLECT: id:', collect.id, 'number', collect.air_temp);
          if (nested) {
            collectExamsModule.print(collect.id, nested);
            samplesModule.printCollectSamples(collect.id, nested);
          }
        });
    };
  }

  @Mutation
  clear() {
    this.collects = [];
    this.collectMethods = [];
    console.log('collects module cleared...');
  }

  @Mutation
  replaceCollect(collect: Collect): any {
    //console.log('replaceCollect mutation', collect);
    const c = this.collects.find(c => c.id === collect.id);
    Object.assign(c, collect);
  }

  @Mutation
  setCollects(collects: Collect[]) {
    this.collects = [];
    collects.forEach(collect => {
      this.collects.push(Object.assign(new Collect(), collect));
    });
  }

  @Mutation
  appendCollect(collect: Collect) {
    //console.log('Append collect', collect);
    this.collects.push(collect);
  }

  @Mutation
  addCollectByJSON(json: object) {
    const collect = Object.assign(new Collect(), json);
    collect.methods = [];
    collect.method_ids.forEach(method_id => {
      collect.methods.push(methodsModule.methodById(method_id));
    });
    this.collects.push(collect);
    console.log('collects::addCollectByJSON', collect.id);

    samplesModule.clearCollectSamples(collect.id);
    json['samples'].forEach(sample_json => {
      samplesModule.addSampleByJSON(sample_json);
    });
    collectExamsModule.clearCollectExam(collect.id);
    collectExamsModule.addCollectExamByJSON(json['exam']);
  }
  @Mutation
  clearMixerCollects(mixerId: number) {
    this.collects = this.collects.filter(c => c.mixer_id !== mixerId);
  }
  /*
  @Action
  updateCollectByJSON(json: object) {
    console.log('updateCollectByJSON', json['id']);
    let collect = this.collects.find(c => c.id === json['id']);
    if (collect) {
      this.replaceCollect(json as Collect);
    } else {
      collect = Object.assign(new Collect(), json);
      this.appendCollect(collect);
    }

    collectExamsModule.updateCollectExamByJSON(json['exam']);

    json['samples'].forEach(sample_json => {
      samplesModule.updateSampleByJSON(sample_json);
    });
  }
*/
  @Action
  async updateCollect(collect: Collect): Promise<AxiosResponse> {
    const response = await API.saveCollect(collect);
    if (response.data.success) {
      collect.id = response.data.collect.id;
      collect.mixer_id = response.data.collect.mixer;
      collect.methods = [];
      collect.method_ids.forEach(method_id => {
        collect.methods.push(methodsModule.methodById(method_id));
      });
      this.replaceCollect(collect);
      console.log('Replace to VUEX collects', collect);
      return response;
    }
  }
  @Action
  async addCollect(collect: Collect): Promise<AxiosResponse> {
    const response = await API.saveCollect(collect);
    if (response.data.success) {
      collect.id = response.data.collect.id;
      this.appendCollect(collect);
      console.log('Added to VUEX collects', collect);
      return response;
    }
  }
  @Action
  @RelatedModel('Collect')
  async fetchCollects(): Promise<AxiosResponse> {
    console.log('*** FetchCollects');
    const response = await API.fetchCollects();
    if (response.data.success) {
      this.setCollects(response.data.collects);
      return response;
    }
  }

  @Mutation
  loadCollectFromConfig(configDict: ICollect[]): any {
    this.collects = [];
    configDict.forEach(c => {
      const collect = new Collect();
      Object.assign(collect, c);
      this.collects.push(collect);
    });
    console.log('Store module CollectsModule loaded...', this.collects.length);
  }
}

export default getModule(CollectsModule);
