import { render, staticRenderFns } from "./ModalSetExamDestiny.vue?vue&type=template&id=4dba1ac5&scoped=true&"
import script from "./ModalSetExamDestiny.vue?vue&type=script&lang=ts&"
export * from "./ModalSetExamDestiny.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dba1ac5",
  null
  
)

export default component.exports