











































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class DashboardHeader extends Vue {
  private mainTitle = '<brak nazwy>';
  private badges = [];

  @Prop({ required: true, type: String }) readonly title: string;
  @PropSync('fullscreenProp', { type: Boolean }) fullscreen: boolean;
  @PropSync('entityType', { type: Number }) entityTypeSelected: number;
  buttonClick = 0;

  get entitySel() {
    return { name: this.entityTypeSelected, text: this.entityTypes[this.entityTypeSelected].text };
  }
  entitySelected(event) {
    console.log('selected', event);
    this.entityTypeSelected = event.name;
  }

  get entityTypes() {
    return [
      { name: 0, text: 'Metody badawcze' },
      { name: 1, text: 'Urządzenia' },
      { name: 2, text: 'Personel' },
    ];
  }

  // toggleFullscreen() {
  //   this.fullscreen = !this.fullscreen;
  //   console.log('to', this.fullscreen);
  // }
}
