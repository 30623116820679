




















































































































































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import Header from '../../components/dummy/Header.vue';
import Footer from '../../components/dummy/Footer.vue';
import userModule from '../../store/modules/user';
import usersModule from '../../store/modules/users';
import examsModule from '../../store/modules/exams';
import { Client, Exam, ExamStage, DestinyGroup, ExamSampleStageParam } from '../../types';
import * as consts from '../../consts';
import * as helpers from '../../helpers';
import moment from 'moment';
import breadcrumbModule from '../../store/modules/breadcrumb';
import dictModule from '../../store/modules/dict';
import samplesModule from '../../store/modules/samples';
import protocolsModule from '../../store/modules/protocols';
import visitsModule from '../../store/modules/visits';
import ModalQuestion from '../../components/dummy/ModalQuestion.vue';
import ModalCalendarExamsDayFilter from '../../components/spec/ModalCalendarExamsDayFilter.vue';
import * as API from '../../store/api';

const TO_EXAM = 1;
const IN_EXAM = 2;
const AFTER_EXAM = 3;
const ANY_STATUS_EXAM = 4;

const EXAMS_MINE = 1;
const EXAMS_ALL_LABORANTS = 2;

const ASIGNED_EXAMS = 1;
const UNASIGNED_EXAMS = 2;
const ALL_EXAMS = 3;

type MultiselectOption = {
  name: number;
  text: string;
};
type ExamsFilterOptions = {
  statusSelected?: number;
  labCoordOptionSel?: number;
  asignedOrNotSel?: number;
};

@Component({
  components: { Header, Footer, ModalQuestion, ModalCalendarExamsDayFilter },
})
export default class CalendarExamsDay extends Vue {
  @Ref() readonly questionModal: ModalQuestion;
  @Ref() readonly modalCalendarExamsDayFilter: ModalCalendarExamsDayFilter;

  TO_EXAM = TO_EXAM;
  IN_EXAM = IN_EXAM;
  AFTER_EXAM = AFTER_EXAM;
  ANY_STATUS_EXAM = ANY_STATUS_EXAM;

  EXAMS_MINE = EXAMS_MINE;
  EXAMS_ALL_LABORANTS = EXAMS_ALL_LABORANTS;

  ASIGNED_EXAMS = ASIGNED_EXAMS;
  UNASIGNED_EXAMS = UNASIGNED_EXAMS;

  private currentMonth = 1;
  private currentYear = 2020;
  private currentWeek = 1;
  private currentDay = 1;
  currDate: moment.Moment = moment();
  revalidate = 0;
  enabled = true;
  previousDestinyId = null;
  exams = [];
  user_id = -1;
  dataReady = false;
  revalidateSamples = 0;

  showMergeModal = false;
  btnSave = 0;

  selectedExam: Exam = null;
  examsToMerge: Exam[] = [];

  efo: ExamsFilterOptions = {
    labCoordOptionSel: 1,
    asignedOrNotSel: 1,
    statusSelected: 1,
  };
  noExamsToShow = false;
  filterParams: { field: string; value: any }[] = [];

  get statusSel() {
    return this.statuses[this.efo.statusSelected - 1];
  }
  get labCoordOptionsSel() {
    return this.labCoordOptions[this.efo.labCoordOptionSel - 1];
  }
  get asignedOrNotOptionsSel() {
    return this.asignedOrNotOptions[this.efo.asignedOrNotSel - 1];
  }
  unblockCoord(exam: Exam) {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz anulować przypisanie badania wybranej osobie?',
      exam: exam,
      status: 1,
    });
  }
  startCoord(exam: Exam) {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz rozpocząć badanie?',
      exam: exam,
      status: 3,
    });
  }
  openCoord(exam: Exam) {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz ponownie otworzyć badanie?',
      exam: exam,
      status: 3,
    });
  }
  async assignCoord(exam: Exam) {
    exam.status = 2;
    console.log('lockUser', exam, exam.current_laborant_id);
    return await examsModule.updateExam(exam);
  }
  selectStatus(event: MultiselectOption) {
    this.efo.statusSelected = event.name;
    this.sendEfoToVuex();
    this.fillExams();
  }
  selectLabCoordOptions(event: MultiselectOption) {
    this.efo.labCoordOptionSel = event.name;
    this.sendEfoToVuex();
    if (event.name === EXAMS_ALL_LABORANTS && this.efo.asignedOrNotSel === UNASIGNED_EXAMS) {
      this.selectStatus(this.statuses.find(s => s.name === TO_EXAM));
    } else {
      this.selectAsignedOrNotOptions(this.asignedOrNotOptions.find(a => a.name === ASIGNED_EXAMS));
    }
  }
  selectAsignedOrNotOptions(event: MultiselectOption) {
    this.efo.asignedOrNotSel = event.name;
    this.sendEfoToVuex();
    if (event.name === UNASIGNED_EXAMS) {
      this.selectStatus(this.statuses.find(s => s.name === TO_EXAM));
    } else if (event.name === ALL_EXAMS) {
      this.selectStatus(this.statuses.find(s => s.name === ANY_STATUS_EXAM));
    } else {
      this.fillExams();
    }
  }

  get statuses(): MultiselectOption[] {
    return [
      { name: TO_EXAM, text: 'DO BADANIA' },
      { name: IN_EXAM, text: 'W TRAKCIE BADAŃ' },
      { name: AFTER_EXAM, text: 'ZAKOŃCZONE' },
      { name: ANY_STATUS_EXAM, text: 'WSZYSTKIE STATUSY' },
    ];
  }
  get labCoordOptions(): MultiselectOption[] {
    return [
      { name: EXAMS_MINE, text: 'BADANIA MOJE' },
      { name: EXAMS_ALL_LABORANTS, text: 'BADANIA WSZYSTKICH' },
    ];
  }
  get asignedOrNotOptions(): MultiselectOption[] {
    return [
      { name: ASIGNED_EXAMS, text: 'PRZYPISANE BADANIA' },
      { name: UNASIGNED_EXAMS, text: 'NIEPRZYPISANE BADANIA' },
      { name: ALL_EXAMS, text: 'WSZYSTKIE BADANIA' },
    ];
  }

  get disableStatusSelection(): boolean {
    return (
      this.isLabCoord === true &&
      this.efo.labCoordOptionSel === EXAMS_ALL_LABORANTS &&
      this.asignedOrNotOptionsSel.name === UNASIGNED_EXAMS
    );
  }

  //  ExamsFilterOptions
  sendEfoToVuex() {
    examsModule.setExamsFilterOptions({
      labCoordOptionSel: this.efo.labCoordOptionSel,
      asignedOrNotSel: this.efo.asignedOrNotSel,
      statusSelected: this.efo.statusSelected,
    });
  }

  canExamsBeMerged(exam1: Exam, exam2: Exam) {
    if (exam1.destiny_id === exam2.destiny_id && exam1.sample_ids.length === exam2.sample_ids.length) {
      return true;
    }
    return false;
  }

  get examsPossibleToMerge() {
    return (exam: Exam): Exam[] => {
      if (exam === null) {
        return [];
      }
      return this.exams.filter(element => element.id !== exam.id && this.canExamsBeMerged(exam, element));
    };
  }

  selectedExamToMerge(exam) {
    const index = this.examsToMerge.findIndex(el => el.id === exam.id);
    console.log(index, 'Found?');
    if (index !== -1) {
      this.examsToMerge.splice(index, 1);
    } else {
      this.examsToMerge.push(exam);
    }
    console.log('examToMerge', this.examsToMerge);
  }

  canMergeExam(exam: Exam) {
    const destiny = dictModule.destinyById(exam.destiny_id);
    return (
      this.examsPossibleToMerge(exam).length !== 0 &&
      dictModule.destinyGroupById(destiny.group_id).symbol !== consts.DestinyGroupCodes.PROBE
    );
  }

  mergeModalShow(exam: Exam) {
    this.selectedExam = exam;
    this.showMergeModal = true;
  }

  mergeModalHide() {
    this.examsToMerge = [];
    this.selectedExam = null;
    this.showMergeModal = false;
  }

  async mergeExams() {
    helpers.info('Łączenie badań w toku...');
    const examsToMerge = this.examsToMerge;
    const exam = this.selectedExam;
    examsToMerge.forEach(examToMerge => {
      const toDelete = examsModule.examSampleStageParams
        .filter(el => examToMerge.sample_ids.includes(el.sample_id))
        .slice();
      toDelete.forEach(param => {
        examsModule.removeExamSampleStageParamById(param.id);
      });
      const modelSample = samplesModule.sampleById(exam.sample_ids[0]);
      const params = examsModule.examSampleStageParams.filter(el => el.sample_id === modelSample.id);
      let ffid = examsModule.examSampleStageParams.length;
      params.forEach(param => {
        examToMerge.sample_ids.forEach(sid => {
          const new_param = Object.assign(new ExamSampleStageParam(), param);
          new_param.id = ffid;
          ffid++;
          new_param.sample_id = sid;
          examsModule.appendExamSampleStageParam(new_param);
        });
      });

      examToMerge.sample_ids.forEach(id => {
        const sample = samplesModule.sampleById(id);
        sample.exam_id = exam.id;
        sample.load(); //reload exam
        exam.sample_ids.push(id);
      });
      examToMerge.samples = [];
      const mIndex = this.exams.findIndex(el => el.id === examToMerge.id);
      this.exams.splice(mIndex, 1);
      this.revalidateSamples++;
    });

    const response = await API.mergeExams(examsToMerge, exam);

    this.mergeModalHide();
    if (response.data.success) {
      helpers.info('Pomyślnie złączono badania!');
    } else {
      helpers.error('Wystąpił błąd.');
    }
  }

  setPreviousDestinyId(id: number) {
    this.previousDestinyId = id;
  }

  async lockUser(exam: Exam) {
    //console.log('LabID', this.laborantSel['id']);
    //exam['current_laborant_id'] = this.laborantSel['id'];
    if (exam.status === 1) {
      exam.status = 2;
      console.log('lockUser', exam, exam.current_laborant_id);
      return await examsModule.updateExam(exam);
    } else if (exam.status === 2) {
      if (this.isLabCoord === true) {
        this.questionModal.showModal({
          question: 'Czy na pewno chcesz odblokować badanie?',
          exam: exam,
          status: 1,
        });
      } else {
        this.questionModal.showModal({
          question: 'Czy na pewno chcesz rozpocząć badanie?',
          exam: exam,
          status: 3,
        });
      }
    } else if (exam.status === 3) {
      this.questionModal.showModal({
        question: 'Czy na pewno chcesz anulować badanie?',
        exam: exam,
        status: 2,
      });
    } else {
      // only 1,2 statues, we cannot unlock closed exam
    }
  }

  async questionOK(params: object) {
    const exam = params['exam'];
    exam.status = params['status'];
    if (exam.status === consts.ExamStatus.PENDING.id) {
      exam.start_date_time = moment().format(consts.DB_DATE_TIME_FORMAT);
    }
    console.log('lockUser after question', exam, exam.current_laborant_id, exam.start_date_time);
    return await examsModule.updateExam(exam);
  }

  get examStage() {
    return (exam: Exam): ExamStage => {
      //console.log('examStage', exam.stage_id);
      return examsModule.examStageById(exam.stage_id);
    };
  }

  get laborantSel() {
    return (exam: Exam) => {
      this.revalidate;
      const labId = exam.current_laborant_id;
      const user = usersModule.users.find(u => u.id === labId);
      if (user !== undefined) {
        return { name: user.firstname + ' ' + user.lastname, id: user.id };
      }
      return this.workers[0];
    };
  }

  get workers() {
    this.revalidate;
    let optionsUsers = [{ name: '-- brak wyboru --', id: 0 }];
    const usersWithExamStartRight = usersModule.getUsersByRightSymbolInAllRights(consts.UserRightSymbols.EXAM_START);
    const users = usersModule.getWorkingUsers(usersWithExamStartRight);

    optionsUsers = [
      ...optionsUsers,
      ...users.map(u => {
        const retUser = { name: u.firstname + ' ' + u.lastname, id: u.id };
        return retUser;
      }),
    ];
    return optionsUsers;
  }
  async workerSelected(e, exam) {
    exam['current_laborant_id'] = e.id;
    Object.assign(new Exam(), exam);
    examsModule.replaceExam(exam);
    console.log('Lab selected', e, exam.current_laborant_id);
    await examsModule.updateExam(exam);
    this.revalidate++;
  }

  get client() {
    return (exam: Exam): Client => {
      if (exam.client_id !== undefined) {
        return dictModule.clientById(exam.client_id);
      } else {
        return dictModule.clientById(exam.client.id);
      }
    };
  }

  get getClients() {
    return dictModule.clients;
  }

  async fillExams() {
    console.log('################################### fillExams ##################################');
    console.log('selectedStatus', this.efo.statusSelected, 'isLabCoord', this.isLabCoord);
    let exams = examsModule.allExams.filter(e => {
      /*
      console.log(
        'EXAM:',
        e,
        this.currDate.format(consts.DATE_TIME_FORMAT),
        e.n_date,
        moment(this.currDate).diff(moment(e.n_date)),
        helpers.isSameDay(moment(), moment(this.currDate)),
        this.efo.statusSelected === AFTER_EXAM && e.status === 4,
        (this.efo.statusSelected === TO_EXAM && (e.status === 1 || e.status === 2)) ||
          (this.efo.statusSelected === IN_EXAM && e.status === 3) ||
          (this.efo.statusSelected === AFTER_EXAM && e.status === 4),
        e.current_laborant_id,
        userModule.user.id
      );
      */
      let res =
        (helpers.isSameDay(moment(), moment(this.currDate))
          ? moment(this.currDate).diff(moment(e.n_date)) >= 0
          : moment(this.currDate).diff(moment(e.n_date)) === 0) &&
        (this.isLabCoord === true ? this.showToLabCoord(e) === true : e.current_laborant_id === userModule.user.id);

      res =
        res &&
        ((this.efo.statusSelected === TO_EXAM &&
          (e.status === consts.ExamStatus.TO_ASSIGN.id || e.status === consts.ExamStatus.ASSIGNED.id)) ||
          (this.efo.statusSelected === IN_EXAM && e.status === consts.ExamStatus.PENDING.id) ||
          (this.efo.statusSelected === AFTER_EXAM && e.status === consts.ExamStatus.FINISHED.id) ||
          this.efo.statusSelected === ANY_STATUS_EXAM);
      if (res) {
        //console.log('Adding exam Laborant: ', e);
      }
      return res;
    });
    console.log('exams', exams);
    if (this.filterParams.length > 0) {
      console.log('FILL Filters', this.filterParams);
      exams = await this.filterExams(exams, this.filterParams);
      console.log('After filtering', exams);
    }
    this.exams = exams.sort((a, b) => {
      return dictModule.destinyById(a.destiny_id).id > dictModule.destinyById(b.destiny_id).id ? 1 : -1;
    });
    this.noExamsToShow = this.exams.length === 0;
    await this.fillStages();
  }

  async fillStages() {
    console.log('Fill stages');
    for (let i = 0; i < this.exams.length; i++) {
      //console.log('Fill stages for exam', this.exams[i]);
      //await examsModule.fetchExamStages(this.exams[i]);
    }
  }

  modalExamFilterOK(filters) {
    console.log('modalExamFilterOK', filters);
    this.filterParams = filters;
    this.fillExams();
  }
  modalExamFilterCancel() {
    console.log('modalExamFilterCancel');
  }

  filterPage() {
    this.modalCalendarExamsDayFilter.showModal(this.filterParams);
  }

  async filterExams(exams: Exam[], filters: { field: string; value: any }[]) {
    let result = true;
    const examsFiltered = exams.filter(exam => {
      const samples = [];
      const protocols = [];
      exam.sample_ids.forEach(id => {
        const sample = samplesModule.sampleById(id);
        if (!samples.includes(sample)) {
          samples.push(sample);
        }
        const protocol = protocolsModule.protocolBySample(sample);
        if (!protocols.includes(protocol)) {
          protocols.push(protocol);
        }
      });
      result = true;

      filters.forEach(filter => {
        if (result) {
          if (filter.field === 'client_id') {
            result = exam.client_id === filter.value;
            return result;
          }
          if (filter.field === 'prot_number') {
            for (const protocol of protocols) {
              if (protocol.number.toLowerCase().includes(filter.value.toLowerCase())) {
                result = true;
                return result;
              } else {
                result = false;
              }
            }
            return result;
          }
          if (filter.field === 'destiny_id') {
            result = exam.destiny_id === filter.value;
            return result;
          }
          if (filter.field === 'destiny_group_id') {
            const destiny = dictModule.destinyById(exam.destiny_id);
            result = destiny.group_id === filter.value;
            return result;
          }
          if (filter.field === 'laborant_id') {
            result = exam.current_laborant_id === filter.value;
            return result;
          }
          if (filter.field === 'stage_id') {
            const examStage = this.examStage(exam).index;
            result = examStage === filter.value;
            return result;
          }
          if (filter.field === 'take_date_from') {
            for (const protocol of protocols) {
              const visit = visitsModule.visitById(protocol.visit_id);
              if (
                moment(visit.date, consts.DB_DATE_FORMAT).isSameOrAfter(moment(filter.value, consts.DB_DATE_FORMAT))
              ) {
                result = true;
                return result;
              } else {
                result = false;
              }
            }
            return result;
          }
          if (filter.field === 'take_date_to') {
            for (const protocol of protocols) {
              const visit = visitsModule.visitById(protocol.visit_id);
              if (
                moment(visit.date, consts.DB_DATE_FORMAT).isSameOrBefore(moment(filter.value, consts.DB_DATE_FORMAT))
              ) {
                result = true;
                return result;
              } else {
                result = false;
              }
            }
            return result;
          }
          if (filter.field === 'sample_number') {
            for (const sample of samples) {
              if (sample.number.toLowerCase().includes(filter.value.toLowerCase())) {
                result = true;
                return result;
              } else {
                result = false;
              }
            }
            return result;
          }
          if (filter.field === 'exam_number') {
            result = exam.number.includes(filter.value);
            return result;
          }
        }
      });
      return result;
    });
    return examsFiltered;
  }

  get getSamples() {
    this.revalidateSamples;
    return (exam: Exam) => {
      return samplesModule.samples.filter(s => s.exam_id === exam.id);
    };
  }

  showExam(exam: Exam) {
    this.$router.push({
      path: 'lab/ce/cem/examcard/',
      name: 'examcard',
      params: {
        date: this.currDate.date() + '_' + (this.currDate.month() + 1).toString() + '_' + this.currDate.year(),
        exam_id: exam.id.toString(),
      },
    });
  }

  get isLabCoord() {
    return userModule.inGroupBySymbol(consts.UserGroupSymbols.LAB_COORD);
  }
  showToLabCoord(exam: Exam): boolean {
    //console.log('efo, exam', this.efo, exam);
    if (this.efo.labCoordOptionSel === EXAMS_MINE && exam.current_laborant_id !== userModule.user.id) {
      return false;
    }
    if (this.efo.asignedOrNotSel === UNASIGNED_EXAMS && exam.current_laborant_id !== null) {
      return false;
    }
    if (this.efo.asignedOrNotSel === ASIGNED_EXAMS && exam.current_laborant_id === null) {
      return false;
    }
    return true;
  }

  get visibleExams() {
    return this.exams.filter(el => {
      const samples = this.getSamples(el);
      if (samples.length === 0) {
        return false;
      }
      return true;
    });
  }

  getProtosNumbersList(exam) {
    const protocols = [];
    exam.sample_ids.forEach(s_id => {
      const protocol = protocolsModule.protocolBySampleId(s_id);
      if (!protocols.includes(protocol)) {
        protocols.push(protocol);
      }
    });
    return protocols;
  }

  getProtLink(prot) {
    const visit = visitsModule.visitById(prot.visit_id);
    this.$router.push({
      name: 'visitmain',
      params: { visit_id: visit.id.toString() },
    });
    return this.$router.currentRoute.fullPath;
  }

  setCurrentDate(dateString: string) {
    this.currDate = moment(dateString, 'DD_MM_YYYY');
    this.currentYear = this.currDate.year();
    this.currentWeek = this.currDate.week();
    this.currentDay = this.currDate.date();
    this.currentMonth = this.currDate.month() + 1;
    /*
    console.log(
      'data:',
      this.currentDay,
      '.',
      this.currentMonth,
      '.',
      this.currentYear,
      ' week:',
      this.currentWeek,
      this.currDate
    );
    */
  }

  get getDestinyName() {
    return (exam: Exam) => {
      const destiny = dictModule.destinyById(exam.destiny_id);
      return destiny.name;
    };
  }
  get getDestinyGroupSymbol() {
    return (exam: Exam) => {
      const destiny = dictModule.destinyById(exam.destiny_id);
      const group: DestinyGroup = dictModule.destinyGroups.find(d => d.id === destiny.group_id);
      //console.log('dest', exam.id, exam.destiny_id, destiny, group);
      return group.symbol;
    };
  }

  get getMainTitle() {
    return (exam: Exam) => {
      const groupSymbol = this.getDestinyGroupSymbol(exam);
      return groupSymbol === 'C' || groupSymbol === 'SW' ? '' : '(' + exam.age + ')';
    };
  }

  get getStageName() {
    return (exam: Exam) => {
      return examsModule.examStageById(exam.stage_id);
    };
  }
  get getStages() {
    return (exam: Exam) => {
      return examsModule.examStagesByExamId(exam.id);
    };
  }

  getCurrentDate() {
    return moment({ year: this.currentYear, month: this.currentMonth, day: this.currentDay });
  }

  get currentMonthTitle() {
    return consts.Months[this.currentMonth - 1];
  }

  get currentDayTitle() {
    return moment(this.currentDay + '.' + this.currentMonth + '.' + this.currentYear, consts.DATE_FORMAT).format(
      consts.DATE_FORMAT
    );
  }
  goMonth() {
    this.$router.push({ name: 'calendarexamsmonth', params: { day: this.currDate.format(consts.DATE_FORMAT) } });
  }

  async mounted() {
    //console.log('user=', userModule.user);
    this.user_id = userModule.user.id;
    moment.updateLocale('pl', { week: { dow: 1 } });
    const strDate = this.$route.params['day'];
    //console.log('dateString', strDate);
    if (strDate !== undefined) {
      this.setCurrentDate(strDate);
    } else {
      this.setCurrentDate('01.01.2020');
    }
    await this.fillExams();
    //await this.fillStages();

    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Kalendarz badań - miesiąc',
          link: 'ce/cem',
        },
        {
          title: 'Badania na dzień ' + this.currDate.format(consts.DATE_FORMAT),
          link: '',
        },
      ],
    });
    this.dataReady = true;
  }

  created() {
    const efo = examsModule.examsFilterOptions;
    if (efo.statusSelected === undefined) {
      this.efo.labCoordOptionSel = EXAMS_ALL_LABORANTS;
      this.efo.asignedOrNotSel = UNASIGNED_EXAMS;
      this.efo.statusSelected = TO_EXAM;
    } else {
      this.efo.labCoordOptionSel = efo.labCoordOptionSel;
      this.efo.asignedOrNotSel = efo.asignedOrNotSel;
      this.efo.statusSelected = efo.statusSelected;
    }
  }
}
