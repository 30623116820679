







































































import { Component, Vue } from 'vue-property-decorator';

import usersModule from '@/store/modules/users';
import dictModule from '@/store/modules/dict';
import * as consts from '@/consts';
import { User } from '@/types';
import * as helpers from '@/helpers';

import validator from 'validator';

const FILTER_TYPE = 0;
const ADD_TYPE = 1;
const EDIT_TYPE = 2;

@Component
export default class ModalExternalUserFilter extends Vue {
  type: number;
  filters: { field: string; value: any }[] = [];

  user: User = null;
  userActive = '';
  userFirstname = '';
  userLastname = '';
  userEmail = '';
  userModule = '';
  userPosition = '';

  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  btnSave = 0;

  clientSelected = null;

  get getClients() {
    const clients = dictModule.clients;
    clients.sort((a, b) => {
      return parseInt(a.code) - parseInt(b.code);
    });
    clients.forEach(c => {
      c['code_name'] = c.code + ' - ' + c.name;
    });
    return clients;
  }

  showModal(type: string, user: User = null) {
    if (type === 'filter') {
      this.type = FILTER_TYPE;
    } else if (type === 'add') {
      this.type = ADD_TYPE;
      this.user = new User();
    } else if (type === 'edit') {
      this.type = EDIT_TYPE;
      this.user = user;
      this.userFirstname = user.firstname;
      this.userLastname = user.lastname;
      this.userEmail = user.email;
      if (user.client_id !== null) {
        this.clientSelected = this.getClients.find(c => c.id === user.client_id);
      }
    }

    this.show = true;
  }
  addFilter(field: string, value: any) {
    if (value !== '') {
      this.filters.push({ field: field, value: value });
    }
  }
  async saveModal() {
    this.save = true;

    if (this.type === FILTER_TYPE) {
      this.addFilter('is_active', this.userActive);
      this.addFilter('firstname', this.userFirstname);
      this.addFilter('lastname', this.userLastname);
      this.addFilter('email', this.userEmail);
      this.addFilter('module', this.userModule);
      this.addFilter('position', this.userPosition);
    } else {
      if (!validator.isEmail(this.userEmail)) {
        helpers.error('Podaj prawidłowy adres email !');
        return;
      } else if (this.userFirstname === '' || this.userLastname === '' || this.userEmail === '') {
        helpers.error('Należy podać wszytkie dane');
        return;
      }
      if (this.type === ADD_TYPE) {
        const userUnique =
          usersModule.allUsers.filter(
            el =>
              (el.firstname === this.userFirstname && el.lastname === this.userLastname) || el.email === this.userEmail
          ).length === 0;
        if (userUnique === false) {
          helpers.error('Użytkownik o podanych danych już istnieje');
          return;
        }
      }

      this.user['firstname'] = this.userFirstname;
      this.user['lastname'] = this.userLastname;
      this.user['first_name'] = this.userFirstname;
      this.user['last_name'] = this.userLastname;
      this.user['email'] = this.userEmail;
      if (this.clientSelected !== null) {
        this.user['client_id'] = this.clientSelected.id;
      }
      if (this.type === ADD_TYPE) {
        this.user.module = 2;
        this.user.position = 'Użytkownik zewnętrzny';
        const response = await usersModule.addUser(this.user);
        if (response.data.success === false) {
          helpers.error('Wystąpił błąd w trakcie komunikacji z serwerem');
          return;
        }
        helpers.info('Użytkownik utworzony');
        this.clear();
      } else if (this.type === EDIT_TYPE) {
        const response = await usersModule.updateUser(this.user);
        if (response.data.success === false) {
          helpers.error('Wystąpił błąd w trakcie komunikacji z serwerem');
          return;
        }
        helpers.info('Dane użytkownika zapisane');
        this.clear();
      }
    }
    this.$emit(consts.ModalEvents.OK_EVENT, this.filters);
    this.show = false;
  }
  clear() {
    this.userActive = '';
    this.userFirstname = '';
    this.userLastname = '';
    this.userEmail = '';
    this.userModule = '';
    this.userPosition = '';
    this.clientSelected = null;
    this.filters = [];
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.clear();
    this.show = false;
  }

  title(): string {
    switch (this.type) {
      case FILTER_TYPE:
        return 'Użytkownicy - filtrowanie';
      case ADD_TYPE:
        return 'Dodaj użytkownika zewnętrznego';
      case EDIT_TYPE:
        return 'Edytuj użytkownika zewnętrznego';
    }
  }
  isFilter(): boolean {
    // console.log('isFilter::this.type', this.type);
    return this.type === FILTER_TYPE;
  }
  btnOkText(): string {
    switch (this.type) {
      case FILTER_TYPE:
        return 'Filtruj';
      case ADD_TYPE:
        return 'Dodaj';
      case EDIT_TYPE:
        return 'Zapisz';
    }
  }
}
