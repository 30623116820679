














































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { create, all } from 'mathjs';
const math = create(all);

import samplesModule from '@/store/modules/samples';
import protocolsModule from '@/store/modules/protocols';
``;
import examsModule from '@/store/modules/exams';
import examModule from '@/store/modules/exam';
import visitsModule from '@/store/modules/visits';
import dictModule from '@/store/modules/dict';

import { Sample, CollectAndExamProtocol, Client, Exam, ExamStageParam } from '@/types';
import { ExamStatus, DATE_FORMAT, sampleToolParams } from '@/consts';

const UNSELECTED = -1;
enum TABS {
  CHOOSE,
  CONFIG,
  PRINT,
}
type multiselectOption = { id: number; name: string };

@Component
export default class ModalResultsDoc extends Vue {
  DATE_FORMAT = DATE_FORMAT;
  TABS = TABS;
  CTB_WORKER_GET_PERSON = 1;
  CLIENT_GET_PERSON = 2;
  GET_PERSON_K = 1;

  show = false;
  activeTab: TABS = TABS.CHOOSE;
  showSamples: boolean[] = [];
  selectedProtocol: number = UNSELECTED;
  selectedSamples: Sample[] = [];
  pibProtocols: CollectAndExamProtocol[] = [];
  pibProtocolsTemp: CollectAndExamProtocol[] = [];
  pickedFromDate = '';
  pickedToDate = '';
  headerDescription = '';
  resultsDescription = '';
  exam: Exam = new Exam();

  filters = this.getEmptyFilters;
  filter2Val = '';

  showModal() {
    this.show = true;
  }
  hideModal() {
    this.show = false;
  }
  async saveModal() {
    this.show = false;
  }

  destinySelected(event: multiselectOption) {
    this.filters.destiny = event;
    this.filterProtocols();
  }
  clientSelected(event: Client) {
    this.filters.client = event;
    this.filterProtocols();
  }

  filterProtocols() {
    this.pibProtocolsTemp = this.getPibProtocols();
    for (const filter in this.filters) {
      const filterValue = this.filters[filter];
      if (filter === 'sampleNumber') {
        this.filterBySamples(sample => sample.number.includes(filterValue));
      } else if (filter === 'destiny' && filterValue.id !== UNSELECTED) {
        this.filterBySamples(sample => sample.destiny_id === filterValue.id);
      } else if (filter === 'date') {
        const from = filterValue.from === '' ? moment('1.01.1970', DATE_FORMAT) : moment(filterValue.from, DATE_FORMAT);
        const to = filterValue.to === '' ? moment('1.01.2970', DATE_FORMAT) : moment(filterValue.from, DATE_FORMAT);

        this.pibProtocolsTemp = this.pibProtocolsTemp.filter(pibProtocol =>
          moment(this.getVisitDate(pibProtocol.id), DATE_FORMAT).isBetween(from, to, undefined, '[]')
        );
      } else if (filter === 'client' && filterValue.id !== UNSELECTED) {
        this.pibProtocolsTemp = this.pibProtocolsTemp.filter(
          pibProtocol => dictModule.clientByCode(pibProtocol.client_code)?.id === filterValue.id
        );
      }
    }
    this.pibProtocolsTemp = this.pibProtocolsTemp.sort((a, b) =>
      moment(this.getVisitDate(a.id), DATE_FORMAT).isBefore(moment(this.getVisitDate(b.id), DATE_FORMAT)) ? -1 : 1
    );
    this.pibProtocols = this.pibProtocolsTemp;
  }
  filterBySamples(cb: (sample: Sample) => boolean) {
    this.pibProtocolsTemp = this.pibProtocolsTemp.filter(pibProtocol =>
      this.getSamplesByPibId(pibProtocol.id).some(cb)
    );
  }

  toggleHideSamples(pibId: number) {
    this.$set(this.showSamples, pibId, !this.showSamples[pibId]);
    this.selectedProtocol = pibId;
  }
  isDisabled(sampleId: number): boolean {
    return this.filters.destiny.id !== UNSELECTED && this.filters.destiny.id !== sampleId;
  }
  selectSample(sample: Sample) {
    if (sample['selected'] === true) {
      this.selectedSamples = this.selectedSamples.filter(s => s.id !== sample.id);
      if (this.selectedSamples.length === 0) {
        this.filters.destiny.id = UNSELECTED;
      }
    } else {
      this.filters.destiny.id = sample.destiny_id;
      this.selectedSamples.push(sample);
    }
  }

  onFromDateInput(date: string) {
    this.filters.date.from = moment(date).format(DATE_FORMAT);
    this.filterProtocols();
  }
  onToDateInput(date: string) {
    this.filters.date.to = moment(date).format(DATE_FORMAT);
    this.filterProtocols();
  }
  clearFilters() {
    console.log(this.selectedSamples);
    this.filters = this.getEmptyFilters;
    this.pickedFromDate = '';
    this.pickedToDate = '';
    this.filterProtocols();
  }

  stringOrDash(val: string): string {
    return val === '' ? '-' : val;
  }

  calculateToolParams() {
    interface ExtSample extends Sample {
      sampleToolParams: { symbol: string; value: string; prec: number }[];
    }
    (this.selectedSamples as ExtSample[]).forEach(sample => {
      sample.sampleToolParams = JSON.parse(JSON.stringify(sampleToolParams));
      console.log('------------------- Sample ', sample.number, sample);
      sample.sampleToolParams.forEach(stp => {
        console.log('-------- stp:', stp.symbol);
        const thisSampleParams = examsModule.examSampleStageParams.filter(essp => essp.sample_id === sample.id);
        if (!this.selectedTool) {
          return;
        }
        const thisToolsParams = this.selectedTool.params;
        if (thisToolsParams === undefined) {
          return;
        }
        const toolParam = thisToolsParams.find(p => p.symbol === stp.symbol);

        console.log('calculateToolParam', thisSampleParams, thisToolsParams);
        const symbolValues = {};
        thisSampleParams.forEach(sap => {
          console.log('gathering sample params' /* , sap.symbol */, sap.value);
          const stageParam = this.examStageParams.find(sp => sp.id === sap.param_id);
          const newVal = sap.value.toString().replace(',', '.');
          symbolValues['$' + stageParam.symbol] = newVal;
          console.log('Added symbol', stageParam.symbol, sap.value);
        });

        sample.sampleToolParams.forEach(p => {
          console.log('gathering sample uncert params', p.symbol, p.value);
          const newVal = p.value.toString().replace(',', '.');
          symbolValues['$' + p.symbol] = newVal;
          console.log('Added uncert symbol', p.symbol, p.value);
        });

        if (toolParam) {
          console.log('toolParam value evaluating', toolParam.formula, symbolValues);
          let val = math.evaluate(toolParam.formula, symbolValues);
          if (toolParam.symbol !== 'Uw' && isNaN(val)) {
            return;
          }
          console.log('Val=', val);
          if (toolParam.symbol === 'boF') {
            val = math.round(val);
            console.log('BoF after rounding', val);
          }
          const p = sample.sampleToolParams.find(sp => sp.symbol === toolParam.symbol);
          stp.value = val.toString().replace('.', ','); //math.round(val, 1).toString().replace('.', ',');

          console.log('param value AFTER=', stp.symbol, stp.value);
        }
      });
    });
  }

  get examStageParams(): ExamStageParam[] {
    const examStageParams: ExamStageParam[] = [];
    examsModule.examStages.forEach(es => {
      if (es.exam_id === this.exam.id) {
        // this.examStages.push(examsModule.examStageCopyById(es.id));
        // console.log('Stage ', es.name);
        examsModule.examStageParams.forEach(esp => {
          // console.log('Param',esp.id,':',esp.name,' stage ',es.id,':',es.name,'ESP:',esp,'ES',es,esp.exam_stage_id,'?',es.id          );
          if (esp.exam_stage_id === es.id) {
            // console.log('OK', esp.id);
            examStageParams.push(examsModule.examStageParamCopyById(esp.id));
            // examsModule.examSampleStageParams.forEach(essp => {
            //   if (essp.param_id === esp.id) {
            //     this.examSampleStageParams.push(examsModule.examSampleStageParamCopyById(essp.id));
            //   }
            // });
          }
        });
      }
    });
    return examStageParams;
  }

  get destiniesOptions(): multiselectOption[] {
    const destiniesOptions: multiselectOption[] = [{ id: UNSELECTED, name: '-- brak wyboru --' }];
    dictModule.destinies.map(destiny => destiniesOptions.push({ id: destiny.id, name: destiny.name }));
    return destiniesOptions;
  }
  get clientsOptions(): multiselectOption[] {
    const clientsOptions: multiselectOption[] = [{ id: UNSELECTED, name: '-- brak wyboru --' }];
    dictModule.clients.map(client =>
      clientsOptions.push({ id: client.id, name: `(${client.code}) ${client.short_name}` })
    );
    return clientsOptions;
  }

  getPibProtocols(): CollectAndExamProtocol[] {
    return protocolsModule.pibProtocols;
  }
  getSamplesByPibId(pibId: number): Sample[] {
    let samples = samplesModule.samplesByPibId(pibId);
    samples = samples.filter(sample => sample.number.includes(this.filters.sampleNumber));
    return samples;
  }
  getSampleStatus(sample: Sample): { badge: string; name: string } {
    if (sample.exam_id === null) {
      return {
        badge: '',
        name: 'Nie rozpoczęte',
      };
    }
    const exam = examsModule.examById(sample.exam_id);
    const examStatus = ExamStatus[exam.status];
    if (examStatus === undefined) {
      return {
        badge: '',
        name: 'Nie rozpoczęte',
      };
    }
    return examStatus;
  }
  getVisitDate(pibId: number): string {
    const dateISO = visitsModule.allVisits.find(visit => visit.collect_and_exam_protocol_id === pibId).date;
    return moment(dateISO).format(DATE_FORMAT);
  }
  getClientFullName(clientCode: string): string {
    const client = dictModule.clientByCode(clientCode);
    if (client === undefined) {
      return 'Brak klienta w bazie';
    } else {
      return `(${client.code}) ${client.short_name}`;
    }
  }
  getIcon(pibId: number): string {
    return this.showSamples[pibId] === true ? 'chevron-up' : 'chevron-down';
  }
  get getEmptyFilters() {
    return {
      sampleNumber: '',
      date: {
        from: '',
        to: '',
      },
      destiny: this.destiniesOptions[0],
      client: this.clientsOptions[0],
    };
  }
  getDestinyName(destinyId: number): string {
    return dictModule.destinyById(destinyId).name;
  }

  departments = [
    { id: 1, name: 'ODDZIAŁ RZESZÓW: ul. Przemysłowa 23, 35-105 Rzeszów, M1' },
    { id: 2, name: 'ODDZIAŁ RZESZÓW: ul. Przemysłowa 23, 35-105 Rzeszów, M6' },
    { id: 3, name: 'ODDZIAŁ X: Milówka' },
    { id: 4, name: 'ODDZIAŁ WARSZAWA: ul. Kaczorowa 35, 03-042 Warszawa' },
  ];
  selectedDepartment = this.departments[0];
  get selDepartment() {
    return this.selectedDepartment;
  }
  selectDepartment(e: any) {
    this.selectedDepartment = e;
  }
  getPeople = [
    {
      id: this.CTB_WORKER_GET_PERSON,
      name:
        'Próbki do badań zostały pobrane przez pracownika CTB wg PN-EN 12350-1:2019-07 i zaformowane wg PN-EN 12390-2:2019-07.',
    },
    { id: this.CLIENT_GET_PERSON, name: 'Próbki do badań zostały pobrane przez Zleceniodawcę.' },
  ];

  selectedGetPerson = this.getPeople[0];
  get selGetPerson() {
    return this.selectedGetPerson;
  }
  selectGetPerson(e: any) {
    this.selectedGetPerson = e;
  }

  rules = [
    {
      id: 1,
      name:
        'zasada A (ZA) z rozszerzeniem przedziału akceptowalności o niepewność pomiaru zgodnie z ZPD1 Wydanie 1 z dnia 05.11.2019 r.',
    },
    { id: 2, name: 'zasada prostej akceptacji zgodnie z ILAC-G8:09/2019 (ZB wg ZPD1 Wydanie 1 z dnia 05.11.2019 r.)' },
  ];
  selectedRule = this.rules[0];
  get selRule() {
    return this.selectedRule;
  }
  selectRule(e: any) {
    this.selectedRule = e;
  }
  get tools() {
    let toolArray = [];
    const exams: Exam[] = [],
      examsIds: number[] = [];

    for (const sample of this.selectedSamples) {
      const exam = examsModule.allExams.find(exam => exam.sample_ids.includes(sample.id));
      if (examsIds.includes(exam.id) === false) {
        exams.push(exam);
        examsIds.push(exam.id);
      }
    }

    //console.log('gathering tools...');
    if (this.exam) {
      const stages = examModule.examStages.filter(s => s.exam_id === this.exam.id);
      //console.log('Stages', stages);
      stages.forEach(stage => {
        //console.log('stage', stage.name, stage.tools);
        toolArray = [...toolArray, ...stage.tools];
      });
    }

    return toolArray;
  }
  selectedTool = null;
  get selTool() {
    return this.selectedTool;
  }
  selectTool(e: any) {
    console.log('Selected', e);
    this.selectedTool = e;
    this.calculateToolParams();
  }
  get getToolParam() {
    return (paramSymbol: string) => {
      //console.log('getToolParam', paramSymbol, this.selectedTool);
      if (this.selectedTool && this.selectedTool.params) {
        //console.log('Tool params', this.selectedTool.params);
        return this.selectedTool.params.find(p => p.symbol === paramSymbol);
      } else {
        return '';
      }
    };
  }
  get getParamValue() {
    return (paramSymbol: string, sample: Sample): string => {
      const param = this.examStageParams.find(p => p.symbol === paramSymbol);
      if (param !== undefined) {
        return examsModule.examSampleStageParams.find(sp => sp.param_id === param.id && sp.sample_id === sample.id)
          .value;
      } else {
        if (sample['sampleToolParams']) {
          const p = sample['sampleToolParams'].find(p => p.symbol === paramSymbol);
          return math.round(p.value.replace(',', '.'), p.prec).toString().replace('.', ',');
        }
      }
    };
  }

  created() {
    this.pibProtocols = this.getPibProtocols();
    this.filterProtocols();
    // console.log('examStages', examsModule.examStages);
    // console.log('examStageParams', examsModule.examStageParams);
    // console.log('examSampleStageParams', examsModule.examSampleStageParams);
    // examsModule.examSampleStageParams.forEach(essp => {
    //   if (essp.param_id === esp.id) {
    //     this.examSampleStageParams.push(examsModule.examSampleStageParamCopyById(essp.id));
    //   }
    // });
  }
}
