










































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import orderModule from '@/store/modules/order';
import dictModule from '@/store/modules/dict';
import { Collect, Destiny, DestinyGroup, Mixer, Sample } from '@/types';
import { DestinyGroupCodes } from '@/consts';

enum ItemType {
  Mixer = 1,
  Collect = 2,
  Sample = 3,
}

enum CollectTestType {
  AIR = 1,
  CONSISTENCY = 2,
  NONE = 3,
}
enum CollectTestTypeName {
  AIR = 'Powietrze',
  CONSISTENCY = 'Konsystencja',
  NONE = 'Brak badań',
}

@Component
export default class OrderConcrete extends Vue {
  @Prop({ type: String, required: true }) readonly mode;

  mixers: Mixer[] = [];

  selected: { obj: object; index: number; type: ItemType } = {
    obj: null,
    index: 0,
    type: ItemType.Mixer,
  };

  collectExams = [
    {
      name: CollectTestTypeName.AIR,
      value: CollectTestType.AIR,
    },
    {
      name: CollectTestTypeName.CONSISTENCY,
      value: CollectTestType.CONSISTENCY,
    },
    {
      name: CollectTestTypeName.NONE,
      value: CollectTestType.NONE,
    },
  ];

  btnAddMixer = true;
  btnAddCollect = false;
  btnAddSample = false;

  selectedMixerIndex = '';
  selectedCollectIndex = '';
  selectedSampleIndex = '';

  minimize = []; // zwiniete

  selectedDestiny = [];
  selectedCollect: { name: string; value: number } = {
    name: CollectTestTypeName.AIR,
    value: CollectTestType.AIR,
  };

  get visit() {
    return orderModule.order.visit;
  }
  fillMixers() {
    orderModule.setMixers(this.mixers);
  }
  collectSelected(e) {
    this.selectedCollect = e;
    this.$forceUpdate();
  }

  btnCollectClick(e) {
    const exam = this.selectedCollect;
    const indexes = this.selectedCollectIndex.split('-');
    const selCollect = this.visit.collect_and_exam_protocol.mixers[indexes[0]].collects[indexes[1]];
    console.log('btnCollect', exam, indexes, selCollect);
    if (exam.value === CollectTestType.AIR) {
      selCollect.air_contents = true;
    }
    if (exam.value === CollectTestType.CONSISTENCY) {
      selCollect.consistency_exam = true;
    }
    if (exam.value === CollectTestType.NONE) {
      selCollect.air_contents = false;
      selCollect.consistency_exam = false;
    }
  }

  isMinimized(index, indexc) {
    if (indexc < 0) {
      return this.minimize.includes(index);
    } else {
      return this.minimize.includes(index + '-' + indexc);
    }
  }

  getIcon(index, indexc) {
    const src = '@/assets/' + (this.isMinimized(index, indexc) ? 'plus.png' : 'minus.png');
    return src;
  }

  minimise(index, indexc) {
    if (indexc >= 0) {
      if (this.minimize.includes(index + '-' + indexc)) {
        this.minimize = this.minimize.filter(o => o !== index + '-' + indexc);
      } else {
        this.minimize.push(index + '-' + indexc);
      }
    } else {
      if (this.minimize.includes(index)) {
        this.minimize = this.minimize.filter(o => o !== index);
      } else {
        this.minimize.push(index);
      }
    }
  }

  getGroupOptions(group: DestinyGroup): Destiny[] {
    const filtered = dictModule.destinies.filter(d => d.group_id === group.id);
    return filtered;
  }
  destinySelected(e: any, index: number) {
    this.selectedDestiny[index] = e;
    this.$forceUpdate();
  }

  getDestinyName(destinyId: number) {
    const dest = dictModule.destinies.find(d => d.id === destinyId);
    const destiny = Object.assign(new Destiny(), dest);
    if (destiny !== null) {
      return destiny.name;
    }
  }

  getExams(collect: Collect) {
    if (collect.air_contents && collect.consistency_exam) {
      return 'P, K';
    }
    if (collect.air_contents && !collect.consistency_exam) {
      return 'P';
    }
    if (!collect.air_contents && collect.consistency_exam) {
      return 'K';
    }
    if (!collect.air_contents && !collect.consistency_exam) {
      return '';
    }
  }

  btnDestinyClick(index: number) {
    const destiny = this.selectedDestiny[index];
    const destinyGroup = dictModule.destinyGroupById(destiny.group_id);
    const indexes = this.selectedSampleIndex.split('-');
    const selSample = this.visit.collect_and_exam_protocol.mixers[indexes[0]].collects[indexes[1]].samples[indexes[2]];

    selSample.destiny_id = destiny.id;

    if (destinyGroup.symbol === DestinyGroupCodes.COMPRESSIVE_STREGTH) {
      selSample.age = parseInt(destiny.name);
    } else if (destinyGroup.symbol === DestinyGroupCodes.PROBE) {
      selSample.age = 1;
    } else if (destinyGroup.symbol === DestinyGroupCodes.MIXTURE) {
      selSample.age = 0;
    }

    this.fillMixers();
  }

  getMixerClass(index: number) {
    return this.selectedMixerIndex === index.toString() ? 'item-sel' : '';
  }
  getCollectClass(index: number, indexc: number) {
    const no = index + '-' + indexc;
    return this.selectedCollectIndex === no ? 'item-sel' : '';
  }
  getSampleClass(index: number, indexc: number, indexs: number) {
    const no: string = index + '-' + indexc + '-' + indexs;
    return this.selectedSampleIndex === no ? 'item-sel' : '';
  }
  addMixer() {
    const newMixer = new Mixer();
    let lastIndex = Math.max(...this.mixers.map(mixer => mixer.index));
    if (this.mixers.length === 0) lastIndex = 0;
    newMixer.index = lastIndex + 1;
    this.mixers.push(newMixer);
    this.fillMixers();
  }
  addCollect() {
    if (this.selected.type === ItemType.Mixer) {
      const collect = new Collect();
      collect.air_contents = false;
      (this.selected.obj as Mixer).collects.push(collect);
      this.fillMixers();
    }
  }
  addSample() {
    if (this.selected !== null) {
      if (this.selected.type === ItemType.Collect) {
        (this.selected.obj as Collect).samples.push(new Sample());

        this.fillMixers();
      }
    }
  }
  remove() {
    let no = '';
    if (this.selected.type === ItemType.Mixer) {
      no = this.selectedMixerIndex;
      this.mixers.splice(parseInt(no), 1);
    } else if (this.selected.type === ItemType.Collect) {
      no = this.selectedCollectIndex;
      const splitted = no.split('-');
      this.visit.collect_and_exam_protocol.mixers[splitted[0]].collects.splice(splitted[1], 1);
    } else {
      no = this.selectedSampleIndex;
      const splitted = no.split('-');
      this.visit.collect_and_exam_protocol.mixers[splitted[0]].collects[splitted[1]].samples.splice(splitted[2], 1);
    }
    this.fillMixers();
  }
  selectMixer(mixer: Mixer, index) {
    this.selected = { obj: mixer, index: index, type: ItemType.Mixer };
    this.btnAddCollect = true;
    this.btnAddSample = false;

    this.selectedSampleIndex = '';
    this.selectedMixerIndex = index.toString();
    this.selectedCollectIndex = '';
  }
  selectCollect(collect: Collect, index, indexc) {
    this.selected = { obj: collect, index: indexc, type: ItemType.Collect };

    this.btnAddCollect = false;
    this.btnAddSample = true;

    this.selectedSampleIndex = '';
    this.selectedMixerIndex = '';
    this.selectedCollectIndex = index + '-' + indexc;
  }
  selectSample(sample: Sample, index, indexc, indexs) {
    this.selected = { obj: sample, index: indexs, type: ItemType.Sample };

    this.btnAddCollect = false;
    this.btnAddSample = false;

    this.selectedSampleIndex = index + '-' + indexc + '-' + indexs;
    this.selectedMixerIndex = '';
    this.selectedCollectIndex = '';
  }

  get classGroups() {
    return dictModule.destinyGroups;
  }

  get order() {
    return orderModule.order;
  }

  mounted() {
    console.log('Mounted OrderConcrete MODE', this.mode);
    this.mixers = orderModule.order.visit.collect_and_exam_protocol.mixers;
    //this.fillMixers();
  }
}
