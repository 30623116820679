





































































































































































































































































































































































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import * as consts from '../../../consts';
import VueHtml2pdf from 'vue-html2pdf';
import ExamCardHeader from '../../../components/spec/ExamCardHeader.vue';
import Footer from '../../../components/dummy/Footer.vue';
import usersModule from '../../../store/modules/users';
import {
  User,
  Exam,
  ExamStage,
  DestinyGroup,
  ExamStageParam,
  Sample,
  Collect,
  Mixer,
  ExamSampleStageParam,
} from '../../../types';
import moment from 'moment';
import dictModule from '../../../store/modules/dict';
import collectsModule from '../../../store/modules/collects';
import mixersModule from '../../../store/modules/mixers';
import visitsModule from '../../../store/modules/visits';
import ordersModule from '../../../store/modules/orders';
import offersModule from '../../../store/modules/offer';
import protocolsModule from '../../../store/modules/protocols';
import methodsModule from '../../../store/modules/methods';
import { create, all } from 'mathjs';

const math = create(all);

const enum viewMode {
  FORM,
  PRINT,
}
type Avs = { av: string; avUw: string };

@Component({
  components: { ExamCardHeader, Footer, VueHtml2pdf },
})
export default class ResultsDocCPrint extends Vue {
  @Ref() readonly rdPrint: VueHtml2pdf;
  @Prop({ type: Exam, required: true }) exam: Exam;
  @Prop({ type: String, required: true }) userAuthorised: string;
  @Prop({ type: Array, required: true }) samples: Sample[];
  @Prop({ type: Array, required: true }) examStages: ExamStage[];
  @Prop({ type: Array, required: true }) examStageParams: ExamStageParam[];
  @Prop({ type: Array, required: true }) examSampleStageParams: ExamSampleStageParam[];

  CLIENT_PRODUCER = 1;
  currDate: moment.Moment = moment();
  currStageIndex = 1;

  viewModePrint = viewMode.PRINT;
  viewModeForm = viewMode.FORM;
  mode: viewMode = viewMode.FORM;

  // wytrzymalosc probki fci
  get w() {
    return sample => {
      return this.pad(this.getResult('WYTRZYMALOSC', sample), 1);
    };
  }

  // niepwenosc dla danej probki
  get uw() {
    return sample => {
      return this.pad(sample['uw'].toString().replace('.', ','), 1);
    };
  }

  // minimalna w dla producenta i odbiorcy, zwracamy obiekt aby potem mozna bylo latwo znalezc najmniejsze uw dla najmniejszego w
  get minW() {
    const x = this.samples.map(s => this.w(s));
    let min_c = this.w(this.samples[0]).replace(',', '.');
    console.log('Min_c', min_c);
    let minSample = this.samples[0];
    for (let c = 0; c < this.samples.length; c++) {
      const r = this.w(this.samples[c]).replace(',', '.');
      console.log('R<min_c', +r, ' ? ', +min_c);
      if (+r < +min_c) {
        console.log('Min_c<', +r, +min_c);
        min_c = r;
        minSample = this.samples[c];
      }
    }
    console.log('MinC', minSample, min_c);
    return minSample; //min_c;
  }

  get minFcm() {
    const x = this.samplesAV.map(s => s.av);
    let min_c = x[0].replace(',', '.');
    console.log('AV MinFcm_c', min_c);
    let minSampleAV = this.samplesAV[0];
    for (let c = 0; c < this.samplesAV.length; c++) {
      const r = this.samplesAV[c].av.replace(',', '.');
      console.log('AV R<min_c', +r, ' ? ', +min_c);
      if (+r < +min_c) {
        console.log('AV Min_c<', +r, +min_c);
        min_c = r;
        minSampleAV = this.samplesAV[c];
      }
    }
    console.log('AV MinC', minSampleAV, min_c);
    return minSampleAV; //min_c;
  }

  get examNumber() {
    const examEndDateTime = !this.isNotFinished(this.exam.status)
      ? moment(this.exam.end_date_time, consts.DB_DATE_TIME_FORMAT)
      : moment();
    return this.client.code + this.cplaceSymbol + examEndDateTime.format('/DDMM/YYYY');
  }
  get endDateTime() {
    const examEndDateTime = !this.isNotFinished(this.exam.status)
      ? moment(this.exam.end_date_time, consts.DB_DATE_TIME_FORMAT)
      : moment();
    return examEndDateTime.format(consts.DATE_FORMAT);
  }
  get orderNumber() {
    return (
      this.order.contract_number +
      (this.order.contract_date
        ? '    z dnia ' +
          moment(this.order.contract_date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT) +
          ' r.'
        : '')
    );
  }
  get orderDate() {
    return 'Zlecenie z dnia ' + moment(this.order.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT) + ' r.';
  }
  get departmentText() {
    return dictModule.departmentById(this.exam.results_doc.department_id).name;
  }
  get getTakeDate() {
    return moment(this.visit.date).format(consts.DATE_FORMAT);
  }
  get getPersonTake() {
    const text = this.exam.results_doc.get_person_text;
    const lineMaxLen = 100;
    const wsLookup = 15;
    const regex = new RegExp(
      String.raw`\s*(?:(\S{${lineMaxLen}})|([\s\S]{${lineMaxLen - wsLookup},${lineMaxLen}})(?!\S))`,
      'g'
    );
    const textBr = text.replace(regex, (_, x, y) => (x ? `${x}-\n` : `${y}\n`));
    return textBr.split('\n');
  }
  get visit() {
    return visitsModule.visitById(this.exam.protocol.visit_id);
  }
  get client() {
    return dictModule.clientById(this.exam.client_id);
  }
  get protocols() {
    const protocols = [];
    this.exam.samples.forEach(s => {
      const protocol = protocolsModule.protocolBySample(s);
      if (!protocols.includes(protocol)) {
        protocols.push(protocol);
      }
    });
    return protocols;
  }
  get protocolElementList() {
    return this.protocols.map(p => (p = p.element)).join(', ');
  }
  get getTakeDateList() {
    const takeDates = [];
    this.protocols.forEach(p => {
      const visit = visitsModule.visitById(p.visit_id);
      const date = moment(visit.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
      if (!takeDates.includes(date)) {
        takeDates.push(date);
      }
    });
    return takeDates.join(', ');
  }
  get protocolNumberDateList() {
    const protocolNumberDates = [];
    this.protocols.forEach(p => {
      const visit = visitsModule.visitById(p.visit_id);
      const date = moment(visit.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
      p['date'] = date;
      protocolNumberDates.push(p);
    });
    return protocolNumberDates.map(p => (p = `${p.number} z dnia ${p.date} r.`)).join(', ');
  }
  get cplaceSymbol() {
    return dictModule.cPlaceById(this.visit.cplace_id).symbol !== '-'
      ? dictModule.cPlaceById(this.visit.cplace_id).symbol
      : '';
  }
  get clientAddress() {
    return (
      this.order.address.street_and_house_no + ' ' + this.order.address.postal_code + ' ' + this.order.address.city
    );
  }
  get contract() {
    return offersModule.contractById(this.order.contract_id);
  }
  get order() {
    const o = ordersModule.orderById(this.visit.order_id);
    o.load();
    return o;
  }
  get examEndDateTime() {
    return moment(this.exam.end_date_time, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_TIME_FORMAT);
  }
  get concreteClass() {
    return (groupSymbol: string) => {
      const group = dictModule.groupClassBySymbol(groupSymbol);
      //console.log('concreteClass ', groupSymbol, ' group=', group);
      const classCId = this.exam.protocol.class_ids.find(id => dictModule.concreteClassById(id).group_id === group.id);
      if (classCId) {
        return dictModule.concreteClassById(classCId).name;
      } else {
        return '';
      }
    };
  }
  get concreteClasses() {
    return (groupSymbol: string) => {
      const group = dictModule.groupClassBySymbol(groupSymbol);
      //console.log('concreteClass ', groupSymbol, ' group=', group);
      return this.exam.protocol.class_ids
        .filter(id => dictModule.concreteClassById(id).group_id === group.id)
        .map(id => dictModule.concreteClassById(id).name);
    };
  }
  get getXClasses() {
    const w = this.concreteClass('W');
    const f = this.concreteClass('F');
    const x = this.concreteClasses('X');
    console.log('W,F,X', w, f, x);
    const classes = [];
    if (w) {
      classes.push(w);
    }
    if (f) {
      classes.push(f);
    }
    if (x) {
      classes.push(x);
    }

    let str = '';
    for (let i = 0; i < classes.length; i++) {
      if (i !== 0) {
        str += ',';
      }
      str += classes[i];
    }
    return str;
  }
  get methods() {
    return methodsModule.allMethods.filter(m => m.default_destiny_group_id === this.destinyGroup.id);
  }
  get destinyGroup() {
    //console.log('dest', this.exam.destiny_id, dictModule.destinies);
    const destiny = dictModule.destinyById(this.exam.destiny_id);
    const group: DestinyGroup = dictModule.destinyGroups.find(d => d.id === destiny.group_id);
    return group;
  }
  get getRecipie() {
    return this.exam.protocol.recipie_no;
  }
  get collect() {
    return (sample: Sample): Collect => {
      return collectsModule.collectById(sample.collect_id);
    };
  }
  get isLastStage() {
    return this.stage.index === this.examStages.length;
  }
  isNotFinished(status: number) {
    return status !== consts.ExamStatus.FINISHED.id;
  }
  get mixer() {
    return (sample: Sample): Mixer => {
      return mixersModule.mixerById(this.collect(sample).mixer_id);
    };
  }
  getSampleAge(sample: Sample) {
    const collect = collectsModule.collectById(sample.collect_id);
    const mixer = mixersModule.mixerById(collect.mixer_id);
    const protocol = protocolsModule.protocolById(mixer.protocol_id);
    const visit = visitsModule.visitById(protocol.visit_id);

    let dateFormat = '';
    if (/\d\d\.\d\d\.\d\d\d\d/.test(visit.date) === true) {
      dateFormat = consts.DATE_FORMAT;
    } else {
      dateFormat = consts.DB_DATE_TIME_FORMAT;
    }

    const visitDate = moment(visit.date, dateFormat);
    let end_dt = moment(new Date());
    if (this.exam.end_date_time) {
      end_dt = moment(this.exam.end_date_time, consts.DB_DATE_TIME_FORMAT);
      console.log('Exam AGE end_date_time=', end_dt, this.exam.end_date_time, visit.date);
    } else {
      console.log('Exam AGE curr end_date_time=', end_dt, visit.date);
    }
    const sampleAge = moment.duration(end_dt.diff(visitDate)).asDays();

    return Math.floor(sampleAge);
  }
  get withCompliance() {
    return this.getSampleAge(this.samples[0]) >= consts.MIN_SAMPLE_AGE_FOR_COMPLIANCE;
  }
  get getCPlaceName() {
    return dictModule.cPlaceById(this.visit.cplace_id).name;
  }
  get stage() {
    return this.examStages.find(s => s.id === this.exam.stage_id);
  }
  get getCurrentStage() {
    //console.log('this.currStageIndex', this.currStageIndex, this.examStages.length);
    if (this.currStageIndex <= this.examStages.length) {
      return this.examStages.find(s => s.index === this.currStageIndex);
    } else {
      return null;
    }
  }
  get user() {
    return (id: number): User => {
      return usersModule.userById(id);
    };
  }
  get round10() {
    return (N: number) => {
      console.log('ROUND10 N=', N, math.round(N / 10), math.round(N / 10) * 10);
      return math.round(N / 10) * 10;
    };
  }
  get getResult() {
    return (symbol: string, sample: Sample): string => {
      const param = this.examStageParams.find(p => p.symbol === symbol);
      //console.log('getResults', symbol, param, sample);
      const value = this.examSampleStageParams.find(sp => sp.param_id === param.id && sp.sample_id === sample.id).value;
      const precParam = consts.PRECISIONS.find(p => p.symbol === param.symbol);
      let precision = 1;
      if (precParam !== undefined) {
        precision = precParam.prec;
      }
      return parseFloat(value.replace(',', '.')).toFixed(precision).replace('.', ',');
    };
  }

  get samplesAV(): Avs[] {
    const avs: Avs[] = [];
    if (this.isReceiver === true) {
      for (let i = 0; i < this.samples.length; i += 2) {
        const sample2 = this.samples[i];
        const sample1 = i + 1 >= this.samples.length ? this.samples[i] : this.samples[i + 1];

        const strVal1 = this.getResult('WYTRZYMALOSC', sample1).replace(',', '.');
        const strVal2 = this.getResult('WYTRZYMALOSC', sample2).replace(',', '.');

        let av = (math.number(strVal1) + math.number(strVal2)) / 2;
        av = Math.round(Number(av) * 10) / 10;

        let avUw =
          (math.number(sample1['uw'].toString().replace(',', '.')) +
            math.number(sample2['uw'].toString().replace(',', '.'))) /
          2;
        avUw = Math.round(Number(avUw) * 10) / 10;

        let avString = av.toString().replace('.', ',');
        if (avString.includes(',') === false) {
          avString += ',0';
        }
        avs.push({ av: avString, avUw: avUw.toString().replace('.', ',') });
      }
    } else {
      console.log('samplesAV 2', this.samples.length);
      if (this.samples.length === 1) {
        avs.push({ av: '-', avUw: '-' });
      } else if (this.samples.length === 2) {
        avs.push({ av: '-', avUw: '-' });
        avs.push({ av: '-', avUw: '-' });
      } else {
        for (let i = 2; i < this.samples.length; i++) {
          let av: number | string = 0,
            avUw: number | string = 0;
          for (let j = i - 2; j <= i; j++) {
            av += parseFloat(this.getResult('WYTRZYMALOSC', this.samples[j]).replace(',', '.'));
            avUw += parseFloat(this.samples[j]['uw'].toString().replace(',', '.'));
          }
          av = (Math.round((av / 3) * 10) / 10).toString().replace('.', ',');
          avUw = (Math.round((avUw / 3) * 10) / 10).toString().replace('.', ',');

          avs.push({ av: this.pad(av, 1), avUw: this.pad(avUw, 1) });
        }
      }
    }

    return avs;
  }
  showAvs(avs: Avs) {
    if (avs.av === '') {
      return '';
    } else if (avs.av === '-') {
      return '-';
    } else {
      return `${avs.av} ± ${this.pad(avs.avUw, 1)}`;
    }
  }

  min(a: string[]) {
    return a.slice().sort(function (a, b) {
      return +a - +b;
    })[0];
  }

  sum(a: string[]) {
    return (a.length && parseFloat(a[0]) + this.sum(a.slice(1))) || 0;
  }

  pad(val: string, prec: number) {
    const splitted = val.split(',');
    if (splitted.length > 1) {
      return splitted[0] + ',' + splitted[1].padEnd(prec, '0');
    } else {
      return splitted[0] + ',' + '0'.padEnd(prec, '0');
    }
  }

  get averageAV() {
    const avPL = this.samplesAV.map(sav => sav.av.toString().replace(',', '.'));
    console.log('AverageAV=', avPL);

    let av = this.sum(avPL);
    console.log('AV=', av / avPL.length);
    av = (Math.round((av / avPL.length) * 10) / 10).toString().replace('.', ',');
    av = this.pad(av, 1);
    return av;
  }
  get avUw() {
    const avPL = this.samplesAV.map(sav => sav.avUw.toString().replace(',', '.'));
    console.log('AverageAV UW=', avPL);

    const avUw = this.sum(avPL);
    console.log('AV UW=', avUw / avPL.length);
    return this.pad((Math.round((avUw / avPL.length) * 10) / 10).toString().replace('.', ','), 1);
  }

  get minCAvarageAC() {
    const avPL = this.samplesAV.map(sav => sav.av.toString().replace(',', '.'));
    let min_c = avPL[0];
    console.log('Min_avPL', min_c);
    for (let c = 0; c < avPL.length; c++) {
      const r = avPL[c];
      console.log('R<min_avPL', +r, ' ? ', +min_c);
      if (+r < +min_c) {
        console.log('min_avPL<', +r, +min_c);
        min_c = r;
      }
    }
    console.log('min_avPL', min_c);
    return min_c;
  }

  get minAv() {
    let results: number[] = [];
    /*
    for (const sample of this.samples) {
      results.push(parseFloat(this.getResult('WYTRZYMALOSC', sample).replace(',', '.')));
    }
    */
    console.log('samplesAV', this.samplesAV);
    results = this.samplesAV.map(x => +x.av.toString().replace(',', '.'));
    console.log('results', results);
    const smin = Math.min(...results)
      .toString()
      .replace('.', ',');
    return this.pad(smin, 1);
  }

  // minimalna niepewnosc pomiaru (= uw dla najmniejszego w)
  get minAvUw() {
    if (this.isReceiver) {
      const avPL = this.samplesAV.map(sav => sav.avUw.toString().replace(',', '.'));
      console.log('MinAVUw arr=', avPL, this.min(avPL));
      return this.min(avPL).toString().replace('.', ',');
    } else {
      return this.minW['uw'];
    }
  }

  get fck() {
    const cls = this.concreteClass('C');
    console.log('FCK:', cls, cls.split('/')[1]);
    return cls.split('/')[1];
  }

  // pierwsze od gory - czyli dla minimum - "kryterium 2"
  get crit1() {
    return +this.fck - 4;
  }

  // "kryterium 1" - u dolu
  crit2Factor = 1;
  get crit2() {
    return +this.fck + this.crit2Factor;
  }
  get crit2Text() {
    let val = '+2';
    if (this.isReceiver === true) {
      if (this.samples.length === 4 || this.samples.length === 6 || this.samples.length === 8) {
        this.crit2Factor = 1;
        val = '+1';
      } else if (this.samples.length === 10 || this.samples.length === 12) {
        this.crit2Factor = 2;
        val = '+2';
      } else {
        val = '';
        this.crit2Factor = 0;
      }
    } else {
      val = '+4';
      this.crit2Factor = 4;
    }
    return val;
  }

  CRIT_OK = 'spełnione';
  CRIT_NOK = 'niespełnione';

  get verifiedCrit1() {
    const ruleId = this.exam.results_doc['rule_id'];
    let res = this.CRIT_NOK;

    const minAv = +this.w(this.minW).replace(',', '.');
    const minAvUw = +this.minAvUw.replace(',', '.');
    const crit1 = +this.crit1;
    console.log('verifiedCrit1', ruleId, minAv, minAvUw, crit1);

    switch (ruleId) {
      case 1:
        res = minAv + minAvUw >= crit1 ? this.CRIT_OK : this.CRIT_NOK;
        break;
      case 2:
        res = minAv >= crit1 ? this.CRIT_OK : this.CRIT_NOK;
        break;
      default:
        break;
    }
    return res;
  }

  get verifiedCrit2() {
    const ruleId = this.exam.results_doc['rule_id'];
    let res = this.CRIT_NOK;

    const averageAV = +this.averageAV.replace(',', '.');
    const avUw = +this.avUw.replace(',', '.');
    const crit2 = +this.crit2;

    console.log('verifiedCrit2', ruleId, averageAV, avUw, this.crit2);
    switch (ruleId) {
      case 1:
        res = averageAV + avUw >= crit2 ? this.CRIT_OK : this.CRIT_NOK;
        break;
      case 2:
        res = averageAV >= crit2 ? this.CRIT_OK : this.CRIT_NOK;
        break;
      default:
        break;
    }
    return res;
  }

  RISK_TO_5 = 'do 5%';
  RISK_5_TO_50 = 'od 5 do 50%';
  RISK_50 = '50%';
  RISK_50_TO_95 = 'od 50 do 95%';

  get risk5_min() {
    const ruleId = this.exam.results_doc['rule_id'];
    let res = this.CRIT_NOK;

    const minAv = +this.minAv.replace(',', '.');
    const minAvUw = +this.minAvUw.replace(',', '.');
    const crit1 = +this.crit1;

    console.log('risk5_min', ruleId, minAv, minAvUw, crit1);

    switch (ruleId) {
      case 1:
        res =
          minAv - minAvUw > crit1
            ? this.RISK_TO_5
            : minAv > crit1
            ? this.RISK_5_TO_50
            : minAv === crit1
            ? this.RISK_50
            : minAv + minAvUw > crit1
            ? this.RISK_50_TO_95
            : minAv + minAvUw > crit1
            ? this.RISK_50_TO_95
            : this.RISK_TO_5;
        break;
      case 2:
        res =
          minAv - minAvUw > crit1
            ? this.RISK_TO_5
            : minAv > crit1
            ? this.RISK_5_TO_50
            : minAv === crit1
            ? this.RISK_50
            : minAv + minAvUw > crit1
            ? this.RISK_5_TO_50
            : this.RISK_TO_5;
        break;
      default:
        break;
    }
    return res;
  }

  get risk5_fcm() {
    const ruleId = this.exam.results_doc['rule_id'];
    let res = this.CRIT_NOK;

    const av = +this.minAv.replace(',', '.');
    const avUw = +this.minAvUw.replace(',', '.');
    const crit2 = +this.crit2;

    console.log('risk5_fcm', ruleId, av, avUw, crit2);

    switch (ruleId) {
      case 1:
        res =
          av - avUw > crit2
            ? this.RISK_TO_5
            : av > crit2
            ? this.RISK_5_TO_50
            : av === crit2
            ? this.RISK_50
            : av + avUw > crit2
            ? this.RISK_50_TO_95
            : av + avUw > crit2
            ? this.RISK_50_TO_95
            : this.RISK_TO_5;
        break;
      case 2:
        res =
          av - avUw > crit2
            ? this.RISK_TO_5
            : av > crit2
            ? this.RISK_5_TO_50
            : av === crit2
            ? this.RISK_50
            : av + avUw > crit2
            ? this.RISK_5_TO_50
            : this.RISK_TO_5;
        break;
      default:
        break;
    }
    return res;
  }

  get getConcreteClasses() {
    const classes = ['LC', 'F(rozpływ)', 'Cl', 'X', 'SF', 'VF', 'W', 'F'];
    let names = '';
    const SEP = ', ';
    for (const clas of classes) {
      const name = this.concreteClass(clas);
      if (name !== '') {
        names += name + SEP;
      }
    }

    return names === '' ? '' : names.slice(0, -SEP.length);
  }
  get isReceiver() {
    return this.order.client_type !== this.CLIENT_PRODUCER;
  }
  get calcAv() {
    return this.isReceiver === true && this.samplesAV.length > 2;
  }

  getDepartmentText(id: number): string {
    return dictModule.departments.find(d => d.id === id).name;
  }

  makePdf() {
    this.rdPrint.generatePdf();
  }
}
