















































































































































































import { Component, Ref } from 'vue-property-decorator';
import VueHtml2pdf from 'vue-html2pdf';
import { Sample, Method } from '../../../types';
import visitMixin from '../../../helpers/components/visit';
import dictModule from '../../../store/modules/dict';
import dataModule from '../../../store/modules/data';
import usersModule from '../../../store/modules/users';
import { DATE_FORMAT, CollectExamType } from '../../../consts';
import moment from 'moment';
import { EventBus, BusEvents } from '../../../helpers/eventbus';
import ProtocolTable from '../../../views/laborant/visits/VisitProtocolPIBPrintTable.vue';

const SAMPLES_ON_ONE_PAGE = 10;

@Component({
  components: {
    VueHtml2pdf,
    ProtocolTable,
  },
})
export default class VisitProtocolPIBPrint extends visitMixin {
  @Ref() readonly protocolpdf: VueHtml2pdf;
  @Ref() readonly protocolTable: ProtocolTable;
  CollectExamTypeS = CollectExamType.S;
  CollectExamTypeF = CollectExamType.F;
  CollectExamTypeSF = CollectExamType.SF;

  invalidate = 0;
  rows = [];

  get getSampleShortNumber() {
    return (sample: Sample, index: number) => {
      let no = '';
      const user = usersModule.userById(this.visit.laborant_id);
      if (!this.visit.client_data) {
        no = (index + 1).toString().padStart(2, '0') + user.initials;
      } else {
        if (this.collect(sample)) {
          no = this.collect(sample).collect_place + (index + 1) + user.initials;
        } else {
          no = '';
        }
      }
      return no;
    };
  }

  get getSampleDestinies() {
    return (method: Method) => {
      const destinies = [];
      console.log('Method', method.name, method.norm);
      if (method.name.includes('Wykonanie') && method.norm.includes('PN-88')) {
        for (let i = 0; i < this.samples.length; i++) {
          if (this.samples[i]) {
            const group = dictModule.getDestinyGroupByDestinyId(this.samples[i].destiny_id);
            if (group.symbol !== 'C') {
              const destiny = dictModule.destinyById(this.samples[i].destiny_id);
              if (!destinies.includes(destiny.name)) {
                destinies.push(destiny.name);
              }
            }
          }
        }
      }
      return destinies.toString();
    };
  }

  get getSignPerson() {
    console.log('getSignPerson', this.protocol, dataModule.signatures);
    const sigs = dataModule.signatures.filter(s => s.object_id === this.protocol.visit_id);
    const lastSig = sigs[sigs.length - 1];
    console.log('Sigs', sigs, lastSig);
    return lastSig !== undefined ? lastSig.client_name : '-';
  }

  get getExamMaker() {
    const u = usersModule.userById(this.protocol.exam_maker_id);
    return u.firstname + ' ' + u.lastname;
  }

  get isAirContents() {
    for (let i = 0; i < this.samples.length; i++) {
      //console.log('Sample X:', this.samples[i]);
      if (this.samples[i] !== null) {
        if (this.collect(this.samples[i]).air_contents) {
          return true;
        }
      }
    }
    return false;
  }

  get isConsistencyS() {
    for (let i = 0; i < this.samples.length; i++) {
      console.log('Sample X:', this.samples[i]);
      if (this.samples[i] !== null) {
        if (
          this.collect(this.samples[i]).consistency_exam &&
          this.collect(this.samples[i]).exam_type_id === this.CollectExamTypeS.id
        ) {
          return true;
        }
      }
    }
    return false;
  }
  get isConsistencyF() {
    for (let i = 0; i < this.samples.length; i++) {
      if (this.samples[i] !== null) {
        if (
          this.collect(this.samples[i]).consistency_exam &&
          this.collect(this.samples[i]).exam_type_id === this.CollectExamTypeF.id
        ) {
          //console.log('F', this.collect(this.samples[i]), this.CollectExamTypeF.id);
          return true;
        }
      }
    }
    return false;
  }
  get isConsistencySF() {
    for (let i = 0; i < this.samples.length; i++) {
      if (this.samples[i] !== null) {
        if (
          this.collect(this.samples[i]).consistency_exam &&
          this.collect(this.samples[i]).exam_type_id === this.CollectExamTypeSF.id
        ) {
          return true;
        }
      }
    }
    return false;
  }

  get concreteClasses() {
    return (groupSymbol: string) => {
      const group = dictModule.groupClassBySymbol(groupSymbol);
      const classesID = this.protocol.class_ids.filter(id => dictModule.concreteClassById(id).group_id === group.id);
      return classesID.map(id => dictModule.concreteClassById(id).name);
    };
  }

  get visitDateTime() {
    return moment(this.visit.date, DATE_FORMAT).format('DD.MM');
  }

  get collectExam() {
    return (sample: Sample) => {
      const collect = this.collect(sample);
      return collect.exam;
    };
  }

  get collect() {
    return (sample: Sample) => {
      let col = null;
      this.protocol.mixers.forEach(mixer => {
        mixer.collects.forEach(collect => {
          if (collect !== null) {
            if (collect.id === sample.collect_id) {
              col = collect;
            }
          }
        });
      });
      return col;
    };
  }

  get methods() {
    const methods = [];

    for (let i = 0; i < this.protocol.mixers.length; i++) {
      const mixer = this.protocol.mixers[i];
      for (let c = 0; c < mixer.collects.length; c++) {
        const collect = mixer.collects[c];
        for (let m = 0; m < collect.methods.length; m++) {
          const method = collect.methods[m];
          if (methods.find(m => m.id === method.id) === undefined) {
            methods.push(method);
          }
        }
      }
    }

    return methods;
  }

  get mixers() {
    return this.protocol.mixers;
  }

  get mixer() {
    return (sample: Sample) => {
      if (sample !== null) {
        return this.protocol.mixers.find(m => m.id === this.collect(sample).mixer_id);
      } else {
        return [];
      }
    };
  }
  get destiny() {
    return (id: number) => {
      return dictModule.destinyById(id);
    };
  }

  get additionals() {
    if (this.protocol.additionals1 === '' && this.protocol.additionals2 === '') {
      return '-';
    } else {
      if (this.protocol.additionals1 === '') {
        return this.protocol.additionals2;
      } else if (this.protocol.additionals2 === '') {
        return this.protocol.additionals1;
      } else {
        return this.protocol.additionals1 + '; &nbsp;' + this.protocol.additionals2;
      }
    }
  }
  get admixtures() {
    if (this.protocol.admixtures1 === '' && this.protocol.admixtures2 === '') {
      return '-';
    } else {
      if (this.protocol.admixtures1 === '') {
        return this.protocol.admixtures2;
      } else if (this.protocol.admixtures2 === '') {
        return this.protocol.admixtures1;
      } else {
        return this.protocol.admixtures1 + '; &nbsp;' + this.protocol.admixtures2;
      }
    }
  }

  get samples() {
    let choosenSamples = [];
    this.protocol.mixers.forEach(mixer => {
      mixer.collects.forEach(collect => {
        choosenSamples = [...choosenSamples, ...collect.samples];
      });
    });
    const used = choosenSamples.length;
    for (let i = 0; i < SAMPLES_ON_ONE_PAGE - used; i++) {
      choosenSamples.push(null);
    }
    return choosenSamples;
  }

  get specialProperties(): string {
    const groupSymbols = ['LC', 'F(rozpływ)', 'VF', 'W', 'F', 'Cl', 'X'];
    const classesHtmlString = this.classesHtmlStringFromGroupSymbols(groupSymbols);
    return classesHtmlString;
  }
  get cplaceSymbol() {
    return dictModule.cPlaceById(this.visit.cplace_id).symbol;
  }

  get classesHtmlStringFromGroupSymbols() {
    return (groupSymbols: string[]) => {
      let classesHtmlString = '';

      for (const groupSymbol of groupSymbols) {
        for (const concreteClass of this.concreteClasses(groupSymbol)) {
          if (groupSymbol === 'Cl') classesHtmlString += 'Cl ';
          classesHtmlString += concreteClass + '; &nbsp;';
        }
      }

      const charsToCutOff = '; &nbsp;'.length;
      return classesHtmlString.substr(0, classesHtmlString.length - charsToCutOff).length > 0
        ? classesHtmlString.substr(0, classesHtmlString.length - charsToCutOff)
        : '-';
    };
  }

  get samplesInParts() {
    const res: Sample[][] = [];
    const samples = this.samples
      .filter(s => s !== null)
      .map(s => Object.assign(new Sample(), JSON.parse(JSON.stringify(s))));
    while (samples.length > 0) {
      res.push(samples.splice(0, SAMPLES_ON_ONE_PAGE));
    }
    const lastPart = res[res.length - 1];
    while (lastPart.length < SAMPLES_ON_ONE_PAGE) {
      lastPart.push(null);
    }
    return res;
  }

  addPageBreak(i: number) {
    return this.samplesInParts.length > 1 && this.samplesInParts.length !== i + 1 && i % 2 === 0;
  }
  numberToDisplay(value: any) {
    if (value === '') {
      return value;
    }
    value = value.replace('.', ',');
    return value.includes(',') === true ? value : value + ',0';
  }

  onProgress(event) {
    console.log('progress', event);
  }
  download() {
    if (this.protocolpdf) {
      this.protocolpdf.generatePdf();
    }
  }
  get authorisePersonFullName() {
    if (this.protocol.authorise_person) {
      return this.protocol.authorise_person.fullName;
    } else {
      return '-';
    }
  }
  authorise() {
    console.log('VisitProtocolPiBPrint::Authorise');
    this.invalidate++;
  }
  async beforePdfDownload({ html2pdf, options, pdfContent }) {
    console.log('PDF Options', options);
    await html2pdf()
      .set(options)
      .from(pdfContent)
      .toPdf()
      .get('pdf')
      .then(pdf => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(6);
          pdf.setTextColor(0);
          pdf.text(
            'Strona ' + i + ' z ' + totalPages,
            pdf.internal.pageSize.getWidth() * 0.91,
            pdf.internal.pageSize.getHeight() - 0.3
          );
        }
      })
      .save();
  }
  created() {
    EventBus.$off(BusEvents.PRINT_PDF, this.download);
    EventBus.$on(BusEvents.PRINT_PDF, this.download);
    EventBus.$on(BusEvents.AUTHORISE, this.authorise);
  }
}
