










































import { Component, Vue, Prop } from 'vue-property-decorator';

import usersModule from '@/store/modules/users';

import { ModalEvents } from '@/consts';
import { ModalChoosePersonData } from '@/types';
import * as helpers from '@/helpers';

interface MultiselectOption {
  id: number;
  name: string;
}
const UNSELECTED = { name: '- wybierz osobę -', id: -1 };

@Component
export default class ModalChoosePerson extends Vue {
  @Prop({ type: String, required: false, default: '' }) title: string;
  @Prop({ type: String, required: false, default: '' }) question: string;
  @Prop({ type: String, required: false, default: '' }) questionClass: string;
  @Prop({ type: String, required: false, default: '' }) titleClass: string;
  @Prop({ type: String, required: false, default: 'md' }) size: string;

  private show = false;
  private data?: ModalChoosePersonData = null;

  selectedPerson?: MultiselectOption = UNSELECTED;
  questionValue = '';

  get people() {
    if (this.data === null) return [];

    const users = usersModule.getUsersByRightSymbolInAllRights(this.data.rightToFilter);
    const options: MultiselectOption[] = [];
    for (const user of users) {
      options.push({
        name: user.fullName,
        id: user.id,
      });
    }

    return options;
  }
  selectPerson(option: MultiselectOption) {
    this.selectedPerson = option;
  }

  showModal(data: ModalChoosePersonData) {
    this.data = data;
    this.questionValue = this.question === '' ? this.data.question : this.question;
    this.show = true;
  }
  saveModal() {
    if (this.selectedPerson === UNSELECTED) {
      helpers.error('Musisz wybrać osobę');
      return;
    }
    this.$emit(ModalEvents.OK_EVENT, this.selectedPerson);
    this.show = false;
  }
  hideModal() {
    this.$emit(ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
