






































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, Method, User, MethodGroup } from '../../types';
import usersModule from '../../store/modules/users';
import dictModule from '../../store/modules/dict';
import moment from 'moment';

const NOT_CHOOSEN = 100;

@Component
export default class ModalMethodFilter extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  methodName = '';
  methodNorm = '';
  methodNumber = '';
  methodGroup = this.methodGroups[0];
  methodAcreditation = this.getAcreditations[0];
  methodStatus = this.getStatuses[0];

  enabled = false;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;

  groupItemSelected: MethodGroup = null;

  get getStatuses() {
    return [
      { id: NOT_CHOOSEN, name: '-- brak wyboru --' },
      { id: consts.MethodStatus.NEW.id, name: consts.MethodStatus.NEW.name },
      { id: consts.MethodStatus.WORKING.id, name: consts.MethodStatus.WORKING.name },
      { id: consts.MethodStatus.CANCELED.id, name: consts.MethodStatus.CANCELED.name },
    ];
  }
  get getAcreditations() {
    return [
      { id: NOT_CHOOSEN, name: '-- brak wyboru --' },
      { id: 1, name: 'TAK' },
      { id: 0, name: 'NIE' },
    ];
  }
  get groupSel() {
    return this.groupItemSelected;
  }
  get methodGroups() {
    return [...[{ id: NOT_CHOOSEN, name: '-- brak wyboru --' }], ...dictModule.allMethodGroups];
  }
  groupSelected(event) {
    this.methodGroup = event;
  }

  acreditationSelected(event) {
    this.methodAcreditation = event;
  }

  statusSelected(event) {
    this.methodStatus = event;
  }

  showModal() {
    this.show = true;
  }
  addFilter(filters: { field: string; value: any }[], field: string, value: any) {
    filters.push({ field: field, value: value });
  }
  saveModal() {
    this.save = true;
    const filters = [];
    if (this.methodName !== '') {
      this.addFilter(filters, 'name', this.methodName);
    }
    if (this.methodNumber !== '') {
      this.addFilter(filters, 'number', this.methodNumber);
    }
    if (this.methodNorm !== '') {
      this.addFilter(filters, 'norm', this.methodNorm);
    }
    if (this.methodStatus.id !== NOT_CHOOSEN) {
      this.addFilter(filters, 'status.id', this.methodStatus.id);
    }
    if (this.methodAcreditation.id !== NOT_CHOOSEN) {
      this.addFilter(filters, 'acreditation', this.methodAcreditation.id === 1);
    }
    if (this.methodGroup.id !== NOT_CHOOSEN) {
      this.addFilter(filters, 'group.id', this.methodGroup.id);
    }
    this.$emit(consts.ModalEvents.OK_EVENT, filters);
    this.show = false;
  }
  clear() {
    this.methodName = '';
    this.methodNorm = '';
    this.methodNumber = '';
    this.methodGroup = this.methodGroups[0];
    this.methodAcreditation = this.getAcreditations[0];
    this.methodStatus = this.getStatuses[0];
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
