



















































import { Vue, Component, Emit } from 'vue-property-decorator';
import { Visit } from '@/types';
import visitsModule from '@/store/modules/visits';
import visits from '@/store/modules/visits';
import { info } from '@/helpers';
import { EventBus } from '@/helpers/eventbus';
import TopPanel from '@/components/dummy/TopPanel.vue';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import moment from 'moment';
import { DB_DATE_FORMAT, DB_DATE_TIME_FORMAT, DATE_FORMAT } from '@/consts';
import { getModuleVersion } from '@/store/api';
import moduleManager from '@/store/modules/moduleManager';
import breadcrumb from '@/store/modules/breadcrumb';

@Component({
  components: { Header, TopPanel, Footer },
})
export default class SynchroList extends Vue {
  errorMessage = '';

  visitsToSynchro: Array<Visit> = [];

  editVisit(v: Visit) {
    this.$router.push({
      name: 'visitmain',
      params: { visit_id: v.id.toString(), edit: 'y', isDirectedBySynchro: 'y' },
    });
  }

  showVisit(v: Visit) {
    this.$router.push({
      name: 'visitmain',
      params: { visit_id: v.id.toString(), edit: 'n', isDirectedBySynchro: 'y' },
    });
  }

  async saveModal() {
    this.offlineVisits.forEach(async visit => {
      visit.date = moment(visit.date, DATE_FORMAT).format(DB_DATE_TIME_FORMAT);
      await visits.updateVisitAction(visit);
    });
    visits.clearOfflineVisits();
    this.$router.push({ name: 'calendarvisitsmonth' });
    info('Zsynchronizowano wizyty', 5000);

    EventBus.$emit('ENDED_SYNCHRO', true);
  }

  mounted() {
    const breadcrumbItems = [
      {
        title: 'Synchronizacja',
        link: {
          name: 'Synchro',
        },
      },
    ];
    breadcrumb.setBreadcrumb({
      routePrefix: '',
      items: breadcrumbItems,
    });
  }

  get offlineVisits() {
    const visits = visitsModule.offlineVisits;
    visits.forEach(el => {
      el.load();
    });
    return visits;
  }
}
