








































































































































































































































































































































































































































































































import { Component, Ref } from 'vue-property-decorator';
import {
  Material,
  Client,
  ConcreteClassGroup,
  ConcreteClassSubgroup,
  ConcreteClass,
  BreadcrumbItem,
} from '../../../types';
import VisitProtocolPIBPrint from '../../../views/laborant/visits/VisitProtocolPIBPrint.vue';
import VisitHeader from '../../../views/laborant/visits/VisitHeader.vue';
import Footer from '../../../components/dummy/Footer.vue';
import visitModule from '../../../store/modules/visit';
import dictModule from '../../../store/modules/dict';
import visitsModule from '../../../store/modules/visits';
import usersModule from '../../../store/modules/users';
import protocolsModule from '../../../store/modules/protocols';
import ModalClient from '../../../components/spec/ModalClient.vue';
import {
  ClientType,
  ModalEvents,
  GroupClassSymbols,
  VisitStatus,
  DB_DATE_TIME_FORMAT,
  UserRightSymbols,
  ViewModes,
} from '../../../consts';
import * as helpers from '../../../helpers';
import VisitTree from '../../../views/laborant/visits/VisitTree.vue';
import { EventBus, BusEvents } from '../../../helpers/eventbus';
import breadcrumbModule from '../../../store/modules/breadcrumb';
import visitMixin from '../../../helpers/components/visit';
import moment from 'moment';
import ModalQuestion from '../../../components/dummy/ModalQuestion.vue';
import userModule from '../../../store/modules/user';

@Component({
  components: {
    ModalClient,
    VisitHeader,
    Footer,
    VisitTree,
    VisitProtocolPIBPrint,
    ModalQuestion,
  },
})
export default class VisitProtocolPIB extends visitMixin {
  pdf() {
    console.log('PDF');
    EventBus.$emit(BusEvents.PRINT_PDF);
  }
  MIN_ID_TO_REMOVE = 100000; // tymczasowe ID przed zapisen na serwerze, do offline
  ClientType = ClientType;
  ModalEvents = ModalEvents;
  authkey = 0;

  input = '';
  option = '';
  selectedNotDefault = '';
  viewModePrint = ViewModes.PRINT;
  viewModeForm = ViewModes.FORM;

  @Ref() readonly questionModal: ModalQuestion;
  @Ref() readonly protocolpdf: HTMLElement;
  @Ref() readonly modalClient: ModalClient;

  GroupClassSymbolsSF = GroupClassSymbols.CONSISTENCY_CONE;
  GroupClassSymbolsVF = GroupClassSymbols.VISCOSITY;
  GroupClassSymbolsX = GroupClassSymbols.EXPOSURE;
  GroupClassSymbolsW = GroupClassSymbols.WATER;
  GroupClassSymbolsF = GroupClassSymbols.FROZEN;
  GroupClassSymbolsC = GroupClassSymbols.COMPRESSIVE_STREGTH;
  GroupClassSymbolsVr = GroupClassSymbols.CONSISTENCY_TABLE;
  GroupClassSymbolsS = GroupClassSymbols.CONSISTENCY;

  VisitStatusVerifiedId = VisitStatus.VERIFIED.id;
  allClientsName: string[];
  producerReceiverType: ClientType = 1;
  prodRecvNameValue = '';
  prodRecvCodeValue = 0;
  prodRecvShortNameValue = '';
  revalidate = 0;
  mode: ViewModes = ViewModes.FORM;

  get canBeAuthorised(): boolean {
    return (
      this.mode === this.viewModePrint &&
      userModule.hasRightBySymbolInAllRights(UserRightSymbols.VISIT_AUTHORISE) === true
    );
  }
  get visitTitle() {
    return 'Protokół PiB nr ' + this.protocol.number;
  }
  /***************************** MATERIAL ***************************/
  get materialSel() {
    this.revalidate;
    return dictModule.materialById(this.protocol.material_id);
  }
  get materials() {
    return dictModule.getVisibleMaterials;
  }
  get examMaker() {
    const u = usersModule.userById(this.protocol.exam_maker_id);
    return u.firstname + ' ' + u.lastname;
  }
  getIsAuthorised() {
    return this.protocol.authorise_person_id !== null;
  }
  materialSelected(e) {
    console.log('e=', e);
    this.protocol.material_id = (e as Material).id;
    this.updateProtocolPIB(this.protocol);
    this.revalidate++;
  }
  getNewClients(e: any) {
    console.log(e);
    this.input = e;
    this.newClients++;
    console.log(this.input);
  }

  get protocolElement() {
    console.log('Getting protocolElement value', this.protocol.element);
    return this.protocol.element;
  }
  set protocolElement(value: string) {
    console.log('Setting protocolElement value', value, this.protocol.element);
    this.protocol.element = value;
    visitModule.setProtocolPIB(this.protocol);
    console.log('Value from vuex item:', visitModule.visit.collect_and_exam_protocol);
  }
  newClients = 0;
  get getClientsAndInput() {
    this.newClients;
    console.log('getClients: ', this.allClientsName, this.input);
    return [this.input !== '' ? this.input : [], ...this.allClientsName].filter(
      el => el !== null && el !== this.protocol.producer_receiver
    );
  }
  get producerReceiverSel() {
    this.revalidate;
    console.log('producerReceiverSel', this.protocol.producer_receiver);
    return this.protocol.producer_receiver;
  }
  producentReceiverSelected(e) {
    this.option = e;
    console.log('producentReceiverSelected option:', e);
    this.protocol.producer_receiver = this.option;
    console.log('PROTOCOL: ', this.protocol);
    this.updateProtocolPIB(this.protocol); //to leci do vuexa
    this.revalidate++;
  }
  addProducerReceiver() {
    this.prodRecvNameValue = ''; // TO-DO nie czyscic, jak ktos blednie podal taka sama nazwe to mu to pokazac w modalu
    this.modalClient.showModal();
  }
  removeProducerReceiver() {
    // TO-DO dołozyc sprawdzanie czy usuwany obiekt na pewno jest z id > 100k (nowym)
    console.log('PROD_RE', this.producerReceiverSel);
    if (this.producerReceiverSel) {
      //dictModule.removeClientById(this.producerReceiverSel.id);
      //this.protocol.producer_receiver_id = 0;
    }
  }

  /************************* MODAL ************************/
  modalClientOK(client: Client) {
    //tutaj powinno byc sprawdzanie, czy kodzik jest poprawny
    console.log('client added', client);
    this.protocol.producer_receiver = this.option;
    this.updateProtocolPIB(this.protocol);
    helpers.info('Klient dodany...');
    this.revalidate++;
  }

  /******************** AGE ******************/

  get isDefaultGroup() {
    return (group: ConcreteClassGroup) => {
      return (
        group.symbol === this.GroupClassSymbolsC ||
        group.symbol === this.GroupClassSymbolsW ||
        group.symbol === this.GroupClassSymbolsF
      );
    };
  }

  get defaultSAge() {
    return this.protocol.defaultSAge;
  }
  set defaultSAge(value: number) {
    this.protocol.defaultSAge = value;
    this.updateProtocolPIB(this.protocol);
  }
  get defaultWAge() {
    return this.protocol.defaultWAge;
  }
  set defaultWAge(value: number) {
    this.protocol.defaultWAge = value;
    this.updateProtocolPIB(this.protocol);
  }
  get defaultFAge() {
    return this.protocol.defaultFAge;
  }
  set defaultFAge(value: number) {
    this.protocol.defaultFAge = value;
    this.updateProtocolPIB(this.protocol);
  }

  /**************************** CLASSES ****************************/
  get groups() {
    return dictModule.concreteClassGroups;
  }
  get classSel() {
    return (group: ConcreteClassGroup): ConcreteClass => {
      let choosenConcreteClass = null;
      this.protocol.class_ids.forEach(id => {
        if (choosenConcreteClass === null) {
          const concreteClass = dictModule.concreteClassById(id);
          if (concreteClass.group_id === group.id) {
            choosenConcreteClass = concreteClass;
          }
        }
      });
      return choosenConcreteClass;
    };
  }

  get getSelectedClassesInGroup() {
    return (group: ConcreteClassGroup): ConcreteClass[] => {
      const selectedClasses: ConcreteClass[] = this.protocol.class_ids.reduce(
        (classAcc: ConcreteClass[], currentClassId) => {
          const selectedClass = dictModule.concreteClassById(currentClassId);
          if (selectedClass.group_id === group.id) return [...classAcc, selectedClass];
          return classAcc;
        },
        []
      );
      return selectedClasses;
    };
  }

  get getClasses() {
    return (group: ConcreteClassGroup): ConcreteClass[] => {
      return dictModule.getGroupClassesById(group.id);
    };
  }
  clearClass(group: ConcreteClassGroup) {
    this.protocol.class_ids = this.protocol.class_ids.filter(
      id => dictModule.concreteClassById(id).group_id !== group.id
    );
    this.updateVisit();
  }

  classSelected(selectedClass: ConcreteClass, group: ConcreteClassGroup, removePreviousClasses = false) {
    console.log('event', event, selectedClass.id);
    if (removePreviousClasses) {
      const previousClasses = dictModule.getGroupClassesById(selectedClass.group_id);
      previousClasses.forEach(c => {
        this.protocol.class_ids = this.protocol.class_ids.filter(id => id !== c.id);
      });
    }

    this.protocol.class_ids.push(selectedClass.id);
    this.updateVisit();
  }

  /******************* X ****************/

  updateCheckbox(event: any) {
    console.log('updateCheckbox event=', event);
    this.protocol.is_training = event;
    visitModule.setVisit(this.visit);
    this.revalidate++;
  }

  get groupSubgroups() {
    return (group: ConcreteClassGroup) => {
      //console.log("groupSubgroups", group);
      return dictModule.subgroupsByGroupId(group.id);
    };
  }
  get getSubgroupXClasses() {
    return (group: ConcreteClassGroup, subgroup: ConcreteClassSubgroup) => {
      return dictModule.getSubgroupClassesById(group.id, subgroup.id);
    };
  }

  get xclassSel() {
    return (group: ConcreteClassGroup, subgroup: ConcreteClassSubgroup): ConcreteClass[] => {
      console.log('XClassSel', group.symbol, subgroup.symbol, this.protocol.class_ids);
      this.revalidate;
      const choosenConcreteClass = [];
      this.protocol.class_ids.forEach(id => {
        const classObj = dictModule.concreteClassById(id);
        console.log('xclassSel classObj', classObj.name, group.symbol, subgroup.symbol);
        if (classObj.group_id === group.id && classObj.subgroup_id === subgroup.id) {
          console.log('Adding class ', classObj.name, classObj.id);
          choosenConcreteClass.push(classObj);
        }
      });
      console.log('return class', choosenConcreteClass);
      return choosenConcreteClass;
    };
  }
  get getXClasses() {
    return (group: ConcreteClassGroup, subgroup: ConcreteClassSubgroup): ConcreteClass[] => {
      return dictModule.getSubgroupClassesById(group.id, subgroup.id);
    };
  }
  clearXClass(group: ConcreteClassGroup, subgroup: ConcreteClassSubgroup) {
    this.protocol.class_ids = this.protocol.class_ids.filter(id => {
      const classObj = dictModule.concreteClassById(id);
      return !(classObj.group_id === group.id && classObj.subgroup_id === subgroup.id);
    });
    this.updateVisit();
  }
  classXSelected(event: any, group: ConcreteClassGroup, subgroup: ConcreteClassSubgroup) {
    const newClass = event as ConcreteClass;
    console.log('event', event, newClass.id, group, subgroup);
    const classes = dictModule.getSubgroupClassesById(newClass.group_id, newClass.subgroup_id);
    console.log('Classes', classes);
    /*
    classes.forEach(c => {
      this.protocol.class_ids = this.protocol.class_ids.filter(id => id !== c.id);
    });
    */
    console.log('revalidate', this.revalidate, newClass);
    if (!this.protocol.class_ids.includes(newClass.id)) {
      this.protocol.class_ids.push(newClass.id);
    }
    console.log('prot classes', this.protocol.class_ids);
    this.updateVisit();
    this.revalidate++;
  }

  isClassGroupDisabled(group: ConcreteClassGroup) {
    return (
      !this.enabled || ((group.symbol === 'SF' || group.symbol === 'VF') && this.protocol.self_compacting === false)
    );
  }

  printView() {
    this.mode = ViewModes.PRINT;
  }
  formView() {
    this.mode = ViewModes.FORM;
  }

  authorise() {
    this.questionModal.showModal({
      question: 'Czy na pewno chcesz AUTORYZOWAĆ ten protokół?',
    });
  }
  async questionOK(params: object) {
    helpers.info('Autoryzacja wizyty w toku...');
    this.authkey++;
    this.protocol.authorise_person_id = this.user.id;
    this.protocol.authorise_person = this.user;
    this.protocol.authorise_date_time = moment().format(DB_DATE_TIME_FORMAT);
    this.protocol.cplace_id = this.visit.cplace.id;
    this.updateVisit();
    await protocolsModule.updatePIBProtocol(this.protocol);
    this.getIsAuthorised();
    EventBus.$emit(BusEvents.AUTHORISE);
    this.$nextTick(() => {
      this.formView();
    });
    helpers.info('Wizyta zautoryzowana!');
  }
  /********************  COMMON ********************/
  setBreadcrumb() {
    const breadcrumbItems: BreadcrumbItem[] = [
      {
        title: 'Wizyta ' + this.visit.number,
        link: {
          name: 'visitmain',
          params: {
            visit_id: this.visit.id.toString(),
            pending: true,
          },
        },
      },
      {
        title: this.protocol.getName() + ' ' + this.protocol.number,
        link: '',
      },
    ];
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: breadcrumbItems,
    });
  }

  created() {
    console.log('%c*********************** ProtocolPIB mounting ***********************', 'color:red');
    console.log('PARAMS:', this.$route.params);
    window.addEventListener(
      'click',
      function (e: any) {
        this.checkPanelVisible(e);
      }.bind(this)
    );
    this.allClientsName = protocolsModule.protocolsByClientCode(this.client.code).map(el => el.producer_receiver);
    const visit = visitsModule.visitById(this.protocol.visit_id);
    this.protocol.exam_maker_id = visit.laborant_id;
    this.mode = ViewModes.FORM;
    if (this.$route.params.view_mode === ViewModes.PRINT) this.mode = ViewModes.PRINT;
    this.isTreeVisible = false;
    this.producerReceiverType = this.protocol.producer_receiver_type;
    console.log(this.producerReceiverType);
    this.setBreadcrumb();
    this.revalidate++;
    EventBus.$on(BusEvents.CHANGE_TREE_VISIBLE, this.changeVisible);
    console.log(
      '%c###################  VISIT PROTOCOL id ' + this.protocol.id + ' loaded.. ' + '###################',
      'color:red',
      this.protocol
    );

    if ([undefined, null, ''].includes(this.protocol.granulation)) this.protocol.granulation = '0-16';
  }
}
