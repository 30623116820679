























































































































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import * as consts from '@/consts';
import * as types from '@/types';
import { DashboardItem } from '@/types';
import * as API from '@/store/api';
import FileManager from './FileManager.vue';
import * as fileHelper from '@/store/modules/file';
import userModule from '@/store/modules/user';
import usersModule from '@/store/modules/users';
import dataModule from '@/store/modules/data';
import dashboardItems from '@/store/modules/dashboard_items';
import moment from 'moment';
import * as helpers from '@/helpers';
import { Guid } from 'guid-typescript';

@Component({
  components: {
    FileManager,
  },
})
export default class ModalCert extends Vue {
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'xl' }) size;
  @Prop({ required: false, type: Number }) readonly type: number;

  @Ref() fileManager: FileManager;

  guid = '';
  show = false;
  isNew = false;
  date = '';
  dateText = '';
  currentStatus: consts.CertActionStatus = new consts.CertActionStatus(-1, '', '', '');
  certFieldsArray: string[] = [];

  item: DashboardItem = null;
  document: types.GenericDocument = new types.GenericDocument();
  title = '';
  number = '';

  onMainDateContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.document.date = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = moment(this.document.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    }
  }

  dateTextLeave(event: any) {
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.date = today.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = today.format(consts.DATE_FORMAT);
      helpers.warn('Nie wybrano daty, ustawiono domyślną wartość');
    }
  }

  get getFileType() {
    if (!this.document) {
      return 'unknown';
    }
    if (this.document.guid !== undefined) {
      return 'TMP';
    }
    switch (this.type) {
      case consts.DashboardObjectTypes.CERT:
        return consts.FileGroup.CERT_DOC.symbol;
      default:
        return 'unknown';
    }
  }
  get disableInputs() {
    return (
      this.currentStatus.id === consts.CertActionStatus.INITIAL.id ||
      this.currentStatus.id === consts.CertActionStatus.CANCELLED.id ||
      usersModule
        .getAllUserRightsList(userModule.user)
        .map(r => r.symbol)
        .includes(consts.UserRightSymbols.CERT_DASHBOARD_IN_PROGRESS) === false
    );
  }
  get getDocument() {
    return this.document;
  }

  get isCert() {
    return this.type === consts.DashboardObjectTypes.CERT;
  }
  get isSupervision() {
    return this.type === consts.DashboardObjectTypes.SUPERVISION;
  }
  get isInspection() {
    return this.isCert === true || this.isSupervision === true;
  }

  async newObjectNumber(): Promise<string> {
    const response = await API.obtainCertDocNumber();
    const number = response.data.number;
    return number;
  }

  async saveObjectAsItem() {
    this.item.object_id = this.document.id;
    this.item.object_type = consts.DashboardObjectTypes.CERT;
    this.item.object_type_id = this.document.type;
    this.item.status_id = consts.CertActionStatus.AFTER.id;
    this.item.comment = '';
    this.item.operation_type_id = consts.CertOperationType.ADD.id;
    this.item.person_initiated_id = userModule.wholeUser.id;
    const result = await dashboardItems.addItem(this.item);
    return result;
  }

  async getFiles() {
    const id = this.document.id;
    const type = consts.FileGroup.CERT_DOC.symbol;
    const response = await fileHelper.fetchFiles(id, type);
    const files = response.data.files;
    this.document.files = [];
    files.forEach(element => {
      this.document.files.push(element);
    });
  }
  setNumber() {
    let number: string;

    if (this.isNew === true) {
      number = moment().year() + '-';
    } else {
      number = this.document.number.split('/')[0] + '/';
    }

    const certs = dataModule.documents.filter(d => d.type === consts.DashboardObjectTypes.CERT);

    const numbers = certs
      .map(c => {
        const numberSplitted = c.number.split('-');
        if (numberSplitted[0] === this.getNumberYear) {
          return parseInt(numberSplitted[1].split('/')[0]);
        }
        return undefined;
      })
      .filter(c => c !== undefined);

    let counter: string;
    if (numbers.length === 0) {
      counter = '001';
    } else {
      counter = (Math.max(...numbers) + 1).toString();
      counter = '0'.repeat(Math.max(0, 3 - counter.length)) + counter;
    }

    if (this.isNew === true) {
      number += counter + '/';
    }

    number += this.certFields;

    this.number = ': ' + number;
    this.document.number = number;
  }
  get certFields() {
    return this.certFieldsArray.sort().join('');
  }
  get getNumberYear() {
    return moment().year().toString();
  }

  empty(value?: string | boolean | number): boolean {
    return value === null || value === undefined || value === '';
  }
  validateInspectionData() {
    return (
      this.empty(this.document.additionals1) === false &&
      this.empty(this.document.additionals2) === false &&
      this.empty(this.certFields) === false &&
      (this.empty(this.document.description) === false || this.fileManager.files.length !== 0) &&
      this.empty(this.dateText) === false
    );
  }

  async showModal(dbi?: DashboardItem) {
    this.date = this.dateText = '';
    if (dbi) {
      this.item = dbi;
      this.document = Object.assign(new types.GenericDocument(), dbi.object);
      this.document.load();
      this.currentStatus = this.document.titles.status.byId(dbi.status_id);
    } else {
      this.document = new types.GenericDocument();
      this.item = new DashboardItem();
      this.document.files = [];
      this.document.name = '';
      this.document.guid = this.guid = Guid.raw();
      this.document.type = this.type;
      this.document.load();

      this.document.date = moment()
        .add(userModule.getSettings.timeToSupervision.time, userModule.getSettings.timeToSupervision.unit)
        .format(consts.DB_DATE_TIME_FORMAT);

      this.document.load();
      this.currentStatus = this.document.titles.status.byId(1);
      this.isNew = true;
    }

    const response = await dataModule.fetchdocuments();
    if (response.data.success === false) {
      this.hideModal();
      helpers.error('Błąd w ładowaniu certyfikatów, spróbuj ponownie');
      return;
    }
    if (this.isNew === true) {
      this.title = 'Nowa certyfikacja';
      this.certFieldsArray = [];
      this.setNumber();
    } else {
      this.certFieldsArray = this.document.number.split('/')[1].split('');
      this.setNumber();
      this.title = this.isCert === true ? 'Certyfikacja numer' : 'Nadzór numer';
    }

    this.date = this.document.date;
    this.dateText = moment(this.document.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);

    this.show = true;
  }

  async saveModal() {
    if (this.document.name === '' || this.validateInspectionData() === false) {
      helpers.error('Neleży wypełnić wszystkie wymagane pola!');
      return;
    }

    this.item.object = this.document;
    const documents = dataModule.documents.filter(d => this.isInspection === true && d.name === this.document.name);

    for (const document of documents) {
      document.number = document.number.split('/')[0] + '/' + this.certFields;
      const response = await API.saveCert(document);
      if (response.data.success === false) {
        helpers.error('Wystąpił błąd przy zapisie, spróbuj ponownie');
        return;
      }
    }

    const response = await API.saveCert(this.document);
    if (response.data.success) {
      this.document = response.data.certDocument;
      if (this.isNew) {
        await this.saveObjectAsItem();
      }
      helpers.info('Pomyślnie zapisano.', 1500);
      this.$emit(consts.ModalEvents.OK_EVENT);
    } else {
      helpers.warn('Wystąpił błąd przy zapisie!', 1500);
    }
    this.hideModal();
  }
  hideModal() {
    this.document = new types.GenericDocument();
    this.show = false;
  }
}
