import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
//import * as api from "@/store/api";
import { Exam, User, ExamStage, ExamStageParam, ExamSampleStageParam } from '@/types';
import axios, { AxiosResponse } from 'axios';
import * as consts from '@/consts';
import examsModule from './exams';
import user from './user';
import { authorise_exam, ctbApi } from '../api';

const NEW_ID_START = 100000;

@Module({
  namespaced: true,
  name: 'exam_item',
  store,
  dynamic: true,
})
class ExamModule extends VuexModule {
  exam: Exam = null;
  examStages: ExamStage[] = [];
  examStageParams: ExamStageParam[] = [];
  examSampleStageParams: ExamSampleStageParam[] = [];

  public get examStageById() {
    return (id: number): ExamStage => {
      return this.examStages.find(m => m.id === id);
    };
  }
  public get examStageParamById() {
    return (id: number): ExamStageParam => {
      return this.examStageParams.find(p => p.id === id);
    };
  }
  public get examStageParamCopyById() {
    return (id: number): ExamStageParam => {
      return Object.assign(new ExamStageParam(), JSON.parse(JSON.stringify(this.examStageParamById(id))));
    };
  }
  public get examStageCopyById() {
    return (id: number): ExamStage => {
      return Object.assign(new ExamStage(), JSON.parse(JSON.stringify(this.examStageById(id))));
    };
  }

  public get examSampleStageParamById() {
    return (id: number): ExamSampleStageParam => {
      return this.examSampleStageParams.find(essp => essp.id === id);
    };
  }

  @Mutation
  clear() {
    this.exam = null;
    this.examStages = [];
    this.examStageParams = [];
    this.examSampleStageParams = [];
    console.log('exam module cleared...');
  }

  @Mutation
  createEmptyExam() {
    console.log('ExamModule:: create empty exam');
    this.exam = new Exam();
  }
  @Mutation
  setExam(exam: Exam) {
    this.exam = exam;
  }
  @Mutation
  setExamStages(examStages: ExamStage[]) {
    this.examStages = [];
    examStages.forEach(examStage => {
      this.examStages.push(Object.assign(new ExamStage(), examStage));
    });
  }

  @Action
  async authoriseCurrentExam(): Promise<AxiosResponse> {
    console.log('*** Authorise current exam');
    console.log(this.exam);
    const response = await authorise_exam(this.exam, user.wholeUser);
    return response;
  }

  @Action
  async authoriseExam(exam: Exam): Promise<AxiosResponse> {
    console.log('*** Authorise exam');
    console.log(exam);
    const response = await authorise_exam(exam, user.wholeUser);
    return response;
  }

  @Mutation
  setExamStageParams(examStageParams: ExamStageParam[]) {
    this.examStageParams = [];
    console.log('setExamStageParams', examStageParams);
    examStageParams.forEach(examStageParam => {
      console.log('Add', examStageParam);
      this.examStageParams.push(Object.assign(new ExamStageParam(), examStageParam));
    });
  }
  @Mutation
  setExamSampleStageParams(examSampleStageParams: ExamSampleStageParam[]) {
    this.examSampleStageParams = [];
    //  console.log('setExamSampleStageParams', examSampleStageParams);
    examSampleStageParams.forEach(examSampleStageParam => {
      //console.log('Add', examSampleStageParam);
      this.examSampleStageParams.push(Object.assign(new ExamSampleStageParam(), examSampleStageParam));
    });
  }
}

export default getModule(ExamModule);
