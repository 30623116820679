




























































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, Method, User, MethodGroup } from '../../types';
import usersModule from '../../store/modules/users';
import dictModule from '../../store/modules/dict';
import moment from 'moment';

const NOT_CHOOSEN = 100;

@Component
export default class ClientOffersFilterModal extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  actionNumber = '';
  actionDateCreated = '';
  actionDateFinished = '';
  actionStatus = this.getStatuses[0];

  enabled = false;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;

  groupItemSelected: MethodGroup = null;

  get getStatuses() {
    return [
      { id: NOT_CHOOSEN, name: '-- brak wyboru --' },
      { id: consts.OfferActionStatus.REQUEST.id, name: consts.OfferActionStatus.REQUEST.name },
      { id: consts.OfferActionStatus.OFFER.id, name: consts.OfferActionStatus.OFFER.name },
      { id: consts.OfferActionStatus.TOACCEPT.id, name: consts.OfferActionStatus.TOACCEPT.name },
      { id: consts.OfferActionStatus.CONTRACT.id, name: consts.OfferActionStatus.CONTRACT.name },
      { id: consts.OfferActionStatus.CANCELLED.id, name: consts.OfferActionStatus.CANCELLED.name },
    ];
  }
  get groupSel() {
    return this.groupItemSelected;
  }
  showModal() {
    this.show = true;
  }
  addFilter(filters: { field: string; value: any }[], field: string, value: any) {
    console.log('added filter', field, value);
    filters.push({ field, value });
  }
  saveModal() {
    this.save = true;
    const filters = [];
    if (this.actionNumber !== '') {
      this.addFilter(filters, 'number', this.actionNumber);
    }
    if (this.actionDateCreated !== '') {
      this.addFilter(filters, 'dateCreated', this.actionDateCreated);
    }
    if (this.actionDateFinished !== '') {
      this.addFilter(filters, 'dateFinished', this.actionDateFinished);
    }
    if (this.actionStatus.id !== NOT_CHOOSEN) {
      this.addFilter(filters, 'status_id', this.actionStatus.id);
    }
    this.$emit(consts.ModalEvents.OK_EVENT, filters);
    this.show = false;
  }
  clear() {
    this.actionNumber = '';
    this.actionDateCreated = '';
    this.actionDateFinished = '';
    this.actionStatus = this.getStatuses[0];
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
  statusSelected(status) {
    this.actionStatus = status;
  }
}
