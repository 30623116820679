var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container text-center mb-3 mt-5"},_vm._l((Array(_vm.pinLength).keys()),function(i){return _c('span',{key:i},[((_vm.allowInput === 'nums' ? 'number' : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code[i]),expression:"code[i]"}],ref:'c' + i,refInFor:true,staticClass:"inline code",attrs:{"maxlength":"1","id":'code' + i,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.code[i])?_vm._i(_vm.code[i],null)>-1:(_vm.code[i])},on:{"input":function (e) {
          _vm.checkChar(e, i);
        },"keyup":function (e) {
          _vm.nextInput(e, i);
        },"change":[function($event){var $$a=_vm.code[i],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.code, i, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.code, i, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.code, i, $$c)}},_vm.checkIfValid]}}):((_vm.allowInput === 'nums' ? 'number' : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code[i]),expression:"code[i]"}],ref:'c' + i,refInFor:true,staticClass:"inline code",attrs:{"maxlength":"1","id":'code' + i,"type":"radio"},domProps:{"checked":_vm._q(_vm.code[i],null)},on:{"input":function (e) {
          _vm.checkChar(e, i);
        },"keyup":function (e) {
          _vm.nextInput(e, i);
        },"change":[function($event){return _vm.$set(_vm.code, i, null)},_vm.checkIfValid]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code[i]),expression:"code[i]"}],ref:'c' + i,refInFor:true,staticClass:"inline code",attrs:{"maxlength":"1","id":'code' + i,"type":_vm.allowInput === 'nums' ? 'number' : 'text'},domProps:{"value":(_vm.code[i])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.code, i, $event.target.value)},function (e) {
          _vm.checkChar(e, i);
        }],"keyup":function (e) {
          _vm.nextInput(e, i);
        },"change":_vm.checkIfValid}})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }