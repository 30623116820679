

















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from '@/components/dummy/Header.vue';
import Footer from '@/components/dummy/Footer.vue';
import moment from 'moment';
import userModule from '@/store/modules/user';

@Component({
  components: { Header, Footer },
})
export default class Calendar extends Vue {
  private dayClass = 'day';
  private currentMonth = 7;
  private currentYear = 2020;
  private currentWeek = 15;
  private currentDay = 1;

  @Prop({ required: true, type: Object })
  public view!: object;
  @Prop({ required: true, type: String })
  readonly title: string;
  @Prop({ required: false, default: [] })
  events: Record<string, any>;
  @Prop({ required: false, type: Object }) readonly dayBox: object;

  get currentMonthTitle() {
    // TO-DO przeliczanie numeru currentMonth na dni
    return 'Lipiec';
  }

  get currentDayTitle() {
    return moment(this.currentDay + '.' + this.currentMonth + '.' + this.currentYear, 'DD.MM.YYYY').format(
      'DD.MM.YYYY'
    );
  }

  public changeView(view: object): any {
    this.$sync('view', view);
    console.log(view);
  }

  get weeks() {
    moment.locale('pl', { week: { dow: 1 } });
    const currDate = moment('01.01.2020', 'DD.MM.YYYY').startOf('day');
    const lastDate = moment('01.02.2021', 'DD.MM.YYYY').startOf('day');

    console.log('WEEKS', moment().year.length);
    let week = null;
    const weeks = [];

    while (currDate.diff(lastDate) < 0) {
      //console.log("curr", currDate.format("DD.MM.YYYY"));
      const weekStart = moment(currDate).startOf('week');
      const weekEnd = moment(currDate).endOf('week');

      week = {
        weekStart: weekStart,
        weekEnd: weekEnd,
        weekOfYear: currDate.week(),
        year: currDate.year(),
        month: currDate.month() + 1,
        days: [],
      };

      const day = currDate.clone().startOf('week');
      for (let dayInWeek = 1; dayInWeek < 8; dayInWeek++) {
        week.days.push({
          day: day.format('DD.MM.YYYY'),
          dayAndMonth: day.format('DD.MM'),
          dayOfMonth: day.date(),
          dayOfYear: day.dayOfYear(),
          dayOfWeek: day.day(),
          year: day.year(),
          month: day.month() + 1,
          events: this.events.filter(
            e => e.day === day.date() && e.month === day.month() + 1 && e.yaear === day.year()
          ),
        });
        day.add(1, 'days');
      }
      weeks.push(week);
      currDate.add(7, 'days');
    }
    console.log('WEEKS:', weeks);
    return weeks;
  }

  goToday() {
    this.scrollTo(4);
  }

  goMonth() {
    this.changeView({ type: 'month', month: 12 });
  }

  dayClick(week: any, day: string) {
    const curDay = moment(day);
    this.currentDay = curDay.date();
    this.currentMonth = curDay.month();
    this.currentYear = curDay.year();
    this.changeView({ type: 'day', day: day });
  }

  weekClick(week: any) {
    alert(week.number);
    //this.dayClass = this.dayClass === "day" ? "day-selected" : "day";
  }

  prevMonth() {
    console.log('Prev');
  }

  nextMonth() {
    console.log('Next');
  }

  scrollTo(weekNumber: number) {
    const elementToScrollTo = document.getElementById('week_' + weekNumber.toString());
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
    console.log('Scrolled');
  }

  mounted() {
    this.$nextTick(() => {
      this.scrollTo(15);
    });
  }
}
