import Vue from 'vue';
import 'izitoast/dist/css/iziToast.min.css';
import visits from '@/store/modules/visits';
import localForage from 'localforage';
import { vuexLocalStorage } from '@/store/index';
import { info } from '@/helpers';
import { store } from '@/store';
import izi from 'izitoast';
export const onlineHelper = Vue.observable({
  isOnline: navigator.onLine,
});

console.log('Add event listener online/offline');
window.addEventListener('offline', () => {
  onlineHelper.isOnline = false;
  console.log('OFFLINE listener');
});

const processOnline = () => {
  onlineHelper.isOnline = true;
  izi.show({
    message: 'Powrócono do trybu online.',
    title: 'Komunikat',
    position: 'bottomLeft',
    timeout: 3000,
    color: 'green',
  });
  console.log('ONLINE listener');
};

export const forceUpdateForage = async () => {
  console.log(localForage, 'localForage');
  await vuexLocalStorage.saveState(vuexLocalStorage.key, store.state, vuexLocalStorage.storage);
};

declare global {
  interface Window {
    switchInternet: any;
  }
}

window.switchInternet = () => {
  if (onlineHelper.isOnline) {
    onlineHelper.isOnline = false;
  } else {
    processOnline();
  }
};

window.addEventListener('online', processOnline);
