import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IMixer } from '@/store/models';
import { Mixer } from '@/types';
import * as helpers from '@/helpers';
import collectsModule from '@/store/modules/collects';

import store from '@/store';
import { RelatedModel } from './decorators';

@Module({
  namespaced: true,
  name: 'mixers',
  store,
  dynamic: true,
})
class MixersModule extends VuexModule {
  public mixers: Mixer[] = [];

  /* GETTERS */
  public get allMixers() {
    return this.mixers;
  }
  public get mixerById() {
    return (id: number): Mixer => {
      return this.mixers.find(m => m.id === id);
    };
  }
  public get mixerCopyById() {
    return (id: number): Mixer => {
      console.log('Mixer load', id);
      return Object.assign(new Mixer(), JSON.parse(JSON.stringify(this.mixerById(id))));
    };
  }
  get print() {
    return (protId: number, nested = false) => {
      this.mixers
        .filter(m => m.protocol_id === protId)
        .forEach(mixer => {
          console.log('      MIXER: id:', mixer.id, 'vehicle_no', mixer.vehicle_no);
          if (nested) {
            collectsModule.print(mixer.id, nested);
          }
        });
    };
  }

  /* MUTATIONS */
  @Mutation
  clear() {
    this.mixers = [];
    console.log('mixers module cleared...');
  }

  @Mutation
  replaceMixer(mixer: Mixer): any {
    console.log('updateMixer mutation', mixer);
    const m = this.mixers.find(m => m.id === mixer.id);
    Object.assign(m, mixer);
  }

  @Mutation
  setMixers(mixers: Mixer[]) {
    this.mixers = [];
    mixers.forEach(mixer => {
      this.mixers.push(Object.assign(new Mixer(), mixer));
    });
  }

  @Mutation
  appendMixer(mixer: Mixer) {
    //console.log('Append Mixer', mixer);
    this.mixers.push(mixer);
  }

  @Mutation
  addMixerByJSON(json: object) {
    const mixer = Object.assign(new Mixer(), json);
    this.mixers.push(mixer);
    console.log('mixers::addMixerByJSON', mixer.id, 'COLLECTS LEN', mixer.collects.length);

    collectsModule.clearMixerCollects(mixer.id);
    json['collects'].forEach(c_json => {
      collectsModule.addCollectByJSON(c_json);
    });
  }
  /*
  @Action
  updateMixerByJSON(json: object) {
    console.log('updateMixerByJSON', json['id']);
    let mixer = this.mixers.find(m => m.id === json['id']);
    if (mixer) {
      this.replaceMixer(json as Mixer);
    } else {
      mixer = Object.assign(new Mixer(), json);
      this.appendMixer(mixer);
    }

    collectsModule.clearMixerCollects(mixer.id);
    json['collects'].forEach(collect_json => {
      collectsModule.addCollectByJSON(collect_json);
    });
  }
  */
  @Mutation
  clearProtocolMixers(protocolId: number) {
    this.mixers = this.mixers.filter(m => m.protocol_id !== protocolId);
  }

  @Mutation
  loadMixersFromConfig(mixers: IMixer[]): any {
    this.mixers = mixers.map(c => Object.assign(new Mixer(), c));
    console.log('Store module Mixers loaded..', this.mixers.length);
  }

  /* ACTIONS */
  @Action
  async updateMixer(mixer: Mixer): Promise<AxiosResponse> {
    const response = await API.saveMixer(mixer);
    if (response.data.success) {
      mixer.id = response.data.mixer.id;
      mixer.protocol_id = response.data.protocol;
      this.replaceMixer(mixer);
      console.log('Replaced to VUEX mixers', mixer);
      return response;
    }
  }
  @Action
  async addMixer(mixer: Mixer): Promise<AxiosResponse> {
    const response = await API.saveMixer(mixer);
    if (response.data.success) {
      mixer.id = response.data.mixer.id;
      this.appendMixer(mixer);
      console.log('Added to VUEX mixers', mixer);
      return response;
    }
  }
  @Action
  @RelatedModel('Mixer')
  async fetchMixers(): Promise<AxiosResponse> {
    console.log('*** FetchMixers');
    const response = await API.fetchMixers();
    if (response.data.success) {
      this.setMixers(response.data.mixers);
      return response;
    }
  }
}

export default getModule(MixersModule);
