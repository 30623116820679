














































































import { Component, Watch, Ref } from 'vue-property-decorator';
import Footer from '@/components/dummy/Footer.vue';
import DashboardCertHeader from '@/components/spec/DashboardCertHeader.vue';
import DashboardMixin from '@/helpers/components/dashboard';
import TopPanel from '@/components/dummy/TopPanel.vue';
import breadcrumbModule from '@/store/modules/breadcrumb';
import * as consts from '@/consts';
import dashboardItemsModule from '@/store/modules/dashboard_items';
import {
  DashboardItem,
  DashboardEntity,
  DashboardColumn,
} from '@/types';
import draggable from 'vuedraggable';
import ModalAction from '@/components/spec/ModalAction.vue';
import ModalTool from '@/components/spec/ModalTool.vue';
import ModalMethod from '@/components/spec/ModalMethod.vue';
import ModalCert from '@/components/spec/ModalCert.vue';
import ModalUser from '@/components/spec/ModalUser.vue';
import dataModule from '@/store/modules/data';
import usersModule from '@/store/modules/users';

@Component({
  components: {
    DashboardCertHeader,
    Footer,
    TopPanel,
    draggable,
    ModalAction,
    ModalCert,
    ModalTool,
    ModalMethod,
    ModalUser,
  },
})
export default class DashboardCert extends DashboardMixin {
  @Ref() readonly modalAction: ModalAction;
  @Ref() readonly modalCert: ModalCert;

  type = consts.DashboardObjectTypes.CERT;

  cols: DashboardColumn[] = [];
  fullscreen = false;
  objectTypes: consts.DashboardObjectTypes[] = [
    consts.DashboardObjectTypes.CERT,
    consts.DashboardObjectTypes.SUPERVISION,
  ];
  colWitdth = '0';
  invalidate = 0;
  lastMove = {
    oldIndex: -1,
    oldStatusId: -1,
    oldStatus: null,
    newStatusId: -1,
    newIndex: -1,
    newStatus: null,
  };

  get getNumber() {
    return (item: DashboardItem) => {
      if (!item.object) {
        return '';
      }
      console.log('Entity Obj', item);
      return (item.object as DashboardEntity).number;
    };
  }
  get getColumnHeight() {
    const itemHeight = 200;
    let max = 1200;
    this.cols.forEach(col => {
      if (col.items) {
        if (col.items.length * itemHeight > max) {
          max = col.items.length * itemHeight;
        }
      }
    });
    return 200 + max + 'px';
  }
  getColWidth() {
    return (this.$refs.contentBox as HTMLFormElement).clientWidth / this.cols.length - 10;
  }

  getColByStatusId(statusId: number): DashboardColumn {
    return this.cols.find(col => col.statusId === statusId);
  }

  fillColsItems() {
    this.cols = this.cols.map(col => {
      return this.fillItems(col);
    });
  }

  fillItems(column: DashboardColumn) {
    this.invalidate;
    const items: DashboardItem[] = dashboardItemsModule.allItems.filter(di => {
      return this.objectTypes.includes(di.object_type_id) === true && di.status_id === column.statusId;
    });

    column.items = [];
    items.forEach((t, index) => {
      console.log('foreach', t, index);
      t.load();
      t.index = index;
      t.columnStatusId = column.statusId;
      t.enabled = column.editable;
      //column.items.push(t);
      this.$set(column.items, index, t);
    });
    console.log('------------------- Items loaded', column);
    return column;
  }
  isSupervision(item: DashboardItem) {
    return item.object_type_id === consts.DashboardObjectTypes.SUPERVISION;
  }

  change(event) {
    console.log('change');
  }
  move(event) {
    console.log('move', event);
    return true;
  }
  add(event) {
    console.log('add', event);
  }
  startDrag(event: any) {
    console.log('startDrag', event);
  }
  endDrag(event: any) {
    console.log('endDrag', event, event.oldIndex, event.from.id, ' => ', event.to.id, event.newIndex);
    if (event.from.id !== event.to.id) {
      const oldCol: DashboardColumn = this.getColByStatusId(parseInt(event.from.id));
      console.log('OLD COL:', oldCol, oldCol.items);
      const newCol: DashboardColumn = this.getColByStatusId(parseInt(event.to.id));
      console.log('NEW COL:', newCol, newCol.items);

      this.lastMove = {
        oldIndex: event.oldIndex,
        oldStatusId: parseInt(event.from.id),
        oldStatus: oldCol.status,
        newStatusId: parseInt(event.to.id),
        newIndex: event.newIndex,
        newStatus: newCol.status,
      };

      const newItem = newCol.items[parseInt(event.newIndex)];

      this.modalAction.showModal({ item: newItem, lastMove: this.lastMove });
      console.log('showModal');
    }
    this.fillColsItems();
  }
  get dragOptions() {
    return {
      animation: 150,
      disabled: false,
      ghostClass: 'ghost',
      handle: '.tile-handle',
    };
  }

  modalEntity(item: DashboardItem) {
    console.log('item.object_type_id', item.object_type_id);
    if (item.object_type_id === consts.DashboardObjectTypes.CERT || this.isSupervision(item) === true) {
      this.modalCert.showModal(item);
    }
  }

  modalActionOK(params: object) {
    const newCol: DashboardColumn = this.getColByStatusId(this.lastMove.newStatusId);
    console.log('modalActionOK 1', newCol, this.lastMove);

    newCol.items.forEach((item, index) => (item.index = index));

    const newItem = params['item'];
    const newAction = params['action'];

    this.fillColsItems();
  }
  modalActionCancel(params: object) {
    console.log('modalAction Cancel', params);
    if (!params['save']) {
      const newCol: DashboardColumn = this.getColByStatusId(this.lastMove.newStatusId);
      const oldCol: DashboardColumn = this.getColByStatusId(this.lastMove.oldStatusId);
      console.log('Revert to old col', this.lastMove.newIndex, newCol, this.lastMove);
      oldCol.items.splice(this.lastMove.oldIndex, 0, newCol.items[this.lastMove.newIndex]);
      oldCol.items.forEach((t, index) => (t.index = index));
      console.log('removing ', this.lastMove, newCol);
      newCol.items = newCol.items.filter(t => t.index !== this.lastMove.newIndex);
      newCol.items.forEach((t, index) => (t.index = index));
    }
  }

  async modalCertOK() {
    await dataModule.fetchdocuments();
    await dashboardItemsModule.fetchDashboardItems();
    this.fillColsItems();
  }

  /******************************* TOOL ********************************/
  colsTOOL() {
    this.objectTypes = [consts.DashboardObjectTypes.TOOL];
    this.cols = [
      new DashboardColumn(consts.ToolActionStatus.CANCELLED, 0),
      new DashboardColumn(consts.ToolActionStatus.INITIAL, 1),
      new DashboardColumn(consts.ToolActionStatus.PENDING, 2, true),
      new DashboardColumn(consts.ToolActionStatus.TOACCEPT, 3),
      new DashboardColumn(consts.ToolActionStatus.DONE, 4),
    ];
  }
  modalToolActionOK(params) {
    this.invalidate++;
    console.log('ModalToolActionOK', params);
  }
  modalToolActionCancel() {
    console.log('ModalToolActionCancel');
  }

  /******************************* METHOD ********************************/
  colsMETHOD() {
    this.objectTypes = [consts.DashboardObjectTypes.METHOD];
    this.cols = [
      new DashboardColumn(consts.MethodActionStatus.CANCELLED, 0),
      new DashboardColumn(consts.MethodActionStatus.INITIAL, 1),
      new DashboardColumn(consts.MethodActionStatus.PENDING, 2, true),
      new DashboardColumn(consts.MethodActionStatus.TOACCEPT, 3),
      new DashboardColumn(consts.MethodActionStatus.DONE, 4),
    ];
  }
  modalMethodActionOK(params) {
    usersModule.updateUser(params.object);
    console.log('ModalMethodActionOK', params.object);
    this.invalidate++;
  }
  modalMethodActionCancel() {
    console.log('ModalMethodActionCancel');
  }

  /******************************* PERSONEL ********************************/
  colsPERSONEL() {
    this.objectTypes = [consts.DashboardObjectTypes.PERSONEL];
    this.cols = [
      new DashboardColumn(consts.PersonelActionStatus.CANCELLED, 0),
      new DashboardColumn(consts.PersonelActionStatus.INITIAL, 1),
      new DashboardColumn(consts.PersonelActionStatus.PENDING, 2, true),
      new DashboardColumn(consts.PersonelActionStatus.TOACCEPT, 3),
      new DashboardColumn(consts.PersonelActionStatus.DONE, 4),
    ];
  }
  colsOFFER() {
    this.objectTypes = [consts.DashboardObjectTypes.OFFER];
    this.cols = [
      new DashboardColumn(consts.OfferActionStatus.CANCELLED, 0),
      new DashboardColumn(consts.OfferActionStatus.REQUEST, 1),
      new DashboardColumn(consts.OfferActionStatus.OFFER, 2, true),
      new DashboardColumn(consts.OfferActionStatus.TOACCEPT, 3),
      new DashboardColumn(consts.OfferActionStatus.CONTRACT, 4),
    ];
  }
  colsCERT() {
    this.objectTypes = [consts.DashboardObjectTypes.CERT, consts.DashboardObjectTypes.SUPERVISION];
    this.cols = [
      new DashboardColumn(consts.CertActionStatus.INITIAL, 0),
      new DashboardColumn(consts.CertActionStatus.AFTER, 1, true),
      new DashboardColumn(consts.CertActionStatus.TOACCEPT, 2),
      new DashboardColumn(consts.CertActionStatus.CERTYFICATE, 3),
      new DashboardColumn(consts.CertActionStatus.CANCELLED, 4),
    ];
  }

  created() {
    this.colsCERT();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/lab',
      items: [
        {
          title: 'Tablica certyfikacji',
          link: '',
        },
      ],
    });
  }
  async mounted() {
    await dataModule.fetchdocuments();
    await dashboardItemsModule.fetchDashboardItems();
    this.colWitdth = this.getColWidth() + 'px';
    this.fillColsItems();
  }
  @Watch('objectType')
  onObjectTypeChange(oldVal: number, newVal: number) {
    this.fillColsItems();
  }
}
