import * as API from '@/store/api';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators';
import { Guid } from 'guid-typescript';

import store from '@/store';
import userModule from '@/store/modules/user';
import dashboardItemsModule from '@/store/modules/dashboard_items';

import * as helpers from '@/helpers';
import { Signature, DashboardAction, Message, GenericDocument, DashboardItem } from '@/types';
import {
  DB_DATE_TIME_FORMAT,
  DashboardObjectTypes,
  CertActionStatus,
  CertOperationType,
  MessageSenderType,
  MessageGroup,
  MessageType,
  MessageObjectType,
  DATE_FORMAT,
} from '@/consts';
import { RelatedModel } from './decorators';

const NEW_ID_START = 100000; // standardowo jest okolo 1400 klientow wiec 100k wystarczy

@Module({
  namespaced: true,
  name: 'data',
  store,
  dynamic: true,
})
class DataModule extends VuexModule {
  signatures: Signature[] = [];
  dashboardActions: DashboardAction[] = [];
  messages: Message[] = [];
  documents: GenericDocument[] = [];
  unreadMessages: {
    count: number;
  } = { count: 1 };

  get signatureById() {
    return (id: number): Signature => {
      return this.signatures.find(s => s.id === id);
    };
  }
  @Mutation
  clear() {
    this.signatures = [];
    this.dashboardActions = [];
    this.messages = [];
    this.documents = [];
    this.unreadMessages = { count: 0 };
    console.log('Data module cleared...');
  }

  @Mutation
  addSignature(signature: Signature) {
    const maxId = Math.max(...this.signatures.map(c => c.id));
    if (signature.id === 0) {
      if (maxId < NEW_ID_START) {
        signature.id = NEW_ID_START;
      } else {
        signature.id = maxId + 1;
      }
    }
    this.signatures.push(signature);
    console.log('Signature added:', signature);
  }

  @Mutation
  setDocuments(documents: GenericDocument[]) {
    this.documents = [];
    documents.forEach(el => {
      this.documents.push(Object.assign(new GenericDocument(), el));
    });
  }

  @Action({ rawError: true })
  async fetchdocuments() {
    console.log('*** fetchdocuments');
    const response = await API.fetchCertDocs();
    if (response.data.success) {
      const documents = response.data.certDocuments;
      this.setDocuments(documents);
    }
    return response;
  }
  @Action({ rawError: true })
  async addSupervisions() {
    let response = await this.fetchdocuments();
    if (response.data.success === false) {
      helpers.warn('Wystąpił błąd przy pobieraniu dokumentów');
      return;
    }

    const toBeSupervision = this.documents.filter(d => {
      if (d.type !== DashboardObjectTypes.CERT && d.type !== DashboardObjectTypes.SUPERVISION) {
        return false;
      }
      const sameCertDocs = this.documents.filter(doc => doc.name === d.name && doc.number === d.number);

      const maxDate = Math.max(...sameCertDocs.map(doc => moment(doc.date, DB_DATE_TIME_FORMAT).unix()));
      if (maxDate !== moment(d.date, DB_DATE_TIME_FORMAT).unix()) {
        return false;
      }

      const diff = moment(d.date, DB_DATE_TIME_FORMAT).diff(moment(), 'days');
      if (diff > 0 && diff < userModule.getSettings.daysToMakeSupervision) {
        return true;
      }
      return false;
    });
    for (const document of toBeSupervision) {
      const supervision = Object.assign(new GenericDocument(), document);
      supervision.files = [];
      supervision.guid = Guid.raw();
      supervision.id = undefined;
      supervision.date = moment().format(DB_DATE_TIME_FORMAT);
      supervision.type = DashboardObjectTypes.SUPERVISION;

      response = await API.saveCert(supervision);
      supervision.id = response.data.certDocument.id;
      this.setDocuments([...this.documents, supervision]);

      let receiverId: number;
      if (response.data.success === true) {
        const item = new DashboardItem();
        item.object_id = supervision.id;
        item.object_type = DashboardObjectTypes.SUPERVISION;
        item.object_type_id = DashboardObjectTypes.SUPERVISION;
        item.status_id = CertActionStatus.AFTER.id;
        item.comment = '';
        item.operation_type_id = CertOperationType.ADD.id;
        item.start_date_time = moment().format(DB_DATE_TIME_FORMAT);

        response = await dashboardItemsModule.fetchDashboardItems();
        if (response.data.success === true) {
          const items = dashboardItemsModule.allItems.filter(
            i => i.object_type_id === DashboardObjectTypes.CERT || i.object_type_id === DashboardObjectTypes.SUPERVISION
          );

          item.person_initiated_id = items.find(i => i.object_id === document.id).person_initiated_id;
          receiverId = item.person_initiated_id;

          response = await dashboardItemsModule.addItem(item);
        }
      }
      if (response.data.success === false) {
        helpers.warn('Wystąpił błąd przy tworzeniu nadzorów');
      } else {
        const message = new Message();
        message.receiver_id = receiverId;
        message.title = 'Przypomnienie o nadzorze';
        const supervisionDate = moment(document.date, DB_DATE_TIME_FORMAT).format(DATE_FORMAT);
        // message.content = `Nadzór ${supervision.number} odbędzię się ${supervisionDate}`;
        message.content = `Nadzor ${supervision.number} odbedzie sie ${supervisionDate}`;
        message.type = MessageSenderType.SYSTEM;
        message.group_id = MessageGroup.SUPERVISION.id;
        message.message_type = MessageType.INFO;
        message.sender_id = null;
        message.object_id = 1;
        message.object_type_id = MessageObjectType.SUPERVISION;
        const response = await this.sendMessage(message);
        if (response.data.success === false) {
          helpers.warn('Wystąpił błąd przy tworzeniu nadzorów');
          return;
        }
      }
    }
  }

  get docByTypeAndId() {
    return (id: number, type: number): GenericDocument => {
      return this.documents.find(el => el.id === id && el.type === type);
    };
  }

  @Mutation
  loadSignaturesFromConfig(signatures: Signature[]) {
    this.signatures = signatures.map(s => Object.assign(new Signature(), s));
    console.log('Store module Data loadSignaturesFromConfig..', this.signatures.length);
  }

  /******************************* ACTION ******************************/
  get actionById() {
    return (id: number): DashboardAction => {
      return this.dashboardActions.find(a => a.id === id);
    };
  }

  @Mutation
  loadDashboardActionsFromConfig(actions: DashboardAction[]) {
    this.dashboardActions = actions.map(s => Object.assign(new DashboardAction(), s));
    console.log('Store module Data loadDashboardActionsFromConfig..', this.dashboardActions.length);
  }

  /******************************* MESSAGE ******************************/
  get allMessages() {
    return this.messages;
  }
  get messageById() {
    return (id: number): Message => {
      return this.messages.find(m => m.id === id);
    };
  }
  get unreadMessagesCount() {
    return this.unreadMessages.count;
  }

  @Mutation
  changeMessageRead(id: number) {
    const msg = this.messages.find(m => m.id === id);
    msg.is_read = true;
  }

  @Mutation
  setMessages(messages: Message[]) {
    this.messages = messages;
  }

  @Mutation
  setUnreadCount(count: number) {
    this.unreadMessages.count = count;
  }

  @Mutation
  addMessage(message: Message) {
    const maxId = Math.max(...this.messages.map(m => m.id));
    if (message.id === 0) {
      if (maxId < NEW_ID_START) {
        message.id = NEW_ID_START;
      } else {
        message.id = maxId + 1;
      }
    }
    this.messages.push(message);
    console.log('Message added:', message);
  }
  @Mutation
  loadMessagesFromConfig(messages: Message[]) {
    this.messages = messages.map(s => Object.assign(new Message(), s));
    console.log('Store module Data loadMessagesFromConfig..', this.messages.length);
  }

  @Mutation
  appendAction(action: DashboardAction) {
    this.dashboardActions.push(action);
  }

  @Mutation
  replaceAction(action: DashboardAction) {
    const u = this.dashboardActions.find(v => v.id === action.id);
    Object.assign(u, action);
  }

  @Action({ rawError: true })
  async fetchMessages(): Promise<AxiosResponse> {
    const user = userModule.user.id;
    const response = await API.fetchMessages(user);
    if (response.data.success) {
      const messages = response.data.messages.map(m => Object.assign(new Message(), m));
      this.setMessages(messages);
      const unread = messages.filter(m => {
        return !m.is_read;
      });
      this.setUnreadCount(unread.length);
    } else {
      console.log('fetchMessages Error', response.data.error);
    }
    return response;
  }

  @Action
  async readMessage(id: number): Promise<AxiosResponse> {
    const response = await API.readMessage(id);
    this.changeMessageRead(id);
    const unread = this.messages.filter(m => {
      return !m.is_read;
    });
    this.setUnreadCount(unread.length);
    return response;
  }

  @Action
  async sendMessage(message: Message): Promise<AxiosResponse> {
    const response = await API.addMessage(message);
    return response;
  }

  @Action({ rawError: true })
  async addDashboardAction(action: DashboardAction): Promise<AxiosResponse> {
    const response = await API.saveDashboardAction(action);
    if (response.data.success) {
      const newAction = Object.assign(new DashboardAction(), response.data.action);
      this.appendAction(newAction);
    } else {
      console.log('addItem Error', response.data.error_code);
    }
    return response;
  }

  @Action({ rawError: true })
  async updateDashboardAction(action: DashboardAction): Promise<AxiosResponse> {
    const response = await API.saveDashboardAction(action);
    if (response.data.success) {
      this.replaceAction(action);
    }
    return response;
  }

  @Mutation
  setDashboardActions(dashboardActions: DashboardAction[]) {
    this.dashboardActions = [];
    dashboardActions.forEach(u => {
      this.dashboardActions.push(Object.assign(new DashboardAction(), u));
    });
  }

  @Action
  async fetchDashboardActions(): Promise<AxiosResponse> {
    console.log('*** fetchDashboardActions');
    const response = await API.fetchDashboardActions();
    if (response.data.success) {
      this.setDashboardActions(response.data.dashboard_actions);
      console.log('   fetchDashboardActions VUEX DONE:', this.dashboardActions.length);
      return response;
    }
  }

  @Mutation
  setSignatures(signs: Signature[]) {
    this.signatures = [];
    signs.forEach(el => {
      this.signatures.push(Object.assign({}, el));
    });
  }

  @Action
  @RelatedModel('Signature')
  async fetchSignatures(): Promise<AxiosResponse> {
    console.log('*** fetchSignatures');
    const response = await API.fetchSignatures();
    if (response.data.success) {
      this.setSignatures(response.data.signatures);
      return response;
    }
  }

  @Action
  async saveSignature(signature: Signature): Promise<AxiosResponse> {
    console.log('*** saveSignature');
    const response = await API.saveSignature(signature);
    if (response.data.success) {
      this.signatures.push(response.data.signature);
      return response;
    }
  }
  @Action
  async sendCode(signature: Signature): Promise<AxiosResponse> {
    console.log('*** sendCode');
    const response = await API.sendSignatureCode(signature);
    return response;
  }
}

export default getModule(DataModule);
