

























































































import { Component, Vue, Ref } from 'vue-property-decorator';
import AppModule from '../store/modules/app';
import UserModule from '../store/modules/user';
import { onlineHelper } from '@/helpers/offline';
import PINInput from '@/components/dummy/PINInput.vue';
import { stateInfo } from '@/store';
import { EventBus, BusEvents } from '../helpers/eventbus';
import localforage from 'localforage';
import { User } from '@/types';
import * as consts from '@/consts';
import user from '../store/modules/user';
import { sha256 } from 'js-sha256';

@Component({
	components: { PINInput },
})
export default class Login extends Vue {
	@Ref() pinInput: PINInput;
	private email = '';
	private pass = '';
	private pin = '';
	loginError = '';
	max = 100;
	progressValue = 0;
	loginBtnKey = 0;
	newVersion = 'v1.0.19';

	get appVersion() {
		return AppModule.appVersion;
	}

	get appLoaded() {
		return stateInfo.loadedState;
	}

	get isOnline() {
		return onlineHelper.isOnline;
	}

	checkIfLoginPossible() {
		if (!UserModule.user && !this.isOnline) {
			return false;
		}
		return true;
	}

	get getStoredUser() {
		const user = UserModule.user;
		if (user) {
			return `${user.firstname} ${user.lastname}`;
		} else {
			return '----';
		}
	}

	// TO-DO rest of the validations
	private validate() {
		if (!this.email) {
			return 'Proszę wypełnić email';
		}
		if (!this.pass) {
			return 'Proszę wypełnić hasło';
		}
		return '';
	}
	clearErrors() {
		this.loginError = '';
	}

	invalidPasswordError() {
		EventBus.$emit(BusEvents.LOGIN_PROGRESS, 0);
		this.loginError = 'Niepoprawny email lub hasło !!';
		this.loginBtnKey++;
	}

	// TO-DO change event resets errors
	async login() {
		if (!this.isOnline) {
			this.pin = this.pinInput.fullCode;
			console.log('Logging in offline mode...', this.pin);
			const hashed = sha256(this.pin);
			if (UserModule.pin === hashed) {
				user.reloadUser();
				this.$router.push('lab');
			} else {
				this.invalidPasswordError();
				this.pinInput.clear();
			}
			return;
		}

		this.loginError = this.validate();

		if (this.loginError) {
			this.loginBtnKey++;
			return;
		}
		EventBus.$emit(BusEvents.LOGIN_PROGRESS, 10);

		const response = await UserModule.login({
			email: this.email,
			password: this.pass,
		});

		if (response && response.data && response.data.success) {
			console.log('Login.vue login Response', response);
			const user: User = response.data.user as User; //logowanie powinno zwracac typ modulu do przekierowania
			if (user.is_logged_in) {
				console.log('usermodule', user.module);
				if (user.module.toString() === consts.ModuleType.LAB.toString()) {
					this.$router.push('lab');
				} else if (user.module.toString() === consts.ModuleType.CLIENT.toString()) {
					this.$router.push('client');
				} else if (user.module.toString() === consts.ModuleType.ADMIN.toString()) {
					this.$router.push('admin');
				}
			} else {
				this.invalidPasswordError();
			}
		} else {
			this.invalidPasswordError();
		}
	}

	// TO-DO pokazać okno z pobraniem email, wyslac na niego nowy pass
	async reset() {
		this.$router.push('reset');
	}

	visitWorker() {
		this.email = 'm.barc@ctb-ibc.pl';
		this.pass = '***';
	}
	visitWorker2() {
		this.email = 'b.pieta@ctb-ibc.pl';
		this.pass = '***';
	}
	coordWorker() {
		this.email = 'a.pieta@ctb-ibc.pl';
		this.pass = '***';
	}
	adminWorker() {
		this.email = 'admin.a1@prym-soft.pl';
		this.pass = '***';
	}
	makeProgress(value: number) {
		this.progressValue = value;
	}
	refreshing = false;
	registration = null;
	updateExists = false;
	currentVersion = '';

	showRefreshUI(e) {
		console.log('Login showRefreshUI');
		this.registration = e.detail;
		this.updateExists = true;
	}
	refreshApp() {
		this.updateExists = false;

		localforage.setItem('vuex', {});

		if (!this.registration || !this.registration.waiting) {
			console.log('Login refreshApp !');
			return;
		}
		console.log('Login refreshApp post SKIP_WAITING');
		this.registration.waiting.postMessage('SKIP_WAITING');
	}
	mounted() {
		this.currentVersion = process.env.VUE_APP_VERSION;

		document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
		if (navigator && navigator.serviceWorker) {
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (this.refreshing) {
					console.log('Login this.refreshing');
					return;
				}
				console.log('Login this.refreshing false, reload');
				this.refreshing = true;
				window.location.reload();
			});
		}
		EventBus.$on(BusEvents.LOGIN_PROGRESS, this.makeProgress);
	}
}
