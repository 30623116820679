import Vue from 'vue';
import Vuex from 'vuex';
import localForage from 'localforage';
import VuexPersist, { AsyncStorage } from 'vuex-persist';
import * as types from '@/types';
import dataModule from '@/store/modules/data';
Vue.use(Vuex);

const parser = (store: any) => {
  for (const key of Object.keys(store)) {
    if (Array.isArray(store[key]) && store[key].length === 0) {
      continue;
    }

    if (store[key] && typeof store[key] === 'object') {
      if (store[key]['__type']) {
        const type = store[key]['__type'];
        const _constructor = types[type];
        store[key] = Object.assign(new _constructor(), store[key]);
      }
      parser(store[key]);
    }
  }
};

export const stateInfo = Vue.observable({
  loadedState: false,
});

export const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: localForage,
  asyncStorage: true,
  restoreState: async function (key: string, storage: Storage | AsyncStorage) {
    const store = await storage.getItem(key);
    const t1 = performance.now();

    if (!store) {
      stateInfo.loadedState = true;
      return {};
    }

    await parser(store);

    const t2 = performance.now();

    console.log((t2 - t1) / 1000, 's elapsed');
    console.log(store, 'LOADED STORE');

    stateInfo.loadedState = true;
    return store;
  },
});

export const store = new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {},
  mutations: {},
  actions: {},
});

export default store;
