import store from "@/store";
import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Breadcrumb, BreadcrumbItem } from "@/types";
import * as eventbus from "../../helpers/eventbus";

@Module({
  namespaced: true,
  name: "breadcrumb",
  store,
  dynamic: true,
})
class BreadcrumbModule extends VuexModule {
  breadcrumbObj: Breadcrumb;

  get breadcrumb(): Breadcrumb {
    console.log("get breadcrumb", this.breadcrumbObj);
    return this.breadcrumbObj;
  }

  get items(): BreadcrumbItem[] {
    console.log("get breadcrumb items", this.breadcrumbObj.items);
    return this.breadcrumbObj.items;
  }

  @Mutation
  setBreadcrumb(breadcrumb: Breadcrumb): any {
    console.log("VUEX::breadcrumb setBreadcrumb", breadcrumb);
    this.breadcrumbObj = breadcrumb;
    eventbus.updateBreadcrumb();
  }
}

export default getModule(BreadcrumbModule);
