













































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { ProtocolType, VisitStatus, VisitViewType, DATE_FORMAT, DB_DATE_TIME_FORMAT } from '../../../consts';
import { VisitView, Visit } from '../../../types';
import moment from 'moment';
import * as consts from '../../../consts';
import { EventBus, BusEvents } from '../../../helpers/eventbus';

@Component
export default class VisitHeader extends Vue {
  @Prop({ required: true, type: String }) readonly title: string;
  @Prop({ required: false, type: Visit }) readonly visit: Visit;
  @Prop({ required: false, type: Boolean }) isTreeVisible = false;

  buttonClick = 0;
  isVerified = false;
  locale: 'pl-PL';
  authKey = 0;

  showTree(e: any) {
    console.log('------ TREE Clicked ---------');
    EventBus.$emit(BusEvents.CHANGE_TREE_VISIBLE, { isTreeVisible: !this.isTreeVisible, event: e });
    this.buttonClick++;
  }

  get visitDate() {
    if (this.visit) {
      if (moment(this.visit.date, consts.DB_DATE_TIME_FORMAT, true).isValid()) {
        return moment(this.visit.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
      } else {
        return this.visit.date;
      }
    } else {
      return '';
    }
  }

  get status(): VisitStatus {
    if (this.visit) {
      return VisitStatus.byId(this.visit.status_id);
    } else {
      return VisitStatus.PLANNED;
    }
  }

  setVerified() {
    console.log('setVerif', this.visit.collect_and_exam_protocol);
    this.isVerified = this.visit.status === VisitStatus.VERIFIED;
  }

  get isAuthorised() {
    return (
      this.visit.collect_and_exam_protocol !== undefined &&
      this.visit.collect_and_exam_protocol.authorise_person !== undefined
    );
  }

  authorise() {
    console.log('setVerfied');
    this.setVerified();
    this.authKey++;
  }
  mounted() {
    this.setVerified();
    EventBus.$on(BusEvents.AUTHORISE, this.authorise);
  }
  get getTitle() {
    console.log('title Visit', this.visit);
    return '';
  }
}
