


















































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import { DashboardItem, Localization, Tool, ToolParam, ToolGroup, User, Car } from '../../types';
import usersModule from '../../store/modules/users';
import dictModule from '../../store/modules/dict';
import toolsModule from '../../store/modules/tools';
import moment from 'moment';
import * as helpers from '../../helpers';

const TOOL_INVENT_ALREADY_EXISTED = 2;

@Component
export default class ModalCar extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'xl' }) size;

  show = false;
  isNew = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  comment = '';
  btnSave = 0;
  car: Car = null;
  orginalCar: Car = null;
  item: DashboardItem = null;
  userItemSelected = this.users[0];
  carTools: Tool[] = [];

  nameWithLastname({ firstname, lastname }) {
    return `${firstname} ${lastname}`;
  }

  get userSel() {
    return this.userItemSelected;
  }
  get users() {
    let users = usersModule.allUsers;
    users = users.filter(u => u.module == consts.ModuleType.LAB);
    return [{ id: null, firstname: '-- brak --', lastname: '' }, ...users];
  }
  userSelected(event) {
    this.userItemSelected = event;
    this.car.default_user_id = this.userItemSelected.id;
  }

  showModal(car: Car = null) {
    console.log('CarModal showModal ', car);
    this.show = true;
    this.isNew = car === null;
    if (this.isNew) {
      this.car = new Car();
      this.userItemSelected = this.users[0];
    } else {
      this.car = Object.assign(new Car(), car);
      this.userItemSelected = usersModule.userById(car.default_user_id);
      this.orginalCar = car;
      this.carTools = toolsModule.tools.filter(tool => tool.car_id === car.id);
      this.carTools.forEach((t, index) => {
        t['index'] = index;
      });
    }
    console.log('showModal', this.car, this.isNew);
  }

  async saveModal() {
    if (this.car.number.length === 0) {
      helpers.error('Proszę wypełnić numer samochodu');
      this.btnSave++;
      return;
    }
    if (this.car.register_number.length === 0) {
      helpers.error('Proszę wypełnić numer rejestracyjny');
      this.btnSave++;
      return;
    }
    this.save = true;
    this.car['save'] = true;
    this.car['new'] = this.isNew;
    console.log('Saving car', this.car, this.isNew);
    let response = null;
    if (this.isNew !== true) {
      response = await toolsModule.updateCar(this.car);
    } else {
      response = await toolsModule.addCar(this.car);
    }
    this.car.id = response.data.car['id'];
    this.$emit(consts.ModalEvents.OK_EVENT, this.car);
    this.show = false;
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
}
