

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ClientHeader extends Vue {
  private mainTitle = '<brak nazwy>';
  private elements = [];
  private badges = [];

  @Prop({ required: true, type: String }) readonly title: string;
  @Prop({ required: false, type: String, default: 'calendar-alt' }) readonly mainIcon: string;
  @Prop({ required: false, type: String }) readonly secTitle: string;
  @Prop({ required: false, type: Number, default: 6 }) readonly leftCol: number;
  @Prop({ required: false, type: Number, default: 5 })
  readonly rightCol: number;
}
