















































































































































import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import ClientMenu from '../../components/spec/ClientMenu.vue';
import ClientTopPanel from '../../components/dummy/ClientTopPanel.vue';
import ClientHeader from '../../components/dummy/ClientHeader.vue';
import Footer from '../../components/dummy/Footer.vue';
import offerModule from '../../store/modules/offer';
import userModule from '../../store/modules/user';
import ClientOffersFilterModal from '../../views/client/ClientOffersFilterModal.vue';
import { Offer, OfferAction } from '../../types';
import * as consts from '../../consts';
import dashBoardItems from '../../store/modules/dashboard_items';
import { SortDir } from '../../consts';
import breadcrumbModule from '../../store/modules/breadcrumb';

@Component({
  components: { ClientMenu, ClientTopPanel, ClientHeader, Footer, ClientOffersFilterModal },
})
export default class ClientOffers extends Vue {
  //dodaj kolumne ze statusem
  @Ref() readonly clientOffersFilterModal: ClientOffersFilterModal;
  spinner = false;
  expanded: Array<boolean>;
  offerActions: OfferAction[] = [];
  revalidate = 0;
  btnPrevVisible = false;
  btnNextVisible = false;
  pageSize = 20;
  pageNumber = 0;
  itemStatus: consts.OfferActionStatus;
  allPagesCount = 0;
  filters = [];
  sortParams = { field: 'number', dir: SortDir.ASC };
  nextPage() {
    this.pageNumber++;
    this.update();
    this.fillOffers();
  }
  prevPage() {
    this.pageNumber--;
    this.update();
    this.fillOffers();
  }
  update() {
    console.log('update', this.pageNumber, this.allPagesCount);
    this.btnNextVisible = this.pageNumber + 1 !== this.allPagesCount;
    this.btnPrevVisible = this.pageNumber + 1 !== 1;
  }
  get getOffers() {
    this.revalidate;
    return this.offerActions;
  }
  addOffer() {
    this.$router.push({ name: 'clientAddOffer' });
  }
  setExpanded() {
    this.expanded = [];
    this.offerActions.forEach((el, index) => {
      this.expanded[index] = false;
    });
  }
  async created() {
    console.log('CREATE ClientOffers', userModule.wholeUser);
    await offerModule.fetchOffersClient(userModule.user.id);
    offerModule.offerActions.forEach(el => {
      el.load();
    });
    this.setExpanded();
    this.update();
    this.fillOffers();
    breadcrumbModule.setBreadcrumb({
      routePrefix: '/client',
      items: [
        {
          title: 'Lista zapytań ofertowych',
          link: '/clientoffers',
        },
      ],
    });
  }

  offersVisible(): boolean {
    return this.offerActions.length > 0;
  }

  fillOffers() {
    this.offerActions = [];
    this.offerActions = offerModule.allOfferActions;
    if (this.filters.length > 0) {
      this.offerActions = this.filterActions(this.offerActions, this.filters);
    }
    this.offerActions = this.sortOffers(this.offerActions);
    this.offerActions = this.paginate(this.offerActions, this.pageNumber);
    this.setExpanded();
    this.update();
    this.revalidate++;
  }

  paginate(array: OfferAction[], pageNumber: number): OfferAction[] {
    console.log('paginate', pageNumber, this.pageSize);
    this.allPagesCount = Math.ceil(this.offerActions.length / this.pageSize);
    const rowFrom: number = +(pageNumber * this.pageSize);
    const rowTo: number = +(pageNumber * this.pageSize) + +this.pageSize;
    console.log('Page [' + rowFrom, '-', rowTo + ']');
    const templateArray: OfferAction[] = [];
    array.forEach(o => {
      templateArray.push(o);
    });
    return templateArray.slice(rowFrom, rowTo);
  }

  sortOffers(offers: OfferAction[]) {
    if (this.sortParams.dir === SortDir.ASC) {
      return offers.sort((a, b) => {
        return a[this.sortParams.field] > b[this.sortParams.field] ? 1 : -1;
      });
    } else {
      return offers.sort((a, b) => {
        return a[this.sortParams.field] < b[this.sortParams.field] ? 1 : -1;
      });
    }
  }

  filterActions(offers: OfferAction[], filters: { field: string; value: any }[]) {
    return offers.filter(element => {
      let result = true;
      filters.forEach(filter => {
        if (result) {
          console.log('-- FILTER', filter.field, filter.value);
          if (typeof filter.value === 'string') {
            result = element[filter.field].includes(filter.value);
          } else if (typeof filter.value === 'number') {
            result = element[filter.field] === filter.value;
          }
        }
      });
      return result;
    });
  }

  redirectToOffer(action: OfferAction, offer: Offer) {
    offerModule.setOfferActionReference(action);
    offer.active = true;
    offerModule.setOffer(offer);
    this.$router.push({
      name: 'clientEditOffer',
      params: { offer_id: action.id.toString(), redirectTo: consts.OfferActionStatus.OFFER.symbol },
    });
  }
  async redirectToOfferAction(action: OfferAction) {
    offerModule.setOfferActionReference(action);
    offerModule.setOffer(null);
    await dashBoardItems.fetchDashboardItems();
    const redirect = action.status.symbol;
    this.$router.push({
      name: 'clientEditOffer',
      params: { offer_id: action.id.toString(), redirectTo: redirect },
    });
  }

  sortPage(field) {
    this.spinner = true;
    if (this.sortParams.field === field)
      this.sortParams.dir = this.sortParams.dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    else this.sortParams.dir = SortDir.DESC;
    console.log('sortPage', this.sortParams);
    this.sortParams.field = field;
    this.fillOffers();
    this.spinner = false;
  }
  modalFilterOK(filters) {
    this.filters = filters;
    this.pageNumber = 0;
    console.log('reseted the page number', this.pageNumber);
    this.fillOffers();
  }

  changePageSize(size) {
    this.pageSize = size;
    this.fillOffers();
    this.update();
  }

  expandRow(index: number) {
    this.revalidate++;
    this.expanded[index] = !this.expanded[index];
  }
  filterPage() {
    this.clientOffersFilterModal.showModal();
  }
}
