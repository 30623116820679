import Vue from 'vue';
import { PageMode } from '@/consts';
import VueRouter, { RouteConfig } from 'vue-router';
import * as consts from '@/consts';

import Admin from '../views/Admin.vue';
import UsersList from '../views/admin/users/UsersList.vue';

import Client from '../views/Client.vue';
import ClientOrders from '../views/client/ClientOrders.vue';
import ClientResultsDocList from '../views/client/ClientResultsDocList.vue';

import LaborantResultsDocList from '../views/laborant/laboratorium/LaborantResultsDocList.vue';
import CalendarVisits from '../views/laborant/CalendarVisits.vue';
import CalendarVisitsMonth from '../views/laborant/CalendarVisitsMonth.vue';
import CalendarVisitsDayPeople from '../views/laborant/CalendarVisitsDayPeople.vue';
import CalendarVisitsDayPerson from '../views/laborant/CalendarVisitsDayPerson.vue';

import CalendarExams from '../views/laborant/CalendarExams.vue';
import CalendarMetroControl from '../views/laborant/CalendarMetroControl.vue';
import CalendarCerts from '../views/laborant/CalendarCerts.vue';
import Login from '../views/Login.vue';
import Reset from '../views/Reset.vue';
import Laborant from '@/views/Laborant.vue';
import LaborantOrders from '@/views/laborant/Orders.vue';
import LaborantOrder from '@/views/laborant/orders/OrderView.vue';
import ClientOffers from '@/views/client/ClientOffers.vue';
import DashboardOfferAdd from '@/components/spec/DashBoardOfferAction.vue';

import VisitViewMain from '@/views/laborant/visits/VisitViewMain.vue';
import Visit from '@/views/laborant/visits/Visit.vue';
import VisitProtocolPiB from '@/views/laborant/visits/VisitProtocolPIB.vue';
import VisitProtocolCTB from '@/views/laborant/visits/VisitProtocolCTB.vue';
import VisitProtocolCollect from '@/views/laborant/visits/VisitProtocolCollect.vue';
import VisitMixer from '@/views/laborant/visits/VisitMixer.vue';
import VisitCollect from '@/views/laborant/visits/VisitCollect.vue';
import SynchroList from '@/views/SynchroList.vue';
// ADMINISTRATION
import MethodList from '@/views/laborant/administration/MethodList.vue';
import ToolList from '@/views/laborant/administration/ToolList.vue';
import CarList from '@/views/laborant/administration/CarList.vue';
import PersonelList from '@/views/laborant/administration/PersonelList.vue';
import DashboardActions from '@/views/laborant/administration/DashboardActions.vue';

import CalendarExamsMonth from '@/views/laborant/CalendarExamsMonth.vue';
import CalendarExamsDay from '@/views/laborant/CalendarExamsDay.vue';

import ExamCard from '@/views/laborant/exams/ExamCard.vue';
import ExamCardView from '@/views/laborant/exams/ExamCardView.vue';
import DashboardOffer from '@/views/laborant/laboratorium/DashboardOffer.vue';
import DashboardCert from '@/views/laborant/laboratorium/DashboardCert.vue';
import DashboardAudit from '@/views/laborant/laboratorium/DashboardAudit.vue';
import DashboardDocument from '@/views/laborant/laboratorium/DashboardDocument.vue';
import DashboardSettlement from '@/views/laborant/laboratorium/DashboardSettlement.vue';

// import Admin from '@/views/laborant/administration/Admin.vue';

// DICT
import ClientList from '@/views/laborant/dicts/ClientList.vue';
import ExamDestiny from '@/views/laborant/dicts/ExamDestiny.vue';

import Test from '@/views/Test.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
  },
  {
    name: 'client',
    path: '/client/',
    component: Client,
    props: true,
    children: [
      { path: '', redirect: 'clientrdlist' },
      {
        path: 'clientorders',
        name: 'ClientOrders',
        component: ClientOrders,
      },
      {
        path: 'clientoffers',
        name: 'ClientOffers',
        component: ClientOffers,
      },
      {
        path: 'clientEditOffer/:offer_id',
        name: 'clientEditOffer',
        component: DashboardOfferAdd,
        props: { mode: PageMode.EDIT, client: true },
      },
      {
        path: 'clientAddOffer', //lepiej add offer
        name: 'clientAddOffer',
        component: DashboardOfferAdd,
        props: { mode: PageMode.NEW, client: true },
      },
      {
        path: 'clientrdlist',
        component: ClientResultsDocList,
        props: true,
      },
    ],
  },
  {
    path: '/lab/',
    component: Laborant,
    props: true,
    children: [
      { path: '', redirect: 'cv' },
      {
        path: 'cv',
        component: CalendarVisits,
        props: true,
        children: [
          { path: '', redirect: 'cvm' },
          {
            path: 'cvm',
            name: 'calendarvisitsmonth',
            component: CalendarVisitsMonth,
          },
          {
            path: 'cvm/:date',
            name: 'calendarvisitsdaypeople',
            component: CalendarVisitsDayPeople,
          },
          {
            path: 'cvm/:date/:person_id/:visit_id',
            name: 'calendarvisitsdayperson',
            component: CalendarVisitsDayPerson,
          },
          {
            path: 'cvm/:date/:person_id',
            name: 'calendarvisitsdayperson',
            component: CalendarVisitsDayPerson,
          },
        ],
      },
      {
        path: 'synchro',
        component: SynchroList,
        name: 'Synchro',
      },
      {
        path: 'ce',
        component: CalendarExams,
        props: true,
        children: [
          {
            path: 'cem',
            name: 'calendarexamsmonth',
            component: CalendarExamsMonth,
          },
          {
            path: 'cem/examcard/:date/:exam_id',
            name: 'examcard',
            component: ExamCardView,
          },
          {
            path: 'cem/:day/:person_id',
            name: 'calendarexamsday',
            component: CalendarExamsDay,
          },
          {
            path: 'cem/:day',
            name: 'calendarexamsdaylabcoord',
            component: CalendarExamsDay,
          },
        ],
      },
      {
        path: 'calendarmetrocontrol',
        name: 'calendarmetrocontrol',
        component: CalendarMetroControl,
      },
      {
        path: 'calendarcerts',
        name: 'calendarcerts,',
        component: CalendarCerts,
      },
      {
        path: 'laborders',
        component: LaborantOrders,
        props: true,
      },
      {
        path: 'resultsdoclist',
        component: LaborantResultsDocList,
        props: true,
      },
      {
        name: 'dashBoardOffer',
        path: 'dashboardoffer',
        component: DashboardOffer,
        props: true,
      },
      {
        path: 'addOfferQuestion',
        name: 'addOfferQuestion',
        component: DashboardOfferAdd,
        props: { mode: PageMode.NEW },
      },
      {
        path: 'offerQuestion/:offer_id',
        name: 'editOfferQuestion',
        component: DashboardOfferAdd,
        props: { mode: PageMode.EDIT },
      },
      {
        path: 'dashboardcert',
        component: DashboardCert,
        props: true,
      },
      {
        path: 'dashboardaudit',
        component: DashboardAudit,
        props: true,
      },
      {
        path: 'dashboarddocument',
        component: DashboardDocument,
        props: true,
      },
      {
        path: 'dashboardsettlement',
        component: DashboardSettlement,
        props: true,
      },
      {
        path: 'laborders/:order_id',
        name: 'laborderpreview',
        component: LaborantOrder,
        props: { mode: PageMode.PREVIEW },
      },
      {
        path: 'laborders/:order_id',
        name: 'laborderedit',
        component: LaborantOrder,
        props: { mode: PageMode.EDIT },
      },
      {
        path: 'labordernew',
        name: 'labordernew',
        component: LaborantOrder,
        props: { mode: PageMode.NEW },
      },

      {
        path: 'methodlist',
        component: MethodList,
        props: true,
      },
      {
        path: 'toollist',
        component: ToolList,
        props: true,
      },
      {
        path: 'carlist',
        component: CarList,
        props: true,
      },
      {
        path: 'personellist',
        component: PersonelList,
        props: true,
      },
      {
        path: 'dashboardactions',
        name: 'dashboardactions',
        component: DashboardActions,
        props: true,
      },
      {
        path: 'visit/:visit_id',
        component: Visit,
        children: [
          { path: '', redirect: 'visitmain' },
          {
            path: '',
            name: 'visitmain',
            component: VisitViewMain,
          },
          {
            path: 'mixer/:protocol_id/:mixer_id',
            name: 'mixer',
            component: VisitMixer,
          },
          {
            path: 'collect/:protocol_id/:mixer_id/:collect_id',
            name: 'collect',
            component: VisitCollect,
          },
          {
            path: 'protocolpib/:protocol_id',
            name: consts.ProtocolTypeString.PIB,
            component: VisitProtocolPiB,
          },
          {
            path: 'protocolctb/:protocol_id',
            name: consts.ProtocolTypeString.COLLECT_CTB,
            component: VisitProtocolCTB,
          },
          {
            path: 'protocolc/:protocol_id',
            name: consts.ProtocolTypeString.COLLECT,
            component: VisitProtocolCollect,
          },
        ],
      },
      /************************ DICTS ************************/
      /* {
        path: 'admin',
        name: 'Admin',
        component: Admin,
      }, */
      {
        path: '/test',
        name: 'Test',
        component: Test,
      },
      {
        path: 'clientlist',
        name: 'ClientList',
        component: ClientList,
      },
      {
        path: 'examDestiny',
        name: 'ExamDestiny',
        component: ExamDestiny,
      },
    ],
  },
  {
    name: 'admin',
    path: '/admin/',
    component: Admin,
    props: true,
    children: [
      { path: '', redirect: 'userslist' },
      {
        path: 'userslist',
        name: 'UsersList',
        component: UsersList,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
