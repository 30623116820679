









































































































































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import * as consts from '@/consts';
import * as types from '@/types';
import { DashboardItem } from '@/types';
import * as API from '@/store/api';
import FileManager from './FileManager.vue';
import * as fileHelper from '@/store/modules/file';
import userModule from '@/store/modules/user';
import dashboardItems from '@/store/modules/dashboard_items';
import moment from 'moment';
import * as helpers from '@/helpers';
import { Guid } from 'guid-typescript';

@Component({
  components: {
    FileManager,
  },
})
export default class ModalDocuments extends Vue {
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'xl' }) size;
  @Prop({ required: false, type: Number }) readonly type: number;

  @Ref() fileManager: FileManager;

  guid = '';
  show = false;
  isNew = false;
  date = '';
  dateText = '';
  currentStatus: consts.CertActionStatus = new consts.CertActionStatus(-1, '', '', '');

  item: DashboardItem = null;
  document: types.GenericDocument = new types.GenericDocument();
  title = '';
  number = '';

  onMainDateContext(ctx) {
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.document.date = dt.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = moment(this.document.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
    }
  }

  dateTextLeave(event: any) {
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.date = today.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = today.format(consts.DATE_FORMAT);
      helpers.warn('Nie wybrano daty, ustawiono domyślną wartość');
    }
  }

  get getFileType() {
    if (!this.document) {
      return 'unknown';
    }
    if (this.document.guid !== undefined) {
      return 'TMP';
    }
    switch (this.type) {
      case consts.DashboardObjectTypes.AUDIT:
        return consts.FileGroup.AUDIT.symbol;
      case consts.DashboardObjectTypes.DOCUMENT:
        return consts.FileGroup.DOCUMENT.symbol;
      default:
        return 'unknown';
    }
  }
  get disableInputs() {
    return (
      this.currentStatus.id === consts.CertActionStatus.INITIAL.id ||
      this.currentStatus.id === consts.CertActionStatus.CANCELLED.id
    );
  }
  get auditEntitites() {
    const auditEntitites = [];
    for (const key in consts.AuditEntity) {
      auditEntitites.push(consts.AuditEntity[key]);
    }
    return auditEntitites;
  }
  get auditInternalStates() {
    const auditInternalStates = [];
    for (const key in consts.AuditInternalState) {
      auditInternalStates.push(consts.AuditInternalState[key]);
    }
    return auditInternalStates;
  }
  get getDocument() {
    return this.document;
  }

  get isAudit() {
    return this.document.type === consts.DashboardObjectTypes.AUDIT;
  }
  get isDocument() {
    return this.type === consts.DashboardObjectTypes.DOCUMENT;
  }

  async newObjectNumber(): Promise<string> {
    const response = await API.obtainCertDocNumber();
    const number = response.data.number;
    return number;
  }

  async saveObjectAsItem() {
    this.item.object_id = this.document.id;
    this.item.object_type = this.document.type;
    this.item.object_type_id = this.document.type;
    this.item.status_id = consts.CertActionStatus.AFTER.id;
    this.item.comment = '';
    this.item.operation_type_id = consts.CertOperationType.ADD.id;
    this.item.person_initiated_id = userModule.wholeUser.id;
    const result = await dashboardItems.addItem(this.item);
    return result;
  }

  async getFiles() {
    const id = this.document.id;
    const type = consts.FileGroup.CERT_DOC.symbol;
    const response = await fileHelper.fetchFiles(id, type);
    const files = response.data.files;
    this.document.files = [];
    files.forEach(element => {
      this.document.files.push(element);
    });
  }
  setNumber() {
    if (this.isDocument === true) {
      this.setTitleForDocuemnt();
      return;
    }
  }
  setTitleForDocuemnt() {
    this.title = this.document.name === null || this.document.name === '' ? 'Nowy dokument' : this.document.name;
  }

  empty(value?: string | boolean | number): boolean {
    return value === null || value === undefined || value === '';
  }
  validateAuditData() {
    return (
      this.empty(this.document.additionals1) === false &&
      this.empty(this.document.additionals2) === false &&
      this.empty(this.document.internal) === false &&
      this.empty(this.document.entity) === false &&
      (this.empty(this.document.description) === false || this.fileManager.files.length !== 0)
    );
  }
  validateDocumentData() {
    return this.empty(this.document.description) === false || this.fileManager.files.length !== 0;
  }

  async showModal(dbi?: DashboardItem) {
    this.date = this.dateText = '';
    if (dbi) {
      this.item = dbi;
      this.document = Object.assign(new types.GenericDocument(), dbi.object);
      this.document.load();
      this.currentStatus = this.document.titles.status.byId(dbi.status_id);
    } else {
      this.document = new types.GenericDocument();
      this.item = new DashboardItem();
      this.document.files = [];
      this.document.name = '';
      this.document.guid = this.guid = Guid.raw();
      this.document.type = this.type;
      this.document.load();

      this.document.date = moment().add(1, 'year').format(consts.DB_DATE_TIME_FORMAT);
      const number = await this.newObjectNumber();
      const strArr = number.split('-');
      strArr.splice(1, 0, this.document.titles.numberSeparator);
      this.document.number = strArr.join('-');

      this.document.load();
      this.currentStatus = this.document.titles.status.byId(1);
      this.isNew = true;
    }

    if (this.isAudit === true) {
      this.title = 'Audit numer: ' + this.document.number;
    } else if (this.isDocument === true) {
      this.setTitleForDocuemnt();
    } else {
      this.title = 'Dokument numer: ' + this.document.number;
    }

    this.date = this.document.date;
    this.dateText = moment(this.document.date, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);

    this.show = true;
  }

  async saveModal() {
    if (
      this.document.name === '' ||
      (this.isAudit === true && this.validateAuditData() === false) ||
      (this.isDocument === true && this.validateDocumentData() === false)
    ) {
      helpers.error('Neleży wypełnić wszystkie wymagane pola!');
      return;
    }

    this.item.object = this.document;

    const response = await API.saveCert(this.document);
    if (response.data.success) {
      this.document = response.data.certDocument;
      if (this.isNew) {
        await this.saveObjectAsItem();
      }
      helpers.info('Pomyślnie zapisano.', 1500);
      this.$emit(consts.ModalEvents.OK_EVENT);
    } else {
      helpers.warn('Wystąpił błąd przy zapisie!', 1500);
    }
    this.hideModal();
  }
  hideModal() {
    this.document = new types.GenericDocument();
    this.show = false;
  }
}
